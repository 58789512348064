import React from 'react'
import { Form } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	form: {
		'&$form': {
			width: '95%',
		},
	},
}))

function TForm({ children, className, onChange, formCallback, ...props }) {
	const classes = useStyles()

	return (
		<Form
			{...props}
			render={({ handleSubmit, form }) => (
				<form
					onChange={onChange}
					className={classNames(classes.form, className)}
					onSubmit={handleSubmit}
					autoComplete="off"
				>
					{formCallback ? children(form) : children}
				</form>
			)}
		/>
	)
}
export default TForm
