import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Dialog, AppBar, Toolbar, IconButton, Typography, Slide } from '@material-ui/core'
import CGCookiesContentFr from './CGCookiesContentFr'
import CGUContentFr from './CGUContentFr'
import CGCookiesContentEn from './CGCookiesContentEn'
import CGUContentEn from './CGUContentEn'
import CloseIcon from '@material-ui/icons/Close'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	appBar: {
		position: 'relative',
	},
	totalLogo: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	content: {
		height: '100vh',
		padding: '10%',
	},
	title: {
		textAlign: 'center',
		fontWeight: 400,
	},
	loremIpsum: {
		padding: '10%',
		paddingTop: 50,
	},
}))

function CGUDialog({ openDialog, legalCheckCookies, setOpenDialog, setLegalCheckCookies }) {
	const classes = useStyles()
	const { t, i18n } = useTranslation(['user', 'legal', 'legalCookies'])

	const showCGU = () => setLegalCheckCookies(false)
	const showCGUCookie = () => setLegalCheckCookies(true)

	return (
		<Dialog fullScreen open={openDialog} onClose={() => setOpenDialog(false)}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<a href="/" className={classes.totalLogo}>
						<img src="/images/total.png" alt={t('brand')} height="26" />
					</a>
					<IconButton color="inherit" onClick={() => setOpenDialog(false)} aria-label="Close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div className={classes.content}>
				<Typography variant="h2" component="h1" className={classes.title}>
					{legalCheckCookies ? t('legal:titleCGC') : t('legal:titleCGU')}
				</Typography>
				<div className={classes.loremIpsum}>
					{i18n.language == 'fr' ? (
						legalCheckCookies ? (
							<CGCookiesContentFr onClick={showCGU} />
						) : (
							<CGUContentFr onClick={showCGUCookie} />
						)
					) : legalCheckCookies ? (
						<CGCookiesContentEn onClick={showCGU} />
					) : (
						<CGUContentEn onClick={showCGUCookie} />
					)}
				</div>
			</div>
		</Dialog>
	)
}

export default CGUDialog
