import { configureStore } from '@reduxjs/toolkit'
import platformsReducer from './platforms/platformsSlice'
import msalInstanceReducer from './msalInstance/msalInstanceSlice'
import tokenReducer from './token/tokenSlice'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rolesReducer from './roles/rolesSlice'

const persistConfig = {
	key: 'rapsod',
	storage,
}

const persistedReducer = persistReducer(persistConfig, tokenReducer)

export const store = configureStore({
	reducer: {
		platforms: platformsReducer,
		msalInstance: msalInstanceReducer,
		token: persistedReducer,
		roles: rolesReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
})
export const persistor = persistStore(store)
