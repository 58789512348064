import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import {
	Grid,
	Typography,
	Card,
	CardHeader,
	CardContent,
	IconButton,
	Icon,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { Link } from 'react-router-dom/cjs/react-router-dom'

const useStyles = makeStyles((theme) => ({
	Card: {
		backgroundColor: '#FFF',
		position: 'relative',
		width: '100%',
		height: 200,
		animation: 'test 2500ms ease-out infinite',
		'&:hover': {
			color: theme.palette.secondary.main + ' !important',
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
			cursor: 'pointer',
		},
	},
	title: {
		color: theme.palette.secondary.main,
	},
	hidden: {
		visibility: 'hidden',
	},
	editIcon: {
		position: 'absolute',
		right: 15,
		bottom: 15,
		overflow: 'visible',
	},
}))

function BaseCard({ title, onClick, disableAction = false, icon, children }) {
	const classes = useStyles()

	const Action = ({ show }) => {
		return (
			<IconButton className={classNames({ [classes.hidden]: !show })}>
				<MoreVertIcon />
			</IconButton>
		)
	}
	return (
		<Card className={classes.Card} onClick={onClick}>
			<CardHeader
				avatar={
					<Typography className={classes.title} variant="h6">
						{title}
					</Typography>
				}
				action={<Action show={!disableAction} />}
			/>
			{children}
			{icon && (
				<CardContent>
					<Icon className={classes.editIcon}>
						<img height="25px" src={icon} alt="Footer Icon" />
					</Icon>
				</CardContent>
			)}
		</Card>
	)
}

function BasicCard({ title, size, onClick, disableAction = false, icon, to, children }) {
	return (
		<Grid item md={size} xs={12}>
			{to && (
				<Link to={to} style={{ textDecoration: 'none' }}>
					<BaseCard
						title={title}
						onClick={onClick}
						disableAction={disableAction}
						icon={icon}
						children={children}
					/>
				</Link>
			)}
			{!to && (
				<BaseCard
					title={title}
					onClick={onClick}
					disableAction={disableAction}
					icon={icon}
					children={children}
				/>
			)}
		</Grid>
	)
}
export default BasicCard
