import React, { useState, useEffect, useMemo } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	Grid,
	Typography,
	Card,
	CardContent,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import InputFile from '../../../components/Form/inputFullPage/InputFile'
import Form from '../../../components/Form/Form'
import InputRadio, { TotalRadio } from '../../../components/Form/inputFullPage/InputRadio'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import moment from 'moment'
import GetApp from '@material-ui/icons/GetApp'
import SearchIcon from '@material-ui/icons/Search'
import { useCookies } from 'react-cookie'
import { downloadFile } from '../../../containers/DataContainer'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
	getFileToByteArray,
	getFileNameToHeaders,
	managedFileNotFoundError,
} from '../../../utils/File'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import {
	SEND_PDP_CONVOCATION,
	SEND_PDP_NOTIFICATION,
	COMMUNICATION_HISTORY,
} from '../../../constants/url'
import RichText from '../../../components/RichText/RichText'
import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import debounce from 'lodash.debounce'
import PropTypes from 'prop-types'
import { noSpecialCharURLFriendly } from '../../../utils/VerifyFields'

const useStyles = makeStyles((theme) => ({
	convocationCard: {
		textAlign: 'center',
		width: '100%',
	},
	radioGroup: {
		textAlign: 'left',
		marginLeft: theme.spacing.unit,
		display: 'block',
		color: 'red',
	},
	dataTable: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		marginBottom: '10px',
	},
	icon: {
		color: theme.palette.secondary.main + ' !important',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	circularProgress: {
		color: theme.palette.secondary.main + ' !important',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
}))

Communications.propTypes = {
	buttonsStyle: PropTypes.object,
	pdpNumber: PropTypes.string,
	revisionNumber: PropTypes.string,
	pdpId: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
}
function Communications({
	buttonsStyle,
	pdpNumber,
	revisionNumber,
	pdpId,
	refreshChevrons,
	setRefreshChevrons,
	nextStep,
	previousStep,
}) {
	const [openConvocPopup, setOpenConvocPopup] = useState(false)
	const [openNotifPopup, setOpenNotifPopup] = useState(false)
	const [openMesPopup, setOpenMesPopup] = useState(false)
	const [message, setMessage] = useState(false)
	const [cookies] = useCookies()
	const [convocationNameFile, setConvocationNameFile] = useState(null)
	const [notificationNameFile, setNotificationNameFile] = useState(null)
	const [notificationFile, setNotificationFile] = useState(null)
	const [notificationData, setNotificationData] = useState(null)
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user', 'communication'])
	const [result, setResult] = useState({})
	const [loaderNotification, setLoaderNotification] = useState(false)
	const [loaderConvocation, setLoaderConvocation] = useState(false)
	const [pdpNotification, setPdpNotification] = useState(null)
	const [historyData, setHistoryData] = useState()
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const [isLoading, setIsLoading] = useState(true)

	const debouncedChangeEditorState = useMemo(() => debounce(setEditorState), [editorState])

	useEffect(() => {
		if (notificationFile) {
			if (noSpecialCharURLFriendly(notificationFile.name)) {
				enqueueSnackbar(t(`user:file_name_invalid`), {
					variant: 'error',
				})
			} else {
				setNotificationNameFile(notificationFile.name)
			}
		}
	}, [notificationFile])

	const ConvocationPopup = () => (
		<Dialog open={openConvocPopup} onClose={() => setOpenConvocPopup(false)}>
			<DialogContent>
				<Typography variant="subtitle1">{t('SureSendSummons')}</Typography>
				{loaderConvocation && <CircularProgress className={classes.circularProgress} />}
			</DialogContent>
			<DialogActions>
				{!loaderConvocation && (
					<Button onClick={sendConvocation} color="secondary">
						{t('confirm')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)

	const NotificationPopup = () => (
		<Dialog open={openNotifPopup} onClose={() => setOpenNotifPopup(false)}>
			<DialogContent>
				<Typography variant="subtitle1">{t('SendNotificationWarning')}</Typography>
				{loaderNotification && <CircularProgress className={classes.circularProgress} />}
			</DialogContent>
			<DialogActions>
				{!loaderNotification && (
					<Button onClick={sendNotification} color="secondary">
						{t('confirm')}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	)

	const MessagePopup = () => (
		<Dialog open={openMesPopup} onClose={() => setOpenMesPopup(false)}>
			<DialogContent>
				<Typography
					variant="subtitle1"
					color="secondary"
					dangerouslySetInnerHTML={{ __html: message }}
				/>
			</DialogContent>
		</Dialog>
	)

	const openConvocationPopup = () => {
		setOpenConvocPopup(true)
	}

	const sendingPdpConvocation = (res) => {
		const { data, problem } = res

		setLoaderConvocation(false)
		setOpenConvocPopup(false)

		if (problem) {
			if (data) {
				if (data[0].code) {
					enqueueSnackbar(t(data[0].code), { variant: 'error' })
				}
				if (data.status) {
					enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
				}
				if (data[0].innerErrors && data[0].innerErrors[0] && data[0].innerErrors[0].code) {
					enqueueSnackbar(t(data[0].innerErrors[0].code), { variant: 'error' })
				}
			} else enqueueSnackbar(t('error') + ` : ${data.status} ! `, { variant: 'error' })

			return
		}

		setRefreshChevrons((state) => !state)
		enqueueSnackbar('La convocation a bien été envoyée', { variant: 'success' })
		setConvocationNameFile(null)
	}

	const sendingPdpNotification = (res) => {
		setLoaderNotification(false)
		const { data, problem } = res

		if (problem) {
			setOpenNotifPopup(false)
			if (data && data[0] && data[0].code) {
				enqueueSnackbar(t(data[0].code), { variant: 'error' })
				return
			}
			if (data) enqueueSnackbar(t('error') + ` : ${data.status} ! `, { variant: 'error' })
			return
		}

		setRefreshChevrons((state) => !state)
		enqueueSnackbar(t('TheNotificationHasBeenSent'), { variant: 'success' })
		setOpenNotifPopup(false)
		setNotificationFile(null)
		setNotificationNameFile(null)
	}

	const sendingCommunicationHistory = (res) => {
		const { data } = res
		if (data) {
			setResult(data)
		}
	}

	useEffect(() => {
		if (!pdpNotification) return

		const formData = new FormData()
		formData.append('Message', pdpNotification.message)
		formData.append('Target', pdpNotification.target)

		if (pdpNotification.file) {
			formData.append('File', pdpNotification.file.content, pdpNotification.file.fileName)
		}

		api
			.post(
				bindParams(SEND_PDP_NOTIFICATION, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber),
				formData,
				{
					headers: {
						'content-type': 'multipart/form-data;',
					},
				},
			)
			.then(sendingPdpNotification)
	}, [pdpNotification])

	useEffect(() => {
		if (!historyData) return
		setIsLoading(true)
		api
			.post(
				bindParams(COMMUNICATION_HISTORY, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber),
				historyData,
			)
			.then(sendingCommunicationHistory)
		setIsLoading(false)
	}, [historyData])

	const sendConvocation = () => {
		if (!cookies[PLATFORM_ID_COOKIE]) {
			setOpenConvocPopup(false)
			enqueueSnackbar(`${t('error')} : ${t('Refresh')}  ! `, { variant: 'error' })
		} else {
			setLoaderConvocation(true)
			api
				.post(bindParams(SEND_PDP_CONVOCATION, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
				.then(sendingPdpConvocation)
		}
	}

	const openNotificationPopup = (values) => {
		setNotificationData(values)
		setOpenNotifPopup(true)
	}

	const openMessagePopup = (value) => {
		setMessage(value)
		setOpenMesPopup(true)
	}

	const sendNotification = () => {
		const rawContentState = convertToRaw(editorState.getCurrentContent())

		if (rawContentState.blocks.length === 1 && rawContentState.blocks[0].text.trim() === '') {
			setOpenNotifPopup(false)
			return enqueueSnackbar(t('pdp_notif_missing_data'), { variant: 'error' })
		}

		setLoaderNotification(true)
		if (notificationFile)
			getFileToByteArray(notificationFile, function (res) {
				let formData = {
					message: draftToHtml(rawContentState),
					target: parseInt(notificationData.nRecipient, 10),
					file: {
						fileName: notificationNameFile,
						content: res,
					},
				}

				setPdpNotification(formData)
			})
		else
			setPdpNotification({
				message: draftToHtml(rawContentState),
				target: parseInt(notificationData.nRecipient, 10),
			})
	}

	const ConvocationCard = () => (
		<Card className={classes.convocationCard}>
			<CardContent>
				<Typography variant="h6" color="secondary">
					{t('convocation')}
				</Typography>
				<Typography variant="body2" color="secondary">
					{convocationNameFile}
				</Typography>
				<br />
				<Button type="submit" color="secondary">
					{t('send')}
				</Button>
			</CardContent>
			<ConvocationPopup />
		</Card>
	)

	const NotificationCard = () => (
		<Card className={classes.convocationCard}>
			<CardContent>
				<Typography variant="h6" color="secondary">
					{t('notification')}
				</Typography>

				<RichText editorState={editorState} onChangeEditorState={debouncedChangeEditorState} />
				<br />
				<InputFile
					name="nFile"
					label={t('SelectFile')}
					style={{ width: '100%' }}
					setFile={setNotificationFile}
				/>
				<Typography variant="body2" color="secondary">
					{notificationNameFile}
				</Typography>
				<br />
				<InputRadio name="nRecipient" className={classes.radioGroup}>
					<TotalRadio label={t('allCompanies')} value="0" />
					<TotalRadio label={t('companiesWithValidatedFile')} value="1" />
					<TotalRadio label={t('companiesWithoutFile')} value="2" />
					<TotalRadio label={t('companiesNoVisitNorReunion')} value="3" />
				</InputRadio>
				<br />
				<Button type="submit" color="secondary">
					{t('send')}
				</Button>
			</CardContent>
			<NotificationPopup />
		</Card>
	)

	const downloadDocument = (file) => {
		downloadFile(file, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				if (error.data && error.data.code) {
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
					return
				}
				enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	useEffect(() => {
		let data = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			nbItemsAsked: 10,
			pageNumber: 1,
		}
		setHistoryData(data)
	}, [refreshChevrons])

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('Communications')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('preventionPlanNo')} {pdpNumber}
			</Typography>
			<hr className={classes.hrRoot} />
			<Grid container spacing={8} justify="center">
				<Grid item sm={12} md={4}>
					<Form onSubmit={openConvocationPopup}>
						<ConvocationCard />
					</Form>
					<br />
					<Form onSubmit={openNotificationPopup} initialValues={{ nRecipient: '0' }}>
						<NotificationCard />
					</Form>
				</Grid>
				<Grid item sm={12} md={8}>
					<DataTable
						title="Communication"
						data={result.data}
						columns={result.columns}
						options={result.options}
						isLoading={isLoading}
						customColumns={[
							{
								name: 'date',
								component: (value) => {
									return moment(value.toString()).format('DD/MM/YYYY')
								},
							},
							{
								name: 'document',
								component: function componentDocument(value) {
									if (!value) return t('communication:no_document')
									return (
										<GetApp
											classes={{ root: classes.icon }}
											onClick={() => {
												downloadDocument(value)
											}}
										/>
									)
								},
							},
							{
								name: 'message',
								component: function componentMessage(value) {
									if (!value) return
									return (
										<SearchIcon
											classes={{ root: classes.icon }}
											onClick={() => {
												openMessagePopup(value)
											}}
										/>
									)
								},
							},
						]}
						customLabels={[
							{
								name: 'date',
								label: t('date'),
							},
							{
								name: 'qui',
								label: t('qui'),
							},
							{
								name: 'nom',
								label: t('nom'),
							},
							{
								name: 'document',
								label: t('document'),
							},
							{
								name: 'message',
								label: t('message'),
							},
						]}
					/>
					<MessagePopup />
				</Grid>
			</Grid>
			<div className={buttonsStyle.root}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.previous} onClick={previousStep}>
							{t('previous')}
						</Button>
					</Grid>
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.next} onClick={nextStep}>
							{t('next')}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

export default Communications
