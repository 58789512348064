import React from 'react'
import { Field } from 'react-final-form'
import { Switch } from '@material-ui/core'
import PropTypes from 'prop-types'
function InputSwitch({ name, validate }) {
	return (
		<Field
			name={name}
			validate={validate}
			type="checkbox"
			render={({ input }) => (
				<>
					<Switch value="checkedB" color="primary" {...input} />
				</>
			)}
		/>
	)
}
InputSwitch.propTypes = {
	name: PropTypes.string,
	validate: PropTypes.func,
}
export default InputSwitch
