import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import humanFileSize from '../../../constants/coreConstants'
import { uploadPdpDocument } from '../../../containers/DataContainer'
import { getFileToByteArray } from '../../../utils/File'
import { bindParams } from '../../../utils/Link'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputFile from '../../Form/input/InputFile'
import SlidingForm from '../../Form/SlidingForm'
import PropTypes from 'prop-types'
import { noSpecialCharURLFriendly } from '../../../utils/VerifyFields'
import InputCheckbox from '../../Form/input/InputCheckbox'

const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	iconSmall: {
		fontSize: 20,
	},
	typographyRoot: {
		color: 'white',
		fontSize: 17,
		marginTop: 20,
		marginBottom: 15,
	},
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},
	charactersAllowed: {
		margin: '20px 0',
		color: '#fff',
	},
	inputCheckbox: {
		display: 'flex',
		marginTop: '20px',
	},
}))

/**
 * Shows a form to import a document
 * @returns {JSX.Element} React stack to render
 */

DocumentFileForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
function DocumentFileForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [loader, setLoader] = useState(false)
	const [file, setFile] = useState(null)
	const [nameFile, setNameFile] = useState(null)
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()
	const [IsNotified, setIsNotified] = useState(false)

	const onSubmit = () => {
		if (file) {
			if (noSpecialCharURLFriendly(file.name)) {
				enqueueSnackbar(t(`user:file_name_invalid`), {
					variant: 'error',
				})
			} else {
				upload(file)
			}
		}
	}

	useEffect(() => {
		if (file) setNameFile(file.name)
	}, [file])

	const upload = (file) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				setLoader(true)

				let fileData = {
					fileName: nameFile,
					content: res,
				}
				const formData = new FormData()
				formData.append('value', res, res.name)
				formData.append('IsNotified', IsNotified)

				if (fileData.content && fileData.content.errorCode) {
					enqueueSnackbar(t(`user:${fileData.content.errorCode}`), {
						variant: 'error',
					})
					close()
					setLoader(false)
					return
				}

				uploadPdpDocument(
					cookies[PLATFORM_ID_COOKIE],
					formData,
					data.pdpId,
					data.revisionNumber,
					function (res, error) {
						if (res) {
							data.setRefresh(!data.refresh)
							if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
							enqueueSnackbar(t('YouHaveJustDownloadedThisFile'), { variant: 'success' })
							close()
							setLoader(false)
						}
						if (error) {
							console.log(error)
							enqueueSnackbar(`Erreur: ${error.status} !`, { variant: 'error' })
							setLoader(false)
						}
					},
				)
			} else {
				enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('ImportFile')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit}>
				<Typography
					component="p"
					classes={{
						root: classes.typographyRoot,
					}}
				>
					{t('joinFile')}

					<Typography component="span" className={classes.charactersAllowed}>
						{t('file_characters_allowed')}
					</Typography>
				</Typography>
				<InputFile label={t('upload')} name="upload" setFile={setFile} />
				<InputCheckbox
					label={t('file_notified')}
					name="file_notified"
					onClick={() => {
						setIsNotified(!IsNotified)
					}}
					checked={IsNotified}
					labelClass={classes.inputCheckbox}
				/>
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>{t('UploadInProgress')}</Typography>
						</div>
					</>
				)}
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default DocumentFileForm
