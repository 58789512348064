import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Chip from '@material-ui/core/Chip'
import PropTypes from 'prop-types'

//STYLES
const useStyles = makeStyles(() => ({
	chip: {
		color: '#fff',
		border: '1px solid #fff',
	},
	formatText: {
		cursor: 'auto',
		textTransform: 'capitalize',
	},
}))

const ListItemSuperCard = ({ value }) => {
	// STYLES
	const classes = useStyles()

	return (
		<ListItem role={undefined} dense button color="inherit" className={classes.formatText}>
			<ListItemText
				primary={value.title}
				secondary={value.subTitle ? `(${value.subTitle})` : ''}
				primaryTypographyProps={{ color: 'inherit' }}
				secondaryTypographyProps={{ color: 'inherit' }}
			/>
			<ListItemSecondaryAction>
				<Chip label={value.count} className={classes.chip} variant="outlined" />
			</ListItemSecondaryAction>
		</ListItem>
	)
}
ListItemSuperCard.propTypes = {
	value: PropTypes.exact({
		title: PropTypes.string,
		subTitle: PropTypes.string,
		count: PropTypes.number,
	}),
}
export default ListItemSuperCard
