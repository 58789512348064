/**
 * Retrieving QueryStrings in an URL
 * @param {String} url URL string
 * @returns {Array} Query table
 */
export function SearchQueryStrings(url) {
	let queries = []

	if ('URLSearchParams' in window) {
		const currURL = new URL(url)
		const params = new URLSearchParams(currURL.search)
		for (let [key, value] of params.entries()) {
			queries.push({ key, value })
		}
		return queries
	}

	queries = url
		.split('?')[1]
		.split('&')
		.map(rslt => rslt.split('='))
		.map(rslt => ({ key: rslt[0], value: rslt[1] }))

	return queries
}
