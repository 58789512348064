import { Button, CircularProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	getCompanyRecordById,
	sendApprobation,
	getCompanyRecordRefusalMessage,
} from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormIcon from '../../Form/FormIcon'
import InputTextArea from '../../Form/input/InputTextArea'
import SlidingForm from '../../Form/SlidingForm'
import PropTypes from 'prop-types'
import InputSelectField from '../../Form/input/InputSelect'
import SelectMenuItem from '../../Form/input/SelectMenuItem'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing.unit * 2,
		paddingBottom: 10,
		backgroundColor: 'rgba(0,0,0,0)',
	},
	input: {
		display: 'block',
		margin: theme.spacing.unit * 2,
		marginLeft: 0,
	},
	buttonGroup: {
		textAlign: 'center',
	},
	button: {
		width: `calc(50% - ${theme.spacing.unit * 2}px)`,
		margin: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	acceptButton: {
		backgroundColor: theme.palette.yes.main,
		borderColor: theme.palette.yes.main,
	},
	refuseButton: {
		backgroundColor: theme.palette.no.main,
		borderColor: theme.palette.no.main,
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

function ApprovalCompaniesRecordsForm({ open, close, data }) {
	const classes = useStyles()
	const [currentWFStep, setCurrentWFStep] = useState({ name: '' })
	const { t } = useTranslation(['user', 'translation', 'chemical', 'companyRecord'])
	const [update, setUpdate] = useState(false)
	const [show, setShow] = useState(false)
	const [test, setTest] = useState('')
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(false)
	const [refusalMessages, setRefusalMessages] = useState([])
	const [comment, setComment] = useState('')
	const [refusalMessageSelected, setRefusalMessageSelected] = useState('')

	const recoverWorflowStep = () => {
		if (!open) return
		getCompanyRecordById(data.platformId, data.CR_id, (res, err) => {
			if (err) console.error(err)

			if (res) {
				setCurrentWFStep(res.data.filter((dt) => dt.isCurrent)[0])
				setShow(true)
			}
		})
	}

	const recoverRefusalMessage = () => {
		if (!open) return
		getCompanyRecordRefusalMessage(data.platformId, (res, err) => {
			if (err) console.error(err)

			if (res) {
				setRefusalMessages(res.data.map((x) => x.message))
				setShow(true)
			}
		})
	}

	useEffect(() => {
		if (!data || !Object.values(data).length) return
		setUpdate((u) => !u)
	}, [data])

	const sendRequest = (dt) => {
		if (!dt.isValidated && (!dt.reason || dt.reason.trim() === '')) {
			setLoading(false)
			enqueueSnackbar(t('commentNotEmpty'), { variant: 'warning' })
			return
		}
		sendApprobation(data.platformId, data.CR_id, dt, (res, err) => {
			setLoading(false)
			if (err) {
				enqueueSnackbar(t('failWithValidation') + ` ${currentWFStep.name} !`, {
					variant: 'error',
				})
				return
			}

			if (res && res.status === 200) {
				if (dt.isValidated)
					enqueueSnackbar(
						t('StepOfWorkflow') + ` "${currentWFStep.name}" ` + t('companyRecord:validated2'),
						{
							variant: 'success',
						},
					)
				else
					enqueueSnackbar(
						t('StepOfWorkflow') + ` "${currentWFStep.name}" ` + t('companyRecord:rejected2'),
						{
							variant: 'success',
						},
					)
				setUpdate(!update)
				data.setRefresh(true)
				close()
			}
		})
		setUpdate(!update)
	}

	useEffect(() => {
		recoverWorflowStep()
		recoverRefusalMessage()
	}, [open])

	return (
		<>
			<SlidingForm
				direction="vertical"
				open={open}
				onClose={close}
				title={t('validateDE') + ` ${data.societyName}`}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={() => {
								close()
							}}
						/>
					</>
				}
				submitLabel="Valider"
			>
				<Form
					initialValues={{ justify: comment, refusal_messages: refusalMessageSelected }}
					onSubmit={(values) => {
						setLoading(true)
						sendRequest({ reason: values.justify, isValidated: test })
					}}
				>
					{show && currentWFStep && (
						<>
							<br />
							<Typography color="primary" variant="body1">
								{t('state') + ` : ${currentWFStep.name}`}
							</Typography>
							<br />
							<Typography color="primary" variant="body1">
								{t('lastname') + ` : ${data.societyName}`}
							</Typography>
							<br />
							<Typography color="primary" variant="body1">
								{t('preventionPlanNumber') + ` : ${data.pdpNumber}`}
							</Typography>
							<br />
							<Typography color="primary" variant="body1">
								{t('natureintervention') + ` : ${data.typeOf}`}
							</Typography>
							<br />

							{refusalMessages && (
								<InputSelectField
									onChange={(e) => {
										setComment(e.target.value)
										setRefusalMessageSelected(e.target.value)
									}}
									name="refusal_messages"
									label={t('companyRecord:refusal_message')}
								>
									{refusalMessages.map((msg) => {
										return (
											<SelectMenuItem key={msg} value={msg}>
												{msg}
											</SelectMenuItem>
										)
									})}
								</InputSelectField>
							)}

							<InputTextArea
								disabled={loading}
								classes={{ root: classes.text }}
								name="justify"
								label={t('comment_workflow')}
							/>
							<br />
							<div className={classes.buttonGroup}>
								<Button
									disabled={loading}
									onClick={() => {
										setTest(true)
									}}
									type="submit"
									color="inherit"
									variant="contained"
									className={classNames(classes.button, classes.acceptButton)}
								>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
									{t('validate')}
								</Button>
								<Button
									disabled={loading}
									onClick={() => {
										setTest(false)
									}}
									type="submit"
									color="inherit"
									variant="contained"
									className={classNames(classes.button, classes.refuseButton)}
								>
									{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
									{t('refuse')}
								</Button>
							</div>
							{data.comment && (
								<>
									<br />
									<Typography color="primary" variant="body1" align="justify">
										{t('initialRefusalMessage') + ` : ${data.comment}`}
									</Typography>
								</>
							)}
						</>
					)}
				</Form>
			</SlidingForm>
		</>
	)
}
ApprovalCompaniesRecordsForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default ApprovalCompaniesRecordsForm
