import Cookies from 'js-cookie'
import {
	ACCOUNT_ID_COOKIE,
	ACCOUNT_TYPES_COOKIE,
	COMPANY_ID_COOKIE,
	FIRST_NAME_COOKIE,
	LAST_NAME_COOKIE,
	PLATFORM_ID_COOKIE,
	DEFAULT_COOKIE_OPTIONS,
} from '../constants/cookies'

// Without "authToken"
export const REQUIRED_COOKIES = [
	ACCOUNT_ID_COOKIE,
	FIRST_NAME_COOKIE,
	LAST_NAME_COOKIE,
	PLATFORM_ID_COOKIE,
	ACCOUNT_TYPES_COOKIE,
	COMPANY_ID_COOKIE,
]

export function requiredCookiesIsSaved(cookies) {
	let count = cookies.length
	let cookies2 = cookies.filter((data) => cookieIsPresent(data))
	return cookies2.length >= count
}

export function cookieIsPresent(cookieName) {
	let cookie = Cookies.get(cookieName)
	return cookie !== undefined && cookie !== ''
}

export function deleteCookie(cookieName) {
	Cookies.remove(cookieName, DEFAULT_COOKIE_OPTIONS)
}

export function resetAllRequiredCookies(RC) {
	RC.forEach((cookie) => {
		if (cookieIsPresent(cookie)) {
			deleteCookie(cookie)
		}
	})
}
