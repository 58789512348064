import React, { useState } from 'react'
import AccountInfoForm from '../components/forms/AccountInfoForm'
import EEInfoForm from '../components/forms/EEInfoForm'
import AddContactForm from '../components/forms/AddContactForm'
import AddPdPContactForm from '../components/forms/PreventionPlanForms/AddContactForm'
import AddPdPCompanyForm from '../components/forms/PreventionPlanForms/AddCompanyForm'
import CreateContact from '../components/forms/PreventionPlanForms/CreateContact'
import ChemicalProductsForm from '../components/forms/ChemicalProductsForm'
import EnvironmentalAspectForm from '../components/forms/EnvironmentalAspectForm'
import AttachmentForm from '../components/forms/AttachmentForm'
import PlatformChoiceForm from '../components/forms/PlatformChoiceForm'
import OperationnalModeForm from '../components/forms/OperationnalModeForm'
import WarningAccountForm from '../components/forms/WarningAccountForm'
import UserProfileForm from '../components/forms/UserProfileForm'
import UserForm from '../components/forms/UserForm'
import IEEInfoForm from '../components/forms/IEEInfoForm'
import OtherUserInfoForm from '../components/forms/OtherUserInfoForm'
import AttachmentExpiredForm from '../components/forms/AttachmentExpiredForm'
import DWDCorrespondentForm from '../components/forms/DWDCorrespondentForm'
import AcceptOpenningRequestForm from '../components/forms/AcceptOpenningRequestForm'
import RefuseOpenningRequestForm from '../components/forms/RefuseOpenningRequestForm'
import AttachmentCardForm from '../components/forms/AttachmentCardForm'
import JustifyAttachmentForm from '../components/forms/JustifyAttachmentForm'
import AttachmentNewDateForm from '../components/forms/AttachmentNewDateForm'
import CertificationForm from '../components/forms/CertificationForm'
import OtherFilesInformationForm from '../components/forms/OtherFilesInformationForm'
import DocumentFileForm from '../components/forms/PreventionPlanForms/DocumentFileForm'
import AddCompanyFilesContactForm from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/AddCompanyFilesContactForm'
import CreateCompanyFilesContact from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/CreateCompanyFilesContact'
import AddCompanyFilesCertification from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/AddCompanyFilesCertification'
import AddCompanyFilesDocuments from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/AddCompanyFilesDocuments'
import MedicalSurveillanceForm from '../components/forms/CompanyFiles/MedicalSurveillanceForm'
import AddChemicalProduct from '../components/forms/CompanyFiles/AddChemicalProduct'
import ChemicalProductsStorage from '../components/forms/CompanyFiles/ChemicalProductsStorage'
import SubcontractingCompagniesForm from '../components/forms/CompanyFiles/SubcontractingCompagniesForm'
import AddEnvironmentalAspectsFiles from '../components/forms/CompanyFiles/AddEnvironmentalAspectsFiles'
import EnvironmentalAspectNewFileForm from '../components/forms/CompanyFiles/EnvironmentalAspectNewFileForm'
import OperationalProceduresForm from '../components/forms/CompanyFiles/OperationalProceduresForm'
import PhasesUnsafeOperationsForm from '../components/forms/CompanyFiles/PhasesUnsafeOperationsForm'
import SocialReasonEditorForm from '../components/forms/PreventionPlanForms/SocialReasonEditorForm'
import ToggleDOForm from '../components/forms/ToggleDOForm'
import AddCompanyResponsable from '../components/forms/PreventionPlanForms/AddCompanyResponsable'
import ApprovalCompaniesRecordsForm from '../components/forms/CompanyFiles/ApprovalCompaniesRecordsForm'
import AddCRForm from '../components/forms/CompanyFiles/AddCRForm'
import AddCSEContactForm from '../components/forms/CompanyFiles/AddCSEContactForm'
import PhaseUnsafeObservationForm from '../components/forms/CompanyFiles/PhaseUnsafeObservationForm'
import AddExecutionManagerForm from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/AddExecutionManagerForm'
import CreateContactForm from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/CreateContactForm'
import CertificationDEForm from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/CertificationDEForm'
import ValidationDEForm from '../components/forms/CompanyFiles/ValidationDEForm'
import DEChemicalProductsForm from '../components/forms/CompanyFiles/DEChemicalProductsForm'
import AddCompanyFilesContactHSEForm from '../components/forms/CompanyFiles/AddCompanyFilesContactHSEForm'
import AlternatesForm from '../components/forms/AlternatesManagement/AlternatesForm'
import ActivityPhasesForm from '../components/forms/ActivityPhasesManagement/ActivityPhasesForm'
import CheckListForm from '../components/forms/CompanyFiles/DescriptionOfOperationsForm/CheckListForm'

export function useAccountForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AccountInfoForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useIEEInfoForm(data) {
	const { opened, open, close } = useForm()
	const formular = <IEEInfoForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useOtherUserInfoForm(data) {
	const { opened, open, close } = useForm()
	const formular = <OtherUserInfoForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useEEForm(data) {
	const { opened, open, close } = useForm()
	const formular = <EEInfoForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAddContactForm(data, changeState) {
	const { opened, open, close } = useForm()
	const formular = (
		<AddContactForm open={opened} close={close} data={data} changeState={changeState} />
	)

	return { formular, open, close }
}

export function useChemicalProductsForm(data) {
	const { opened, open, close } = useForm()
	const formular = <ChemicalProductsForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useEnvironmentalAspectForm(data) {
	const { opened, open, close } = useForm()
	const formular = <EnvironmentalAspectForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAttachmentForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AttachmentForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useWarningAccountForm(data) {
	const { opened, open, close } = useForm()
	const formular = <WarningAccountForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function usePlatformChoiceForm(data) {
	const { opened, open, close } = useForm()
	const formular = <PlatformChoiceForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAttachmentExpiredForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AttachmentExpiredForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useOperationnalModeForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <OperationnalModeForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useSelectUserProfileForm(data) {
	const { opened, open, close } = useForm()
	const formular = <UserProfileForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useCreateUserForm(data) {
	const { opened, open, close } = useForm()
	const formular = <UserForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

function useForm(data = {}) {
	const [opened, openForm] = useState(false)
	const [data1, setData1] = useState(data)

	const addData = (data2) => {
		setData1({ ...data1, ...data2 })
	}

	const open = () => openForm(true)
	const close = () => openForm(false)
	return { opened, open, close, data: data1, addData }
}

export function useDWPForm(data) {
	const { opened, open, close } = useForm()
	const formular = <DWDCorrespondentForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAddPdPContact(data) {
	const { opened, open, close } = useForm()
	const formular = <AddPdPContactForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAddPdPCompanyForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AddPdPCompanyForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAddPdPCompanyResponsableForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AddCompanyResponsable open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useCreatePdPContact(data) {
	const { opened, open, close } = useForm()
	const formular = <CreateContact open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAcceptOpenningRequestForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AcceptOpenningRequestForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useRefuseOpenningRequestForm(data) {
	const { opened, open, close } = useForm()
	const formular = <RefuseOpenningRequestForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useAttachmentCardForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AttachmentCardForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useJustifyAttachmentForm(data) {
	const { opened, open, close } = useForm()
	const formular = <JustifyAttachmentForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useAttachmentNewDateForm(data) {
	const { opened, open, close } = useForm()
	const formular = <AttachmentNewDateForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useCertificationForm(data) {
	const { opened, open, close } = useForm()
	const formular = <CertificationForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useOtherFilesInformationForm(data) {
	const { opened, open, close } = useForm()
	const formular = <OtherFilesInformationForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function usePDPDocumentFileForm(data) {
	const { opened, open, close } = useForm()
	const formular = <DocumentFileForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useAddCompanyFilesContact(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddCompanyFilesContactForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useCreateCompanyFilesContact(data) {
	const { opened, open, close } = useForm()
	const formular = <CreateCompanyFilesContact open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useAddCompanyFilesCertification(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddCompanyFilesCertification open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useAddCompanyFilesDocuments(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddCompanyFilesDocuments open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useSocialReasonEditorForm(data) {
	const { opened, open, close } = useForm()
	const formular = <SocialReasonEditorForm open={opened} close={close} data={data} />
	return { formular, open, close }
}

export function useMedicalSurveillanceForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<MedicalSurveillanceForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useAddCSEContactForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <AddCSEContactForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useAddChemicalProduct(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <AddChemicalProduct open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useChemicalProductsStorage(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<ChemicalProductsStorage open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useSubcontractingCompagniesForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<SubcontractingCompagniesForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useAddEnvironmentalAspectsFiles(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddEnvironmentalAspectsFiles open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useEnvironmentalAspectNewFileForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<EnvironmentalAspectNewFileForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useOperationalProceduresForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<OperationalProceduresForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function usePhasesUnsafeOperations(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<PhasesUnsafeOperationsForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useToggleDOForm(data) {
	const { opened, open, close } = useForm()
	const formular = <ToggleDOForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useApprovalCompaniesRecordsForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<ApprovalCompaniesRecordsForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useCreateCRForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <AddCRForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function usePhaseUnsafeObservationForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<PhaseUnsafeObservationForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useExecutionManagerForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddExecutionManagerForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useCreateContactForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <CreateContactForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useCertificationDEForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <CertificationDEForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useValidationDEForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = <ValidationDEForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}

export function useDEChemicalProductsForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<DEChemicalProductsForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useAddCompanyFilesContactHSEForm(data2) {
	const { opened, open, close, data, addData } = useForm(data2)
	const formular = (
		<AddCompanyFilesContactHSEForm open={opened} close={close} data={{ ...data, ...data2 }} />
	)

	return { formular, open, close, addData }
}

export function useAddAlternates(data) {
	const { opened, open, close } = useForm()
	const formular = <AlternatesForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useEditActivityPhases(data) {
	const { opened, open, close } = useForm()
	const formular = <ActivityPhasesForm open={opened} close={close} data={data} />

	return { formular, open, close }
}

export function useCheckListForm(data2) {
	const { opened, open, close, data, addData } = useForm()
	const formular = <CheckListForm open={opened} close={close} data={{ ...data, ...data2 }} />

	return { formular, open, close, addData }
}
