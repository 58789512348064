import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import FormIcon from '../../../components/Form/FormIcon'
import InputMail from '../../../components/Form/input/InputMail'
import InputText from '../../../components/Form/input/InputText'
import SlidingForm from '../../../components/Form/SlidingForm'
import { createPdPContact } from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import { emailFormat, maxLong, required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { capitalize } from 'lodash'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function AddContactForm({ open, close, data }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [initialValues, setInitialValues] = useState({})
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		if (data.data == null) setInitialValues({})
		else
			setInitialValues({
				lastname: data.data.name,
				firstname: data.data.firstName,
				mail: data.data.email,
				entity: data.data.entity,
			})
	}, [data])

	const onSubmit = (values) => {
		let formData = {
			firstName: capitalize(values.firstname.trim()),
			lastName: values.lastname.trim().toUpperCase(),
			email: values.mail.trim(),
			entity: values.entity.trim(),
		}
		if (data.data) {
			formData = {
				...formData,
				contactId: data.data.id,
			}
		}
		createPdPContact(
			cookies[PLATFORM_ID_COOKIE],
			formData,
			data.pdpId,
			data.revisionNumber,
			function (res, error) {
				if (res) {
					data.setNeedRefresh(true)
					if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
					if (data.data) {
						enqueueSnackbar(t('TheContactHasBeenUpdated'), { variant: 'info' })
					} else {
						enqueueSnackbar(t('TheContactHasBeenAdded'), { variant: 'info' })
					}

					close()
				}
				if (error) {
					if (error.status) {
						enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
						return
					}
					enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
					return
				}
			},
		)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('contacts:AddContact')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="lastname"
					label={t('lastname')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="firstname"
					label={t('firstname')}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong, emailFormat)}
					name="mail"
					label={t('email')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="entity"
					label={t('entity')}
				/>
				<div className={classes.validateGroup}>
					<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddContactForm
