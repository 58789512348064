import { Button, Modal, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import Form from '../Form/Form'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { CREATE_NEW_REVISION_CR } from '../../constants/url'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '10%',
		maxWidth: '35%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	title: {
		color: theme.palette.secondary.main,
		marginBottom: 20,
	},
	secondTitle: {
		display: 'inline-block',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	inputs: {
		margin: 10,
		width: '90%',
	},
	groupCheckbox: {
		marginLeft: 20,
	},
}))

/**
 * Shows a form to delete a company in the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function ConfirmRevision({ open, closeModal, deId, entId, setRedirected }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()

	const close = () => {
		closeModal(false)
	}

	const onSubmit = () => {
		api.post(bindParams(CREATE_NEW_REVISION_CR, cookies[PLATFORM_ID_COOKIE], deId)).then((res) => {
			const { data, problem } = res
			if (problem) return

			if (data)
				setRedirected({
					State: true,
					Link: `/companyRecord/${cookies[PLATFORM_ID_COOKIE]}/${entId}/${data.companyRecordId}`,
				})
		})
	}

	return (
		<Modal open={open} onClose={close}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit}>
					<Typography variant="h6" classes={{ root: classes.title }}>
						{t('ConfirmRevision')}
					</Typography>
					<Typography variant="body1" classes={{ root: classes.title }}>
						{t('ReasonConfirmRevision')}
					</Typography>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button className={classNames(classes.button, classes.validateButton)} type="submit">
							{t('submit')}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	)
}
ConfirmRevision.propTypes = {
	open: PropTypes.bool,
	closeModal: PropTypes.func,
	deId: PropTypes.string,
	entId: PropTypes.string,
	setRedirected: PropTypes.func,
}
export default ConfirmRevision
