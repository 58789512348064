import React from 'react'
import { makeStyles } from '@material-ui/styles'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { withRouter } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	arrowRoot: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		verticalAlign: 'middle',
		'&:hover': {
			cursor: 'pointer',
		},
	},
}))

function ArrowBackLink({ history }) {
	const classes = useStyles()

	return <ArrowBack className={classes.arrowRoot} onClick={() => history.goBack()} />
}

export default withRouter(ArrowBackLink)
