import { OutlinedInput, Tooltip } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Toolbar from '@material-ui/core/Toolbar'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import MenuIcon from '@material-ui/icons/Menu'
import PowerSettingsNew from '@material-ui/icons/PowerSettingsNew'
import { makeStyles } from '@material-ui/styles'
import React, { useContext, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import {
	ACCOUNT_ID_COOKIE,
	ACCOUNT_TYPES_COOKIE,
	FIRST_NAME_COOKIE,
	LAST_NAME_COOKIE,
	PLATFORM_ID_COOKIE,
	DEFAULT_COOKIE_OPTIONS,
	SHAREPOINT_URL_LIVE_COOKIE,
	SHAREPOINT_URL_SANDBOX_COOKIE,
} from '../../constants/cookies'
import { GETPLATFORMS, SHAREPOINT_URL } from '../../constants/url'
import { findRoleById } from '../../constants/roles'
import { REQUIRED_COOKIES, resetAllRequiredCookies } from '../../containers/CookieContainer'
import { getAccountInfosById } from '../../containers/DataContainer'
import { MenuContext } from '../../contexts/MenuContext'
import api from '../../utils/Requester'
import { setPlatforms } from '../../store/platforms/platformsSlice'
import { setRoles } from '../../store/roles/rolesSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useSnackbar } from 'notistack'
import { jwtDecode } from 'jwt-decode'
import { store } from '../../store/store'

// STYLES
const useStyles = makeStyles((theme) => ({
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		backgroundColor: theme.palette.primary.main,
	},
	totalIconButton: {
		marginLeft: theme.spacing.unit * -2 - 5,
		color: theme.palette.secondary.main,
	},
	AppImage: {
		flexGrow: 1,
	},
	paper: {
		position: 'fixed',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		right: 30,
		top: 10,
	},
	logout: {
		color: theme.palette.secondary.main,
	},
	profil: {
		color: theme.palette.secondary.main,
	},
	flag: {
		width: 33,
		margin: theme.spacing.unit,
		cursor: 'pointer',
	},
	langContainer: {
		margin: theme.spacing.unit,
	},
	roles: {
		paddingLeft: theme.spacing.unit,
	},
	logo: {
		height: 60,
		padding: '0 8px',
	},
}))

function TotalAppBar() {
	// STYLES
	const classes = useStyles()

	// STATES
	const [rapsoImg, setRapsoImg] = useState('/images/rapsod.png')
	const [clickCount, setClickCount] = useState(0)
	const [platform, setPlatform] = useState(null)
	const [profile, setProfile] = useState('ANONYMOUS.X')
	const [redirect] = useState({ active: false, path: '/' })
	const reduxDispatch = useDispatch()
	const platforms = useSelector((state) => state.platforms.platforms)
	const roles = useSelector((state) => state.roles.roles)?.accountTypes

	// eslint-disable-next-line no-unused-vars
	const [cookies, setCookie] = useCookies()

	// CONTEXTS
	const { open, dispatch } = useContext(MenuContext)

	// i18n
	const { t, i18n } = useTranslation(['user'])
	const { enqueueSnackbar } = useSnackbar()

	const getAssignedPlatforms = (p_AccountId) => api.get(GETPLATFORMS, { p_AccountId })

	const handlePlatformResp = (res) => {
		const { ok, data } = res
		if (!ok) return
		reduxDispatch(setPlatforms(data.map((platform) => platform)))
	}

	const getSharePointURL = (cookieOptions, platformId) => {
		api.get(SHAREPOINT_URL, { p_PlatformId: platformId }).then((res) => {
			const { ok, data } = res
			if (!ok) return
			if (data.length) {
				let live_url = data.filter((obj) => obj.environment === 'live')[0].url
				let sandbox_url = data.filter((obj) => obj.environment === 'sandbox')[0].url

				setCookie(SHAREPOINT_URL_LIVE_COOKIE, live_url, cookieOptions)
				setCookie(SHAREPOINT_URL_SANDBOX_COOKIE, sandbox_url, cookieOptions)
			}
		})
	}

	useEffect(() => {
		if (!cookies[ACCOUNT_ID_COOKIE]) return
		getAssignedPlatforms(cookies[ACCOUNT_ID_COOKIE]).then(handlePlatformResp)
		getAccountInfosById(cookies[ACCOUNT_ID_COOKIE], (res, err) => {
			if (err) {
				return
			}
			let defaultPlatform = res.data.accountTypePerPlatform.find(
				(x) => x.platformId === +cookies[PLATFORM_ID_COOKIE],
			)

			setCookie(PLATFORM_ID_COOKIE, defaultPlatform.platformId, DEFAULT_COOKIE_OPTIONS)
			setCookie(
				ACCOUNT_TYPES_COOKIE,
				JSON.stringify(defaultPlatform.accountTypes),
				DEFAULT_COOKIE_OPTIONS,
			)

			reduxDispatch(setRoles(defaultPlatform))
			setPlatform(defaultPlatform.platformId)
			if (
				cookies[ACCOUNT_TYPES_COOKIE].some(
					(ur) => ur.code === 'ADMIN' || ur.code === 'PLATFORMMANAGER',
				)
			)
				getSharePointURL(DEFAULT_COOKIE_OPTIONS, defaultPlatform.platformId)
		})

		const decoded = jwtDecode(store.getState().token.token)
		if (decoded.idp_access_token !== undefined) {
			let exp = jwtDecode(decoded.idp_access_token).exp
			if (exp < Date.now() / 1000) {
				enqueueSnackbar(t('ExpiredToken'), { variant: 'info' })
			}
		}
		setProfile(formatProfile(cookies[FIRST_NAME_COOKIE], cookies[LAST_NAME_COOKIE]))
	}, [])

	// EVENTS
	const toggleMenu = () => {
		if (open) dispatch({ type: 'close' })
		else dispatch({ type: 'open' })
	}

	const disconnect = () => {
		resetAllRequiredCookies(REQUIRED_COOKIES)
		window.location.href = window.location.href + '?disconnect'
	}

	const changePlatform = (e) => {
		let platformId = e.target.value

		getAccountInfosById(cookies[ACCOUNT_ID_COOKIE], (res, err) => {
			if (err) {
				return
			}

			let selectedPlatform = res.data.accountTypePerPlatform.find(
				(x) => x.platformId === +platformId,
			)
			setCookie(PLATFORM_ID_COOKIE, platformId, DEFAULT_COOKIE_OPTIONS)
			setCookie(
				ACCOUNT_TYPES_COOKIE,
				JSON.stringify(selectedPlatform.accountTypes),
				DEFAULT_COOKIE_OPTIONS,
			)
			setPlatform(platformId)

			window.location.reload()
		})
	}

	const LangSelector = () => {
		const changeLanguage = (lang) => {
			switch (lang) {
				case 'fr':
					i18n.changeLanguage('fr')
					break
				case 'en':
					i18n.changeLanguage('en')
					break
				default:
					break
			}
		}

		return (
			<div className={classes.langContainer}>
				<img
					onClick={() => changeLanguage('fr')}
					src="/images/fr.png"
					alt="fr"
					className={classes.flag}
				/>
				<img
					onClick={() => changeLanguage('en')}
					src="/images/en.png"
					alt="en"
					className={classes.flag}
				/>
			</div>
		)
	}

	const formatProfile = (firstName, lastName) => firstName + '.' + lastName.substring(0, 1)

	const onImgClick = () => {
		if (clickCount >= 5) setRapsoImg('/images/rapsod1.png')
		else setClickCount(clickCount + 1)
	}

	if (redirect.active) return <Redirect to={redirect.path} />
	else
		return (
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						color="primary"
						aria-label="Open drawer"
						onClick={toggleMenu}
						className={classes.totalIconButton}
					>
						{open ? <KeyboardArrowLeftIcon /> : <MenuIcon />}
					</IconButton>
					<a href="/">
						<img src="/images/total.png" alt={t('brand')} className={classes.logo} />
					</a>
					<span className={classes.AppImage}>
						<img src={rapsoImg} alt={t('title')} className={classes.logo} onClick={onImgClick} />
					</span>
					<LangSelector />
					<form className={classes.root} autoComplete="off">
						<FormControl variant="outlined" className={classes.formControl}>
							<Select
								value={platform !== null && platform}
								inputProps={{
									name: 'platform',
									id: 'platform',
								}}
								onChange={changePlatform}
								input={<OutlinedInput name="platform" labelWidth={0} />}
							>
								{platforms &&
									platforms.map(
										(plf) =>
											plf.platformId && (
												<MenuItem key={plf.platformId} value={plf.platformId}>
													{plf.name}
												</MenuItem>
											),
									)}
							</Select>
						</FormControl>
					</form>
					<div className={classes.roles}>
						{roles &&
							roles.map((role, i) => {
								let currentRole = findRoleById(role.id)[0]
								return (
									<Tooltip title={role.name} key={i}>
										<img src={currentRole.icon} alt={role.name} />
									</Tooltip>
								)
							})}
					</div>
					<Button disabled color="secondary">
						<AccountCircleOutlinedIcon className={classes.profil} />
						<span className={classes.profil}>&nbsp;{profile}</span>
					</Button>
					<Button onClick={disconnect} color="secondary" className={classes.logout}>
						<PowerSettingsNew className={classes.logout} />
						{t('logout')}
					</Button>
				</Toolbar>
			</AppBar>
		)
}

export default TotalAppBar
