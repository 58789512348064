import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import humanFileSize from '../../constants/coreConstants'
import { getFileToByteArray } from '../../utils/File'
import { bindParams } from '../../utils/Link'
import { required } from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormButtonIconDelete from '../Form/FormButtonIconDelete'
import FormIcon from '../Form/FormIcon'
import InputDate from '../Form/input/InputDate'
import InputFile from '../Form/input/InputFile'
import InputNumber from '../Form/input/InputNumber'
import InputText from '../Form/input/InputText'
import SlidingForm from '../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	label: {
		display: 'inline-block',
		color: theme.palette.primary.main,
		marginRight: 10,
	},
	inputFile: {
		display: 'inline-block',
	},
	date: {
		width: '100%',
		marginTop: 10,
	},
	dateLabel: {
		color: theme.palette.primary.main,
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function CertificationForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [nameFile, setNameFile] = useState(null)
	const [initialValues, setInitialValues] = useState({
		cftDateObtention: moment(new Date()).format('YYYY-MM-DD'),
	})
	const [tempId, setTempId] = useState(1)

	useEffect(() => {
		/* Update the name of the file in the input file */
		if (file && file.name) setNameFile(file.name)
		if (file && file.fileName) setNameFile(file.fileName)
		if (!file) setNameFile(null)
	}, [file])

	/* Set initial Values */
	useEffect(() => {
		if (!data.certificationData.cftReference) {
			setInitialValues({ cftDateObtention: moment(new Date()).format('YYYY-MM-DD') })
			return
		}

		setInitialValues({
			cftReference: data.certificationData.cftReference,
			cftIntitule: data.certificationData.cftIntitule,
			cftDateObtention: moment(data.certificationData.cftDateObtention).format('YYYY-MM-DD'),
			cftDureeValidite: data.certificationData.cftDureeValidite,
			cftLieu: data.certificationData.cftLieu,
		})
		if (data.certificationData.certifFile) {
			setFile(data.certificationData.certifFile)
			setNameFile(data.certificationData.certifFile.fileName)
		}
	}, [data])

	const onSubmit = (values) => {
		const formData = new FormData()
		for (const [key, val] of Object.entries(values)) {
			formData.append(key, val)
		}
		/* If the card already exist */
		if (!file && data.certificationData.cftId) {
			formData.append('cftId', data.certificationData.cftId)
			formData.append('cftReference', values.cftReference)
			formData.append('cftIntitule', values.cftIntitule)
			formData.append('cftDateObtention', moment(values.cftDateObtention).format('YYYY-MM-DD'))
			formData.append('cftDureeValidite', values.cftDureeValidite)
			formData.append('cftLieu', values.cftLieu)

			const certification = {}
			formData.forEach((value, key) => (certification[key] = value))

			data.updateCertificationList(certification)
			data.flushCertificateForm()
			setFile(null)
			close()
			return
		}
		/* If the card exist but not on the server */
		if (file && file.fileName) {
			let certification = {
				cftReference: values.cftReference,
				tempID: data.certificationData.tempID,
				cftIntitule: values.cftIntitule,
				cftDateObtention: moment(values.cftDateObtention).format('YYYY-MM-DD'),
				cftDureeValidite: values.cftDureeValidite,
				certifFile: file,
				cftLieu: values.cftLieu,
			}

			data.updateCertificationList(certification)
			data.flushCertificateForm()
			setFile(null)
			close()
			return
		}

		/* Upload if the card doesn't exist or exist but the file was change */
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				let certification = {
					cftReference: values.cftReference,
					cftIntitule: values.cftIntitule,
					cftDateObtention: moment(values.cftDateObtention).format('YYYY-MM-DD'),
					cftDureeValidite: values.cftDureeValidite,
					certifFile: res,
					cftLieu: values.cftLieu,
				}

				if (data.certificationData.cftId) {
					certification = { ...certification, cftId: data.certificationData.cftId }
				} else if (data.certificationData.tempID) {
					certification = { ...certification, tempID: data.certificationData.tempID }
				} else {
					// This temp id is used to find the object if we want to update it
					certification = { ...certification, tempID: tempId }
					setTempId(tempId + 1)
				}
				data.updateCertificationList(certification)
				data.flushCertificateForm()
				setFile(null)
				close()
			} else {
				enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
			}
		})
	}

	/* Test if input file is empty */
	const validate = () => {
		const errors = {}
		if (!file && !data.certificationData.cftId) {
			if (!file && !data.certificationData.certifFile) {
				errors.cftDocumentCheminComplet = 'upload obligatoire !'
			}
		}
		return errors
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('CreateCertification')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
							data.flushCertificateForm()
							setFile(null)
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
				data.flushCertificateForm()
				setFile(null)
			}}
		>
			<Form onSubmit={onSubmit} validate={validate} initialValues={initialValues}>
				<InputText
					name="cftReference"
					label={t('reference')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<InputText
					name="cftIntitule"
					label={t('entitled')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<InputDate
					name="cftDateObtention"
					label={t('ObtainingDate')}
					classes={{ root: classes.date }}
					delayOptions={{ disableFuture: true }}
				/>
				<InputNumber
					name="cftDureeValidite"
					label={t('PeriodOfValidity')}
					classes={{ root: classes.inputRoot }}
					validate={required}
					inputProperties={{ min: 0, max: 60 }}
				/>
				<InputText
					name="cftLieu"
					label={t('PlaceOfPassage')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<label className={classes.label}>{t('AssociatedDocument')}</label>
				<InputFile
					setFile={setFile}
					nameFileUpdated={nameFile}
					label={t('upload')}
					name="cftDocumentCheminComplet"
					className={classes.inputFile}
				/>
				<div className={classes.validateGroup}>
					{data.certificationData.cftId !== undefined && (
						<FormButtonIconDelete
							classes={{ root: classes.deleteButton }}
							onClick={() => {
								data.deleteCertification(data.certificationData.cftId)
								close()
								data.flushCertificateForm()
								setFile(null)
							}}
						/>
					)}
					{data.certificationData.tempID !== undefined && (
						<FormButtonIconDelete
							classes={{ root: classes.deleteButton }}
							onClick={() => {
								data.deleteCertification(data.certificationData.tempID)
								close()
								data.flushCertificateForm()
								setFile(null)
							}}
						/>
					)}
					<FormButtonIcon type="submit" />
				</div>
			</Form>
		</SlidingForm>
	)
}

export default CertificationForm
