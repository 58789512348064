import { CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { COMPANY_ID_COOKIE } from '../../constants/cookies'
import {
	deleteOtherDocument,
	downloadFile,
	recoverOperationalInfos,
	updateOperationalInfos,
} from '../../containers/DataContainer'
import { useCertificationForm, useOtherFilesInformationForm } from '../../containers/FormContainer'
import FormStep from '../Form/FormStep'
import HorizontalStepper from '../HorizontalStepper/HorizontalStepper'
import FirstStep from './OperationalInformations/FirstStep'
import FourthStep from './OperationalInformations/FourthStep'
import SecondStep from './OperationalInformations/SecondStep'
import ThirdStep from './OperationalInformations/ThirdStep'
import PropTypes from 'prop-types'
import { managedFileNotFoundError } from '../../utils/File'
import ROLES from '../../constants/roles'

const useStyles = makeStyles((theme) => ({
	stepperButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		margin: '0 0 0 auto',
		border: 'solid 2px',
		marginTop: 50,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
	},
	rootBtn: {
		display: 'flex',
		marginBottom: theme.spacing.unit * 2,
		justifyContent: 'space-between',
	},
	previousBtn: {
		marginLeft: '8%',
		marginRight: '20px',
	},
	nextBtn: {
		marginRight: '20px',
	},
	contentStyle: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		padding: '3%',
		borderRadius: '10px',
		backgroundColor: theme.palette.primary.main,
	},
	globalLoader: {
		marginRight: 'auto',
		display: 'inline-block',
		marginLeft: 10,
		color: theme.palette.secondary.main,
	},
	globalCircularProgress: {
		marginLeft: 'auto',
		color: theme.palette.secondary.main,
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	globalLoaderGroup: {
		display: 'flex',
	},
}))

/**
 * Shows the page with Operationnal Information Form using a stepper
 * @returns {JSX.Element} React stack to render
 */
function OperationnalInformationsForm({ companyID = null }) {
	const { t } = useTranslation(['user', 'validateData', 'common', 'operationalInfos'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [initialValues, setInitialValues] = useState({})
	const [certifications, setCertifications] = useState([])
	const [loader, setLoader] = useState({})
	const { enqueueSnackbar } = useSnackbar()
	const [confirmPopUp, setConfirmPopUp] = useState({ open: false, path: null })
	const [listFiles, setListFiles] = useState([])
	const [certificationdata, setcertificationData] = useState({})
	const targetCompanyID = companyID != null ? companyID : cookies[COMPANY_ID_COOKIE]
	const [refresh, setRefresh] = useState(true)
	const [globalLoader, setGlobalLoader] = useState(false)
	const [selectedJobs, setSelectedJobs] = useState(null)
	const [initialJobs, setInitialJobs] = useState([])
	const [numberStep, setNumberStep] = useState(null)
	const [nationality, setNationality] = useState(1)

	useEffect(() => {
		if (selectedJobs) setInitialJobs(selectedJobs)
	}, [selectedJobs])

	const closePopUp = () => {
		setConfirmPopUp({ open: false, path: null })
	}

	const updateCertificationList = (values) => {
		// We delete the object we want update if the object already exist on the server
		if (values.cftId) {
			let data = certifications.filter((obj) => {
				return obj.cftId !== values.cftId
			})
			setCertifications([...data, values])
			return
		}
		// We delete the object we want update if the object doesn't exist on the server
		let data = certifications.filter((obj) => {
			return obj.tempID !== values.tempID
		})
		setCertifications([...data, values])
	}

	const flushCertificateForm = () => {
		setcertificationData({})
	}

	const deleteCertification = (id) => {
		let data = certifications.filter((obj) => {
			return obj.cftId !== id && obj.tempID !== id
		})
		setCertifications([...data])
	}

	const CertificationForm = useCertificationForm({
		updateCertificationList,
		certificationData: certificationdata,
		flushCertificateForm,
		deleteCertification,
	})

	const uploadTempFile = (values) => {
		setListFiles([...listFiles, values])
	}
	const OtherFilesInformationForm = useOtherFilesInformationForm(uploadTempFile)

	useEffect(() => {
		/* Get data from the API */
		if (!refresh) return
		let Filters = 'None'
		recoverOperationalInfos(Filters, targetCompanyID, (res, error) => {
			if (error) return

			if (res) {
				setNationality(res.data.lngId)
				setInitialValues(formatResponse(res))
				if (res.data.companyCertificationList.length > 0)
					setCertifications(
						res.data.companyCertificationList.map((certification) => {
							return {
								cftId: certification.cftId,
								cftReference: certification.cftReference,
								cftIntitule: certification.cftIntitule,
								cftDateObtention: certification.cftDateObtention,
								cftDureeValidite: certification.cftDureeValidite,
								cftLieu: certification.cftLieu,
								cftDocumentCheminComplet: certification.cftDocumentCheminComplet,
							}
						}),
					)
				if (res.data.companyOtherDocumentList) setListFiles(res.data.companyOtherDocumentList)
				setRefresh(false)
			}
		})
	}, [refresh])

	const downloadDocument = (file, i) => {
		setLoader({ [`${i}`]: true })
		/* Test if the file is on the browser or not */
		if (file.content) {
			fileDownload(file.content, file.fileName)
			return
		}
		/* Download if the file is on the server */
		downloadFile(file.path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				setLoader({ [`${i}`]: false })
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				setLoader({ [`${i}`]: false })
				fileDownload(res.data, file.fileName)
			}
		})
	}

	const formatResponse = (res) => {
		let data = res.data
		let listSelectedJobs = []
		if (data.companyJobList) {
			data.companyJobList.forEach((job) => {
				listSelectedJobs = [...listSelectedJobs, job.metId]
			})

			setSelectedJobs(listSelectedJobs)
		}
		return {
			siret: data.entNumeroSiret,
			companyNationality: data.lngId,
			address: data.entAdresse,
			cp: data.entCodePostal,
			city: data.entVille,
			phone: data.entTelAgence,
			activity: data.entActivite,
			name: data.entNom,
			nameAgency: data.entNomAgence,
			socialReason: data.entRaisonSociale,
		}
	}

	const deleteDocument = (file, index) => {
		if (file.content) {
			let newListFiles = listFiles
			newListFiles.splice(index, 1)
			setListFiles(newListFiles)
			closePopUp()
			return
		}
		deleteOtherDocument(targetCompanyID, file.path, function (res, error) {
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('TheFileHasBeenDeleted'), { variant: 'info' })
				setRefresh(true)
				closePopUp()
			}
		})
	}

	const result = (data) => {
		for (const certification of certifications) {
			if (
				certification.certifFile &&
				certification.certifFile.content &&
				certification.certifFile.content.errorCode
			) {
				enqueueSnackbar(t(`user:${certification.certifFile.content.errorCode}`), {
					variant: 'error',
				})
				return
			}
		}

		let listNewFiles = []

		listFiles.forEach((file) => {
			if (!file.path) {
				// Path exist only for already present files so we filter them out
				listNewFiles = [...listNewFiles, file]
			}
		})

		const formData = new FormData()
		fillCertifications(formData)
		fillOtherDocuments(formData, listNewFiles)
		fillJobs(formData)
		formData.append('socialReason', data.socialReason)
		formData.append('companyName', data.name)
		formData.append('agencyName', data.nameAgency)
		formData.append('siret', data.siret)
		formData.append('nationalityId', nationality)
		formData.append('address', data.address)
		formData.append('zipCode', data.cp)
		formData.append('city', data.city)
		formData.append('phone', data.phone)
		formData.append('activity', data.activity)

		setGlobalLoader(true)
		updateOperationalInfos(targetCompanyID, formData, function (res, error) {
			if (res) {
				setNumberStep([])
				setGlobalLoader(false)
				setRefresh(true)
				enqueueSnackbar(t('YourDataHasBeenEdited'), { variant: 'info' })
			}
			if (error) {
				setGlobalLoader(false)

				if (error.data && error.data[0] && error.data[0].code)
					enqueueSnackbar(t(`operationalInfos:${error.data[0].code}`), { variant: 'error' })
				else enqueueSnackbar(t('operationalInfos:error'), { variant: 'error' })
			}
		})
	}

	const fillJobs = (formData) => {
		for (let index = 0; index < selectedJobs.length; index++) {
			formData.append(`jobs[${index}].metId`, selectedJobs[index])
		}
	}

	const fillCertifications = (formData) => {
		for (let index = 0; index < certifications.length; index++) {
			if (certifications[index].certifFile) {
				formData.append(
					`certifications[${index}].value`,
					certifications[index].certifFile,
					certifications[index].certifFile.name,
				)
			} else {
				let cftId = null
				if (certifications[index].cftId) cftId = certifications[index].cftId
				else if (certifications[index].tempID) cftId = certifications[index].tempID
				formData.append(`certifications[${index}].cftId`, cftId)
			}

			if (certifications[index].cftReference)
				formData.append(`certifications[${index}].cftReference`, certifications[index].cftReference)
			if (certifications[index].cftDateObtention)
				formData.append(
					`certifications[${index}].cftDateObtention`,
					certifications[index].cftDateObtention.toLocaleString(),
				)
			if (certifications[index].cftDocumentCheminComplet)
				formData.append(
					`certifications[${index}].cftDocumentCheminComplet`,
					certifications[index].cftDocumentCheminComplet,
				)
			if (certifications[index].cftDureeValidite)
				formData.append(
					`certifications[${index}].cftDureeValidite`,
					certifications[index].cftDureeValidite,
				)
			if (certifications[index].cftLieu)
				formData.append(`certifications[${index}].cftLieu`, certifications[index].cftLieu)
			if (certifications[index].cftIntitule)
				formData.append(`certifications[${index}].cftIntitule`, certifications[index].cftIntitule)
		}
	}

	const fillOtherDocuments = (formData, listNewFiles) => {
		for (let index = 0; index < listNewFiles.length; index++) {
			formData.append(
				`otherDocuments[${index}].value`,
				listNewFiles[index],
				listNewFiles[index].name,
			)
			formData.append(`otherDocuments[${index}].fileName`, listNewFiles[index].name)
		}
	}

	const updateCertificate = (values) => {
		setcertificationData(values)
		CertificationForm.open()
	}

	useEffect(() => {
		if (numberStep) {
			let findStep = numberStep.filter((step) => step !== 2)
			setNumberStep(findStep)
		}
	}, [certifications])

	useEffect(() => {
		if (numberStep) {
			let findStep = numberStep.filter((step) => step !== 3)
			setNumberStep(findStep)
		}
	}, [listFiles])

	const newCertificate = () => {
		CertificationForm.open()
	}

	const newFile = () => {
		OtherFilesInformationForm.open()
	}

	return (
		<>
			{CertificationForm.formular}
			{OtherFilesInformationForm.formular}
			{globalLoader && (
				<div className={classes.globalLoaderGroup}>
					<CircularProgress className={classes.globalCircularProgress} />
					<p className={classes.globalLoader}>Envoi du formulaire en cours</p>
				</div>
			)}
			<div className={classes.content}>
				<HorizontalStepper
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton, classes.previousBtn),
						next: classNames(classes.stepperButton, classes.nextBtn),
						completed: classes.stepperButton,
						finished: classes.stepperButton,
					}}
					contentStyle={classes.contentStyle}
					initialValues={initialValues}
					result={result}
					access={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
				>
					{/* STEP 1 */}
					<FormStep label={t('info')}>
						<FirstStep
							initialValues={initialValues}
							nationality={nationality}
							setNationality={setNationality}
						/>
					</FormStep>

					{/* STEP 2 */}
					<FormStep label={t('contactInformation')}>
						<SecondStep
							setSelectedJobs={setSelectedJobs}
							initialSelected={initialJobs}
							companyCountry={nationality}
						/>
					</FormStep>

					{/* STEP 3 */}
					<FormStep label={t('Certifications')}>
						<ThirdStep
							certifications={certifications}
							setCertifications={setCertifications}
							updateCertificate={updateCertificate}
							newCertificate={newCertificate}
						/>
					</FormStep>

					{/* STEP 4 */}
					<FormStep label={t('OtherFiles')}>
						<FourthStep
							setConfirmPopUp={setConfirmPopUp}
							deleteDocument={deleteDocument}
							newFile={newFile}
							confirmPopUp={confirmPopUp}
							downloadDocument={downloadDocument}
							closePopUp={closePopUp}
							listFiles={listFiles}
							loader={loader}
						/>
					</FormStep>
				</HorizontalStepper>
			</div>
		</>
	)
}
OperationnalInformationsForm.propTypes = {
	companyID: PropTypes.string,
}

export default OperationnalInformationsForm
