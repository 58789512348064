import React from 'react'
import { Step, StepLabel, StepContent } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	stepIcon: {
		color: theme.palette.primary.main,
		fill: theme.palette.secondary.main,
		stroke: theme.palette.primary.main,
		overflow: 'unset',
		opacity: 0.4,
	},
	textIcon: {
		fill: theme.palette.primary.main,
	},
	activeIcon: {
		opacity: 1,
	},
	activeLabel: {
		color: theme.palette.primary.main + ' !important',
		opacity: 1,
	},
	completedIcon: {
		fill: theme.palette.primary.main,
		opacity: 1,
	},
	completedIconFullScreen: {
		fill: theme.palette.primary.main,
		opacity: 1,
	},
	completedForm: {
		color: 'rgba(255,255,255,0.4)',
	},
	completedIconForm: {
		color: theme.palette.secondary.main + ' !important',
	},
	verticalLabel: {
		color: theme.palette.primary.main + ' !important',
	},
}))

function FormStep({ label, validate, vertical, type, disable = false, children, ...props }) {
	const classes = useStyles()
	return (
		<Step {...props}>
			<StepLabel
				classes={{
					alternativeLabel: classNames({
						[classes.completedIcon]: type === 'fullscreen',
						[classes.completedForm]: type === null,
					}),
					active: classNames({
						[classes.completedIcon]: type === 'fullscreen',
						[classes.completedIconForm]: type === null,
					}),
					completed: classNames({
						[classes.completedIcon]: type === 'fullscreen',
						[classes.completedIconForm]: type === null,
					}),
					label: classNames({ [classes.verticalLabel]: vertical }),
				}}
				StepIconProps={{
					classes: {
						root: classes.stepIcon,
						text: classes.textIcon,
						active: classes.activeIcon,
						completed: classNames({
							[classes.completedIconFullScreen]: type === 'fullscreen',
							[classes.completedIcon]: type === null,
						}),
					},
				}}
			>
				{label}
			</StepLabel>
			{vertical && <StepContent>{children}</StepContent>}
		</Step>
	)
}
export default FormStep
