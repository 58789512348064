import { createMuiTheme } from '@material-ui/core/styles'

const darkBlue = '#134391 !important'
export const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#ffffff',
			main: '#ffffff',
			dark: '#cccccc',
			contrastText: '#134391',
		},
		secondary: {
			light: '#526dc2',
			main: '#134391',
			dark: '#001d63',
			contrastText: '#fff',
		},
		yes: {
			main: '#72B84D',
		},
		no: {
			main: '#9D2235',
		},
	},
	typography: {
		useNextVariants: true,
	},
	overrides: {
		MuiMobileStepper: {
			dotActive: {
				backgroundColor: '#134391',
			},
		},
		MuiPickersModal: {
			dialogAction: {
				color: '#134391',
			},
		},
		MuiPickersYear: {
			root: {
				color: darkBlue,
			},
		},
		MUIDataTableHeadCell: {
			root: {
				color: '#134391',
			},
			toolButton: {
				display: 'inline-flex',
			},
		},
		MUIDataTableSelectCell: {
			checked: {
				color: darkBlue,
			},
		},
		MUIDataTableViewCol: {
			checked: {
				color: darkBlue,
			},
		},
		MUIDataTableToolbar: {
			root: {
				position: 'inherit',
			},
			icon: {
				'&:hover': {
					color: '#29508f',
				},
				color: '#134391',
			},
			titleRoot: {
				WebkitTextFillColor: '#134391',
			},
		},
		MUIDataTableBodyRow: {
			root: {
				'&:hover': {
					with: '98%',
					paddingLeft: '1%',
					boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
					transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
					backgroundColor: '#fafafa !important',
				},
			},
		},
		MuiTableCell: {
			root: {
				paddingLeft: 10,
				paddingRight: 10,
				textAlign: 'left',
			}
		},
		MuiPickersToolbarButton: {
			toolbarBtnSelected: {
				color: '#134391',
				fontWeight: 500,
			},
			toolbarBtn: {
				color: '#134391',
				fontWeight: 500,
			},
		},
		MuiPickersToolbar: {
			toolbar: {
				backgroundColor: '#ffffff',
			},
		},
		MuiPickersDay: {
			isSelected: {
				backgroundColor: '#001d63 !important',
				color: '#fff',
				'&:hover': {
					mouse: 'pointer',
				},
			},
			current: {
				color: 'black',
				opacity: 0.87,
				fontWeight: 500,
			},
		},
	},
})
