import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({}))

function TotalTable({ tableHeadData, messageError, children, props }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	return (
		<Table className={classes.table}>
			<TableHead>
				<TableRow>
					{tableHeadData.map((name, i) => {
						return(
							<TableCell align="center" key={i}>{t(name)}</TableCell>
						)
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{children}
			</TableBody>
		</Table>
	)
}

export default TotalTable
