import React from 'react'

function CGUContentEn(props) {
	return (
		<div>
			<h1 style={{ fontWeight: 'bold' }}>General Terms and Conditions of Use (GTCU)</h1>
			<p>
				Welcome to the website{' '}
				<a href="https://rapso-d.totalenergies.com/">https://rapso-d.totalenergies.com</a>
				(referred to herein as the "Website"). By connecting to the Website, you acknowledge that
				you have read, understood and accepted without limitation this document ("General Terms and
				Conditions of Use" or "GTCU"). You also agree to take cognizance of the{' '}
				<u>Personal Data Protection Policy</u> (
				<a href="https://rapso-d.totalenergies.com/dashboard">
					https://rapso-d.totalenergies.com/dashboard
				</a>
				) available on the Website. Please note that if you visit other websites of the
				TotalEnergies, different general terms and conditions and policies concerning personal data
				protection apply to these websites. It is recommended that you carefully read their legal
				notices.
			</p>
			<p>
				<strong>
					The companies within the TotalEnergies have their own legal existence and independent
					legal status. The Company publishing this Website (referred to herein as the &laquo;
					Website Publisher &raquo;) belongs to the TotalEnergies. The terms &laquo; TotalEnergies
					&raquo;, &laquo; TotalEnergies Company &raquo; and &laquo; Company &raquo; refer to the
					Company or any subsidiary of the Total group in general, a subsidiary being a company in
					which TotalEnergies SE directly or indirectly holds the majority of the voting rights. It
					should be noted that each of the subsidiaries companies has its own legal existence and
					independent legal personality. Likewise, the words &ldquo;we&rdquo;, &ldquo;us&rdquo; and
					&ldquo;our&rdquo; are used to refer to the Company or any company of the TotalEnergies in
					general or to those who work for them. It cannot be inferred from these expressions that
					TotalEnergies SE or any of its subsidiaries or affiliates are involved in the business and
					management of any other of TotalEnergies company.
				</strong>
			</p>
			<h2>1. Legal information</h2>
			<p>
				<strong>1.1. Website Publisher</strong>
			</p>
			<p>
				TotalEnergies Refining Chemicals, SAS with capital of 933 605 625,50 &euro;, whose head
				office is at
			</p>
			<p>
				2 place Jean Millier &ndash; La D&eacute;fense 6 &ndash; 92400 Courbevoie and registered in
				the Trade and Companies Register of Nanterre under the number FR63692004807.
			</p>
			<p>E-mail: ot-Rapso-D.admin@totalenergies.com</p>
			<p>Phone number: 33 + 6 30 56 70 66</p>
			<p>Publication director: TotalEnergies OneTech</p>
			<p>
				<strong>1.2. Hosting</strong>
			</p>
			<p>Total Global Information Technology Services,</p>
			<p>
				SAS with capital of 6 577 787 &euro;, whose head office is at 2 place Jean Millier &ndash;
				La D&eacute;fense 6 &ndash; 92400 Courbevoie and registered with the Nanterre Registry under
				number 531 680 437
			</p>
			<p>Phone number: 33 + 6 30 56 70 66</p>
			<h2>2. Definitions</h2>
			<p>
				The terms and expressions identified by a capital letter in the GTCU have the meanings
				indicated below, whether they are used in the singular or plural:
			</p>
			<p>
				&laquo;&nbsp;<strong>Account</strong>&nbsp;&raquo;: refers to the unique space reserved for
				the User, accessible after registration and enabling the User to use the services offered on
				the Website.
			</p>
			<p>
				&laquo;&nbsp;<strong>Contribution</strong>&nbsp;&raquo;: refers to any type of content (in
				particular, text, images, video, sound, etc.) published by a User on the Website.
			</p>
			<p>
				&laquo;&nbsp;<strong>User</strong>&nbsp;&raquo;: refers to any person who has an Account and
				accesses the Website.
			</p>
			<h2>3. Access and registration</h2>
			<p>
				The GTCU have been drawn up to govern the use of the Website. In the area of technology and
				communication, they supplement the charters and other texts applicable within the Company:
			</p>
			<p>Code of Conduct, internal regulations, charter for the use of IT resources, etc.</p>
			<p>
				All Users of the Website must comply with the principles established by the GTCU, which
				define a general framework common to all in order to avoid inappropriate use of the Website.
			</p>
			<p>They apply to all features of the Website.&nbsp;</p>
			<p>
				<strong>3.1 Conditions of access to the Website</strong>
			</p>
			<p>
				Each User of this Website also certifies that he or she complies with the GTCU and the laws
				in force, and in particular:
			</p>
			<ul>
				<li>Has the ability and means required to access the Website and use it.</li>
				<li>
					Grants the Website Editor and partners where applicable a right to use the information
					provided (other than data of a personal nature).
				</li>
			</ul>
			<ul>
				<li>
					Verified that the IT configuration used does not contain any virus and that it is in full
					working order.
				</li>
				<li>
					Must keep confidential the access codes and passwords that the Website Publisher may send
					to the User and is therefore responsible for their use and security. The Web Site
					publisher reserves the right to suspend your access to the Website in the event of
					fraudulent use or attempts at fraudulent use of said access.
				</li>
			</ul>
			<p>
				<strong>3.2 </strong>
				<strong>Website registration procedure</strong>
			</p>
			<p>
				To use the Website, the User must create an Account by providing the information requested.{' '}
				<strong>
					The User will then receive confirmation of their registration enabling them to access and
					use the Platform.
				</strong>{' '}
				The User must choose a password that is sufficiently secure. Once registered, the User will
				be able to connect to their Account using the login details provided at the time of
				registration.
			</p>
			<h2>4. Use and Undertakings of the User</h2>
			<p>
				<strong>4.1 </strong>
				<strong>Use of the Website</strong>
			</p>
			<p>On the Website, the User will be able to:</p>
			<ul>
				<li>Consult the information of his user&rsquo;s profile.</li>
				<li>
					Consult the information made available by TotalEnergies to enable them to prepare their
					file for the Prevention Plan for which their company is applying.
				</li>
				<li>Submit their Company File.</li>
				<li>
					Manage the components of their company's files (declaration of chemical products,
					operating procedures, etc.).
				</li>
			</ul>
			<p>
				<strong>4.2 </strong>
				<strong>Undertakings of the User</strong>
			</p>
			<p>The User acknowledges that:</p>
			<ul>
				<li>The GTCU apply to all Users authorized to use the Website.</li>
				<li>The Internet Site is intended for professional use.</li>
				<li>
					Each User is identified and expresses him/herself in his/her own name on the Internet
					Site.
				</li>
				<li>
					Each User is personally and legally responsible for the content, comments and information
					that he chooses to share or distribute via the Website.
				</li>
				<li>
					It is forbidden to distribute, send or share confidential or sensitive information in
					accordance with the obligations of loyalty, discretion and confidentiality incumbent on
					each User according to the rules applicable to them. Contributions to the Website are part
					of the Information Heritage Security Policy (PSPI) aimed at protecting sensitive data
					generated, held or exchanged by all entities of the Company.
				</li>
				<li>
					Each User who notices inappropriate content can either directly modify the data concerning
					him or his Company (Operational account) in the application or request its modification or
					deletion by contacting the Rapso-D KeyUser of the site to which he refers.
				</li>
			</ul>
			<p>The User also undertakes to:</p>
			<ul>
				<li>
					Protect personal data in accordance with applicable law and internal standards. They shall
					refrain from any use that is contrary to the regulations in force, to public order or to
					accepted standards of behavior.
				</li>
				<li>
					Provide information that is accurate, complete, sincere and unequivocal, and to ensure,
					where necessary, that it is updated in the event of any changes.
				</li>
				<li>
					Have all the rights and authorizations required to supply the information and content
					provided.
				</li>
			</ul>
			<h2>5. Intellectual Property</h2>
			<p>
				<strong>5.1. Company Property</strong>
			</p>
			<p>
				All information or documents (text, animated or static images, databases, sounds,
				photographs, know-how or cited products) stored in the Website as well as all elements
				created for the Website and its general structure are either the property of the Website
				Publisher or the companies within the TotalEnergies or are subject to rights to use,
				duplicate or communicate to the public that have been granted to such.
			</p>
			<p>
				This information, documents and items are subject to laws protecting copyright insofar as
				they have been made available to the public via this Website. No license or any right other
				than to view the Website has been granted to any party with regard to intellectual property
				rights.
			</p>
			<p>
				Duplication of Website documents is authorized solely as information for personal and
				private usage. Any duplication or usage of copies made for other purposes is formally
				prohibited and subject to prior and formal authorization by the Website Publisher. In all
				cases, authorized duplication of information stored in this Website must cite the source and
				make adequate references as to proprietary.
			</p>
			<p>
				<strong>5.2. Distinguishing Marks</strong>
			</p>
			<p>
				Unless otherwise stipulated, company names, logotypes, products and brands quoted on the
				Website are the property of the Website Publisher or the companies within the TotalEnergies
				or are subject to rights to use, duplicate or represent/communicate to the public that have
				been granted to such. You may not use them without prior written authorization from the
				Website Publisher.
			</p>
			<p>
				<strong>5.3. Databases</strong>
			</p>
			<p>
				The Website Publisher is the controller of databases on the Website and the Website
				Publisher is the owner of any databases made available. You may not extract or reuse a
				substantial qualitative or quantitative portion of the databases, including for private
				purpose.
			</p>
			<h2>6. Hypertext Links</h2>
			<p>
				<strong>6.1. Activation of links</strong>
			</p>
			<p>
				The Website Publisher formally declines any liability as to the content of websites to which
				it provides links or to which Users redirect in their Contributions. Said links are offered
				to users of this Website as a service. Please consult the general terms and conditions and
				the personal data protection policy for the websites in order to understand their practice.
				The decision to use links is made solely by the Website users. Website Publisher may modify
				or delete a link on this Website at any moment.
			</p>
			<p>
				<strong>6.2. Link authorization</strong>
			</p>
			<p>
				If you wish to create a hypertext link to this Website, you must obtain prior written
				authorization from the Website Publisher using the contact details provided at the end of
				the GTCU.
			</p>
			<h2>7. Formal Notice</h2>
			<p>
				<strong>7.1. Responsibility</strong>
			</p>
			<p>
				The information (&ldquo;Information&rdquo;) available on this www (World Wide Web) server is
				provided in good faith. This Information is considered correct when it is published on the
				Website. However, the Website Publisher neither represents nor guarantees that the
				Information is comprehensive or accurate. You bear all the risks arising from your reliance
				on the Information. The Information is provided on the condition that you or any other
				person receiving it can determine its interest for a specific purpose before use. Under no
				circumstances will the Website Publisher accept liability for injury arising from reliance
				on the said Information, its use or use of a product to which it refers. The Information
				shall not be deemed a recommendation to use information, products, procedures, equipment or
				formulae contravening a patent, copyright or registered trademark. The Website Publisher
				declines any explicit or implicit liability in the event that use of the Information
				contravenes a patent, copyright or registered trademark.
			</p>
			<p>
				The Website Publisher and all directly or indirectly owned subsidiaries or affiliates and
				any company within the TotalEnergies group categorically rejects any interpretation which
				may assimilate the content of its websites to purchase offers or incitements to acquire
				shares or other listed or unlisted negotiable securities. No explicit or implicit guarantee
				is given regarding the commercial nature of the Information provided or its suitability for
				a given purpose as well as the products to which said Information refers to. Under no
				circumstances does the Website Publisher undertake to update or correct the Information
				disseminated on the Internet or on its web servers. Similarly, the Website Publisher
				reserves the right to amend or correct the content of its Website at any time without prior
				notification.
			</p>
			<p>
				The Website Publisher does not guarantee that the Website will operate without interruption
				or that the servers ensuring access to it operate and/or the third-party sites to which
				hypertext links refer do not contain viruses.
			</p>
			<p>
				<strong>7.2. Forward-looking statements and information</strong>
			</p>
			<p>
				The documents included in this website may contain forward-looking information on the
				Company (including objectives and trends), as well as forward-looking statements within the
				meaning of the Private Securities Litigation Reform Act of 1995, notably with respect to the
				financial condition, results of operations, business, strategy and plans of TotalEnergies.
			</p>
			<p>
				Such forward-looking information and statements included in these documents are based on a
				number of economic data and assumptions made in a given economic, competitive and regulatory
				environment. They may prove to be inaccurate in the future and are subject to a number of
				risk factors that could lead to a significant difference between actual results and those
				anticipated, including currency fluctuations, the price of petroleum products, the ability
				to realize cost reductions and operating efficiencies without unduly disrupting business
				operations, environmental regulatory considerations and general economic and business
				conditions. Certain financial information is based on estimates particularly in the
				assessment of the recoverable value of assets and potential impairments of assets relating
				thereto.
			</p>
			<p>
				Neither TotalEnergies nor any of its subsidiaries assumes any obligation to update publicly
				any forward-looking information or statement, objectives or trends contained in these
				documents whether as a result of new information, future events or otherwise. Further
				information on factors, risks and uncertainties that could affect the Company&rsquo;s
				financial results or the Group&rsquo;s activities is provided in the most recent
				Registration Document, the French language version of which is filed by the Company with the
				French Autorit&eacute; des March&eacute;s Financiers and annual report on Form 20-F filed
				with the United States Securities and Exchange Commission (&ldquo;SEC&rdquo;)
			</p>
			<h2>8. Updates of the GTCU and applicable law</h2>
			<p>
				The Website Publisher may update these GTCU at any time. Consequently, the User is invited
				to regularly refer to the latest GTCU in effect available on the Website.
			</p>
			<p>
				If one or more of the provisions of the GTCU are found to be inapplicable or prohibited by
				virtue of a regulation of public order, the other unaffected provisions of the GTCU will
				remain applicable to the User to the extent permitted by this regulation.&nbsp;
			</p>
			<p>
				These GTCU are governed by French law and are subject to the jurisdiction of the competent
				French courts.
			</p>
			<h2>9. Contact</h2>
			<p>
				You may direct all questions concerning GTCU of the Website by e-mail to
				ot-Rapso-D.admin@totalenergies.com or by post to the following address:
			</p>
			<p>TotalEnergies OneTech</p>
			<p>2 place Jean Millier</p>
			<p>La D&eacute;fense 6</p>
			<p>92400 Courbevoie</p>
		</div>
	)
}

export default CGUContentEn
