import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import CheckCircle from '@material-ui/icons/CheckCircle'

const useStyles = makeStyles(theme => ({
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	validateIcon: {
		width: '2em',
		height: '2em',
	},
}))

function FormButtonIcon({ label, type, onClick, children, ...props }) {
	const classes = useStyles()

	return (
		<Button
			className={classes.buttonValidate}
			type={type}
			variant="outlined"
			onClick={onClick}
			{...props}
		>
			<CheckCircle className={classes.validateIcon} />
		</Button>
	)
}

export default FormButtonIcon
