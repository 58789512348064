import { getUserFirstConnexion } from '../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import {
	PLATFORM_ID_COOKIE,
	FIRST_CONNECTION,
	DEFAULT_COOKIE_OPTIONS,
} from '../../constants/cookies'
import { DOCUMENTATION_HELP } from '../../constants/url'
import React, { useState } from 'react'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Button,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

function HelpModal() {
	const { t } = useTranslation(['user'])
	const [cookies, setCookie] = useCookies()
	const platformId = cookies[PLATFORM_ID_COOKIE]
	let firstConnection = cookies[FIRST_CONNECTION]
	let docLink = DOCUMENTATION_HELP.replace(/[{@}]{3}/g, platformId)
	const [open, setOpen] = useState(firstConnection != undefined ? Boolean(+firstConnection) : false)

	if (firstConnection == undefined) {
		getUserFirstConnexion(platformId, (res, err) => {
			if (err) {
				setOpen(false)
				return
			}

			firstConnection = res.data
			setCookie(FIRST_CONNECTION, JSON.stringify(res.data ? 1 : 0), DEFAULT_COOKIE_OPTIONS)
			setOpen(res.data)
		})
	}

	const handleClose = () => {
		setCookie(FIRST_CONNECTION, JSON.stringify(0), DEFAULT_COOKIE_OPTIONS)
		setOpen(false)
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle id="alert-dialog-title">{t('Help')}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{t('HelpText')}
					<Link to={{ pathname: docLink }} target="_blank">
						{t('here')}
					</Link>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary" autoFocus>
					{t('close')}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default HelpModal
