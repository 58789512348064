import React, { useState, useEffect } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import FormIcon from '../../Form/FormIcon'
import SlidingForm from '../../Form/SlidingForm'
import { makeStyles } from '@material-ui/styles'
import Form from '../../Form/Form'
import { composeValidators } from '../../../utils/Form'
import { useCookies } from 'react-cookie'
import { required, maxLong, emailSize, siretFormat } from '../../../utils/VerifyFields'
import InputText from '../../Form/input/InputText'
import InputMail from '../../Form/input/InputMail'
import InputPhone from '../../Form/input/InputPhone'
import { useTranslation } from 'react-i18next'
import InputNumber from '../../Form/input/InputNumber'
import FormButton from '../../Form/FormButton'
import { getJobList } from '../../../containers/DataContainer'
import InputMultipleSelectField from '../../Form/input/InputMultipleSelect'
import SelectMenuItem from '../../Form/input/SelectMenuItem'
import InputTextArea from '../../Form/input/InputTextArea'
import InputDate from '../../Form/input/InputDate'
import InputSelectField from '../../Form/input/InputSelect'
import api from '../../../utils/Requester'
import { RESTRICT_PDP_CREATE_COMP, NATIONALITIES } from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import { useSnackbar } from 'notistack'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	scroll: {
		height: '63vh !important',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
	radioInput: {
		display: 'flex',
	},
	input: {
		width: '45%',
		margin: theme.spacing.unit * 2,
		display: 'inline-block',
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	dateBox: {
		width: '45%',
		display: 'inline-block',
	},
	fullWidthField: {
		width: '90%',
		marginLeft: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
	},
	block: {
		display: 'block',
	},
	date: {
		display: 'inline-block',
		verticalAlign: 'bottom',
		width: '50%',
		margin: theme.spacing.unit * 2,
		fontSize: 15,
	},
	text: {
		display: 'inline-block',
		color: theme.palette.primary.main,
	},
	button: {
		marginLeft: theme.spacing.unit,
	},
}))

/**
 * Shows a form to add a company to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddCompanyForm({ open, close, data, ...props }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation(['user'])
	const [listJobs, setListJobs] = useState(null)
	const [selectedJobs, setSelectedJobs] = useState([])
	const [nationalities, setNationalities] = useState([{}])
	const [date, setDate] = useState(null)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		GetNationalities()
		getJobList(1, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListJobs(res.data)
			}
		})
		let currentDate = new Date()
		currentDate.setDate(currentDate.getDate() + 10)
		setDate(currentDate)
	}, [])

	const recoveryNationalities = (res) => {
		const { data, problem, status } = res
		if (problem)
			return enqueueSnackbar(`${t('error')} ${status} : ${t('view_render_failed')}`, {
				variant: 'error',
			})
		if (data) {
			setNationalities(data)
		}
	}

	const GetNationalities = () => {
		api.get(NATIONALITIES).then(recoveryNationalities)
	}

	const onChangeMultipleSelect = (e) => {
		setSelectedJobs(e.target.value)
	}

	const formatRequest = (req) => ({
		socialReason: req.socialReason,
		companyName: req.enterpriseName,
		agencyName: req.agencyName,
		siret: req.siretNumber,
		agencyPhone: req.agencyPhone,
		postalCode: req.postalCode,
		city: req.city,
		activity: req.activity,
		jobs: selectedJobs,
		contactEmail: req.mail,
		justification: req.justification,
		expirationDate: req.approvalEndDate,
		nationalityId: req.nationality,
		agencyAddress: req.address,
	})

	const companyCreationResponse = (res, values) => {
		if (res.problem)
			return enqueueSnackbar("Problème de création d'entreprise !", { variant: 'error' })

		if (res.data) {
			const resultat = res.data.filter((object) => object.siretNumber == values.siretNumber)
			if (resultat && resultat[0]) {
				data.setNewCompanyCreated({
					value: resultat[0].companyId,
					label: `${resultat[0].socialReason} ${resultat[0].agencyName}`,
				})
			}

			enqueueSnackbar("Succès de la création d'entreprise !", { variant: 'success' })
			if (data && typeof data.refreshEligibleComp === 'function') data.refreshEligibleComp()
			close()
		}
	}

	const onSubmit = (values) => {
		const formData = formatRequest({
			...values,
			approvalEndDate: moment(values.approvalEndDate).format(),
		})
		api
			.post(
				bindParams(
					RESTRICT_PDP_CREATE_COMP,
					cookies[PLATFORM_ID_COOKIE],
					data.pdpId,
					data.revisionNumber,
				),
				formData,
			)
			.then((res) => {
				companyCreationResponse(res, values)
			})
	}

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			title={t('InformationAboutExternCompany')}
			iconAttachement={<FormIcon icon="clear" onClick={close} />}
		>
			<Form onSubmit={onSubmit} initialValues={{ approvalEndDate: date }}>
				<InputText
					name="enterpriseName"
					label={t('companyName')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					name="socialReason"
					label={t('socialReason')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					name="agencyName"
					label={t('agencyName')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					name="address"
					label={t('address')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputNumber
					name="postalCode"
					label={t('postalCode')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					name="city"
					label={t('city')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputPhone
					name="agencyPhone"
					label={t('agencyPhone')}
					className={classes.input}
					validate={composeValidators(required)}
				/>
				<InputNumber
					name="siretNumber"
					label={t('siretNumber')}
					className={classes.input}
					validate={composeValidators(required, siretFormat)}
				/>
				<InputText
					name="activity"
					label={t('activity')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputMultipleSelectField
					name="jobList"
					onChange={onChangeMultipleSelect}
					className={classes.input}
					label={t('profession')}
					validate={required}
				>
					{listJobs &&
						listJobs.map((job, i) => (
							<SelectMenuItem key={i} value={job.metId}>
								{job.lmtNom}
							</SelectMenuItem>
						))}
				</InputMultipleSelectField>
				<InputTextArea
					name="justification"
					label={t('justification')}
					validate={composeValidators(required, maxLong)}
					className={classes.input}
				/>
				<InputDate name="approvalEndDate" label={"Date d'expiration"} inputStyle={classes.input} />
				<InputMail
					name="mail"
					label={t('email')}
					className={classes.input}
					validate={composeValidators(required, emailSize)}
				/>
				<InputSelectField
					name="nationality"
					label={t('companyNationality')}
					className={classes.input}
					validate={composeValidators(required)}
				>
					{nationalities.map((nationality, i) => (
						<SelectMenuItem key={i} value={nationality.id}>
							{nationality.name}
						</SelectMenuItem>
					))}
				</InputSelectField>

				<FormButton type="submit" label={t('submit')} classes={{ root: classes.button }} />
			</Form>
		</SlidingForm>
	)
}

export default AddCompanyForm
