import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import classNames from 'classnames'
import InputMultipleSelect from '../Form/input/InputMultipleSelect'
import PropTypes from 'prop-types'
import CONTACTS from '../../constants/contacts'
import { upsertCompanyContacts } from '../../containers/DataContainer'
import {
	Button,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
	Paper,
	Icon,
	CardContent,
	Card,
} from '@material-ui/core'
import FPInputText from '../Form/inputFullPage/InputText'
import { composeValidators } from '../../utils/Form'
import InputPhone from '../Form/inputFullPage/InputPhone'
import { InputSwitch } from '../Form/input/InputCheckbox'
import { emailFormat, emailSize, required } from '../../utils/VerifyFields'
import { formatDate } from '../../utils/Date'
const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}
export const ChemicalProductFormStyle = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing.unit * 2,
		paddingBottom: 10,
		backgroundColor: 'rgba(0,0,0,0)',
	},
	input: {
		display: 'block',
		margin: theme.spacing.unit * 2,
		marginLeft: 0,
	},
	basicWidth: {
		width: 400,
	},
	fullWidth: {
		width: '100%',
	},
	date: {
		width: '100%',
	},
	leftSide: {
		display: CSS_VALUES.INLINE_BLOCK,
		width: 400,
		marginRight: theme.spacing.unit,
		verticalAlign: 'middle',
	},
	rightSide: {
		display: CSS_VALUES.INLINE_BLOCK,
		width: 500,
		verticalAlign: 'middle',
	},
	list: {
		display: 'block',
		margin: theme.spacing.unit * 2,
	},
	items: {
		textAlign: 'center',
	},
	text: {
		color: theme.palette.primary.main,
		marginBottom: theme.spacing.unit * 2,
	},
	rootBtn: {
		display: 'block',
		marginTop: theme.spacing.unit * 2,
	},
	previousBtn: {
		borderRadius: 30,
		paddingLeft: 15,
		paddingRight: 15,
		color: '#DDDDDD',
	},
	nextAndCompletedBtn: {
		border: 'solid 2px white',
		borderRadius: 30,
		paddingLeft: 15,
		paddingRight: 15,
		color: theme.palette.primary.main,
		marginLeft: theme.spacing.unit,
		'&:disabled': {
			borderColor: 'rgba(0,0,0,0.2)',
		},
	},
	dpList: {
		backgroundColor: '#3268a7',
		color: theme.palette.primary.main,
		boxShadow: 'none',
		height: 270,
		overflowY: 'auto',
	},
	dp: {
		padding: theme.spacing.unit * 3,
		width: '100%',
		borderBottom: 'solid 1px ' + theme.palette.primary.main,
	},
	hide: {
		display: 'none',
	},
	picto: {
		width: '25%',
		margin: theme.spacing.unit * 2,
		padding: theme.spacing.unit,
		background: theme.palette.secondary.dark,
		borderRadius: '35%',
	},
	selectedDp: {
		background: theme.palette.primary.light,
		borderRadius: '35%',
	},
	contactCard: {
		display: CSS_VALUES.INLINE_BLOCK,
		cursor: 'pointer',
		width: 430,
		margin: theme.spacing.unit,
	},
	scroll: {
		overflow: 'auto',
		maxHeight: 335,
	},
	selectedContact: {
		border: 'solid 6px ' + theme.palette.secondary.light,
	},
	notification: {
		color: theme.palette.secondary.main,
		position: 'absolute',
		top: 5,
		right: 5,
	},
}))
export const formatRequest = (
	req,
	data,
	selectedDangerPhrases,
	selectedContact,
	isCasNumberIgnored = null,
) => {
	const formData = new FormData()
	formData.append('name', req.name || '')
	formData.append('synonymous', req.synonym || '')
	formData.append('supplierName', req.provider_name || '')
	formData.append('purpose', req.use || '')
	formData.append('casOrCeNumber', req.cas_ce || '')
	formData.append('cmrCode', req.cmr || false)
	formData.append('hasOpertaingScenario', true)
	formData.append('contactId', selectedContact.id)
	formData.append('contactName', selectedContact.lastName)
	formData.append('contactFirstName', selectedContact.firstName)
	formData.append('contactPhone', selectedContact.phone)
	formData.append('contactNotifiableForProducts', true)
	formData.append('isCasNumberIgnored', isCasNumberIgnored)
	formData.append('validityDuration', req.validityDuration)
	formData.append('validityEndDate', formatDate(req.validityEndDate))
	for (const dangerPhrase of selectedDangerPhrases)
		formData.append('dangerPhrases[]', parseInt(dangerPhrase.phdId))
	if (data && data.id) formData.append('id', data.id)
	if (req.file.name) {
		formData.append('value', req.formFile, req.file.name)
	}
	return formData
}
export const formatResponse = (res, setFile) => {
	if (res && res.data) {
		if (res.data.prdFDSCheminComplet) {
			setFile(res.data.prdFDSCheminComplet)
		}
		return {
			name: res.data.prdNom,
			synonym: res.data.prdSynonyme,
			provider_name: res.data.prdNomFournisseur,
			use: res.data.prdUsage,
			cas_ce: res.data.prdNumeroCasCe,
			cmr: res.data.prdCodeCMR,
			se: res.data.prdAsScenarioExpoSE,
		}
	}
}
export const ContactCard = ({
	id,
	lastName,
	firstName,
	phone,
	mail,
	setSelectedContact,
	selectedContact,
}) => {
	const classes = ChemicalProductFormStyle()
	return (
		<Card
			className={classNames(
				{ [classes.selectedContact]: id === selectedContact.id },
				classes.contactCard,
			)}
			onClick={() => setSelectedContact({ id, lastName, firstName, phone, mail })}
		>
			<CardContent style={{ position: 'relative' }}>
				<Typography className={classes.title} color="secondary" variant="h5">
					{lastName + ' ' + firstName}
				</Typography>
				<br />
				<Typography variant="subtitle1" color="secondary">
					<Icon style={{ verticalAlign: 'middle' }}>phone</Icon>
					{' ' + phone}
				</Typography>
				<Typography variant="subtitle1" color="secondary">
					<Icon style={{ verticalAlign: 'middle' }}>mail</Icon>
					{' ' + mail}
				</Typography>
			</CardContent>
		</Card>
	)
}
ContactCard.propTypes = {
	id: PropTypes.number.isRequired,
	lastName: PropTypes.string.isRequired,
	firstName: PropTypes.string.isRequired,
	phone: PropTypes.string.isRequired,
	mail: PropTypes.string.isRequired,
	setSelectedContact: PropTypes.func.isRequired,
	selectedContact: PropTypes.object.isRequired,
}
/**
 * @name createCompanyContact
 * @description Create a new contact in a company
 * @param {Object} dt
 * @param {Object} data
 * @param {Function} callback
 */
export const createCompanyContact = async (dt, data, callback) => {
	dt = {
		...dt,
		createApplicationAccess: true,
		roles: [CONTACTS.PRODUCTCONTACT.id],
	}
	upsertCompanyContacts(dt, data.companyId, function (response, error) {
		if (response) {
			if (data.id) {
				callback({ variant: 'info', message: 'TheContactHasBeenUpdated', isError: false })
			} else {
				callback({ variant: 'info', message: 'TheContactHasBeenAdded', isError: false })
			}
		}
		if (error) {
			callback({ variant: 'error', message: 'error', isError: true, errorStatus: error.status })
		}
	})
}
export const Picto = ({ src, code, selectedDangerPhrases }) => {
	const [checked, setChecked] = useState(false)
	const classes = ChemicalProductFormStyle()
	useEffect(() => {
		for (const sdp of selectedDangerPhrases) {
			for (const picto of sdp.phdListePictos) {
				if (picto === code) setChecked(true)
			}
		}
	}, [selectedDangerPhrases])
	return (
		<img
			src={src}
			alt={code}
			className={classNames(classes.picto, { [classes.selectedDp]: checked })}
		/>
	)
}
Picto.propTypes = {
	src: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired,
	selectedDangerPhrases: PropTypes.array.isRequired,
}
export const FormStepRiskIdentification = ({
	translation,
	selectedDangerPhrases,
	setSelectedDangerPhrases,
	dangerPhrases,
}) => {
	const classes = ChemicalProductFormStyle()
	return (
		<>
			<InputMultipleSelect
				onChange={(e) => {
					setSelectedDangerPhrases(e.target.value)
				}}
				name="dangerous_sentences"
				label={translation.dangerousSentences}
				className={classNames([classes.input, classes.basicWidth])}
				value={selectedDangerPhrases}
			>
				{dangerPhrases.map((dp, i) => {
					return (
						<SelectMenuItem key={i} value={dp}>
							{dp.lpdCodification + ' - ' + dp.lpdLibelle}
						</SelectMenuItem>
					)
				})}
			</InputMultipleSelect>
			<Paper className={classes.dpList}>
				{selectedDangerPhrases.map((sdp, i) => (
					<div key={i} className={classes.dp}>
						{sdp.lpdCodification + ' - ' + sdp.lpdLibelle}
					</div>
				))}
				{selectedDangerPhrases.length <= 0 && (
					<div className={classes.noResult}>
						<Icon>info</Icon>
						<span>{translation.NoDangerPhrases}</span>
					</div>
				)}
			</Paper>
		</>
	)
}
FormStepRiskIdentification.propTypes = {
	translation: PropTypes.object.isRequired,
	selectedDangerPhrases: PropTypes.array.isRequired,
	setSelectedDangerPhrases: PropTypes.func.isRequired,
	dangerPhrases: PropTypes.array.isRequired,
}
export const PictoRightSide = ({ selectedDangerPhrases }) => {
	const classes = ChemicalProductFormStyle()
	return (
		<>
			<div className={classes.rightSide}>
				<Picto
					src="/icons/chemical_notices/candent.png"
					code="GHS02"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/health.png"
					code="GHS08"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/polution.png"
					code="GHS09"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/pressure.png"
					code="GHS04"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/explosion.png"
					code="GHS01"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/climat.png"
					code="GHS07"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/death.png"
					code="GHS06"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/erosion.png"
					code="GHS05"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
				<Picto
					src="/icons/chemical_notices/consumer.png"
					code="GHS03"
					selectedDangerPhrases={selectedDangerPhrases}
				/>
			</div>
		</>
	)
}
PictoRightSide.propTypes = {
	selectedDangerPhrases: PropTypes.array.isRequired,
}
export const FormCreateContact = ({ t, setOpenDialog }) => {
	return (
		<>
			<DialogTitle id="customized-dialog-title" onClose={() => setOpenDialog(false)}>
				<Typography color="secondary">{t('CreationChemicalProductManager')}</Typography>
			</DialogTitle>
			<DialogContent>
				<FPInputText name="contactName" label={t('lastname')} validate={required} />
				<br />
				<FPInputText name="contactFirstName" label={t('firstname')} validate={required} />
				<br />
				<FPInputText
					name="contactEmail"
					label={t('email')}
					validate={composeValidators(required, emailSize, emailFormat)}
				/>
				<br />
				<InputPhone name="contactPhone" label={t('phone')} validate={required} />
				<br />
				<InputSwitch name="isContactNotifiable" label={t('notifie')} contrast />
				<br />
			</DialogContent>
			<DialogActions>
				<Button type="submit" color="secondary">
					{t('Create')}
				</Button>
			</DialogActions>
		</>
	)
}
FormCreateContact.propTypes = {
	t: PropTypes.func.isRequired,
	setOpenDialog: PropTypes.func.isRequired,
}
