import { Button, Typography, CircularProgress } from '@material-ui/core'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import AddCircle from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import FormIcon from '../../../Form/FormIcon'
import SlidingForm from '../../../Form/SlidingForm'
import Form from '../../../Form/Form'
import { getotherDocuments } from '../../../../containers/DataContainer'
import InputRadio, { TotalRadio } from '../../../Form/input/InputRadio'
import { OTHER_DOCUMENT_DESCRIPTION } from '../../../../constants/url'
import { useOtherFilesInformationForm } from '../../../../containers/FormContainer'
import { bindParams } from '../../../../utils/Link'
import api from '../../../../utils/Requester'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	addIcon: {
		width: '2em',
		height: '2em',
	},
	validateGroup: {
		display: 'flex',
	},
	scrollbars: {
		height: '63vh',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},
}))

/**
 * Shows a slide of the checklist who must be completed
 * @returns {JSX.Element} React stack to render
 */
function CheckListForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [listFiles, setListFiles] = useState([])
	const [loader, setLoader] = useState(false)

	const getDocuments = () => {
		if (!open) return
		getotherDocuments(data.companyId, (res, err) => {
			if (err) return console.error('Error to get documents !')
			setListFiles(res.data)
		})
	}

	useEffect(getDocuments, [open])

	const onSubmit = (values) => {
		let copyCheckList = data.checkListDocuments
		let indexItemSelected = copyCheckList.checkListItemsCompanyRecord.indexOf(
			data.checkListItemOnFocus,
		)
		let file = listFiles.filter((file) => values.file === file.path)[0]

		if (file) {
			copyCheckList.checkListItemsCompanyRecord[indexItemSelected].documentFile = parseFile(file)
			data.setChecklistDocuments(copyCheckList)
		}

		data.setOnClickOpenCheckListForm(false)
		close()
	}

	const parseFile = (file) => {
		return {
			docName: file.fileName,
			docId: file.docId,
			docDocumentCheminComplet: file.path,
			content: file.content,
			dateAdded: file.dateAdded,
			username: file.username,
		}
	}

	const OtherFilesInformationsForm = useOtherFilesInformationForm((dt) => {
		setLoader(true)
		const formData = new FormData()
		formData.append('value', dt)

		api
			.post(bindParams(OTHER_DOCUMENT_DESCRIPTION, data.platformId, data.companyId), formData)
			.then((res) => {
				const { ok } = res
				if (ok && res.data) {
					const newDoc = res.data.find((doc) => doc.fileName === dt.name)
					let copyCheckList = data.checkListDocuments
					let indexItemSelected = copyCheckList.checkListItemsCompanyRecord.indexOf(
						data.checkListItemOnFocus,
					)
					copyCheckList.checkListItemsCompanyRecord[indexItemSelected].documentFile =
						parseFile(newDoc)
					data.setChecklistDocuments(copyCheckList)
					data.setOnClickOpenCheckListForm(false)
					setLoader(false)

					close()
				}
			})
	})

	return (
		<>
			{OtherFilesInformationsForm.formular}
			<SlidingForm
				direction="vertical"
				open={open}
				title={t('addFromLibrary')}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={() => {
								data.setOnClickOpenCheckListForm(false)
								close()
							}}
						/>
					</>
				}
				submitLabel="Valider"
				onClose={() => {
					data.setOnClickOpenCheckListForm(false)
					close()
				}}
			>
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>{t('UploadInProgress')}</Typography>
						</div>
					</>
				)}
				{!loader && (
					<Form onSubmit={onSubmit} initialValues={{}}>
						<div className={classes.scrollbars}>
							<Scrollbars>
								<InputRadio name="file">
									{listFiles &&
										listFiles.map((el, i) => (
											<TotalRadio
												name={'file-' + el.docId}
												label={el.fileName}
												value={el.path}
												key={i}
											/>
										))}
								</InputRadio>
							</Scrollbars>
						</div>
						<div className={classes.validateGroup}>
							<FormButtonIcon classes={{ root: classes.buttonValidate }} type="submit" />
							<Button
								className={classes.buttonValidate}
								onClick={(e) => OtherFilesInformationsForm.open()}
							>
								<AddCircle className={classes.addIcon} />
							</Button>
						</div>
					</Form>
				)}
			</SlidingForm>
		</>
	)
}

export default CheckListForm
