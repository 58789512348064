/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import { Button, Stepper, StepConnector, Typography, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
	},
	hidden: {
		display: 'none',
	},
	content: {
		display: 'block',
		width: '100%',
	},
	step: {},
	completedIcon: { color: theme.palette.primary.main },
	BasicIcon: {},
	show: {
		display: 'initial',
	},
	stepper: {
		backgroundColor: 'rgba(0,0,0,0)',
		paddingBottom: 0,
	},
	connector: {
		borderColor: 'rgba(255,255,255,0.3)',
	},
	StepCompleted: {
		color: '#72B84D',
		paddingLeft: 15,
		paddingRight: 15,
		fontWeight: 'bold',
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

function LinearVerticalStepper({
	contentStyle,
	buttonsStyle,
	initialValues = {},
	result,
	form = false,
	children,
	isLoading = false,
}) {
	const classes = useStyles()
	const [step, setStep] = useState(0)
	const [completedSteps, setCompletedSteps] = useState([])
	const count = children.length
	const formRef = useRef()
	const [data, setData] = useState({})
	const { t } = useTranslation('user')

	const isCompleted = (step1) => {
		let found = completedSteps.find((element) => element === step1)
		return found !== undefined
	}

	useEffect(() => {
		if (result && completedSteps.length >= count) result(data)
	}, [data, completedSteps.length, count])

	const isLast = () => step + 1 >= count

	const isFirst = () => step - 1 < 0

	const complete = () => {
		if (!isCompleted(step)) setCompletedSteps([...completedSteps, step])
	}

	const uncompleteByStep = (completedStep) => {
		setCompletedSteps(completedSteps.filter((stp) => stp !== completedStep))
	}

	const uncomplete = () => {
		setCompletedSteps(completedSteps.filter((stp) => stp !== step))
	}

	const nextStep = () => {
		if (isLast()) return
		setStep(step + 1)
	}

	const previousStep = () => {
		if (isFirst()) return
		setStep(step - 1)
	}

	const changeEvent = (e) => {
		uncomplete()
	}

	const saveData = (dt) => {
		setData({ ...data, ...dt })
	}

	const submit = (values) => {
		if (!children[step].props.validate || children[step].props.validate(values)) {
			complete()
			saveData(values)
			nextStep()
		}
	}

	const IsCompletedStep = (stp) => {
		return isCompleted(stp) ? (
			<Typography className={classes.StepCompleted}>{t('AlreadyCompleteStep')}</Typography>
		) : (
			t('completeStep')
		)
	}

	const getClassIsCompleted = () => {
		return isCompleted(step) ? '' : buttonsStyle.completed
	}

	const buttons = (
		<div className={buttonsStyle.root} key={step}>
			{isLoading && (
				<Button className={buttonsStyle.completed} disabled={true}>
					<CircularProgress size={24} className={classes.buttonProgress} />
					{t('inProgress')}
				</Button>
			)}
			{!isLoading && (
				<>
					{!isFirst() && (
						<Button
							className={buttonsStyle.completed}
							onClick={() => {
								uncompleteByStep(step)
								if (!isFirst()) previousStep()
							}}
						>
							{t('previous')}
						</Button>
					)}
					{isCompleted(step) && (
						<Button
							className={buttonsStyle.completed}
							onClick={() => {
								nextStep()
							}}
						>
							{t('next')}
						</Button>
					)}
					{!isLast() ? (
						<Button
							className={getClassIsCompleted()}
							disabled={isCompleted(step)}
							onClick={() => formRef.current.click()}
						>
							<IsCompletedStep />
						</Button>
					) : (
						<Button
							className={buttonsStyle.finished}
							disabled={isCompleted(step)}
							onClick={() => formRef.current.click()}
						>
							{t('finish')}
						</Button>
					)}
				</>
			)}
		</div>
	)

	const connector = (
		<StepConnector
			classes={{
				line: classes.connectorLine,
			}}
		/>
	)

	return (
		<div className={classes.root}>
			<Form onSubmit={submit} onChange={changeEvent} initialValues={initialValues}>
				<Stepper
					orientation="vertical"
					connector={connector}
					classes={{
						root: classes.stepper,
					}}
					activeStep={step}
				>
					{children.map((child, i) => {
						const Step = child.type
						const props = child.props
						const chldr = [...props.children, buttons]
						return (
							<Step completed={isCompleted(i)} key={i} {...props}>
								{chldr}
							</Step>
						)
					})}
				</Stepper>
				<input ref={formRef} type="submit" className={classes.hidden} />
			</Form>
		</div>
	)
}
LinearVerticalStepper.propTypes = {
	contentStyle: PropTypes.object,
	buttonsStyle: PropTypes.object,
	initialValues: PropTypes.object,
	result: PropTypes.func,
	children: PropTypes.array,
	form: PropTypes.object,
	isLoading: PropTypes.bool,
}

export default LinearVerticalStepper
