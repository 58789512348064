import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { createDWPCorrespondent, deleteDWPCorrespondent } from '../../containers/DataContainer'
import { required } from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormButtonIconDelete from '../Form/FormButtonIconDelete'
import FormIcon from '../Form/FormIcon'
import InputCheckbox from '../Form/input/InputCheckbox'
import InputMail from '../Form/input/InputMail'
import InputText from '../Form/input/InputText'
import SlidingForm from '../Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'

// STYLES
const useStyles = makeStyles(() => ({
	input: {
		marginTop: '10px',
		width: '80%',
	},
	mailInput: {
		marginTop: '10px',
		width: '80%',
		marginBottom: 20,
	},
	checkbox: {
		display: 'inline-block !important',
	},
	helpertext: {
		color: '#E10032',
		position: 'absolute',
		transform: 'translateY(-15px) translateX(15px)',
	},
	validateGroup: {
		display: 'flex',
	},
}))

const DWDCorrespondentForm = ({ data, open, close }) => {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [isDelete, setIsDelete] = useState(false)
	const [defaultValues, setDefaultValues] = useState({
		preparerPermit: false,
		adminEE: false,
		acceptorPermit: false,
	})
	const [showDeleteButton, setShowDeleteButton] = useState(false)

	useEffect(() => {
		if (data.data) {
			if (Object.keys(data.data).length > 0 && isDelete === false) {
				let contactValues = {
					lastname: data.data.lastName,
					firstname: data.data.firstName,
					email: data.data.email,
					igg: data.data.igg,
					preparerPermit: data.data.preparerPermit,
					adminEE: data.data.isCompanyAdmin,
					acceptorPermit: data.data.isPermitAcceptator,
				}
				setDefaultValues(contactValues)
			}
		} else {
			setDefaultValues({})
		}
	}, [data, isDelete])

	useEffect(() => {
		if (data.data) {
			if (Object.keys(data.data).length !== 0) setShowDeleteButton(true)
		} else setShowDeleteButton(false)
		if (data !== undefined) {
			setIsDelete(false)
		}
	}, [data])

	const onSubmit = (values) => {
		let correspondentData = {
			dwpCorrespondent: {
				lastName: values.lastname,
				firstname: values.firstname,
				igg: values.igg,
				email: values.email,
				isPermitPreparator: values.preparerPermit,
				isPermitAcceptator: values.acceptorPermit,
				isCompanyAdmin: values.adminEE,
			},
			platformId: cookies[PLATFORM_ID_COOKIE],
		}
		if (data.data) {
			correspondentData.dwpCorrespondent = {
				...correspondentData.dwpCorrespondent,
				id: data.data.id,
			}
		}
		createDWPCorrespondent(correspondentData, data.companyID, function (res, error) {
			if (res) {
				if (data.data.id !== undefined) {
					enqueueSnackbar(t('TheCorrespondentHasBeenUpdated'), { variant: 'success' })
				} else {
					enqueueSnackbar(t('TheCorrespondentHasBeenCreated'), { variant: 'success' })
				}
				data.updateList(res.data.dwpCorrespondents)
				data.changeState()
				close()
			}
			if (error) {
				if (error.data.errors['DwpCorrespondent.IGG']) {
					enqueueSnackbar(t('error') + ` : ${error.data.errors['DwpCorrespondent.IGG'][0]}`, {
						variant: 'error',
					})
					return
				}
				if (error.data.errors['DwpCorrespondent.Email']) {
					enqueueSnackbar(t('error') + ` : ${error.data.errors['DwpCorrespondent.Email'][0]}`, {
						variant: 'error',
					})
					return
				}
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
			}
		})
	}

	const RemoveContact = () => {
		if (data.data.id) {
			deleteDWPCorrespondent(data.data.id, data.companyID, function (res, error) {
				if (res) {
					setIsDelete(true)
					data.changeState()
					close()
					enqueueSnackbar(t('TheCorrespondentHasBeenDeleted'), { variant: 'success' })
				}
				if (error) {
					enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				}
			})
		}
		close()
	}

	const validate = (values) => {
		const errors = {}
		if (
			values.preparerPermit === false &&
			values.adminEE === false &&
			values.acceptorPermit === false
		) {
			errors.preparerPermit = t('CheckAtLeastOneRole')
			errors.adminEE = t('CheckAtLeastOneRole')
			errors.acceptorPermit = t('CheckAtLeastOneRole')
		}
		return errors
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('DWDCorrespondent')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							data.changeState()
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				data.changeState()
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={defaultValues} reset={true} validate={validate}>
				<InputText
					label={t('lastname')}
					name="lastname"
					className={classes.input}
					validate={required}
				/>
				<InputText
					label={t('firstname')}
					name="firstname"
					className={classes.input}
					validate={required}
				/>
				<InputText label={t('IGG')} name="igg" className={classes.input} validate={required} />
				<InputMail
					label={t('email')}
					name="email"
					classes={{ root: classes.mailInput }}
					validate={required}
				/>
				<InputCheckbox
					name="preparerPermit"
					label={t('PreparerPermit')}
					classes={{ root: classes.checkbox }}
					helperStyle={classes.helpertext}
				/>
				<InputCheckbox
					name="adminEE"
					label={t('AdminEE')}
					helperStyle={classes.helpertext}
					classes={{ root: classes.checkbox }}
				/>
				<InputCheckbox
					name="acceptorPermit"
					label={t('AcceptorPermit')}
					helperStyle={classes.helpertext}
					classes={{ root: classes.checkbox }}
				/>
				<div className={classes.validateGroup}>
					{showDeleteButton && (
						<FormButtonIconDelete
							classes={{ root: classes.deleteButton }}
							onClick={RemoveContact}
						/>
					)}
					<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
				</div>
			</Form>
		</SlidingForm>
	)
}
DWDCorrespondentForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default DWDCorrespondentForm
