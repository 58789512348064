import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import SignIn from './pages/SignIn/SignIn'
import SignUp from './pages/SignUp/SignUp'
import Dashboard from './pages/Dashboard/'
import Contacts from './pages/Contacts'
import Overview from './pages/Overview'
import Users from './pages/Users'
import ChemicalProducts from './pages/ChemicalProducts'
import { LogoutRoute, LoginRoute } from './containers/AuthContainer'
import MainLayout from './layouts/MainLayout'
import RegisterLayout from './layouts/RegisterLayout'
import OpeningRequests from './pages/OpeningRequests'
import OperationalInfos from './pages/OperationalInfos/OperationalInfos'
import EnvironmentalAspect from './pages/EnvironmentalAspect/EnvironmentalAspect'
import OperationnalModes from './pages/OperationnalModes/OperationnalModes'
import Attachment from './pages/Attachment/Attachment'
import DWDCorrespondent from './pages/DWDCorrespondent/DWDCorrespondent'
import OperationnalInformations from './pages/OperationnalInformations/OperationnalInformations'
import MyAccount from './pages/MyAccount/MyAccount'
import PreventionPlan from './pages/PdP/PreventionPlan'
import ROLES from './constants/roles'
import EmptyLayout from './layouts/EmptyLayout'
import FirstConnect from './pages/FirstConnect'
import FollowPreventionPlan from './pages/PdP/FollowPreventionPlan'
import CompanyRecord from './pages/CompanyRecord/CompanyRecord'
import MassNotification from './pages/MassNotification/MassNotification'
import ApprovalCompaniesRecords from './pages/CompanyRecord/ApprovalCompaniesRecords/ApprovalCompaniesRecords'
import CompanyRecordValidate from './pages/CompanyRecord/CompanyRecordValidate'
import FollowCompanyRecord from './pages/CompanyRecord/FollowCompanyRecord'
import HistorizationCompaniesRecords from './pages/CompanyRecord/HistorizationCompaniesRecords'
import Reports from './pages/Reports/Reports'
import FlowModels from './pages/FlowModel/FlowModel'
import AlternatesManagement from './pages/AlternatesManagement/AlternatesManagement'
import ActivityPhasesManagement from './pages/ActivityPhasesManagement/ActivityPhasesManagement'
import ReferenceDocumentManagement from './pages/ReferenceDocumentManagement/ReferenceDocumentManagement'
import EditReferenceDocument from './pages/ReferenceDocumentManagement/EditReferenceDocument'
import MailCommunication from './pages/MailCommunication/MailCommunication'
import HygienistNotification from './pages/HygienistNotification/HygienistNotification'
import ChecklistCompanyRecord from './pages/ChecklistCompanyRecord/ChecklistCompanyRecord'
import SectorsUnitsManagement from './pages/SectorsUnitsManagement/SectorsUnitsManagement'
import SpecialsTreatments from './pages/SpecialsTreatments/SpecialsTreatments'
import SiretChangement from './pages/SpecialsTreatments/SiretChangement/SiretChangement'
import PlatformConfiguration from './pages/PlatformConfiguration/PlatformConfiguration'
import RefusalMessageCompanyRecord from './pages/RefusalMessageCompanyRecord/RefusalMessageCompanyRecord'
import PdpList from './pages/Reports/PdpList/PdpList'
import PDPContacts from './pages/SpecialsTreatments/PDPContacts/PDPContacts'

const HCOk = () => {
	return 'All is ok'
}

const Routes = () => (
	<BrowserRouter>
		<Switch>
			<Route exact path="/health-check" component={HCOk} />

			{/* LOGOUT */}
			<LogoutRoute exact path="/firstConnect" layout={EmptyLayout} page={FirstConnect} />
			<LogoutRoute exact path="/externalCompany" layout={EmptyLayout} page={FirstConnect} />
			<LogoutRoute exact path="/" layout={SignIn} page={() => <></>} />
			<LogoutRoute exact path="/sign-up" layout={SignUp} page={() => <></>} />
			<LogoutRoute exact path="/register" layout={RegisterLayout} page={Overview} />

			{/* LOGIN */}
			<LoginRoute exact path="/dashboard" layout={MainLayout} page={Dashboard} />
			<LoginRoute
				exact
				path="/chemical/:companyId"
				layout={MainLayout}
				page={(props) => <ChemicalProducts {...props} />}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/chemical"
				layout={MainLayout}
				page={() => <ChemicalProducts />}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/OperationnalInformations/:companyId"
				layout={MainLayout}
				page={(props) => <OperationnalInformations {...props} />}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/OperationnalInformations/"
				layout={MainLayout}
				page={() => <OperationnalInformations />}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/openingRequest"
				layout={MainLayout}
				page={OpeningRequests}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/users"
				layout={MainLayout}
				page={Users}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL]}
			/>
			<LoginRoute
				exact
				path="/contacts/:companyId"
				layout={MainLayout}
				page={Contacts}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/contacts"
				layout={MainLayout}
				page={Contacts}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/DWPCorrespondent/:companyId"
				layout={MainLayout}
				page={DWDCorrespondent}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/DWPCorrespondent"
				layout={MainLayout}
				page={DWDCorrespondent}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/op-infos/:companyId"
				layout={MainLayout}
				page={OperationalInfos}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/op-infos"
				layout={MainLayout}
				page={OperationalInfos}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/environmentalAspects/:companyId"
				layout={MainLayout}
				page={EnvironmentalAspect}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/environmentalAspects"
				layout={MainLayout}
				page={EnvironmentalAspect}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/attachment/:companyId"
				layout={MainLayout}
				page={Attachment}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/attachment"
				layout={MainLayout}
				page={Attachment}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>
			<LoginRoute
				exact
				path="/myAccount/:accountId"
				layout={MainLayout}
				page={MyAccount}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute exact path="/myAccount" layout={MainLayout} page={MyAccount} />
			<LoginRoute
				exact
				path="/operationnalModes/:companyId"
				layout={MainLayout}
				page={OperationnalModes}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			<LoginRoute
				exact
				path="/operationnalModes"
				layout={MainLayout}
				page={OperationnalModes}
				accept={[ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
			/>

			{/* PDP */}

			<LoginRoute exact path="/preventionPlan" layout={MainLayout} page={PreventionPlan} />
			<LoginRoute
				exact
				path="/preventionPlan/:pdpId/:revisionId"
				layout={MainLayout}
				page={(props) => <PreventionPlan {...props} />}
			/>
			<LoginRoute exact path="/followPdp" layout={MainLayout} page={FollowPreventionPlan} />

			{/* PDP END */}

			{/* DE */}

			<LoginRoute
				exact
				path="/CompanyRecordValidate"
				layout={MainLayout}
				page={CompanyRecordValidate}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL]}
			/>
			<LoginRoute
				exact
				path="/CompanyRecordValidate/:platformId/:pdpId/:revisionNumber/:companyId/:companyRecordId"
				layout={MainLayout}
				page={CompanyRecordValidate}
			/>
			<LoginRoute exact path="/companyRecord/" layout={MainLayout} page={CompanyRecord} />
			<LoginRoute exact path="/followCR" layout={MainLayout} page={FollowCompanyRecord} />
			<LoginRoute
				exact
				path="/companyRecord/:platformId/:companyId/:companyRecordId"
				layout={MainLayout}
				page={(props) => <CompanyRecord {...props} />}
			/>
			<LoginRoute
				exact
				path="/createCompanyRecord/:sousTraitant/:pdpId/:pdpRevisionId/:companyId"
				layout={MainLayout}
				page={(props) => <CompanyRecord {...props} />}
			/>
			<LoginRoute exact path="/massNotification" layout={MainLayout} page={MassNotification} />
			<LoginRoute
				exact
				path="/ApprovalCompaniesRecords/:platformId"
				layout={MainLayout}
				page={ApprovalCompaniesRecords}
			/>
			<LoginRoute
				exact
				path="/HistorizationCompaniesRecords"
				layout={MainLayout}
				page={HistorizationCompaniesRecords}
			/>

			{/* DE END */}

			{/* REPORTS */}
			<LoginRoute
				exact
				path="/reports"
				layout={MainLayout}
				page={Reports}
				accept={[
					ROLES.ADMIN,
					ROLES.PRINCIPAL,
					ROLES.PLATFORMMANAGER,
					ROLES.HYGIENIST,
					ROLES.INTERNALOBS,
				]}
			/>

			<LoginRoute
				exact
				path="/reports/pdp-list"
				layout={MainLayout}
				page={PdpList}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			{/* REPORTS END */}

			{/* FLOW MODEL */}
			<LoginRoute
				exact
				path="/flowmodel"
				layout={MainLayout}
				page={FlowModels}
				accept={[ROLES.ADMIN]}
			/>
			{/* FLOW MODEL END */}

			{/* ALTERNATES MANAGEMENT */}
			<LoginRoute
				exact
				path="/alternatesmanagement"
				layout={MainLayout}
				page={AlternatesManagement}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			{/* ALTERNATES MANAGEMENT END */}

			{/* Sectors / Units Management */}
			<LoginRoute
				exact
				path="/sectors-units-management"
				layout={MainLayout}
				page={SectorsUnitsManagement}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			{/* ALTERNATES MANAGEMENT END */}

			{/* ACTIVITY PHASES MANAGEMENT */}
			<LoginRoute
				exact
				path="/activityphasesmanagement"
				layout={MainLayout}
				page={ActivityPhasesManagement}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>
			{/* ACTIVITY PHASES MANAGEMENT END */}

			{/* REFERENCE DOCUMENTS MANAGEMENT */}
			<LoginRoute
				exact
				path="/reference-documents-management"
				layout={MainLayout}
				page={ReferenceDocumentManagement}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			<LoginRoute
				exact
				path="/reference-documents-management/:action/:idrefdoc"
				layout={MainLayout}
				page={EditReferenceDocument}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* REFERENCE DOCUMENTS MANAGEMENT END */}

			{/* MAIL COMMUNICATION */}

			<LoginRoute
				exact
				path="/mail-communication"
				layout={MainLayout}
				page={MailCommunication}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* MAIL COMMUNICATION END */}

			{/* HYGIENIST NOTIFICATION */}

			<LoginRoute
				exact
				path="/hygienist-notification"
				layout={MainLayout}
				page={HygienistNotification}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* HYGIENIST NOTIFICATION END */}

			{/* CHECKLIST */}

			<LoginRoute
				exact
				path="/checklist-company-record"
				layout={MainLayout}
				page={ChecklistCompanyRecord}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* CHECKLIST END */}

			{/* SPECIALS TREATMENTS */}

			<LoginRoute
				exact
				path="/specials-treatments"
				layout={MainLayout}
				page={SpecialsTreatments}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			<LoginRoute
				exact
				path="/specials-treatments/siret-changement"
				layout={MainLayout}
				page={SiretChangement}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			<LoginRoute
				exact
				path="/specials-treatments/pdp-contacts"
				layout={MainLayout}
				page={PDPContacts}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* SPECIALS TREATMENTS END */}

			{/* PLATFORM CONFIGURATION */}

			<LoginRoute
				exact
				path="/platform-configuration"
				layout={MainLayout}
				page={PlatformConfiguration}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* PLATFORM CONFIGURATION END */}

			{/* REFUSAL MESSAGE COMPANY RECORD CONFIGURATION */}

			<LoginRoute
				exact
				path="/refusal-messages"
				layout={MainLayout}
				page={RefusalMessageCompanyRecord}
				accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}
			/>

			{/* REFUSAL MESSAGE COMPANY RECORD CONFIGURATION END */}

			{/* GENERAL */}
			<Route path="/*">
				<Redirect to="/" />
			</Route>
			{/* GENERAL END */}
		</Switch>
	</BrowserRouter>
)

export default Routes
