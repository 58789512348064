import React, { useState, useEffect, Fragment } from 'react'
import { makeStyles } from '@material-ui/styles'
import InputCheckbox from '../input/InputCheckbox'
import InputCheckboxFullPage from '../inputFullPage/InputCheckbox'

// STYLES
const useStyles = makeStyles(theme => ({
	checkBoxRoot: {
		marginLeft: '30px',
		maxHeight: '18px',
	},
}))

function InputCheckboxGroup({
	name,
	label,
	fullPage,
	readOnly,
	setValueCheckbox,
	valueCheckbox,
	children,
	...props
}) {
	// STYLES
	const classes = useStyles()

	//useState used to manage the visual of the checkboxs
	const [checkboxs, setCheckboxs] = useState([])

	const [mainCheckboxIndeterminate, setMainCheckboxIndeterminate] = useState(false)

	const [mainCheckboxChecked, setMainCheckboxChecked] = useState(false)

	useEffect(() => {
		let tempChecked = []
		let isPresent = false
		//Loop in initial values to initialize the visual of checkboxs list
		children.forEach((elem, i) => {
			valueCheckbox.forEach(initialCheckbox => {
				if (initialCheckbox.id === elem.key && initialCheckbox.checked) {
					tempChecked = [...tempChecked, { id: elem.key, checked: true }]
					isPresent = true
				}
			})
			if (isPresent) {
				isPresent = false
				return
			}
			tempChecked = [...tempChecked, { id: elem.key, checked: false }]
		})
		setCheckboxs(tempChecked)
	}, [valueCheckbox])

	useEffect(() => {
		let countTrue = 0
		checkboxs.forEach(element => {
			if (element.checked) {
				countTrue = countTrue + 1
			}
		})
		if (countTrue !== 0) setMainCheckboxIndeterminate(true)
		if (countTrue === checkboxs.length) {
			setMainCheckboxIndeterminate(false)
			setMainCheckboxChecked(true)
		}
		if (countTrue === 0) {
			setMainCheckboxIndeterminate(false)
			setMainCheckboxChecked(false)
		}
	}, [checkboxs])

	const onClick = i => {
		setCheckboxs(
			checkboxs.map(child => {
				if (child.id === i) return { id: child.id, checked: !child.checked }
				return child
			}),
		)
		// Update list of values with the value of all checkboxs
		let temp = valueCheckbox
		let isPresent = false
		for(const child of checkboxs) {
			isPresent = false
			temp.forEach((tempValue, key) => {
				if (child.id === tempValue.id && child.id === i) {
					temp[key] = { id: child.id, checked: !child.checked }
					isPresent = true
				}
			})
			if (!isPresent && child.id === i) {
				temp = [...temp, { id: child.id, checked: !child.checked }]
			}
		}
		setValueCheckbox(temp)
	}

	//Check if a checkbox is check or not. Use to manage the visual of one checkbox
	const isChecked = i => {
		let temp = {}
		checkboxs.forEach((elem, key) => {
			if (elem.id === i) {
				temp = { id: key, checked: elem.checked }
			}
		})
		if (temp.checked) return checkboxs[temp.id].checked
		return false
	}

	const mainCheckboxState = event => {
		if (event.target.checked) {
			//Update visual of a group of checkboxs
			setCheckboxs(checkboxs.map(child => ({ id: child.id, checked: true })))
			//Update the list of the values of all checkboxs
			let temp = valueCheckbox
			let isPresent = false
			checkboxs.forEach(child => {
				isPresent = false
				temp.forEach((tempValue, key) => {
					if (child.id === tempValue.id) {
						temp[key] = { id: child.id, checked: true }
						isPresent = true
					}
				})
				if (!isPresent) {
					temp = [...temp, { id: child.id, checked: true }]
				}
			})
			setValueCheckbox([...temp])
		} else {
			//Update visual of a group of checkboxs
			setCheckboxs(checkboxs.map(child => ({ id: child.id, checked: false })))
			//Update the list of the values of all checkboxs
			let temp = valueCheckbox
			let isPresent = false
			checkboxs.forEach(child => {
				isPresent = false
				temp.forEach((tempValue, key) => {
					if (child.id === tempValue.id) {
						temp[key] = { id: child.id, checked: false }
						isPresent = true
					}
				})
				if (!isPresent) {
					temp = [...temp, { id: child.id, checked: false }]
				}
			})
			setValueCheckbox([...temp])
		}
	}

	const MainInputCheckboxFullPage = () => {
		return (
			<InputCheckboxFullPage
				label={label}
				name={name}
				onClick={mainCheckboxState}
				indeterminate={mainCheckboxIndeterminate}
				checked={mainCheckboxChecked}
				key={name}
				readOnly={readOnly}
			/>
		)
	}

	const MainInputCheckboxForm = () => {
		return (
			<InputCheckbox
				label={label}
				name={name}
				onClick={mainCheckboxState}
				indeterminate={mainCheckboxIndeterminate}
				checked={mainCheckboxChecked}
				key={name}
			/>
		)
	}

	const CheckboxFullPage = ({ checkbox }) => {
		return (
			<InputCheckboxFullPage
				key={checkbox.key}
				label={checkbox.props.label}
				name={checkbox.props.name}
				onClick={() => {
					onClick(checkbox.key)
				}}
				checked={isChecked(checkbox.key)}
				classes={{ root: classes.checkBoxRoot }}
				readOnly={checkbox.props.readOnly}
			/>
		)
	}

	const CheckboxForm = ({ checkbox }) => {
		return (
			<InputCheckbox
				key={checkbox.key}
				label={checkbox.props.label}
				name={checkbox.props.name}
				onClick={e => onClick(checkbox.key)}
				checked={isChecked(checkbox.key)}
				classes={{ root: classes.checkBoxRoot }}
			/>
		)
	}

	const MapChildren = ({ children }) => {
		return (
			<>
				{children.map((child, i) => {
					return (
						<Fragment key={child.key}>
							{fullPage ? (
								<CheckboxFullPage checkbox={child}></CheckboxFullPage>
							) : (
								<CheckboxForm checkbox={child}></CheckboxForm>
							)}
						</Fragment>
					)
				})}
			</>
		)
	}

	const isChildArray = children ? Array.isArray(children) : false
	return (
		<>
			{fullPage ? <MainInputCheckboxFullPage /> : <MainInputCheckboxForm />}
			{isChildArray && children ? (
				<MapChildren>{children}</MapChildren>
			) : fullPage ? (
				<CheckboxFullPage checkbox={children}></CheckboxFullPage>
			) : (
				<CheckboxForm checkbox={children}></CheckboxForm>
			)}
		</>
	)
}

export default InputCheckboxGroup
