import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { getCompanyPrincipalForSubcontractor } from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import { TotalRadio } from '../../Form/input/InputRadio'
import InputRadio from '../../Form/inputFullPage/InputRadio'
import SlidingForm from '../../Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	text: {
		marginTop: theme.spacing.unit,
	},
}))

/**
 * Shows a form to add a chemical product to the company file
 * @returns {JSX.Element} React stack to render
 */
function AddCRForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [cookies] = useCookies()

	const [redirect, setRedirect] = useState(false)

	const getListPrincipal = () => {
		getCompanyPrincipalForSubcontractor(
			cookies[PLATFORM_ID_COOKIE],
			data.entId,
			data.id,
			(_res, err) => {
				if (err) console.error(err)
			},
		)
	}

	useEffect(() => {
		if (!open) return
		getListPrincipal()
	}, [open])

	const onSubmit = (values) => {
		if (values.createDE === 'no') setRedirect(true)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('createDE')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			{redirect && (
				<Redirect
					to={'/createCompanyRecord/1/' + data.id + '/' + data.revisionId + '/' + data.entId}
				/>
			)}
			<Form onSubmit={onSubmit}>
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('subcontractingBlaBla')}
				</Typography>
				<br />
				<InputRadio name="createDE">
					<TotalRadio label={t('yesIWant')} value="yes" />
					<TotalRadio label={t('noIDontWant')} value="no" />
				</InputRadio>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

AddCRForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default AddCRForm
