import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import InputTextArea from '../../Form/inputFullPage/InputTextArea'
import { Typography, Grid, Button, FormControl, TextField, Paper, Fab } from '@material-ui/core'
import { TotalRadio } from '../../Form/inputFullPage/InputRadio'
import InputDate from '../../Form/inputFullPage/InputDate'
import InputSelect from '../../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import classNames from 'classnames'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import InputYear from '../../Form/inputFullPage/InputYear'
import { Field } from 'react-final-form'
import { useCookies } from 'react-cookie'
import moment from 'moment'
import { typePdP, statePdP } from '../../../pages/PdP/PreventionPlan'
import { useSnackbar } from 'notistack'
import { Redirect } from 'react-router-dom'
import InputCheckbox from '../../Form/inputFullPage/InputCheckbox'
import api from '../../../utils/Requester'
import {
	PDPSUGGESTION,
	GENERALINFOS,
	ACCOUNTBYTYPEPLATFORM,
	PDPWORKFLOW,
	SETGENERALINFOS,
} from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import { PLATFORM_ID_COOKIE, ACCOUNT_TYPES_COOKIE } from '../../../constants/cookies'
import InputDateTime from '../../Form/inputFullPage/InputDateTime'

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	body: {
		fontSize: '1em',
		padding: 15,
		color: theme.palette.secondary.main,
		paddingLeft: 0,
	},
	dateText: {
		display: 'inline-block',
		verticalAlign: 'bottom',
		paddintTop: 0,
		paddingBottom: 0,
	},
	titleh5: {
		fontSize: '1.5em',
		fontWeight: 500,
		margin: 5,
		color: theme.palette.secondary.main,
	},
	divDE: {
		height: '100%',
		padding: theme.spacing.unit * 2,
		color: theme.palette.secondary.main,
		borderRadius: 5,
		[theme.breakpoints.down('sm')]: {
			marginTop: 25,
		},
	},
	date: {
		display: 'inline-block',
		verticalAlign: 'bottom',
		width: 155,
		marginRight: 5,
	},
	inputSelect: {
		margin: '2%',
	},
	bodyDE: {
		display: 'inline-block',
		width: '30%',
		paddingLeft: 5,
	},
	type: {
		display: 'inline-block',
		marginLeft: 'auto',
	},
	flexBox: {
		display: 'flex',
	},
	textArea: {
		marginTop: 20,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	dataGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: 'inline-block',
		borderRadius: 5,
		marginTop: 15,
		marginRight: 10,
		paddingBottom: 10,
	},
	categoryTitle: {
		backgroundColor: theme.palette.primary.main,
		transform: 'translateY(-18px)',
		position: 'absolute',
		padding: 8,
		paddingBottom: 0,
		zIndex: 1,
		paddingTop: 0,
		color: theme.palette.secondary.main,
	},
	inlineBlock: {
		display: 'inline-block',
	},
	inspection: {
		marginTop: 10,
	},
	radio: {
		color: theme.palette.secondary.main,
	},
	pdpNumber: {
		paddingRight: 5,
	},
	inputYear: {
		transform: 'translateY(-2.5px)',
		width: 60,
		cursor: 'pointer',
	},
	numberPDP: {
		width: 45,
		transform: 'translateY(-5px)',
	},
	numberInput: {
		color: theme.palette.secondary.main,
		paddingBottom: 4,
	},
	addButton: {
		position: 'fixed',
		right: theme.spacing.unit + 10,
		bottom: theme.spacing.unit + 10,
		zIndex: 1,
	},
	pdpArret: {
		width: 120,
	},
}))

function GeneralInformationsForm({
	pdpId,
	buttonsStyle,
	setStepStatus,
	setTypePdP,
	currentPdPType,
	isReadOnly,
	setIsReadOnly,
	isNotRevisedReadOnly,
	setIsNotRevisedReadOnly,
	isNotCreate,
	setIsNotCreate,
	revisionNumber,
	setPdpId,
	setRevisionNumber,
	setPdPNumber,
	setPdpState,
	pdpState,
	nextStep,
	previousStep,
	setRedirectRevision,
	redirectRevision,
}) {
	const classes = useStyles()
	const { t, i18n } = useTranslation(['user', 'pdp'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const todayDate = moment()
	const [year, setYear] = useState(moment().format('YYYY'))
	const [pdpNumber, setPDPNumber] = useState({})
	const [pdpName, setPDPName] = useState({})
	const [pdpType, setPdpType] = useState(1)
	const [pdpNumberFormat, setpdpNumberFormat] = useState({})
	const [listDO, setListDO] = useState([])
	const [initialValues, setInitialValues] = useState({
		creation: todayDate,
		start: todayDate,
		end: todayDate,
		inspection: todayDate,
		coordinationMeetingDate: todayDate,
		type: currentPdPType.value,
		year: todayDate,
		pdpState: 0,
	})
	const [workflow, setWorkflow] = useState([])
	const [idWorkFlow, setIdWorkFlow] = useState(null)
	const [isSubmit, setIsSubmit] = useState(false)
	const [redirect, setRedirect] = useState(false)
	const [cancelDraft, setCancelDraft] = useState(false)
	const [radioDisabled, setRadioDisabled] = useState(false)
	const isAdminOrManager = cookies[ACCOUNT_TYPES_COOKIE].some(
		(ur) => ur.code === 'ADMIN' || ur.code === 'PLATFORMMANAGER',
	)

	const recoveryNumberSuggestion = (res, pdpTypeId) => {
		const { data } = res
		if (data) {
			setPDPNumber({ ...pdpNumber, [pdpTypeId]: data })
		}
	}

	const recoveryPdpNumber = (pdpType) => {
		const type = pdpType || currentPdPType
		if (!pdpNumber[type.id] || year !== moment().year())
			api
				.get(PDPSUGGESTION, {
					p_TypePdP: type.id,
					p_PlatformId: parseInt(cookies[PLATFORM_ID_COOKIE]),
					p_Year: year,
				})
				.then((res) => recoveryNumberSuggestion(res, type.id))
	}

	const recoveryGeneralInfos = (res) => {
		const { data, problem } = res
		if (problem) return

		if (data) {
			if (data.pdPState === 4) {
				setIsReadOnly(true)
				if (data.isPdPRevised) setIsNotRevisedReadOnly(true)
				else setIsNotRevisedReadOnly(false)
			}

			switch (data.pdPType) {
				case 1:
					setTypePdP(typePdP.PERMANENT)
					setPdpType(typePdP.PERMANENT)
					if (pdpId) break

					recoveryPdpNumber(typePdP.PERMANENT)
					break
				case 2:
					setTypePdP(typePdP.SPECIFIC)
					setPdpType(typePdP.SPECIFIC)
					if (pdpId) break
					recoveryPdpNumber(typePdP.SPECIFIC)
					break
				case 3:
					setTypePdP(typePdP.RESTRICTED)
					setPdpType(typePdP.RESTRICTED)
					if (pdpId) break
					recoveryPdpNumber(typePdP.RESTRICTED)
					break
				default:
					break
			}

			if (data.workflowSteps) setWorkflow(data.workflowSteps)

			let result = data

			const regexNumberFormat = /[P|R|S]-[1-2][0-9]{3}-[0-9]{3}/g
			let nameofpdp = result.pdpNumero
			if (result.rpdNumero) nameofpdp = `${nameofpdp} - ${result.rpdNumero}`
			setPDPName(nameofpdp)
			setpdpNumberFormat(regexNumberFormat.test(result.pdpNumero))

			setPdpState(data.pdPState)
			if (data.pdPState !== statePdP.DRAFT.id) setRadioDisabled(true)
			if (result.workflowSteps.length) setIdWorkFlow(result.workflowSteps[0].workflowId)
			let listWorkflow = {}
			data.workflowSteps.forEach((step) => {
				listWorkflow = {
					...listWorkflow,
					[`step#${step.stepNumber}`]: step.selectedAccountId,
				}
			})
			setPdpType(result.pdPType)
			let splitedPdpNumber = result.pdpNumero.split('-')
			setPDPNumber({ [result.pdPType]: splitedPdpNumber[2] }) // Only the two last number to complete the field "number pdp"
			setPdPNumber(result.pdpNumero) // The entire number
			setYear(splitedPdpNumber[1])
			setInitialValues({
				...initialValues,
				...listWorkflow,
				year: splitedPdpNumber[1],
				creation: result.rpdCreationDate,
				start: result.rpdDebut,
				end: result.rpdFin,
				inspection: result.rpdDateInspectionPrealable,
				coordinationMeetingDate: result.rpdDateReunionCoordination
					? result.rpdDateReunionCoordination
					: initialValues.coordinationMeetingDate,
				preInspectionPlace: result.rpdLieuInspectionPrealable,
				coordinationMeetingPlace: result.rpdLieuReunionCoordination,
				operationTitle: result.rpdLibelle,
				operationAreasDescription: result.rpdDescriptionLieux,
				pdpState: result.pdPState,
				type: result.pdPType === 1 ? 'P' : 'S',
				do: result.principalId,
				pdpArret: result.isPdPArret,
			})
		}
	}

	const recoveryAccountType = (res) => {
		const { data, problem } = res

		if (problem) return

		if (data) {
			setListDO(data)
		}
	}

	const recoveryPdpWorkflow = (res) => {
		const { data, problem } = res

		if (problem) return

		if (data) {
			setWorkflow(data)
			if (data.length) setIdWorkFlow(data[0].workflowId)
		}
	}

	const sendingGeneralInfos = (res) => {
		const { data, problem } = res
		if (problem) {
			if (data && data.errors) {
				let err = data.errors
				if (err.ValidityEndDate) {
					return enqueueSnackbar(t('error') + ' : ' + t('validityEndDate'), { variant: 'error' })
				}
				if (err.PrincipalId) {
					return enqueueSnackbar(t('error') + ' : ' + t('principalId'), { variant: 'error' })
				}
				if (err.WorkflowValidationSteps) {
					return enqueueSnackbar(t('error') + ' : ' + t('WorkflowValidationStepsError'), {
						variant: 'error',
					})
				}
			}
			enqueueSnackbar(t(`pdp:${data[0].code}`), { variant: 'error' })
		} else if (data) {
			if (!pdpId) {
				setPdpId(data.pdPId)
				setRevisionNumber(0)
				setIsSubmit(true)
				enqueueSnackbar(t('pdPHasBeenCreated'), { variant: 'info' })
			} else if (data.revisionNumber && `${data.revisionNumber}` !== revisionNumber) {
				setRevisionNumber(data.revisionNumber)
				setRedirectRevision(true)
				setIsSubmit(true)
				if (data.hasBeenReactivated) setIsReadOnly(false)
				enqueueSnackbar(t(data.hasBeenReactivated ? 'pdp:pdp_reactivated' : 'pdPHasBeenModified'), {
					variant: 'info',
				})
			} else {
				setIsNotCreate(false)
			}
			nextStep()
		}
	}

	useEffect(() => {
		if (!pdpId || !revisionNumber) return
		api
			.get(bindParams(GENERALINFOS, parseInt(cookies[PLATFORM_ID_COOKIE]), pdpId, revisionNumber))
			.then(recoveryGeneralInfos)
	}, [pdpId, revisionNumber])

	useEffect(() => {
		if (pdpId) return
		recoveryPdpNumber()
	}, [year])

	useEffect(() => {
		api
			.get(ACCOUNTBYTYPEPLATFORM, {
				p_PlatformId: parseInt(cookies[PLATFORM_ID_COOKIE]),
				p_AccountType: 3 /* DO ID*/,
			})
			.then(recoveryAccountType)

		const lang = getLang()

		if (!pdpId && !revisionNumber) getInitialWorkflow(currentPdPType.id, lang)
	}, [])

	const getLang = () => {
		let lang
		switch (i18n.language) {
			case 'fr':
				lang = 1
				break
			case 'en':
				lang = 2
				break
			default:
				lang = 1
				break
		}
		return lang
	}

	const getInitialWorkflow = (pdptype, lang) => {
		api
			.get(PDPWORKFLOW, {
				p_PlatformId: parseInt(cookies[PLATFORM_ID_COOKIE]),
				p_LangId: lang,
				p_PdPType: pdptype,
				p_DedicatedUser: true /* Only Specific Workflow */,
			})
			.then(recoveryPdpWorkflow)
	}

	const onSubmit = (values) => {
		let formattedStart = new Date(values.start)
		let formattedEnd = new Date(values.end)

		//Get the TimezoneOffset of the startTime and endTime because depending on the month it can be GMT+1 or GMT+2
		let startTimezoneOffset = formattedStart.getTimezoneOffset() * 60000
		formattedStart = new Date(formattedStart.getTime() - startTimezoneOffset)
		let endTimezoneOffset = formattedEnd.getTimezoneOffset() * 60000
		formattedEnd = new Date(formattedEnd.getTime() - endTimezoneOffset)

		formattedStart.setUTCHours(0, 0, 0)
		formattedEnd.setUTCHours(0, 0, 0)
		//Test if the date start is after the end date
		if (moment(values.start).diff(values.end, 'day') > 0) {
			enqueueSnackbar(t('error') + ' : ' + t('start_date_anterior_exclude_error'), {
				variant: 'error',
			})
			return
		}

		//Test if the inspection date is after the end date
		if (moment(values.inspection).diff(values.end, 'day') > 0) {
			enqueueSnackbar(t('error') + ' : ' + t('inpection_date_error'), { variant: 'error' })
			return
		}

		let formData = {
			number: parseInt(pdpNumber[currentPdPType.id]),
			operationTitle: values.operationTitle,
			principalId: values.do,
			pdPType: currentPdPType.id,
			operationAreasDescription: values.operationAreasDescription,
			validityStartDate: formattedStart,
			validityEndDate: formattedEnd,
			preInspectionDate: moment(values.inspection).format(),
			coordinationMeetingDate: moment(values.coordinationMeetingDate).format(),
			preInspectionPlace: values.preInspectionPlace,
			coordinationMeetingPlace: values.coordinationMeetingPlace,
			revision: parseInt(revisionNumber),
			year: parseInt(year),
			shouldValidate: !isNotCreate,
			isPdPArret: values.pdpArret,
		}
		var v_StepPrefix = 'step#'

		let workflowData = []
		workflowData = Object.keys(values)
			.filter((wf) => wf.includes(v_StepPrefix))
			.map((wf) => {
				if (!values[wf]) return 0
				let workFlowStep = {
					// Remove the prefix to get the step ID
					stepNumber: wf.replace(v_StepPrefix, ''),
					selectedAccountId: values[wf],
				}
				if (pdpId) {
					workFlowStep = {
						...workFlowStep,
						workflowId: idWorkFlow,
					}
				} else {
					workFlowStep = {
						...workFlowStep,
						workflowId: workflow[0].workflowId,
					}
				}
				return workFlowStep
			})
		if (workflowData[0] === 0) workflowData = []
		if (workflowData.length) {
			formData = {
				...formData,
				workflowValidationSteps: workflowData,
			}
		} else {
			formData = {
				...formData,
				workflowValidationSteps: [],
			}
		}

		if (pdpId) {
			formData = {
				...formData,
				id: parseInt(pdpId),
			}
		}

		api
			.post(bindParams(SETGENERALINFOS, parseInt(cookies[PLATFORM_ID_COOKIE])), formData)
			.then(sendingGeneralInfos)
	}

	const switchCheckBox = (e) => {
		const lang = getLang()
		switch (e.target.value) {
			case 'P':
				setPdpType(typePdP.PERMANENT.id)
				setTypePdP(typePdP.PERMANENT)
				recoveryPdpNumber(typePdP.PERMANENT)
				getInitialWorkflow(typePdP.PERMANENT.id, lang)
				if (pdpId) break
				break
			case 'S':
				setPdpType(typePdP.SPECIFIC.id)
				setTypePdP(typePdP.SPECIFIC)
				recoveryPdpNumber(typePdP.SPECIFIC)
				getInitialWorkflow(typePdP.SPECIFIC.id, lang)
				if (pdpId) break
				break
			default:
				break
		}
	}

	useEffect(() => {
		if (pdpId && isSubmit) {
			setRedirect(true)
			return
		}
		setRedirect(false)
	}, [pdpId, isSubmit])

	return (
		<>
			{redirect && <Redirect to={'/preventionPlan/' + pdpId + '/' + revisionNumber} />}
			{cancelDraft && <Redirect to={'/followPdp'} />}
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<Typography variant="h3" classes={{ root: classes.title }}>
					{t('GeneralInformations')}
				</Typography>
				<hr className={classes.hrRoot} />
				<br />
				<Grid container>
					<Grid container spacing={8} item style={{ marginBottom: 8 }}>
						<Grid item sm={12} md={12} lg={8}>
							<Paper className={classes.divDE}>
								<div className={classes.flexBox}>
									{pdpNumberFormat ? (
										<div className={classes.flexBox}>
											<Typography
												variant="body1"
												className={classNames(classes.body, classes.pdpNumber)}
											>
												{t('preventionPlanNo')} {currentPdPType.value} -
											</Typography>
											<InputYear
												name="year"
												classes={{ root: classes.inputYear }}
												disableLine={true}
												value={year}
												onChange={(e) => {
													setYear(e.format('YYYY'))
												}}
												readOnly={isReadOnly}
											/>
											<Typography
												variant="body1"
												className={classNames(classes.body, classes.inlineBlock)}
											>
												-
											</Typography>
											<Field
												name="numberPDP"
												render={({ input, meta }) => (
													<FormControl margin="normal" {...input} className={classes.numberPDP}>
														<TextField
															InputProps={{
																onChange: (e) => {
																	let number = e.target.value
																	if (number.length > 3) return
																	setPDPNumber({ ...pdpNumber, [pdpType]: number })
																},
																value: pdpNumber[pdpType],
																readOnly:
																	pdpState === 2 || pdpState === 3 || isReadOnly ? true : false,
																disableUnderline: true,
																inputProps: { min: 0, max: 999, className: classes.numberInput },
															}}
															{...input}
														/>
													</FormControl>
												)}
											/>
											{revisionNumber && revisionNumber > 0 && (
												<Typography variant="body1" classes={{ root: classes.body }}>
													-&nbsp;&nbsp;&nbsp;{revisionNumber}
												</Typography>
											)}
										</div>
									) : (
										<div className={classes.flexBox}>
											<Typography
												variant="body1"
												className={classNames(classes.body, classes.pdpNumber)}
											>
												{t('preventionPlanNo')} {pdpName}
											</Typography>
										</div>
									)}
									<div className={classes.type}>
										<Typography
											variant="body1"
											className={classNames(classes.body, classes.inlineBlock)}
										>
											{t('type')} :
										</Typography>
										<TotalRadio
											name="type"
											label={t('permanent')}
											value="P"
											labelPlacement="bottom"
											onClick={switchCheckBox}
											classes={{ label: classes.radio }}
											readOnly={isReadOnly || radioDisabled}
										/>
										<TotalRadio
											name="type"
											label={t('specific')}
											value="S"
											labelPlacement="bottom"
											onClick={switchCheckBox}
											classes={{ label: classes.radio }}
											readOnly={isReadOnly || radioDisabled}
										/>
									</div>
								</div>
								<div className={classes.pdpArret}>
									<InputCheckbox
										name="pdpArret"
										label={t('pdpArret')}
										readOnly={isReadOnly}
									></InputCheckbox>
								</div>
							</Paper>
						</Grid>
						<Grid item sm={12} md={12} lg={4}>
							<Paper className={classes.divDE}>
								<Typography variant="h5" classes={{ root: classes.titleh5 }}>
									{t('DEValidationChain')} :
								</Typography>

								<InputSelect
									name="do"
									label={t('principal')}
									classes={{ root: classes.inputSelect }}
									readOnly={isReadOnly}
									required
								>
									{listDO.map((contact) => {
										return (
											<SelectMenuItem value={contact.id} key={`${contact.id}`}>
												{contact.firstName} {contact.lastName}
											</SelectMenuItem>
										)
									})}
								</InputSelect>

								{workflow.map((step) => {
									return (
										<Fragment key={step.stepNumber}>
											<InputSelect
												name={`step#${step.stepNumber}`}
												label={step.name}
												key={step.stepNumber}
												classes={{ root: classes.inputSelect }}
												readOnly={isReadOnly}
											>
												{step.eligibleAccounts &&
													step.eligibleAccounts.map((account) => {
														return (
															<SelectMenuItem
																value={account.id}
																key={`${step.stepNumber}-${account.id}`}
															>
																{account.firstName} {account.lastName}
															</SelectMenuItem>
														)
													})}
											</InputSelect>
										</Fragment>
									)
								})}
							</Paper>
						</Grid>
					</Grid>
					<Grid container spacing={8} item>
						<Grid item sm={12} md={12} lg={7}>
							<Paper className={classes.divDE}>
								<Typography variant="body1" classes={{ root: classes.body }}>
									{t('creationDate')} : {moment(initialValues.creation).format('DD/MM/YYYY')}
								</Typography>
								<div className={classes.dataGroup}>
									<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
										{t('validity')}
									</Typography>
									<InputDate
										disableLine={true}
										label={t('start')}
										name="start"
										classname={classes.date}
										readOnly={isReadOnly}
									/>
									<InputDate
										disableLine={true}
										name="end"
										label={t('end')}
										classname={classes.date}
										readOnly={isNotRevisedReadOnly || (isReadOnly && !isAdminOrManager)}
										minDate={moment().format('YYYY-MM-DD')}
									/>
								</div>
								<InputTextArea
									name="operationTitle"
									label={t('operationTitle')}
									classes={{ root: classes.textArea }}
									validate={required}
									readOnly={isReadOnly}
								/>
								<InputTextArea
									name="operationAreasDescription"
									label={t('operationAreasDescription')}
									classes={{ root: classes.textArea }}
									validate={required}
									readOnly={isReadOnly}
								/>
							</Paper>
						</Grid>
						<Grid item sm={12} md={12} lg={5}>
							<Paper className={classes.divDE}>
								<Typography variant="h5" classes={{ root: classes.titleh5 }}>
									{t('pdp_prior_inspection_title')}
								</Typography>
								<InputDateTime
									label={t('pdp_prior_inspection_datetime')}
									name="inspection"
									classname={classes.date}
									readOnly={isReadOnly}
									style={{ marginLeft: 'auto' }}
								/>
								<InputTextArea
									name="preInspectionPlace"
									label={t('pdp_prior_inspection_area')}
									classes={{ root: classes.textArea }}
									validate={required}
									readOnly={isReadOnly}
								/>
								<Typography variant="h5" classes={{ root: classes.titleh5 }}>
									{t('pdp_coordination_meeting_title')}
								</Typography>
								<InputDateTime
									label={t('pdp_coordination_meeting_datetime')}
									name="coordinationMeetingDate"
									classname={classes.date}
									readOnly={isReadOnly}
									style={{ marginLeft: 'auto' }}
								/>
								<InputTextArea
									name="coordinationMeetingPlace"
									label={t('pdp_coordination_meeting_area')}
									classes={{ root: classes.textArea }}
									validate={required}
									readOnly={isReadOnly}
								/>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
				<div className={buttonsStyle.root}>
					{pdpId ? (
						<Grid container direction="row" justify="flex-end" alignItems="center">
							{/* <Grid container item xs={4} justify="center">
                <Button className={buttonsStyle.previous} onClick={previousStep}>
                  {t("previous")}
                </Button>
              </Grid> */}
							{isNotRevisedReadOnly && (
								<Grid container item xs={4} justify="center">
									<Button className={buttonsStyle.completed} onClick={nextStep}>
										{t('next')}
									</Button>
								</Grid>
							)}
							{!isNotRevisedReadOnly && (
								<Grid container item xs={4} justify="center">
									<Fab
										color="secondary"
										aria-label="add"
										variant="extended"
										className={classes.addButton}
										type="submit"
										disabled={isReadOnly && !isAdminOrManager}
									>
										{t('completeStep')}
									</Fab>
								</Grid>
							)}
						</Grid>
					) : (
						<Grid container direction="row" justify="flex-end" alignItems="center">
							<Fab
								color="secondary"
								aria-label="add"
								variant="extended"
								className={classes.addButton}
								type="submit"
								disabled={isReadOnly && !isAdminOrManager}
							>
								{t('save')}
							</Fab>
						</Grid>
					)}
				</div>
			</Form>
		</>
	)
}

export default GeneralInformationsForm
