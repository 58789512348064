/*
To use this component, you need to provide a id (unique), a state (active, validate, noData, current or currentValidate). You also need to provide a 
children, which is the title of the arrow.
*/
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Icon, Typography } from '@material-ui/core'
import getColor, { COLOR_DISABLED_ARROW } from '../../constants/colorSequence'
import ID from '../../utils/ID'

const useStyles = makeStyles(theme => ({
	labelBlock: {
		top: '0px',
		position: 'absolute',
		paddingTop: '2px',
		paddingLeft: '10px',
		width: '100%',
		height: '100%',
		display: 'flex',
	},
	arrow: {
		width: 10,
		height: '80px',
		flexGrow: '1',
		position: 'relative',
		'&:hover': {
			filter: 'drop-shadow( 2px 2px 2px rgba(0, 0, 0, .4))',
			cursor: 'pointer',
			zIndex: '10000',
		},
	},
	arrowDisabled: {
		width: 10,
		height: '80px',
		flexGrow: '1',
		position: 'relative',
		'&:hover': {
			cursor: 'not-allowed',
		},
	},
	currentArrow: {
		width: 10,
		height: '80px',
		flexGrow: '1',
		position: 'relative',
		zIndex: '999',
		filter: 'drop-shadow( 1px 5px 2px rgba(0, 0, 0, .3))',
		'&:hover': {
			filter: 'drop-shadow( 3px 6px 2px rgba(0, 0, 0, .4))',
			cursor: 'pointer',
			zIndex: '10000',
		},
	},
	labelTitleNext: {
		fontSize: '14px',
		position: 'absolute',
		overflowWrap: 'break-word',
		maxWidth: '95%',
		display: 'contents',
	},
	width: {
		width: 10,
	},
	icon: {
		top: '67%',
		left: '80%',
		position: 'absolute',
		color: theme.palette.primary.main,
	},
	svgRoot: {
		overflow: 'inherit',
	},
	label: {
		color: theme.palette.primary.main,
		marginTop: 'auto',
		marginBottom: 'auto',
		marginLeft: 'auto',
		marginRight: 'auto',
		textAlign: 'center',
	},
	label1: {
		color: theme.palette.secondary.main,
		marginTop: 'auto',
		marginBottom: 'auto',
		textAlign: 'center',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	style: {
		display: 'contents',
		textAlign: 'unset',
	},
}))

function TotalArrowStep({ index, className, state, content, changeStep }) {
	const classes = useStyles()
	const itemId = ID()

	return (
		<div
			className={classNames(
				{
					[classes.currentArrow]: state === 'current' || state === 'currentValidate',
					[classes.arrow]:
            state === 'noData' ||
            state === 'active' ||
            state === 'validate' ||
            state === 'hasData' ||
            state === 'disable',
					[classes.arrowDisabled]: state === 'disable',
				},
				className,
			)}
			onClick={changeStep}
		>
			<svg
				width="107%"
				height="100%"
				preserveAspectRatio="none"
				viewBox="0 0 100 100"
				className={classes.svgRoot}
			>
				<defs id="defs7">
					<linearGradient
						x1="0"
						y1="0"
						x2="100"
						y2="0"
						id={'SvgArrow' + itemId}
						gradientUnits="userSpaceOnUse"
					>
						{state === 'noData' && (
							<stop offset="1" stopColor={COLOR_DISABLED_ARROW} stopOpacity="1" id="stop4" />
						)}
						{state === 'disable' && (
							<stop offset="1" stopColor="#E1E5F1" stopOpacity="1" id="stop4" />
						)}
						{(state === 'active' ||
              state === 'hasData' ||
              state === 'validate' ||
              state === 'current' ||
              state === 'currentValidate') && (
							<stop offset="1" stopColor={getColor(index)} stopOpacity="1" id="stop4" />
						)}
					</linearGradient>
				</defs>
				{(state === 'noData' ||
          state === 'disable' ||
          state === 'active' ||
          state === 'validate') && (
					<polygon
						stroke="#004392"
						strokeWidth="1"
						points="95,0 100,50 95,100 0,100 5,50 0,0 "
						//id="polygon9"
						fill={'url(#SvgArrow' + itemId + ')'}
					/>
				)}
				{(state === 'current' || state === 'currentValidate' || state === 'hasData') && (
					<polygon
						stroke="#ffffff"
						strokeWidth="2"
						strokeLinejoin="round"
						points="95,0 100,50 95,100 0,100 5,50 0,0 "
						//id="polygon9"
						fill={'url(#SvgArrow' + itemId + ')'}
					/>
				)}
			</svg>
			<div className={classes.labelBlock}>
				<Typography
					classes={{
						root: state === 'noData' || state === 'disable' ? classes.label1 : classes.label,
					}}
				>
					{content}
				</Typography>
				{state === 'validate' && <Icon className={classes.icon}>check_circle_outline</Icon>}
				{state === 'currentValidate' && <Icon className={classes.icon}>check_circle_outline</Icon>}
			</div>
		</div>
	)
}

/*
function TotalArrowStep({ id, className, state,content,changeStep, children, props }) {
  const classes = useStyles();

  let color;
  switch (id) {
    case 0:
      color = "#4B96D1";
      break;
    case 1:
      color = "#F5960A";
      break;
    case 2:
      color = "#72B84D";
      break;
    case 3:
      color = "#9D2235";
      break;
    case 4:
      color = "#006946";
      break;
    case 5:
      color = "#746F66";
      break;
    case 6:
      color = "#4B96D1";
      break;
    case 7:
      color = "#F5960A";
      break;
    case 8:
      color = "#72B84D";
      break;
    default:
      color = "#9D2235";
  }

  return (
      <div
        className={classNames(
          {
            [classes.currentArrow]: state === "current" || state === "currentValidate",
            [classes.arrow]: state === "noData" || state === "active" || state === "validate" || state === "hasData" || state=== "disable",
            [classes.arrowDisabled]: state=== "disable",
          },
          className,
        )}
        onClick={(e) => {
          if (state === "disable")return;
          changeStep(id)}}
      >
        <svg
          width="107%"
          height="100%"
          preserveAspectRatio="none"
          viewBox="0 0 100 100"
          className={classes.svgRoot}
        >
          <defs id="defs7">
            <linearGradient
              x1="0"
              y1="0"
              x2="100"
              y2="0"
              id={"SvgArrow" + id}
              gradientUnits="userSpaceOnUse"
            >
              {state === "noData" && (
                <stop offset="1" stopColor="#E1E5F1" stopOpacity="1" id="stop4" />
              )}
              {state === "disable" && (
                <stop offset="1" stopColor="#E1E5F1" stopOpacity="1" id="stop4" />
              )}
              {(state === "active" ||
                state === "hasData" ||
                state === "validate" ||
                state === "current" ||
                state === "currentValidate") && (
                <stop offset="1" stopColor={color} stopOpacity="1" id="stop4" />
              )}
            </linearGradient>
          </defs>
          {(state === "noData" || state === "disable" || state === "active" || state === "validate") && (
            <polygon
              stroke="#004392"
              strokeWidth="1"
              points="95,0 100,50 95,100 0,100 5,50 0,0 "
              id="polygon9"
              fill={"url(#SvgArrow" + id + ")"}
            />
          )}
          {(state === "current" || state === "currentValidate" ||state === "hasData") && (
            <polygon
              stroke="#ffffff"
              strokeWidth="2"
              strokeLinejoin="round"
              points="95,0 100,50 95,100 0,100 5,50 0,0 "
              id="polygon9"
              fill={"url(#SvgArrow" + id + ")"}
            />
          )}
        </svg>
        <div className={classes.labelBlock}>
          <Typography classes={{ root: state === "noData" || state === "disable" ? classes.label1 : classes.label, }}>{content}</Typography>
          {state === "validate" && <Icon className={classes.icon}>check_circle_outline</Icon>}
          {state === "currentValidate" && (
            <Icon className={classes.icon}>check_circle_outline</Icon>
          )}
        </div>
      </div>
  );
}
*/

export default TotalArrowStep
