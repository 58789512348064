import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import FilterResults from 'react-filter-search'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import FormIcon from '../../../components/Form/FormIcon'
import InputCheckbox from '../../../components/Form/input/InputCheckbox'
import SlidingForm from '../../../components/Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { PDPCONTACTS } from '../../../constants/url'
import { addPdPContactWithId } from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import api from '../../../utils/Requester'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormButtonIconAdd from '../../Form/FormButtonIconAdd'
import InputSearch from '../../Form/input/InputSearch'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	scrollbars: {
		height: '63vh',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
	loadingMessage: {
		color: theme.palette.primary.main,
		fontWeight: 400,
	},
}))

/**
 * Shows a form to add a contact to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddContactForm({ open, close, data }) {
	const classes = useStyles()
	const [value, setValue] = useState('')
	const [cookies] = useCookies()
	const [listConctacts, setListConctacts] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])
	const REGEX_CONTACTS_ID = /[^0-9-]+-{1}(?<idClient>-?[0-9]+)$/

	const search = (event) => {
		setValue(event.target.value)
	}

	/**
	 * @name getContacts
	 * @description Calls the API and retrieves the contacts from the PDP
	 */
	const getContacts = () => {
		const params = {
			p_PlatformId: cookies[PLATFORM_ID_COOKIE],
			p_PdpId: data.pdpId,
			p_RevisionNumber: data.revisionNumber,
		}
		api.get(PDPCONTACTS, params).then((res) => {
			if (res.ok) setListConctacts(res.data)
		})
	}

	const onSubmit = (values) => {
		let listIdContacts = []
		Object.keys(values).forEach((id) => {
			if (!values[id]) return

			let result = REGEX_CONTACTS_ID.exec(id)
			let getId = result.groups.idClient
			listIdContacts = [...listIdContacts, getId]
		})
		addPdPContactWithId(
			cookies[PLATFORM_ID_COOKIE],
			listIdContacts,
			data.pdpId,
			data.revisionNumber,
			function (res, error) {
				if (res) {
					data.setNeedRefresh(true)
					if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
					enqueueSnackbar(t('contactAddedToPdP'), { variant: 'info' })
				}
				if (error) {
					if (error.status) {
						enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
						return
					}
					enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				}
			},
		)
		setValue('')
		close()
	}

	useEffect(() => {
		if (open) getContacts()
	}, [open])

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('contactPdP')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							setValue('')
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				setValue('')
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{}}>
				<InputSearch
					name="search"
					label={t('searchContact')}
					onChange={search}
					classes={{ root: classes.inputSearch }}
				/>
				<div className={classes.scrollbars}>
					<Scrollbars>
						<FilterResults
							value={value}
							data={listConctacts}
							renderResults={(results) => (
								<div>
									{results.map((el) => {
										return (
											<LazyLoad key={el.utlId} overflow offset={100}>
												<InputCheckbox
													name={'contact-' + el.utlId}
													label={el.utlPrenom + ' ' + el.utlNom}
													value={el.utlId}
													key={el.utlId}
												/>
											</LazyLoad>
										)
									})}
								</div>
							)}
						/>
					</Scrollbars>
				</div>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<FormButtonIconAdd
						classes={{ root: classes.validateButton }}
						onClick={composeValidators(data.openCreatePdPContact, close)}
					/>
				</div>
			</Form>
		</SlidingForm>
	)
}
AddContactForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}

export default AddContactForm
