import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import {
	Fab,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	TableRow,
	TableCell,
	CircularProgress,
	Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import GetApp from '@material-ui/icons/GetApp'
import LinkOff from '@material-ui/icons/LinkOff'
import TotalTable from '../../TotalTable/TotalTable'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.secondary.main + ' !important',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	circularProgress: {
		color: theme.palette.secondary.main,
	},
	buttonAddCertification: {
		position: 'absolute',
		right: '-33px',
		bottom: '-33px',
		zIndex: 99,
	},
	certification: {
		position: 'relative',
		minHeight: 250,
	},
}))

/**
 * Fourth step of the operational informations form
 */
function FourthStep({
	setConfirmPopUp,
	deleteDocument,
	newFile,
	confirmPopUp,
	downloadDocument,
	closePopUp,
	listFiles,
	loader,
}) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()

	/*Add button openning file form*/
	const AddFile = () => {
		return (
			<Fab className={classes.buttonAddCertification} color="secondary" onClick={newFile}>
				<AddIcon />
			</Fab>
		)
	}

	/* Confirm delete file pop-up */
	const OpenConfirmPopUp = () => {
		return (
			<Dialog
				open={confirmPopUp.open}
				onClose={closePopUp}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t('warning')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('doYouWantToDeleteThisFile')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePopUp} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteDocument(confirmPopUp.file, confirmPopUp.index)
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			<div className={classes.certification}>
				<AddFile />
				{confirmPopUp.open && <OpenConfirmPopUp />}
				<TotalTable
					tableHeadData={['date_add_or_update', 'user', 'nameOfTheFile', 'download', 'delete']}
				>
					{/*Construct Table*/}
					{listFiles !== null && listFiles.length > 0 ? (
						listFiles.map((file, i) => (
							<TableRow key={file.fileName}>
								<TableCell align="center" component="th" scope="row">
									{file.dateAdded}
								</TableCell>
								<TableCell align="center" component="th" scope="row">
									{file.username}
								</TableCell>
								<TableCell align="center" component="th" scope="row">
									{file.fileName}
								</TableCell>
								<TableCell align="center" component="th" scope="row">
									{file.path ? (
										!loader[`${i}`] ? (
											<GetApp
												classes={{ root: classes.icon }}
												onClick={() => {
													downloadDocument(file, i)
												}}
											/>
										) : (
											<CircularProgress className={classes.circularProgress} />
										)
									) : (
										<Tooltip title={t('YouHaveJustDownloadedThisFile')}>
											<LinkOff classes={{ root: classes.icon }} />
										</Tooltip>
									)}
								</TableCell>
								<TableCell align="center" component="th" scope="row">
									<Delete
										classes={{ root: classes.icon }}
										onClick={() => {
											setConfirmPopUp({ open: true, file: file, index: i })
										}}
									/>
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell />
							<TableCell align="center">
								<p>{t('YouHaveNoFiles')}</p>
							</TableCell>
							<TableCell />
						</TableRow>
					)}
				</TotalTable>
			</div>
		</>
	)
}
FourthStep.propTypes = {
	setConfirmPopUp: PropTypes.func,
	deleteDocument: PropTypes.func,
	newFile: PropTypes.func,
	confirmPopUp: PropTypes.object,
	downloadDocument: PropTypes.func,
	closePopUp: PropTypes.func,
	listFiles: PropTypes.array,
	loader: PropTypes.object,
}
export default FourthStep
