import React, { useEffect, useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import SuperCard from '../../components/Cards/SuperCard'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import { useTranslation } from 'react-i18next'
import TotalPopUpWarning from '../../components/TotalPopUpWarning/TotalPopUpWarning'
import { TotalModalPopUpContext } from '../../contexts/TotalModalPopUpContext'
import { useCookies } from 'react-cookie'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import {
	PLATFORM_CONFIGURATION_SPECIFIC,
	SUPERVISION,
	OPERA_HEALTHCHECK,
} from '../../constants/url'
import { useSnackbar } from 'notistack'
import { SUPERVISION_CODE, ITEMPERCOL } from '../../constants/supervision'
import PDPSuperCard from '../../components/Cards/PDPSuperCard'
import moment from 'moment'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import TotalLoader from '../../components/TotalLoader/TotalLoader'
import { v4 as uuid_v4 } from 'uuid'
import Button from '@material-ui/core/Button'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	gridContainer: {
		//flexGrow: 1,
		padding: theme.spacing.unit * 3,
		width: '100% !important',
		margin: '0 !important',
	},
	gridLoadMore: {
		marginBottom: 20,
		width: '100%',
		height: 100,
		justifyContent: 'center',
	},
	expand: {
		color: theme.palette.secondary.main,
	},
	loadMoreButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		height: 50,

		'&:disabled': {
			opacity: 0.5,
		},
	},
	circularProgress: {
		color: theme.palette.secondary.main + ' !important',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		marginTop: 10,
	},
	fullFlex: {
		flexBasis: '100%',
	},
}))

function Dashboard() {
	const localLang = localStorage.getItem('i18nextLng')
	const [lang, setLang] = useState()
	const classes = useStyles()
	const { setTitleBar } = useTotalTitleBarContext()
	const { t } = useTranslation(['user', 'dashboard'])
	const { typeModal, setTypeModal } = useContext(TotalModalPopUpContext)
	const [openModel, setOpenModal] = useState(false)
	const [cookies] = useCookies(['platformId'])
	const [listSupervision, setListSupervision] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const [IsLoaded, SetLoadState] = useState(false)
	const [platformConfig, setPlatformConfig] = useState([])
	const [loadingMore, setLoadingMore] = useState(false)
	const [offset, setOffset] = useState(0)
	const [hasMoreValue, setHasMoreValue] = useState(true)
	const length = 10
	const usersConnectedSince = 'UsersConnectedSince'
	const dashboardExpiringPdpDelay = 'DashboardExpiringPdpDelay'
	const [operaData, setOperaData] = useState()

	const recoverySupervisions = (res, loadMore = false) => {
		const { data, ok, originalError } = res

		if (originalError && originalError.code === 'ECONNABORTED') return

		if (!ok) {
			enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
			return
		}

		if (data) {
			if (!loadMore) {
				setListSupervision(data.elements)
			} else {
				setListSupervision([
					...listSupervision,
					...data.elements.filter((item) => item.elementCode === 'OpenedPreventionPlan'),
				])
			}

			setHasMoreValue(data.hasMoreItems)
		}
		SetLoadState(true)
		setOffset((ofs) => ofs + 10)
		setLoadingMore(false)
	}

	const recoverPlatformConfig = (res) => {
		const { data, ok, originalError } = res

		if (originalError && originalError.code === 'ECONNABORTED') return

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
		if (data) {
			const config = platformConfig
			config.push(data)
			setPlatformConfig(config)
		}
	}

	const operaDashboard = (res) => {
		const { data, ok, originalError } = res
		if (originalError && originalError.code === 'ECONNABORTED') return

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
		if (data) {
			setOperaData(data)
		}
	}

	const getSupervisions = (loadMore = false) => {
		api
			.get(bindParams(SUPERVISION, cookies[PLATFORM_ID_COOKIE]), {
				offset: offset,
				length: loadMore ? length : 10,
				loadMore: loadMore,
			})
			.then((res) => recoverySupervisions(res, loadMore))
	}

	const getPlatformConfiguration = (translationCode) => {
		api
			.get(
				bindParams(PLATFORM_CONFIGURATION_SPECIFIC, cookies[PLATFORM_ID_COOKIE], translationCode),
			)
			.then(recoverPlatformConfig)
	}

	const getOperaHealthCheck = () => {
		api.get(OPERA_HEALTHCHECK).then(operaDashboard)
	}

	/* Open a modal if the context contain a message */
	useEffect(() => {
		if (typeModal) setOpenModal(true)
	}, [typeModal])

	useEffect(() => {
		setLang(localLang)
	}, [localLang])

	useEffect(() => {
		getSupervisions()
		getPlatformConfiguration(usersConnectedSince)
		getPlatformConfiguration(dashboardExpiringPdpDelay)
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'remove_red_eye',
				title: () => t('oversight'),
				subtitle: t('WelcomeToYourPdP'),
			},
		})
		getOperaHealthCheck()
	}, [])

	const closeModal = () => {
		setOpenModal(false)
		/* Flush the context */
		setTypeModal(null)
	}

	const checkIfPdpExpiring = (endDate) => {
		const value = platformConfig.find(
			(pc) => pc.translationCode === dashboardExpiringPdpDelay,
		)?.value
		if (value) return moment(endDate) < moment().add(value, 'd')
		return false
	}

	const formatList = (data) => {
		let newData = []
		Object.keys(data).forEach((oldKey) => {
			let value = [oldKey]
			const regexResult = /^(.*?)\s*\((.*?)\)$/.exec(value)
			if (regexResult) {
				value = regexResult.slice(1)
			}
			newData.push({
				title: value[0],
				subTitle: value[1] ?? '',
				count: data[oldKey],
			})
		})
		return newData
	}

	function onLoadMore() {
		setLoadingMore(true)
		getSupervisions(true)
	}

	return (
		<>
			{!IsLoaded && <TotalLoader />}
			{IsLoaded && (
				<div>
					{typeModal && (
						<TotalPopUpWarning
							open={openModel}
							onClose={closeModal}
							content={typeModal.content}
							title={typeModal.title}
						/>
					)}
					<Grid container className={classes.gridContainer} spacing={16}>
						{listSupervision.map((supervision, i) => {
							switch (supervision.elementCode) {
								case SUPERVISION_CODE.PERFORMANCE_TRACK:
									return (
										<SuperCard
											key={uuid_v4()}
											keyCard={`OR-${i}`}
											title={t('dashboard:health.title')}
											status="incomplete"
											size={6}
											avatar="health"
											health={{
												userConnected: {
													since: platformConfig.find(
														(pc) => pc.translationCode === usersConnectedSince,
													).value,
													details: [
														{
															count: supervision.elementData.nbExternalUsers,
															title:
																supervision.elementData.nbExternalUsers > 1
																	? t('dashboard:health.userConnected.externals')
																	: t('dashboard:health.userConnected.external'),
														},
														{
															count: supervision.elementData.nbInternalUsers,
															title:
																supervision.elementData.nbInternalUsers > 1
																	? t('dashboard:health.userConnected.internals')
																	: t('dashboard:health.userConnected.internal'),
														},
													],
												},
												bddPerf: supervision.elementData.dbPerformance,
												geconex: operaData,
											}}
										/>
									)
								case SUPERVISION_CODE.EXPIRING_PDP:
									return (
										<SuperCard
											key={uuid_v4()}
											keyCard={`OR-${i}`}
											title={t('dashboard:expiring_pdp.title')}
											countTitle={t('dashboard:expiring_pdp.count_title')}
											status="refused"
											size={6}
											avatar="unsecuredShield"
											itemspercol={4}
											maxDisplay={12}
											count={supervision.elementData.count}
											list={formatList(supervision.elementData.countByPlatform)}
										/>
									)
								case SUPERVISION_CODE.OPENINGREQUEST:
									return (
										<SuperCard
											key={i}
											keyCard={`OR-${i}`}
											title={t('accountOpening')}
											countTitle={t('openingRequestToBeValidated')}
											status="approved"
											size={6}
											avatar="people"
											itemspercol={4}
											maxDisplay={12}
											count={supervision.elementData.count}
											list={formatList(supervision.elementData.countByPlatform)}
											linkRedirect={'/openingRequest'}
										/>
									)
								case SUPERVISION_CODE.COMPANYRECORDVALIDATION:
									return (
										<SuperCard
											key={i}
											keyCard={`VCF-${i}`}
											title={t('ValidationCompanyFiles')}
											subTitle={t('CompanyFilesToBeValidated')}
											countTitle={t('CompanyRecordValidation')}
											status="validated"
											size={6}
											avatar="folder"
											itemspercol={ITEMPERCOL}
											count={supervision.elementData.companyRecordNumber}
											list={formatList(supervision.elementData.companyRecordNumberByPdp)}
											linkRedirect={'/followCR'}
										/>
									)
								case SUPERVISION_CODE.OPENEDPREVENTIONPLAN:
									return (
										<PDPSuperCard
											key={i}
											keyCard={`PDP-${i}`}
											status={'toComplete'}
											title={supervision.elementData.pdpNumber}
											subTitle={bindParams(
												t('PDPValidateTo'),
												moment(supervision.elementData.pdpStartDate).lang(lang).format('L'),
												moment(supervision.elementData.pdpEndDate).lang(lang).format('L'),
											)}
											size={6}
											itemspercol={ITEMPERCOL}
											count={supervision.elementData.companyNumber}
											validCompanyRecordNumber={supervision.elementData.validCompanyRecordNumber}
											toValidateCompanyRecordNumber={
												supervision.elementData.toValidateCompanyRecordNumber
											}
											unsubmittedCompanyRecordNumber={
												supervision.elementData.unsubmittedCompanyRecordNumber
											}
											linkRedirect={`/preventionPlan/${supervision.elementData.pdpId}/${supervision.elementData.revisionNumber}`}
											pdpName={supervision.elementData.pdpName}
											principalName={supervision.elementData.principalName}
											dwpToSend={supervision.elementData.dwpToSend}
											expiring={checkIfPdpExpiring(supervision.elementData.pdpEndDate)}
										/>
									)
								default:
									return <></>
							}
						})}
					</Grid>
					{hasMoreValue && (
						<Grid container className={classes.gridLoadMore}>
							<Button
								disabled={loadingMore}
								className={classes.loadMoreButton}
								color="secondary"
								onClick={onLoadMore}
							>
								<ExpandMoreIcon className={classes.expand} />
								<span className={classes.expand}>{t('user:load_more')}</span>
							</Button>
							{loadingMore && (
								<div className={classes.fullFlex}>
									<CircularProgress className={classes.circularProgress} />
								</div>
							)}
						</Grid>
					)}
				</div>
			)}
		</>
	)
}

export default Dashboard
