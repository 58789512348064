import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Form from '../../Form/Form'
import FormIcon from '../../Form/FormIcon'
import SlidingForm from '../../Form/SlidingForm'
import FormButton from '../../Form/FormButton'
import { useTranslation } from 'react-i18next'
import { required } from '../../../utils/VerifyFields'
import api from '../../../utils/Requester'
import { ACTIVITYPHASESMANAGER } from '../../../constants/url'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { CircularProgress } from '@material-ui/core'
import { bindParams } from '../../../utils/Link'
import { InputSwitch } from '../../Form/input/InputCheckbox'
import InputText from '../../Form/input/InputText'
import InputFile from '../../Form/input/InputFile'
import { getFileToByteArray } from '../../../utils/File'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import humanFileSize from '../../../constants/coreConstants'

const useStyles = makeStyles((theme) => ({
	profile: {
		textAlign: 'left',
	},
	submit: {
		display: 'block',
		marginTop: theme.spacing.unit * 5,
		margin: 'auto',
	},
	listCheckbox: {
		maxWidth: 450,
		margin: 'auto',
		height: '55vh',
	},
	topMargin: {
		marginTop: '20px',
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

/**
 * Formular Activity Phases
 *
 * @param {object} open
 * @param {object} close
 * @param {object} data
 */
function ActivityPhasesForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'activityPhaseManagement'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [loader, setLoader] = useState(false)

	const onSubmit = (value) => {
		if (value.isForbiddenPermanent == undefined) {
			value.isForbiddenPermanent = false
		}

		if (value.isForbiddenRestricted == undefined) {
			value.isForbiddenRestricted = false
		}

		setLoader(true)
		if (file) upload(file, value)
		else editActivityPhases(value)
	}

	const editActivityPhases = (values) => {
		api.put(bindParams(ACTIVITYPHASESMANAGER, cookies[PLATFORM_ID_COOKIE]), values).then((res) => {
			setLoader(false)
			const { ok } = res
			if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

			if (data.refresh) data.refresh()
			setFile(null)
			close()
		})
	}

	useEffect(() => {}, [open])

	const upload = (file, value) => {
		const formData = new FormData()
		for (const [key, val] of Object.entries(value)) {
			formData.append(key, val)
		}

		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				formData.append('value', res, res.name)
				editActivityPhases(formData)
			} else {
				enqueueSnackbar(bindParams(t(cberror.errorCode), humanFileSize), { variant: 'error' })
			}
		})
	}

	return (
		<>
			<SlidingForm
				direction="vertical"
				open={open}
				title={t('activityPhaseManagement:activityPhasesEdition')}
				onClose={close}
				iconAttachement={
					<>
						<FormIcon icon="clear" onClick={close} />
					</>
				}
			>
				<Form onSubmit={onSubmit} initialValues={data.datas} className={classes.topMargin}>
					<InputSwitch name="isActive" label={t('activityPhaseManagement:isActive')} />
					<InputSwitch name="isRequired" label={t('activityPhaseManagement:isrequired')} />
					<InputText
						classes={{ root: classes.inputID }}
						name="phaseLabel"
						label={t('activityPhaseManagement:name')}
						validate={required}
					/>
					<InputSwitch
						name="isForbiddenPermanent"
						label={t('activityPhaseManagement:isforbiddenpermanent')}
					/>
					<InputSwitch
						name="isForbiddenRestricted"
						label={t('activityPhaseManagement:isforbiddenrestreint')}
					/>
					<InputFile label={t('upload')} name="fileActivityPhase" setFile={setFile} />

					{loader && <CircularProgress size={24} className={classes.buttonProgress} />}
					{!loader && (
						<FormButton
							classes={{
								root: classes.submit,
							}}
							type="submit"
							label={t('submit')}
						/>
					)}
				</Form>
			</SlidingForm>
		</>
	)
}

export default ActivityPhasesForm
