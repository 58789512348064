import React, { useState, useCallback, useEffect } from 'react'
import {
	Dialog,
	DialogContent,
	Button,
	Grid,
	Typography,
	CircularProgress,
	DialogTitle,
	Icon,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import InputCheckbox from '../../../../components/Form/inputFullPage/InputCheckbox'
import Form from '../../../../components/Form/Form'

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100% !important',
	},
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	button: {
		marginTop: 20,
	},
	dialog: {
		width: '500px',
	},

	checkbox: {
		display: 'inline-block',
	},
	label: {
		textAlign: 'right',
		paddingRight: 15,
	},
}))

function ModalExtraction({ isOpen, onClose, onSubmit, fileTypes }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [isLoading, setIsLoading] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [checkboxValues, setCheckboxValues] = useState({
		HeadDocument: false,
		InvitationToAttend: false,
		Annex: false,
		Endorsement: false,
	})

	const executeOnClose = useCallback(() => {
		onClose(false)
	})
	const executeOnSubmit = useCallback(async (values) => {
		setIsLoading(true)
		await onSubmit(values)
		setIsLoading(false)
	})

	const DisplayCheckbox = () => {
		const pdPFileType = {
			0: 'HeadDocument',
			1: 'InvitationToAttend',
			2: 'Annex',
			3: 'Endorsement',
		}
		return fileTypes.map((type) => (
			<Grid container justify="center" alignItems="center" key={type}>
				<Grid item xs={5} className={classes.label}>
					<span>{t(pdPFileType[type])}</span>
				</Grid>
				<Grid item xs={3}>
					<InputCheckbox
						name={pdPFileType[type]}
						helperStyle={classes.helpertext}
						classes={{ root: classes.checkbox }}
						value={checkboxValues[pdPFileType[type]]}
						onClick={(e) => {
							const newCheckboxValues = checkboxValues
							newCheckboxValues[pdPFileType[type]] = e.target.checked
							setCheckboxValues({ ...newCheckboxValues })
						}}
					/>
				</Grid>
			</Grid>
		))
	}

	useEffect(() => {
		Object.values(checkboxValues).find((value) => value) ? setDisabled(false) : setDisabled(true)
	}, [checkboxValues])

	return (
		<Dialog open={isOpen} onBackdropClick={executeOnClose} maxWidth="md">
			<DialogTitle>
				<Typography classes={{ root: classes.title }}>{t('Documents')}</Typography>
			</DialogTitle>
			<DialogContent className={classes.dialog}>
				<Form onSubmit={executeOnSubmit} className={classes.form}>
					<DisplayCheckbox />
					<Grid container justify="flex-end" alignItems="center">
						<Grid item xs={4}>
							<Button
								color="secondary"
								type={'submit'}
								variant="contained"
								className={classes.button}
								disabled={isLoading || disabled}
							>
								{isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
								<Icon>download</Icon>
								{t('generate')}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</DialogContent>
		</Dialog>
	)
}

export default ModalExtraction
