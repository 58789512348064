import React, { useEffect } from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { FormControlLabel, Checkbox, Switch, FormHelperText } from '@material-ui/core'
import { Translation } from 'react-i18next'
import classNames from 'classnames'

// STYLES
const useStyles = makeStyles(theme => ({
	label: {
		color: 'white',
		fontSize: 16,
		display: 'inline-flex',
		//maxWidth: "70%",
	},
	label2: {
		color: theme.palette.secondary.main,
		fontSize: 16,
		display: 'inline-flex',
		//maxWidth: "70%",
	},
	labelRoot: {
		display: 'block',
	},
	switchRoot: {
		width: '100px',
		color: theme.palette.primary.main,
		'&$checkboxChecked': {
			color: theme.palette.primary.main,
		},
	},
	checkboxChecked: {
		color: theme.palette.primary.main,
		'&$checkboxChecked': {
			color: theme.palette.primary.main,
		},
	},
	iconChecked: {
		color: theme.palette.primary.main,
		transform: 'translateX(52px)',
		'&:after': {
			marginLeft: '10px',
			fontSize: 22,
			// eslint-disable-next-line no-useless-concat
			content: '""' + '!important',
		},
	},
	switchAfter: {
		'&:after': {
			marginLeft: '25px',
			fontSize: 17,
			display: 'flex',
		},
	},
	switchChecked: {
		color: 'black !important',
	},
	switchBase: {
		left: '21px',
		'&$switchChecked': {
			'& + $switchBar': {
				backgroundColor: '#72B84B',
			},
		},
	},
	switchBase2: {
		left: '21px',
		'&$switchChecked': {
			'& + $switchBar': {
				backgroundColor: theme.palette.primary.primary,
			},
		},
	},
	gridSize: {
		maxHeight: '40px',
	},
	block: {
		display: 'block',
	},
	switchBar: {
		width: '90px',
		height: '24px',
		top: '40%',
		borderRadius: '25px',
	},
	helperText: {
		color: '#E10032',
		position: 'absolute',
		transform: 'translateY(-17px) translateX(17px)',
	},
}))

export function InputCheckbox({ name, label, helperStyle, labelClass = null, ...props }) {
	// STYLES
	const classes = useStyles()

	return (
		<FormControlLabel
			label={label}
			classes={{
				root: labelClass ? labelClass : classes.labelRoot,
				label: classes.label,
			}}
			color="primary"
			control={
				<Field
					name={name}
					type="checkbox"
					render={({ input, meta }) => (
						<>
							<Checkbox className={classes.checkboxChecked} {...input} {...props} value="" />
							{meta && meta.touched && meta.error && (
								<FormHelperText classes={{ root: classes.helperText }} className={helperStyle}>
									<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
								</FormHelperText>
							)}
						</>
					)}
				/>
			}
		/>
	)
}

export default InputCheckbox

export function InputSwitch({ name, label, contrast = false, ...props }) {
	// STYLES
	const classes = useStyles()
	return (
		<div className={classes.labelRoot}>
			<FormControlLabel
				label={label}
				classes={{
					label: classNames({ [classes.label]: !contrast, [classes.label2]: contrast }),
				}}
				labelPlacement="start"
				color="primary"
				control={
					<Field
						name={name}
						type="checkbox"
						render={({ input, meta }) => (
							<Switch
								classes={{
									root: classes.switchRoot,
									icon: classes.switchAfter,
									iconChecked: classes.iconChecked,
									switchBase: classNames({
										[classes.switchBase]: !contrast,
										[classes.switchBase2]: contrast,
									}),
									bar: classes.switchBar,
									checked: classes.switchChecked,
								}}
								{...props}
								{...input}
							/>
						)}
					/>
				}
			/>
		</div>
	)
}
