import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTranslation } from 'react-i18next'
import {
	Grid,
	Paper,
	Button,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Select,
	Switch,
	Checkbox,
	MenuItem,
	Fab,
	Input,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import * as link from '../../constants/url'
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded'
import red from '@material-ui/core/colors/red'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import AddRoundedIcon from '@material-ui/icons/AddRounded'
import { useSnackbar } from 'notistack'
import { LANGUAGES } from '../../constants/flowmodel'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing.unit,
		paddingBottom: theme.spacing.unit * 10,
	},
	inputSelect: {
		margin: '2%',
	},
	div: {
		padding: theme.spacing.unit * 2,
		color: theme.palette.secondary.main,
		borderRadius: 5,
		overflowX: 'auto',
		width: '100%',
		[theme.breakpoints.down('sm')]: {
			marginTop: 25,
		},
	},
	selected: {
		backgroundColor: '#000',
	},
	marginUpDown: {
		marginTop: '20px',
		marginBottom: '20px',
	},
	marginFabButtons: {
		marginRight: '5px',
		marginLeft: '5px',
	},
	marginAll: {
		marginTop: '15px',
		marginBottom: '15px',
		marginRight: '20px',
		marginLeft: '20px',
	},
	icon: {
		margin: theme.spacing.unit,
		fontSize: 24,
	},
	danger: {
		backgroundColor: red[900],
		color: theme.palette.grey[50],
	},
	table: {
		minWidth: 650,
	},
}))

function FlowModels() {
	const localLang = localStorage.getItem('i18nextLng')
	const { t } = useTranslation(['user', 'tools', 'translation', 'flowmodel'])
	const [cookies] = useCookies(['platformId'])
	const classes = useStyles()
	const [listPDPType, setListPDPType] = useState([])
	const [listWorkflow, setWorkflow] = useState([])
	const [listAccountType, setListAccountTypes] = useState([])
	const [TypeOfPdp, setTypeOfPdp] = useState(2)
	const [lineSelected, setLineSelected] = useState(null)
	const [switches, setSwitches] = useState([])
	const [lang, setLang] = useState()
	const { enqueueSnackbar } = useSnackbar()
	const [readOnly, setReadOnly] = useState(false)

	//#region CONSTANTS
	const STEPS = [
		{
			label: t('OperationDescription'),
			code: 1,
		},
		{
			label: t('SubContractingCompanies'),
			code: 2,
		},
		{
			label: t('HygieneAndHealth'),
			code: 3,
		},
		{
			label: t('DangerActivityPhases'),
			code: 4,
		},
		{
			label: t('ChemicalProducts'),
			code: 5,
		},
		{
			label: t('EnvironmentalAspects'),
			code: 6,
		},
		{
			label: t('OperatingModes'),
			code: 7,
		},
	]
	const EXCLUDE_ACCOUNT_TYPE = ['EXTCOMPADMIN', 'EXTCOMP', 'INTERNALOBS', 'EXTERNALOBS']
	//#endregion CONSTANTS

	//#region USEEFFECTS
	useEffect(() => {
		if (lang) {
			getAccountType()
			getPDPType()
		}
	}, [lang])
	useEffect(() => {
		setLang(localLang)
	}, [localLang])
	useEffect(() => {
		getWorkflow(TypeOfPdp)
	}, [])
	useEffect(() => {
		setWorkflow([])
		setSwitches([])
		getWorkflow(TypeOfPdp)
	}, [TypeOfPdp])
	//#endregion USEEFFECTS

	//#region RECOVERY DATABASE
	const recoveryPDPType = (res) => {
		const { data, ok } = res

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

		if (data) {
			setListPDPType(data.filter((x) => x.id !== 3))
		}
	}

	const recoveryWorkflow = (res) => {
		const { data, ok } = res

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

		if (data) {
			setTheLine(data)
		}
	}

	const recoveryAccountType = (res) => {
		const { data, ok } = res

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

		if (data) {
			const liste = data.filter((d) => !EXCLUDE_ACCOUNT_TYPE.includes(d.code))
			setListAccountTypes(liste)
		}
	}
	//#endregion RECOVERY DATABASE

	//#region GET FUNCTIONS
	const getWorkflow = (p_TypeOfPdp) =>
		api
			.get(bindParams(link.WORKFLOW, cookies[PLATFORM_ID_COOKIE], p_TypeOfPdp))
			.then(recoveryWorkflow)

	const getAccountType = () =>
		api
			.get(link.GETACCOUNTTYPES, { p_LangId: LANGUAGES.find((x) => x.code === localLang).id })
			.then(recoveryAccountType)

	const getPDPType = () => {
		api
			.get(link.PDPTYPES, { p_LangId: LANGUAGES.find((x) => x.code === localLang).id })
			.then(recoveryPDPType)
	}
	//#endregion GET FUNCTIONS

	//#region BUTTONS ACTION
	/**
	 * Set the selecte line
	 * @param {Object} workflow
	 */
	const handleLineClick = (workflow) => {
		setLineSelected(!isLineSelected(workflow) ? workflow : '')
	}

	/**
	 * Change the states of selected STEPS
	 * @param {Object} workflow
	 * @param {int} stepCode
	 */
	const stepsChangeState = (workflow, stepCode) => {
		const newSwitches = switches.map((x) => {
			if (x.stepNumber === workflow.stepNumber) {
				x.chevrons.find((y) => y.code === stepCode).state = !x.chevrons.find(
					(y) => y.code === stepCode,
				).state
			}
			return x
		})
		setSwitches(newSwitches)
		const newListWorkflow = listWorkflow.map((x) => {
			if (x.stepNumber === workflow.stepNumber) {
				x.companyRecordChevrons = switches
					.find((y) => y.stepNumber === workflow.stepNumber)
					.chevrons.map((y) => {
						if (y.state === true) return y.code
					})
					.filter((y) => {
						return y !== undefined
					})
			}
			return x
		})

		setWorkflow(newListWorkflow)
	}

	/**
	 * Change the state of selected SWITCH
	 * @param {Object} workflow
	 */
	const switchChangeState = (workflow) => {
		const newSwitches = switches.map((x) => {
			if (x.stepNumber === workflow.stepNumber) x.state = !workflow.isValidatedByDedicatedUser
			return x
		})
		setSwitches(newSwitches)
		const newListWorkflow = listWorkflow.map((x) => {
			if (x.stepNumber === workflow.stepNumber)
				x.isValidatedByDedicatedUser = !workflow.isValidatedByDedicatedUser
			return x
		})
		setWorkflow(newListWorkflow)
	}

	/**
	 * On click on up button
	 */
	const upLine = () => {
		if (lineSelected.stepNumber - 1 >= 0) {
			//Decrement stepnumber
			lineSelected.stepNumber -= 1
			//Change workflow line
			//Increment stepnumber of the now same stepnumber but not our line
			const newListWorkflow = listWorkflow.map((x) => {
				if (x.stepNumber === lineSelected.stepNumber && x !== lineSelected) x.stepNumber += 1
				return x
			})

			//Change Switches line
			//Keep the logic of switches for all workflow line
			const newSwitches = switches.map((x, i) => {
				if (x.stepNumber === lineSelected.stepNumber) {
					//update state of new stepNumber of our line
					x.state = lineSelected.isValidatedByDedicatedUser
					//update state of next stepNumber
					switches[i + 1].state = listWorkflow.find(
						(y) => y.stepNumber === x.stepNumber + 1,
					).isValidatedByDedicatedUser

					//For chevrons
					x.chevrons = mapStepsIntoChevrons()
					//Chevron +1
					switches[i + 1].chevrons = STEPS.map((y) => {
						if (
							listWorkflow
								.find((wf) => wf.stepNumber === x.stepNumber + 1)
								.companyRecordChevrons.find((z) => z === y.code)
						)
							return { code: y.code, state: true }
						return { code: y.code, state: false }
					})
				}
				return x
			})

			//Change Check chevron line

			setSwitches(newSwitches)
			setWorkflow(
				newListWorkflow.sort(function (a, b) {
					return a.stepNumber - b.stepNumber
				}),
			)
		}
	}

	const mapStepsIntoChevrons = () => {
		return STEPS.map((step) => {
			if (lineSelected.companyRecordChevrons.find((z) => z === step.code))
				return { code: step.code, state: true }
			return { code: step.code, state: false }
		})
	}

	/**
	 * On click on down button
	 */
	const downLine = () => {
		if (lineSelected.stepNumber + 1 < listWorkflow.length) {
			//Increment stepNumber
			lineSelected.stepNumber += 1
			//Change workflow line
			//Decrement stepnumber of the now same stepnumber but not our line
			const newListWorkflow = listWorkflow.map((x) => {
				if (x.stepNumber === lineSelected.stepNumber && x !== lineSelected) x.stepNumber -= 1
				return x
			})

			//Change Switches line
			//Keep the logic of switches for all workflow line
			const newSwitches = switches.map((x, i) => {
				if (x.stepNumber === lineSelected.stepNumber) {
					//update state of new stepNumber of our line
					x.state = lineSelected.isValidatedByDedicatedUser
					//update state of next stepNumber
					switches[i - 1].state = listWorkflow.find(
						(y) => y.stepNumber === x.stepNumber - 1,
					).isValidatedByDedicatedUser

					//For chevrons
					x.chevrons = mapStepsIntoChevrons()
					//Chevron +1
					switches[i - 1].chevrons = STEPS.map((step) => {
						if (
							listWorkflow
								.find((wf) => wf.stepNumber === x.stepNumber - 1)
								.companyRecordChevrons.find((z) => z === step.code)
						)
							return { code: step.code, state: true }
						return { code: step.code, state: false }
					})
				}
				return x
			})

			setSwitches(newSwitches)
			setWorkflow(
				newListWorkflow.sort(function (a, b) {
					return a.stepNumber - b.stepNumber
				}),
			)
		}
	}

	/**
	 * Switch the step number AND switches (switch + steps)
	 * @param {Object} workflow
	 * @param {Object} e
	 */
	const switchStepNum = (workflow, e) => {
		const initialStep = workflow.stepNumber
		const stepWanted = e.target.value - 1

		const newListWorkflow = listWorkflow.map((lw) => {
			if (lw.stepNumber === initialStep) {
				lw.stepNumber = stepWanted
			} else {
				if (stepWanted < initialStep) {
					//UP
					if (lw.stepNumber >= stepWanted && lw.stepNumber <= initialStep) lw.stepNumber += 1
				} else if (initialStep < stepWanted) {
					//DOWN
					if (lw.stepNumber <= stepWanted && lw.stepNumber >= initialStep) lw.stepNumber -= 1
				}
			}
			return lw
		})

		switches.forEach((x) => {
			if (x.stepNumber === initialStep) {
				x.stepNumber = stepWanted
			} else {
				if (stepWanted < initialStep) {
					if (x.stepNumber >= stepWanted && x.stepNumber <= initialStep) x.stepNumber += 1
				} else if (initialStep < stepWanted) {
					if (x.stepNumber <= stepWanted && x.stepNumber >= initialStep) x.stepNumber -= 1
				}
			}
			return x
		})

		setWorkflow(
			newListWorkflow.sort(function (a, b) {
				return a.stepNumber - b.stepNumber
			}),
		)
	}

	/**
	 * Change the role of the line
	 * @param {Object} workflow
	 * @param {int} value
	 */
	const changeRole = (workflow, value) => {
		const newRoles = listWorkflow.map((role) => {
			if (role === workflow) {
				role.accountType = value
				return role
			}
			return role
		})
		setWorkflow(newRoles)
	}

	/**
	 * Change the name of the line
	 * @param {Object} workflow
	 * @param {int} value
	 */
	const changeName = (workflow, value) => {
		const newName = listWorkflow.map((name) => {
			if (name === workflow) {
				name.name = value
				return name
			}
			return name
		})
		setWorkflow(newName)
	}

	/**
	 * Delete the selected line
	 */
	const deleteLine = () => {
		const newLW = listWorkflow.filter((w) => {
			return w !== lineSelected
		})
		const newSwitches = switches.filter((s) => {
			return s.stepNumber !== lineSelected.stepNumber
		})
		newLW.forEach((w, i) => {
			w.stepNumber = i
		})
		newSwitches.forEach((w, i) => {
			w.stepNumber = i
		})
		setSwitches(newSwitches)
		setWorkflow(newLW)
		setLineSelected(null)
	}

	/**
	 * Add Line
	 */
	const addLine = () => {
		let lastLine = 0
		if (listWorkflow.length) lastLine = listWorkflow[listWorkflow.length - 1].stepNumber + 1
		const newWorkflow = {
			workflowId: TypeOfPdp,
			stepNumber: lastLine,
			name: '',
			accountType: null,
			companyRecordChevrons: [],
			isCurrent: false, //default value
			isValid: false, //default value
			eligibleAccounts: null, //default value
			selectedAccountId: null, //default value
			isValidatedByDedicatedUser: false, //default value
		}
		const newListWorkflow = [...listWorkflow, newWorkflow]
		setTheLine(newListWorkflow)
	}

	/**
	 * Click on Save all array
	 */
	const SaveWorkflows = () => {
		setReadOnly(true)
		let allChevrons = []
		let flag = true
		listWorkflow.forEach((workflow) => {
			if (!workflow.name) {
				flag = false
				enqueueSnackbar(t('flowmodel:NameMiss'), { variant: 'error' })
			}
			if (!workflow.accountType) {
				flag = false
				enqueueSnackbar(t('flowmodel:accountTypeMiss'), { variant: 'error' })
			}
			allChevrons = [...allChevrons, ...workflow.companyRecordChevrons]
		})
		if ([...new Set(allChevrons)].length !== 7 && [...new Set(allChevrons)].length !== 0) {
			setReadOnly(false)
			return enqueueSnackbar(t('flowmodel:noSuite'), { variant: 'error' })
		}
		if (flag) {
			api
				.put(bindParams(link.WORKFLOW, cookies[PLATFORM_ID_COOKIE], TypeOfPdp), {
					workflow: listWorkflow,
				})
				.then((res) => {
					setReadOnly(false)
					const { ok } = res
					if (!ok) {
						enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
						return
					}
					enqueueSnackbar(t('flowmodel:successValidateFlowModel'), { variant: 'success' })
				})
		} else setReadOnly(false)
	}
	//#endregion BUTTONS ACTION

	//#region MISCELLANEOUS FUNCTIONS
	/**
	 * Set all line datas : workflow, steps, switches ...
	 * @param {Object} data
	 */
	const setTheLine = (data) => {
		const resSwitches = data.map((x) => {
			return {
				stepNumber: x.stepNumber,
				state: x.isValidatedByDedicatedUser,
				chevrons: STEPS.map((y) => {
					if (x.companyRecordChevrons.find((z) => z === y.code))
						return { code: y.code, state: true }
					return { code: y.code, state: false }
				}),
			}
		})
		setSwitches(resSwitches)
		setWorkflow(data)
	}

	const isLineSelected = (id) => lineSelected === id
	const isOneLineSelected = () =>
		lineSelected !== null && lineSelected !== undefined && lineSelected !== ''
	//#endregion MISCELLANEOUS FUNCTIONS

	//#region HTML COMPONENT
	const MyTableHead = () => {
		return (
			<TableHead>
				<TableRow>
					<TableCell padding="checkbox"></TableCell>
					<TableCell align="center">{t('flowmodel:stepNumber')}</TableCell>
					<TableCell align="center">{t('flowmodel:stepName')}</TableCell>
					<TableCell align="center">{t('flowmodel:stepAccountType')}</TableCell>
					<TableCell align="center">{t('flowmodel:stepValidateBySpecificUser')}</TableCell>
					{STEPS.map((step) => (
						<TableCell align="center" key={step.code}>{`${step.code} ${step.label}`}</TableCell>
					))}
				</TableRow>
			</TableHead>
		)
	}

	const ActionButtons = () => {
		return (
			<div>
				<Grid className={`${classes.marginUpDown}`} container justify="flex-start">
					<Fab
						onClick={() => upLine()}
						disabled={!isOneLineSelected()}
						className={classes.marginFabButtons}
						color="primary"
					>
						<ArrowUpwardIcon />
					</Fab>
					<Fab
						onClick={() => downLine()}
						disabled={!isOneLineSelected()}
						className={classes.marginFabButtons}
						color="primary"
					>
						<ArrowDownwardIcon />
					</Fab>
					<Fab
						disabled={!isOneLineSelected()}
						className={`${classes.marginFabButtons} ${classes.danger}`}
						onClick={() => deleteLine()}
					>
						<DeleteRoundedIcon className={classes.icon} />
					</Fab>
				</Grid>
			</div>
		)
	}
	//#endregion HTML COMPONENT

	return (
		<>
			<HeadTitle title={t('user:EditingFlowModels')} />
			<Paper className={classes.div} style={{ marginBottom: 8 }}>
				<Grid container className={classes.marginUpDown} justify="center">
					<Grid item xs={12} sm={4}>
						<Select
							fullWidth={true}
							value={TypeOfPdp}
							onChange={(e) => {
								setTypeOfPdp(e.target.value)
							}}
						>
							{listPDPType.map((PDPType) => (
								<MenuItem value={PDPType.id} key={`${PDPType.id}`}>
									{PDPType.name}
								</MenuItem>
							))}
						</Select>
					</Grid>
				</Grid>
				<Grid container justify="flex-end">
					<Fab
						className={`${classes.marginFabButtons}`}
						onClick={() => addLine()}
						color="secondary"
					>
						<AddRoundedIcon className={classes.icon} />
					</Fab>
				</Grid>
				<Grid container className={classes.marginUpDown} justify="center">
					<Grid item xs={12}>
						<Table className={classes.table} aria-label="simple table">
							<MyTableHead />
							<TableBody>
								{listWorkflow.map((workflow) => (
									<TableRow
										key={workflow.stepNumber}
										hover={true}
										selected={isLineSelected(workflow)}
									>
										<TableCell padding="checkbox" align="center">
											<Checkbox
												onClick={() => handleLineClick(workflow)}
												checked={isLineSelected(workflow)}
											/>
										</TableCell>
										<TableCell align="center">
											<Select
												value={workflow.stepNumber + 1}
												onChange={(e) => {
													switchStepNum(workflow, e)
												}}
											>
												{listWorkflow.map((workflowNum, i) => (
													<MenuItem value={i + 1} key={i}>
														{i + 1}
													</MenuItem>
												))}
											</Select>
										</TableCell>
										<TableCell padding="none" align="center">
											<Input
												type="text"
												value={
													listWorkflow.length
														? workflow.name
															? listWorkflow.find((x) => x === workflow).name
															: ''
														: ''
												}
												onChange={(e) => {
													changeName(workflow, e.target.value)
												}}
												multiline
											/>
										</TableCell>
										<TableCell align="center">
											<Select
												value={
													listWorkflow.length
														? workflow.accountType
															? listWorkflow.find((x) => x === workflow).accountType
															: ''
														: ''
												}
												onChange={(e) => {
													changeRole(workflow, e.target.value)
												}}
											>
												{listAccountType.map((accountType) => (
													<MenuItem value={accountType.id} key={accountType.id}>
														{accountType.name}
													</MenuItem>
												))}
											</Select>
										</TableCell>
										<TableCell padding="checkbox" align="center">
											<Switch
												size="small"
												onClick={() => switchChangeState(workflow)}
												checked={
													switches.length
														? switches.find((x) => x.stepNumber === workflow.stepNumber).state
														: false
												}
											/>
										</TableCell>
										{STEPS.map((step) => (
											<TableCell padding="checkbox" align="center" key={step.code}>
												<Checkbox
													onClick={() => stepsChangeState(workflow, step.code)}
													checked={
														switches.length
															? switches
																	.find((x) => x.stepNumber === workflow.stepNumber)
																	.chevrons.find((x) => x.code === step.code).state
															: false
													}
												/>
											</TableCell>
										))}
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Grid>
				</Grid>
				<ActionButtons />
				<Grid container justify="center">
					<Button
						className={classes.marginAll}
						onClick={() => SaveWorkflows()}
						variant="contained"
						color="secondary"
						disabled={readOnly}
					>
						{t('translation:save_btn')}
					</Button>
				</Grid>
			</Paper>
		</>
	)
}
export default FlowModels
