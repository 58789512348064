export const useSession = (key) => {
	const session = getSessionItem(key)
	const setSession = (value) => setSessionItem(key, value)
	const clearSession = () => clearSessionItem(key)
	return { session, setSession, clearSession }
}

export const setSessionItem = (key, value) => sessionStorage.setItem(key, value)

export const getSessionItem = (key) => sessionStorage.getItem(key)

export const clearSessionItem = (key) => sessionStorage.removeItem(key)
