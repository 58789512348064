import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Icon,
	IconButton,
	Typography,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { downloadFile } from '../../../../../containers/DataContainer'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../../../../utils/File'

const useStyles = makeStyles((theme) => ({
	presentationText: {
		color: theme.palette.secondary.main,
	},
}))

function StepChecklist({
	setOnClickOpenCheckListForm,
	isReadOnly,
	checkListDocuments,
	setCheckListItemOnFocus,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'companyRecord'])
	const { enqueueSnackbar } = useSnackbar()

	const addFileOnClick = (checkListItemIndex) => {
		let res = checkListDocuments.checkListItemsCompanyRecord[checkListItemIndex]
		setCheckListItemOnFocus(res)
		setOnClickOpenCheckListForm(true)
	}

	const downloadDocument = (file) => {
		let path = file.docDocumentCheminComplet
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				console.log(error)
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	return (
		<>
			<div>
				<Typography className={classes.presentationText}>
					{checkListDocuments.presentationText}
				</Typography>
				<Table className={classes.table}>
					<TableHead>
						<TableRow>
							<TableCell className={classes.categoryTitle}>{t('user:user')}</TableCell>
							<TableCell className={classes.categoryTitle}>
								{t('user:date_add_or_update')}
							</TableCell>
							<TableCell className={classes.categoryTitle}>{t('companyRecord:document')}</TableCell>
							<TableCell className={classes.categoryTitle} align="center">
								{t('nameOfTheFile')}
							</TableCell>
							<TableCell className={classes.categoryTitle} align="right">
								{t('companyRecord:add')}
							</TableCell>
							<TableCell className={classes.categoryTitle} align="right">
								{t('companyRecord:download')}
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{checkListDocuments.checkListItemsCompanyRecord.map((item, i) => (
							<TableRow key={i}>
								<TableCell component="th" scope="row">
									{item.username}
								</TableCell>
								<TableCell component="th" scope="row">
									{item.dateAdded}
								</TableCell>
								<TableCell component="th" scope="row">
									{item.chkItem.document}
								</TableCell>
								<TableCell component="th" scope="row">
									{item.documentFile ? item.documentFile.docName : ''}
								</TableCell>
								<TableCell component="th" scope="row" align="right">
									<IconButton
										disabled={isReadOnly}
										onClick={(e) => {
											e.preventDefault()
											addFileOnClick(i)
										}}
									>
										<Icon>add</Icon>
									</IconButton>
								</TableCell>
								<TableCell component="th" scope="row" align="right">
									<IconButton
										disabled={!item.documentFile}
										onClick={() => downloadDocument(item.documentFile)}
									>
										<Icon>get_app</Icon>
									</IconButton>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</div>
		</>
	)
}

export default StepChecklist
