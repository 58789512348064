import { setSessionItem, getSessionItem } from '../containers/SessionContainer'

export const switchLanguage = (selectedLang) => {
	let value
	let sessionValues = {}

	switch (selectedLang) {
		case 'fr':
			value = 1
			break

		case 'en':
			value = 2
			break

		default:
			value = 1
			break
	}
	const session = getSessionItem('signup')
	if (!session) Object.assign(sessionValues, { langInfo: value })

	let tmpSession = JSON.parse(session)
	if (tmpSession) Object.assign(sessionValues, Object.assign(tmpSession, { langInfo: value }))
	setSessionItem('signup', JSON.stringify(sessionValues))
}
