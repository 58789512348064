import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { ACCOUNT_TYPES_COOKIE } from '../constants/cookies'

const Access = ({ accept, children }) => {
	const [show, setShow] = useState(false)
	const [cookies] = useCookies()

	const processTypes = () => {
		if (!cookies[ACCOUNT_TYPES_COOKIE] && !accept) return

		let accountTypes = cookies[ACCOUNT_TYPES_COOKIE]
		if (!accept) return setShow(true)

		if (!Array.isArray(accountTypes)) return
		let typesId = accountTypes.map(type => type.id)
		let acceptId = accept.map(type => type.id)
		for (let typeId of typesId) {
			for (let id of acceptId) {
				if (typeId === id) setShow(true)
			}
		}
		return
	}
	useEffect(processTypes, [])

	return <>{show && children}</>
}

export const useAccount = () => {
	const [cookies] = useCookies()
	let accTypes = cookies[ACCOUNT_TYPES_COOKIE]
	if (!accTypes && Array.isArray(accTypes)) return
	return accTypes[0]
}

export default Access
