import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: '#3268A7',
	},
	labelFocused: {
		color: '#3268A7 !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: '#3268A7',
		width: '100%',
		border: 'solid 0.5px',
		borderColor: '#3268A7',
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
		minHeight: 0,
	},
}))

function InputTextArea({
	name,
	label,
	validate,
	readOnly,
	onChange = null,
	value = null,
	...props
}) {
	const classes = useStyles()

	const onChangeHandle = React.useCallback((input, e) => {
		input.onChange(e)
		onChange && onChange(e)
	})

	return (
		<Field
			name={name}
			validate={validate}
			value={value ?? null}
			render={({ input, meta }) => (
				<TextField
					type="text"
					label={label}
					variant="filled"
					multiline={true}
					FormHelperTextProps={{
						classes: {
							root: classes.helperText,
						},
					}}
					helperText={
						meta && meta.touched && meta.error ? (
							<Translation ns="validateData">{(t) => <>{t(meta.error)}</>}</Translation>
						) : undefined
					}
					className={classes.block}
					InputProps={{
						readOnly: readOnly,
						classes: {
							root: classes.inputRoot,
							focused: classes.inputFocused,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.labelRoot,
							focused: classes.labelFocused,
						},
					}}
					{...props}
					{...input}
					value={value ?? input.value}
					onChange={(e) => onChangeHandle(input, e)}
					onFocus={(e) =>
						e.currentTarget.setSelectionRange(
							e.currentTarget.value.length,
							e.currentTarget.value.length,
						)
					}
				/>
			)}
		/>
	)
}

export default InputTextArea
