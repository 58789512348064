import React, { useState, useEffect } from 'react'
import FormIcon from '../Form/FormIcon'
import FormButtonIcon from '../Form/FormButtonIcon'
import SlidingForm from '../Form/SlidingForm'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import InputSelect from '../Form/input/InputSelect'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import Form from '../Form/Form'
import { TYPERETURN } from '../../pages/SignIn/SignIn'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
	select: {
		marginTop: 20,
	},
}))

const PlatformChoiceForm = ({ open, close, data }) => {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const [namePlatforms, setNamePlatforms] = useState(null)

	const onSubmit = (values) => {
		data.selectPlatform(values.platform, data.authRes)
		close()
	}

	useEffect(() => {
		if (!data) return
		let listNames = []
		data.listPlatforms
			.filter((p) => p.statusCode !== TYPERETURN.CompanyPlatformExpired)
			.forEach((platform) => {
				// Add a warning message to the name of the platform
				switch (platform.statusCode) {
					case TYPERETURN.CompanyPlatformThreeMonthsRemains:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiresIn') + ' 3 ' + t('Months') + ')',
							},
						]
						break
					case TYPERETURN.CompanyPlatformTwoMonthsRemains:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiresIn') + ' 2 ' + t('Months') + ')',
							},
						]
						break
					case TYPERETURN.CompanyPlatformOneMonthsRemains:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiresIn') + ' 1 ' + t('Months') + ')',
							},
						]
						break
					case TYPERETURN.CompanyPlatformOneMonthsPassed:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiredSince') + ' 1 ' + t('Months') + ')',
							},
						]
						break
					case TYPERETURN.CompanyPlatformTwoMonthsPassed:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiredSince') + ' 2 ' + t('Months') + ')',
							},
						]
						break
					case TYPERETURN.CompanyPlatformThreeMonthsPassed:
						listNames = [
							...listNames,
							{
								pftId: platform.ptfId,
								ptfNom: platform.ptfNom + ' (' + t('ExpiredSince') + ' 3 ' + t('Months') + ')',
							},
						]
						break
					default:
						listNames = [...listNames, { pftId: platform.ptfId, ptfNom: platform.ptfNom }]
						break
				}
			})
		setNamePlatforms(listNames)
	}, [data, t])

	const validate = (values) => {
		const errors = {}
		if (!values.platform) errors.platform = t('PleaseSelectAPlatform')
		return errors
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('platformChoice')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} validate={validate}>
				<InputSelect
					name="platform"
					label={t('platformChoice')}
					classes={{
						root: classes.select,
					}}
				>
					{namePlatforms &&
						namePlatforms.map((platform, i) => {
							return (
								<SelectMenuItem key={i} value={platform.pftId}>
									{platform.ptfNom}
								</SelectMenuItem>
							)
						})}
				</InputSelect>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
PlatformChoiceForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default PlatformChoiceForm
