const resourceFolder = '/icons/account_types/'
const resourceExtension = '.svg'

const icon = (name) => resourceFolder + name + resourceExtension

export const findRoleById = (id) =>
	Object.values(ROLES).filter((r) => {
		if (r.id === id) return r
		return null
	})

const ROLES = {
	ADMIN: { id: 1, name: 'admin', icon: icon('a'), code: 'ADMIN' },
	// Gestionnaire
	PLATFORMMANAGER: { id: 2, name: 'platform_manager', icon: icon('g'), code: 'PLATFORMMANAGER' },
	// DO
	PRINCIPAL: { id: 3, name: 'principal', icon: icon('do'), code: 'PRINCIPAL' },
	EXTCOMPADMIN: { id: 4, name: 'ext_comp_admin', icon: icon('eex'), code: 'EXTCOMPADMIN' },
	EXTCOMP: { id: 5, name: 'ext_comp', icon: icon('st'), code: 'EXTCOMP' },
	INTERNALOBS: { id: 6, name: 'internal_obs', icon: icon('oi'), code: 'INTERNALOBS' },
	EXTERNALOBS: { id: 7, name: 'external_obs', icon: icon('oe'), code: 'EXTERNALOBS' },
	DOCUMENTATION: { id: 8, name: 'documentation', icon: icon('doc'), code: 'DOCUMENTATION' },
	HYGIENIST: { id: 9, name: 'hygienist', icon: icon('hyg'), code: 'HYGIENIST' },
}

export const ALL_ROLES = [
	ROLES.ADMIN,
	ROLES.PLATFORMMANAGER,
	ROLES.PRINCIPAL,
	ROLES.EXTCOMPADMIN,
	ROLES.EXTCOMP,
	ROLES.INTERNALOBS,
	ROLES.EXTERNALOBS,
	ROLES.DOCUMENTATION,
	ROLES.HYGIENIST,
]

export const ALL_EXT_ROLES = [ROLES.EXTERNALOBS, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]
export const INTERNALS = [ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.INTERNALOBS]
export const MODIFY_COMPANYRECORD_WITHOUT_PRINCIPAL = [
	ROLES.ADMIN,
	ROLES.PLATFORMMANAGER,
	ROLES.INTERNALOBS,
	ROLES.EXTCOMP,
	ROLES.EXTCOMPADMIN,
]
export const VALIDATORS = [ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.PRINCIPAL]
export default ROLES
