import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
	buttonDelete: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	deleteIcon: {
		width: '2em',
		height: '2em',
	},
}))

function FormButtonIconDelete({ label, type, onClick, children, ...props }) {
	const classes = useStyles()

	return (
		<Button
			className={classes.buttonDelete}
			type={type}
			variant="outlined"
			onClick={onClick}
			{...props}
		>
			<Delete className={classes.deleteIcon} />
		</Button>
	)
}

export default FormButtonIconDelete
