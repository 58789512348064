import React, { useState, useEffect } from 'react'
import {
	Grid,
	Typography,
	Button,
	Icon,
	IconButton,
	Checkbox,
	Card,
	CardContent,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ContactPdPCard from '../../../components/Cards/ContactPdPCard'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { useTranslation } from 'react-i18next'
import {
	getHygieneData,
	deleteHygieneSurveillancePoint,
	updateCse,
} from '../../../containers/DataContainer'
import {
	COLUMNS_HYGYENE_HEALTH,
	convertArrayToDatatable,
	getIndexOfColumn,
} from '../../../utils/Datatable'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { dateDisplay } from '../../../utils/Date'
import api from '../../../utils/Requester'
import { DELETE_HC } from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import PropTypes from 'prop-types'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
		margin: 'auto',
		marginLeft: 0,
	},
	dataTableContainer: {
		position: 'relative',
		display: CSS_VALUES.INLINE_BLOCK,
	},
	button: {
		position: 'absolute',
		top: 35,
		right: 290,
	},
	buttonAdd: {
		display: CSS_VALUES.INLINE_BLOCK,
		marginLeft: 'auto',
	},
	memberGroup: {
		display: 'flex',
		marginBottom: 10,
	},
	checkboxChecked: {
		color: theme.palette.secondary.main,
		'&$checkboxChecked': {
			color: theme.palette.secondary.main,
		},
	},
	elibility: {
		color: theme.palette.secondary.main,
		display: CSS_VALUES.INLINE_BLOCK,
	},
	messageElibility: {
		color: theme.palette.secondary.main,
		margin: 0,
		fontSize: 13,
		transform: 'translateY(-11px)',
	},
	elibilityGroup: {
		textAlign: 'center',
	},
}))

function HygieneAndHealth({
	forms,
	btnsStyle,
	queries,
	isReadOnly,
	isReviewMode = false,
	setRevisionCompanyRecordId,
	nextStep,
	previousStep,
	refreshChevrons,
	setRefreshChevrons,
}) {
	const classes = useStyles()
	const [update, setUpdate] = useState(false)
	const [SCs, setSCs] = useState({ columns: [], data: [], options: {} })
	const [listContacts, setListContacts] = useState([])
	const [cseButton, setCseButton] = useState(false)

	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()

	const [MedicalSurveillanceForm, AddCSEContactForm, AddCompanyFilesContactHSEForm] = forms
	const { companyRecordId, platformId, companyId } = queries

	const [isDisable, setDisable] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const customLabels = [
		{
			name: 'startDate',
			label: t('date_debut'),
		},
		{
			name: 'endDate',
			label: t('date_fin'),
		},
		{
			name: 'periodicMedicalExamination',
			label: t('visitPeriodic'),
		},
		{
			name: 'harmfulProduct',
			label: t('nocifOrNuisance'),
		},
		{
			name: 'workstation',
			label: t('postToWatch'),
		},
		{
			name: 'worker',
			label: t('worker'),
		},
		{
			name: 'actions',
			label: t('actions'),
		},
	]

	const updateData = () => {
		setUpdate(!update)
	}

	//#region Contact
	const openAddCSEContactForm = () => {
		AddCSEContactForm.addData({
			updateData: updateData,
			companyRecordId: companyRecordId,
		})
		AddCSEContactForm.open()
	}

	const openAddCompanyFilesContactHSEForm = () => {
		AddCompanyFilesContactHSEForm.addData({
			companyId: companyId,
			updateData: updateData,
			companyRecordId: companyRecordId,
			openAddCSEContactForm: openAddCSEContactForm,
			setListContacts: setListContacts,
			listContacts: listContacts,
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
		})
		AddCompanyFilesContactHSEForm.open()
	}

	//#endregion Contact

	//#region Hygiene
	const openAddMSForm = () => {
		MedicalSurveillanceForm.addData({
			updateData: updateData,
			companyRecordId: companyRecordId,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
		})
		MedicalSurveillanceForm.open()
	}

	const deleteHygieneSurveillance = (HygieneSurveillanceId) => {
		deleteHygieneSurveillancePoint(
			cookies[PLATFORM_ID_COOKIE],
			companyRecordId,
			HygieneSurveillanceId,
			(res, err) => {
				if (res) {
					updateData()
					enqueueSnackbar(t('pointdeleted'), {
						variant: 'info',
					})
					if (res.data && res.data.companyRecordId)
						setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (err) {
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	//#endregion HygieneAndHealth

	//#region get data & datatable
	const recoverDatatable = () => {
		setIsLoading(true)
		getHygieneData(cookies[PLATFORM_ID_COOKIE], companyRecordId, (res, err) => {
			setIsLoading(false)
			if (!err && !res) setSCs({ columns: [], data: [], options: {} })
			if (err) return console.error(err)
			if (!res || !res.data) return
			setListContacts(res.data.hygieneContacts)
			setCseButton(res.data.hasCse)
			return setSCs(convertArrayToDatatable(res.data.surveillancePoints, ['id', 'companyRecordId']))
		})
	}

	const actionsColumn = {
		name: 'actions',
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const customColumns = [
		{
			name: 'actions',
			component: function componentActions(value, tableMeta) {
				if (!tableMeta.rowData) return

				return (
					<IconButton
						onClick={() => {
							deleteHygieneSurveillance(
								tableMeta.rowData[getIndexOfColumn(SCs.columns, COLUMNS_HYGYENE_HEALTH.ID)],
							)
						}}
					>
						<Icon>delete</Icon>
					</IconButton>
				)
			},
		},
		{
			name: 'startDate',
			component: (value) => {
				if (value) return dateDisplay(value)
			},
		},
		{
			name: 'endDate',
			component: (value) => {
				if (value) return dateDisplay(value)
			},
		},
		{
			name: 'periodicMedicalExamination',
			component: function ComponentPeriodicMedicalExamination(value) {
				if (value)
					return (
						<IconButton>
							<Icon>done</Icon>
						</IconButton>
					)
				return (
					<IconButton>
						<Icon>clear</Icon>
					</IconButton>
				)
			},
		},
	]

	useEffect(recoverDatatable, [update])
	//#endregion Datatable

	const toggleCse = (e) => {
		const isChecked = e.target.checked
		setDisable(true)
		updateCse(
			platformId,
			companyRecordId,
			{
				hasCse: isChecked,
			},
			function (res, error) {
				if (error) {
					return
				}
				if (res) {
					setCseButton(isChecked)
					setDisable(false)
				}
			},
		)
	}

	const deleteContact = (contactId) => {
		api.delete(bindParams(DELETE_HC, platformId, companyRecordId, contactId)).then(() => {
			updateData()
			setRefreshChevrons(!refreshChevrons)
		})
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('HygieneAndHealth')}
			</Typography>
			<br />
			<div className={classes.elibilityGroup}>
				<p className={classes.elibility}>{t('chsct')} </p>
				<Checkbox
					className={classes.checkboxChecked}
					onChange={toggleCse}
					checked={cseButton}
					disabled={isReadOnly || isDisable}
				/>
			</div>
			<Grid container spacing={8} justify="center">
				<Grid item sm={12} md={4}>
					<div className={classes.memberGroup}>
						<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
							{t('CSEMember')}
						</Typography>
						{!isReadOnly && (
							<Button
								variant="contained"
								color="primary"
								classes={{ root: classes.buttonAdd }}
								onClick={openAddCompanyFilesContactHSEForm}
							>
								{t('add')}
							</Button>
						)}
					</div>
					{listContacts &&
						listContacts.map((contact) => {
							return (
								<>
									<ContactPdPCard
										size={12}
										data={{
											firstName: contact.firstName,
											name: contact.name,
											email: contact.email,
											platformId: platformId,
											companyRecordId: companyRecordId,
											id: contact.id,
											phone: contact.phone,
											refresh: () => setUpdate(!update),
										}}
										de
										labelDeleteMessage={t('warningDE')}
										deleteAction={deleteContact}
									/>
									<br />
								</>
							)
						})}
					{listContacts && !listContacts.length && (
						<Card>
							<CardContent>
								<Typography variant="h6" color="secondary">
									{t('noMember')}
								</Typography>
							</CardContent>
						</Card>
					)}
				</Grid>
				<Grid item sm={12} md={8} className={classes.dataTableContainer}>
					{!isReadOnly && (
						<Button
							variant="contained"
							color="primary"
							className={classes.button}
							onClick={openAddMSForm}
						>
							{t('add')}
						</Button>
					)}
					<DataTable
						title={t('postToWatch')}
						columns={[...SCs.columns, actionsColumn]}
						data={SCs.data}
						options={SCs.options}
						isLoading={isLoading}
						customColumns={customColumns}
						customLabels={customLabels}
					/>
				</Grid>
			</Grid>
			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}
HygieneAndHealth.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	queries: PropTypes.object,
	isReadOnly: PropTypes.bool,
	isReviewMode: PropTypes.bool,
	setRevisionCompanyRecordId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
}
export default HygieneAndHealth
