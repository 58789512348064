import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { upsertHygieneSurveillancePoint } from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputDate from '../../Form/input/InputDate'
import InputSwitch from '../../Form/input/InputSwitch'
import InputText from '../../Form/input/InputText'
import SlidingForm from '../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	dateBox: {
		width: '100%',
		display: 'inline-block',
	},
	date: {
		width: '100%',
		marginTop: 10,
	},
	text: {
		display: 'inline-block',
		color: theme.palette.primary.main,
	},
}))

/**
 * Shows a form to add a Medical Surveillance to the company file
 * @returns {JSX.Element} React stack to render
 */
function MedicalSurveillanceForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])

	const onSubmit = (values) => {
		//Test if the start date is before or equals the end date
		if (moment(values.startDate).isAfter(moment(values.endDate))) {
			enqueueSnackbar(t('error') + ' : ' + t('start_date_anterior_error'), { variant: 'error' })
			return
		}

		let formData = {
			startDate: values.startDate,
			endDate: values.endDate,
			periodicMedicalExamination: values.medicalCheckUp ? values.medicalCheckUp : false,
			harmfulProduct: values.harmfulProduct,
			workstation: values.workstation,
			worker: values.worker,
		}
		upsertHygieneSurveillancePoint(
			cookies[PLATFORM_ID_COOKIE],
			data.companyRecordId,
			formData,
			function (res, error) {
				if (res) {
					data.updateData()
					enqueueSnackbar(t('pointAdded'), {
						variant: 'info',
					})
					close()
					if (res.data && res.data.companyRecordId)
						data.setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (error) {
					console.log(error)
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addSurveillance')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{ startDate: moment(), endDate: moment() }}>
				<InputText
					classes={{ root: classes.size1 }}
					name="workstation"
					label={t('postToWatch')}
					validate={composeValidators(required)}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					name="harmfulProduct"
					label={t('nocifOrNuisance')}
					validate={composeValidators(required)}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					name="worker"
					label={t('worker')}
					validate={composeValidators(required)}
				/>
				<InputDate name="startDate" label={t('date_debut')} classes={{ root: classes.date }} />
				<InputDate
					name="endDate"
					label={t('date_fin')}
					inputStyle={classNames(classes.input, classes.date)}
					classes={{ root: classes.date }}
				/>
				<Typography variant="subtitle1" className={classes.text}>
					{t('visitPeriodic')}
				</Typography>
				<InputSwitch name="medicalCheckUp" />
				<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
			</Form>
		</SlidingForm>
	)
}

export default MedicalSurveillanceForm
