import React, { useState, useEffect } from 'react'
import { Typography, Button, IconButton, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { useTranslation } from 'react-i18next'
import {
	COLUMNS_SUBCONTRACTORS_COMPANIES,
	convertArrayToDatatable,
	getIndexOfColumn,
} from '../../../utils/Datatable'
import { useSnackbar } from 'notistack'
import api from '../../../utils/Requester'
import { SUBCONTRACTORCOMPANIES, DELETE_SUBCONTRACTORCOMPANIES } from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: 14,
		right: 280,
	},
	icon: {
		color: theme.palette.secondary.main,
		textAlign: 'left',
		marginLeft: 35,
		width: '100%',
	},
	iconNotValid: {
		color: theme.palette.no.main,
		textAlign: 'left',
		marginLeft: 35,
		width: '100%',
	},
	iconValid: {
		color: theme.palette.yes.main,
		textAlign: 'left',
		marginLeft: 35,
		width: '100%',
	},
}))

function SubcontractingCompagnies({
	forms,
	btnsStyle,
	queries,
	isReadOnly = false,
	isReviewMode = false,
	setRevisionCompanyRecordId,
	nextStep,
	previousStep,
	refreshChevrons,
	setRefreshChevrons,
}) {
	const classes = useStyles()
	const [update, setUpdate] = useState(false)
	const [SCs, setSCs] = useState({ columns: [], data: [], options: {} })
	const { t } = useTranslation(['user', 'companyRecord'])
	const { enqueueSnackbar } = useSnackbar()
	const { platformId, companyRecordId } = queries
	const [SubcontractingCompagniesForm] = forms
	const [isLoading, setIsLoading] = useState(true)

	const customLabels = [
		{
			name: 'companyName',
			label: t('companyName'),
		},
		{
			name: 'responsibleFullName',
			label: t('responsibleFullName'),
		},
		{
			name: 'workDescription',
			label: t('workDescription'),
		},
		{
			name: 'isNewFromRevision',
			label: t('isNewFromRevision'),
		},
		{
			name: 'agreementExpired',
			label: t('companyRecord:agreementExpired'),
		},
		{
			name: 'actions',
			label: t('actions'),
		},
	]

	const createSubcontractor = () => {
		SubcontractingCompagniesForm.addData({
			id: companyRecordId,
			platformId,
			new: true,
			refresh: () => setUpdate(!update),
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
		})
		SubcontractingCompagniesForm.open()
	}

	//#region Company section

	const deletionSubcontractor = (res) => {
		const { data, problem } = res

		if (problem) return

		if (data) {
			setRefreshChevrons(!refreshChevrons)
			setUpdate(!update)
			enqueueSnackbar('Sous-traitant supprimé !', { variant: 'info' })
		}
	}

	const deleteSubcontractor = (CR_Id, C_Id) => {
		api
			.delete(bindParams(DELETE_SUBCONTRACTORCOMPANIES, platformId, CR_Id, C_Id))
			.then(deletionSubcontractor)
	}
	//#endregion Company

	//#region Datatable

	const recoverySubcontractorsCompanies = (res) => {
		const { data, problem } = res
		setIsLoading(false)

		if (problem) return console.error('Error to subcontractor companies datatable !')

		if (!data) return
		if (!data.length) return setSCs({ columns: [], data: [], options: {} })
		return setSCs(convertArrayToDatatable(data, ['companyId', 'principalCompanyRecordId']))
	}

	const recoverDatatable = () => {
		setIsLoading(true)
		api
			.get(bindParams(SUBCONTRACTORCOMPANIES, platformId, companyRecordId))
			.then(recoverySubcontractorsCompanies)
	}

	const actionsColumn = {
		name: 'actions',
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const customColumns = [
		{
			name: 'actions',
			component: function componentAction(_value, tableMeta) {
				return (
					<>
						<IconButton
							disabled={isReadOnly}
							onClick={() => {
								deleteSubcontractor(
									companyRecordId,
									tableMeta.rowData[
										getIndexOfColumn(SCs.columns, COLUMNS_SUBCONTRACTORS_COMPANIES.COMPANYID)
									],
								)
							}}
						>
							<Icon>delete</Icon>
						</IconButton>
					</>
				)
			},
		},
		{
			name: 'isNewFromRevision',
			component: (_value, tableMeta) => {
				const isNewFromRevision =
					tableMeta.rowData[
						getIndexOfColumn(SCs.columns, COLUMNS_SUBCONTRACTORS_COMPANIES.ISNEWFROMREVISION)
					]

				return <>{isNewFromRevision && <Icon className={classes.icon}>info_outline</Icon>}</>
			},
		},
		{
			name: 'agreementExpired',
			component: (_value, tableMeta) => {
				const agreementExpired =
					tableMeta.rowData[
						getIndexOfColumn(SCs.columns, COLUMNS_SUBCONTRACTORS_COMPANIES.AGREEMENTEXPIRED)
					]

				return (
					<>
						{agreementExpired ? (
							<Icon className={classes.iconNotValid}>cancel</Icon>
						) : (
							<Icon className={classes.iconValid}>check_circle</Icon>
						)}
					</>
				)
			},
		},
	]

	useEffect(recoverDatatable, [update])
	//#endregion Datatable
	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('subcontractorCompany')}
			</Typography>
			<br />
			<div className={classes.dataTableContainer}>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => createSubcontractor()}
					>
						{t('add')}
					</Button>
				)}

				<DataTable
					title={t('subcontractor')}
					columns={[...SCs.columns, actionsColumn]}
					data={SCs.data}
					options={SCs.options}
					isLoading={isLoading}
					customColumns={customColumns}
					customLabels={customLabels}
				/>
			</div>
			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}
SubcontractingCompagnies.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	queries: PropTypes.object,
	isReadOnly: PropTypes.bool,
	isReviewMode: PropTypes.bool,
	setRevisionCompanyRecordId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
}
export default SubcontractingCompagnies
