import { Button, Fab, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import ContactPdPCard from '../../../components/Cards/ContactPdPCard'
import { COMPANY_ID_COOKIE, PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { DELETE_PDP_CONTACTS, PDP_CONTACTS } from '../../../constants/url'
import { useAddPdPContact, useCreatePdPContact } from '../../../containers/FormContainer'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	fab: {
		display: 'block',
		marginLeft: 'auto',
	},
	addIcon: {
		display: 'flex',
	},
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
}))

function Contacts({
	buttonsStyle,
	setStepStatus,
	pdpId,
	isReadOnly,
	revisionNumber,
	pdpNumber,
	refreshChevrons,
	setRefreshChevrons,
	nextStep,
	previousStep,
}) {
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const [listContacts, setListContacts] = useState(null)
	const [needRefresh, setNeedRefresh] = useState(true)
	const [createPdPContactData, setCreatePdPContactData] = useState({
		setRefreshChevrons: setRefreshChevrons,
		refreshChevrons: refreshChevrons,
		pdpId: pdpId,
		revisionNumber: revisionNumber,
		setNeedRefresh: setNeedRefresh,
	})

	const CreatePdPContact = useCreatePdPContact(createPdPContactData)
	const { enqueueSnackbar } = useSnackbar()
	const openCreatePdPContact = () => {
		if (createPdPContactData.data) createPdPContactData.data = null
		setCreatePdPContactData({ ...createPdPContactData })
		CreatePdPContact.open()
	}
	const classes = useStyles()

	const AddPdPContact = useAddPdPContact({
		setRefreshChevrons: setRefreshChevrons,
		refreshChevrons: refreshChevrons,
		pdpId: pdpId,
		revisionNumber: revisionNumber,
		openCreatePdPContact: openCreatePdPContact,
		setNeedRefresh: setNeedRefresh,
	})
	const openCreatePdPContactWithData = (data) => {
		setCreatePdPContactData({ ...createPdPContactData, data: data })
		CreatePdPContact.open()
	}
	const openAddPdPContact = () => {
		AddPdPContact.open()
	}

	const recoveryPdpContacts = (res) => {
		if (!needRefresh) return
		const { data, problem } = res

		if (problem) return

		if (data) {
			setListContacts(data)
			setNeedRefresh(false)
		}
	}

	const deletionPdpContacts = (res) => {
		const { data, problem } = res

		if (problem) {
			if (data) enqueueSnackbar(t('error') + ` : ${data.status} ! `, { variant: 'error' })
			return
		}

		if (data) {
			setNeedRefresh(true)
			setRefreshChevrons(!refreshChevrons)
			enqueueSnackbar(t('TheContactHasBeenDeleted'), {
				variant: 'info',
			})
		}
		setNeedRefresh(true)
	}

	useEffect(() => {
		api
			.get(bindParams(PDP_CONTACTS, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(recoveryPdpContacts)
	}, [needRefresh])

	const AddContact = () => {
		const classes = useStyles()

		return (
			<>
				<Fab
					classes={{ root: classes.fab, label: classes.addIcon }}
					color="secondary"
					onClick={openAddPdPContact}
				>
					<AddIcon className={classes.addIcon} />
				</Fab>
			</>
		)
	}

	const deleteAction = (id) => {
		if (!id) return
		api
			.delete(bindParams(DELETE_PDP_CONTACTS, cookies[PLATFORM_ID_COOKIE], pdpId, id))
			.then(deletionPdpContacts)
	}

	return (
		<>
			{AddPdPContact.formular}
			{CreatePdPContact.formular}
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('contacts')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('preventionPlanNo')} {pdpNumber}
			</Typography>
			<hr className={classes.hrRoot} />
			<Grid container spacing={32} direction="row">
				{listContacts &&
					listContacts.map((contact) => {
						return (
							<ContactPdPCard
								size={3}
								isReadOnly={isReadOnly}
								key={contact.utlId}
								data={{
									firstName: contact.utlPrenom,
									name: contact.utlNom,
									email: contact.utlEmail,
									entity: contact.cppEntite,
									id: contact.utlId,
									platformId: cookies[PLATFORM_ID_COOKIE],
									companyRecordId: cookies[COMPANY_ID_COOKIE],
								}}
								openCreatePdPContactWithData={openCreatePdPContactWithData}
								labelDeleteMessage={t('SureToDeleteContact')}
								deleteAction={deleteAction}
							/>
						)
					})}
			</Grid>
			{!isReadOnly && <AddContact />}
			<div className={buttonsStyle.root}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.previous} onClick={previousStep}>
							{t('previous')}
						</Button>
					</Grid>
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.next} onClick={nextStep}>
							{t('next')}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	)
}

Contacts.propTypes = {
	buttonsStyle: PropTypes.object,
	setStepStatus: PropTypes.func,
	pdpId: PropTypes.string,
	isReadOnly: PropTypes.bool,
	revisionNumber: PropTypes.string,
	pdpNumber: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
}

export default Contacts
