import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import FormIcon from '../Form/FormIcon'
import SlidingForm from '../Form/SlidingForm'
import InputRadio, { TotalRadio } from '../Form/input/InputRadio'
import { Typography, Grid, Paper } from '@material-ui/core'
import FormButton from '../Form/FormButton'
import InputText from '../Form/input/InputText'
import InputMail from '../Form/input/InputMail'
import { useTranslation } from 'react-i18next'
import { composeValidators } from '../../utils/Form'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { required, nameSize, emailSize } from '../../utils/VerifyFields'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { createAccount } from '../../containers/DataContainer'
import Access from '../../containers/RoleContainer'
import ROLES, { findRoleById } from '../../constants/roles'
import LANG from '../../constants/lang'
import PropTypes from 'prop-types'
import api from '../../utils/Requester'
import { ACCOUNTINFOS } from '../../constants/url'
import PlatformPicker from '../PlatformPicker/PlatformPicker'
import { ACCOUNT_ID_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	rootInputLeft: {
		marginTop: '12.5px',
		marginBottom: '12.5px',
		width: '90%',
		display: 'inline-block',
	},
	rootInputRight: {
		marginTop: '12.5px',
		marginBottom: '12.5px',
		width: '90%',
		display: 'inline-block',
	},
	leftPane: {
		textAlign: 'center',
	},
	rightPane: {
		textAlign: 'center',
	},
	part: {
		marginTop: theme.spacing.unit,
		padding: theme.spacing.unit,
		border: 'solid 1px ' + theme.palette.secondary.light,
		borderRadius: 15,
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '95%',
		marginLeft: '2.5%',
		padding: 20,
		borderRadius: 10,
	},
	typography: {
		display: 'block',
		color: theme.palette.secondary.main,
		fontSize: 18,
	},
	language: {
		marginTop: theme.spacing.unit,
		display: 'inline-flex',
	},
	radio: {
		marginTop: 0,
	},
	validateRoot: {
		display: 'flex',
		marginTop: theme.spacing.unit * 2,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	title: {
		padding: theme.spacing.unit,
	},
	box: {
		margin: theme.spacing.unit,
		borderRadius: 5,
		border: 'solid 1px ' + theme.palette.secondary.light,
		padding: theme.spacing.unit,
	},
	groupCheckbox: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	button: {
		marginTop: theme.spacing.unit * 2,
		marginLeft: '45%',
	},
}))

/**
 * @description Basic account creation formular
 * @param {boolean} open : It's used to open the formular
 * @param {function} close : It's used to close the formular
 * @param {any} data : It's used to insert default data into the formular
 * @returns {JSX.Element} React stack to render
 */
function OtherUserInfoForm({ open, close }) {
	const { t } = useTranslation(['user', 'lang', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [sites, setSites] = useState([{}])
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		api.get(ACCOUNTINFOS + cookies[ACCOUNT_ID_COOKIE]).then((res) => {
			const { data, ok } = res

			if (!ok) return
			if (data) {
				const sites = data.accountTypePerPlatform.filter(
					(x) =>
						x.accountTypes.findIndex(
							(y) => y.code === ROLES.ADMIN.code || y.code === ROLES.PLATFORMMANAGER.code,
						) !== -1,
				)
				setSites(sites)
			}
		})
	}, [])

	function onSubmit(values) {
		let language
		switch (values.language) {
			case 'fr':
				language = 1
				break
			case 'en':
				language = 2
				break
			default:
				break
		}
		let data = {
			lastName: values.lastname,
			firstName: values.firstname,
			email: values.email,
			languageId: language,
		}
		let accountTypePerPlatform = sites
			.map((site) => {
				if (!values['roles-' + site.platformId]) return
				let roles = JSON.parse(values['roles-' + site.platformId])
				roles = roles.map((role) => ({
					id: role.id,
					code: findRoleById(role.id)[0].code,
				}))
				return {
					accountTypes: roles,
					platformId: site.platformId,
				}
			})
			.filter((site) => site !== undefined)
		if (accountTypePerPlatform.length > 0)
			data = { ...data, accountTypePerPlatform: accountTypePerPlatform }
		createAccount(data, function (res, error) {
			if (error) {
				error.data.forEach((error) => {
					enqueueSnackbar(t(error.code), { variant: 'error' })
					console.error(error)
				})
			}
			if (res) {
				enqueueSnackbar(t('AccountHasBeenCreated'), { variant: 'success' })
				close()
			}
		})
	}

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			onClose={close}
			title={t('InformationsAboutTheUser')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
		>
			<Form
				initialValues={{ language: 'fr' }}
				className={classes.form}
				onSubmit={onSubmit}
				formCallback
			>
				{(form) => (
					<>
						<Grid container justify="center" spacing={8} className={classes.parent}>
							<Grid item md={6} sm={12} className={classes.leftPane}>
								<Paper className={classes.part}>
									<AccountBoxIcon color="primary" />
									<Typography variant="h6" color="primary">
										{t('Profil')}
									</Typography>
									<InputText
										name="lastname"
										label={t('lastname')}
										classes={{ root: classes.rootInputLeft }}
										validate={composeValidators(required, nameSize)}
									/>
									<InputText
										name="firstname"
										label={t('firstname')}
										classes={{ root: classes.rootInputLeft }}
										validate={composeValidators(required, nameSize)}
									/>
									<InputMail
										name="email"
										label={t('email')}
										classes={{ root: classes.rootInputLeft }}
										validate={composeValidators(required, emailSize)}
									/>
								</Paper>
							</Grid>

							<Grid item md={6} sm={12} className={classes.rightPane}>
								<Paper className={classes.part}>
									<div className={classes.language}>
										<InputRadio classes={{ root: classes.radio }} name="language">
											<TotalRadio
												name="language"
												label={t('lang:' + LANG.fr.label)}
												value={LANG.fr.code}
												image={LANG.fr.icon}
												labelPlacement="bottom"
											/>
											<TotalRadio
												name="language"
												label={t('lang:' + LANG.en.label)}
												value={LANG.en.code}
												image={LANG.en.icon}
												labelPlacement="bottom"
											/>
										</InputRadio>
									</div>
								</Paper>
							</Grid>
						</Grid>

						<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
							<br />
							<br />
							<Typography align="center" variant="h5" color="secondary">
								{t('platforms')}
							</Typography>
							<br />
							<div>
								<div className={classes.groupCheckbox}>
									{sites.map((site, i) => {
										return <PlatformPicker key={i} platform={site} form={form} />
									})}
								</div>
							</div>
						</Access>

						<FormButton type="submit" label={t('submit')} classes={{ root: classes.button }} />
					</>
				)}
			</Form>
		</SlidingForm>
	)
}
OtherUserInfoForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}
export default OtherUserInfoForm
