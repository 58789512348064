import '@babel/polyfill'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/styles'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import TotalLoader from './components/TotalLoader'
import { theme } from './Theme'
import './i18n'
import { MuiThemeProvider } from '@material-ui/core'
import { store, persistor } from './store/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

ReactDOM.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persistor}>
			<Suspense fallback={<TotalLoader />}>
				<ThemeProvider theme={theme}>
					<MuiThemeProvider theme={theme}>
						<App />
					</MuiThemeProvider>
				</ThemeProvider>
			</Suspense>
		</PersistGate>
	</Provider>,
	document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
