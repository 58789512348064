import DeleteForever from '@material-ui/icons/DeleteForever'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import CreateCompanyContact from '../../../components/forms/PreventionPlanForms/CreateCompanyContact'
import DeleteCompany from '../../../components/forms/PreventionPlanForms/DeleteCompany'
import OperationManagerChoice from '../../../components/forms/PreventionPlanForms/OperationManagerChoice'
import PrincipalCoordinatorChoice from '../../../components/forms/PreventionPlanForms/PrincipalCoordinatorChoice'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { getPdPDraftListCompanies } from '../../../containers/DataContainer'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import PropTypes from 'prop-types'
import { COLUMNS_PDP, getIndexOfColumn } from '../../../utils/Datatable'
import { PLATFORM_CONFIGURATION } from '../../../constants/url'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
	deleteIcon: {
		color: theme.palette.secondary.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	addButton: {
		width: '1em',
		verticalAlign: 'middle',
		margin: 5,
		color: theme.palette.secondary.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
}))

function DTDEDraft({ pdpId, refreshDatatable, setRefreshDatatable }) {
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const [modalState, setModalState] = useState(false)
	const [result, setResult] = useState({ columns: [], data: [], options: {} })
	const [modalData, setModalData] = useState({})
	const [managerChoiceForm, setmanagerChoiceForm] = useState(false)
	const [managerChoiceData, setManagerChoiceData] = useState(false)
	const [principalCoordinatorChoiceForm, setPrincipalCoordinatorChoiceForm] = useState(false)
	const [principalCoordinatorChoiceData, setPrincipalCoordinatorChoiceData] = useState(false)
	const [createCompanyContactForm, setCreateCompanyContactForm] = useState(false)
	const [isLoading, setIsLoading] = useState(true)
	const [isPrincipalEditable, setIsPrincipalEditable] = useState(false)

	useEffect(() => {
		setIsLoading(true)
		getPdPDraftListCompanies(
			cookies[PLATFORM_ID_COOKIE],
			pdpId,
			{
				platformId: cookies[PLATFORM_ID_COOKIE],
				nbItemsAsked: 10,
				pageNumber: 1,
			},
			function (res, err) {
				if (err || !res) return
				let tempRes = res.data
				let newColumns = {
					name: 'companyid',
					label: t('delete'),
					options: {
						display: 'true',
						filter: false,
						filterOptions: null,
						searchable: false,
						sort: false,
					},
				}
				tempRes.columns = [...tempRes.columns, newColumns]
				setResult(tempRes)
				setIsLoading(false)
			},
		)
	}, [refreshDatatable, pdpId, cookies, t])

	useEffect(() => {
		getPlatformConfiguration()
	}, [])

	const recoverPlatformConfiguration = (res) => {
		const { data, problem } = res
		if (problem) {
			enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
		} else {
			data.forEach((line) => {
				if (line.translationCode == 'MassiveDOAssignation') setIsPrincipalEditable(line.value)
			})
		}
	}

	const getPlatformConfiguration = () => {
		api
			.get(bindParams(PLATFORM_CONFIGURATION, cookies[PLATFORM_ID_COOKIE]))
			.then(recoverPlatformConfiguration)
	}

	const delCompany = (pPdpId, companyId) => {
		setModalData({ pdpId: pPdpId, companyId: companyId })
		setModalState(true)
	}

	const OpenForm = (companyName, companyId) => {
		setmanagerChoiceForm(true)
		setManagerChoiceData({
			companyName: companyName,
			pdpId: pdpId,
			companyId: companyId,
		})
	}

	const OpenPrincipalForm = (companyName, companyId) => {
		setPrincipalCoordinatorChoiceForm(true)
		setPrincipalCoordinatorChoiceData({
			companyName: companyName,
			pdpId: pdpId,
			companyId: companyId,
		})
	}

	return (
		<>
			<DeleteCompany
				open={modalState}
				closeModal={setModalState}
				data={modalData}
				refreshDatatable={refreshDatatable}
				setRefreshDatatable={setRefreshDatatable}
			/>
			<OperationManagerChoice
				open={managerChoiceForm}
				setmanagerChoiceForm={setmanagerChoiceForm}
				setCreateCompanyContactForm={setCreateCompanyContactForm}
				managerChoiceData={managerChoiceData}
				refreshDatatable={refreshDatatable}
				setRefreshDatatable={setRefreshDatatable}
			/>
			{isPrincipalEditable != 0 && (
				<PrincipalCoordinatorChoice
					open={principalCoordinatorChoiceForm}
					setPrincipalCoordinatorChoiceForm={setPrincipalCoordinatorChoiceForm}
					principalCoordinatorChoiceData={principalCoordinatorChoiceData}
					refreshDatatable={refreshDatatable}
					setRefreshDatatable={setRefreshDatatable}
				/>
			)}

			<CreateCompanyContact
				open={createCompanyContactForm}
				setCreateCompanyContactForm={setCreateCompanyContactForm}
				managerChoiceData={managerChoiceData}
				refreshDatatable={refreshDatatable}
				setRefreshDatatable={setRefreshDatatable}
			/>
			<DataTable
				title={t('companies')}
				columns={result.columns}
				data={result.data}
				options={result.options}
				isLoading={isLoading}
				customColumns={[
					{
						name: 'companyid',
						component: function ComponentDeleteForever(value) {
							return (
								<DeleteForever
									className={classes.deleteIcon}
									onClick={() => {
										delCompany(pdpId, value)
									}}
								/>
							)
						},
					},
					{
						name: 'operationresponsablefullname',
						component: function componentPersonAdd(value, tableMeta) {
							return (
								<>
									{value}

									<PersonAdd
										className={classes.addButton}
										onClick={() => {
											OpenForm(
												tableMeta.rowData[
													getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYFULLNAME)
												],
												tableMeta.rowData[getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYID)],
											)
										}}
									/>
								</>
							)
						},
					},
					{
						name: 'principalcoordinator',
						component: function componentPersonAdd(value, tableMeta) {
							return (
								isPrincipalEditable != 0 && (
									<>
										{value}

										<PersonAdd
											className={classes.addButton}
											onClick={() => {
												OpenPrincipalForm(
													tableMeta.rowData[
														getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYFULLNAME)
													],
													tableMeta.rowData[
														getIndexOfColumn(result.columns, COLUMNS_PDP.COMPANYID)
													],
												)
											}}
										/>
									</>
								)
							)
						},
					},
				]}
				customLabels={[
					{
						name: 'companyfullname',
						label: t('companyName'),
					},
					{
						name: 'responsablefullname',
						label: t('responsablefullname'),
					},
					{
						name: 'operationresponsablefullname',
						label: t('OperationManager'),
					},
					{
						name: 'creatorfullname',
						label: t('creatorfullname'),
					},
					isPrincipalEditable != 0 && {
						name: 'principalcoordinator',
						label: t('principal'),
					},
					{
						name: 'delete',
						label: t('delete'),
					},
				]}
			/>
		</>
	)
}
DTDEDraft.propTypes = {
	pdpId: PropTypes.string,
	refreshDatatable: PropTypes.bool,
	setRefreshDatatable: PropTypes.object,
}

export default DTDEDraft
