import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/MailOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import DeleteForever from '@material-ui/icons/DeleteForever'
import MoreVert from '@material-ui/icons/MoreVert'
import IconButton from '@material-ui/core/IconButton'
import {
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// STYLES
const useStyles = makeStyles((theme) => ({
	card: {
		minHeight: 180,
		position: 'relative',
	},
	cardIcon: {
		position: 'absolute',
		top: 5,
		right: 5,
		cursor: 'mouse',
	},
	text: {
		color: theme.palette.secondary.main,
	},
	separator: {
		width: '90%',
		height: 1,
		border: 'none',
		backgroundColor: '#d6d6d6',
	},
	icons: {
		color: 'black',
	},
	cardHeader: {
		paddingBottom: 5,
	},
	deleteIcon: {
		marginLeft: 'auto',
		display: 'block',
		width: '1.3em',
		height: '1.3em',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	entity: {
		fontWeight: 500,
		fontSize: 15,
		margin: 0,
		padding: 0,
	},
	paper: {
		margin: 'auto',
		marginTop: '20%',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	title: {
		fontSize: 16,
		paddingRight: '10%',
		display: 'block',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	item: {
		padding: 0,
		margin: 0,
	},
	cardText: {
		color: 'black !important',
		textDecoration: 'none',
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		fontSize: 11,
		margin: 0,
		padding: 0,
	},
	listItem: {
		margin: 0,
		padding: 0,
		paddingTop: 10,
	},
	noMarginPadding: {
		margin: 0,
		padding: 0,
	},
}))
/**
 * Shows a Contact card
 * @param {int} size : Size of the card
 * @param {any} data : Object with the data
 * @returns {JSX.Element} React stack to render
 */
const ContactPdPCard = ({
	size,
	data,
	de,
	openCreatePdPContactWithData,
	isReadOnly,
	type,
	labelDeleteMessage,
	deleteAction,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const deleteCard = () => {
		deleteAction(data.id)
		handleClose()
	}

	const MessageDeleteContact = () => {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"> {t('warning')} !</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{labelDeleteMessage}</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteCard()
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	const openForm = () => {
		openCreatePdPContactWithData(data)
	}

	return (
		<Grid item xs={12} sm={6} md={4} lg={size}>
			<MessageDeleteContact />
			<Card className={classes.card}>
				<MuiCardHeader
					title={
						<Typography className={classes.title} variant="h6" color="secondary">
							{data.firstName} {data.name}
						</Typography>
					}
					titleTypographyProps={{ color: 'inherit' }}
					className={classes.cardHeader}
				/>
				<hr className={classes.separator} />
				<CardContent>
					<List className={classes.noMarginPadding}>
						<ListItem className={classes.listItem}>
							<ListItemIcon>
								<MailIcon className={classes.icons} />
							</ListItemIcon>
							<ListItemText className={classes.item}>
								<a
									className={classes.cardText}
									href={`mailto:${data.email}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.email}
								</a>
							</ListItemText>
						</ListItem>
						{data.phone && (
							<ListItem className={classes.listItem}>
								<ListItemIcon>
									<PhoneIcon className={classes.icons} />
								</ListItemIcon>
								<ListItemText>
									<a
										className={classes.cardText}
										href={`tel:${data.phone}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data.phone}
									</a>
								</ListItemText>
							</ListItem>
						)}
						{!de && (
							<ListItem className={classes.listItem}>
								<p className={classes.entity}>{t('entity')} :</p>
								<ListItemText>
									{data.entity ? (
										<p className={classes.cardText}> {data.entity} </p>
									) : (
										<p className={classes.cardText}> {t('noEntity')} </p>
									)}
								</ListItemText>
							</ListItem>
						)}
					</List>
					{openCreatePdPContactWithData && !isReadOnly && (
						<IconButton className={classes.cardIcon} aria-label="Edit" onClick={openForm}>
							<MoreVert className={classes.icons} />
						</IconButton>
					)}
					{!isReadOnly && (
						<DeleteForever
							classes={{ root: classes.deleteIcon }}
							onClick={handleOpen}
							color="secondary"
						/>
					)}
				</CardContent>
			</Card>
		</Grid>
	)
}

export default ContactPdPCard
