import React, { useState, useEffect } from 'react'
import { Button, Typography, Paper, Grid, Fab } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Pie } from 'react-chartjs-2'
import AddIcon from '@material-ui/icons/Add'
import { useSocialReasonEditorForm } from '../../../containers/FormContainer'
import AddMajorCompagnies from '../../../components/forms/PreventionPlanForms/AddMajorCompanies'
import DeleteCompany from '../../../components/forms/PreventionPlanForms/DeleteCompany'
import { useCookies } from 'react-cookie'
import DTDE from './DTDE'
import DTDEDraft from './DTDEDraft'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import { PDPSTATS, PDPDRAFTLIST } from '../../../constants/url'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import PropTypes from 'prop-types'

const CSS_INLINE_BLOCK = 'inline-block'

const useStyles = makeStyles((theme) => ({
	pieContainer: {
		display: CSS_INLINE_BLOCK,
		width: 130,
		verticalAlign: 'middle',
	},
	statsContainer: {
		borderRadius: 5,
		padding: 4,
		display: 'flex',
	},
	stats: {
		border: '1px solid ' + theme.palette.secondary.main,
		borderRadius: 5,
		margin: 5,
		display: 'inline-flex',
		width: '25%',
		paddingTop: 12,
	},
	statsWithPie: {
		border: '1px solid ' + theme.palette.secondary.main,
		borderRadius: 5,
		margin: 5,
		display: CSS_INLINE_BLOCK,
		width: '25%',
		paddingTop: 12,
	},
	categoryTitle: {
		transform: 'translateY(-22px) translateX(3px)',
		position: 'absolute',
		backgroundColor: theme.palette.primary.main,
		paddingLeft: 3,
		paddingRight: 3,
		color: theme.palette.secondary.main,
	},
	mainStatNumber: {
		display: CSS_INLINE_BLOCK,
		margin: 4,
		marginBottom: 0,
		fontSize: '2em',
		fontWeight: 600,
		color: theme.palette.secondary.main,
		textAlign: 'center',
	},
	secondaryStatNumber: {
		display: CSS_INLINE_BLOCK,
		color: theme.palette.secondary.main,
		textAlign: 'center',
	},
	mainStatText: {
		fontSize: '1.5em',
		fontWeight: 600,
		color: theme.palette.secondary.main,
		textAlign: 'center',
	},
	secondaryStatText: {
		color: theme.palette.secondary.main,
		textAlign: 'center',
	},
	divider: {
		border: 'solid 1px ' + theme.palette.secondary.main,
		display: CSS_INLINE_BLOCK,
		height: '100%',
		opacity: 0.2,
	},
	statsContent: {
		width: '49.5%',
		display: CSS_INLINE_BLOCK,
		padding: 5,
	},
	icon: {
		verticalAlign: 'middle',
	},
	title: {
		fontWeight: 500,
		margin: 5,
	},
	buttonContainer: {
		display: CSS_INLINE_BLOCK,
		maxWidth: '70%',
		verticalAlign: 'middle',
		transform: 'translateY(-13px)',
	},
	fab: {
		display: 'flex',
		marginLeft: 'auto',
	},
	title2: {
		display: CSS_INLINE_BLOCK,
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: CSS_INLINE_BLOCK,
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
}))

function ParticipatingCompanies({
	buttonsStyle,
	pdpId,
	revisionNumber,
	setRevisionNumber,
	setRedirectRevision,
	isReadOnly,
	refreshChevrons,
	setRefreshChevrons,
	isNotCreate,
	pdpState,
	pdpNumber,
	nextStep,
	previousStep,
}) {
	const [filterMode] = useState(false)
	const { t } = useTranslation(['user', 'pdpParticipatingCompanies', 'pdp', 'common'])
	const socialReasonEditor = useSocialReasonEditorForm()
	const [deleteCompany, setDeleteCompany] = useState(false)
	const [addMajorCompagnies, setAddMajorCompagnies] = useState(false)
	const [cookies] = useCookies()
	const [stateData, setStateData] = useState(null)
	const [refreshDatatable, setRefreshDatatable] = useState(false)
	const classes = useStyles()
	const [stats, setStats] = useState({
		datasets: [
			{
				data: [0, 0],
				backgroundColor: ['#4B96D1', '#134391'],
				hoverBackgroundColor: ['#746F66', '#746F66'],
			},
		],
	})

	useEffect(() => {
		getPdPStats()

		api
			.post(bindParams(PDPDRAFTLIST, cookies[PLATFORM_ID_COOKIE], pdpId), {
				platformId: cookies[PLATFORM_ID_COOKIE],
				nbItemsAsked: 10,
				pageNumber: 1,
			})
			.then(recoveryPdpDraftCompaniesList)
	}, [])

	const recoveryPdpStats = (res) => {
		if (isNotCreate) return
		if (pdpState === 1) return

		const { data, problem } = res

		if (problem) return

		if (data) {
			setStats({
				datasets: [
					{
						data: [data.principalCompaniesCount, data.subContractorCompaniesCount],
						backgroundColor: ['#4B96D1', '#134391'],
						hoverBackgroundColor: ['#746F66', '#746F66'],
					},
				],
			})
			setStateData(data)
		}
	}

	const recoveryPdpDraftCompaniesList = (res) => {
		if (pdpState !== 1) return
		const { problem } = res

		if (problem) return
	}

	const getPdPStats = () => {
		api.get(bindParams(PDPSTATS, cookies[PLATFORM_ID_COOKIE], pdpId)).then(recoveryPdpStats)
	}

	const OpenFormMajorCompanies = () => {
		setAddMajorCompagnies(true)
	}

	const AddCompanies = () => {
		return (
			<>
				<Fab classes={{ root: classes.fab }} color="secondary" onClick={OpenFormMajorCompanies}>
					<AddIcon className={classes.addIcon} />
				</Fab>
			</>
		)
	}

	const Stats = () => {
		return (
			<Paper className={classes.statsContainer}>
				<div className={classes.statsWithPie}>
					<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
						{t('common:companies')}
					</Typography>
					<Typography classes={{ root: classes.mainStatNumber }}>
						{stateData.companiesCount}
					</Typography>
					<Typography classes={{ root: classes.secondaryStatNumber }}>
						{t('common:companies')}
					</Typography>
					<div className={classes.pieContainer}>
						{stateData && (
							<Pie
								data={{
									...stats,
									labels: [
										t('pdpParticipatingCompanies:principalCompanies'),
										t('pdpParticipatingCompanies:subContractingCompanies'),
									],
								}}
								height={150}
								width={200}
								options={{
									maintainAspectRatio: false,
									legend: {
										position: 'bottom',
									},
								}}
							/>
						)}
					</div>
				</div>
				<div className={classes.stats}>
					<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
						{t('user:Meetings')}
					</Typography>
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.convenedCompaniesCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:haveBeenConvened')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.preliminaryVisitCompaniesCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:haveCompletedVisit')}
						</Typography>
					</div>
					<div className={classes.divider} />
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.coordinationMeetingCompaniesCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:emergedCoordinationMeeting')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.companyValidatingEveryting}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:company_validating_requirements')}
						</Typography>
					</div>
				</div>
				<div className={classes.stats}>
					<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
						{t('user:CompanyFiles')}
					</Typography>
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.validatedCompanyRecordsCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:valids')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.pendingCompanyRecordsCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:toBeValidated')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.refusedCompanyRecordsCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:refuseds')}
						</Typography>
					</div>
					<div className={classes.divider} />
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.waitAboutCompaniesCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:waitingCompany')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.waitWritingCompaniesCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:writingCompany')}
						</Typography>
					</div>
				</div>
				<div className={classes.stats}>
					<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
						{t('user:ExportDWP')}
					</Typography>
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.synchroDwpCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:synchrosDWP')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.currentInSentCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:beingIssued')}
						</Typography>
					</div>
					<div className={classes.divider} />
					<div className={classes.statsContent}>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.derogationCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:exemption')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.doNotSendDwpCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:refusalIssue')}
						</Typography>
						<Typography classes={{ root: classes.mainStatText }}>
							{stateData.notSendDwpCount}
						</Typography>
						<Typography classes={{ root: classes.secondaryStatText }}>
							{t('user:toBeIssued')}
						</Typography>
					</div>
				</div>
			</Paper>
		)
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title2 }}>
				{t('pdpParticipatingCompanies:participatingCompanies')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('pdp:preventionPlanNo', { pdpNumber: pdpNumber })}
			</Typography>
			<hr className={classes.hrRoot} />
			{socialReasonEditor.formular}
			{!filterMode && pdpState > 1 && stateData && <Stats></Stats>}
			<DeleteCompany open={deleteCompany} setCreateCompanyContactForm={setDeleteCompany} />
			<AddMajorCompagnies
				refreshDatatable={refreshDatatable}
				setRefreshDatatable={setRefreshDatatable}
				open={addMajorCompagnies}
				setAddMajorCompagnies={setAddMajorCompagnies}
				refreshChevrons={refreshChevrons}
				pdpId={pdpId}
				revisionNumber={revisionNumber}
				setRefreshChevrons={setRefreshChevrons}
			/>
			{pdpState === 1 ? (
				<DTDEDraft
					pdpId={pdpId}
					refreshDatatable={refreshDatatable}
					setRefreshDatatable={setRefreshDatatable}
				/>
			) : (
				<DTDE
					pdpId={pdpId}
					revisionNumber={revisionNumber}
					setRevisionNumber={setRevisionNumber}
					setRedirectRevision={setRedirectRevision}
					isReadOnly={isReadOnly}
					refreshDatatable={refreshDatatable}
					getPdPStats={getPdPStats}
				/>
			)}
			{!isReadOnly && <AddCompanies />}
			<div className={buttonsStyle.root}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.previous} onClick={previousStep}>
							{t('common:previous')}
						</Button>
					</Grid>
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.next} onClick={nextStep}>
							{t('common:next')}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	)
}
ParticipatingCompanies.propTypes = {
	accountId: PropTypes.object,
	buttonsStyle: PropTypes.object,
	pdpId: PropTypes.string,
	revisionNumber: PropTypes.string,
	setRevisionNumber: PropTypes.func,
	redirectRevision: PropTypes.object,
	setRedirectRevision: PropTypes.func,
	isReadOnly: PropTypes.bool,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	isNotCreate: PropTypes.bool,
	pdpState: PropTypes.number,
	pdpNumber: PropTypes.string,
	nextStep: PropTypes.object,
	previousStep: PropTypes.object,
}
export default ParticipatingCompanies
