import React, { useEffect, useState } from 'react'
import {
	Button,
	Typography,
	Grid,
	Paper,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core'
import Form from '../../components/Form/Form'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { useSnackbar } from 'notistack'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { CHECKLIST_COMPANY_RECORD, CHECKLISTITEM_COMPANY_RECORD } from '../../constants/url'
import InputTextArea from '../../components/Form/inputFullPage/InputTextArea'
import InputText from '../../components/Form/inputFullPage/InputText'
import { required } from '../../utils/VerifyFields'
import AddCircle from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	formCheckList: {
		margin: 40,
		padding: 20,
		backgroundColor: theme.palette.primary.main,
	},
	container: {
		display: 'block',
	},
	submitButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginBottom: 40,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
	},
	textArea: {
		margin: theme.spacing.unit * 2,
		marginLeft: 0,
	},
	dFlex: {
		display: 'flex',
	},
	titleCenterVertical: {
		alignSelf: 'center',
	},
	btnIcon: {
		marginLeft: 5,
	},
	addIcon: {
		width: '1.5em',
		height: '1.5em',
	},
	popupTitle: {
		color: theme.palette.secondary.main,
		margin: 25,
		marginBottom: 0,
	},
	dialog: {
		paddingTop: 0,
	},
	checklistItems: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: '75%',
	},
	form: {
		width: '100% !important',
	},
}))

function ChecklistCompanyRecord() {
	const { t } = useTranslation(['user', 'activityPhaseManagement'])
	const [cookies] = useCookies()
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [initialValues, setInitialValues] = useState({})
	const [checkListItems, setCheckListItems] = useState([])
	const [currentEditIndex, setCurrentEditIndex] = useState(null)

	const [openAddCheckListPopUp, setAddOpenCheckListPopUp] = useState(false)
	const [openEditCheckListPopUp, setEditOpenCheckListPopUp] = useState(false)

	const ERROR_MESSAGE = 'user:error'

	useEffect(() => {
		getCheckListInformations()
	}, [])

	const getCheckListInformations = () => {
		api.get(bindParams(CHECKLIST_COMPANY_RECORD, cookies[PLATFORM_ID_COOKIE])).then((res) => {
			const { data, ok } = res

			if (!ok) return

			if (data) {
				updateFormValues(data.presentationText, data.checkListItems)
			}
		})
	}

	const updateFormValues = (presentationText, pCheckListItems) => {
		setInitialValues({
			presentationText: presentationText,
		})
		setCheckListItems(pCheckListItems)
	}

	const CheckListPopUp = (props) => {
		const { onClose, open, onSubmit: onSubmitEvent, defaultValue } = props
		return (
			<Dialog open={open} onClose={onClose} classes={{ paper: classes.popup }}>
				<Typography classes={{ root: classes.popupTitle }} variant="h6">
					{t('new_document')}
				</Typography>
				<Form onSubmit={onSubmitEvent} initialValues={defaultValue}>
					<DialogContent className={classes.dialog}>
						<InputText
							classes={{ root: classes.textArea }}
							name="document"
							label={t('user:document_name')}
							validate={required}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							{t('cancel')}
						</Button>
						<Button type="submit" color="secondary" autoFocus>
							{t('validate')}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		)
	}
	CheckListPopUp.propTypes = {
		onClose: PropTypes.func,
		open: PropTypes.bool,
		onSubmit: PropTypes.func,
		defaultValue: PropTypes.object,
	}

	const addCheckListItem = (values) => {
		let formData = {
			checkListItem: values.document,
		}
		api
			.post(bindParams(CHECKLIST_COMPANY_RECORD, parseInt(cookies[PLATFORM_ID_COOKIE])), formData)
			.then((res) => {
				const { problem } = res

				if (problem) return enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })

				handleClose()
				getCheckListInformations()
				return enqueueSnackbar(t('user:checklist_new_item'), { variant: 'success' })
			})
	}

	const editCheckListItem = (values) => {
		let formData = {
			checkListItemId: checkListItems[currentEditIndex].checkListItemId,
			checkListIdFk: checkListItems[currentEditIndex].checkListIdFk,
			document: values.document,
		}
		api
			.put(
				bindParams(
					CHECKLISTITEM_COMPANY_RECORD,
					parseInt(cookies[PLATFORM_ID_COOKIE]),
					parseInt(formData.checkListItemId),
				),
				formData,
			)
			.then((res) => {
				const { problem } = res

				if (problem) return enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })

				setCurrentEditIndex(null)
				handleClose()
				getCheckListInformations()
				return enqueueSnackbar(t('user:checklist_edit_item'), { variant: 'success' })
			})
	}

	const handleClickOpen = () => {
		setAddOpenCheckListPopUp(true)
	}
	const handleClose = () => {
		setAddOpenCheckListPopUp(false)
		setEditOpenCheckListPopUp(false)
	}

	const onSubmit = (values) => {
		let formData = {
			presentationText: values.presentationText,
		}
		api
			.post(bindParams(CHECKLIST_COMPANY_RECORD, parseInt(cookies[PLATFORM_ID_COOKIE])), formData)
			.then((res) => {
				const { problem } = res

				if (problem) return enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })

				return enqueueSnackbar(t('user:checklist_form_success'), { variant: 'success' })
			})
	}

	const onClickEditCheckListItem = (index) => {
		setEditOpenCheckListPopUp(true)
		setCurrentEditIndex(index)
	}
	const onClickDeleteCheckListItem = (index) => {
		let formData = {
			checkListItemId: checkListItems[index].checkListItemId,
			checkListIdFk: checkListItems[index].checkListIdFk,
			document: checkListItems[index].document,
		}
		api
			.delete(
				bindParams(
					CHECKLISTITEM_COMPANY_RECORD,
					parseInt(cookies[PLATFORM_ID_COOKIE]),
					parseInt(formData.checkListItemId),
				),
			)
			.then((res) => {
				const { problem } = res
				if (problem) return enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })

				getCheckListInformations()
				return enqueueSnackbar(t('user:checklist_delete_item'), { variant: 'success' })
			})
	}

	return (
		<>
			<CheckListPopUp
				open={openAddCheckListPopUp}
				onSubmit={addCheckListItem}
				onClose={() => {
					setAddOpenCheckListPopUp(false)
				}}
			/>
			<CheckListPopUp
				open={openEditCheckListPopUp}
				onSubmit={editCheckListItem}
				defaultValue={
					checkListItems && checkListItems[currentEditIndex]
						? { document: checkListItems[currentEditIndex].document }
						: null
				}
				onClose={() => {
					setEditOpenCheckListPopUp(false)
				}}
			/>
			<HeadTitle title={t('checklist_company_record')} />
			<Form onSubmit={onSubmit} initialValues={initialValues} className={classes.form}>
				<Grid container justify="center" className={classes.container} spacing={8}>
					<Paper className={classes.formCheckList}>
						<Grid item xs={12}>
							<Typography variant="h6" color="secondary">
								{t('presentation_text')}
							</Typography>
							<InputTextArea
								classes={{ root: classes.textArea }}
								name="presentationText"
								label={t('user:presentation_text')}
								validate={required}
							/>
							<Button classes={{ root: classes.submitButton }} label={t('edit')} type="submit">
								{t('save')}
							</Button>
						</Grid>
						<Grid item md={10} xs={12}></Grid>
						<Grid item xs={12} className={classes.dFlex}>
							<Typography variant="h6" color="secondary" className={classes.titleCenterVertical}>
								{t('checklist_document')}
							</Typography>
							<IconButton className={classes.btnIcon} onClick={handleClickOpen}>
								<AddCircle className={classes.addIcon} />
							</IconButton>
						</Grid>
						<Grid item xs={12}>
							<List>
								{checkListItems &&
									checkListItems.map((child, index) => (
										<ListItem className={classes.checklistItems} key={index}>
											<ListItemText primary={child.document} />
											<ListItemSecondaryAction>
												<IconButton
													edge="start"
													aria-label="edit"
													onClick={() => onClickEditCheckListItem(index)}
												>
													<EditIcon />
												</IconButton>
												<IconButton
													edge="end"
													aria-label="delete"
													onClick={() => onClickDeleteCheckListItem(index)}
												>
													<DeleteIcon />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))}
							</List>
						</Grid>
					</Paper>
				</Grid>
			</Form>
		</>
	)
}

export default ChecklistCompanyRecord
