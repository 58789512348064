import React, { useState, useEffect } from 'react'
import { ACCOUNT_TYPES_COOKIE, PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import FormStep from '../../components/Form/FormStep'
import TotalArrowStepper from '../../components/TotalArrowsStepper/TotalArrowsStepper'
import classNames from 'classnames'
import Contacts from './Contacts/Contacts'
import Documents from './Documents/Documents'
import ParticipatingCompanies from './ParticipatingCompanies/ParticipatingCompanies'
import GeneralInformationsForm from '../../components/forms/PreventionPlanForms/generalInformationsForm'
import ParticipatingCompaniesRestrictedPdP from '../../components/forms/PreventionPlanForms/ParticipatingCompaniesRestrictedPdP'
import Communications from './Communications/Communications'
import Diffusion from './Diffusion/Diffusion'
import GeneralInformationsRestrictedPdPForm from '../../components/forms/PreventionPlanForms/GeneralInformationsRestrictedPdPForm'
import OperationAreas from './OperationAreas/OperationAreas'
import { Button, CircularProgress } from '@material-ui/core'
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import CompanyRecord from '../CompanyRecord/CompanyRecord'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { CHEVRONSSTATES, PDPVALIDATION } from '../../constants/url'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	nextBtn: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	rootBtn: {
		display: 'flex',
	},
	stepperButton: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 50,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	buttonFont: {
		fontSize: 12,
		color: 'white',
	},
	button: {
		maxWidth: 200,
		marginTop: 3,
		marginLeft: 15,
		boxShadow: '0px 0px 0px 0px',
	},
	buttonText: {
		['@media (max-width:1150px)']: {
			// eslint-disable-line no-useless-computed-key
			display: 'none',
		},
	},
	tooltipRoot: {
		zIndex: '20000',
		['@media (min-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			display: 'none',
		},
	},
	rightIcon: {
		['@media (max-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			fontSize: '3em',
		},
	},
	buttonProgressWhite: {
		color: '#ffffff',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	colorOpacity: {
		color: 'rgba(255,255,255,0.3)',
	},
}))

export const typePdP = {
	RESTRICTED: {
		value: 'R',
		id: 3,
	},
	PERMANENT: {
		value: 'P',
		id: 1,
	},
	SPECIFIC: {
		value: 'S',
		id: 2,
	},
}

export const statePdP = {
	DRAFT: {
		id: 1,
	},
	VALIDE: {
		id: 2,
	},
	DIFFUSED: {
		id: 3,
	},
	ARCHIVED: {
		id: 4,
	},
}

function PreventionPlan({ match }) {
	const { t } = useTranslation('user')
	const [cookies] = useCookies()
	const classes = useStyles()
	const [stepStatus, setStepStatus] = useState('')
	const [currentPdPType, setTypePdP] = useState(
		cookies[ACCOUNT_TYPES_COOKIE][0].code === 'PRINCIPAL' ? typePdP.RESTRICTED : typePdP.PERMANENT,
	)	
	const isAdminOrManager = cookies[ACCOUNT_TYPES_COOKIE].some(
		(ur) => ur.code === 'ADMIN' || ur.code === 'PLATFORMMANAGER',
	)
	const [completedSteps, setCompletedSteps] = useState([])
	const [isReadOnly, setIsReadOnly] = useState(false)
	const [isNotRevisedReadOnly, setIsNotRevisedReadOnly] = useState(false)
	const [isNotCreate, setIsNotCreate] = useState(false)
	const [isNotAreaSelected, setIsNotAreaSelected] = useState(true)
	const [revisionNumber, setRevisionNumber] = useState(match.params.revisionId)
	const [pdpId, setPdpId] = useState(match.params.pdpId)
	const [refreshChevrons, setRefreshChevrons] = useState(false)
	const [pdpNumber, setPdPNumber] = useState(null)
	const [pdpState, setPdpState] = useState(null)

	const { enqueueSnackbar } = useSnackbar()
	const [companyId, setCompanyId] = useState(null)
	const [companyRecordId, setCompanyRecordId] = useState(null)
	const [redirectRevision, setRedirectRevision] = useState(false)

	const [loading, setLoading] = useState(false)

	const GENERAL_INFOS = 'general-infos'
	const OPERATION_AREAS = 'operation-areas'

	const recoveryChevronsState = (res) => {
		const { data, problem } = res

		if (isNotCreate) return
		if (problem) {
			enqueueSnackbar(data.status.toString(), { variant: 'error' })
			return
		}
		if (data) {
			setIsReadOnly(data.isReadOnly)
			setIsNotRevisedReadOnly(data.isNotRevisedReadOnly)
			setCompletedSteps(data.chevronStates)
		}
	}

	const sendingPdpValidation = (res) => {
		const { data, problem, status } = res

		setLoading(false)
		setIsReadOnly(false)

		if (problem) {
			if (data && Array.isArray(data)) {
				enqueueSnackbar(t('error') + ' : ' + t(data[0].code), { variant: 'error' })
				return
			}
			if (data) enqueueSnackbar(t('error') + ':' + data.status, { variant: 'error' })
			return
		}

		if (data || status === 200) {
			setPdpState(2)
			enqueueSnackbar(t('pdPHasBeenValidated'), { variant: 'info' })
		}
	}

	const validatePdp = () => {
		setLoading(true)
		setIsReadOnly(true)
		api
			.post(bindParams(PDPVALIDATION, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(sendingPdpValidation)
	}

	useEffect(() => {
		if (!match.params.pdpId || !match.params.revisionId) {
			setIsNotCreate(true)
			return
		}
		callChevronState()
		setIsNotCreate(false)
	}, [pdpId])

	useEffect(() => {
		setRefreshChevrons(true)
	}, [companyRecordId])

	useEffect(() => {
		if (pdpId) callChevronState()
	}, [refreshChevrons])

	const callChevronState = () => {
		api
			.get(bindParams(CHEVRONSSTATES, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(recoveryChevronsState)
	}

	useEffect(() => {
		if (completedSteps) {
			let completedStepsOperationAreas = completedSteps.find(
				(c) => c.chevronCode === OPERATION_AREAS,
			)
			if (completedStepsOperationAreas && completedStepsOperationAreas.isValidated)
				setIsNotAreaSelected(false)
		}
	}, [completedSteps])

	let STEPS = []

	// STEP 1
	if (currentPdPType.value !== typePdP.RESTRICTED.value) {
		STEPS.push({
			label: t('GeneralInformations'),
			chevronCode: GENERAL_INFOS,
			component: (
				<GeneralInformationsForm
					pdpId={pdpId}
					setStepStatus={setStepStatus}
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					setTypePdP={setTypePdP}
					currentPdPType={currentPdPType}
					isReadOnly={isReadOnly}
					setIsReadOnly={setIsReadOnly}
					isNotRevisedReadOnly={isNotRevisedReadOnly}
					setIsNotRevisedReadOnly={setIsNotRevisedReadOnly}
					setIsNotCreate={setIsNotCreate}
					isNotCreate={isNotCreate}
					revisionNumber={revisionNumber}
					setRevisionNumber={setRevisionNumber}
					setPdpId={setPdpId}
					setPdPNumber={setPdPNumber}
					setPdpState={setPdpState}
					pdpState={pdpState}
					redirectRevision={redirectRevision}
					setRedirectRevision={setRedirectRevision}
				/>
			),
		})
	} else {
		STEPS.push({
			label: t('GeneralInformations'),
			chevronCode: GENERAL_INFOS,
			component: (
				<GeneralInformationsRestrictedPdPForm
					pdpId={pdpId}
					revisionNumber={revisionNumber}
					setRevisionNumber={setRevisionNumber}
					setPdpId={setPdpId}
					setPdPNumber={setPdPNumber}
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					setTypePdP={setTypePdP}
					currentPdPType={currentPdPType}
					setCompanyId={setCompanyId}
					setCompanyRecordId={setCompanyRecordId}
					companyId={companyId}
					setPdpState={setPdpState}
					companyRecordId={companyRecordId}
				/>
			),
		})
	}

	// STEP 2
	STEPS.push({
		label: t('operationsAreas'),
		chevronCode: OPERATION_AREAS,
		disabled: isNotCreate,
		component: (
			<OperationAreas
				pdpId={pdpId}
				setStepStatus={setStepStatus}
				buttonsStyle={{
					root: classes.rootBtn,
					previous: classNames(classes.stepperButton),
					next: classNames(classes.stepperButton),
					completed: classNames(classes.stepperButton),
				}}
				isReadOnly={isReadOnly}
				pdpNumber={pdpNumber}
				refreshChevrons={refreshChevrons}
				setRefreshChevrons={setRefreshChevrons}
				revisionNumber={revisionNumber}
				setRevisionNumber={setRevisionNumber}
				redirectRevision={redirectRevision}
				setRedirectRevision={setRedirectRevision}
				setIsNotAreaSelected={setIsNotAreaSelected}
			/>
		),
	})

	// Step 3
	if (currentPdPType.value !== typePdP.RESTRICTED.value) {
		STEPS.push({
			label: t('participatingCompanies'),
			chevronCode: 'companies',
			disabled: isNotCreate,
			component: (
				<ParticipatingCompanies
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					isReadOnly={isReadOnly}
					pdpNumber={pdpNumber}
					refreshChevrons={refreshChevrons}
					setRefreshChevrons={setRefreshChevrons}
					pdpId={pdpId}
					revisionNumber={revisionNumber}
					setRevisionNumber={setRevisionNumber}
					setRedirectRevision={setRedirectRevision}
					isNotCreate={isNotCreate}
					pdpState={pdpState}
				/>
			),
		})
	} else {
		STEPS.push({
			label: t('participatingCompany'),
			chevronCode: 'companies',
			disabled: isNotCreate || isNotAreaSelected,
			component: (
				<ParticipatingCompaniesRestrictedPdP
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					pdpId={pdpId}
					revisionNumber={revisionNumber}
					refreshChevrons={refreshChevrons}
					setRefreshChevrons={setRefreshChevrons}
					setCompanyId={setCompanyId}
				/>
			),
		})
	}
	// Step 4
	STEPS.push({
		label: t('contacts'),
		chevronCode: 'contacts',
		disabled: isNotCreate || isNotAreaSelected,
		component: (
			<Contacts
				pdpId={pdpId}
				buttonsStyle={{
					root: classes.rootBtn,
					previous: classNames(classes.stepperButton),
					next: classNames(classes.stepperButton),
					completed: classNames(classes.stepperButton),
				}}
				setStepStatus={setStepStatus}
				isReadOnly={isReadOnly}
				revisionNumber={revisionNumber}
				pdpNumber={pdpNumber}
				refreshChevrons={refreshChevrons}
				setRefreshChevrons={setRefreshChevrons}
				match={match}
			/>
		),
	})

	// Step 5
	STEPS.push({
		label: t('Documents'),
		chevronCode: 'documents',
		disabled: isNotCreate || isNotAreaSelected,
		component: (
			<Documents
				pdpId={pdpId}
				revisionNumber={revisionNumber}
				buttonsStyle={{
					root: classes.rootBtn,
					previous: classNames(classes.stepperButton),
					next: classNames(classes.stepperButton),
					completed: classNames(classes.stepperButton),
				}}
				isReadOnly={isReadOnly}
				pdpNumber={pdpNumber}
				refreshChevrons={refreshChevrons}
				setRefreshChevrons={setRefreshChevrons}
				pdpState={pdpState}
			/>
		),
	})

	// Step 6
	if (
		currentPdPType.value !== typePdP.RESTRICTED.value &&
		pdpState > 1 &&
		cookies[ACCOUNT_TYPES_COOKIE][0].code !== 'EXTCOMP' &&
		cookies[ACCOUNT_TYPES_COOKIE][0].code !== 'EXTCOMPADMIN'
	) {
		STEPS.push({
			label: t('Communications'),
			chevronCode: 'communication',
			disabled: isNotCreate,
			component: (
				<Communications
					setStepStatus={setStepStatus}
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					isReadOnly={isReadOnly}
					revisionNumber={revisionNumber}
					pdpId={pdpId}
					pdpNumber={pdpNumber}
					refreshChevrons={refreshChevrons}
					setRefreshChevrons={setRefreshChevrons}
				/>
			),
		})
	}

	if (currentPdPType.value === typePdP.RESTRICTED.value) {
		STEPS.push({
			label: t('DE'),
			chevronCode: 'companyRecord',
			disabled: isNotCreate || companyId === 0,
			component: (
				<CompanyRecord
					match={{
						params: {
							companyRecordId: companyRecordId,
							companyId: companyId,
							pdpId,
							platformId: cookies[PLATFORM_ID_COOKIE],
							pdpRevisionId: revisionNumber,
							restrictedPdPContext: true,
						},
					}}
					setCompanyRecordId={setCompanyRecordId}
				/>
			),
		})
	}

	if (
		pdpState > 1 &&
		cookies[ACCOUNT_TYPES_COOKIE][0].code !== 'EXTCOMP' &&
		cookies[ACCOUNT_TYPES_COOKIE][0].code !== 'EXTCOMPADMIN'
	) {
		STEPS.push({
			label: t('Diffusion'),
			chevronCode: 'diffusion',
			disabled: isNotCreate,
			component: (
				<Diffusion
					revisionNumber={revisionNumber}
					pdpId={pdpId}
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classNames(classes.stepperButton),
						next: classNames(classes.stepperButton),
						completed: classNames(classes.stepperButton),
					}}
					isReadOnly={isReadOnly}
					pdpNumber={pdpNumber}
					refreshChevrons={refreshChevrons}
					setRefreshChevrons={setRefreshChevrons}
				/>
			),
		})
	}

	if (
		currentPdPType.value !== typePdP.RESTRICTED.value &&
		!isNotCreate &&
		pdpState === 1 &&
		completedSteps.length &&
		completedSteps.find((c) => c.chevronCode === GENERAL_INFOS).isValidated &&
		completedSteps.find((c) => c.chevronCode === OPERATION_AREAS).isValidated &&
		completedSteps.find((c) => c.chevronCode === 'companies').isValidated
	) {
		STEPS.push({
			label: t('button'),
			chevronCode: 'button',
			type: 'button',
			disabled: isNotCreate,
			component: (
				<Button
					disabled={loading}
					variant="contained"
					color="secondary"
					classes={{ label: classes.buttonFont, root: classes.button }}
					onClick={() => {
						if (isNotCreate === true) return
						validatePdp()
					}}
				>
					{loading && <CircularProgress size={24} className={classes.buttonProgressWhite} />}
					<CheckCircleOutline className={classes.rightIcon} />
					<span className={classes.buttonText}>{t('makePdPValid')}</span>
				</Button>
			),
		})
	}

	return (
		<>
			{!isAdminOrManager && pdpState === 4 &&	<Redirect to={'/followPdp'} />}
			{redirectRevision && <Redirect to={'/preventionPlan/' + pdpId + '/' + revisionNumber} />}			
			<TotalArrowStepper
				contentStyle={classes.contentStyle}
				stepStatus={stepStatus}
				setStepStatus={setStepStatus}
				currentPdPType={currentPdPType}
				completedSteps={completedSteps}
				icon="remove_red_eye"
				title={t('oversight')}
				content={t('WelcomeToYourPdP')}
			>
				{STEPS.map((step) => (
					<FormStep
						label={step.label}
						chevronCode={step.chevronCode}
						key={step.chevronCode}
						type={step.type}
						disable={step.disabled}
					>
						{step.component}
					</FormStep>
				))}
			</TotalArrowStepper>
		</>
	)
}
PreventionPlan.propTypes = {
	match: PropTypes.object,
}

export default PreventionPlan
