import React from 'react'

function CGUContentFr(props) {
	return (
		<div>
			<h1 style={{ fontWeight: 'bold' }}>
				Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation (CGU)
			</h1>
			<p>
				Bienvenue &agrave; l&rsquo;adresse{' '}
				<a href="https://rapso-d.totalenergies.com/">https://rapso-d.totalenergies.com/</a>{' '}
				(ci-apr&egrave;s &laquo;&nbsp;Site Internet&nbsp;&raquo;). En vous connectant ou en
				utilisant notre site internet, vous reconnaissez avoir lu, compris, et accept&eacute;, sans
				limitation ni r&eacute;serve le pr&eacute;sent document (ci-apr&egrave;s
				&laquo;&nbsp;Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation&nbsp;&raquo;
				ou&nbsp;&laquo;&nbsp;CGU&nbsp;&raquo;) en cochant la case pr&eacute;vue &agrave; cet effet
				lors de votre premi&egrave;re connexion sur le Site Internet. Vous vous engagez
				&eacute;galement &agrave; prendre connaissance de la{' '}
				<u>charte donn&eacute;es personnelles et cookies</u> (
				<a href="https://rapso-d.totalenergies.com/dashboard">
					https://rapso-d.totalenergies.com/dashboard
				</a>
				) disponible sur le Site Internet. Veuillez noter que si vous visitez d&rsquo;autres sites
				internet de la compagnie TotalEnergies, d'autres conditions g&eacute;n&eacute;rales
				d&rsquo;utilisation et chartes donn&eacute;es personnelles et cookies, ou tout autre
				document relatif &agrave; la protection des donn&eacute;es personnelles, sont applicables
				sur ces sites internet et nous vous recommandons de les consulter.
			</p>
			<p>&nbsp;</p>
			<p>
				<strong>
					Les soci&eacute;t&eacute;s de la compagnie TotalEnergies ont leur propre existence
					l&eacute;gale et disposent d&rsquo;une personnalit&eacute; juridique autonome. La
					soci&eacute;t&eacute; &eacute;ditrice du Site Internet (ci-apr&egrave;s &laquo; Editeur du
					Site Internet&nbsp;&raquo;) appartient &agrave; la compagnie TotalEnergies. Les termes
					&laquo; TotalEnergies &raquo;, &laquo; compagnie TotalEnergies &raquo; et &laquo;
					Compagnie &raquo; d&eacute;signent de fa&ccedil;on g&eacute;n&eacute;rale TotalEnergies SE
					et/ou toutes ses filiales ; une filiale &eacute;tant une soci&eacute;t&eacute; dans
					laquelle TotalEnergies SE d&eacute;tient directement ou indirectement la majorit&eacute;
					des droits de vote. Il est rappel&eacute; que chaque soci&eacute;t&eacute; de la Compagnie
					a sa propre existence l&eacute;gale et dispose d'une personnalit&eacute; juridique
					autonome. De m&ecirc;me, les termes &laquo; nous &raquo;, &laquo; notre &raquo;, &laquo;
					nos &raquo; font r&eacute;f&eacute;rence &agrave; l&rsquo;Editeur du Site Internet ou
					toute autre soci&eacute;t&eacute; de la compagnie TotalEnergies en g&eacute;n&eacute;ral.
					Il ne peut &ecirc;tre d&eacute;duit de la simple utilisation de ces expressions une
					quelconque implication de TotalEnergies SE, ni d&rsquo;aucune de ses filiales dans les
					affaires ou la gestion d&rsquo;une autre soci&eacute;t&eacute; de la compagnie
					TotalEnergies.{' '}
				</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<h2>1. Mentions l&eacute;gales</h2>
			<p>&nbsp;</p>
			<p>
				<strong>1.1. Editeur du Site Internet</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				TotalEnergies Raffinage Chimie, SAS au capital de 933 605 625,50 &euro;, dont le
				si&egrave;ge social est situ&eacute; au 2 place Jean Millier &ndash; La D&eacute;fense 6
				&ndash; 92400 Courbevoie et immatricul&eacute;e au registre du commerce et des
				soci&eacute;t&eacute;s de Nanterre sous le num&eacute;ro FR63692004807.
			</p>
			<p>&nbsp;</p>
			<p>Adresse email&nbsp;: ot-Rapso-D.admin@totalenergies.com</p>
			<p>T&eacute;l&eacute;phone&nbsp;: 33 + 6 30 56 70 66</p>
			<p>Directeur de la publication&nbsp;: TotalEnergies OneTech</p>
			<p>&nbsp;</p>
			<p>
				<strong>1.2. H&eacute;bergeur du Site Internet</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>Total Global Information Technology Services,</p>
			<p>
				SAS au capital de 6 577 787 euros, dont le si&egrave;ge social est situ&eacute; 2 place Jean
				Millier &ndash; La D&eacute;fense 6 &ndash; 92400 Courbevoie et immatricul&eacute;e au
				Greffe de Nanterre sous le num&eacute;ro 531 680 437
			</p>
			<p>&nbsp;</p>
			<p>T&eacute;l&eacute;phone : 33 + 6 30 56 70 66</p>
			<p>&nbsp;</p>
			<h2>2. D&eacute;finitions</h2>
			<p>&nbsp;</p>
			<p>
				Les termes et expressions identifi&eacute;s par une majuscule dans les CGU ont la
				signification indiqu&eacute;e ci-apr&egrave;s, qu&rsquo;ils soient utilis&eacute;s au
				singulier ou au pluriel :
			</p>
			<p>&nbsp;</p>
			<p>
				&laquo;&nbsp;<strong>Compte</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe l&rsquo;espace
				unique r&eacute;serv&eacute; &agrave; l&rsquo;Utilisateur, accessible &agrave; l&rsquo;issue
				de son inscription et lui permettant d&rsquo;utiliser les services propos&eacute;s sur le
				Site Internet.
			</p>
			<p>&nbsp;</p>
			<p>
				&laquo;&nbsp;<strong>Contribution</strong>&nbsp;&raquo; : d&eacute;signe tout type de
				contenu (notamment texte, image, vid&eacute;o, son, etc.) publi&eacute; par un Utilisateur
				sur le Site Internet.
			</p>
			<p>&nbsp;</p>
			<p>
				&laquo;&nbsp;<strong>Utilisateur</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe toute personne
				disposant d&rsquo;un Compte et acc&eacute;dant au Site Internet.
			</p>
			<p>&nbsp;</p>
			<h2>3. Acc&egrave;s et inscription</h2>
			<p>&nbsp;</p>
			<p>
				Les CGU ont &eacute;t&eacute; &eacute;labor&eacute;es afin d&rsquo;encadrer l'utilisation du
				Site Internet. Elles compl&egrave;tent dans le domaine des technologies et de la
				communication les chartes et autres textes applicables au sein de la Compagnie : Code de
				conduite, r&egrave;glements int&eacute;rieurs, charte d'utilisation des ressources
				informatiques, etc.
			</p>
			<p>
				&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
			</p>
			<p>
				Tout Utilisateur du Site Internet doit respecter les principes &eacute;tablis par les CGU
				qui d&eacute;finissent un cadre g&eacute;n&eacute;ral et commun &agrave; tous pour
				&eacute;viter une utilisation inappropri&eacute;e du Site Internet. Elles s'appliquent
				&agrave; toutes les fonctionnalit&eacute;s du Site Internet.&nbsp;
			</p>
			<p>
				<u>&nbsp;</u>
			</p>
			<p>
				<strong>3.1 Conditions d&rsquo;acc&egrave;s au Site Internet</strong>
			</p>
			<p>&nbsp;</p>
			<p>
				Chaque Utilisateur d&eacute;clare se conformer aux CGU et aux lois en vigueur, et en
				particulier :
			</p>
			<ul>
				<li>
					disposer de la comp&eacute;tence et des moyens n&eacute;cessaires pour acc&eacute;der au
					Site Internet et l&rsquo;utiliser ;
				</li>
				<li>
					consentir &agrave; l&rsquo;Editeur du Site Internet et &agrave; ses partenaires le cas
					&eacute;ch&eacute;ant le droit de faire tout usage des informations fournies (autres que
					des donn&eacute;es &agrave; caract&egrave;re personnel) ;
				</li>
			</ul>
			<ul>
				<li>
					v&eacute;rifier que la configuration internet utilis&eacute;e ne contient aucun virus et
					est en parfait &eacute;tat de fonctionnement;
				</li>
			</ul>
			<ul>
				<li>
					devoir conserver confidentiels les codes d&rsquo;acc&egrave;s et mots de passe que
					l&rsquo;Editeur du Site Internet peut transmettre &agrave; l&rsquo;Utilisateur et
					&ecirc;tre en cons&eacute;quence responsable de l&rsquo;utilisation et de la
					s&eacute;curit&eacute; de ceux-ci. L&rsquo;Editeur du Site Internet se r&eacute;serve le
					droit de suspendre l&rsquo;acc&egrave;s au Site Internet en cas d'utilisation frauduleuse
					ou de tentative d'utilisation frauduleuse de cet acc&egrave;s.
				</li>
			</ul>
			<p>&nbsp;</p>
			<p>
				<strong>3.2 </strong>
				<strong>Proc&eacute;dure d&rsquo;inscription </strong>
				<strong>au Site Internet</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Pour utiliser le Site Internet, l&rsquo;Utilisateur doit cr&eacute;er un Compte en
				renseignant les informations demand&eacute;es.{' '}
				<strong>
					&nbsp;L&rsquo;Utilisateur recevra ensuite confirmation de son inscription lui permettant
					d&rsquo;acc&eacute;der et d&rsquo;utiliser la Plateforme.{' '}
				</strong>
				Il devra choisir un mot de passe qui soit suffisamment s&eacute;curis&eacute;. Une fois
				inscrit, l&rsquo;Utilisateur pourra se connecter &agrave; son Compte avec les identifiants
				renseign&eacute;s lors de son inscription.
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<h2>4. Utilisation et engagements de l&rsquo;Utilisateur</h2>
			<p>&nbsp;</p>
			<p>
				<strong>4.1 Utilisation du Site Internet</strong>
			</p>
			<p>&nbsp;</p>
			<p>Sur le Site Internet, l&rsquo;Utilisateur aura la possibilit&eacute; de&nbsp;:</p>
			<ul>
				<li>Consulter les informations de son profil utilisateur&nbsp;;</li>
				<li>
					Consulter les informations mise &agrave; disposition par TotalEnergies lui permettant
					d&rsquo;instruire sont dossier en vue du Plan de Pr&eacute;vention pour lequel son
					entreprise est sollicit&eacute;e&nbsp;;
				</li>
				<li>Soumettre son Dossier Entreprise&nbsp;;</li>
				<li>
					G&eacute;rer les &eacute;l&eacute;ments constitutifs des dossiers de son entreprise
					(d&eacute;claration des produits chimiques, modes op&eacute;ratoires&hellip;)&nbsp;;
				</li>
			</ul>
			<p>&nbsp;</p>
			<p>
				<strong>4.2 </strong>
				<strong>Engagements de l&rsquo;Utilisateur</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>L&rsquo;Utilisateur reconna&icirc;t que&nbsp;:</p>
			<ul>
				<li>
					les CGU s'appliquent &agrave; tout Utilisateur habilit&eacute; &agrave; utiliser le Site
					Internet;
				</li>
				<li>le Site Internet est destin&eacute; &agrave; une utilisation professionnelle&nbsp;;</li>
				<li>
					chaque Utilisateur est identifi&eacute; et s'exprime en son nom sur le Site
					Internet&nbsp;;
				</li>
				<li>
					chaque Utilisateur est personnellement et l&eacute;galement responsable des contenus,
					propos et informations qu'il choisit de partager ou diffuser via le Site Internet&nbsp;;
				</li>
				<li>
					il est interdit de diffuser, envoyer ou partager une information confidentielle ou
					sensible conform&eacute;ment aux obligations de loyaut&eacute;, de discr&eacute;tion et de
					confidentialit&eacute; incombant &agrave; chaque Utilisateur selon les r&egrave;gles qui
					leur sont applicables. Les Contributions au sein du Site Internet s'inscrivent dans le
					cadre de la Politique S&ucirc;ret&eacute; du Patrimoine Informationnel (PSPI) visant
					&agrave; prot&eacute;ger les donn&eacute;es sensibles g&eacute;n&eacute;r&eacute;es,
					d&eacute;tenues ou &eacute;chang&eacute;es par toutes les entit&eacute;s de la
					Compagnie&nbsp;;
				</li>
				<li>
					chaque Utilisateur qui constate un contenu inappropri&eacute; peut soit modifier
					directement dans l&rsquo;application les donn&eacute;es le concernant ou concernant son
					Entreprise (compte Op&eacute;rationnel) soit demander sa modification ou sa suppression en
					contactant le KeyUser Rapso-D du site auquel il se r&eacute;f&egrave;re&nbsp;;
				</li>
			</ul>
			<p>&nbsp;</p>
			<p>L&rsquo;Utilisateur s&rsquo;engage &eacute;galement &agrave;&nbsp;:</p>
			<ul>
				<li>
					prot&eacute;ger les donn&eacute;es personnelles conform&eacute;ment au droit applicable et
					aux normes internes.
				</li>
			</ul>
			<p>
				Il s'abstient de tout usage qui serait contraire &agrave; la r&eacute;glementation en
				vigueur, &agrave; l'ordre public et aux bonnes m&oelig;urs&nbsp;;
			</p>
			<ul>
				<li>
					fournir des informations exactes, compl&egrave;tes, sinc&egrave;res, non &eacute;quivoques
					et en assurer, en tant que de besoin, la mise &agrave; jour en cas de modifications&nbsp;;
				</li>
				<li>
					disposer de tous les droits et autorisations n&eacute;cessaires &agrave; la fourniture des
					informations et contenus fournis&nbsp;;
				</li>
			</ul>
			<p>&nbsp;</p>
			<h2>5. Propri&eacute;t&eacute; intellectuelle</h2>
			<p>&nbsp;</p>
			<p>
				<strong>5.1. Principe</strong>
			</p>
			<p>&nbsp;</p>
			<p>
				Toutes les informations ou documents (textes, images anim&eacute;es ou non, sons, photos,
				savoir-faire, produits cit&eacute;s) contenus dans le Site Internet ainsi que tous les
				&eacute;l&eacute;ments cr&eacute;&eacute;s pour le Site Internet et sa structure
				g&eacute;n&eacute;rale, sont soit la propri&eacute;t&eacute; de l&rsquo;Editeur du Site
				Internet ou de la compagnie TotalEnergies, soit font l'objet de droits d'utilisation, de
				reproduction et de repr&eacute;sentation consentis au profit de ces derniers.
			</p>
			<p>
				Ces informations et &eacute;l&eacute;ments sont soumis aux lois prot&eacute;geant le droit
				d'auteur d&egrave;s lors qu&rsquo;ils sont mis &agrave; la disposition du public sur ce Site
				Internet. Aucune licence ni aucun droit autre que celui de consulter le Site Internet n'est
				conf&eacute;r&eacute; &agrave; quiconque au regard des droits de propri&eacute;t&eacute;
				intellectuelle. La reproduction des informations est autoris&eacute;e &agrave; des fins
				exclusives d'information pour un usage personnel et priv&eacute;. Toute reproduction et
				toute utilisation de copie r&eacute;alis&eacute;e &agrave; d'autres fins est
				express&eacute;ment interdite et soumise &agrave; l'autorisation pr&eacute;alable et
				expresse de l&rsquo;Editeur du Site Internet. Dans tous les cas, la reproduction
				autoris&eacute;e des informations devra indiquer la source et la mention de
				propri&eacute;t&eacute; ad&eacute;quates.
			</p>
			<p>&nbsp;</p>
			<p>
				<strong>5.2. Signes distinctifs</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Sauf mention contraire, les d&eacute;nominations sociales, les logos, les produits et
				marques cit&eacute;s sur le Site Internet sont la propri&eacute;t&eacute; de l&rsquo;Editeur
				du Site Internet ou de la compagnie TotalEnergies, ou bien font l&rsquo;objet de droits
				d&rsquo;usage, de reproduction ou de repr&eacute;sentation qui ont &eacute;t&eacute;
				consentis &agrave; leur profit. Ils ne peuvent &ecirc;tre utilis&eacute;s sans
				l'autorisation &eacute;crite pr&eacute;alable de l&rsquo;Editeur du Site Internet.
			</p>
			<p>&nbsp;</p>
			<p>
				<strong>5.3. Bases de donn&eacute;es</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Les &eacute;ventuelles bases de donn&eacute;es mises &agrave; votre disposition sont la
				propri&eacute;t&eacute; de l&rsquo;Editeur du Site Internet qui a la qualit&eacute; de
				producteur de bases de donn&eacute;es. Il vous est interdit d&rsquo;extraire ou de
				r&eacute;utiliser une partie qualitativement ou quantitativement substantielle des bases de
				donn&eacute;es y compris &agrave; des fins priv&eacute;es.
			</p>
			<p>&nbsp;</p>
			<h2>6. Liens hypertextes</h2>
			<p>&nbsp;</p>
			<p>
				<strong>6.1. Activation des liens</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				L&rsquo;Editeur du Site Internet d&eacute;cline formellement toute responsabilit&eacute;
				quant aux contenus des sites internet vers lesquels il offre des liens ou vers lesquels les
				Utilisateurs redirigent dans leurs Contributions. Veuillez consulter les conditions
				g&eacute;n&eacute;rales d&rsquo;utilisation et la charte donn&eacute;es personnelles et
				cookies, ou tout autre document relatif &agrave; la protection des donn&eacute;es
				personnelles, de ces sites internet afin de comprendre leurs pratiques. La d&eacute;cision
				d'activer les liens appartient exclusivement aux Utilisateurs. L&rsquo;Editeur du Site
				Internet peut modifier ou supprimer un lien sur le Site Internet &agrave; tout moment.
			</p>
			<p>&nbsp;</p>
			<p>
				<strong>6.2. Autorisation des liens</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Si vous souhaitez cr&eacute;er un lien hypertexte redirigeant vers ce Site Internet, vous
				devez obtenir l'autorisation &eacute;crite et pr&eacute;alable de l&rsquo;Editeur du Site
				Internet en utilisant les coordonn&eacute;es de contact mentionn&eacute;es &agrave; la fin
				des CGU.
			</p>
			<p>&nbsp;</p>
			<h2>7. Mises en garde</h2>
			<p>&nbsp;</p>
			<p>
				<strong>7.1. Responsabilit&eacute;</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Les informations et recommandations disponibles sur ce Site Internet (ci-apr&egrave;s
				&laquo;&nbsp;Informations&nbsp;&raquo;) sont propos&eacute;es de bonne foi. Elles sont
				cens&eacute;es &ecirc;tre correctes au moment o&ugrave; elles sont publi&eacute;es sur le
				Site Internet. Toutefois, l&rsquo;Editeur du Site Internet ne garantit pas le
				caract&egrave;re exhaustif et l'exactitude de ces Informations. Vous assumez pleinement les
				risques li&eacute;s au cr&eacute;dit que vous leur accordez. Ces Informations vous sont
				fournies &agrave; la condition que vous ou toute autre personne qui les recevez puissiez
				d&eacute;terminer leur int&eacute;r&ecirc;t pour un objectif pr&eacute;cis avant de les
				utiliser. En aucun cas l&rsquo;Editeur du Site Internet ne sera responsable des dommages
				susceptibles de r&eacute;sulter du cr&eacute;dit accord&eacute; &agrave; ces Informations ou
				de leur utilisation. Ces Informations ne doivent pas &ecirc;tre consid&eacute;r&eacute;es
				comme des recommandations pour l'utilisation d'informations, de produits, de
				proc&eacute;dures, d'&eacute;quipements ou de formulations qui seraient en contradiction
				avec tout droit de propri&eacute;t&eacute; intellectuelle. L&rsquo;Editeur du Site Internet
				d&eacute;cline toute responsabilit&eacute;, expresse ou implicite, si l'utilisation de ces
				Informations venait &agrave; contrevenir &agrave; un droit de propri&eacute;t&eacute;
				intellectuelle.
			</p>
			<p>&nbsp;</p>
			<p>
				L&rsquo;Editeur du Site Internet, et toute soci&eacute;t&eacute; de la compagnie
				TotalEnergies rejette cat&eacute;goriquement toute interpr&eacute;tation qui viserait
				&agrave; assimiler le contenu de ses sites internet &agrave; des offres d'achat ou des
				incitations &agrave; acqu&eacute;rir des actions ou autres valeurs mobili&egrave;res
				cot&eacute;es ou non cot&eacute;es de l&rsquo;Editeur du Site Internet ou de toute autre
				soci&eacute;t&eacute; de la compagnie TotalEnergies. Aucune garantie, expresse ou implicite,
				n'est donn&eacute;e quant &agrave; la nature marchande des Informations fournies, ni quant
				&agrave; leur ad&eacute;quation &agrave; une finalit&eacute; d&eacute;termin&eacute;e, ainsi
				qu'en ce qui concerne les produits auxquels il est fait r&eacute;f&eacute;rence dans ces
				Informations. En aucun cas, l&rsquo;Editeur du Site Internet ne s'engage &agrave; mettre
				&agrave; jour ou &agrave; corriger les Informations qui seront diffus&eacute;es sur Internet
				ou sur ses serveurs web. L&rsquo;Editeur du Site Internet se r&eacute;serve le droit de
				modifier ou de corriger le contenu de ses sites internet &agrave; tout moment sans
				pr&eacute;avis.
			</p>
			<p>&nbsp;</p>
			<p>
				L&rsquo;Editeur du Site Internet ne garantit pas, sans que cette liste soit exhaustive, que
				le Site Internet fonctionne sans interruption et que les serveurs qui y donnent acc&egrave;s
				et/ou les sites tiers pour lesquels apparaissent des liens hypertextes ne contiennent pas de
				virus.
			</p>
			<p>&nbsp;</p>
			<p>
				<strong>7.2. Informations et d&eacute;clarations prospectives</strong>
			</p>
			<p>
				<strong>&nbsp;</strong>
			</p>
			<p>
				Les documents pr&eacute;sent&eacute;s sur ce Site Internet peuvent contenir des informations
				prospectives sur la Compagnie (notamment des objectifs et tendances), ainsi que des
				d&eacute;clarations prospectives (forward-looking statements) au sens du Private Securities
				Litigation Reform Act de 1995, concernant notamment la situation financi&egrave;re, les
				r&eacute;sultats d&rsquo;exploitation, les activit&eacute;s et la strat&eacute;gie de
				TotalEnergies.
			</p>
			<p>&nbsp;</p>
			<p>
				Les informations et d&eacute;clarations prospectives contenues dans ces documents sont
				fond&eacute;es sur des donn&eacute;es et hypoth&egrave;ses &eacute;conomiques
				formul&eacute;es dans un contexte &eacute;conomique, concurrentiel et r&eacute;glementaire
				donn&eacute;. Elles peuvent s&rsquo;av&eacute;rer inexactes dans le futur et
				d&eacute;pendent de facteurs de risques susceptibles de donner lieu &agrave; un &eacute;cart
				significatif entre les r&eacute;sultats r&eacute;els et ceux envisag&eacute;s, tels que
				notamment la variation des taux de change, le prix des produits p&eacute;troliers, la
				capacit&eacute; d&rsquo;effectuer des r&eacute;ductions de co&ucirc;ts ou des gains
				d&rsquo;efficacit&eacute; sans perturbation inopportune des op&eacute;rations, les
				consid&eacute;rations de r&eacute;glementations environnementales et des conditions
				&eacute;conomiques et financi&egrave;res g&eacute;n&eacute;rales. De m&ecirc;me, certaines
				informations financi&egrave;res reposent sur des estimations notamment lors de
				l&rsquo;&eacute;valuation de la valeur recouvrable des actifs et des montants des
				&eacute;ventuelles d&eacute;pr&eacute;ciations d&rsquo;actifs.
			</p>
			<p>&nbsp;</p>
			<p>
				Ni TotalEnergies ni aucune de ses filiales ne prennent l&rsquo;engagement ou la
				responsabilit&eacute; vis-&agrave;-vis des investisseurs ou de toute autre partie prenante
				de mettre &agrave; jour ou de r&eacute;viser, en particulier en raison d&rsquo;informations
				nouvelles et/ou d&rsquo;&eacute;v&eacute;nements futurs, tout ou partie des
				d&eacute;clarations, informations prospectives, tendances ou objectifs contenus dans ces
				documents. Des informations suppl&eacute;mentaires concernant les facteurs, risques et
				incertitudes susceptibles d&rsquo;avoir un effet sur les r&eacute;sultats financiers ou les
				activit&eacute;s de la Compagnie sont par ailleurs disponibles dans les versions les plus
				actualis&eacute;es du Document de r&eacute;f&eacute;rence / Document d&rsquo;enregistrement
				universel d&eacute;pos&eacute; en France aupr&egrave;s de l&rsquo;Autorit&eacute; des
				march&eacute;s financiers et du annual report on form 20-F d&eacute;pos&eacute; aux
				Etats-Unis d&rsquo;Am&eacute;rique aupr&egrave;s de la Securities and Exchange Commission
				(&laquo; SEC &raquo;).
			</p>
			<p>&nbsp;</p>
			<h2>8. Mises &agrave; jour des CGU et loi applicable</h2>
			<p>&nbsp;</p>
			<p>
				L&rsquo;Editeur du Site Internet peut proc&eacute;der &agrave; la mise &agrave; jour des CGU
				du Site Internet &agrave; tout moment.
			</p>
			<p>
				En cons&eacute;quence, l&rsquo;Utilisateur est invit&eacute; &agrave; se
				r&eacute;f&eacute;rer r&eacute;guli&egrave;rement aux derni&egrave;res CGU en vigueur
				disponibles sur le Site Internet.
			</p>
			<p>&nbsp;</p>
			<p>
				Si une ou plusieurs dispositions des CGU s'av&eacute;raient non applicables ou interdites en
				vertu d'une r&eacute;glementation d'ordre public, les autres dispositions des CGU non
				affect&eacute;es demeureront applicables &agrave; l'Utilisateur dans la mesure permise par
				cette r&egrave;glementation.&nbsp;
			</p>
			<p>&nbsp;</p>
			<p>
				Les CGU sont soumises au droit fran&ccedil;ais et rel&egrave;vent de la comp&eacute;tence
				des tribunaux fran&ccedil;ais comp&eacute;tents.
			</p>
			<p>&nbsp;</p>
			<h2>9. Contact</h2>
			<p>&nbsp;</p>
			<p>
				Pour toute question relative aux CGU du Site Internet, vous pouvez nous contacter &agrave;
				l&rsquo;adresse email suivante ot-Rapso-D.admin@totalenergies.com ou &agrave;
				l&rsquo;adresse postale suivante&nbsp;:
			</p>
			<p>TotalEnergies OneTech</p>
			<p>2 place Jean Millier</p>
			<p>La D&eacute;fense 6</p>
			<p>92400 Courbevoie</p>
			<p>&nbsp;</p>
		</div>
	)
}

export default CGUContentFr
