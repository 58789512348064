import React from 'react'
import FormIcon from '../../../../components/Form/FormIcon'
import InputText from '../../../../components/Form/input/InputText'
import InputMail from '../../../../components/Form/input/InputMail'
import SlidingForm from '../../../../components/Form/SlidingForm'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import Form from '../../../Form/Form'
import { composeValidators } from '../../../../utils/Form'
import { required, maxLong } from '../../../../utils/VerifyFields'
import InputSelectField from '../../../Form/input/InputSelect'
import SelectMenuItem from '../../../Form/input/SelectMenuItem'

const useStyles = makeStyles(theme => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function CreateCompanyFilesContact({ open, close, data }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()

	const onSubmit = values => {
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('contacts:AddContact')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{
				lastname : data.name,
				firstname : data.firstName,
				mail : data.email,
				entity : data.entity
			}}>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="lastname"
					label={t('lastname')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="firstname"
					label={t('firstname')}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="mail"
					label={t('email')}
				/>
				<InputSelectField
					name="entity"
					label="Entité"
					classes={{
						root: classes.size1,
					}}
					validate={composeValidators(required)}
				>
					<SelectMenuItem  value={1}>
                bouchon 1 
					</SelectMenuItem>
					<SelectMenuItem  value={2}>
                bouchon 2 
					</SelectMenuItem>
					<SelectMenuItem  value={3}>
                bouchon 3
					</SelectMenuItem>
				</InputSelectField>
				<div className={classes.validateGroup}>
					<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
				</div>
			</Form>
		</SlidingForm>
	)
}

export default CreateCompanyFilesContact
