const resourceFolder = '/icons/contact_types/'
const resourceExtension = '.svg'

const icon = (name) => resourceFolder + name + resourceExtension

export const findContactById = (id) =>
	Object.values(CONTACTS).filter((c) => {
		if (c.id === id) return c
		return null
	})

const CONTACTS = {
	DIRECTOR: { id: 1, icon: icon('director') },
	AGENCYMANAGER: { id: 2, icon: icon('agency_manager') },
	SSTMANAGER: { id: 3, icon: icon('sst_manager') },
	OCPPHYSICIAN: { id: 4, icon: icon('ocp_physician') },
	STAFFREPRESENTATIVE: { id: 5, icon: icon('staff_representative') },
	HSEMANAGER: { id: 6, icon: icon('hse_manager') },
	NONE: { id: 7, icon: icon('none') },
	PRODUCTCONTACT: { id: 8, icon: icon('product_contact') },
	OPERATIONMANAGER: { id: 9, icon: icon('operation_manager') },
	TECHNICALMANAGER: { id: 10, icon: icon('technical_manager') },
}
export default CONTACTS
