import React, { useState, useEffect } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	Typography,
	Button,
	Icon,
	IconButton,
	DialogTitle,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { upsertCompanyRecordOperationalMode, downloadFile } from '../../../containers/DataContainer'
import {
	COLUMNS_OPERATIONAL_PROCEDURES,
	convertArrayToDatatable,
	getIndexOfColumn,
} from '../../../utils/Datatable'
import { useSnackbar } from 'notistack'
import fileDownload from 'js-file-download'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import { OPERATIONALMODE } from '../../../constants/url'
import { managedFileNotFoundError } from '../../../utils/File'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: 14,
		right: 300,
	},
	icon: {
		color: theme.palette.secondary.main,
		textAlign: 'left',
		marginLeft: 35,
		width: '100%',
	},
}))

function OperationalProcedures({
	forms,
	btnsStyle,
	queries,
	isReadOnly,
	isReviewMode,
	setRevisionCompanyRecordId,
	nextStep,
	previousStep,
	refreshChevrons,
	setRefreshChevrons,
}) {
	const classes = useStyles()
	const [update, setUpdate] = useState(false)
	const [SCs, setSCs] = useState({ columns: [], data: [], options: {} })
	const [listProcedure, setListProcedure] = useState([])

	const [OperationalProceduresForm, AddOperationalProceduresForm] = forms
	const { companyRecordId, companyId, platformId } = queries
	const [isLoading, setIsLoading] = useState(true)
	const [confirmPopUp, setConfirmPopUp] = useState({ open: false, path: null })

	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()

	const updateData = () => {
		setUpdate(!update)
	}

	const openOperationalProceduresForm = () => {
		OperationalProceduresForm.addData({
			listProcedure,
			updateData: updateData,
			companyRecordId: companyRecordId,
			openAddOPForm: openAddOPForm,
			companyId: companyId,
			refreshChevrons,
			setRefreshChevrons,
		})
		OperationalProceduresForm.open()
	}

	const openAddOPForm = () => {
		AddOperationalProceduresForm.open()
		AddOperationalProceduresForm.addData({
			listProcedure: listProcedure,
			updateData: updateData,
			companyRecordId: companyRecordId,
			companyId: companyId,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
		})
	}

	//#region OperationalProcedures
	const deleteOperationalProcedures = (procedurePath) => {
		let data = listProcedure.filter((procedure) => {
			return procedure.path !== procedurePath
		})
		setListProcedure([...data])

		const formData = new FormData()
		for (let index = 0; index < data.length; index++) {
			if (data[index].dateAdded) formData.append(`files[${index}].dateAdded`, data[index].dateAdded)
			if (data[index].fileName) formData.append(`files[${index}].fileName`, data[index].fileName)
			if (data[index].path) formData.append(`files[${index}].path`, data[index].path)
			if (data[index].username) formData.append(`files[${index}].username`, data[index].username)
			if (data[index].environmentalAspectCategory)
				formData.append(
					`files[${index}].environmentalAspectCategory`,
					data[index].environmentalAspectCategory,
				)
		}

		upsertCompanyRecordOperationalMode(
			platformId,
			companyRecordId,
			formData,
			function (res, error) {
				if (res) {
					setRefreshChevrons(!refreshChevrons)
					updateData()
					enqueueSnackbar(t('modeOpDeleted'), {
						variant: 'info',
					})
					if (res.data && res.data.companyRecordId)
						data.setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (error) {
					enqueueSnackbar(`${t('error')} : ${error.status} !`, { variant: 'error' })
				}
			},
		)
	}

	//#endregion OperationalProcedures

	//#region Datatable
	const download = (path, name) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				fileDownload(res.data, name)
			}
		})
	}

	const recoverDatatable = () => {
		setIsLoading(true)
		api
			.get(bindParams(OPERATIONALMODE, cookies[PLATFORM_ID_COOKIE], companyRecordId))
			.then((res) => {
				const { ok, status, data } = res
				setIsLoading(false)
				if (!ok) return console.error('Error to subcontractor companies datatable !')

				if (ok) {
					setListProcedure(data)
					AddOperationalProceduresForm.addData(res.data)
					if (data.length) {
						let tmpData = data.map((dt) => ({
							updatedAt: dt.dateAdded,
							owner: dt.username,
							fileName: dt.fileName,
							isNewFromRevision: dt.isNewFromRevision,
							...dt,
						}))
						return setSCs(
							convertArrayToDatatable(tmpData, ['path', 'content', 'dateAdded', 'username']),
						)
					} else {
						return setSCs({ columns: [], data: [], options: {} })
					}
				}
			})
	}

	const actionsColumn = {
		name: 'actions',
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const customLabels = [
		{
			name: 'fileName',
			label: t('molibrary'),
		},
		{
			name: 'updatedAt',
			label: t('date_add_or_update'),
		},
		{
			name: 'owner',
			label: t('user'),
		},
		{
			name: 'isNewFromRevision',
			label: t('isNewFromRevision'),
		},
		{
			name: 'actions',
			label: t('actions'),
		},
	]

	const customColumns = [
		{
			name: 'actions',
			component: (value, tableMeta, updateValue) => {
				const filePath =
					tableMeta.rowData[getIndexOfColumn(SCs.columns, COLUMNS_OPERATIONAL_PROCEDURES.PATH)]
				const fileName =
					tableMeta.rowData[getIndexOfColumn(SCs.columns, COLUMNS_OPERATIONAL_PROCEDURES.FILENAME)]
				return (
					<>
						<IconButton
							onClick={() => {
								download(filePath, fileName)
							}}
						>
							<Icon>get_app</Icon>
						</IconButton>
						<IconButton
							disabled={isReadOnly}
							onClick={() => {
								setConfirmPopUp({ open: true, path: filePath })
							}}
						>
							<Icon>delete</Icon>
						</IconButton>
					</>
				)
			},
		},
		{
			name: 'isNewFromRevision',
			component: (value, tableMeta, updateValue) => {
				const isNewFromRevision =
					tableMeta.rowData[
						getIndexOfColumn(SCs.columns, COLUMNS_OPERATIONAL_PROCEDURES.ISNEWFROMREVISION)
					]

				return <>{isNewFromRevision && <Icon className={classes.icon}>info_outline</Icon>}</>
			},
		},
	]

	const OpenConfirmPopUp = () => {
		return (
			<Dialog
				open={confirmPopUp.open}
				onClose={closePopUp}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t('warning')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('doYouWantToDeleteThisFile')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePopUp} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteOperationalProcedures(confirmPopUp.path)
							closePopUp()
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	const closePopUp = () => {
		setConfirmPopUp({ open: false, path: null })
	}

	useEffect(recoverDatatable, [update])
	//#endregion Datatable

	return (
		<>
			{confirmPopUp.open && <OpenConfirmPopUp />}
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('OperationnalModes')}
			</Typography>
			<br />
			<div className={classes.dataTableContainer}>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openOperationalProceduresForm}
					>
						{t('add')}
					</Button>
				)}
				<DataTable
					title={t('Documents')}
					columns={[...SCs.columns, actionsColumn]}
					data={SCs.data}
					options={SCs.options}
					isLoading={isLoading}
					customColumns={customColumns}
					customLabels={customLabels}
				/>
			</div>
			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}

export default OperationalProcedures
