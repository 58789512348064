import { Typography, Grid, Button } from '@material-ui/core'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import PersonAdd from '@material-ui/icons/PersonAdd'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	getCompanyManagers,
	getPdPDraftListCompanies,
	getPdPEligibleCompaniesRestrictedPdP,
	addCompanyToPdPRestricted,
} from '../../../containers/DataContainer'
import {
	useAddPdPCompanyForm,
	useAddPdPCompanyResponsableForm,
} from '../../../containers/FormContainer'
import InputSelectSearch from '../../Form/input/InputSelectSearch'
import InputSelect from '../../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import { useTranslation } from 'react-i18next'
import Form from '../../Form/Form'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	body: {
		fontSize: '1em',
		padding: 15,
		color: theme.palette.secondary.main,
		paddingLeft: 0,
	},
	dateText: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'bottom',
		paddingTop: 0,
		paddingBottom: 0,
	},
	date: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'bottom',
		width: 155,
		marginRight: 5,
	},
	inputSelect: {
		width: '70%',
		display: CSS_VALUES.INLINE_BLOCK,
		margin: '1%',
		verticalAlign: 'middle',
	},
	bodyDE: {
		display: CSS_VALUES.INLINE_BLOCK,
		width: '30%',
		paddingLeft: 5,
	},
	flexBox: {
		display: 'flex',
	},
	textArea: {
		marginTop: 20,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	dataGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: CSS_VALUES.INLINE_BLOCK,
		borderRadius: 5,
		marginTop: 15,
		marginRight: 10,
		paddingBottom: 10,
	},
	categoryTitle: {
		transform: 'translateY(-18px)',
		position: 'absolute',
		backgroundColor: theme.palette.primary.main,
		padding: 8,
		paddingTop: 0,
		color: theme.palette.secondary.main,
	},
	inlineBlock: {
		display: CSS_VALUES.INLINE_BLOCK,
	},
	inspection: {
		marginTop: 10,
	},
	radio: {
		color: theme.palette.secondary.main,
	},
	pdpNumber: {
		paddingRight: 5,
	},
	inputYear: {
		transform: 'translateY(-2.5px)',
		width: 70,
	},
	numberPDP: {
		width: 45,
		transform: 'translateY(-5px)',
		marginLeft: 5,
	},
	numberInput: {
		color: theme.palette.secondary.main,
		paddingBottom: 4,
	},
	textType: {
		display: CSS_VALUES.INLINE_BLOCK,
		marginLeft: 'auto',
	},
	companyGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: 'block',
		borderRadius: 5,
		marginTop: 40,
		marginRight: 10,
		paddingBottom: 10,
		maxWidth: 700,
	},
	companyText: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'middle',
		paddingTop: 0,
		paddingBottom: 0,
		width: 120,
	},
	addContactIcon: {
		width: '1.8em',
		height: '1.8em',
		color: theme.palette.secondary.main,
		verticalAlign: 'middle',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	paper: {
		marginTop: theme.spacing.unit,
		padding: theme.spacing.unit,
	},
	groupCompany: {
		marginTop: 20,
	},
	name: {
		width: 'auto',
	},
}))

/**
 * Shows the form to create a restricted prevention plan
 * @returns {JSX.Element} React stack to render
 */
function ParticipatingCompaniesRestrictedPdP({
	buttonsStyle,
	pdpId,
	revisionNumber,
	refreshChevrons,
	setRefreshChevrons,
	setCompanyId,
	nextStep,
	previousStep,
}) {
	const { t } = useTranslation('user')
	const [cookies] = useCookies()
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [listManagers, setListManagers] = useState([])
	const [isRefreshEligibleComp, setRefreshEligibleComp] = useState(false)
	const refreshEligibleComp = () => {
		setRefreshEligibleComp((v) => !v)
	}

	const [listCompanies, setListCompanies] = useState([])
	const [companySelected, setCompanySelected] = useState(null)
	const [cpErrMsg] = useState('')
	const [selectedCompany, setSelectedCompany] = useState(null)
	const [newCompanyCreated, setNewCompanyCreated] = useState(null)
	const [newResponsableCreated, setNewResponsableCreated] = useState(null)
	const [refreshManager, setRefreshManager] = useState(false)
	const AddPdPCompanyForm = useAddPdPCompanyForm({
		pdpId,
		revisionNumber,
		refreshEligibleComp,
		setNewCompanyCreated,
	})

	const refreshManagerFc = () => setRefreshManager(!refreshManager)

	const [formAddResponsableData, setFormAddResponsableData] = useState({
		setNewResponsableCreated: setNewResponsableCreated,
		selectedCompany: selectedCompany,
		refreshManager: refreshManagerFc,
	})

	const AddCompanyResponsableForm = useAddPdPCompanyResponsableForm(formAddResponsableData)

	useEffect(() => {
		if (!pdpId) return
		getPdPDraftListCompanies(
			cookies[PLATFORM_ID_COOKIE],
			pdpId,
			{
				platformId: cookies[PLATFORM_ID_COOKIE],
				nbItemsAsked: 10,
				pageNumber: 1,
			},
			function (res, err) {
				if (err || !res || !res.data || !res.data.data[0]) return
				setCompanySelected({
					name: res.data.data[0].companyfullname,
					manager: res.data.data[0].operationresponsablefullname,
				})
				setRefreshChevrons(!refreshChevrons)
			},
		)
		refreshEligibleComp()
	}, [])

	useEffect(() => {
		if (newCompanyCreated) setSelectedCompany(newCompanyCreated.value)
	}, [newCompanyCreated])

	useEffect(() => {
		if (!selectedCompany) return
		setFormAddResponsableData({
			...formAddResponsableData,
			selectedCompany: selectedCompany,
		})
		getCompanyManagers(selectedCompany, function (res, error) {
			if (res) {
				setListManagers(res.data)
				if (newResponsableCreated) {
					const resultat = res.data.filter(
						(contact) =>
							contact.email == newResponsableCreated.contactEmail &&
							contact.firstName == newResponsableCreated.contactFirstName &&
							contact.lastName == newResponsableCreated.contactName,
					)
					setNewResponsableCreated({
						id: resultat[0].id,
						firstName: resultat[0].firstname,
						lastName: resultat[0].lastName,
					})
				}
			}
			if (error) {
				console.log(error)
			}
		})
	}, [selectedCompany, refreshManager])

	useEffect(() => {
		getPdPEligibleCompaniesRestrictedPdP(
			cookies[PLATFORM_ID_COOKIE],
			pdpId,
			revisionNumber,
			function (res, error) {
				if (res) {
					let tempListCompanies = res.data.map((company) => {
						return {
							value: company.companyId,
							label: company.socialReason + ' ' + company.agencyName,
						}
					})
					setListCompanies(tempListCompanies)
				}
				if (error) {
					console.error(`Cannot get PdP eligible companies ${error}`)
				}
			},
		)
	}, [isRefreshEligibleComp])

	const onChangeSelectInput = (e) => {
		setSelectedCompany(e.value)
	}

	const openAddPdPCompanyForm = () => {
		AddPdPCompanyForm.open()
	}

	const openAddCompanyResponsableForm = () => {
		if (!selectedCompany) return
		AddCompanyResponsableForm.open()
	}

	const onSubmit = (values) => {
		if (!values.principal) {
			enqueueSnackbar(t('OperationnalResponsableRequired'), { variant: 'error' })
			return
		}
		let formData = {
			companyId: selectedCompany,
			companyManagerId: values.principal,
			isRestricted: true,
		}
		addCompanyToPdPRestricted(
			formData,
			cookies[PLATFORM_ID_COOKIE],
			pdpId,
			revisionNumber,
			function (res, error) {
				if (res) {
					setCompanyId(selectedCompany)
					setRefreshChevrons(!refreshChevrons)
					enqueueSnackbar(t('CompaniesHaveBeenAddedPdP'), {
						variant: 'info',
					})
					nextStep()
				}
				if (error) {
					if (error.data) {
						enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
						return
					}
					enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				}
			},
		)
	}

	const getDefaultValueSelectSearchCompanies = () => {
		if (newCompanyCreated) return newCompanyCreated

		return null
	}

	return (
		<>
			{AddPdPCompanyForm.formular}
			{AddCompanyResponsableForm.formular}
			<Form onSubmit={onSubmit}>
				<div className={classes.companyGroup}>
					<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
						{t('company')}
					</Typography>
					<div className={classes.groupCompany}>
						<Typography variant="body1" className={classNames(classes.body, classes.companyText)}>
							{t('company')} :
						</Typography>

						{!pdpId || !companySelected ? (
							<>
								<div className={classes.inputSelect}>
									<InputSelectSearch
										name="company"
										label="Raison sociale"
										data={listCompanies}
										defaultValue={getDefaultValueSelectSearchCompanies()}
										onChange={onChangeSelectInput}
									></InputSelectSearch>
								</div>
								<AddCircleOutline
									className={classes.addContactIcon}
									onClick={openAddPdPCompanyForm}
								/>
							</>
						) : (
							<Typography
								variant="body1"
								className={classNames(classes.body, classes.companyText, classes.name)}
							>
								{companySelected.name}
							</Typography>
						)}
					</div>

					<div>
						<Typography variant="body1" className={classNames(classes.body, classes.companyText)}>
							{t('OperationManager')} :
						</Typography>
						{!pdpId || !companySelected ? (
							<>
								<InputSelect
									name="principal"
									label={t('OperationManager')}
									classes={{ root: classes.inputSelect }}
								>
									{listManagers &&
										listManagers.map((manager) => {
											return (
												<SelectMenuItem value={manager.id} key={manager.id}>
													{manager.firstName} {manager.lastName}
												</SelectMenuItem>
											)
										})}
								</InputSelect>
								<PersonAdd
									className={classes.addContactIcon}
									onClick={openAddCompanyResponsableForm}
								/>
							</>
						) : (
							<Typography
								variant="body1"
								className={classNames(classes.body, classes.companyText, classes.name)}
							>
								{companySelected.manager}
							</Typography>
						)}
					</div>
				</div>

				<Typography variant="caption" style={{ color: 'red' }}>
					{cpErrMsg}
				</Typography>
				<div className={buttonsStyle.root}>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Grid container item xs={4} justify="center">
							<Button className={buttonsStyle.previous} onClick={previousStep}>
								{t('previous')}
							</Button>
						</Grid>
						{pdpId && companySelected ? (
							<Grid container item xs={4} justify="center">
								<Button className={buttonsStyle.next} onClick={nextStep}>
									{t('next')}
								</Button>
							</Grid>
						) : (
							<Grid container item xs={4} justify="center">
								<Button type="submit" className={buttonsStyle.next}>
									{t('completeStep')}
								</Button>
							</Grid>
						)}
					</Grid>
				</div>
			</Form>
		</>
	)
}
ParticipatingCompaniesRestrictedPdP.propTypes = {
	buttonsStyle: PropTypes.object,
	pdpId: PropTypes.string,
	revisionNumber: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	setCompanyId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
}

export default ParticipatingCompaniesRestrictedPdP
