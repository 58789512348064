import React, { useState, useEffect } from 'react'
import SlidingForm from '../../Form/SlidingForm'
import FormIcon from '../../Form/FormIcon'
import FormButton from '../../Form/FormButton'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Form from '../../Form/Form'
import { Dialog, DialogTitle, DialogActions, Grid } from '@material-ui/core'
import InputText from '../../Form/input/InputText'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import InputCheckbox from '../../Form/input/InputCheckbox'
import {
	recoverChemicalProduct,
	upsertChemicalProductById,
	upsertChemicalProduct,
	getMaterialList,
	getRetentionList,
	getConditionsList,
} from '../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { HandlingErrorMessages } from '../../../utils/Errors'
import PropTypes from 'prop-types'
import { PLATFORM_CONFIGURATION } from '../../../constants/url'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { ThemedInputSelectField } from '../../Form/inputFullPage/InputSelect'
import FormButtonFullPage from '../../Form/FormButtonFullPage'
import { chemicalProductRetention } from '../../../utils/VerifyFields'
import { CHEMICAL_PRODUCT_RETENTION } from '../../../constants/chemicalRetention'
import InputNumber from '../../Form/input/InputNumber'

const useStyles = makeStyles((theme) => ({
	validateRoot: {
		display: 'block',
		margin: '0 auto',
	},
	multipleSelect: {
		width: '100%',
	},
	inputText: {
		marginBottom: '1vh',
	},
	popupdiv: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		gap: '15px',
	},
	popupTitle: {
		color: theme.palette.secondary.main,
		margin: 25,
		marginBottom: 0,
	},
}))

function ChemicalProductsStorage({ open, close, data }) {
	const classes = useStyles()
	const { t, i18n } = useTranslation(['user', 'validate', 'chemical'])
	const [initialValues, setInitialValues] = useState({})
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [noStorage, setNoStorage] = useState(false)
	const [isReadOnly, setIsReadOnly] = useState(false)
	const [formValues, setFormValues] = useState({})
	const [maxDay, setMaxDay] = useState()
	const langId = i18n.language === 'fr' ? 1 : 2
	const [listContainer, setListContainer] = useState([])
	const [container, setContainer] = useState()
	const [containerMaterialList, setContainerMaterialList] = useState([])
	const [containerMaterial, setContainerMaterial] = useState()
	const [retentionChoices, setRetentionChoices] = useState([])
	const [retention, setRetention] = useState()
	const [openNotifPopup, setOpenNotifPopup] = useState(false)
	const [chemicalName, setChemicalName] = useState()
	const formatResponse = (resData) => {
		let listSelectedMaterials = []
		if (resData.pddMateriauContenant) {
			resData.pddMateriauContenant.forEach((mat) => {
				listSelectedMaterials = [...listSelectedMaterials, mat]
			})

			setContainerMaterial(listSelectedMaterials)
		}
		return {
			localisation: resData.pddLocalisation,
			userService: resData.pddServiceUtilisateurTotalEe,
			containerMaterial: resData.matContainerMaterial,
			buildingUnit: resData.pddUnite,
			conditionnement: resData.pddConditionnement,
			maxStorage: resData.pddStockMax,
			Retention: resData.pddRetentionNecessaire,
			frequence: resData.pddFrequenceReapprovisionnement,
		}
	}

	const formatRequest = (resData) => ({
		localisation: resData.localisation,
		serviceUtilisateurTotalEE: resData.userService,
		containerMaterial: resData.containerMaterial,
		unite: resData.buildingUnit,
		conditionnement: resData.conditionnement,
		stockMax: resData.maxStorage,
		Retention: resData.Retention,
		frequenceReapprovisionnement: resData.frequence,
		pasDeStockageSurSite: noStorage,
	})

	const noStorageFormat = () => ({
		localisation: '',
		serviceUtilisateurTotalEE: '',
		containerMaterial: '',
		unite: '',
		conditionnement: 0,
		stockMax: '',
		Retention: 0,
		frequenceReapprovisionnement: '',
		PasDeStockageSurSite: true,
	})

	useEffect(() => {
		const handleRecoverCP = (res, err) => {
			if (err || !res) return
			setFormValues(formatResponse(res.data))
			setNoStorage(res.data.pddPasDeStockageSurSite)
		}
		const sendCPRecovery = () => {
			if (!data || !data.productId || !data.companyRecordId) return
			if (data.productId.create) return
			let prdId = isNaN(parseInt(data.productId))
				? data.productId.chemicalProductId
				: data.productId
			recoverChemicalProduct(data.platformId, data.companyRecordId, prdId, handleRecoverCP)
		}
		if (!open) {
			setInitialValues({})
			return
		}
		if (!data || !data.chemicalName) return
		setChemicalName(data.chemicalName)
		sendCPRecovery()
	}, [open, data, cookies])

	useEffect(() => {
		setInitialValues(formValues)
		setIsReadOnly(noStorage)
	}, [formValues])

	useEffect(() => {
		getPlatformConfiguration()
		getMaterialList(langId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				const d = res.data
					.sort(function (a, b) {
						return a.lmxNom - b.lmxNom
					})
					.sort(function (a, b) {
						return a.order - b.order
					})
				setContainerMaterialList(res.data)
			}
		})
		getRetentionList(langId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setRetentionChoices(res.data)
			}
		})
		getConditionsList(langId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListContainer(res.data)
			}
		})
	}, [])

	const getPlatformConfiguration = () => {
		api.get(bindParams(PLATFORM_CONFIGURATION, cookies[PLATFORM_ID_COOKIE])).then((res) => {
			const { data, problem } = res
			if (problem) {
				enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
			} else {
				data.forEach((line) => {
					if (line.translationCode == 'ChemicalProductsStockDayMax') setMaxDay(line.value)
				})
			}
		})
	}

	const handleSendCP = (res, err) => {
		if (err) {
			if (err.data && err.data[0]) {
				if (err.data[0].innerErrors && err.data[0].innerErrors[0]) {
					enqueueSnackbar(t('error') + ` : ${t(err.data[0].innerErrors[0].code)} !`, {
						variant: 'error',
					})
					return
				}
				enqueueSnackbar(t('error') + ` : ${t(err.data[0].code)} !`, { variant: 'error' })
				return
			}
			enqueueSnackbar(t('error') + ` : ${err.status} !`, { variant: 'error' })
			return
		}
		if (!res) return
		enqueueSnackbar(t('chemicalProductHasBeenUpdated'), {
			variant: 'info',
		})

		close()
		if (res.data && res.data.companyRecordId)
			data.setRevisionCompanyRecordId(res.data.companyRecordId)
		data.refresh()
		data.productId.refreshStore()
	}

	const sendCreateAndStorageRequest = (values) => {
		upsertChemicalProduct(
			{
				companyId: data.companyId,
				data: data.productId.values,
			},
			(res, err) => {
				if (err && err.data) {
					HandlingErrorMessages(err.data, (code) => {
						enqueueSnackbar(t('chemical:' + code), { variant: 'error' })
					})
					return
				}
				if (res) {
					upsertChemicalProductById(
						data.platformId,
						data.companyRecordId,
						res.data,
						values,
						handleSendCP,
					)
					close()
					data.productId.refreshStore()
					data.refresh()
				}
			},
		)
	}

	const sendFormRequest = (values) => {
		const form = () =>
			upsertChemicalProductById(
				data.platformId,
				data.companyRecordId,
				isNaN(parseInt(data.productId)) ? data.productId.chemicalProductId : data.productId,
				values,
				handleSendCP,
			)
		if (data.creationFunc) data.creationFunc(() => form())
		else form()
	}

	const NotificationPopup = () => (
		<Dialog open={openNotifPopup} onClose={() => setOpenNotifPopup(false)}>
			<DialogTitle className={classes.popupTitle}>{t('chemical_product_warning')}</DialogTitle>
			<div className={classes.popupdiv}>
				<FormButtonFullPage
					variant="outlined"
					label={t('user:cancel')}
					onClick={() => {
						setOpenNotifPopup(false)
					}}
				/>

				<FormButtonFullPage
					variant="outlined"
					label={t('user:confirm')}
					onClick={() => {
						StoreProduct()
						setOpenNotifPopup(false)
					}}
				/>
			</div>

			<DialogActions></DialogActions>
		</Dialog>
	)

	function handleNoStorage() {
		setNoStorage(!noStorage)
	}

	useEffect(() => {
		if (noStorage) {
			setInitialValues(noStorageFormat)
		} else {
			setInitialValues(formValues)
		}
		setIsReadOnly(noStorage)
	}, [noStorage])

	const StoreProduct = (values = null) => {
		if (values == null) values = formValues
		if (data.productId.create) return sendCreateAndStorageRequest(formatRequest(values))

		sendFormRequest(formatRequest(values))
	}

	const onSubmit = (values) => {
		if (!values) console.error(new Error('No data !'))
		if (
			!values.PasDeStockageSurSite &&
			(!values.Retention ||
				values.Retention === 0 ||
				!values.conditionnement ||
				values.conditionnement === 0 ||
				!values.containerMaterial ||
				values.containerMaterial.length === 0)
		) {
			enqueueSnackbar(t('missing_parameters'), { variant: 'error' })
			return false
		}
		setFormValues(values)
		if (
			values.Retention === CHEMICAL_PRODUCT_RETENTION.NO &&
			chemicalName &&
			(!Array.isArray(chemicalName) ||
				!chemicalName.every((element) => chemicalProductRetention(element)))
		) {
			setOpenNotifPopup(true)
		} else {
			StoreProduct(values)
		}
	}

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			title={t('chemicalTitle')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<Grid container spacing={8} justify="center">
					<Grid item sm={12} md={12}>
						<InputCheckbox
							name="noStorageOnSite"
							label={t('noStorageOnSite', { maxDay: maxDay })}
							labelClass={classes.inputText}
							checked={noStorage}
							onChange={handleNoStorage}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							label={t('Localisation')}
							name="localisation"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
						/>
						<InputText
							label={t('userService')}
							name="userService"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
						<ThemedInputSelectField
							name="containerMaterial"
							onChange={(e) => {
								setContainerMaterial(e.target.value)
							}}
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							label={t('ContainerMaterial')}
							required={true}
							value={containerMaterial}
						>
							{containerMaterialList.map((value) => (
								<SelectMenuItem value={value.matId} key={value.matId}>
									{value.lmxNom}
								</SelectMenuItem>
							))}
						</ThemedInputSelectField>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							name="buildingUnit"
							label={t('UniteBatiment')}
							classes={{
								root: classes.inputText,
							}}
							disabled={isReadOnly}
						/>
						<ThemedInputSelectField
							name="conditionnement"
							label={t('Conditionnement')}
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							value={container}
							required={true}
							onChange={(e) => {
								setContainer(e.target.value)
							}}
						>
							{listContainer.map((value) => (
								<SelectMenuItem value={value.cpcId} key={value.cpcId}>
									{value.lctNom}
								</SelectMenuItem>
							))}
						</ThemedInputSelectField>
						<InputNumber
							label={t('StockMaximum')}
							name="maxStorage"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							required={true}
							validate={(values) => (values < 0 ? t('noNegative') : undefined)}
						/>
					</Grid>
					<Grid item sm={12} md={4}>
						<InputText
							label={t('frequence')}
							name="frequence"
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
						/>
						<ThemedInputSelectField
							name="Retention"
							label={t('retention')}
							classes={{ root: classes.inputText }}
							disabled={isReadOnly}
							value={retention}
							required={true}
							onChange={(e) => {
								setRetention(e.target.value)
							}}
						>
							{retentionChoices.map((value) => (
								<SelectMenuItem value={value.rtpId} key={value.rtpId}>
									{value.lrtNom}
								</SelectMenuItem>
							))}
						</ThemedInputSelectField>
					</Grid>
				</Grid>

				{!data.isReviewMode && (
					<FormButton classes={{ root: classes.validateRoot }} label="Valider" type="submit" />
				)}
				<br />
				{data && data.productId && data.productId.create && (
					<FormButton
						classes={{ root: classes.validateRoot }}
						label={t('back')}
						onClick={() => {
							close()
							data.productId.openChemicalProductsForm(undefined, {
								openChemicalProductsForm: data.productId.openChemicalProductsForm,
								refreshStore: data.productId.refreshStore,
								initialValues: data.productId.initialValues,
							})
						}}
					/>
				)}
			</Form>
			<NotificationPopup />
		</SlidingForm>
	)
}
ChemicalProductsStorage.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}

export default ChemicalProductsStorage
