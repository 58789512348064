import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	deleteActivityPhaseObservation,
	upsertActivityPhaseObservation,
} from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormButtonIconDelete from '../../Form/FormButtonIconDelete'
import FormIcon from '../../Form/FormIcon'
import InputTextArea from '../../Form/input/InputTextArea'
import SlidingForm from '../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	dateBox: {
		width: '100%',
		display: 'inline-block',
	},
	date: {
		width: '100%',
		marginTop: 10,
	},
	text: {
		display: 'inline-block',
		color: theme.palette.primary.main,
	},
	flex: {
		display: 'flex',
	},
}))

/**
 * Shows a form to add a Medical Surveillance to the company file
 * @returns {JSX.Element} React stack to render
 */
function PhaseUnsafeObservationForm({ open, close, data }) {
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])
	const [initialValues, setInitialValues] = useState({ observation: data.observation })

	useEffect(() => {
		setInitialValues({ observation: data.observation })
	}, [data])

	const onSubmit = (values) => {
		upsertActivityPhaseObservation(
			data.platformId,
			data.companyRecordId,
			data.activityId,
			{ observation: values['observation'] },
			function (res, error) {
				if (res) {
					data.updateData()
					enqueueSnackbar(t('observationUpdate'), {
						variant: 'info',
					})
					close()
					if (res.data && res.data.companyRecordId)
						data.setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (error) {
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	const deleteObservation = () => {
		deleteActivityPhaseObservation(
			data.platformId,
			data.companyRecordId,
			data.activityId,
			function (res, error) {
				if (res) {
					enqueueSnackbar(t('obserDelete'), {
						variant: 'info',
					})
					data.updateData()
					close()
				}
				if (error) {
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('Observation')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<InputTextArea
					classes={{ root: classes.size1 }}
					name="observation"
					label={t('observationPhase')}
					validate={composeValidators(required)}
				/>
				<div className={classes.flex}>
					<FormButtonIcon
						type="submit"
						classes={{ root: classes.validateButton }}
						disabled={Boolean(data.isReviewMode || data.isReadOnly)}
					/>
					<FormButtonIconDelete
						classes={{ root: classes.deleteButton }}
						onClick={deleteObservation}
						disabled={Boolean(data.isReviewMode || data.isReadOnly)}
					/>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default PhaseUnsafeObservationForm
