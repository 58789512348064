import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import makeStyles from '@material-ui/styles/makeStyles'
import InputCheckbox from '../Form/inputFullPage/InputCheckbox'
import InputText from '../Form/inputFullPage/InputText'
import {
	Grid,
	Paper,
	Switch,
	Typography,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@material-ui/core'
import HeadTitle from '../HeadTitle/HeadTitle'
import Form from '../Form/Form'
import api from '../../utils/Requester'
import { PLATFORM_CONFIGURATION } from '../../constants/url'
import { bindParams } from '../../utils/Link'
import { useSnackbar } from 'notistack'
import FormButtonFullPage from '../Form/FormButtonFullPage'
import { useCookies } from 'react-cookie'
import { ACCOUNT_TYPES_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 40,
		padding: 20,
		backgroundColor: theme.palette.primary.main,
	},
	saveButton: {
		display: 'flex',
		justifyContent: 'flex-end',
	},
	popupdiv: {
		display: 'flex',
		flexDirection: 'row',
		alignSelf: 'center',
		gap: '15px',
	},
	popupTitle: {
		color: theme.palette.secondary.main,
		margin: 25,
		marginBottom: 0,
	},
	inputSize: {
		padding: '22px 12px 1px',
	},
	inputRoot: {
		margin: '0 25px',
	},
	checkbox: {
		display: 'inline-block !important',
	},
}))

const editableLabel = '_editable'

function PlatformConfigurationForm({ configurations, isPlatformManager, platformId }) {
	const classes = useStyles()
	const { t } = useTranslation('platformConfiguration')
	const [switches, setSwitches] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const [initialValues, setInitialValues] = useState()
	const [cookies] = useCookies()
	const [openNotifPopup, setOpenNotifPopup] = useState(false)
	const [correctValues, setCorrectValues] = useState(false)
	const isManager = cookies[ACCOUNT_TYPES_COOKIE].some((ur) => ur.code === 'PLATFORMMANAGER')

	useEffect(() => {
		configurations.forEach((configuration) => {
			const innerSwitches = switches
			if (configuration.valueType === 'Bool') {
				const find = innerSwitches.findIndex((x) => x.id === configuration.id)
				if (find === -1) {
					innerSwitches.push({
						id: configuration.id,
						value: configuration.value === '1',
						isSwitch: true,
					})
				}
			}
			const name = `${configuration.id}${editableLabel}`
			const find = innerSwitches.findIndex((x) => x.id === name)
			if (find === -1) {
				innerSwitches.push({ id: name, value: configuration.isEditable, isSwitch: false })
			}
			setSwitches(innerSwitches)
		})

		const values = {}
		configurations.forEach((config) => {
			if (config.valueType === 'String' || config.valueType === 'Int') {
				values[config.translationCode] = config.value
			}
		})
		setInitialValues(values)
	}, [])

	const getInputCheckbox = (config, isPlatformManager) => {
		return (
			<>
				{createInput(config)}
				<Grid item sm={6}>
					{!isPlatformManager && (
						<InputCheckbox
							key={`${config.id}${editableLabel}`}
							name={`${config.translationCode}${editableLabel}`}
							classes={{ root: classes.checkbox }}
							checked={
								switches && switches.length
									? switches.find((x) => x.id === `${config.id}${editableLabel}`)?.value
									: false
							}
							onClick={() => switchChangeState(`${config.id}${editableLabel}`)}
						/>
					)}
				</Grid>
			</>
		)
	}

	const switchChangeState = (configId) => {
		const newSwitches = switches.map((x) => {
			if (x.id === configId) {
				x.value = !x.value
				if (x.isSwitch) {
					const value = configurations.find((conf) => conf.id === x.id).value
					configurations.find((conf) => conf.id === x.id).value = value === '0' ? '1' : '0'
				} else {
					const isEditable = configurations.find(
						(conf) => `${conf.id}${editableLabel}` === x.id,
					).isEditable
					configurations.find((conf) => `${conf.id}${editableLabel}` === x.id).isEditable =
						!isEditable
				}
			}
			return x
		})
		setSwitches(newSwitches)
	}

	const createInput = (configuration) => {
		switch (configuration.valueType) {
			case 'Bool':
				const input = (
					<>
						<Grid item sm={3}>
							<Typography className={classes.popupTitle}>
								{t(configuration.translationCode)}
							</Typography>
						</Grid>
						<Grid item sm={3}>
							<Switch
								key={configuration.id}
								name={`${configuration.translationCode}`}
								checked={
									switches && switches.length
										? switches.find((x) => x.id === configuration.id)?.value
										: false
								}
								onClick={() => switchChangeState(configuration.id)}
								disabled={!configuration.isEditable && isManager}
							/>
						</Grid>
					</>
				)
				return input
			case 'Int':
			case 'String':
			default:
				return (
					<>
						<Grid item sm={6}>
							<InputText
								key={configuration.id}
								name={`${configuration.translationCode}`}
								label={t(configuration.translationCode)}
								classes={{ root: classes.inputRoot }}
								inputProps={{
									className: classes.inputSize,
								}}
								InputLabelProps={{ required: false }}
								value={configuration.value}
								required={true}
								readOnly={!configuration.isEditable && isManager}
								disabled={!configuration.isEditable && isManager}
							/>
						</Grid>
					</>
				)
		}
	}

	const NotificationPopup = () => (
		<Dialog open={openNotifPopup} onClose={() => setOpenNotifPopup(false)}>
			<DialogTitle className={classes.popupTitle}>{t('ConfirmLesserRetentionTime')}</DialogTitle>
			<div className={classes.popupdiv}>
				<FormButtonFullPage
					variant="outlined"
					label={t('user:cancel')}
					onClick={() => {
						setOpenNotifPopup(false)
					}}
				/>

				<FormButtonFullPage
					variant="outlined"
					label={t('user:confirm')}
					onClick={() => {
						updatePlatformConfiguration()
						setOpenNotifPopup(false)
					}}
				/>
			</div>

			<DialogActions></DialogActions>
		</Dialog>
	)

	const updatePlatformConfiguration = () => {
		api.put(bindParams(PLATFORM_CONFIGURATION, platformId), configurations).then((res) => {
			const { ok } = res
			if (!ok) return enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
			return enqueueSnackbar(t('submit_form_success'), { variant: 'success' })
		})
	}

	const checkFormValues = (values) => {
		let hasError = false
		const emailRetentionTimeValue = parseInt(values.EmailRetentionTime)
		const emailDisplayPeriodValue = parseInt(values.EmailDisplayPeriod)
		const emailBodyRetentionTimeValue = parseInt(values.EmailBodyRetentionTime)
		const chemicalProductsStockDayMaxValue = parseInt(values.ChemicalProductsStockDayMax)
		const dashboardExpiringPdpDelayValue = parseInt(values.DashboardExpiringPdpDelay)
		const expirationWarningDelayValue = parseInt(values.ExpirationWarningDelay)
		const fdsValidityDurationValue = parseInt(values.FdsValidityDuration)
		const hoursPerDayValue = parseInt(values.HoursPerDay)
		const interventionDurationValue = parseInt(values.InterventionDuration)
		const usersConnectedSinceValue = parseInt(values.UsersConnectedSince)
		const ValuesArray = [
			emailRetentionTimeValue,
			emailDisplayPeriodValue,
			emailBodyRetentionTimeValue,
			chemicalProductsStockDayMaxValue,
			dashboardExpiringPdpDelayValue,
			expirationWarningDelayValue,
			fdsValidityDurationValue,
			hoursPerDayValue,
			interventionDurationValue,
			usersConnectedSinceValue,
		]

		if (
			values?.EmailRetentionTime === null ||
			!Number.isInteger(emailRetentionTimeValue) ||
			values?.EmailDisplayPeriod === null ||
			!Number.isInteger(emailDisplayPeriodValue) ||
			values?.EmailBodyRetentionTime === null ||
			!Number.isInteger(emailBodyRetentionTimeValue)
		) {
			enqueueSnackbar(
				t('Letter') +
					` : ${emailBodyRetentionTimeValue}, ${emailDisplayPeriodValue}, ${emailRetentionTimeValue}`,

				{ variant: 'error' },
			)
			return false
		}
		if (
			emailRetentionTimeValue % 1 != 0 ||
			emailRetentionTimeValue > 1825 ||
			emailRetentionTimeValue < 1
		) {
			enqueueSnackbar(t('EmailRetentionTimeFormat'), { variant: 'error' })
			return false
		} else if (
			emailDisplayPeriodValue % 1 != 0 ||
			emailRetentionTimeValue < emailDisplayPeriodValue ||
			emailDisplayPeriodValue < 1
		) {
			enqueueSnackbar(t('EmailDisplayPeriodFormat'), { variant: 'error' })
			return false
		} else if (
			emailBodyRetentionTimeValue % 1 != 0 ||
			emailRetentionTimeValue < emailBodyRetentionTimeValue ||
			emailBodyRetentionTimeValue < 1
		) {
			enqueueSnackbar(t('EmailBodyRetentionTimeFormat'), { variant: 'error' })
			return false
		} else {
			ValuesArray.forEach((element) => {
				if (!Number.isInteger(element) || element === null || element < 0) {
					enqueueSnackbar(
						t('Letter') + ` : ${element}`,

						{ variant: 'error' },
					)
					hasError = true
				}
			})
			if (hasError) {
				return false
			}
			if (+values.EmailRetentionTime < +initialValues?.EmailRetentionTime) {
				setOpenNotifPopup(true)
			} else {
				return true
			}
		}
	}

	const onSubmit = (values) => {
		Object.keys(values).forEach((key) => {
			if (!key.endsWith(editableLabel)) {
				configurations.find((conf) => conf.translationCode === key).value = values[key]
			}
		})
		if (checkFormValues(values)) updatePlatformConfiguration()
	}

	return (
		<>
			<HeadTitle title={t('title')} />
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<Paper className={classes.paper}>
					<Grid container spacing={24}>
						{!isPlatformManager && (
							<>
								<Grid item sm={6}></Grid>
								<Grid item sm={6}>
									<Typography color="secondary">{t('is_editable')}</Typography>
								</Grid>
							</>
						)}

						{configurations &&
							configurations.map((conf) => getInputCheckbox(conf, isPlatformManager))}
						<Grid item sm={12}>
							<div className={classes.saveButton}>
								<FormButtonFullPage variant="outlined" label={t('submit')} type="submit" />
							</div>
						</Grid>
					</Grid>
				</Paper>
			</Form>
			<NotificationPopup />
		</>
	)
}

export default PlatformConfigurationForm
