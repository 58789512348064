import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		display: 'block',
		border: 'solid 1px',
		borderColor: theme.palette.secondary.main,
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
	},
	inputSize: {
		boxSizing: 'inherit',
		//padding: "22px 12px 10px",
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.secondary.main,
			display: 'none',
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
		display: 'contents',
	},
}))

function InputText({ name, label, validate, value = null, readOnly, onChange = null, ...props }) {
	const classes = useStyles()

	const onChangeHandle = React.useCallback((input, e) => {
		input.onChange(e)
		onChange && onChange(e)
		input.value = e.target.value
	})

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => (
				<TextField
					type="text"
					label={label}
					value={value}
					variant="filled"
					FormHelperTextProps={{
						classes: {
							root: classes.helperText,
						},
					}}
					helperText={
						meta && meta.touched && meta.error && meta.error ? (
							<Translation ns="validateData">{(t) => <>{t(meta.error)}</>}</Translation>
						) : undefined
					}
					className={classes.block}
					InputProps={{
						readOnly: readOnly,
						classes: {
							root: classes.inputRoot,
							focused: classes.inputFocused,
						},
					}}
					// eslint-disable-next-line react/jsx-no-duplicate-props
					inputProps={{
						className: classes.inputSize,
					}}
					InputLabelProps={{
						classes: {
							root: classes.labelRoot,
							focused: classes.labelFocused,
						},
					}}
					{...props}
					{...input}
					onChange={(e) => onChangeHandle(input, e)}
				/>
			)}
		/>
	)
}

export default InputText
