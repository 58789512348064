import * as link from '../constants/url'
import axios from 'axios'

export function sendRegisterData(registerData, callback) {
	axios({
		method: 'POST',
		url: link.REGISTER,
		data: registerData,
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function sendCreateAccountData(createAccountData, callback) {
	axios({
		method: 'POST',
		url: link.REGISTER,
		data: {
			login: GetData(createAccountData, 'login'),
			languageId: GetData(createAccountData, 'languageId'),
			email: GetData(createAccountData, 'email'),
			firstName: GetData(createAccountData, 'firstName'),
			lastName: GetData(createAccountData, 'lastName'),
			accountTypePerPlatform: [
				{
					platformId: GetData(createAccountData, 'platformId'),
					accountTypes: [{}],
				},
			],
			companyName: GetData(createAccountData, 'companyName'),
			socialReason: GetData(createAccountData, 'socialReason'),
			agencyName: GetData(createAccountData, 'agencyName'),
			agencyPhone: GetData(createAccountData, 'agencyPhone'),
			nationalityId: GetData(createAccountData, 'companyNationality'),
			siret: GetData(createAccountData, 'siretNumber'),
			justification: GetData(createAccountData, 'justification'),
			expirationDate: GetData(createAccountData, 'expirationDate'),
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function GetPlatform(token, callback) {
	axios({
		method: 'GET',
		url: link.GETPLATFORMS,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function GetNationalities(token, callback) {
	axios({
		method: 'GET',
		url: link.GETLANGUAGES,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function GetAccountTypes(token, languageId, includeCompany = true, callback) {
	axios({
		method: 'GET',
		url: link.GETACCOUNTTYPES,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			p_LangId: languageId,
			p_IncludeCompany: includeCompany,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function GetAccountList(
	token,
	platformId,
	nbItemsAsked,
	pageNumber,
	columnSort,
	sortOrder,
	callback,
) {
	axios({
		method: 'GET',
		url: link.GETACCOUNTLIST,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		params: {
			platformId,
			nbItemsAsked,
			pageNumber,
			columnSort,
			sortOrder,
		},
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

function GetData(data, id) {
	return data[id].value
}
