import React from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	title : {
		color : theme.palette.secondary.main,
		fontWeight : 500,
		fontSize : '1.2em'
	}
}))

function TotalPopUpWarning({ open, onClose, title, content, icon, props }) {
	const classes = useStyles()

	/* Create pop up using Material ui Dialog component */
	return (
		<>
			<Dialog
				open={open}
				onClose={onClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" disableTypography={true} classes={{ root : classes.title}}>{title}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{content}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={onClose} color="secondary" autoFocus>
            Fermer
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default TotalPopUpWarning
