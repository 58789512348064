import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Fab, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import CertificationCard from '../../../../Cards/CertificationCard'
import moment from 'moment'
import { dateDisplay } from '../../../../../utils/Date'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	buttonAddCertification: {
		position: 'absolute',
		right: '-33px',
		bottom: '-33px',
		zIndex: 99,
	},
	certification: {
		position: 'relative',
	},
	button: {
		margin: 10,
	},
}))

function StepThree({
	openAddCompanyFilesCertification,
	operationInfos,
	certificationList,
	setCertificationList,
	isReadOnly,
	isReviewMode,
}) {
	const { t } = useTranslation('user')
	const classes = useStyles()

	useEffect(() => {
		if (!certificationList || certificationList.length) return
		setCertificationList(operationInfos.certifications ? operationInfos.certifications : [])
	}, [operationInfos])

	const deleteCertif = id => {
		setCertificationList(certificationList.filter(crtf => crtf.cftId !== id))
	}

	return (
		<div className={classes.certification}>
			<div>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openAddCompanyFilesCertification}
					>
						{t('addFromLibrary')}
					</Button>
				)}
			</div>
			{certificationList && certificationList.length > 0 ? (
				certificationList.map(certification => {
					if (!certification.cftDateObtention) return
					return (
						<CertificationCard
							key={certification.cftId}
							title={certification.cftIntitule}
							subtitle={certification.cftReference}
							date={dateDisplay(certification.cftDateObtention)}
							duration={certification.cftDureeValidite}
							location={certification.cftLieu}
							path={certification.cftDocumentCheminComplet}
							type="companyFiles"
							deleteFc={() => deleteCertif(certification.cftId)}
							isReadOnly={isReadOnly}
							isReviewMode={isReviewMode}
						/>
					)
				})
			) : (
				<div className={classes.messageBlock}>{t('noCertif')}</div>
			)}
		</div>
	)
}

export default StepThree
