import React from 'react'

function CGCookiesContentFr(props) {
	return (
		<div>
			<p>
				Bienvenue sur le site internet{' '}
				<a href="https://rapso-d.totalenergies.com">https://rapso-d.totalenergies.com</a> (Le « site
				internet »). En vous connectant ou en consultant le site internet, vous reconnaissez avoir
				lu, compris, et accepté, sans limitation ni réserve, la charte sur la protection des données
				à caractère personnel et cookies (la « charte ») et nos conditions générales d’utilisation.
				Veuillez noter que d'autres conditions générales et chartes sur la protection des données à
				caractère personnel s’appliquent aux autres sites du groupe Total et nous vous recommandons
				de les consulter attentivement.
				<br />
				<br />
				La charte vise à vous informer des droits et libertés que vous pouvez faire valoir à l'égard
				de notre utilisation de vos données personnelles et décrit les mesures que nous mettons en
				œuvre afin de les protéger.
				<br />
				<br />
				Total est le « responsable du traitement » de données personnelles relatives à la gestion du
				site internet. Ces traitements sont mis en œuvre conformément au droit applicable.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>
				1. Finalité, fondement du traitement des données collectées et durée
			</h2>
			<p>
				Vous êtes susceptibles de nous fournir un certain nombre de données personnelles telles que
				vos nom et prénom, afin de bénéficier des services offerts par le site et aux fins de vous
				permettre d’accéder aux services du site.
				<br />
				<br />
				Les informations sont nécessaires pour nous permettre de remplir nos obligations légales
				dans l’établissement des Plans de Prévention pour les travaux que vous seriez amené à
				effectuer sur nos sites de production (articles L4121-5, L4122-1, R4511-1 à R4511-12 et
				R4512-1 à R4512-16 du code du travail). A défaut Total RC ne pourra pas traiter votre
				demande et Total RC sera en infraction avec la loi.
				<br />
				<br />
				Dans nos formulaires en ligne, les champs obligatoires sont signalés. A défaut de réponse
				aux questions obligatoires, nous ne serons pas en mesure de vous fournir les services
				demandés.
				<br />
				<br />
				Vos données personnelles ne sont pas traitées ultérieurement de manière incompatible avec
				les finalités décrites ci-dessus ou sous les formulaires de collecte. Vos données sont
				conservées au maximum 7 ans après la date de fin des travaux liés au Plan de Prévention
				établis
			</p>
			<h2 style={{ fontWeight: 'bold' }}>2. Destinataires des données</h2>
			<p>
				Vos données personnelles sont susceptibles d’être communiquées à certains départements du
				responsable du traitement ou des sociétés du groupe Total à des fins d'analyses et
				d'enquêtes.
				<br />
				<br />
				Tout transfert de données vers un pays tiers hors de l’Espace Economique Européen est
				réalisé en conformité avec la règlementation applicable et de manière à assurer une
				protection adéquate des données.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>3. Transferts de données</h2>
			<p>
				Au titre des services fournis sur le site, vos données peuvent être transférer vers d’autres
				sociétés du Groupe Total situées en dehors de l’Union européenne.
				<br />
				<br />
				Afin d’assurer une protection adéquate des données personnelles originaires de l’Espace
				Economique Européenne pouvant être transférées à des entités du groupe Total établies hors
				de l’Espace économique européen, des « Binding Corporate Rules » (BCR) (Règles internes
				d'entreprise) ont été adoptées. <br />
				<br />
				Vous pouvez obtenir une copie des BCR et des autres garanties en écrivant à l’adresse de
				contact mentionnée ci-après et/ou cliquant ici
			</p>
			<h2 style={{ fontWeight: 'bold' }}>4. Sécurité et confidentialité de vos données</h2>
			<p>
				Le responsable du traitement met en œuvre des mesures appropriées pour préserver la sécurité
				et la confidentialité de vos données personnelles et, notamment, pour empêcher qu’elles
				soient déformées, endommagées, ou que des tiers non autorisés y aient accès.
			</p>
			<h2 style={{ fontWeight: 'bold' }}>5. Gestion des cookies</h2>
			<h3 style={{ fontWeight: 'bold' }}>5.1 Principe</h3>
			<p>
				Un cookie est un fichier qui permet d’enregistrer des informations relatives à la navigation
				de votre ordinateur sur le site internet (ex. nombres de visites, nombre de pages vues…) et
				permet notamment de faciliter vos visites sur le site.
				<br />
				<br />
				Vous pouvez à tout moment supprimer les « cookies » installés sur votre ordinateur, vous
				opposer à l'enregistrement de nouveaux « cookies » et recevoir une notification avant
				l’installation d’un nouveau cookie en configurant votre outil de navigation via les
				instructions figurant ci-dessous (« Types de cookies, cookies et statistiques et paramétrage
				»).
				<br />
				<br />
				<span style={{ fontWeight: 'bold' }}>
					Veuillez noter que si vous désinstallez un « cookie » ou si vous vous opposez à
					l’installation de « cookies » sur votre machine, vous risquez de ne pas être en mesure de
					bénéficier de certains des services du site internet.
				</span>
			</p>
			<h3 style={{ fontWeight: 'bold' }}>
				5.2 Types de cookies, cookies et statistiques et paramétrage.
			</h3>
			<p>
				Les cookies qui peuvent être installés sur votre serveur lorsque vous naviguez sur le site
				sont des cookies qui ont soit pour finalité exclusive de permettre ou de faciliter la
				communication par voie électronique ou qui sont strictement nécessaires à la fourniture du
				service que vous demandez (cookies de langues, cookies d’identification …), soit des cookies
				de statistiques ou autres cookies dans les conditions décrites ci-dessous.
				<br />
				<br />
				Lorsque les cookies exigent{' '}
				<span style={{ fontWeight: 'bold' }}>votre accord préalable à leur installation</span> nous
				vous le demandons au moyen du lien « pour en savoir plus », figurant sur la première page du
				site internet, étant{' '}
				<span style={{ fontWeight: 'bold' }}>
					précisé que la poursuite de votre navigation sur le site vaut acceptation.
				</span>
				<br />
				<br />
				Lorsque les cookies exigent votre accord préalable à leur installation nous vous le
				demandons au moyen du lien « pour en savoir plus », figurant sur la première page du site
				internet, étant précisé que la poursuite de votre navigation sur le site vaut acceptation.
				<h4 style={{ fontWeight: 'bold' }}>5.2.1 Quels sont les cookies installés ?</h4>
				<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
					• Les cookies proposés par le responsable de traitement
				</h5>
				<table>
					<tr>
						<th>Cookie</th>
						<th>Description</th>
						<th>Comment refuser ?</th>
						<th>Durée</th>
					</tr>
					<tr>
						<td>AccountId</td>
						<td>Identifiant du compte</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>AccountTypes</td>
						<td>Type(s) du compte</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>AuthToken</td>
						<td>Token d’authentification</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>CompanyID</td>
						<td>Identifiant de l’entreprise</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>FirstName / LastName</td>
						<td>Prénom / Nom du compte authentifié</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>PlateformId</td>
						<td>Identifiant de la plateforme</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
					<tr>
						<td>Plateforms</td>
						<td>Liste des plateformes</td>
						<td>Voir l’article 5.2.2</td>
						<td>Expire à la déconnexion ou après 24h.</td>
					</tr>
				</table>
				<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
					• Les cookies proposés par des tiers
				</h5>
			</p>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Les cookies de statistiques
			</h5>
			<p>Rapso-D n’utilise pas de cookies statistiques.</p>
			<h4 style={{ fontWeight: 'bold' }}>
				5.2.2 Comment supprimer, être averti de l’installation de cookies ou paramétrer votre
				navigateur ?
			</h4>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Comment supprimer les “cookies” déjà installés sur votre ordinateur ?
			</h5>
			<ul>
				<li>Allez sur votre poste de travail ;</li>
				<li>Sélectionnez dans C :\ le dossier Windows ;</li>
				<li>Ouvrez le dossier “Temporary Internet Files” ;</li>
				<li>Sélectionnez tous les fichiers (CTRL A) ;</li>
				<li>Choisissez l’option “supprimer”.</li>
			</ul>
			<h5 style={{ fontWeight: 'bold', fontStyle: 'italic', color: 'red' }}>
				• Comment paramétrer votre navigateur pour refuser ou être averti de l’installation de
				cookies ?
			</h5>
			<ul>
				<li>
					Navigation avec Internet Explorer 5 (Microsoft) : Choisir “Outils”, “Options Internet”,
					“Sécurité”, “Personnaliser le niveau”, dans le menu déroulant allez à “cookies”, rubrique
					“autoriser les cookies sur votre ordinateur”, choisir “demander” pour être avertis ou
					“désactiver” pour refuser tous les “cookies” ;
				</li>
				<li>
					Navigation avec Internet Explorer 6, 7 ou 8 (Microsoft) : Choisir “Outils”, “Options
					Internet”, “Confidentialité”, puis le niveau que vous souhaitez appliquer ;
				</li>
				<li>
					Navigation avec Firefox : Cliquer sur “Outils”, “Options”. Dans “Vie privée” décocher
					“Accepter les cookies” ;
				</li>
				<li>
					Navigation avec Google Chrome : Cliquer sur “Personnaliser et contrôler Google Chrome”,
					choisir “Paramètres”. Dans “Confidentialité” cliquer sur “Paramètres de contenu” et cocher
					“Bloquer les cookies et les données de site tiers”.
				</li>
			</ul>
			<h3 style={{ fontWeight: 'bold' }}>6. Vos droits /Contact</h3>
			<p>
				Conformément à la règlementation en vigueur, vous disposez d’un droit d’accès, de
				rectification, de suppression et d’opposition de vos données personnelles. Vous pouvez
				demander la communication de vos données personnelles et vous avez le droit de définir des
				directives relatives au sort de vos données à caractère personnel après votre décès. Vous
				pouvez également demander la limitation, OPTION : la portabilité de vos données [supprimer
				la portabilité si le traitement n’est pas fondé sur le consentement ou l’exécution d’un
				contrat] et/ou introduire une réclamation auprès de la CNIL.
				<br />
				<br />
				Veuillez adresser votre demande par message électronique à
				rc.data-protection@totalenergies.com ou à l’adresse suivante :<br />
				<br />
				Total Raffinage Chimie
				<br />
				Direction Industrielle
				<br />
				Tour Coupole 2, place Jean Millier
				<br />
				92078 Paris La Défense Cedex France
				<br />
			</p>
		</div>
	)
}

export default CGCookiesContentFr
