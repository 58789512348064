import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getJobList } from '../../../containers/DataContainer'
import { required, phoneFormat } from '../../../utils/VerifyFields'
import { composeValidators } from '../../../utils/Form'
import InputMultipleSelectField from '../../Form/inputFullPage/InputMultipleSelect'
import InputNumber from '../../Form/inputFullPage/InputNumber'
import InputPhone from '../../Form/inputFullPage/InputPhone'
import InputText from '../../Form/inputFullPage/InputText'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import { FRENCH_NATIONALITY } from '../../../constants/lang'

const useStyles = makeStyles((theme) => ({
	rootText: {
		color: theme.palette.secondary.main,
		fontFamily: 'Helvetica',
		fontSize: '1.2em',
		fontWeight: 500,
		width: '100%',
	},
	contentStyle: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		padding: '3%',
		borderRadius: '10px',
		backgroundColor: theme.palette.primary.main,
	},
	rootInput: {
		marginTop: '25px',
	},
	multipleSelect: {
		maxWidth: '83vw',
		marginLeft: 10,
		marginRight: 10,
	},
	inputs: {
		margin: 10,
	},
	inputPhone: {
		width: '100%',
		display: 'flex',
		margin: 10,
		marginRight: 5,
	},
}))

/**
 * Second step of the operational informations form
 */
function SecondStep({ setSelectedJobs, initialSelected, companyCountry }) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()
	const [listJobs, setListJobs] = useState(null)

	useEffect(() => {
		getJobList(1, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListJobs(res.data)
			}
		})
	}, [])

	const onChangeMultipleSelect = (e) => {
		setSelectedJobs(e.target.value)
	}

	return (
		<>
			<Grid container>
				<Grid item xs={12}>
					<InputText
						name="address"
						label={t('address')}
						classes={{ root: classes.inputs }}
						validate={required}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					{companyCountry === FRENCH_NATIONALITY && (
						<InputNumber
							name="cp"
							label={t('postalCode')}
							classes={{ root: classes.inputs }}
							validate={required}
						/>
					)}
					{/* if the company is not french, just put an input text */}
					{companyCountry !== FRENCH_NATIONALITY && (
						<InputText
							name="cp"
							label={t('postalCode')}
							classes={{ root: classes.inputs }}
							validate={required}
						/>
					)}

					<InputPhone
						name="phone"
						label={t('agencyPhone')}
						className={classes.inputPhone}
						validate={composeValidators(required, phoneFormat)}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<InputText
						name="city"
						label={t('city')}
						classes={{ root: classes.inputs }}
						validate={required}
					/>
					<InputText
						name="activity"
						label={t('activity')}
						classes={{ root: classes.inputs }}
						validate={required}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} className={classes.multipleSelect}>
				<InputMultipleSelectField
					initialSelected={initialSelected}
					name="jobList"
					onChange={onChangeMultipleSelect}
					label={t('profession')}
				>
					{listJobs &&
						listJobs.map((job, i) => (
							<SelectMenuItem key={i} value={job.metId}>
								{job.lmtNom}
							</SelectMenuItem>
						))}
				</InputMultipleSelectField>
			</Grid>
		</>
	)
}
export default SecondStep
