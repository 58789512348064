import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { askAttachment } from '../../containers/DataContainer'
import { GetPlatform } from '../../containers/RegisterContainer'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputMultipleSelect from '../Form/input/InputMultipleSelect'
import InputTextArea from '../Form/input/InputTextArea'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import SlidingForm from '../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
	explainText: {
		color: theme.palette.primary.main,
		marginBottom: 20,
		marginTop: 25,
	},
	justify: {
		marginTop: 10,
	},
}))

const AttachmentExpiredForm = ({ open, close, data }) => {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [sites, setSites] = useState([{}])
	const [platforms, setPlatforms] = useState([])
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		GetPlatform(cookies, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setSites(res.data)
			}
		})
	}, [cookies])

	const onSubmit = (values) => {
		let platformList = []
		//Create an array with the id of a platform and the justification
		platforms.forEach((i) => {
			if (values['justify-' + i]) {
				platformList = [
					...platformList,
					{
						platformId: i,
						justification: values['justify-' + i],
					},
				]
			}
		})
		platformList.forEach((requestData) => {
			let formData = {
				platformId: requestData['platformId'],
				justification: requestData['justification'],
			}
			askAttachment(formData, requestData.platformId, function (res, error) {
				if (error) {
					enqueueSnackbar(t('error') + ` : ${error.status} ! ` + t(error.data[0].code), {
						variant: 'error',
					})
					return
				}
				if (res) {
					enqueueSnackbar(
						t('attachmentRequestLogin').concat(
							' ',
							sites.find((s) => s.platformId === requestData.platformId).name,
						),
						{ variant: 'success' },
					)
				}
			})
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('attachmentRequest')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
							setPlatforms([])
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
				setPlatforms([])
			}}
		>
			<Form onSubmit={onSubmit}>
				<Typography variant="body1" classes={{ root: classes.explainText }}>
					{t('attachmentExpired')}
				</Typography>
				<Typography variant="body1" classes={{ root: classes.explainText }}>
					{t('askNewAttachment')}
				</Typography>
				<InputMultipleSelect
					onChange={(e) => {
						setPlatforms(e.target.value)
					}}
					name="platform"
					label={t('platform')}
					classes={{
						root: classes.size1,
					}}
				>
					{data &&
						data.listPlatforms.map((site, i) => {
							return (
								<SelectMenuItem key={i} value={site.platformId}>
									{site.name}
								</SelectMenuItem>
							)
						})}
				</InputMultipleSelect>
				{platforms.length > 0 &&
					platforms.map((platform) =>
						sites.map((site, i) => {
							if (site.platformId === platform) {
								return (
									<InputTextArea
										classes={{ root: classes.justify }}
										name={`justify-${site.platformId}`}
										key={i}
										label={t('justification') + ` ${site.name}`}
									/>
								)
							}
							return ''
						}),
					)}
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
AttachmentExpiredForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default AttachmentExpiredForm
