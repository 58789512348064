import { makeStyles } from '@material-ui/styles'
import i18next from 'i18next'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormIcon from '../../../../components/Form/FormIcon'
import InputCheckbox, { InputSwitch } from '../../../../components/Form/input/InputCheckbox'
import InputMail from '../../../../components/Form/input/InputMail'
import InputPhone from '../../../../components/Form/input/InputPhone'
import InputText from '../../../../components/Form/input/InputText'
import SlidingForm from '../../../../components/Form/SlidingForm'
import CONTACTS from '../../../../constants/contacts'
import { getContactTypeList, upsertCompanyContacts } from '../../../../containers/DataContainer'
import { composeValidators } from '../../../../utils/Form'
import { maxLong, required } from '../../../../utils/VerifyFields'
import Form from '../../../Form/Form'
import FormButtonIcon from '../../../Form/FormButtonIcon'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function CreateContactForm({ open, close, data }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [listType, setListType] = useState([])
	const defaultValues = { notifie: false, createAccess: false }
	const [contactValues, setContactValues] = useState({})
	const [language, setLanguage] = useState(1)
	const { enqueueSnackbar } = useSnackbar()
	// Array of all contact types
	const contactTypesArr = Object.values(CONTACTS)

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	})

	useEffect(() => {
		if (!open) return
		if (data !== undefined) {
			// Formats the selected contact types
			let typesValues = {}
			if (data.checkedDefaultTypes !== undefined && Array.isArray(data.checkedDefaultTypes)) {
				for (let typeId of data.checkedDefaultTypes) {
					typesValues = { ...typesValues, ['type' + typeId]: true }
				}
			}
			setContactValues({
				lastname: data.name,
				firstname: data.firstName,
				mail: data.email,
				phone: data.phone,
				notifie: data.isNotifiable,
				createAccess: data.hasAccess,
				...typesValues,
			})
		}
	}, [open, data])

	const onSubmit = (values) => {
		let id = {}
		let access = {}

		if (data !== undefined) {
			id = { contactId: data.id }
		}

		access = { createApplicationAccess: values.createAccess }

		let selectedTypes = []

		//Recovery of selected contact types
		for (let CType of contactTypesArr) {
			if (values['type' + CType.id]) selectedTypes = [...selectedTypes, CType.id]
		}

		const upsertData = {
			contactUsername: values.login,
			contactName: values.lastname,
			contactFirstName: values.firstname,
			contactEmail: values.mail,
			contactPhone: values.phone,
			roles: selectedTypes,
			isContactNotifiable: values.notifie,
			...id,
			...access,
		}
		upsertCompanyContacts(upsertData, data.companyId, function (res, error) {
			if (res) {
				let selectedContactList = []
				let newerContactList = res.data
				let olderContactList = data.contactList

				// Find all contacts without ID
				selectedContactList = newerContactList.filter((contact) => {
					let result = true
					for (let olderContact of olderContactList) {
						if (olderContact.id === contact.id) result = false
					}
					return result
				})

				data.setCreateContactResult(selectedContactList)
				if (data.id) {
					enqueueSnackbar(t('TheContactHasBeenUpdated'), { variant: 'info' })
				} else {
					enqueueSnackbar(t('TheContactHasBeenAdded'), { variant: 'info' })
				}
			}
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
		})
		close()
	}

	useEffect(() => {
		getContactTypeList(language, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListType(res.data)
			}
		})
	}, [open])

	// Need at least one contact type
	const validate = (values) => {
		const errors = {}
		let flag = false
		for (let CType of contactTypesArr) {
			if (values['type' + CType.id]) flag = true
		}

		if (!flag) {
			errors.type1 = t('CheckAtLeastOneType')
		}
		return errors
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('contacts:AddContact')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form
				onSubmit={onSubmit}
				initialValues={{ ...defaultValues, ...contactValues }}
				validate={validate}
			>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="lastname"
					label={t('lastname')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="firstname"
					label={t('firstname')}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="mail"
					label={t('email')}
				/>
				<InputPhone
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="phone"
					label={t('phone')}
				/>
				<InputSwitch name="notifie" label={t('notifie')} />
				{listType &&
					listType.map((type) => {
						let id = type.id
						return <InputCheckbox name={'type' + id} label={type.name} value={id} key={id} />
					})}
				<InputSwitch name="createAccess" label={t('contacts:createAccess')} />
				<div className={classes.validateGroup}>
					<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
				</div>
			</Form>
		</SlidingForm>
	)
}

export default CreateContactForm
