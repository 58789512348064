import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		margin: '0 0 0 auto',
	},
}))

function FormButtonFullPage({ size, label, type, onClick, ...props }) {
	const classes = useStyles()

	return (
		<Button className={classes.button} type={type} variant="outlined" onClick={onClick} {...props}>
			{label}
		</Button>
	)
}

export default FormButtonFullPage
