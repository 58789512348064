import React from 'react'
import { FormControlLabel, FormControl, RadioGroup, Radio } from '@material-ui/core'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
	label: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
	},
	root: {
		display: 'inline',
		height: 0,
		padding: 0,
		color: '#black',
		'&$checked': {
			color: 'black',
		},
	},
	block: {
		display: 'block',
	},
	center: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
}))

export default function InputRadio({ name, children, ...props }) {
	const classes = useStyles()
	return (
		<FormControl {...props}>
			<RadioGroup name={name} className={classes.block} {...props}>
				{children}
			</RadioGroup>
		</FormControl>
	)
}

export function TotalRadio({ value, label,readOnly, name, image, ...props }) {
	const classes = useStyles()
	return (
		<FormControlLabel
			classes={{
				label: classes.label,
			}}
			label={label}
			control={
				<Field
					name={name}
					value={value}
					type="radio"
					render={({ input, meta }) => (
						<div className={classes.center}>
							{image && <img src={image} alt={value} />}
							<Radio
								classes={{
									root: classes.root,
									checked: classes.checked,
								}}
								color="secondary"
								value={value}
								{...input}
								disabled={readOnly}
								disableRipple
							/>
						</div>
					)}
				/>
			}
			{...props}
		/>
	)
}
