import React from 'react'
import { makeStyles } from '@material-ui/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MenuContextProvider } from '../contexts/MenuContext'
import TotalAppBar from '../components/TotalAppBar/TotalAppBar'
import TotalToolbar from '../components/TotalToolBar/TotalToolBar'
import { useTotalTitleBarContext } from '../contexts/TotalTitleBarContext'
import TotalTitleBar from '../components/TotalTitleBar/TotalTitleBar'
import TotalFooter from '../components/TotalFooter/TotalFooter'
import HelpModal from '../components/HelpModal/HelpModal'

// STYLES
const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		// Solution temporaire
		paddingBottom: theme.spacing.unit * 4.5,
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120,
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
	},
}))

function MainLayout({ children }) {
	const classes = useStyles()
	const { titleBar } = useTotalTitleBarContext()
	const { type, data } = titleBar

	return (
		<div className={classes.root}>
			<MenuContextProvider>
				<CssBaseline />
				<TotalAppBar />
				<TotalToolbar />
				<HelpModal />
				<main className={classes.content}>
					<div className={classes.toolbar} />
					{type === 'titleBar' && (
						<TotalTitleBar title={data.title} icon={data.icon} subtitle={data.subtitle} />
					)}
					{type === 'chevrons' && <TotalTitleBar />}
					{children}
					<TotalFooter />
				</main>
			</MenuContextProvider>
		</div>
	)
}
export default MainLayout
