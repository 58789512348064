import moment from 'moment'
import i18next from 'i18next'
export function dateDisplay(date) {
	moment.locale(i18next.language)
	return moment(date).format('DD/MM/YYYY HH:mm')
}
/**
 * Add hour to current date and return date
 * @return Date
 */
export function addHourToDate(hour) {
	const today = new Date()
	today.setHours(today.getHours() + hour)
	return today
}
/**
 * Add minutes to current date and return date
 * @return Date
 */
export function addMinuteToDate(minute) {
	const today = new Date()
	today.setMinutes(today.getMinutes() + minute)
	return today
}
/**
 * Format the current Date in the expected format
 * @return Date
 */
export function formatDate(date) {
	return moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ')
}
