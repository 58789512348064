import React, { useEffect, useState } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import SlidingForm from '../../Form/SlidingForm'
import InputText from '../../Form/input/InputText'
import FormIcon from '../../Form/FormIcon'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import InputTextArea from '../../Form/input/InputTextArea'
import FormStep from '../../Form/FormStep'
import VerticalStepper from '../../VerticalStepper'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import CloseIcon from '@material-ui/icons/Close'
import CheckIcon from '@material-ui/icons/Check'
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Paper,
	Button,
	Dialog,
	DialogContent,
	Typography,
	DialogTitle,
	DialogActions,
	IconButton,
	Icon,
} from '@material-ui/core'
import Form from '../../Form/Form'
import FPInputText from '../../Form/inputFullPage/InputText'
import { InputSwitch } from '../../Form/inputFullPage/InputCheckbox'
import InputSelect from '../../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import { upsertSpecificActivity, getMeasureType } from '../../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import { required } from '../../../utils/VerifyFields'

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing.unit * 2,
		paddingBottom: 10,
		backgroundColor: 'rgba(0,0,0,0)',
	},
	input: {
		display: 'block',
		margin: theme.spacing.unit * 2,
		marginLeft: 0,
	},
	basicWidth: {
		width: 400,
	},
	fullWidth: {
		width: '100%',
	},
	rootBtn: {
		display: 'block',
		marginTop: theme.spacing.unit * 2,
	},
	previousBtn: {
		borderRadius: 30,
		paddingLeft: 15,
		paddingRight: 15,
		color: '#DDDDDD',
	},
	nextAndCompletedBtn: {
		border: 'solid 2px white',
		borderRadius: 30,
		paddingLeft: 15,
		paddingRight: 15,
		color: theme.palette.primary.main,
		marginLeft: theme.spacing.unit,
		'&:disabled': {
			borderColor: 'rgba(0,0,0,0.2)',
		},
	},
	text: {
		marginTop: 10,
	},
	hidden: {
		display: 'none',
	},
	paper: {
		padding: 20,
	},
}))

function PhasesUnsafeOperationsForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation(['user', 'translation', 'chemical'])
	const { enqueueSnackbar } = useSnackbar()

	const [initialValues, setInitialValues] = useState({})
	const [openDialog, setOpenDialog] = useState(false)
	const [listMesures, setListMesures] = useState([])
	const [measureTypes, setMeasureTypes] = useState([])

	const { i18n } = useTranslation(['user'])
	const langId = i18n.language === 'fr' ? 1 : 2

	useEffect(() => {
		getMeasureType(langId, (res, err) => {
			if (err) return

			if (res && res.status === 200) {
				setMeasureTypes(res.data)
			}
		})
	}, [])

	useEffect(() => {
		if (!open || !data.activity) return
		setInitialValues(data.activity)
		setListMesures(data.activity.measures)
	}, [open])
	// LISTE DES METIERS

	const onSubmit = (values) => {
		let currentId = data.activity ? { id: data.activity.id } : {}
		let formData = {
			title: values.title,
			workDescription: values.workDescription,
			risks: values.risks,
			precautions: values.precautions,
			observations: values.observations,
			measures: listMesures,
		}
		upsertSpecificActivity(
			cookies[PLATFORM_ID_COOKIE],
			data.companyRecordId,
			{ ...currentId, ...formData },
			function (res, error) {
				if (res) {
					data.updateData()
					enqueueSnackbar(t('pointAdded'), {
						variant: 'info',
					})
					close()
					if (res.data && res.data.companyRecordId)
						data.setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (error) {
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	const addMeasure = (values) => {
		setListMesures([
			{
				measureType: values.metier,
				description: values.mesure,
				eeAction: values.ee,
				euAction: values.eu,
			},
			...listMesures,
		])
	}

	const deleteMeasure = (name) => {
		let data = listMesures.filter((obj) => obj.description !== name)
		setListMesures(data)
	}

	return (
		<>
			<SlidingForm
				direction="fullscreen"
				open={open}
				title={t('createParticular')}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={(e) => {
								close()
								setInitialValues({})
							}}
						/>
					</>
				}
				submitLabel="Valider"
			>
				<VerticalStepper
					result={onSubmit}
					contentStyle={{}}
					initialValues={initialValues}
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classes.nextAndCompletedBtn,
						next: classes.nextAndCompletedBtn,
						completed: classNames([
							classes.nextAndCompletedBtn,
							classes.completedBtn,
							{ [classes.hidden]: data.isReadOnly },
						]),
						finished: classes.nextAndCompletedBtn,
					}}
				>
					<FormStep label="Informations" vertical>
						<InputText
							classes={{ root: classes.text }}
							name="title"
							label={t('title1')}
							validate={required}
						/>
						<InputTextArea
							classes={{ root: classes.text }}
							name="workDescription"
							label={t('workDescription')}
							validate={required}
						/>
						<InputTextArea
							classes={{ root: classes.text }}
							name="risks"
							label={t('risks')}
							validate={required}
						/>
						<InputTextArea
							classes={{ root: classes.text }}
							name="precautions"
							label={t('precautions')}
						/>
						<InputTextArea
							classes={{ root: classes.text }}
							name="observations"
							label={t('observations')}
						/>
					</FormStep>
					<FormStep
						label="Mesures"
						validate={(values) => {
							if (!listMesures.length)
								enqueueSnackbar('Au moins une mesure doit être saisie', { variant: 'error' })
							return listMesures.length
						}}
						vertical
					>
						<div></div>
						<Paper className={classes.paper}>
							<Button
								variant="contained"
								color="primary"
								className={classes.button}
								onClick={() => {
									setOpenDialog(true)
								}}
							>
								{t('add')}
							</Button>
							<Table className={classes.table}>
								<TableHead>
									<TableRow>
										<TableCell align="center">{t('Mesure')}</TableCell>
										<TableCell align="center">{t('TypeMesure')}</TableCell>
										<TableCell align="center">{t('ActionEE')}</TableCell>
										<TableCell align="center">{t('ActionEU')}</TableCell>
										<TableCell align="center">{t('delete')}</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{listMesures &&
										listMesures.map((mesure, i) => {
											return (
												<TableRow key={i}>
													<TableCell align="center" component="th" scope="row">
														{mesure.description}
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														{measureTypes[mesure.measureType - 1].name}
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<IconButton size="small" className={classes.icon}>
															{mesure.eeAction ? <CheckIcon /> : <CloseIcon />}
														</IconButton>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<IconButton size="small" className={classes.icon}>
															{mesure.euAction ? <CheckIcon /> : <CloseIcon />}
														</IconButton>
													</TableCell>
													<TableCell align="center" component="th" scope="row">
														<IconButton
															disabled={data.isReadOnly}
															onClick={() => {
																deleteMeasure(mesure.description)
															}}
														>
															<Icon>delete</Icon>
														</IconButton>
													</TableCell>
												</TableRow>
											)
										})}
								</TableBody>
							</Table>
						</Paper>
					</FormStep>
				</VerticalStepper>
			</SlidingForm>
			<Dialog
				onClose={(e) => setOpenDialog(false)}
				aria-labelledby="customized-dialog-title"
				open={openDialog}
			>
				<Form
					onSubmit={(values) => {
						let result = {}
						if (!values.eu) result.eu = false
						if (!values.ee) result.ee = false

						setOpenDialog(false)
						addMeasure({ ...values, ...result })
					}}
				>
					<DialogTitle id="customized-dialog-title" onClose={(e) => setOpenDialog(false)}>
						<Typography color="secondary">{t('AddMesure')}</Typography>
					</DialogTitle>
					<DialogContent>
						<FPInputText name="mesure" label={t('Mesure')} />
						<br />
						<InputSelect classes={{ root: classes.inputRoot }} name="metier" label={t('type')}>
							{measureTypes &&
								measureTypes.map((type) => {
									return (
										<SelectMenuItem key={type.id} name={'measureType-' + type.id} value={type.id}>
											{type.name}
										</SelectMenuItem>
									)
								})}
						</InputSelect>
						<br />
						<InputSwitch name="eu" label={t('ActionEU')} />
						<br />
						<InputSwitch name="ee" label={t('ActionEE')} />
						<br />
					</DialogContent>
					<DialogActions>
						<Button type="submit" color="secondary">
							{t('submit')}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		</>
	)
}
export default PhasesUnsafeOperationsForm
