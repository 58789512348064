import { createSlice } from '@reduxjs/toolkit'

export const platformsSlice = createSlice({
	name: 'platforms',
	initialState: {
		platforms: [],
	},
	reducers: {
		setPlatforms: (state, action) => {
			state.platforms = action.payload
		},
	},
})

export const { setPlatforms } = platformsSlice.actions
export default platformsSlice.reducer
