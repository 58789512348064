import React from 'react'
import { Icon, IconButton } from '@material-ui/core'
import DataTable from '../NewDataTable/NewDataTable'

function UserForm() {
	const columns = [
		{
			name: 'preventionPlan',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'companyName',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'revision',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'beforeStatus',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'afterStatus',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'validator',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
		{
			name: 'date',
			options: {
				filter: true,
				filterType: 'textField',
				sort: false,
			},
		},
	]

	let data = [
		{
			preventionPlan: 'P2013-1',
			companyName: 'ORTEC',
			revision: 0,
			beforeStatus: 'Soumis',
			afterStatus: 'Incomplet',
			validator: 'Alexandre Genecque',
			date: '18/09/2017 11:47:53',
		},
	]

	const options = {
		selectableRows: false,
		filter: true,
		filterType: 'date',
		responsive: 'scroll',
	}

	return (
		<DataTable
			title="Suivi"
			columns={columns}
			data={data}
			options={options}
			customColumns={[
				{
					name: 'visitePrealable',
					component: (value) => {
						if (value)
							return (
								<IconButton>
									<Icon>check</Icon>
								</IconButton>
							)
						else
							return (
								<IconButton>
									<Icon>clear</Icon>
								</IconButton>
							)
					},
				},
				{
					name: 'sousTraitant',
					component: function sousTraitantComponent(value) {
						if (!value) return
						return (
							<IconButton>
								<Icon>link</Icon>
							</IconButton>
						)
					},
				},
				{
					name: 'actions',
					component: function actionsComponent() {
						return (
							<>
								<IconButton>
									<Icon>refresh</Icon>
								</IconButton>
								<IconButton>
									<Icon>edit</Icon>
								</IconButton>
								<IconButton>
									<Icon>search</Icon>
								</IconButton>
								<IconButton>
									<Icon>get_app</Icon>
								</IconButton>
								<IconButton>
									<Icon>delete</Icon>
								</IconButton>
							</>
						)
					},
				},
			]}
		/>
	)
}

export default UserForm
