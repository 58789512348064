import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import {
	Button,
	Typography,
	Grid,
	Paper,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	List,
	ListItem,
	ListItemText,
	ListItemSecondaryAction,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { required } from '../../utils/VerifyFields'
import Form from '../../components/Form/Form'
import InputText from '../../components/Form/inputFullPage/InputText'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import AddCircle from '@material-ui/icons/AddCircle'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import {
	getCompanyRecordRefusalMessage,
	updateCompanyRecordRefusalMessage,
	deleteCompanyRecordRefusalMessage,
	addCompanyRecordRefusalMessage,
} from '../../containers/DataContainer'

const useStyles = makeStyles((theme) => ({
	formRefusalMessage: {
		margin: 40,
		padding: 20,
		backgroundColor: theme.palette.primary.main,
	},
	container: {
		display: 'block',
	},
	textArea: {
		margin: theme.spacing.unit * 2,
		marginLeft: 0,
	},
	dFlex: {
		display: 'flex',
	},
	titleCenterVertical: {
		alignSelf: 'center',
	},
	btnIcon: {
		marginLeft: 5,
	},
	addIcon: {
		width: '1.5em',
		height: '1.5em',
	},
	popupTitle: {
		color: theme.palette.secondary.main,
		margin: 25,
		marginBottom: 0,
	},
	dialog: {
		paddingTop: 0,
	},
	refusalMessageItems: {
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		maxWidth: '75%',
	},
}))

function RefusalMessageCompanyRecord() {
	const { t } = useTranslation(['user', 'companyRecord'])
	const [cookies] = useCookies()
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()

	const [openAddRefusalMessagePopUp, setOpenAddRefusalMessagePopUp] = useState(false)
	const [openEditRefusalMessagePopUp, setOpenEditRefusalMessagePopUp] = useState(false)

	const [refusalMessageItems, setRefusalMessageItems] = useState([])
	const [currentEditIndex, setCurrentEditIndex] = useState(null)
	const ERROR_MESSAGE = 'user:error'

	useEffect(() => getRefusalMessages(), [])

	const RefusalMessagePopUp = (props) => {
		const { onClose, open, onSubmit: onSubmitEvent, defaultValue } = props
		return (
			<Dialog open={open} onClose={onClose} classes={{ paper: classes.popup }}>
				<Typography classes={{ root: classes.popupTitle }} variant="h6">
					{t('new_message')}
				</Typography>
				<Form onSubmit={onSubmitEvent} initialValues={defaultValue}>
					<DialogContent className={classes.dialog}>
						<InputText
							classes={{ root: classes.textArea }}
							name="message"
							label={t('user:message')}
							validate={required}
						/>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose} color="secondary">
							{t('cancel')}
						</Button>
						<Button type="submit" color="secondary" autoFocus>
							{t('validate')}
						</Button>
					</DialogActions>
				</Form>
			</Dialog>
		)
	}

	const handleClickOpen = useCallback(() => {
		setOpenAddRefusalMessagePopUp(true)
	})
	const handleClose = () => {
		setOpenAddRefusalMessagePopUp(false)
		setOpenEditRefusalMessagePopUp(false)
	}
	const onClickEditRefusalMessageItem = (index) => {
		setOpenEditRefusalMessagePopUp(true)
		setCurrentEditIndex(index)
	}
	const addRefusalMessageItem = (values) => {
		let formData = {
			message: values.message,
		}
		addCompanyRecordRefusalMessage(parseInt(cookies[PLATFORM_ID_COOKIE]), formData, (res, err) => {
			if (err) console.error(err)
			const { problem } = res
			if (res) {
				if (problem) {
					enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })
					return
				}
				handleClose()
				getRefusalMessages()
				enqueueSnackbar(t('companyRecord:refusal_message_new_item'), {
					variant: 'success',
				})
			}
		})
	}

	const getRefusalMessages = () => {
		getCompanyRecordRefusalMessage(parseInt(cookies[PLATFORM_ID_COOKIE]), (res, err) => {
			if (err) console.error(err)

			if (res) {
				setRefusalMessageItems(res.data)
			}
		})
	}

	const editRefusalMessageItem = useCallback((values) => {
		let formData = {
			id: refusalMessageItems[currentEditIndex].id,
			message: values.message,
		}

		updateCompanyRecordRefusalMessage(
			parseInt(cookies[PLATFORM_ID_COOKIE]),
			formData,
			(res, err) => {
				if (err) console.error(err)

				const { problem } = res

				if (res) {
					if (problem) return enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })

					setCurrentEditIndex(null)
					handleClose()
					getRefusalMessages()
					return enqueueSnackbar(t('companyRecord:refusal_message_edit_item'), {
						variant: 'success',
					})
				}
			},
		)
	})

	const onClickDeleteRefusalMessageItem = (index) => {
		let formData = {
			id: refusalMessageItems[index].id,
		}

		deleteCompanyRecordRefusalMessage(
			parseInt(cookies[PLATFORM_ID_COOKIE]),
			formData.id,
			(res, err) => {
				if (err) console.error(err)

				if (res) {
					const { problem } = res
					if (problem) {
						enqueueSnackbar(t(ERROR_MESSAGE), { variant: 'error' })
						return
					}

					getRefusalMessages()
					enqueueSnackbar(t('companyRecord:refusal_message_delete_item'), {
						variant: 'success',
					})
				}
			},
		)
	}

	return (
		<>
			<RefusalMessagePopUp
				open={openAddRefusalMessagePopUp}
				onSubmit={addRefusalMessageItem}
				onClose={useCallback(() => {
					setOpenAddRefusalMessagePopUp(false)
				})}
			/>
			<RefusalMessagePopUp
				open={openEditRefusalMessagePopUp}
				onSubmit={editRefusalMessageItem}
				defaultValue={
					refusalMessageItems && refusalMessageItems[currentEditIndex]
						? { message: refusalMessageItems[currentEditIndex].message }
						: null
				}
				onClose={useCallback(() => {
					setOpenEditRefusalMessagePopUp(false)
				})}
			/>
			<HeadTitle title={t('companyRecord:refusal_message_configuration')} />
			<Grid container justify="center" className={classes.container} spacing={8}>
				<Paper className={classes.formRefusalMessage}>
					<Grid item xs={12} className={classes.dFlex}>
						<Typography variant="h6" color="secondary" className={classes.titleCenterVertical}>
							{t('companyRecord:refusal_message_configuration')}
						</Typography>
						<IconButton className={classes.btnIcon} onClick={handleClickOpen}>
							<AddCircle className={classes.addIcon} />
						</IconButton>
					</Grid>
					<Grid item xs={12}>
						<List>
							{refusalMessageItems &&
								refusalMessageItems.map((child, index) => (
									<ListItem className={classes.refusalMessageItems} key={child.id}>
										<ListItemText primary={child.message} />
										<ListItemSecondaryAction>
											<IconButton
												edge="start"
												aria-label="edit"
												onClick={() => onClickEditRefusalMessageItem(index)}
											>
												<EditIcon />
											</IconButton>
											<IconButton
												edge="end"
												aria-label="delete"
												onClick={() => onClickDeleteRefusalMessageItem(index)}
											>
												<DeleteIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								))}
						</List>
					</Grid>
				</Paper>
			</Grid>
		</>
	)
}

export default RefusalMessageCompanyRecord
