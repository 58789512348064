import React, { useState, useEffect } from 'react'
import { Typography, Button, Icon, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { useTranslation } from 'react-i18next'
import { downloadFile, setDEEnvironmentalAspect } from '../../../containers/DataContainer'
import {
	COLMUNS_ENVIRONMENTAL_ASPECTS,
	convertArrayToDatatable,
	getIndexOfColumn,
} from '../../../utils/Datatable'
import { useSnackbar } from 'notistack'
import fileDownload from 'js-file-download'
import api from '../../../utils/Requester'
import { bindParams } from '../../../utils/Link'
import { ENVIRONMENTALASPECTSDE } from '../../../constants/url'
import PropTypes from 'prop-types'
import { managedFileNotFoundError } from '../../../utils/File'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: 14,
		right: 300,
	},
}))

const actionsColumn = {
	name: 'actions',
	options: {
		filter: false,
		filterType: 'textField',
		sort: false,
	},
}

function EnvironmentalAspects({
	forms,
	btnsStyle,
	isReadOnly,
	queries,
	setRevisionCompanyRecordId,
	isReviewMode,
	nextStep,
	previousStep,
	refreshChevrons,
	setRefreshChevrons,
}) {
	const classes = useStyles()
	const { companyRecordId, companyId, platformId } = queries
	const [AddEnvironmentalAspectsFiles, EnvironmentalAspectNewFileForm] = forms
	const [update, setUpdate] = useState(false)
	const [listAspects, setListAspects] = useState([])
	const { t } = useTranslation(['user', 'environmentalAspects'])
	const [SCs, setSCs] = useState({ columns: [], data: [], options: {} })
	const { enqueueSnackbar } = useSnackbar()
	const [isLoading, setIsLoading] = useState(true)

	const TRANS_COLUMNS = [
		{ name: 'fileName', label: t('environmentalAspects:fileName') },
		{ name: 'environmentalAspectCategory', label: t('environmentalAspectCategory') },
		{ name: 'owner', label: t('user') },
		{ name: 'updatedAt', label: t('date_add_or_update') },
	]

	const openAddEnvironmentalAspectsFiles = () => {
		AddEnvironmentalAspectsFiles.addData({
			companyRecordId: companyRecordId,
			companyId: companyId,
			openEnvironmentalAspectNewFileForm: openEnvironmentalAspectNewFileForm,
			update: update,
			setUpdate: setUpdate,
			platformId: platformId,
			listAspects: listAspects,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
		})
		AddEnvironmentalAspectsFiles.open()
	}

	const openEnvironmentalAspectNewFileForm = () => {
		EnvironmentalAspectNewFileForm.addData({
			companyRecordId: companyRecordId,
			update: update,
			setUpdate: setUpdate,
			platformId: platformId,
			listAspects: listAspects,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
		})
		EnvironmentalAspectNewFileForm.open()
	}

	const recoverDatatable = () => {
		setIsLoading(true)
		api.get(bindParams(ENVIRONMENTALASPECTSDE, platformId, companyRecordId)).then((res) => {
			const { ok, data } = res
			setIsLoading(false)
			if (!ok) return console.error('Error to subcontractor companies datatable !')
			if (ok) {
				setListAspects(data)
				if (data.length) {
					let tmpData = data.map((dt) => ({
						updatedAt: dt.dateAdded,
						owner: dt.username,
						fileName: dt.fileName,
						environmentalAspectCategory: dt.environmentalAspectCategory,
						file: { ...dt },
					}))
					return setSCs(convertArrayToDatatable(tmpData, ['file']))
				} else {
					return setSCs({ columns: [], data: [], options: {} })
				}
			}
		})
	}

	useEffect(recoverDatatable, [update])

	const download = (path, name) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				fileDownload(res.data, name)
			}
		})
	}

	const updateData = () => {
		setUpdate(!update)
	}

	const DeleteElement = (path) => {
		let indexToDelete = listAspects.findIndex((element) => {
			return element.path === path
		})

		listAspects.splice(indexToDelete, 1)

		const formData = new FormData()
		for (let index = 0; index < listAspects.length; index++) {
			if (listAspects[index].dateAdded)
				formData.append(`files[${index}].dateAdded`, listAspects[index].dateAdded)
			if (listAspects[index].fileName)
				formData.append(`files[${index}].fileName`, listAspects[index].fileName)
			if (listAspects[index].path) formData.append(`files[${index}].path`, listAspects[index].path)
			if (listAspects[index].username)
				formData.append(`files[${index}].username`, listAspects[index].username)
			if (listAspects[index].environmentalAspectCategory)
				formData.append(
					`files[${index}].environmentalAspectCategory`,
					listAspects[index].environmentalAspectCategory,
				)
		}

		setDEEnvironmentalAspect(platformId, companyRecordId, formData, (res, err) => {
			if (err) {
				console.log(err)
			}

			if (res) {
				setRefreshChevrons(!refreshChevrons)
				updateData()
			}
		})
	}

	const ENV_ASPECT_CATEGORIES = {
		error: { id: 0, label: 'error' },
		envScan: { id: 1, label: 'environmental_scan' },
		genWaste: { id: 2, label: 'generated_waste' },
	}

	const findTypeById = (id) => {
		let categories = Object.values(ENV_ASPECT_CATEGORIES)

		for (let category of categories) {
			if (category.id === id) return category
		}

		return ENV_ASPECT_CATEGORIES.error
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('environmentalAspect')}
			</Typography>
			<br />
			<div className={classes.dataTableContainer}>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openAddEnvironmentalAspectsFiles}
					>
						{t('add')}
					</Button>
				)}
				<DataTable
					title={t('Documents')}
					columns={[...SCs.columns, actionsColumn]}
					data={SCs.data}
					customLabels={TRANS_COLUMNS}
					options={SCs.options}
					isLoadin={isLoading}
					customColumns={[
						{
							name: 'actions',
							component: function componentCustomColumns(_value, tableMeta) {
								let currFileName =
									tableMeta.rowData[
										getIndexOfColumn(SCs.columns, COLMUNS_ENVIRONMENTAL_ASPECTS.FILENAME)
									]
								let currPath =
									tableMeta.rowData[
										getIndexOfColumn(SCs.columns, COLMUNS_ENVIRONMENTAL_ASPECTS.FILE)
									].path
								return (
									<>
										<IconButton
											onClick={() => {
												download(currPath, currFileName)
											}}
										>
											<Icon>get_app</Icon>
										</IconButton>
										<IconButton
											onClick={() => {
												DeleteElement(currPath)
											}}
										>
											<Icon>delete</Icon>
										</IconButton>
									</>
								)
							},
						},
						{
							name: 'environmentalAspectCategory',
							component: (value) => {
								let typeLabel = findTypeById(value).label
								return t('environmentalAspects:' + typeLabel)
							},
						},
					]}
				/>
			</div>

			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}
EnvironmentalAspects.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	isReadOnly: PropTypes.bool,
	queries: PropTypes.object,
	setRevisionCompanyRecordId: PropTypes.func,
	isReviewMode: PropTypes.bool,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
}
export default EnvironmentalAspects
