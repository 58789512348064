const TYPES = {
	principale: {
		code: 'P',
		icon: '/icons/company_account_types/principale.svg',
	},
	subcontract: {
		code: 'S',
		icon: '/icons/company_account_types/straitant.svg',
	},
	princsub: {
		code: 'PS',
		icon: '/icons/company_account_types/PS.svg',
	},
	error: { code: '', icon: 'error' },
}

export default TYPES
