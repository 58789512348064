import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { askAttachment } from '../../containers/DataContainer'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputDate from '../Form/input/InputDate'
import SlidingForm from '../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	textArea: {
		marginTop: '15px',
	},
	date: {
		marginTop: 15,
	},
}))

function AttachmentNewDateForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = (values) => {
		let formData = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			expiration: values['date'],
			renew: true,
		}
		askAttachment(formData, data.companyID, function (res, error) {
			if (error) {
				console.error(error)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('TheEndDateOfAttachmentHasBeenChanged'), { variant: 'success' })
				data.updateStatus(res.data)
				close()
			}
		})
	}
	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('Attachment')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{ date: new Date() }}>
				<InputDate name="date" classes={{ root: classes.date }} />
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
AttachmentNewDateForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default AttachmentNewDateForm
