import React, { useEffect, useState } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../components/NewDataTable/NewDataTable'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { ALTERNATES_LIST, ALTERNATES_DELETE } from '../../constants/url'
import {
	Fab,
	IconButton,
	Icon,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { useAddAlternates } from '../../containers/FormContainer'
import { useSnackbar } from 'notistack'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles(theme => ({
	addButton: {
		position: 'fixed',
		right: theme.spacing.unit,
		bottom: theme.spacing.unit,
		zIndex: 1,
	},
}))

function AlternatesManagement() {
	const { t } = useTranslation(['user', 'alternatesManagement', 'accountManagment', 'flowmodel'])
	const [cookies] = useCookies()
	const classes = useStyles()
	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const [openWarningDel, setOpenWarningDel] = useState(false)
	const [lineToDel, setLineToDel] = useState({ user: null, alternate: null })
	const { enqueueSnackbar } = useSnackbar()
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	const refreshAll = () => {
		setRefresh(!refresh)
	}

	//#region USEEFFECT
	useEffect(() => {
		getAllAlternates()
	}, [refresh])
	//#endregion USEEFFECT

	//#region RECOVERY
	const recoveryAllAlternates = res => {
		const { data, ok } = res

		if (!ok) return enqueueSnackbar(t('flowmodel:NameMiss'), { variant: 'error' })

		if (data) {
			setDatatable(data)
			setIsLoading(false)
		}
	}
	//#endregion RECOVERY

	//#region GETS
	const getAllAlternates = () => {
		api
			.post(bindParams(ALTERNATES_LIST, cookies[PLATFORM_ID_COOKIE]), {
				nbItemsAsked: 50,
				pageNumber: 1,
			})
			.then(recoveryAllAlternates)
	}
	//#endregion GETS

	//#region ACTIONS
	const removeAlternate = () => {
		api
			.delete(
				bindParams(
					ALTERNATES_DELETE,
					cookies[PLATFORM_ID_COOKIE],
					lineToDel.user,
					lineToDel.alternate,
				),
			)
			.then(res => {
				const { ok } = res
				if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
				handleClose()
				refreshAll()
			})
	}
	//#endregion ACTIONS

	//#region DATATABLES
	const actionsColumn = {
		name: t('alternatesManagement:actions'),
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const customColumns = [
		{
			name: t('alternatesManagement:actions'),
			component: (value, tableMeta, updateValue) => {
				return (
					<>
						<IconButton onClick={e => handleOpen(tableMeta.rowData)}>
							<Icon>delete</Icon>
						</IconButton>
					</>
				)
			},
		},
	]
	//#endregion DATATABLES

	//#region HTML
	const MessageDeleteAlternate = () => {
		return (
			<Dialog
				open={openWarningDel}
				onClose={handleClose}
				aria-labelledby='alert-dialog-title'
				aria-describedby='alert-dialog-description'
			>
				<DialogTitle id='alert-dialog-title'> {t('warning')} !</DialogTitle>
				<DialogContent>
					<DialogContentText id='alert-dialog-description'>
						{t('alternatesManagement:warningAlternate')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color='secondary'>
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							removeAlternate()
						}}
						color='secondary'
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}
	//#endregion HTML

	//#region MISCELLANEOUS FUNCTIONS
	const { formular, open } = useAddAlternates({ refreshAll })

	const handleClose = () => {
		setOpenWarningDel(false)
	}

	const handleOpen = row => {
		setLineToDel({ user: row[0], alternate: row[2] })
		setOpenWarningDel(true)
	}
	//#endregion MISCELLANEOUS FUNCTIONS

	return (
		<>
			{formular}
			<HeadTitle title={t('alternatesManagement:alternatesManagementTitle')} />
			<DataTable
				title={t('alternatesManagement:alternatesManagementTitle')}
				columns={[...datatable.columns, actionsColumn]}
				data={datatable.data}
				options={datatable.options}
				customColumns={customColumns}
				isLoading={isLoading}
				customLabels={[
					{
						name: 'userName',
						label: t('accountManagment:principal'),
					},
					{
						name: 'substituteName',
						label: t('alternatesManagement:substituteName'),
					},
				]}
			/>
			<Fab color='secondary' aria-label='add' className={classes.addButton} onClick={open}>
				<AddIcon />
			</Fab>
			<MessageDeleteAlternate />
		</>
	)
}
export default AlternatesManagement
