import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography, List, ListItem, ListItemText } from '@material-ui/core'
import InputFiles from 'react-input-files'

const useStyles = makeStyles((theme) => ({
	button: {
		display: 'inline-block',
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	typographyRoot: {
		display: 'inline-block',
		marginLeft: 10,
	},
	divRoot: {
		display: 'inline-block',
		width: '50%',
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
	nameFiles: {
		color: '#FFFFFF',

	}
}))

const allowedFileExtensions = ['.bmp', '.jpg', '.jpeg', '.png', '.gif', '.tiff', '.mp3', '.wav', '.avi', '.mkv', '.ogg', '.mov', '.txt', '.doc', '.docx', '.odt', '.xls', '.xlsx', '.ods', '.ppt', '.pptx', '.odp', '.pdf']

function InputFile({ label, name, nameFileUpdated, className, setFiles, ...props }) {
	const classes = useStyles()
	const [namesFiles, setNamesFiles] = useState([])

	useEffect(() => {
		if (nameFileUpdated) {
			setNamesFiles([...namesFiles, nameFileUpdated])
		}
	}, [nameFileUpdated])

	return (
		<InputFiles
			accept= {allowedFileExtensions.toString()}
			multiple={true}
			onChange={(files) => {
				setFiles(files)
				files.forEach(file => {
					if (file && !namesFiles.includes(file.name)) {
						// Create a new array based on current state:
						let tempNamesFiles = namesFiles
						// Add item to it
						tempNamesFiles.push(file.name)
						// Set state
						setNamesFiles( tempNamesFiles )
					}
				})
			}}
		>
			<label htmlFor="outlined-button-file" className={className}>
				<div>
					<Button
						variant="outlined"
						component="span"
						color="secondary"
						className={classes.button}
						{...props}
					>
						{label}
					</Button>

					<List aria-label="namesFiles">
						{namesFiles.map((nameFile) => (
							<ListItem key={`namefile-${nameFile}`}>
								<ListItemText primary={
									<Typography className={classes.nameFiles}>
										{nameFile}
									</Typography>} 
								/>
							</ListItem>
						))}
					</List>
				</div>
			</label>
		</InputFiles>
	)
}
export default InputFile
