import React from 'react'
import {
	InputLabel,
	Select,
	FormControl,
	FilledInput,
	Icon,
	FormHelperText,
} from '@material-ui/core'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { Translation } from 'react-i18next'
import PropTypes from 'prop-types'

// STYLES
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		display: 'block',
	},
	labelRoot: {
		color: theme.palette.primary.main,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		textColor: 'white',
		width: '100%',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	arrowColor: {
		color: '#ffffff',
		marginRight: '10px',
	},
	menuRoot: {
		backgroundColor: '#3268A7',
		color: 'white !important',
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	textColor: {
		color: theme.palette.primary.main,
		backgroundColor: '#3268A7 !important',
		'&:hover': {
			color: '#3268A7',
			backgroundColor: theme.palette.primary.main + '!important',
		},
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
}))

export default function InputSelectField({
	name,
	validate,
	label,
	children,
	onChange = () => {
		return null
	},
	...props
}) {
	const classes = useStyles()

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => (
				<FormControl variant="filled" className={classes.formControl} {...props}>
					<InputLabel
						htmlFor="filled-select"
						classes={{
							root: classes.labelRoot,
							focused: classes.labelFocused,
						}}
					>
						{label}
					</InputLabel>
					<Select
						{...input}
						onChange={(e) => {
							onChange(e)
							input.onChange(e)
						}}
						classes={{
							root: classes.inputRoot,
						}}
						MenuProps={{
							classes: {
								paper: classes.menuRoot,
							},
						}}
						IconComponent={(properties) => (
							<Icon
								{...properties}
								classes={{
									root: classes.arrowColor,
								}}
							>
								arrow_drop_down_circle
							</Icon>
						)}
						inputProps={{
							className: classes.inputSize,
						}}
						input={
							<FilledInput
								id="filled-select"
								classes={{
									root: classes.inputRoot,
									focused: classes.inputFocused,
								}}
							/>
						}
					>
						{children}
					</Select>
					<FormHelperText classes={{ root: classes.helperText }}>
						{meta && meta.touched && meta.error ? (
							<Translation ns="validateData">{(t) => <>{t(meta.error)}</>}</Translation>
						) : undefined}
					</FormHelperText>
				</FormControl>
			)}
		/>
	)
}
InputSelectField.propTypes = {
	name: PropTypes.string,
	validate: PropTypes.func,
	label: PropTypes.string,
	children: PropTypes.array,
	onChange: PropTypes.func,
}
