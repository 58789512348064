import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { IconButton, Icon } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	iconButton: {
		display: 'inline_block',
		marginLeft: 20,
		color: 'white'
	}
}))

function FormIcon({ icon, onClick }) {
	const classes = useStyles()

	return (
		<IconButton className={classes.iconButton} onClick={onClick} size="medium">
			<Icon>{icon}</Icon>
		</IconButton>
	)
}
export default FormIcon
