import { MAX_FILE_SIZE } from '../constants/coreConstants'

export function isValidFile(file) {
	/*
	 * A-Z a-z 0-9 éèêëàâïîôüûÿ  -_ ^ .ext
	 * ->  example: éèêë123456.txt
	 */
	const REGEX_FILENAME = /^[A-z0-9-\u00C0-\u024F\u1E00-\u1EFF ().]*((-|\s))*(?:\.\w+)+$/

	const error = {
		errorCode: null,
	}

	if (file.size > MAX_FILE_SIZE) {
		error.errorCode = 'file_is_too_big'
	}
	if (!file.name.match(REGEX_FILENAME)) {
		error.errorCode = 'file_name_invalid'
	}

	return error
}

export function getFileToByteArray(file, callback) {
	const error = isValidFile(file)
	if (error.errorCode) return callback(null, error)
	callback(file, null)
}

function recursive(files, result, it, callback) {
	if (it >= files.length) return callback(result)
	getFileToByteArray(files[it], (res) => {
		recursive(files, [...result, res], it + 1, callback)
	})
}

export function getFilesToByteArray(files, callback) {
	recursive(files, [], 0, function (res) {
		callback(res)
	})
}

export function getFileNameToHeaders(headers) {
	if (headers['content-disposition']) {
		let fileName = headers['content-disposition'].match(
			/[^;]*\s?;?\s?filename="?([^";]+\.[^\s;"]+)"?\s?;?\s?.*/,
		)[1]
		if (fileName.startsWith('"')) fileName = fileName.replace(/"/g, '')
		return fileName
	}
}

export function managedFileNotFoundError(errors, t, enqueueSnackbar) {
	errors.data.forEach((error) => {
		if (error.code.startsWith('file_not_found')) {
			let fileError = error.code.split(':')
			enqueueSnackbar(t('file_not_found', { filename: fileError[1] }), {
				variant: 'error',
			})
		}
	})
}
