/**
 * Converts an ArrayBuffer as a string.
 * @param {ArrayBuffer} buffer ArrayBuffer to convert as string
 * @returns {String} Buffer as string
 */
export function ArrayBufferToString(buffer) {
	if ('TextDecoder' in window) {
		const dataView = new DataView(buffer)
		const decoder = new TextDecoder('utf-8')
		return decoder.decode(dataView)
	}
	return String.fromCharCode.apply(null, new Uint8Array(buffer))
}
