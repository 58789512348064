export const colorSequence = ['#4B96D1', '#F5960A', '#72B84D', '#9D2235', '#006946', '#746F66']

export const COLOR_DISABLED_ARROW = '#E1E5F1'

function getColor(index) {
	let colorIndex = index % colorSequence.length
	return colorSequence[colorIndex]
}

export default getColor
