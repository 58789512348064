import React, { useState, useEffect, useCallback } from 'react'
import { ACCOUNT_ID_COOKIE } from '../../constants/cookies'
import { makeStyles } from '@material-ui/styles'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { useTranslation } from 'react-i18next'
import InputText from '../Form/inputFullPage/InputText'
import Form from '../Form/Form'
import InputMail from '../Form/inputFullPage/InputMail'
import InputRadio, { TotalRadio } from '../Form/inputFullPage/InputRadio'
import { Typography, Grid, Paper } from '@material-ui/core'
import FormButtonFullPage from '../Form/FormButtonFullPage'
import { useCookies } from 'react-cookie'
import { updateAccountInfos } from '../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import {
	required,
	nameSize,
	emailSize,
	loginSize,
	noSpace,
	noSpecialChar,
} from '../../utils/VerifyFields'
import { composeValidators } from '../../utils/Form'
import Access from '../../containers/RoleContainer'
import ROLES from '../../constants/roles'
import api from '../../utils/Requester'
import { ACCOUNTINFOS } from '../../constants/url'
import { v4 as uuid_v4 } from 'uuid'
import PlatformPicker from '../PlatformPicker/PlatformPicker'

const useStyles = makeStyles((theme) => ({
	rootInputLeft: {
		marginTop: '12.5px',
		marginBottom: '12.5px',
		width: '90%',
		display: 'inline-block',
	},
	rootInputRight: {
		marginTop: '12.5px',
		marginBottom: '12.5px',
		width: '90%',
		display: 'inline-block',
	},
	leftPane: {
		textAlign: 'center',
	},
	rightPane: {
		textAlign: 'center',
	},
	part: {
		marginTop: theme.spacing.unit,
		padding: theme.spacing.unit,
		border: 'solid 1px ' + theme.palette.secondary.light,
		borderRadius: 15,
		height: '100%',
	},
	form: {
		width: '95%',
		marginLeft: '2.5%',
		padding: 20,
		backgroundColor: theme.palette.primary.main,
		borderRadius: 10,
	},
	typography: {
		display: 'block',
		color: theme.palette.secondary.main,
		fontSize: 18,
	},
	language: {
		marginTop: theme.spacing.unit,
		display: 'inline-flex',
	},
	radio: {
		marginTop: 0,
	},
	validateRoot: {
		display: 'flex',
		marginTop: theme.spacing.unit * 6,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	title: {
		padding: theme.spacing.unit,
	},
	box: {
		margin: theme.spacing.unit,
		borderRadius: 5,
		border: 'solid 1px ' + theme.palette.secondary.light,
		padding: theme.spacing.unit,
	},
	groupCheckbox: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
	},
	centerContentDiv: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}))

/**
 * Shows the account form
 * @returns {JSX.Element} React stack to render
 */
function MyAccountForm({ match }) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [sites, setSites] = useState([{}])
	const [initialValues, setInitialValues] = useState({})
	const { enqueueSnackbar } = useSnackbar()
	const [isExternal, setIsExternal] = useState(false)
	const targetAccountId = match.params.accountId || cookies[ACCOUNT_ID_COOKIE]

	useEffect(() => {
		//Get infos current user
		getCurrentUserInformations()
	}, [])

	const getCurrentUserInformations = () => {
		api.get(ACCOUNTINFOS + cookies[ACCOUNT_ID_COOKIE]).then((res) => {
			const { data, ok } = res

			if (!ok) return

			const platformsCanAddRole = data.accountTypePerPlatform.filter(
				(x) =>
					x.accountTypes.findIndex(
						(y) => y.code === ROLES.ADMIN.code || y.code === ROLES.PLATFORMMANAGER.code,
					) !== -1,
			)

			if (platformsCanAddRole) {
				//Get infos selected user
				api.get(ACCOUNTINFOS + targetAccountId).then((res2) => {
					if (!res2.ok) return
					if (res2.data) {
						//All Platforms of both users (without doublon)
						const _filtered = platformsCanAddRole.filter((d) => {
							return !res2.data.accountTypePerPlatform.find((as) => d.platformId === as.platformId)
						})
						let _allSites = [...res2.data.accountTypePerPlatform, ..._filtered]

						//Test if in one site, we have a company
						let isEnt = false
						let iterator = 0
						while (isEnt === false && iterator < _allSites.length) {
							const _account = _allSites[iterator].accountTypes
							for (const element of _account) {
								if (element.id === ROLES.EXTCOMPADMIN.id || element.id === ROLES.EXTCOMP.id)
									isEnt = true
							}
							iterator++
						}

						//If we have a company, we display only infos of selected user
						if (isEnt) {
							_allSites = res2.data.accountTypePerPlatform
							_allSites.forEach((as) => (as.disableFabButton = true))
						} else {
							_allSites.forEach((as) => {
								let find = platformsCanAddRole.find((r) => r.platformId === as.platformId)
								if (!find) as.disableFabButton = true
								else if (as.id === find.id) {
									as.disableFabButton = false
								} else {
									as.disableFabButton = true
								}
							})
						}

						setSites(_allSites)

						let language
						switch (res2.data.langId) {
							case 1:
								language = 'fr'
								break
							case 2:
								language = 'en'
								break
							default:
								break
						}
						setInitialValues({
							lastname: res2.data.lastName,
							firstname: res2.data.firstName,
							email: res2.data.email,
							language: language,
							accountTypePerPlatform: res2.data.accountTypePerPlatform,
							login: res2.data.login,
						})
						res2.data.accountTypePerPlatform.forEach((account) => {
							account.accountTypes.forEach((p_Account) => {
								if (p_Account.id === ROLES.EXTCOMPADMIN.id || p_Account.id === ROLES.EXTCOMP.id) {
									setIsExternal(true)
								}
							})
						})
					}
				})
			}
		})
	}
	useEffect(() => generateKeys(), [sites])

	const generateKeys = useCallback(() => {
		const tmpSites = sites
		tmpSites.forEach((site) => {
			site.key = uuid_v4()
		})
		setSites(tmpSites)
	}, [sites])

	const onSubmit = (values) => {
		let language
		switch (values.language) {
			case 'fr':
				language = 1
				break
			case 'en':
				language = 2
				break
			default:
				break
		}
		let data = {
			userId: targetAccountId,
			lastName: values.lastname,
			firstName: values.firstname,
			email: values.email == initialValues.email ? null : values.email,
			langId: language,
			login: values.login == initialValues.login ? null : values.login,
		}

		let accountTypePerPlatform = sites
			.map((site, i) => {
				if (!values['roles-' + site.platformId]) return
				let roles = JSON.parse(values['roles-' + site.platformId])
				return {
					accountTypes: roles,
					platformId: site.platformId,
				}
			})
			.filter((site) => site !== undefined)
		if (accountTypePerPlatform.length > 0)
			data = { ...data, accountTypePerPlatform: accountTypePerPlatform }
		else data = { ...data, accountTypePerPlatform: initialValues.accountTypePerPlatform }
		updateAccountInfos(data, function (res, error) {
			if (error) {
				if (error.data && error.data[0] && error.data[0].code)
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
				else enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
			}
			if (res) {
				enqueueSnackbar(t('YourDataHasBeenEdited'), { variant: 'success' })
				getCurrentUserInformations()
			}
		})
	}

	return (
		<Form className={classes.form} onSubmit={onSubmit} initialValues={initialValues} formCallback>
			{(form) => (
				<>
					<Grid container justify="center" spacing={8} className={classes.parent}>
						<Grid item md={6} sm={12} className={classes.leftPane}>
							<Paper className={classes.part}>
								<AccountBoxIcon color="secondary" />
								<Typography variant="h6" color="secondary">
									{t('Profil')}
								</Typography>
								<InputText
									name="lastname"
									label={t('lastname')}
									classes={{ root: classes.rootInputLeft }}
									validate={composeValidators(required, nameSize)}
								/>
								<InputText
									name="firstname"
									label={t('firstname')}
									classes={{ root: classes.rootInputLeft }}
									validate={composeValidators(required, nameSize)}
								/>
								<InputMail
									name="email"
									label={t('email')}
									classes={{ root: classes.rootInputLeft }}
									validate={composeValidators(required, emailSize)}
								/>

								{isExternal && (
									<InputText
										name="login"
										label={t('login')}
										classes={{ root: classes.rootInputLeft }}
										validate={composeValidators(required, loginSize, noSpace, noSpecialChar)}
									/>
								)}
							</Paper>
						</Grid>

						<Grid item md={6} sm={12} className={classes.rightPane}>
							<Paper className={`${classes.part} ${classes.centerContentDiv}`}>
								<div className={classes.language}>
									<InputRadio classes={{ root: classes.radio }} name="language">
										<TotalRadio
											name="language"
											label="Français"
											value="fr"
											image="/images/fr.png"
											labelPlacement="bottom"
										/>
										<TotalRadio
											name="language"
											label="English"
											value="en"
											image="/images/en.png"
											labelPlacement="bottom"
										/>
									</InputRadio>
								</div>
							</Paper>
						</Grid>
					</Grid>

					{match.params.accountId != undefined && (
						<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
							<br />
							<br />
							<Typography align="center" variant="h5" color="secondary">
								{t('platforms')}
							</Typography>
							<br />
							<div>
								<div className={classes.groupCheckbox}>
									{sites.map((site, i) => {
										let accountTypes
										if (initialValues.accountTypePerPlatform)
											initialValues.accountTypePerPlatform.map((accTpPrPlt) => {
												if (accTpPrPlt.platformId === site.platformId)
													accountTypes = accTpPrPlt.accountTypes
											})

										return (
											<PlatformPicker
												key={site.key}
												platform={site}
												form={form}
												initialValue={accountTypes}
											/>
										)
									})}
								</div>
							</div>
						</Access>
					)}
					<FormButtonFullPage
						classes={{ root: classes.validateRoot }}
						label={t('edit')}
						type="submit"
					/>
				</>
			)}
		</Form>
	)
}
export default MyAccountForm
