import { create } from 'apisauce'
import { URL } from '../constants/url'
import { DEFAULT } from '../constants/headers'
import { store } from '../store/store'
import { FetchToken, setAuthority } from '../authConfig'
import { disconnect, dispatchToken } from '../containers/AuthContainer'
import { jwtDecode } from 'jwt-decode'

// define the api
const api = create({
	baseURL: URL,
	headers: DEFAULT,
})

export const HttpCode = {
	Unauthorized: 401,
}

api.axiosInstance.interceptors.request.use(
	async function (config) {
		if (store.getState().token.token != '') {
			const decoded = jwtDecode(store.getState().token.token)
			setAuthority(decoded.tfp !== 'B2C_1_SignIn_Externals')
			const token = await FetchToken()
			const tmpHeaders = config.headers
			dispatchToken(token)
			config.headers = {
				tmpHeaders,
				Authorization: `Bearer ${token}`,
			}
		}
		return config
	},
	function (error) {
		return Promise.reject(error)
	},
)

api.axiosInstance.interceptors.response.use(
	function (response) {
		return response
	},
	async function (error) {
		if (HttpCode.Unauthorized === error.response.status) {
			await disconnect()
		} else {
			return Promise.reject(error)
		}
	},
)

export default api
