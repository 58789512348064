import { createSlice } from '@reduxjs/toolkit'

export const msalInstanceSlice = createSlice({
	name: 'msalInstance',
	initialState: {
		msalInstance: {},
	},
	reducers: {
		setMsalInstance: (state, action) => {
			state.msalInstance = action.payload
		},
		updateAuthority: (state, action) => {
			state.msalInstance.config.auth.authority = action.payload
		},
	},
})

export const { setMsalInstance, updateAuthority } = msalInstanceSlice.actions
export default msalInstanceSlice.reducer
