import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { GetPlatform } from '../../containers/RegisterContainer'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputSelect from '../Form/input/InputSelect'
import InputTextArea from '../Form/input/InputTextArea'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import SlidingForm from '../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
}))

function AttachmentForm({ open, close }) {
	const { t } = useTranslation(['attachment', 'user'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [sites, setSites] = useState([{}])

	useEffect(() => {
		GetPlatform(cookies, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setSites(res.data)
			}
		})
	}, [])

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('resquestTitle')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={(values) => console.log(values)}>
				<Typography
					component="p"
					classes={{
						root: classes.typographyFirst,
					}}
				>
					{t('ApprovalExpire')}
				</Typography>
				<br />
				<Typography
					component="p"
					classes={{
						root: classes.typographyRoot,
					}}
				>
					{t('inscriptionRequest')}
				</Typography>
				<InputSelect
					name="place"
					label={t('user:platform')}
					defaultValue="entreprise1"
					classes={{
						root: classes.inputSelectRoot,
					}}
				>
					{sites.map((site, i) => {
						return (
							<SelectMenuItem key={i} value={site.id}>
								{site.name}
							</SelectMenuItem>
						)
					})}
				</InputSelect>
				<InputTextArea
					name="justification"
					label={t('user:justification')}
					size="95px"
					classes={{ root: classes.textArea }}
				/>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
AttachmentForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}

export default AttachmentForm
