import React, { useState } from 'react'
import { Field } from 'react-final-form'
import 'moment/locale/fr'
import { InputAdornment, Icon } from '@material-ui/core'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, InlineDateTimePicker } from 'material-ui-pickers'
import { makeStyles } from '@material-ui/styles'

moment.locale('fr')

// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#ffffff',
		color: theme.palette.secondary.main,
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
}))

function InputDateTime({ name, label, readOnly, classname, disableLine, ...props }) {
	// STYLES
	const classes = useStyles()

	const [selectedDate, handleDateChange] = useState(new Date())

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="fr" moment={moment}>
			<Field
				name={name}
				render={({ input, meta }) => (
					<InlineDateTimePicker
						label={label}
						disabled={readOnly}
						ampm={false}
						showTabs={false}
						autoSubmit={false}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon>calendar_today</Icon>
								</InputAdornment>
							),
							...props,
							className: classes.inputRoot,
							disableUnderline: disableLine,
						}}
						InputLabelProps={{
							classes: {
								root: classes.labelRoot,
								focused: classes.labelFocused,
							},
						}}
						value={selectedDate}
						onChange={handleDateChange}
						onInputChange={(e) => {
							handleDateChange(e.target.value)
						}}
						variant="filled"
						format="DD/MM/YYYY HH:mm"
						mask={[
							/\d/,
							/\d/,
							'/',
							/\d/,
							/\d/,
							'/',
							/\d/,
							/\d/,
							/\d/,
							/\d/,
							' ',
							/\d/,
							/\d/,
							':',
							/\d/,
							/\d/,
						]}
						{...input}
					/>
				)}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default InputDateTime
