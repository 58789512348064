import React, { useState, useCallback } from 'react'
import {
	Dialog,
	DialogContent,
	Switch,
	Button,
	Grid,
	Typography,
	CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import GetApp from '@material-ui/icons/GetApp'
import { v4 as uuid_v4 } from 'uuid'

const useStyles = makeStyles(() => ({
	mR: {
		marginRigth: '10px',
	},
	mL: {
		marginLeft: '10px',
	},
	center: {
		textAlign: 'center',
	},
	buttonProgress: {
		color: 'black',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))
function ModalContacts({ isOpen, onClose, onSubmit, loading }) {
	const classes = useStyles()
	const { t } = useTranslation(['reports'])
	const [switchCheck, setSwitchCheck] = useState(true)

	const executeOnClose = useCallback(() => {
		onClose(false)
		setSwitchCheck(true)
	})
	const onChangeSwitch = useCallback(() => {
		setSwitchCheck(!switchCheck)
	})
	const executeOnSubmit = useCallback(() => {
		onSubmit(switchCheck)
	})

	return (
		<Dialog open={isOpen} onBackdropClick={executeOnClose} maxWidth="md">
			<DialogContent>
				<Grid container justify="center">
					<Grid item xs={12} className={classes.center}>
						<Typography variant="body1">
							{t('reports:activeContacts')}
							<Switch checked={switchCheck} onChange={onChangeSwitch} />
						</Typography>
					</Grid>
					<Grid item xs={12} className={classes.center}>
						<Button
							key={uuid_v4()}
							className={`${classes.mR} ${classes.mL}`}
							variant="contained"
							color="secondary"
							onClick={executeOnSubmit}
							disabled={loading}
						>
							{loading && <CircularProgress size={24} className={classes.buttonProgress} />}

							<GetApp />
							{t('reports:ListContact')}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalContacts
