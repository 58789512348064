export function bindParams(url, ...params) {
	let matchs = url.match(/[{@]+[1-9]+[}]/g)
	let tmpUrl = url

	for (let i = 0; i < matchs.length; i++) {
		tmpUrl = tmpUrl.replace(matchs[i], params[i])
	}

	return tmpUrl
}
