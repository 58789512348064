import React, { createContext, useReducer } from 'react'
import MenuReducer from '../reducers/MenuReducer'

let MenuContext = createContext()

let initialState = false

function MenuContextProvider(props) {
	let [open, dispatch] = useReducer(MenuReducer, initialState)
	let value = { open, dispatch }

	return (
		<MenuContext.Provider value={value}>{props.children}</MenuContext.Provider>
	)
}

let MenuContextConsumer = MenuContext.Consumer

export { MenuContext, MenuContextProvider, MenuContextConsumer }
