import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Typography } from '@material-ui/core'
import InputFiles from 'react-input-files'
import PropTypes from 'prop-types'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

const useStyles = makeStyles((theme) => ({
	button: {
		display: CSS_VALUES.INLINE_BLOCK,
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	typographyRoot: {
		display: CSS_VALUES.INLINE_BLOCK,
		marginLeft: 10,
	},
	divRoot: {
		display: CSS_VALUES.INLINE_BLOCK,
		width: '50%',
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
}))

function InputFile({ label, nameFileUpdated, className, setFile, ...props }) {
	const classes = useStyles()
	const [nameFile, setNameFile] = useState()

	useEffect(() => {
		if (nameFileUpdated) {
			setNameFile(nameFileUpdated)
		}
	}, [nameFileUpdated])

	return (
		<InputFiles
			accept="*"
			onChange={(files) => {
				if (files[0]) {
					setNameFile(files[0].name)
					setFile(files[0])
				}
			}}
		>
			<label htmlFor="outlined-button-file" className={className}>
				<div>
					<Button
						variant="outlined"
						component="span"
						color="secondary"
						className={classes.button}
						{...props}
					>
						{label}
					</Button>
					<Typography variant="body2" color="primary" classes={{ root: classes.typographyRoot }}>
						{nameFile}
					</Typography>
				</div>
			</label>
		</InputFiles>
	)
}
InputFile.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	nameFileUpdated: PropTypes.string,
	setFile: PropTypes.func,
	className: PropTypes.string,
}

export default InputFile
