/**
 * Wrap sentence whitout cut word
 * @param {string} sentence
 * @param {number} maxCharacters
 * @returns
 */
export function wrapSentence(sentence, maxCharacters) {
	if (sentence.length <= maxCharacters) {
		return sentence
	}

	const indexSpace = sentence.lastIndexOf(' ', maxCharacters)

	if (indexSpace === -1) {
		return sentence.slice(0, maxCharacters)
	}

	return sentence.slice(0, indexSpace) + '...'
}
