/*
This is the "chevron" component. To use it, you just need to encompass your arrows (TotalArrowStep component) with it. 
You can also specify an endwith (a className).
*/
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Icon } from '@material-ui/core'
import classNames from 'classnames'
import ID from '../../utils/ID'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main,
		fontSize: '2em',
	},
	title: {
		color: theme.palette.primary.main,
		top: '96px',
	},
	subtitle: {
		position: 'absolute',
		top: '96px',
		paddingLeft: '70px',
	},
	arrowsRoot: {
		height: '80px',
		backgroundColor: theme.palette.secondary.main,
		boxShadow: '1px 1px 5px grey',
	},
	arrowBlock: {
		display: 'flex',
		height: '80px',
	},
	titleBlock: {
		display: 'inline-flex',
		height: '80px',
		transform: 'translateY(21px)',
	},
	mainLabelBlock: {
		display: 'flex',
		paddingTop: '2px',
		paddingLeft: '15px',
		width: '100%',
		top: 0,
		position: 'absolute',
	},
	mainArrow: {
		width: 150,
		height: '80px',
		flexGrow: '1',
		position: 'relative',
	},
	mainLabelTitle: {
		color: 'white',
		fontSize: '20px',
		display: 'block',
	},
	labelContent: {
		color: 'white',
		fontSize: '12px',
		lineHeight: '13px',
		display: 'block',
		maxWidth: '250px',
	},
	verticalDivider: {
		height: '26px',
		width: '1px',
		borderLeft: '2px solid #ffffff',
		marginLeft: '10px',
		top: '24px',
		position: 'relative',
		display: 'inline',
	},
	labelBlock: {
		padding: '10px',
		marginTop: '-7px',
	},
	endWidth: {
		width: '1%',
	},
	validateButton: {
		marginLeft: 10,
		marginTop: 20,
	},
	checkbox: {
		color: 'white',
	},
	buttonFont: {
		fontSize: 12,
		color: 'white',
	},
	topMargin: {
		marginTop: '2px',
	},
	button: {
		maxWidth: 200,
		marginTop: 3,
		marginLeft: 15,
		boxShadow: '0px 0px 0px 0px',
		['@media (max-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			marginTop: 20,
		},
	},
	buttonText: {
		['@media (max-width:1150px)']: {
			// eslint-disable-line no-useless-computed-key
			display: 'none',
		},
	},
	tooltipRoot: {
		zIndex: '20000',
		['@media (min-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			display: 'none',
		},
	},
	rightIcon: {
		['@media (max-width:1151px)']: {
			// eslint-disable-line no-useless-computed-key
			fontSize: '3em',
		},
	},
}))

function TotalArrows({ endWidth, icon, title, content, pdpnumber, children }) {
	const classes = useStyles()
	const itemId = ID()

	return (
		<>
			<div className={classes.arrowsRoot}>
				<div className={classes.arrowBlock}>
					<div className={classes.mainArrow}>
						<svg width="105%" height="100%" preserveAspectRatio="none" viewBox="0 0 100 100">
							<defs id="defs7">
								<linearGradient
									x1="0"
									y1="0"
									x2="100"
									y2="0"
									id={'SvgArrow' + itemId}
									gradientUnits="userSpaceOnUse"
								>
									<stop offset="0.13" stopColor="#134391" stopOpacity="0.50" id="stop2" />
									<stop offset="0.87" stopColor="#134391" stopOpacity="0.12" id="stop2" />
									<stop offset="1" stopColor="#ffffff" stopOpacity="0.50" id="stop4" />
								</linearGradient>
							</defs>
						</svg>
						<div className={classes.mainLabelBlock}>
							<div>
								<div className={classes.titleBlock}>
									<Icon className={classes.icon}>{icon}</Icon>
								</div>
							</div>
							<div className={classes.verticalDivider} />
							<div className={classes.labelBlock}>
								<span className={classes.mainLabelTitle}>{title}</span>
								<span className={classes.labelContent}>{content}</span>
								<span className={`${classes.labelContent} ${classes.topMargin}`}>{pdpnumber}</span>
							</div>
						</div>
					</div>
					{children}
					<div className={classNames(classes.endWidth, endWidth)} />
				</div>
			</div>
		</>
	)
}
TotalArrows.propTypes = {
	endWidth: PropTypes.string,
	icon: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.string,
	pdpnumber: PropTypes.string,
	children: PropTypes.array,
}

export default TotalArrows
