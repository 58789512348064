import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import InputTextArea from '../Form/input/InputTextArea'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { askAttachment } from '../../containers/DataContainer'
import FormIcon from '../Form/FormIcon'
import SlidingForm from '../Form/SlidingForm'
import FormButtonIcon from '../Form/FormButtonIcon'
import { required } from '../../utils/VerifyFields'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	textArea: {
		marginTop: '15px',
	},
}))

function JustifyAttachmentForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = (values) => {
		let formData = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			justification: values['justify'],
		}
		askAttachment(formData, data.companyID, function (res, error) {
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('attachmentRequest'), { variant: 'success' })
				data.updateStatus(res.data)
				close()
			}
		})
	}
	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('Attachment')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit}>
				<InputTextArea
					name="justify"
					label={t('user:justification')}
					validate={required}
					classes={{ root: classes.textArea }}
				/>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
JustifyAttachmentForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default JustifyAttachmentForm
