import React from 'react'
import { Dialog, Slide } from '@material-ui/core'
import PropTypes from 'prop-types'

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

function ContentSlider({ open, handleClose, children }) {
	return (
		<div>
			<Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
				{children}
			</Dialog>
		</div>
	)
}
ContentSlider.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	children: PropTypes.object,
}

export default ContentSlider
