import { Button, Modal, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { deleteCompany } from '../../../containers/DataContainer'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import InputTextArea from '../../Form/inputFullPage/InputTextArea'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '10%',
		maxWidth: '60%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	title: {
		color: theme.palette.secondary.main,
		marginBottom: 20,
	},
	secondTitle: {
		display: 'inline-block',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	inputs: {
		margin: 10,
		width: '90%',
	},
	groupCheckbox: {
		marginLeft: 20,
	},
}))

/**
 * Shows a form to delete a company in the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function DeleteCompany({ open, closeModal, data, refreshDatatable, setRefreshDatatable }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()

	const close = () => {
		closeModal(false)
	}

	const onSubmit = (values) => {
		deleteCompany(
			cookies[PLATFORM_ID_COOKIE],
			data.pdpId,
			data.companyId,
			{ reason: values['justify'] },
			(res, err) => {
				if (err) {
					enqueueSnackbar(t('error') + ` : ${err.status} !`, { variant: 'error' })
				}
				if (res) {
					enqueueSnackbar(t('TheCompanyHasBeenDeleted'), { variant: 'info' })
					if (setRefreshDatatable) {
						setRefreshDatatable(!refreshDatatable)
					}
					close()
				}
			},
		)
	}

	return (
		<Modal open={open} onClose={close}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit}>
					<Typography variant="h6" classes={{ root: classes.title }}>
						{t('DeletingCompany')}
					</Typography>
					<Typography variant="body1" classes={{ root: classes.title }}>
						{t('ReasonForDeletion')}
					</Typography>
					<InputTextArea
						name="justify"
						label="Motif"
						classes={{ root: classes.textArea }}
						validate={required}
					/>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button className={classNames(classes.button, classes.validateButton)} type="submit">
							{t('submit')}
						</Button>
					</div>
				</Form>
			</div>
		</Modal>
	)
}

export default DeleteCompany
