import { makeStyles } from '@material-ui/styles'
import i18next from 'i18next'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import FormIcon from '../../../components/Form/FormIcon'
import InputCheckbox, { InputSwitch } from '../../../components/Form/input/InputCheckbox'
import InputMail from '../../../components/Form/input/InputMail'
import InputPhone from '../../../components/Form/input/InputPhone'
import InputText from '../../../components/Form/input/InputText'
import SlidingForm from '../../../components/Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	addCompanyManagerRestrictedPdP,
	getContactTypeList,
} from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import { maxLong, required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function AddCompanyResponsable({ open, close, data }) {
	const technicalResponsableId = 9

	const { t } = useTranslation(['user', 'contacts', 'validateData', 'pdp'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listType, setListType] = useState([])
	const [numberTypes, setNumberTypes] = useState(0)
	const defaultValues = { notifie: false, createAccess: false }
	const [contactValues, setContactValues] = useState({})
	const [language, setLanguage] = useState(1)
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	})

	useEffect(() => {
		if (!open || !data) return

		setContactValues({
			lastname: data.name,
			firstname: data.firstName,
			mail: data.email,
			phone: data.phone,
			notifie: data.isNotifiable,
			createAccess: data.createAccess,
			['type' + technicalResponsableId]: true,
		})
	}, [open])

	const onSubmit = (values) => {
		let listType = [9]
		for (let i = 1; i <= numberTypes; i++) {
			if (values['type' + i]) listType = [...listType, i]
		}

		const formData = {
			contactName: values.lastname,
			contactFirstName: values.firstname,
			contactEmail: values.mail,
			contactPhone: values.phone,
			roles: listType,
			isContactNotifiable: values.notifie,
			createApplicationAccess: values.createAccess,
		}

		addCompanyManagerRestrictedPdP(
			cookies[PLATFORM_ID_COOKIE],
			data.selectedCompany,
			formData,
			function (res, error) {
				if (res) {
					data.setNewResponsableCreated(formData)
					enqueueSnackbar(t('TheContactHasBeenAdded'), { variant: 'info' })
					data.refreshManager()
					close()
				}
				if (error) {
					enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
					return
				}
			},
		)
	}

	useEffect(() => {
		getContactTypeList(language, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListType(res.data)
				setNumberTypes(res.data.length)
			}
		})
	}, [open])

	const validate = (values) => {
		let errors = {}
		if (!values.type9) errors = { type9: t('pdp:requiredField') }
		return errors
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('contacts:AddContact')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form
				onSubmit={onSubmit}
				initialValues={{ ...defaultValues, ...contactValues }}
				validate={validate}
			>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="lastname"
					label={t('lastname')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="firstname"
					label={t('firstname')}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="mail"
					label={t('email')}
				/>
				<InputPhone
					classes={{ root: classes.size1 }}
					validate={composeValidators(required, maxLong)}
					name="phone"
					label={t('phone')}
				/>
				<InputSwitch name="notifie" label={t('notifie')} required />
				{listType &&
					listType.map((type, i) => (
						<InputCheckbox
							name={'type' + type.id}
							label={type.name}
							value={type.id}
							key={type.id}
						/>
					))}
				<InputSwitch name="createAccess" label={t('contacts:createAccess')} />
				<div className={classes.validateGroup}>
					<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddCompanyResponsable
