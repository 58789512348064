import React, { useState } from 'react'
import { InputLabel, Select, FormControl, FilledInput, Icon } from '@material-ui/core'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'

// STYLES
const useStyles = makeStyles((theme) => ({
	formControl: {
		width: '100%',
		display: 'block',
	},
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		width: '100%',
		border: 'solid 0.5px',
		borderColor: theme.palette.secondary.main,
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	filledInputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		width: '100%',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	arrowColor: {
		color: theme.palette.secondary.main,
		marginRight: '10px',
	},
	menuRoot: {
		backgroundColor: 'white',
		color: 'white !important',
	},
	inputSize: {
		padding: '22px 12px 10px',
	},

	//======================

	themedMenuRoot: {
		color: theme.palette.secondary.main,
	},
	themedInputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		width: '100%',
		border: 'solid 0.5px',
		borderColor: theme.palette.secondary.main,
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
		'&:hover': {
			backgroundColor: 'rgba(0,0,0,0.12)',
		},
	},
	themedDisabledFilledInputRoot: {
		'& > $themedInputRoot': {
			background: 'rgba(0,0,0,0.12)',
		},
		'&$themedFilledInputRoot:hover': {
			backgroundColor: 'none',
		},
		'&[class*="MuiInputBase-formControl"]': {
			background: 'none',
		},
	},
	themedFilledInputRoot: {
		background: 'none',
		color: theme.palette.primary.main,
		width: '100%',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
		'&:hover': {
			background: 'none',
		},
	},
	themedLabelRoot: {
		color: 'white',
	},
	themedArrowColor: {
		color: 'white',
		marginRight: '10px',
		'$themedDisabledFilledInputRoot &': {
			color: 'rgba(0, 0, 0, 0.38)',
		},
	},
}))

export default function InputSelectField({ name, label, className, readOnly, children, ...props }) {
	const classes = useStyles()
	return (
		<Field
			name={name}
			render={({ input, meta }) => (
				<FormControl variant="filled" className={classes.formControl} {...props}>
					<InputLabel
						htmlFor="filled-select"
						classes={{
							root: classes.labelRoot,
							focused: classes.labelFocused,
						}}
					>
						{label}
					</InputLabel>
					<Select
						classes={{
							root: classes.inputRoot,
						}}
						className={className}
						MenuProps={{
							classes: {
								paper: classes.menuRoot,
							},
						}}
						IconComponent={(props) => (
							<Icon
								{...props}
								classes={{
									root: classes.arrowColor,
								}}
							>
								arrow_drop_down
							</Icon>
						)}
						inputProps={{
							readOnly: readOnly,
							className: classes.inputSize,
						}}
						input={
							<FilledInput
								classes={{
									root: classes.filledInputRoot,
									focused: classes.inputFocused,
								}}
								{...input}
							/>
						}
					>
						{children}
					</Select>
				</FormControl>
			)}
		/>
	)
}

export function ThemedInputSelectField({ name, label, className, readOnly, children, ...props }) {
	const classes = useStyles()
	return (
		<Field
			name={name}
			render={({ input, meta }) => (
				<FormControl variant="filled" className={classes.formControl} {...props}>
					<InputLabel
						htmlFor="filled-select"
						classes={{
							root: classes.themedLabelRoot,
							focused: classes.labelFocused,
						}}
					>
						{label}
					</InputLabel>
					<Select
						classes={{
							root: classes.themedInputRoot,
						}}
						className={className}
						MenuProps={{
							classes: {
								paper: classes.themedMenuRoot,
							},
						}}
						IconComponent={(props) => (
							<Icon
								{...props}
								classes={{
									root: classes.themedArrowColor,
								}}
							>
								arrow_drop_down
							</Icon>
						)}
						inputProps={{
							readOnly: readOnly,
							className: classes.inputSize,
						}}
						input={
							<FilledInput
								classes={{
									root: classes.themedFilledInputRoot,
									focused: classes.inputFocused,
									disabled: classes.themedDisabledFilledInputRoot,
								}}
								{...input}
							/>
						}
					>
						{children}
					</Select>
				</FormControl>
			)}
		/>
	)
}

export function InputSelectOnChange({
	name,
	label,
	className,
	readOnly,
	value = null,
	onChange = null,
	children,
	...props
}) {
	const classes = useStyles()
	const [localValue, setLocalValue] = useState(0)

	const myOnChange = (event) => {
		setLocalValue(event.target.value)
	}

	return (
		<Field
			name={name}
			render={({ input, meta }) => {
				const mergedOnChange = (e) => {
					myOnChange(e)
					onChange(e)
				}
				const newInput = { ...input, onChange: mergedOnChange }
				return (
					<FormControl variant="filled" className={classes.formControl} {...props}>
						<InputLabel
							htmlFor="filled-select"
							classes={{
								root: classes.labelRoot,
								focused: classes.labelFocused,
							}}
						>
							{label}
						</InputLabel>
						<Select
							classes={{
								root: classes.inputRoot,
							}}
							value={value || localValue}
							className={className}
							MenuProps={{
								classes: {
									paper: classes.menuRoot,
								},
							}}
							IconComponent={(props) => (
								<Icon
									{...props}
									classes={{
										root: classes.arrowColor,
									}}
								>
									arrow_drop_down
								</Icon>
							)}
							inputProps={{
								readOnly: readOnly,
								className: classes.inputSize,
							}}
							input={
								<FilledInput
									classes={{
										root: classes.filledInputRoot,
										focused: classes.inputFocused,
									}}
									{...newInput}
								/>
							}
						>
							{children}
						</Select>
					</FormControl>
				)
			}}
		/>
	)
}
