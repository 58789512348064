import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { setCompanyCertifications } from '../../../../containers/DataContainer'
import { getFileToByteArray } from '../../../../utils/File'
import { required } from '../../../../utils/VerifyFields'
import Form from '../../../Form/Form'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import FormIcon from '../../../Form/FormIcon'
import InputDate from '../../../Form/input/InputDate'
import InputFile from '../../../Form/input/InputFile'
import InputNumber from '../../../Form/input/InputNumber'
import InputText from '../../../Form/input/InputText'
import SlidingForm from '../../../Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../../../constants/cookies'
import { bindParams } from '../../../../utils/Link'
import humanFileSize from '../../../../constants/coreConstants'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	inputRoot: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	label: {
		display: 'inline-block',
		color: theme.palette.primary.main,
		marginRight: 10,
	},
	inputFile: {
		display: 'inline-block',
	},
	date: {
		width: '100%',
		marginTop: 10,
	},
	dateLabel: {
		color: theme.palette.primary.main,
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginRight: 0,
	},
	validateGroup: {
		display: 'flex',
	},
}))

function CertificationDEForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'common'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [fileError, setFileError] = useState(null)
	const [nameFile, setNameFile] = useState(null)

	useEffect(() => {
		/* Update the name of the file in the input file */
		if (file && file.name) setNameFile(file.name)
		if (file && file.fileName) setNameFile(file.fileName)
		if (!file) setNameFile(null)
	}, [file])

	const onSubmit = (values) => {
		if (!file) return setFileError(t('common:required_file'))

		//Test if the date obtention is after the today's date
		if (moment(values.cftDateObtention).diff(new Date(), 'day') > 0) {
			enqueueSnackbar(t('error') + ' : ' + t('date_superior_error'), { variant: 'error' })
			return
		}

		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				const formData = new FormData()
				formData.append('cftReference', values.cftReference)
				formData.append('cftIntitule', values.cftIntitule)
				formData.append('cftDateObtention', values.cftDateObtention.format('YYYY-MM-DD'))
				formData.append('cftDureeValidite', values.cftDureeValidite)
				formData.append('value', res, res.name)
				formData.append('cftLieu', values.cftLieu)
				const formDataObj = {}
				formData.forEach((value, key) => (formDataObj[key] = value))

				setCompanyCertifications(
					cookies[PLATFORM_ID_COOKIE],
					data.companyId,
					formData,
					function (res, error) {
						if (res) {
							const currentCftId = res.data.filter(
								(dt) => dt.cftReference === formData.get('cftReference'),
							)[0].cftId
							data.setCertificationList([
								...data.certificationList,
								{ cftId: currentCftId, ...formDataObj },
							])
							setFile(null)
							close()
							return
						}
						if (error) {
							error.data.forEach((err) => {
								enqueueSnackbar(t(`common:${err.code}`), { variant: 'error' })
							})
						}
					},
				)
			} else {
				enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('CreateCertification')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			onClose={close}
		>
			<Form onSubmit={onSubmit}>
				<InputText
					name="cftReference"
					label={t('reference')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<InputText
					name="cftIntitule"
					label={t('entitled')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<InputDate
					name="cftDateObtention"
					label={t('ObtainingDate')}
					classes={{ root: classes.date }}
					delayOptions={{ disableFuture: true }}
				/>
				<InputNumber
					name="cftDureeValidite"
					label={t('PeriodOfValidity')}
					classes={{ root: classes.inputRoot }}
					validate={required}
					inputProperties={{ min: 0, max: 60 }}
				/>
				<InputText
					name="cftLieu"
					label={t('PlaceOfPassage')}
					classes={{ root: classes.inputRoot }}
					validate={required}
				/>
				<label className={classes.label}>{t('AssociatedDocument')}</label>
				<InputFile
					setFile={setFile}
					label={t('upload')}
					name="cftDocumentCheminComplet"
					className={classes.inputFile}
				/>
				{fileError && <Typography color="error">{fileError}</Typography>}
				<div className={classes.validateGroup}>
					<FormButtonIcon type="submit" />
				</div>
			</Form>
		</SlidingForm>
	)
}
CertificationDEForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default CertificationDEForm
