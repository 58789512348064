import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CREATEACCOUNT, GETPLATFORMS, NATIONALITIES } from '../../constants/url'
import { composeValidators } from '../../utils/Form'
import api from '../../utils/Requester'
import { emailSize, maxLong, required, siretFormat } from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormButton from '../Form/FormButton'
import FormIcon from '../Form/FormIcon'
import InputDate from '../Form/input/InputDate'
import InputMail from '../Form/input/InputMail'
import InputMultipleSelect from '../Form/input/InputMultipleSelect'
import InputNumber from '../Form/input/InputNumber'
import InputPhone from '../Form/input/InputPhone'
import InputSelect from '../Form/input/InputSelect'
import InputText from '../Form/input/InputText'
import InputTextArea from '../Form/input/InputTextArea'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import SlidingForm from '../Form/SlidingForm'
import PropTypes from 'prop-types'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

const useStyles = makeStyles((theme) => ({
	input: {
		width: '45%',
		margin: theme.spacing.unit * 2,
		display: CSS_VALUES.INLINE_BLOCK,
		[theme.breakpoints.down('sm')]: {
			width: '90%',
		},
	},
	dateBox: {
		width: '45%',
		display: CSS_VALUES.INLINE_BLOCK,
	},
	fullWidthField: {
		width: '90%',
		marginLeft: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit,
	},
	block: {
		display: 'block',
	},
	date: {
		verticalAlign: 'middle',
	},
	text: {
		display: CSS_VALUES.INLINE_BLOCK,
		color: theme.palette.primary.main,
	},
	button: {
		marginLeft: theme.spacing.unit,
	},
	buttonProgress: {
		color: 'white',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

/**
 * @description EE account creation formular
 * @param {boolean} open : It's used to open the formular
 * @param {function} close : It's used to close the formular
 * @param {any} data : It's used to insert default data into the formular
 * @returns {JSX.Element} React stack to render
 */
function IEEInfoForm({ open, close }) {
	const classes = useStyles()
	const [platforms, setPlatforms] = useState(null)
	const [selectedPlatforms, setSelectedPlatform] = useState(null)
	const { t } = useTranslation('user')
	const [nationalities, setNationalities] = useState([{}])
	const [initialValues, setInitialValues] = useState({})
	const { enqueueSnackbar } = useSnackbar()
	const [loading, setLoading] = useState(false)
	const STRING_VALUES = {
		JUSTIFICATION: 'justification-',
		APPROVAL_END_DATE: 'approvalEndDate-',
	}

	const recoveryPlatforms = (res) => {
		const { data, problem, status } = res
		if (problem) {
			enqueueSnackbar(`${t('error')} ${status} : ${t('view_render_failed')}`, {
				variant: 'error',
			})
			return
		}
		if (data) {
			setPlatforms(data)
		}
	}

	const GetPlatform = () => {
		api.get(GETPLATFORMS).then(recoveryPlatforms)
	}

	const recoveryNationalities = (res) => {
		const { data, problem, status } = res
		if (problem) {
			enqueueSnackbar(`${t('error')} ${status} : ${t('view_render_failed')}`, {
				variant: 'error',
			})
			return
		}
		if (data) {
			setNationalities(data)
		}
	}

	const GetNationalities = () => {
		api.get(NATIONALITIES).then(recoveryNationalities)
	}

	useEffect(() => {
		GetNationalities()
		GetPlatform()
	}, [])

	const getPlatformById = (id) => platforms.filter((platform) => platform.platformId === id)[0]

	const onChange = (e) => {
		setSelectedPlatform(e.target.value)
	}

	const onSubmit = (values) => {
		let platformList = []
		platforms.forEach((platform) => {
			const platform_Id = platform.platformId
			if (values[STRING_VALUES.JUSTIFICATION + platform_Id]) {
				let isAfterNow = moment(values[STRING_VALUES.APPROVAL_END_DATE + platform_Id]).isAfter(
					moment.now(),
				)

				let date = values[STRING_VALUES.APPROVAL_END_DATE + platform_Id]
					? values[STRING_VALUES.APPROVAL_END_DATE + platform_Id]
					: values[STRING_VALUES.APPROVAL_END_DATE + platform_Id].toDate()

				let expirationDate = isAfterNow ? date : moment(moment.now()).add(1, 'minute').toDate()

				platformList = [
					...platformList,
					{
						platformId: platform_Id,
						justification: values[STRING_VALUES.JUSTIFICATION + platform_Id],
						expirationDate: expirationDate,
					},
				]
			}
		})
		let formData = {
			accountTypePerPlatform: platformList,
			companyName: values['enterpriseName'],
			socialReason: values['socialReason'],
			agencyName: values['agencyName'],
			agencyPhone: values['agencyPhone'],
			languageId: nationalities.find((res) => res.id === values['nationality']).langId,
			nationalityId: values['nationality'],
			siret: values['siretNumber'],
			email: values['mail'],
			firstName: 'default',
			lastName: 'default',
		}
		setLoading(true)
		createAccount(formData)
	}

	const back = () => close()

	function createAccount(formData) {
		api.post(CREATEACCOUNT, formData).then((res) => {
			const { data, ok } = res
			setLoading(false)
			if (!ok)
				if (!data) {
					enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
					return
				} else {
					enqueueSnackbar(t(data[0].code), { variant: 'error' })
					return
				}
			if (data) {
				enqueueSnackbar(t('CreationExternalCompany'), { variant: 'success' })
				close()
			}
		})
	}

	useEffect(() => {
		if (!selectedPlatforms) return
		let listApproval = {}
		for (let i = 0; i < platforms.length + 1; i++) {
			listApproval = { ...listApproval, [STRING_VALUES.APPROVAL_END_DATE + i]: new Date() }
		}
		setInitialValues(listApproval)
	}, [selectedPlatforms])

	return (
		<SlidingForm
			direction="fullscreen"
			open={open}
			title={t('InformationAboutExternCompany')}
			iconAttachement={<FormIcon icon="clear" onClick={close} />}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<InputMultipleSelect
					onChange={onChange}
					value={selectedPlatforms}
					name="selectedPlatforms"
					label={t('platform')}
					className={classNames(classes.input, classes.block)}
				>
					{platforms &&
						platforms.map(
							(platform) =>
								platform.platformId && (
									<SelectMenuItem key={platform.platformId} value={platform.platformId}>
										{platform.name}
									</SelectMenuItem>
								),
						)}
				</InputMultipleSelect>
				{selectedPlatforms &&
					selectedPlatforms.length > 0 &&
					platforms &&
					selectedPlatforms.map((platformId, i) => (
						<div key={i}>
							<InputTextArea
								name={STRING_VALUES.JUSTIFICATION + platformId}
								label={'Justification ' + getPlatformById(platformId).name}
								validate={composeValidators(required, maxLong)}
								className={classes.input}
							/>
							<div className={classes.dateBox}>
								<Typography variant="subtitle1" className={classes.text}>
									{t('DateEndOfApproval')}
								</Typography>
								<InputDate
									name={STRING_VALUES.APPROVAL_END_DATE + platformId}
									inputStyle={classNames(classes.input, classes.date)}
								/>
							</div>
						</div>
					))}
				<InputMail
					name="mail"
					label={t('email')}
					className={classes.input}
					validate={composeValidators(required, emailSize)}
				/>
				<InputText
					name="enterpriseName"
					label={t('companyName')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputText
					name="socialReason"
					label={t('socialReason')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>

				<InputText
					name="agencyName"
					label={t('agencyName')}
					className={classes.input}
					validate={composeValidators(required, maxLong)}
				/>
				<InputPhone
					name="agencyPhone"
					label={t('agencyPhone')}
					className={classes.input}
					validate={composeValidators(required)}
				/>
				<InputSelect
					name="nationality"
					label={t('companyNationality')}
					className={classes.input}
					validate={composeValidators(required)}
				>
					{nationalities.map((nationality, i) => {
						return (
							<SelectMenuItem key={i} value={nationality.id}>
								{nationality.name}
							</SelectMenuItem>
						)
					})}
				</InputSelect>

				<InputNumber
					name="siretNumber"
					label={t('siretNumber')}
					className={classes.input}
					validate={composeValidators(required, siretFormat)}
				/>
				<Grid item xs={12}>
					<FormButton
						onClick={back}
						type="button"
						label={t('return')}
						classes={{ root: classes.button }}
					/>

					<FormButton
						disabled={loading}
						type="submit"
						label={
							loading ? (
								<>
									<CircularProgress size={24} className={classes.buttonProgress} />
									{t('submit')}
								</>
							) : (
								t('submit')
							)
						}
						classes={{ root: classes.button }}
					/>
				</Grid>
			</Form>
		</SlidingForm>
	)
}
IEEInfoForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}

export default IEEInfoForm
