import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	createSubcontractorCompanies,
	getSubcontractorCompanies,
} from '../../../containers/DataContainer'
import { composeValidators } from '../../../utils/Form'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputAutocomplete from '../../Form/input/InputAutocomplete'
import InputText from '../../Form/input/InputText'
import InputTextArea from '../../Form/input/InputTextArea'
import SlidingForm from '../../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	text: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	dateBox: {
		width: '100%',
		display: 'inline-block',
	},
	date: {
		verticalAlign: 'middle',
		width: '100%',
		marginBottom: 15,
	},
	inputSelect: {
		marginTop: 20,
	},
}))

/**
 * Shows a form to add a subcontracting company to the company file
 * @returns {JSX.Element} React stack to render
 */
function SubcontractingCompagniesForm({ open, close, data }) {
	const [EEList, setEEList] = useState([])
	const [initialValues, setInitialvalues] = useState()
	const classes = useStyles()
	const { t } = useTranslation('user')
	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = (values) => {
		if (!data || !data.platformId || !data.id) return
		const dt = {
			subcontractorId: values.subcontractorId.value,
			workDescription: values.workDescription,
			responsible: values.responsible,
		}

		createSubcontractorCompanies(data.platformId, data.id, dt, (res, err) => {
			if (err) return console.error(new Error(err))
			if (res) {
				if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
				data.refresh()
				close()
				enqueueSnackbar(t('companyHasBeenCreated'), { variant: 'success' })
			}
		})
	}

	useEffect(() => {
		if (!data || !data.platformId || !data.id) return
		getSubcontractorCompanies(data.platformId, data.id, (res, err) => {
			if (err) return
			if (res) {
				setEEList(res.data)
				if (data.new)
					setInitialvalues({
						...initialValues,
						subcontractorId: '',
						responsible: '',
						workDescription: '',
					})
				else
					setInitialvalues({
						...initialValues,
						subcontractorId: data.companyId,
						responsible: data.respName,
						workDescription: data.workDesc,
					})
			}
		})
	}, [open, data])

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addSubcontractor')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				{!data.new ? (
					<InputText
						classes={{ root: classes.text }}
						name="subcontractorId"
						label="EE"
						validate={composeValidators(required)}
						disabled={true}
					/>
				) : (
					<InputAutocomplete
						classes={{ root: classes.inputSelect }}
						name="subcontractorId"
						label="EE"
						data={EEList.map((ee) => ({
							value: ee.companyId,
							label: `${ee.socialReason} (${ee.agencyName})`,
						}))}
					></InputAutocomplete>
				)}
				<InputText
					classes={{ root: classes.text }}
					name="responsible"
					label={t('respName')}
					validate={composeValidators(required)}
				/>
				<InputTextArea
					classes={{ root: classes.text }}
					name="workDescription"
					label={t('desc')}
					validate={composeValidators(required)}
				/>
				<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
			</Form>
		</SlidingForm>
	)
}
SubcontractingCompagniesForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default SubcontractingCompagniesForm
