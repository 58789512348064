import { Icon, IconButton } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../../components/HeadTitle/HeadTitle'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { getCompanyRecordApprobation } from '../../../containers/DataContainer'
import { useApprovalCompaniesRecordsForm } from '../../../containers/FormContainer'
import { COLUMNS_APPROVAL_CR, getIndexOfColumn } from '../../../utils/Datatable'
import PropTypes from 'prop-types'

/**
 * Shows the MyAccount page
 * @returns {JSX.Element} React stack to render
 */
// eslint-disable-next-line no-empty-pattern
function ApprovalCompaniesRecords({ match }) {
	const { t } = useTranslation('user')
	const [cookies] = useCookies()
	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const [refresh, setRefresh] = useState(false)
	const ApprovalCompaniesRecordsForm = useApprovalCompaniesRecordsForm()
	const [isLoading, setIsLoading] = useState(true)

	const { platformId } = match.params

	const openForm = (pPlatformId, societyName, CR_id, pdpNumber, typeOf, comment) => {
		ApprovalCompaniesRecordsForm.addData({
			platformId: pPlatformId,
			CR_id,
			societyName,
			pdpNumber,
			typeOf,
			comment,
			setRefresh,
		})
		ApprovalCompaniesRecordsForm.open()
	}

	const actionsColumns = {
		label: 'Actions',
		name: 'actions',
		options: {
			display: 'true',
			filter: false,
			sort: false,
			sortDirection: 'none',
		},
	}

	useEffect(() => {
		const onBegin = () => {
			getCompanyRecordApprobation(
				platformId,
				{
					nbItemsAsked: 10,
					pageNumber: 1,
				},
				(res, err) => {
					setIsLoading(false)
					if (err) return
					if (res && res.status === 200) {
						setDatatable(res.data)
					}
				},
			)
		}
		setRefresh(false)
		onBegin()
	}, [refresh, platformId])

	return (
		<>
			{ApprovalCompaniesRecordsForm.formular}
			<HeadTitle title={t('approveDE')} link="/dashboard" />
			<DataTable
				title={t('FollowUp')}
				columns={[...datatable.columns, actionsColumns]}
				data={datatable.data}
				options={datatable.options}
				isLoading={isLoading}
				customColumns={[
					{
						name: 'actions',
						component: function componentIconButton(_value, tableMeta) {
							return (
								<IconButton
									onClick={() => {
										let societyName =
											tableMeta.rowData[
												getIndexOfColumn(datatable.columns, COLUMNS_APPROVAL_CR.NOMENTREPRISE)
											]
										let depId =
											tableMeta.rowData[
												getIndexOfColumn(datatable.columns, COLUMNS_APPROVAL_CR.DEPID)
											]
										let pdpNumber =
											tableMeta.rowData[
												getIndexOfColumn(datatable.columns, COLUMNS_APPROVAL_CR.NUMEROPDP)
											]
										let typeOf =
											tableMeta.rowData[
												getIndexOfColumn(datatable.columns, COLUMNS_APPROVAL_CR.NATUREINTERVENTION)
											]
										let comment =
											tableMeta.rowData[
												getIndexOfColumn(datatable.columns, COLUMNS_APPROVAL_CR.COMMENT)
											]
										openForm(platformId, societyName, depId, pdpNumber, typeOf, comment)
									}}
								>
									<Icon>info</Icon>
								</IconButton>
							)
						},
					},
				]}
				customLabels={[
					{
						name: 'numeroPdp',
						label: t('pdpNumero'),
					},
					{
						name: 'natureIntervention',
						label: t('natureIntervention'),
					},
					{
						name: 'nomEntreprise',
						label: t('entRaisonSociale'),
					},
					{
						name: 'revisionDossier',
						label: t('depNumeroRevision'),
					},
					{
						name: 'statutApprobation',
						label: t('current_step_workflow'),
					},
				]}
			/>
		</>
	)
}

ApprovalCompaniesRecords.propTypes = {
	match: PropTypes.object,
}
export default ApprovalCompaniesRecords
