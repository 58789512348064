import React from 'react'
import FormIcon from '../Form/FormIcon'
import FormButtonIcon from '../Form/FormButtonIcon'
import SlidingForm from '../Form/SlidingForm'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'
import InputSelect from '../Form/input/InputSelect'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import Form from '../Form/Form'

const useStyles = makeStyles(theme => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
}))

function WarningAccountForm({ open, close, data }) {
	const { t } = useTranslation(['accountManagment'])
	const classes = useStyles()

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('warning')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={values => console.log(values)}>
				<Typography
					component="p"
					classes={{
						root: classes.typographyFirst,
					}}
				>
					{t('principalDisabledMessage')}
				</Typography>
				<br />
				<InputSelect
					name="place"
					label={t('principal')}
					defaultValue="entreprise1"
					classes={{
						root: classes.inputSelectRoot,
					}}
				>
					<SelectMenuItem value="place1">n°1</SelectMenuItem>
					<SelectMenuItem value="place2">n°2</SelectMenuItem>
					<SelectMenuItem value="place3">n°3</SelectMenuItem>
					<SelectMenuItem value="place4">n°4</SelectMenuItem>
				</InputSelect>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default WarningAccountForm
