import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useTotalTitleBarContext } from '../../../contexts/TotalTitleBarContext'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import HeadTitle from '../../../components/HeadTitle/HeadTitle'
import {
	deletePdpContactsWithoutAccount,
	getPdpContactsWithoutAccount,
	updatePdpContactsWithoutAccount,
} from '../../../containers/DataContainer'
import DataTable from '../../../components/NewDataTable/NewDataTable.js'
import { getIndexOfColumn } from '../../../utils/Datatable.js'
import DeleteForever from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import { Button, Grid, Modal, TextField, Typography } from '@material-ui/core'
import Form from '../../../components/Form/Form'
import InputText from '../../../components/Form/inputFullPage/InputText'
import classNames from 'classnames'
import { required } from '../../../utils/VerifyFields.js'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '10%',
		maxWidth: '35%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	title: {
		color: theme.palette.secondary.main,
		marginBottom: 20,
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	dataTableLink: {
		color: theme.palette.secondary.main,
	},
	icon: {
		'&:hover': {
			cursor: 'pointer',
		},
	},
	buttonGroup: {
		display: 'flex',
	},
	sentence: {
		marginLeft: theme.spacing.unit * 2,
	},
}))

function PDPContacts() {
	const classes = useStyles()
	const { t } = useTranslation(['specialsTreatments', 'user'])
	const { setTitleBar } = useTotalTitleBarContext()
	const [cookies] = useCookies()
	const [contacts, setContacts] = useState([])
	const [isLoading, setIsLoading] = useState(false)
	const [openModalConfirm, setOpenModalConfirm] = useState(false)
	const [openModalUpdate, setOpenModalUpdate] = useState(false)
	const [contact, setContact] = useState({
		id: '',
		firstname: '',
		lastname: '',
	})
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'person',
				title: () => t('specialsTreatments:specials_treatments'),
			},
		})
	}, [setTitleBar, t])

	useEffect(() => {
		retrievePdpContacts()
	}, [])

	const retrievePdpContacts = () => {
		setIsLoading(true)
		getPdpContactsWithoutAccount(cookies[PLATFORM_ID_COOKIE], (res, err) => {
			if (err) {
				setIsLoading(false)
				return
			}
			if (res) {
				let newColumns = {
					name: 'action',
					label: 'action',
					options: {
						display: 'true',
						filter: false,
						filterOptions: null,
						searchable: false,
						sort: false,
					},
				}
				setContacts({
					...res.data,
					columns: [...res.data.columns, newColumns],
				})
				setIsLoading(false)
			}
		})
	}

	const close = () => {
		setOpenModalConfirm(false)
		setOpenModalUpdate(false)
	}

	const handleOnDelete = () => {
		deletePdpContactsWithoutAccount(cookies[PLATFORM_ID_COOKIE], contact.id, (res, err) => {
			if (err) return
			if (res) {
				setOpenModalConfirm(false)
				setContacts({
					...contacts,
					data: contacts.data.filter((x) => x.id !== contact.id),
				})
				setContact()
			}
		})
	}

	const handleOnUpdate = (value) => {
		updatePdpContactsWithoutAccount(
			cookies[PLATFORM_ID_COOKIE],
			contact.id,
			{ ...value, id: undefined },
			(res, err) => {
				if (err) return
				if (res) {
					setOpenModalUpdate(false)
					retrievePdpContacts()
					setContact()
				}
			},
		)
	}

	return (
		<>
			<HeadTitle title={t('specialsTreatments:pdp_contacts')} link="/special-treatment" />
			<Typography className={classes.sentence}>
				{t('specialsTreatments:pdp_contacts_sentence')}
			</Typography>
			<DataTable
				title={t('specialsTreatments:pdp_contacts')}
				data={contacts.data}
				columns={contacts.columns}
				options={contacts.options}
				isLoading={isLoading}
				customColumns={[
					{
						name: 'action',
						component: (_value, tableMeta) => {
							const utlId = tableMeta.rowData[getIndexOfColumn(contacts.columns, 'id')]
							const utlFirstname =
								tableMeta.rowData[getIndexOfColumn(contacts.columns, 'firstname')]
							const utlLastname = tableMeta.rowData[getIndexOfColumn(contacts.columns, 'lastname')]
							const utlEntity = tableMeta.rowData[getIndexOfColumn(contacts.columns, 'entite')]
							return (
								<>
									<EditIcon
										color="secondary"
										className={classes.icon}
										onClick={() => {
											setOpenModalUpdate(true)
											setContact({
												id: utlId,
												firstname: utlFirstname,
												lastname: utlLastname,
												entity: utlEntity,
											})
										}}
									/>
									<DeleteForever
										color="secondary"
										className={classes.icon}
										onClick={() => {
											setOpenModalConfirm(true)
											setContact({
												id: utlId,
											})
										}}
									/>
								</>
							)
						},
					},
				]}
				customLabels={[
					{
						name: 'firstname',
						label: t('user:firstname'),
					},
					{
						name: 'lastname',
						label: t('user:lastname'),
					},
					{
						name: 'mail',
						label: t('user:email'),
					},
					{
						name: 'entite',
						label: t('user:entity'),
					},
					{
						name: 'action',
						label: t('Action'),
					},
				]}
			/>
			<Modal open={openModalConfirm} onClose={close}>
				<div className={classes.paper}>
					<Form onSubmit={handleOnDelete}>
						<Typography variant="h6" classes={{ root: classes.title }}>
							{t('specialsTreatments:pdp_contacts_delete_confirm_title')}
						</Typography>
						<Typography variant="body1" classes={{ root: classes.title }}>
							{t('specialsTreatments:pdp_contacts_delete_confirm')}
						</Typography>
						<div className={classes.buttonGroup}>
							<Button classes={{ root: classes.button }} onClick={close}>
								{t('user:cancel')}
							</Button>
							<Button className={classNames(classes.button, classes.validateButton)} type="submit">
								{t('user:submit')}
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
			<Modal open={openModalUpdate} onClose={close}>
				<div className={classes.paper}>
					<Form onSubmit={handleOnUpdate} initialValues={contact}>
						<Typography variant="h6" classes={{ root: classes.title }}>
							{t('specialsTreatments:pdp_contacts_update_confirm_title')}
						</Typography>
						<Grid container spacing={16}>
							<Grid item xs={12}>
								<InputText
									name="firstname"
									label={t('user:firstname')}
									required={true}
									validate={required}
								/>
							</Grid>
							<Grid item xs={12}>
								<InputText
									name="lastname"
									label={t('user:lastname')}
									required={true}
									validate={required}
								/>
							</Grid>
							<Grid item xs={12}>
								<InputText
									name="entity"
									label={t('user:entity')}
									required={true}
									validate={required}
								/>
							</Grid>
						</Grid>
						<div className={classes.buttonGroup}>
							<Button classes={{ root: classes.button }} onClick={close}>
								{t('user:cancel')}
							</Button>
							<Button className={classNames(classes.button, classes.validateButton)} type="submit">
								{t('user:submit')}
							</Button>
						</div>
					</Form>
				</div>
			</Modal>
		</>
	)
}
PDPContacts.propTypes = {
	match: PropTypes.object,
}

export default PDPContacts
