import { Dialog, Fab, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import {
	getChemicalProduct,
	getContactsByAccountId,
	getDangerPhrases,
	upsertChemicalProduct,
} from '../../containers/DataContainer'
import { HandlingErrorMessages } from '../../utils/Errors'
import { getFileToByteArray } from '../../utils/File'
import { composeValidators } from '../../utils/Form'
import { CASorCENumber, required } from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormIcon from '../Form/FormIcon'
import FormStep from '../Form/FormStep'
import { InputSwitch, InputCheckbox } from '../Form/input/InputCheckbox'
import InputFile from '../Form/input/InputFile'
import InputText from '../Form/input/InputText'
import InputTextArea from '../Form/input/InputTextArea'
import SlidingForm from '../Form/SlidingForm'
import LinearVerticalStepper from '../VerticalStepper/LinearVerticalStepper'
import LANG from '../../constants/lang'
import { bindParams } from '../../utils/Link'
import humanFileSize from '../../constants/coreConstants'
import PropTypes from 'prop-types'
import {
	ChemicalProductFormStyle,
	ContactCard,
	FormStepRiskIdentification,
	PictoRightSide,
	createCompanyContact,
	FormCreateContact,
} from './ChemicalProductsFormContainer'
import InputDate from '../Form/input/InputDate'
import InputNumber from '../Form/input/InputNumber'
import { GetPlatform } from '../../containers/RegisterContainer'
import { formatDate } from '../../utils/Date'
const useStyles = ChemicalProductFormStyle
function ChemicalProductsForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const localLang = localStorage.getItem('i18nextLng')
	const [initialValues, setInitialValues] = useState({})
	const [dangerPhrases, setDangerPhrases] = useState([])
	const [selectedDangerPhrases, setSelectedDangerPhrases] = useState([])
	const [contacts, setContacts] = useState([])
	const [selectedContact, setSelectedContact] = useState({ id: 0 })
	const [openDialog, setOpenDialog] = useState(false)
	const [refresh, setRefresh] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [nameFile, setNameFile] = useState(null)
	const [errorMessage, setErrorMessage] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const { t } = useTranslation(['user', 'translation', 'chemical'])
	const [isCasNumberIgnored, setIsCasNumberIgnored] = useState(false)
	const translationFormStepRiskIdentification = {
		dangerousSentences: t('chemical:dangerousSentences'),
		NoDangerPhrases: t('NoDangerPhrases'),
	}
	const [platformFDSValidityDuration, setPlatformFDSValidityDuration] = useState()
	const getLangId = () => LANG[localLang].id
	const formatResponse = (res) => {
		if (res.data.prdFDSCheminComplet) {
			setFile(res.data.prdFDSCheminComplet)
		}
		setSelectedContact({
			id: res.data.utlId,
			lastName: res.data.utlNom,
			firstName: res.data.utlPrenom,
			phone: res.data.utlTelephone,
			mail: res.data.utlEmail,
		})
		return {
			name: res.data.prdNom,
			synonym: res.data.prdSynonyme,
			provider_name: res.data.prdNomFournisseur,
			use: res.data.prdUsage,
			cas_ce: res.data.prdNumeroCasCe,
			cmr: res.data.prdCodeCMR,
			se: res.data.prdAsScenarioExpoSE,
			validityDuration: res.data.prdValidityDuration,
			validityEndDate: res.data.prdValidityEndDate,
		}
	}
	const formatRequest = (req) => {
		const formData = new FormData()
		formData.append('name', req.name || '')
		formData.append('synonymous', req.synonym || '')
		formData.append('supplierName', req.provider_name || '')
		formData.append('purpose', req.use || '')
		formData.append('casOrCeNumber', req.cas_ce || '')
		formData.append('cmrCode', req.cmr || false)
		formData.append('hasOpertaingScenario', true)
		formData.append('contactId', selectedContact.id)
		formData.append('contactName', selectedContact.lastName)
		formData.append('contactFirstName', selectedContact.firstName)
		formData.append('contactPhone', selectedContact.phone)
		formData.append('contactNotifiableForProducts', true)
		formData.append('isCasNumberIgnored', isCasNumberIgnored)
		formData.append('validityDuration', req.validityDuration)
		formData.append('validityEndDate', formatDate(req.validityEndDate))
		for (const dangerPhrase of selectedDangerPhrases)
			formData.append('dangerPhrases[]', parseInt(dangerPhrase.phdId))
		if (data && data.id) formData.append('id', data.id)
		if (file.name) {
			formData.append('value', req.file, req.file.name)
		}
		return formData
	}
	const fillPlatformFDSValidityDuration = (platforms) => {
		const duration = platforms
			.find((platform) => platform.platformId === parseInt(cookies[PLATFORM_ID_COOKIE]))
			?.platformConfigurations.find((conf) => conf.translationCode === 'FdsValidityDuration')?.value
		setPlatformFDSValidityDuration(duration)
	}
	useEffect(() => {
		if (file) setNameFile(file.name)
	}, [file])
	useEffect(() => {
		if (!open) {
			setSelectedDangerPhrases([])
			return
		}
		setNameFile('')
		if (data && data.id)
			getChemicalProduct({ productId: data.id, companyId: data.companyId }, (res, err) => {
				if (err) console.error(err)
				if (res) {
					setInitialValues(formatResponse(res))
					setSelectedDangerPhrases(res.data.listePhraseDanger)
				}
			})
		getDangerPhrases(getLangId(), (res, err) => {
			if (err) return console.error(err)
			if (res) {
				setDangerPhrases(res.data)
			}
		})
		GetPlatform(null, (res, err) => {
			if (err) console.error(err)
			if (res) {
				fillPlatformFDSValidityDuration(res.data)
			}
		})
	}, [open])
	useEffect(() => {
		if (!open) return
		getContactsByAccountId(data.companyId, function (res, error) {
			if (error) return
			if (res) {
				setContacts(res.data)
			}
		})
	}, [open, refresh])
	/**
	 * @name createContact
	 * @description Create a company contact
	 * @param {Object} dt
	 */
	const createContact = async (dt) => {
		await createCompanyContact(
			{ ...dt, platformId: cookies[PLATFORM_ID_COOKIE] },
			data,
			(result) => {
				if (result.isError) {
					enqueueSnackbar(t(result.message), { variant: 'error' })
				} else {
					enqueueSnackbar(t(result.message), { variant: result.variant })
				}
				setRefresh(!refresh)
				setOpenDialog(false)
			},
		)
	}
	useEffect(() => {
		if (open) return
		setFile(null)
	}, [open])
	const onSubmit = (value) => {
		setIsLoading(true)
		if (!file) {
			enqueueSnackbar('FDS file required !', { variant: 'error' })
			return
		}
		if (file.name) {
			getFileToByteArray(file, function (response, cberror = null) {
				if (!cberror) {
					value = { ...value, file: response }
					upsertChemicalProduct(
						{
							companyId: data.companyId,
							data: formatRequest(value),
						},
						(res, err) => {
							setIsLoading(false)
							if (err && err.data) {
								HandlingErrorMessages(err.data, (code) => {
									enqueueSnackbar(t('chemical:' + code), { variant: 'error' })
								})
								return
							}
							onSubmitSuccess(res)
						},
					)
				} else {
					enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
				}
			})
		} else {
			value = { ...value, file: null }
			upsertChemicalProduct(
				{
					companyId: data.companyId,
					data: formatRequest(value),
				},
				(res, err) => {
					setIsLoading(false)
					if (err) {
						if (err.data && err.data[0] && err.data[0].code) {
							enqueueSnackbar(t(err.data[0].code), { variant: 'error' })
							return
						}
						enqueueSnackbar(t('error') + ` : ${err.status} ! `, { variant: 'error' })
						return
					}
					onSubmitSuccess(res)
				},
			)
		}
	}
	const onSubmitSuccess = (res) => {
		if (res) {
			close()
			enqueueSnackbar('Le produit chimique a bien été ajouté/modifié', {
				variant: 'info',
			})
			if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
			data.setRefresh(!data.refresh)
		}
	}
	const closeDialog = () => {
		setOpenDialog(false)
	}
	return (
		<>
			<SlidingForm
				direction="fullscreen"
				open={open}
				title={t('chemical:ChemicalProduct')}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={() => {
								close()
								setInitialValues({})
							}}
						/>
					</>
				}
				submitLabel="Valider"
				onClose={closeDialog}
			>
				<LinearVerticalStepper
					buttonsStyle={{
						root: classes.rootBtn,
						previous: classes.nextAndCompletedBtn,
						next: classes.nextAndCompletedBtn,
						completed: classes.nextAndCompletedBtn,
						finished: classes.nextAndCompletedBtn,
					}}
					contentStyle={{}}
					initialValues={initialValues}
					result={onSubmit}
					isLoading={isLoading}
				>
					<FormStep
						label={t('chemical:SubstancesIdentification')}
						validate={() => {
							if (file) return true
							enqueueSnackbar(t('fds_file_upload_required'), { variant: 'error' })
						}}
						vertical
					>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="name"
							label={t('chemical:nameFDS')}
							validate={required}
						/>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="synonym"
							label={t('synonym')}
							validate={required}
						/>
						<InputText
							className={classNames([classes.input, classes.basicWidth])}
							name="provider_name"
							label={t('chemical:providerName')}
							validate={required}
						/>
						<InputTextArea
							className={classNames([classes.input, classes.fullWidth])}
							name="use"
							label={t('chemical:usage')}
							validate={required}
						/>
						<Typography className={classes.text} variant="subtitle1">
							{bindParams(t('chemical:FDSWarning'), platformFDSValidityDuration)}
						</Typography>
						<InputNumber
							name="validityDuration"
							label={t('chemical:validity_duration')}
							className={classNames([classes.input, classes.basicWidth])}
							validate={required}
							inputProperties={{ min: 1, max: 100 }}
						/>
						<InputDate
							name="validityEndDate"
							label={t('chemical:validity_end_date')}
							inputStyle={classNames([classes.input, classes.basicWidth])}
							validate={required}
						/>
						<InputFile
							className={classNames([classes.input])}
							name="fds"
							nameFileUpdated={nameFile}
							label={t('chemical:joinFDS')}
							setFile={setFile}
						/>
					</FormStep>
					<FormStep label={t('chemical:composition')} vertical>
						<InputCheckbox
							label={t('chemical:cas_ce_obligation')}
							name="cas_ce_obligation"
							onClick={() => {
								setIsCasNumberIgnored(!isCasNumberIgnored)
							}}
							checked={isCasNumberIgnored}
						/>
						{!isCasNumberIgnored && (
							<InputText
								className={classNames([classes.input])}
								name="cas_ce"
								label={t('chemical:cas_ce')}
								validate={composeValidators(required, CASorCENumber)}
								disabled={isCasNumberIgnored}
							/>
						)}
						<InputSwitch name="cmr" label={t('chemical:cmr')} />
						<InputSwitch name="se" label={t('chemical:se')} />
					</FormStep>
					<FormStep
						label={t('chemical:RiskIdentification')}
						vertical
						validate={() => {
							if (!selectedDangerPhrases || selectedDangerPhrases.length < 1) {
								setErrorMessage('Au moins une phrase de danger est requise !')
								return false
							}
							setErrorMessage('')
							return true
						}}
					>
						<div className={classes.leftSide}>
							<FormStepRiskIdentification
								translation={translationFormStepRiskIdentification}
								selectedDangerPhrases={selectedDangerPhrases}
								setSelectedDangerPhrases={setSelectedDangerPhrases}
								dangerPhrases={dangerPhrases}
							/>
						</div>
						<PictoRightSide selectedDangerPhrases={selectedDangerPhrases} />
						<Typography variant="caption" style={{ color: 'red' }}>
							{errorMessage}
						</Typography>
					</FormStep>
					<FormStep
						label={t('chemical:contact')}
						validate={() => {
							if (selectedContact.id === 0) {
								enqueueSnackbar(t('chemical:requiredContact'), { variant: 'error' })
								return false
							}
							return true
						}}
						vertical
					>
						<Typography className={classes.text} variant="subtitle1">
							{t('chemical:selectOrCreateContact')}
						</Typography>
						<div className={classes.scroll}>
							{contacts.map((contact) => (
								<ContactCard
									key={contact.id}
									id={contact.id}
									lastName={contact.name ? contact.name : 'XXXX'}
									firstName={contact.firstName ? contact.firstName : 'XXXX'}
									phone={contact.phone ? contact.phone : t('NoNumberAvailable')}
									mail={contact.email ? contact.email : t('NoEmailAddressAvailable')}
									selectedContact={selectedContact}
									setSelectedContact={setSelectedContact}
								/>
							))}
						</div>
						<br />
						<Fab
							size="medium"
							color="primary"
							aria-label="add"
							className={classes.margin}
							onClick={() => setOpenDialog(true)}
						>
							<AddIcon color="secondary" />
						</Fab>
					</FormStep>
				</LinearVerticalStepper>
			</SlidingForm>
			<Dialog onClose={closeDialog} aria-labelledby="customized-dialog-title" open={openDialog}>
				<Form onSubmit={createContact}>
					<FormCreateContact t={t} setOpenDialog={setOpenDialog} />
				</Form>
			</Dialog>
		</>
	)
}
ChemicalProductsForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default ChemicalProductsForm
