import React from 'react'

import { AppBar, Toolbar, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import TotalFooter from '../components/TotalFooter/TotalFooter'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	body: {
		overflowX: 'hidden',
		backgroundColor: '#F5F6FA',
		height: 'calc(100vh)',
	},
	AppBar: {
		boxShadow: 'none',
		backgroundColor: theme.palette.primary.main,
		textAlign: 'center',
	},
	Toolbar: {
		padding: theme.spacing.unit * 2,
		display: 'block',
	},
	TotalLogo: {
		height: theme.spacing.unit * 6,
	},
	content: {
		position: 'relative',
		marginTop: 1,
		backgroundColor: '#F5F6FA',
	},
	footer: {
		position: 'absolute',
		bottom: 0,
		width: 'calc(100% - 32px)',
		textAlign: 'center',
		justify: 'center',
		backgroundColor: theme.palette.primary.main,
		padding: theme.spacing.unit * 2,
	},
}))

RegisterLayout.propTypes = {
	children: PropTypes.object,
}
function RegisterLayout({ children }) {
	const classes = useStyles()

	return (
		<>
			<div className={classes.body}>
				<AppBar className={classes.AppBar} position="relative">
					<Toolbar className={classes.Toolbar}>
						<a href="/">
							<img className={classes.TotalLogo} src="/images/total.png" alt="logo" />
						</a>
					</Toolbar>
				</AppBar>
				<Grid
					container
					className={classes.content}
					direction="column"
					alignItems="center"
					justify="center"
					spacing={32}
				>
					{children}
				</Grid>
			</div>
			<TotalFooter />
		</>
	)
}
export default RegisterLayout
