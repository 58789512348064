import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { FormControlLabel, Checkbox, Switch, FormHelperText } from '@material-ui/core'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	label: {
		color: theme.palette.secondary.main,
		fontSize: 16,
		display: 'inline-block',
		maxWidth: '70%',
		verticalAlign: 'middle',
	},
	labelRoot: {
		display: 'block',
	},
	switchRoot: {
		width: '100px',
		color: theme.palette.primary.main,
		'&$checkboxChecked': {
			color: theme.palette.primary.main,
		},
	},
	checkboxChecked: {
		color: theme.palette.secondary.main,
		'&$checkboxChecked': {
			color: theme.palette.secondary.main,
		},
	},
	iconChecked: {
		color: theme.palette.secondary.main,
		transform: 'translateX(52px)',
		'&:after': {
			marginLeft: '10px',
			fontSize: 22,
			content: '\'\' !important',
		},
	},
	switchAfter: {
		'&:after': {
			marginLeft: '25px',
			fontSize: 17,
			color: '#ffffff',
			display: 'flex',
		},
	},
	switchChecked: {
		color: 'black !important',
	},
	switchBase: {
		left: '21px',
		'&$switchChecked': {
			'& + $switchBar': {
				backgroundColor: '#3268A7',
			},
		},
	},
	gridSize: {
		maxHeight: '40px',
	},
	block: {
		display: 'block',
	},
	switchBar: {
		width: '90px',
		height: '24px',
		top: '40%',
		borderRadius: '25px',
	},
	helperText: {
		color: '#E10032',
	},
}))

function InputCheckbox({ name, label, validate, readOnly, helperStyle, ...props }) {
	// STYLES
	const classes = useStyles()

	return (
		<FormControlLabel
			label={label}
			validate={validate}
			classes={{
				root: classes.labelRoot,
				label: classes.label,
			}}
			color="primary"
			control={
				<Field
					name={name}
					type="checkbox"
					render={({ input, meta }) => (
						<>
							<Checkbox
								className={classes.checkboxChecked}
								{...input}
								{...props}
								value=""
								disabled={readOnly}
							/>
							{meta && meta.touched && meta.error && (
								<FormHelperText classes={{ root: classes.helperText }} className={helperStyle}>
									<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
								</FormHelperText>
							)}
						</>
					)}
				/>
			}
		/>
	)
}

export default InputCheckbox

export function InputSwitch({ name, label, ...props }) {
	// STYLES
	const classes = useStyles()
	return (
		<div className={classes.labelRoot}>
			<FormControlLabel
				label={label}
				classes={{
					label: classes.label,
				}}
				labelPlacement="start"
				color="primary"
				control={
					<Field
						name={name}
						type="checkbox"
						render={({ input, meta }) => (
							<Switch
								classes={{
									root: classes.switchRoot,
									icon: classes.switchAfter,
									iconChecked: classes.iconChecked,
									switchBase: classes.switchBase,
									bar: classes.switchBar,
									checked: classes.switchChecked,
								}}
								{...props}
								{...input}
							/>
						)}
					/>
				}
			/>
		</div>
	)
}
