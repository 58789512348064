import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { TextField, InputAdornment, Icon } from '@material-ui/core'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	labelRoot: {
		color: '#3268A7',
	},
	labelFocused: {
		color: '#3268A7 !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: '#3268A7',
		width: '100%',
		border: 'solid 1px',
		borderColor: '#3268A7',
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	inputFocused: {
		'&:after': {
			display: 'none',
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
		display: 'contents',
	},
}))

function InputMail({ name, label,validate, ...props }) {
	const classes = useStyles()

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => (
				<TextField
					type="mail"
					label={label}
					variant="filled"
					FormHelperTextProps={{
						classes: {
							root: classes.helperText,
						},
					}}
					helperText={
						meta && meta.touched && meta.error ? (
							<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
						) : (
							undefined
						)
					}
					className={classes.block}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<Icon>alternate_email</Icon>
							</InputAdornment>
						),
						classes: {
							root: classes.inputRoot,
							focused: classes.inputFocused,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.labelRoot,
							focused: classes.labelFocused,
						},
					}}
					// eslint-disable-next-line react/jsx-no-duplicate-props
					inputProps={{
						className: classes.inputSize,
					}}
					{...props}
					{...input}
				/>
			)}
		/>
	)
}

export default InputMail
