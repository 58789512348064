import { makeStyles } from '@material-ui/styles'
import i18next from 'i18next'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { AddHygieneContact, getContactTypeList } from '../../../containers/DataContainer'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputCheckbox from '../../Form/input/InputCheckbox'
import InputMail from '../../Form/input/InputMail'
import InputPhone from '../../Form/input/InputPhone'
import InputText from '../../Form/input/InputText'
import SlidingForm from '../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	dateBox: {
		width: '100%',
		display: 'inline-block',
	},
	date: {
		width: '100%',
		marginTop: 10,
	},
	text: {
		display: 'inline-block',
		color: theme.palette.primary.main,
	},
}))

/**
 * Shows a form to add a Medical Surveillance to the company file
 * @returns {JSX.Element} React stack to render
 */
function AddCSEContactForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])
	const [language, setLanguage] = useState(1)
	const [listType, setListType] = useState([])

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	})

	useEffect(() => {
		getContactTypeList(language, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListType(res.data)
			}
		})
	}, [open])

	const onSubmit = (values) => {
		let selectedTypes = listType.map((type, i) => {
			if (values['type' + (i - 1)]) return i
		})
		selectedTypes = [...new Set([...selectedTypes, ...[6]])]
		let formData = {
			localeId: language,
			contactLastName: values.lastname,
			firstName: values.firstname,
			phone: values.phone,
			email: values.mail,
			types: selectedTypes.filter((n) => n),
		}

		AddHygieneContact(
			cookies[PLATFORM_ID_COOKIE],
			data.companyRecordId,
			formData,
			function (res, error) {
				if (res) {
					data.updateData()
					enqueueSnackbar('Le contact à été ajouté', {
						variant: 'info',
					})
					close()
				}
				if (error) {
					enqueueSnackbar(t('error'), { variant: 'error' })
				}
			},
		)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addCSEMember')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form
				onSubmit={onSubmit}
				initialValues={{ startDate: moment(), endDate: moment(), type5: true }}
			>
				<InputText
					classes={{ root: classes.size1 }}
					validate={required}
					name="lastname"
					label={t('lastname')}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					validate={required}
					name="firstname"
					label={t('firstname')}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					validate={required}
					name="mail"
					label={t('email')}
				/>
				<InputPhone
					classes={{ root: classes.size1 }}
					validate={required}
					name="phone"
					label={t('phone')}
				/>
				{listType &&
					listType.map((type, i) => (
						<InputCheckbox
							name={'type' + type.id}
							label={type.name}
							value={type.id}
							key={type.id}
						/>
					))}
				<FormButtonIcon type="submit" classes={{ root: classes.validateButton }} />
			</Form>
		</SlidingForm>
	)
}

export default AddCSEContactForm
