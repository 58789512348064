import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import FilterResults from 'react-filter-search'
import { useTranslation } from 'react-i18next'
import FormIcon from '../../../components/Form/FormIcon'
import InputCheckbox from '../../../components/Form/input/InputCheckbox'
import SlidingForm from '../../../components/Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { AddHygieneContact, getContactsByAccountId } from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormButtonIconAdd from '../../Form/FormButtonIconAdd'
import InputSearch from '../../Form/input/InputSearch'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	scrollbars: {
		height: '63vh',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
}))

/**
 * Shows a form to add a contact to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddCompanyFilesContactHSEForm({ open, close, data }) {
	const classes = useStyles()
	const [value, setValue] = useState('')
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])
	const [listContacts, setListContacts] = useState([])

	const search = (event) => {
		setValue(event.target.value)
	}

	const getContacts = () => {
		if (!open) return
		getContactsByAccountId(data.companyId, (res, err) => {
			if (err) return console.error('Error to get contacts !')
			setListContacts(res.data)
		})
	}

	useEffect(getContacts, [open])

	const onSubmit = (values) => {
		let selectedContacts = listContacts.map((contact) => {
			return values[`contact-${contact.id}`] && { contactId: contact.id }
		})
		let contactToSend = selectedContacts.filter((n) => n)
		contactToSend.forEach((contact) => {
			AddHygieneContact(
				cookies[PLATFORM_ID_COOKIE],
				data.companyRecordId,
				contact,
				function (res, error) {
					if (res) {
						if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
						data.updateData()
						enqueueSnackbar(t('TheContactHasBeenAdded'), {
							variant: 'info',
						})
					}
					if (error) {
						enqueueSnackbar(t('error'), { variant: 'error' })
					}
				},
			)
		})
		setValue('')
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addContact')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							setValue('')
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				setValue('')
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{}}>
				<InputSearch
					name="search"
					label={t('searchContact')}
					onChange={search}
					classes={{ root: classes.inputSearch }}
				/>
				<div className={classes.scrollbars}>
					<Scrollbars>
						<FilterResults
							value={value}
							data={listContacts}
							renderResults={(results) => (
								<div>
									{results.map((el) => (
										<InputCheckbox
											name={'contact-' + el.id}
											label={el.firstName + ' ' + el.name}
											value={el.id}
											key={el.id}
										/>
									))}
								</div>
							)}
						/>
					</Scrollbars>
				</div>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<FormButtonIconAdd
						classes={{ root: classes.validateButton }}
						onClick={() => {
							data.openAddCSEContactForm()
							close()
						}}
					/>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddCompanyFilesContactHSEForm
