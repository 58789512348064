import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Typography, IconButton, Icon } from '@material-ui/core'
import CalendarToday from '@material-ui/icons/CalendarToday'
import Place from '@material-ui/icons/Place'
import EditIcon from '@material-ui/icons/EditOutlined'
import { useTranslation } from 'react-i18next'
import fileDownload from 'js-file-download'
import { downloadFile } from '../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'

// STYLES
const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		padding: 0,
		width: 300,
		display: 'inline-block',
		minHeight: 250,
		margin: 10,
	},
	cardHeader: {
		margin: 10,
	},
	cardContent: {
		padding: 0,
	},
	title: {
		fontSize: 25,
		fontWeight: 500,
		color: theme.palette.secondary.main,
	},
	subtitle: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.secondary.main,
		marginBottom: 10,
		'&:hover': {
			cursor: 'pointer',
		},
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	download: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	calendarIcon: {
		margin: 10,
		fontSize: 45,
		display: 'inline-block',
		color: '#323232',
	},
	date: {
		fontSize: 25,
		fontWeight: 600,
		color: '#323232',
	},
	expiration: {
		fontSize: 15,
		color: '#323232',
	},
	groupDate: {
		display: 'inline-block',
		transform: 'translateY(-7px)',
		marginLeft: 10,
	},
	placeName: {
		display: 'inline-block',
		transform: 'translateY(-17px)',
		marginLeft: 10,
	},
	placeIcon: {
		margin: 10,
		marginLeft: 15,
		marginTop: 20,
		fontSize: 30,
		color: '#323232',
	},
	icon: {
		width: 60,
		display: 'inline-block',
	},
	horizontalDivider: {
		width: '95%',
		marginLeft: '2.5%',
		border: 'solid 1px' + theme.palette.secondary.main,
		opacity: '0.2',
	},
	cardIcon: {
		position: 'absolute',
		top: 0,
		right: 0,
	},
	deleteButton: {
		position: 'absolute',
		bottom: theme.spacing.unit,
		right: theme.spacing.unit,
	},
}))

/**
 * Shows a simple Certification card
 * @param {String} title : Title of the certification
 * @param {String} subtitle : Subtitle of the certification
 * @param {String} date : Date of the certification
 * @param {String} duration : Duration of the certification
 * @param {String} location : Location of the certification
 * @returns {JSX.Element} React stack to render
 */
const CertificationCard = ({
	title,
	subtitle,
	date,
	duration,
	location,
	edit,
	path,
	type,
	deleteFc,
	isReadOnly,
	isReviewMode,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const { enqueueSnackbar } = useSnackbar()

	const downloadDocument = (pathFile) => {
		downloadFile(pathFile, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	return (
		<Card className={classes.card}>
			<CardContent className={classes.cardContent}>
				<div className={classes.cardHeader}>
					<Typography classes={{ root: classes.title }}>{title}</Typography>
					<div>
						<Typography
							classes={{ root: classes.subtitle }}
							onClick={() => {
								if (path) {
									downloadDocument(path)
								}
							}}
						>
							{subtitle}
						</Typography>
						{path && (
							<Icon
								className={classes.download}
								onClick={() => {
									if (path) {
										downloadDocument(path)
									}
								}}
							>
								get_app
							</Icon>
						)}
					</div>
				</div>
				<div className={classes.horizontalDivider} />
				<div className={classes.icon}>
					<CalendarToday className={classes.calendarIcon} />
				</div>
				<div className={classes.groupDate}>
					<Typography classes={{ root: classes.date }}>{date}</Typography>
					<Typography classes={{ root: classes.expiration }}>
						{duration + ' '} {t('Months')}
					</Typography>
				</div>
				<div>
					<div className={classes.icon}>
						<Place className={classes.placeIcon} />
					</div>
					<Typography classes={{ root: classes.placeName }}>{location}</Typography>
				</div>
				{!isReadOnly && !isReviewMode && (
					<>
						{type !== 'companyFiles' && (
							<IconButton className={classes.cardIcon} aria-label="Edit" onClick={edit}>
								<EditIcon />
							</IconButton>
						)}
						<IconButton className={classes.deleteButton} onClick={deleteFc}>
							<Icon>clear</Icon>
						</IconButton>
					</>
				)}
			</CardContent>
		</Card>
	)
}

export default CertificationCard
