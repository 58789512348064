/*
You can use this context if tou want to create modal pop up.
*/
import React, { createContext, useContext, useState } from 'react'

const TotalModalPopUpContext = createContext()

function TotalModalPopUpContextProvider(props) {
	const [typeModal, setTypeModal] = useState(null)
	let values = { typeModal, setTypeModal }
	return (
		<TotalModalPopUpContext.Provider value={values}>{props.children}</TotalModalPopUpContext.Provider>
	)
}

let TotalModalPopUpContextConsumer = TotalModalPopUpContext.Consumer

export { TotalModalPopUpContext, TotalModalPopUpContextProvider, TotalModalPopUpContextConsumer }