import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { Typography, MenuItem, Paper, Icon, FormControl, FilledInput, InputLabel, FormHelperText } from '@material-ui/core'
import { Translation, useTranslation } from 'react-i18next'
import Select from 'react-select'

// STYLES
const useStyles = makeStyles(theme => ({
	formControl: {
		width: '100%',
		display: 'block',
	},
	menuItem: {
		zIndex: 1000,
		color: '#3268A7',
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: '#3268A7 !important',
		},
	},
	menuItemBlue: {
		zIndex: 1000,
		color: 'white',
		backgroundColor: '#3268A7',
		'&:hover': {
			color: '#3268A7 !important',
			backgroundColor: 'white !important',
		},
	},
	valueContainer: {
		display: 'flex',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		color: theme.palette.primary.main,
	},
	arrowColor: {
		color: '#ffffff',
		marginRight: '0px',
	},
	paper: {
		position: 'absolute',
		zIndex: 1000,
		left: 0,
		right: 0,
		backgroundColor: '#3268A7',
	},
	label: {
		fontSize: '1em',
	},
	labelBlue: {
		fontSize: '1em',
		backgroundColor: '#3268A7',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		textColor: 'white',
		width: '100%',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
			border: 'none'
		},
	},
	inputLabelRoot: {
		color: 'white',
		paddingLeft: 0,
	},
	noOptionsRoot: {
		marginRight: 'auto',
		marginLeft: 'auto',
		display: 'inline-block'
	},
	labelRoot: {
		color: 'white',
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
}))

const customStyles = {
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? 'red' : 'blue',
		backgroundColor: state.isSelected ? 'red' : 'blue',
		padding: 20,
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		textColor: 'white',
		backgroundColor: '#3268A7',
	}),
	valueContainer: (provided, state) => ({
		...provided,
		paddingLeft: 0,
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: '#3268A7',
	}),
	clearIndicator: (provided, state) => ({
		...provided,
		color: 'white',
	}),
	control: (provided, state) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		backgroundColor: '#3268A7',
		border: 'none',
		borderRadius: '0px',
		borderTopLeftRadius: '4px',
		borderTopRightRadius: '4px',
		boxShadow: 'none',
		padding: '12px 10px 12px 12px',
		alignContent: 'space-around',
		maxHeight: '50px',
		'&:hover': {
			border: 'none',
			cursor: 'pointer',
		},
		'&:focus': {
			border: 'none',
			cursor: 'pointer',
		},
		'& input': {
			color: 'white !important'
		},   
	}),
}

function InputAutocomplete({ name, label, validate, data, onChange = () => {}, ...props }) {
	const classes = useStyles()
	const [value, setValue] = useState([])
	const { t } = useTranslation('user')

	const myOnChange = event => {
		setValue(event)
	}

	function SingleValue(props) {
		return (
			<Typography color="primary" className={classes.labelRoot} {...props.innerProps}>
				{props.children}
			</Typography>
		)
	}

	function NoOptionsMessage(props){
		return(
			<div className={classes.valueContainer}>
				<Typography
					color="primary"
					className={classes.noOptionsRoot}
					{...props.innerProps}
				>
					{t('noResult')}
				</Typography>
			</div>
		)
	}

	function ValueContainer (props) {
		return (
			<div className={classes.valueContainer}>
				{props.children}
			</div>
		)
	}

	function Placeholder(props) {
		const { selectProps, innerProps = {}, children } = props
		return (
			<InputLabel
				{...props.innerProps}
				classes={{
					root: classes.inputLabelRoot,
					focused: classes.labelFocused,
				}}
			>
				{props.children}
			</InputLabel>
		)
	}

	function Option(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				classes={{ root: classes.menuItemBlue, selected: classes.selected }}
				{...props.innerProps}
			>
				{props.children}
			</MenuItem>
		)
	}

	function Menu(props) {
		return (
			<Paper square className={classes.paper} {...props.innerProps}>
				{props.children}
			</Paper>
		)
	}


	function DropdownIndicator(props) {
		return(
			<Icon
				className= {classes.arrowColor}
			>
      arrow_drop_down_circle
			</Icon>
		)
	}


	function IndicatorSeparator(props) {
		return null
	}
    
	const components = {
		SingleValue,
		Menu,
		Option,
		DropdownIndicator,
		IndicatorSeparator,
		Placeholder,
		ValueContainer,
		NoOptionsMessage
	}

	useEffect(() => {
		setValue([])
	}, [])
	return (
		<Field
			name={name}
			validate={validate}   
			render={({ input, meta }) => (
				<FormControl variant="filled" className={classes.formControl} {...props}>
					<Select
						components={components}
						{...input}
						onChange={e => {
							onChange(e)
							input.onChange(e)
						}}
						classes={{
							root: classes.inputRoot,
						}}
						inputProps={{
							className: classes.labelRoot,
						}}                  
						styles={customStyles}
						placeholder={label}                
						{...props}
						isClearable
						options={data}
						TextFieldProps={{
							variant: 'filled',
						}}     
					>
					</Select>
					<FormHelperText classes={{ root: classes.helperText }}>
						{meta && meta.touched && meta.error ? (
							<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
						) : (
							undefined
						)}
					</FormHelperText>
				</FormControl>
			)}
		/>
	)
}

export default InputAutocomplete
