import { Button, Modal, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import ROLES from '../../../constants/roles'
import {
	getAccountByTypePlatform,
	updatePdPCompanyPrincipal,
} from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import InputSelectField from '../../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import PropTypes from 'prop-types'
import { useStyles } from './PopInChoiceStyle'
const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}
function PrincipalCoordinatorChoice({
	open,
	setPrincipalCoordinatorChoiceForm,
	principalCoordinatorChoiceData,
	refreshDatatable,
	setRefreshDatatable,
}) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listPrincipal, setListPrincipal] = useState([])
	const [initialValues, setInitialValues] = useState({})
	const close = () => {
		setPrincipalCoordinatorChoiceForm(false)
	}
	useEffect(() => {
		if (!principalCoordinatorChoiceData) return
		getAccountByTypePlatform(cookies[PLATFORM_ID_COOKIE], ROLES.PRINCIPAL.id, function (res) {
			if (res) {
				setInitialValues({
					principal: res.data.selectedResponsablId,
				})
				setListPrincipal(res.data)
			}
		})
	}, [principalCoordinatorChoiceData])
	const onSubmit = (values) => {
		let formData = {
			CompanyPrincipalId: values.principal,
			companyId: principalCoordinatorChoiceData.companyId,
		}
		updatePdPCompanyPrincipal(
			cookies[PLATFORM_ID_COOKIE],
			principalCoordinatorChoiceData.pdpId,
			formData,
			function (res, error) {
				if (res) {
					setRefreshDatatable(!refreshDatatable)
					close()
				}
				if (error) {
					console.error(error)
				}
			},
		)
	}
	return (
		<Modal open={open} onClose={close}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit} initialValues={initialValues}>
					<Typography variant="h6" classes={{ root: classes.mainTitle }}>
						{t('CoordinatorforCR')}
					</Typography>
					<Typography variant="h6" classes={{ root: classes.secondTitle }}>
						{principalCoordinatorChoiceData.companyName}
					</Typography>
					<div className={classes.PrincipalGroup}>
						<Typography variant="subtitle1" classes={{ root: classes.label }}>
							{t('CoordinatorPrincipal')}
						</Typography>
						<InputSelectField
							name="principal"
							label={t('CoordinatorPrincipal')}
							classes={{ root: classes.inputSelect }}
						>
							{listPrincipal &&
								listPrincipal.map((elem) => {
									return (
										<SelectMenuItem key={elem.id} value={elem.id}>
											{elem.firstName} {elem.lastName}
										</SelectMenuItem>
									)
								})}
						</InputSelectField>
					</div>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button className={classNames(classes.button, classes.validateButton)} type="submit">
							{t('submit')}
						</Button>
					</div>
					<div></div>
				</Form>
			</div>
		</Modal>
	)
}
PrincipalCoordinatorChoice.propTypes = {
	open: PropTypes.bool,
	setPrincipalCoordinatorChoiceForm: PropTypes.func,
	refreshDatatable: PropTypes.func,
	setRefreshDatatable: PropTypes.object,
	principalCoordinatorChoiceData: PropTypes.object,
}
export default PrincipalCoordinatorChoice
