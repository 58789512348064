import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './Gauge.css'
import { useTranslation } from 'react-i18next'
import { SUPERVISION_PERF_SCORE } from '../../constants/supervision'

function Gauge({ perf }) {
	const { t } = useTranslation(['gauge'])
	const [performance, setPerformance] = useState(null)

	const getPerfString = (code) =>
		Object.keys(SUPERVISION_PERF_SCORE).find((key) => SUPERVISION_PERF_SCORE[key].code === code)

	useEffect(() => {
		perf = 100 - perf
		document.documentElement.style.setProperty('--purcent', perf)
		if (perf >= SUPERVISION_PERF_SCORE.FAST.min && perf < SUPERVISION_PERF_SCORE.FAST.max) {
			setPerformance(getPerfString(SUPERVISION_PERF_SCORE.FAST.code))
		} else if (
			perf >= SUPERVISION_PERF_SCORE.MEDIUM.min &&
			perf < SUPERVISION_PERF_SCORE.MEDIUM.max
		) {
			setPerformance(getPerfString(SUPERVISION_PERF_SCORE.MEDIUM.code))
		} else if (perf >= SUPERVISION_PERF_SCORE.LOW.min && perf <= SUPERVISION_PERF_SCORE.LOW.max) {
			setPerformance(getPerfString(SUPERVISION_PERF_SCORE.LOW.code))
		}
	}, [perf])

	return (
		<div className="gauge-wrapper">
			<div className={`gauge three risk`}>
				<div className="slice-colors">
					<div className="st slice-item"></div>
					<div className="st slice-item"></div>
					<div className="st slice-item"></div>
				</div>
				<div className="needle"></div>
				<div className="gauge-center">
					<div className="label">{t('gauge:title')}</div>
					<div className="number">{performance ? t(`gauge:${performance}`) : ''}</div>
				</div>
			</div>
		</div>
	)
}
Gauge.propTypes = {
	perf: PropTypes.number,
}

export default Gauge
