import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import InputTextArea from '../Form/input/InputTextArea'
import InputSelectField from '../Form/input/InputSelect'
import InputDate from '../Form/input/InputDate'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import { askAttachment } from '../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import SlidingForm from '../Form/SlidingForm'
import { required } from '../../utils/VerifyFields'
import classNames from 'classnames'
import ROLES from '../../constants/roles'
import Access from '../../containers/RoleContainer'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	input: {
		width: '100%',
		marginBottom: '10px',
		marginTop: '15px',
	},
	validateRoot: {
		display: 'block',
	},
	inputSelectRoot: {
		marginTop: '15px',
	},
}))

function AttachmentCardForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'attachment'])
	const { enqueueSnackbar } = useSnackbar()

	const onSubmit = (values) => {
		let formData = {
			platformId: values['platform'],
			justification: values['justify'],
			expiration: values['date'],
			renew: false,
		}
		askAttachment(formData, data.companyID, function (res, error) {
			if (error) {
				enqueueSnackbar(t(`attachment:${error.data[0].code}`), { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('TheAttachmentHasBeenrequested'), { variant: 'success' })
				data.updateStatus(res.data)
				close()
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('Attachment')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{ date: new Date() }}>
				<InputSelectField
					name="platform"
					label={t('user:platform')}
					classes={{
						root: classes.inputSelectRoot,
					}}
				>
					{data !== undefined &&
						data.sites.map((site) => {
							return (
								site.platformId && (
									<SelectMenuItem key={site.platformId} value={site.platformId}>
										{site.name}
									</SelectMenuItem>
								)
							)
						})}
				</InputSelectField>
				<InputTextArea
					name="justify"
					label={t('user:justification')}
					classes={{ root: classes.textArea }}
					validate={required}
				/>
				<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
					<InputDate
						name="date"
						label={t('expiration')}
						inputStyle={classNames(classes.input, classes.date)}
					/>
				</Access>

				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
AttachmentCardForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default AttachmentCardForm
