import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import {
	Typography,
	Grid,
	Divider,
	Checkbox,
	FormControlLabel,
	Fab,
	CircularProgress,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ProgressCard from '../../components/Cards/ProgressCard'
import BasicCard from '../../components/Cards/BasicCard'
import { useAccountForm, useEEForm } from '../../containers/FormContainer'
import { sendRegisterData } from '../../containers/RegisterContainer'
import { useTranslation } from 'react-i18next'
import { useSession } from '../../containers/SessionContainer'
import { useSnackbar } from 'notistack'
import CGUDialog from '../../components/CG/CGUDialog'

const useStyles = makeStyles((theme) => ({
	titlesItem: {
		textAlign: 'center',
	},
	typo: {
		fontWeight: 'bold',
		color: theme.palette.secondary.main,
	},
	divider: {
		marginTop: theme.spacing.unit * 1.5,
		backgroundColor: theme.palette.secondary.main,
	},
	cardContainer: {
		width: '90%',
	},
	checkbox: {},
	dontSubmit: {
		width: 200,
		marginBottom: theme.spacing.unit * 10,
		cursor: 'not-allowed',
	},
	submit: {
		width: 200,
		marginBottom: theme.spacing.unit * 10,
	},
	appBar: {
		position: 'relative',
	},
	totalLogo: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	content: {
		height: '100vh',
		padding: '10%',
	},
	title: {
		textAlign: 'center',
		fontWeight: 400,
	},
	loremIpsum: {
		padding: '10%',
		paddingTop: 50,
	},
	text: {
		paddingBottom: 15,
	},
	globalLoader: {
		marginRight: 'auto',
		display: 'inline-block',
		marginLeft: 10,
		color: theme.palette.secondary.main,
	},
	globalCircularProgress: {
		marginLeft: 'auto',
		color: theme.palette.secondary.main,
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	globalLoaderGroup: {
		display: 'flex',
	},
}))

function Overview() {
	const classes = useStyles()
	const AccountInfoForm = useAccountForm()
	const EEInfoForm = useEEForm()
	const [disabledFab, setDisabledFab] = useState(true)
	const [form1Progress, setForm1Progress] = useState(0)
	const [form2Progress, setForm2Progress] = useState(0)
	const [legalCheck, setLegalCheck] = useState(false)
	const [legalCheckCookies, setLegalCheckCookies] = useState(false)
	const [redirect, setRedirect] = useState({ active: false, url: 'sign-up' })
	const { session, clearSession } = useSession('signup')
	const { t } = useTranslation(['user', 'legal', 'legalCookies'])
	const { enqueueSnackbar } = useSnackbar()
	const [openDialog, setOpenDialog] = useState(false)
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		if (!session) return

		let tempSession = JSON.parse(session)

		if (tempSession) {
			if (tempSession.accountInfo) {
				setForm1Progress(100)
			}
			if (tempSession.eeInfo) {
				setForm2Progress(100)
			}
		}
	}, [session])

	useEffect(() => {
		setLegalCheckCookies(false)
	}, [openDialog])

	useEffect(() => {
		const isCompleted = form1Progress === 100 && form2Progress === 100 && legalCheck

		if (isCompleted) setDisabledFab(false)
		else setDisabledFab(true)
	})

	const formatRequest = (dt) => {
		let accountInfo = dt.accountInfo
		let eeInfo = dt.eeInfo
		let langInfo = dt.langInfo

		return {
			userPrincipalName: accountInfo.login,
			password: accountInfo.password,
			languageId: langInfo ? langInfo : 1,
			email: accountInfo.email,
			firstName: accountInfo.firstname,
			lastName: accountInfo.lastname,
			platformRegister: eeInfo.platforms,
			companyName: eeInfo.companyName,
			socialReason: eeInfo.socialReason,
			agencyName: eeInfo.agencyName,
			agencyPhone: eeInfo.agencyPhone,
			nationalityId: eeInfo.companyNationality,
			siret: eeInfo.siretNumber,
		}
	}

	const SubmitForm = () => {
		if (!session) return

		let tempSession = JSON.parse(session)

		if (tempSession && !tempSession.langInfo) {
			setRedirect({ active: true, url: '/sign-up' })
			enqueueSnackbar(t('YouDoNotHaveSelectedLanguage'), { variant: 'error' })
			return
		}
		setLoader(true)
		setDisabledFab(true)
		let data = formatRequest(tempSession)

		sendRegisterData(data, function (res, error) {
			if (error) {
				setLoader(false)
				setDisabledFab(false)
				if (error.data && error.data[0]) {
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
					return
				}
				enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('askOpen'), { variant: 'success' })
				setLoader(false)
				setDisabledFab(false)
				clearSession()
				setRedirect({ active: true, url: '/' })
			}
		})
	}

	const onChange = (event) => {
		if (event.target.checked) setLegalCheck(true)
		else setLegalCheck(false)
	}

	if (redirect.active) return <Redirect to={redirect.url} />
	return (
		<>
			{loader && (
				<div className={classes.globalLoaderGroup}>
					<CircularProgress className={classes.globalCircularProgress} />
					<p className={classes.globalLoader}>Envoi du formulaire en cours</p>
				</div>
			)}
			<CGUDialog
				openDialog={openDialog}
				legalCheckCookies={legalCheckCookies}
				setOpenDialog={setOpenDialog}
				setLegalCheckCookies={setLegalCheckCookies}
			/>
			{AccountInfoForm.formular}
			{EEInfoForm.formular}
			<Grid item className={classes.titlesItem}>
				<Typography variant="h6" className={classes.typo}>
					{t('signup')}
					<br />
				</Typography>
				<Divider variant="middle" className={classes.divider} />
			</Grid>
			<Grid item className={classes.cardContainer}>
				<Grid container spacing={16}>
					<ProgressCard
						title={t('InformationAboutAccount')}
						size={4}
						percent={form1Progress}
						edit={AccountInfoForm.open}
					/>
					<ProgressCard
						title={t('InformationAboutExternCompany')}
						size={4}
						percent={form2Progress}
						edit={EEInfoForm.open}
					/>
					<BasicCard
						title="CGU"
						size={4}
						onClick={() => {
							setOpenDialog(true)
						}}
					></BasicCard>
				</Grid>
			</Grid>
			<Grid item>
				<FormControlLabel
					control={
						<Checkbox
							color="secondary"
							className={classes.checkbox}
							value="CGU"
							onChange={onChange}
						/>
					}
					label={t('acceptConditionsOfUse')}
				/>
			</Grid>
			<Grid item>
				<Fab
					className={classes.submit}
					onClick={SubmitForm}
					color="secondary"
					variant="extended"
					disabled={disabledFab}
				>
					{t('signup_btn')}
				</Fab>
			</Grid>
		</>
	)
}
export default Overview
