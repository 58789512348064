import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import humanFileSize from '../../constants/coreConstants'
import { uploadEnvironmentalAspectFile } from '../../containers/DataContainer'
import { getFileToByteArray } from '../../utils/File'
import { bindParams } from '../../utils/Link'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputFile from '../Form/input/InputFile'
import SlidingForm from '../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	iconSmall: {
		fontSize: 20,
	},
	typographyRoot: {
		color: 'white',
		fontSize: 17,
		marginTop: 20,
		marginBottom: 15,
	},
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},
	charactersAllowed: {
		margin: '20px 0',
		color: '#fff',
	},
}))

function EnvironmentalAspectForm({ open, close, data }) {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const [file, setFile] = useState(null)
	const [nameFile, setNameFile] = useState(null)
	const [loader, setLoader] = useState(false)
	const { enqueueSnackbar } = useSnackbar()

	const onFormSubmit = (values) => {
		upload(file)
	}

	useEffect(() => {
		if (file) setNameFile(file.name)
	}, [file])

	const upload = (file) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				setLoader(true)
				const formData = new FormData()
				formData.append('value', res, nameFile)

				uploadEnvironmentalAspectFile(formData, data.companyID, function (res, error) {
					if (res) {
						enqueueSnackbar(t('YouHaveJustDownloadedThisFile'), { variant: 'success' })
						data.setRefresh(true)
						close()
						setLoader(false)
					}
					if (error) {
						enqueueSnackbar(t('AuthorizedFormat'), { variant: 'error' })
						setLoader(false)
					}
				})
			} else {
				enqueueSnackbar(bindParams(t(cberror.error), humanFileSize), { variant: 'error' })
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('environmentalAspect')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onFormSubmit}>
				<Typography
					component="p"
					classes={{
						root: classes.typographyRoot,
					}}
				>
					{t('joinFile')}
					<Typography component="span" className={classes.charactersAllowed}>
						{t('file_characters_allowed')}
					</Typography>
				</Typography>
				<InputFile label={t('upload')} name="upload" setFile={setFile} />
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>{t('UploadInProgress')}</Typography>
						</div>
					</>
				)}
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default EnvironmentalAspectForm
