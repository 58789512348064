import { Button, Typography } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import {
	getEnvironmentalAspects,
	setDEEnvironmentalAspect,
} from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputCheckbox from '../../Form/input/InputCheckbox'
import InputRadio, { TotalRadio } from '../../Form/input/InputRadio'
import SlidingForm from '../../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	radioInput: {
		display: 'flex',
	},
	validateButton: {
		color: theme.palette.primary.main,
		display: 'block',
		marginLeft: 0,
		marginRight: 0,
		width: '2em',
		height: '2em',
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	text: {
		marginTop: 20,
	},
	scroll: {
		height: '32vh',
	},
	buttonAdd: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	addIcon: {
		width: '2em',
		height: '2em',
	},
	flex: {
		display: 'flex',
	},
}))

/**
 * Shows a form to add environmental aspect to the company file
 * @returns {JSX.Element} React stack to render
 */
function AddEnvironmentalAspectsFiles({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'companyRecord'])
	const [cookies] = useCookies()
	const [listFiles, setListFiles] = useState([])
	const enqueueSnackBar = useSnackbar()

	useEffect(() => {
		if (!open) return
		getEnvironmentalAspects(data.companyId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListFiles(res.data)
			}
		})
	}, [open, cookies, data.companyId])

	const onSubmit = (values) => {
		let selectedFiles = listFiles.filter((file, i) => {
			if (values['file-' + i]) return file
			return null
		})
		selectedFiles = selectedFiles.map((file) => {
			return {
				fileName: file.fileName,
				path: file.path,
				environmentalAspectCategory: values.type,
			}
		})
		selectedFiles = [...selectedFiles, ...data.listAspects]

		const formData = new FormData()

		// Add new file
		for (let index = 0; index < selectedFiles.length; index++) {
			if (selectedFiles[index].dateAdded)
				formData.append(`files[${index}].dateAdded`, selectedFiles[index].dateAdded)
			if (selectedFiles[index].fileName)
				formData.append(`files[${index}].fileName`, selectedFiles[index].fileName)
			if (selectedFiles[index].path)
				formData.append(`files[${index}].path`, selectedFiles[index].path)
			if (selectedFiles[index].username)
				formData.append(`files[${index}].username`, selectedFiles[index].username)
			if (selectedFiles[index].environmentalAspectCategory)
				formData.append(
					`files[${index}].environmentalAspectCategory`,
					selectedFiles[index].environmentalAspectCategory,
				)
		}

		setDEEnvironmentalAspect(data.platformId, data.companyRecordId, formData, (res, err) => {
			if (err) {
				console.error(err)
			}

			if (res) {
				if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
				data.setUpdate(!data.update)
				if (res.data && res.data.companyRecordId)
					data.setRevisionCompanyRecordId(res.data.companyRecordId)

				enqueueSnackBar(t('companyRecord:successToUploadFile'), { variant: 'success' })
				close()
			}
		})
	}

	const openEnvironmentalAspect = () => {
		data.openEnvironmentalAspectNewFileForm()
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addFromLibrary')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{ type: 1 }}>
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('Files')}
				</Typography>

				{!listFiles.length ? (
					<Typography variant="body1" color="primary" className={classes.text}>
						{t('YouHaveNoFiles')}
					</Typography>
				) : (
					listFiles &&
					listFiles.length && (
						<div className={classes.scroll}>
							<Scrollbars>
								{listFiles.map((el, i) => (
									<InputCheckbox
										name={'file-' + i}
										label={el.fileName}
										labelClass={classes.flex}
										value={el.path}
										key={i}
									/>
								))}
							</Scrollbars>
						</div>
					)
				)}
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('filesAreAbout')}
				</Typography>
				<InputRadio name="type">
					<TotalRadio className={classes.radioInput} label={t('enviAnalyse')} value="1" />
					<TotalRadio className={classes.radioInput} label={t('trashs')} value="2" />
				</InputRadio>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<div>
						<Button
							className={classes.buttonAdd}
							variant="outlined"
							onClick={openEnvironmentalAspect}
						>
							<AddCircle className={classes.addIcon} />
						</Button>
					</div>
				</div>
			</Form>
		</SlidingForm>
	)
}
AddEnvironmentalAspectsFiles.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default AddEnvironmentalAspectsFiles
