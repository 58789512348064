import React from 'react'
import FormIcon from '../Form/FormIcon'
import FormButtonIcon from '../Form/FormButtonIcon'
import SlidingForm from '../Form/SlidingForm'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import { useCookies } from 'react-cookie'
import { Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import InputTextArea from '../Form/input/InputTextArea'
import { changeStateOpeningRequest } from '../../containers/DataContainer'
import { required } from '../../utils/VerifyFields'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
	labelTitle: {
		fontSize: 17,
		color: theme.palette.primary.main,
		marginBottom: 20,
		marginTop: 20,
	},
}))

const RefuseOpenningRequestForm = ({ open, close, data }) => {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()

	const onSubmit = (values) => {
		let requestData = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			companyId: data.companyId,
			companyStateId: 3,
			message: values.justify,
			expiration: values.date,
		}
		changeStateOpeningRequest(requestData, function (res, error) {
			if (res) {
				enqueueSnackbar(t('TheRequestHasBeenRefused'), { variant: 'info' })
				data.setUpdate(!data.update)
				close()
			}
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('RefusalOpeningRequest')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{ date: new Date() }}>
				<Typography variant="h4" classes={{ root: classes.labelTitle }}>
					{t('JustificationOfTheRefusal')}
				</Typography>
				<InputTextArea name="justify" label={t('justification')} validate={required} />
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default RefuseOpenningRequestForm
