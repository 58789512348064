import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { TextField, InputAdornment, Icon } from '@material-ui/core'
import { Translation } from 'react-i18next'

// INPUT STYLE
const useStyles = makeStyles(theme => ({
	labelRoot: {
		color: theme.palette.primary.main,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		width: '100%',
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
	},
}))

// FULL PAGE STYLE
const useFullPageStyles = makeStyles(theme => ({
	labelRoot: {
		color: '#3268A7',
	},
	labelFocused: {
		color: '#3268A7 !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: '#3268A7',
		width: '100%',
		border: 'solid 1px',
		borderColor: '#3268A7',
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
	},
}))

function InputSearch({ name, validate, label, onChange, variant, ...props }) {
	const basicClasses = useStyles()
	const fullPageClasses = useFullPageStyles()
	const classes = variant === 'FullPage' ? fullPageClasses : basicClasses

	return (
		<TextField
			type="text"
			label={label}
			variant="filled"
			className={classes.block}
			FormHelperTextProps={{
				classes: {
					root: classes.helperText,
				},
			}}
			onChange={e => {
				onChange(e)
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<Icon>search</Icon>
					</InputAdornment>
				),
				classes: {
					root: classes.inputRoot,
					focused: classes.inputFocused,
				},
			}}
			InputLabelProps={{
				classes: {
					root: classes.labelRoot,
					focused: classes.labelFocused,
				},
			}}
			// eslint-disable-next-line react/jsx-no-duplicate-props
			inputProps={{
				className: classes.inputSize,
			}}
			{...props}
		/>
	)
}

export default InputSearch
