import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import EditIcon from '@material-ui/icons/Edit'
import IconButton from '@material-ui/core/IconButton'
import HourglassEmpty from '@material-ui/icons/HourglassEmpty'
import NotInterested from '@material-ui/icons/NotInterested'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Check from '@material-ui/icons/Check'
import JustifyAttachmentForm from '../forms/JustifyAttachmentForm'
import moment from 'moment'
import Access from '../../containers/RoleContainer'
import ROLES from '../../constants/roles'

// STYLES
const useStyles = makeStyles((theme) => ({
	width: {
		minWidth: '220px',
	},
	card: {
		minHeight: 260,
		position: 'relative',
		margin: '10px',
	},
	updateIcon: {
		position: 'absolute',
		top: 10,
		right: 10,
	},
	cardIcon: {
		position: 'absolute',
		bottom: 25,
		right: 25,
	},
	addCardIcon: {
		position: 'absolute',
		top: '25%',
		left: '15%',
		width: '70%',
	},
	addIcon: {
		height: '100%',
		width: '100%',
	},
	hrCard: {
		margin: 0,
		maxWidth: '90%',
		marginLeft: '5%',
		border: '1px solid' + theme.palette.secondary.main,
		opacity: '0.20',
	},
	cardHeader: {
		color: theme.palette.secondary.main,
		fontWeight: '500',
	},
	hourGlassIcon: {
		width: '2em',
		height: '2em',
		color: '#F5960A',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	notInterestedIcon: {
		width: '2em',
		height: '2em',
		color: '#F44436',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	checkIcon: {
		width: '2em',
		height: '2em',
		color: '#72B84D',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	waitingContent: {
		display: 'block',
		fontSize: '32px',
		color: '#F5960A',
		fontWeight: '500',
		textAlign: 'center',
	},
	expiredContent: {
		display: 'block',
		fontSize: '32px',
		color: '#F44436',
		fontWeight: '500',
		textAlign: 'center',
	},
	currentContent: {
		display: 'block',
		fontSize: '32px',
		color: '#72B84D',
		fontWeight: '500',
		textAlign: 'center',
	},
}))

const AttachmentCard = ({ size, title, type, id, date, open, canedit }) => {
	const classes = useStyles()
	const { t } = useTranslation('user')

	// Fit card skin with date (respect 3 month rule)
	var DateWithThreeMonth = new Date(date)
	DateWithThreeMonth = DateWithThreeMonth.setMonth(DateWithThreeMonth.getMonth() + 3)

	if (Date.now() > DateWithThreeMonth) type = 'expired'

	return (
		<Grid item xs={12} sm={6} md={size} className={classes.width}>
			<Card className={classes.card}>
				<MuiCardHeader title={title} titleTypographyProps={{ className: classes.cardHeader }} />
				<hr className={classes.hrCard} />
				<CardContent>
					{type === 'waiting' && (
						<>
							<HourglassEmpty className={classes.hourGlassIcon} />
							<Typography className={classes.waitingContent}>{t('waiting')}</Typography>
						</>
					)}
					{type === 'expired' && (
						<>
							<NotInterested className={classes.notInterestedIcon} />
							<Typography className={classes.expiredContent}>{t('expired')}</Typography>
							<IconButton
								className={classes.cardIcon}
								aria-label="Edit"
								onClick={() => {
									open(id)
								}}
							>
								{canedit && <EditIcon />}
							</IconButton>
						</>
					)}
					{type === 'current' && (
						<>
							<Check className={classes.checkIcon} />
							<Typography className={classes.currentContent}>
								{moment(date).format('DD/MM/YYYY')}
							</Typography>
							<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
								{canedit && (
									<IconButton
										className={classes.updateIcon}
										aria-label="Edit"
										onClick={() => {
											open(id)
										}}
									>
										<EditIcon />
									</IconButton>
								)}
							</Access>
						</>
					)}
				</CardContent>
			</Card>
		</Grid>
	)
}

export default AttachmentCard
