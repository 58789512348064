import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { makeStyles } from '@material-ui/styles'
import React from 'react'

const useStyles = makeStyles(theme => ({
   
	editorWrapper: {
		marginTop: theme.spacing.unit * 2,
		marginBottom: theme.spacing.unit * 2,
		borderStyle: 'solid',
		borderWidth: 0.5,
		borderColor: theme.palette.secondary.main,
		borderRadius: 5,
	},
	editorInputText: {
		padding: '0 10px'
	},
	editorToolbar: {
		border: 'none',
		borderBottom: 1,
		borderBottomStyle: 'solid',
		borderBottomColor: '#F1F1F1',
		backgroundColor: 'transparent'
	}
}))

/**
 * Shows the custom RichText component
 * @returns {JSX.Element} React stack to render
 */
function RichText({editorState, onChangeEditorState, ...props}) {

	const classes = useStyles()

	return (
		<>
			<Editor
				editorState={editorState}
				toolbarClassName={ classes.editorToolbar }
				wrapperClassName={ classes.editorWrapper }
				editorClassName={ classes.editorInputText }
				onEditorStateChange={onChangeEditorState}
				{...props}
			/>
		</>)
   
}

export default RichText