/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { Redirect } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import InputAdornment from '@material-ui/core/InputAdornment'
import Person from '@material-ui/icons/Person'
import { Form, Field } from 'react-final-form'
import { useTranslation, Translation } from 'react-i18next'
import { TextField } from '@material-ui/core'
import queryString from 'query-string'
import { validateAccountExternalCompany, validateAccountUser } from '../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import LockOutlined from '@material-ui/icons/LockOutlined'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import {
	oneMajCharRequired,
	passwordSize,
	oneSpecialCharRequired,
	required,
	loginSize,
	noSpace,
} from '../../utils/VerifyFields'
import { composeValidators } from '../../utils/Form'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	main: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	form: {
		margin: 'auto',
		width: '70%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 2,
	},
	leftPanel: {
		backgroundImage: 'url(/images/leftResetPassword.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	logo: {
		marginBottom: 20,
		width: '100%',
		maxWidth: 200,
	},
	title: {
		marginBottom: theme.spacing.unit * 1.5,
		color: theme.palette.secondary.main,
	},
	underlineTitle: {
		marginBottom: theme.spacing.unit * 2,
		backgroundColor: theme.palette.secondary.main,
		width: '50%',
		height: 1,
		border: 0,
	},
	icon: {
		fill: '#C8C8C8',
	},
	usernameField: {
		width: 400,
	},
	connectBtn: {
		backgroundColor: theme.palette.primary.main,
		border: 'solid 2px #6D6D6D',
		color: '#6D6D6D',
	},
	signupBtn: {
		marginTop: theme.spacing.unit * 3,
		backgroundColor: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.secondary.dark,
		},
	},
	buttons: {
		boxShadow: 'none',
		width: '228px',
		marginBottom: theme.spacing.unit * 4,
	},
	redCross: {
		color: 'red',
	},
	error: {
		color: '#bf0000',
	},
}))

const FirstConnect = ({ location }) => {
	const classes = useStyles()
	const [redirect, setRedirect] = useState({ active: false, url: '/' })
	const { t } = useTranslation(['user'])
	const pathName = location.pathname.toLowerCase() === '/externalcompany'
	const token = queryString.parse(location.search).token
	const { enqueueSnackbar } = useSnackbar()
	const [showPassword, setShowPassword] = useState(false)

	const onSubmit = (values) => {
		let formData = {
			resetGuid: token,
			password: values.password,
			Username: values.login,
		}
		if (pathName) {
			formData = {
				firstName: values.firstname,
				lastName: values.lastname,
				...formData,
			}

			validateAccountExternalCompany(formData, function (res, error) {
				if (res) {
					setRedirect({ active: true, url: '/' })
					enqueueSnackbar(t('AccessPlatformHasBeenCreated'), { variant: 'info' })
					return
				}
				if (error) {
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
				}
			})
			return
		}

		validateAccountUser(formData, function (res, error) {
			if (res) {
				setRedirect({ active: true, url: '/' })
				enqueueSnackbar(t('AccessPlatformHasBeenCreated'), { variant: 'info' })
			}
			if (error) {
				enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
			}
		})
	}

	const TotalField = ({ name, validate: p_Validate, icon: IconComponent, type, ...props }) => {
		return (
			<Field
				name={name}
				className={classes.usernameField}
				validate={p_Validate}
				render={({ input, meta }) => (
					<FormControl margin="normal" {...input} fullWidth>
						<TextField
							type={showPassword ? 'text' : type ? type : 'text'}
							className={classes.block}
							label={t(name)}
							autoComplete="off"
							FormHelperTextProps={{
								classes: {
									root: classes.error,
								},
							}}
							helperText={
								meta && meta.touched && meta.error ? (
									<Translation ns="validateData">
										{(translation) => <>{translation(meta.error)}</>}
									</Translation>
								) : undefined
							}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<IconComponent className={classes.icon}>alternate_email</IconComponent>
									</InputAdornment>
								),
								endAdornment: type === 'password' && (
									<InputAdornment
										onClick={() => {
											setShowPassword(!showPassword)
										}}
										position="end"
										className={classes.icon}
									>
										{!showPassword && <VisibilityIcon />}
										{showPassword && <VisibilityOffIcon />}
									</InputAdornment>
								),
								classes: {
									root: classes.inputRoot,
									focused: classes.inputFocused,
								},
							}}
							{...props}
							{...input}
						/>
					</FormControl>
				)}
			/>
		)
	}
	TotalField.propTypes = {
		name: PropTypes.string,
		validate: PropTypes.func,
		icon: PropTypes.object,
		type: PropTypes.string,
	}
	if (redirect.active) return <Redirect to={redirect.url} />

	const validate = (values) => {
		let errors = {}
		if (values.password !== values.confirmPassword) errors.confirmPassword = 'notTheSame'
		return errors
	}

	return (
		<>
			<Grid container spacing={0}>
				<Grid item md={6} className={classes.leftPanel} />
				<Grid item md={6} sm={12}>
					<main className={classes.main}>
						<CssBaseline />
						<div className={classes.paper}>
							<img className={classes.logo} src="/images/total.png" alt={t('brand')} />
							<Typography className={classes.title} component="h1" variant="h4">
								{t('CreatingAccessPlatform')}
							</Typography>
							<hr className={classes.underlineTitle} />
							<Form
								onSubmit={onSubmit}
								validate={validate}
								render={({ handleSubmit }) => (
									<form className={classes.form} onSubmit={handleSubmit}>
										<TotalField
											name="login"
											placeholder="login"
											icon={Person}
											validate={composeValidators(required, loginSize, noSpace)}
										/>
										{pathName && (
											<TotalField
												name="firstname"
												validate={required}
												icon={Person}
												placeholder={t('firstname')}
											/>
										)}
										{pathName && (
											<TotalField
												name="lastname"
												validate={required}
												icon={Person}
												placeholder={t('lastname')}
											/>
										)}
										<TotalField
											name="password"
											validate={composeValidators(
												required,
												passwordSize,
												oneSpecialCharRequired,
												oneMajCharRequired,
											)}
											type="password"
											icon={LockOutlined}
											placeholder={t('password')}
										/>
										<TotalField
											name="confirmPassword"
											validate={required}
											type="password"
											icon={LockOutlined}
											placeholder={t('confirm_password')}
										/>
										<Fab
											type="submit"
											variant="extended"
											color="secondary"
											className={classNames([classes.signupBtn, classes.buttons])}
										>
											{t('Create')}
										</Fab>
									</form>
								)}
							/>
						</div>
					</main>
				</Grid>
			</Grid>
		</>
	)
}
FirstConnect.propTypes = {
	location: PropTypes.object,
}

export default FirstConnect
