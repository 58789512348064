import { Button, Typography } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	getOperationnalModes,
	upsertCompanyRecordOperationalMode,
} from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputCheckbox from '../../Form/input/InputCheckbox'
import SlidingForm from '../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	text: {
		marginTop: 20,
	},
	buttonAdd: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	addIcon: {
		width: '2em',
		height: '2em',
	},
	flex: {
		display: 'flex',
	},
}))

/**
 * Shows a form to add a operational procedure to the company file
 * @returns {JSX.Element} React stack to render
 */
function OperationalProceduresForm({ open, close, data }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listProcedure, setListProcedure] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation(['user'])

	const onSubmit = (values) => {
		let procedures = Object.entries(values)
			.filter((check) => check[1] === true)
			.map((check) => listProcedure[parseInt(check[0].split('-')[1])])
		procedures = [...data.listProcedure, ...procedures]

		const formData = new FormData()

		for (let index = 0; index < procedures.length; index++) {
			if (procedures[index].dateAdded)
				formData.append(`files[${index}].dateAdded`, procedures[index].dateAdded)
			if (procedures[index].fileName)
				formData.append(`files[${index}].fileName`, procedures[index].fileName)
			if (procedures[index].path) formData.append(`files[${index}].path`, procedures[index].path)
			if (procedures[index].username)
				formData.append(`files[${index}].username`, procedures[index].username)
			if (procedures[index].docId) formData.append(`files[${index}].docId`, procedures[index].docId)
		}

		upsertCompanyRecordOperationalMode(
			cookies[PLATFORM_ID_COOKIE],
			data.companyRecordId,
			formData,
			function (res, error) {
				if (res) {
					if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
					data.updateData()
					enqueueSnackbar(t('user:OPAdded'), {
						variant: 'info',
					})
					close()
					if (res.data && res.data.companyRecordId)
						data.setRevisionCompanyRecordId(res.data.companyRecordId)
				}
				if (error) {
					//enqueueSnackbar(t("error")` : ${error.status} !`, { variant: "error" }); CRASH: t function is undefined
					console.log(error)
				}
			},
		)
	}

	useEffect(() => {
		if (!data.companyId) return
		getOperationnalModes(data.companyId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				let temp = []
				res.data.map((id, i) => {
					temp = [...temp, { id: `${i}` }]
				})
				setListProcedure(res.data)
			}
		})
	}, [data.companyId])

	const ProceduresCheckboxs = () => {
		if (!listProcedure.length) {
			return (
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('user:libraryEmpty')}
				</Typography>
			)
		}
		return (
			<>
				{listProcedure.map((file, i) => {
					if (data.listProcedure.every((process) => process.fileName !== file.fileName))
						return (
							<InputCheckbox
								name={`procedure-${i}`}
								label={file.fileName}
								labelClass={classes.flex}
								key={i}
								value={file.path}
							/>
						)
				})}
			</>
		)
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('user:addFromLibrary')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit}>
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('user:molibrary')}
				</Typography>
				<ProceduresCheckboxs />
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<div>
						<Button
							className={classes.buttonAdd}
							variant="outlined"
							onClick={() => {
								data.openAddOPForm()
								close()
							}}
						>
							<AddCircle className={classes.addIcon} />
						</Button>
					</div>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default OperationalProceduresForm
