import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'

import { CookiesProvider } from 'react-cookie'

import { SignUpContextProvider } from './contexts/SignUpContext'
import { TotalTitleBarContextProvider } from './contexts/TotalTitleBarContext'

import './App.css'

import { SnackbarProvider } from 'notistack'
import Routes from './routes'
import { TotalModalPopUpContextProvider } from './contexts/TotalModalPopUpContext'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from './authConfig'

const accounts = msalInstance().getAllAccounts()

if (accounts.length > 0) {
	msalInstance().setActiveAccount(accounts[0])
}

function App() {
	return (
		<MsalProvider instance={msalInstance()}>
			<CookiesProvider>
				<CssBaseline />
				<SnackbarProvider maxSnack={3}>
					<TotalModalPopUpContextProvider>
						<TotalTitleBarContextProvider>
							<SignUpContextProvider>
								<Routes />
							</SignUpContextProvider>
						</TotalTitleBarContextProvider>
					</TotalModalPopUpContextProvider>
				</SnackbarProvider>
			</CookiesProvider>
		</MsalProvider>
	)
}

export default App
