import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import TotalArrows from '../TotalArrows/TotalArrows'
import TotalArrowStep from '../TotalArrowStep/TotalArrowStep'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'block',
	},
	hidden: {
		display: 'none',
	},
	content: {
		display: 'block',
		width: '95%',
		margin: '2.5%',
		borderRadius: 10,
		padding: 10,
	},
	step: {},
	completedIcon: { color: theme.palette.primary.main },
	BasicIcon: {},
	show: {
		display: 'initial',
	},
	stepper: {
		display: 'contents',
	},
	connector: {
		borderColor: 'rgba(255,255,255,0.3)',
	},
}))

function TotalArrowsStepper({
	contentStyle,
	completedSteps,
	stepStatus,
	setStepStatus,
	icon,
	title,
	content,
	pdpnumber,
	loader,
	children,
}) {
	const classes = useStyles()
	const [step, setStep] = useState(0)

	const count = children.length

	const nextStep = () => {
		let newStep = step + 1

		if (newStep >= count) {
			setStep(0)
			return
		} else if (children[newStep].props && children[newStep].props.type === 'button') {
			++newStep
			if (newStep >= count) {
				setStep(0)
				return
			}
		}

		setStep(newStep)
	}

	const previousStep = () => {
		let newStep = step - 1

		if (newStep < 0) {
			newStep = count - 1

			if (children[newStep].props && children[newStep].props.type === 'button') {
				--newStep
			}
		}

		setStep(newStep)
	}

	const changeStep = index => {
		setStep(index)
	}

	const getStepState = index => {
		if (!completedSteps.find(c => c.chevronCode === index)) return 'noData'
		if (completedSteps.find(c => c.chevronCode === index).isValidated) return 'validate'
		if (completedSteps.find(c => c.chevronCode === index).hasData) return 'hasData'
		// if (!completedSteps[index]) return "noData";
		// if (completedSteps[index].isValidated) return "validate";
		// if (completedSteps[index].hasData) return "hasData";
		return 'noData'
	}

	function getStepperState(props, index) {
		if (props.disable) return 'disable'
		if (index === step)
			if (getStepState(props.chevronCode) === 'validate') return 'currentValidate'
			else return 'current'
		return getStepState(props.chevronCode)
	}

	var childTmp = children[step].props.children
	var child = {
		...childTmp,
		props: {
			...childTmp.props,
			nextStep: nextStep,
			previousStep: previousStep,
		},
	}

	return (
		<div className={classes.root}>
			<TotalArrows
				icon={icon}
				title={title}
				content={content}
				pdpnumber={pdpnumber}
				startWidth={{ width: '300px' }}
				endWidth={classes.endWidth}
			>
				{children.map((child, i) => {
					if (!child || !child.props) return ''
					if (child.props.type && child.props.type === 'button') return child.props.children
					return (
						<TotalArrowStep
							index={i}
							key={i}
							state={getStepperState(child.props, i)}
							content={child.props.label}
							changeStep={() => {
								if (!child.props.disable) changeStep(i)
							}}
						></TotalArrowStep>
					)
				})}
			</TotalArrows>
			{loader}
			<div className={classNames(classes.content, contentStyle)}>{child}</div>
		</div>
	)
}

export default TotalArrowsStepper
