import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Icon } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.primary.main,
		fontSize: '2em',
	},
	arrowsRoot: {
		height: '60px',
		backgroundColor: theme.palette.secondary.main,
		boxShadow: '1px 1px 5px grey',
	},
	arrowBlock: {
		display: 'flex',
		height: '60px',
	},
	titleBlock: {
		display: 'inline-flex',
		height: '50px',
		transform: 'translateY(20px)',
	},
	mainLabelBlock: {
		display: 'flex',
		paddingTop: '2px',
		paddingLeft: '15px',
		top: '57px',
		position: 'absolute',
	},
	labelTitle: {
		color: 'white',
		fontSize: '20px',
		display: 'block',
		fontWeight: 500,
	},
	mainLabelTitle: {
		color: 'white',
		fontSize: '20px',
		display: 'block',
		transform: 'translateY(-11px)',
		fontWeight: 500,
	},
	verticalDivider: {
		height: '26px',
		width: '1px',
		borderLeft: '2px solid #ffffff',
		marginLeft: '10px',
		top: '31px',
		position: 'relative',
		display: 'inline',
		transform: 'translateY(-8px)',
	},
	labelBlock: {
		padding: '10px',
		transform: 'translateY(11px)',
	},
	labelContent: {
		color: 'white',
		fontSize: '12px',
		lineHeight: '13px',
		display: 'block',
		maxWidth: '300px',
		transform: 'translateY(-7px)',
	},
}))

function TotalTitleBar({ title, subtitle, icon, props }) {
	const classes = useStyles()

	return (
		<>
			<div className={classes.arrowsRoot}>
				<div className={classes.arrowBlock}>
					<div className={classes.mainLabelBlock}>
						<div>
							<div className={classes.titleBlock}>
								<Icon className={classes.icon}>{icon}</Icon>
							</div>
						</div>
						<div className={classes.verticalDivider} />
						<div className={classes.labelBlock}>
							{!subtitle && <span className={classes.labelTitle}>{title()}</span>}
							{subtitle && (
								<>
									<span className={classes.mainLabelTitle}>{title()}</span>
									<span className={classes.labelContent}>{subtitle}</span>
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default TotalTitleBar
