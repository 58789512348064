import React, { useEffect, useState } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import AttachmentCard from '../../components/Cards/AttachmentCard'
import { Grid, Fab } from '@material-ui/core'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import { useTranslation } from 'react-i18next'
import { GetAttachments, getPlatformListByAccountId } from '../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { GetPlatform } from '../../containers/RegisterContainer'
import AddIcon from '@material-ui/icons/Add'
import {
	useAttachmentCardForm,
	useJustifyAttachmentForm,
	useAttachmentNewDateForm,
} from '../../containers/FormContainer'
import { makeStyles } from '@material-ui/styles'
import { useMatch } from '../../containers/AuthContainer'
import { COMPANY_ID_COOKIE, ACCOUNT_ID_COOKIE } from '../../constants/cookies'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import ROLES from '../../constants/roles'
import Access from '../../containers/RoleContainer'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'fixed',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
}))

const StateCompanyAttachement = {
	Waiting: 1,
	Accepted: 2,
	Desactivated: 4,
}

function Attachment({ match }) {
	const { t } = useTranslation('user')
	const { setTitleBar } = useTotalTitleBarContext()
	const [cookies] = useCookies()
	const [attachmentList, setAttachmentList] = useState([])
	const [sites, setSites] = useState([{}])
	const [userSites, setUserSites] = useState([])
	const [isSubmit, setIsSubmit] = useState(false)
	const classes = useStyles()
	const { companyId, formatUrl } = useMatch(match, '/op-infos')

	const targetCompanyID = companyId != null ? companyId : cookies[COMPANY_ID_COOKIE]
	const roles = useSelector((state) => state.roles.roles)

	const updateStatus = (values) => {
		setAttachmentList(values)
		setIsSubmit(true)
	}
	const [formData, setFormData] = useState({
		updateStatus: updateStatus,
		companyID: targetCompanyID,
	})
	const JustifyAttachmentForm = useJustifyAttachmentForm(formData)
	const AttachmentNewDateForm = useAttachmentNewDateForm(formData)
	const [data, setData] = useState({
		sites: sites,
		updateStatus: updateStatus,
		companyID: targetCompanyID,
	})
	const AttachmentForm = useAttachmentCardForm(data)

	useEffect(() => {
		setData({ sites: sites, updateStatus: updateStatus, companyID: targetCompanyID })
	}, [sites])

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: () => t('OperationnalData') },
		})

		getPlatformListByAccountId(cookies[ACCOUNT_ID_COOKIE], (res, err) => {
			let isCorrect = !err && res && res.data && res.data.length
			if (!isCorrect) return
			setUserSites(res.data)
		})
	}, [])

	useEffect(() => {
		GetAttachments(targetCompanyID, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setAttachmentList(res.data)
				setIsSubmit(false)
			}
		})
	}, [isSubmit])

	useEffect(() => {
		GetPlatform(cookies, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				if (Object.keys(attachmentList).length < 1) {
					setSites(res.data)
					return
				}
				// Remove Platform which already have a card in the select.
				let tempListPlatform = res.data
				for (const attachment of attachmentList) {
					let found = tempListPlatform.findIndex((element) => {
						return element.platformId === attachment.platformId
					})
					if (found !== -1) {
						tempListPlatform.splice(found, 1)
					}
				}
				setSites(tempListPlatform)
			}
		})
	}, [attachmentList])

	const AddAttachment = () => {
		const newAttachment = () => {
			AttachmentForm.open()
		}

		return (
			<Fab className={classes.fab} color="secondary" onClick={newAttachment}>
				<AddIcon />
			</Fab>
		)
	}

	const openJustify = (id) => {
		setFormData({ updateStatus: updateStatus, companyID: targetCompanyID, id: id })
		JustifyAttachmentForm.open()
	}
	const openNewDate = (id) => {
		setFormData({ updateStatus: updateStatus, companyID: targetCompanyID, id: id })
		AttachmentNewDateForm.open()
	}

	const canEditPlatformAgreement = (platformId) => {
		return (
			userSites.some(
				(site) =>
					site.platformId === platformId &&
					roles.platformId === platformId &&
					roles.accountTypes.some((r) => r.code === ROLES.PLATFORMMANAGER.code),
			) || roles.accountTypes.some((role) => role.code === ROLES.ADMIN.code)
		)
	}

	return (
		<>
			{AttachmentForm.formular}
			{JustifyAttachmentForm.formular}
			{AttachmentNewDateForm.formular}
			<HeadTitle title={t('Attachment')} link={formatUrl} />
			<Grid container spacing={0} direction="row">
				{sites.length > 0 && (
					<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}>
						<AddAttachment />
					</Access>
				)}

				{Object.keys(attachmentList).length > 0 &&
					roles &&
					attachmentList.map((attachment, i) => {
						switch (attachment.stateId) {
							case StateCompanyAttachement.Accepted:
								return (
									<AttachmentCard
										key={i}
										size={3}
										title={attachment.name}
										id={attachment.id}
										type="current"
										date={attachment.validityDate}
										open={openNewDate}
										canedit={canEditPlatformAgreement(attachment.platformId)}
									/>
								)
							case StateCompanyAttachement.Waiting:
								return (
									<AttachmentCard
										key={i}
										size={3}
										title={attachment.name}
										id={attachment.id}
										type="waiting"
										canedit={canEditPlatformAgreement(attachment.platformId)}
									/>
								)
							case StateCompanyAttachement.Desactivated:
								return (
									<AttachmentCard
										key={i}
										size={3}
										title={attachment.name}
										id={attachment.id}
										type="expired"
										updateStatus={updateStatus}
										open={openJustify}
										canedit={canEditPlatformAgreement(attachment.platformId)}
									/>
								)
							default:
								break
						}
					})}
			</Grid>
		</>
	)
}
Attachment.propTypes = {
	match: PropTypes.object,
}

export default Attachment
