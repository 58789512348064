import React, { useCallback } from 'react'
import { Dialog, DialogContent, Button, CircularProgress, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import GetApp from '@material-ui/icons/GetApp'
import { v4 as uuid_v4 } from 'uuid'
import * as link from '../../../constants/url'
import { InputSelectOnChange } from '../../../components/Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../../components/Form/inputFullPage/SelectMenuItem'
import Form from '../../../components/Form/Form'

const useStyles = makeStyles(() => ({
	center: {
		textAlign: 'center',
	},
	buttonProgress: {
		color: 'black',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	form: {
		width: '100% !important',
	},
}))
function ModalActivityPhase({
	isOpen,
	onClose,
	onSubmit,
	loading,
	companyPdp,
	currentPdp,
	setCurrentPdp,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['reports'])

	const executeOnClose = useCallback(() => {
		onClose(false)
	})
	const executeOnSubmit = useCallback((link) => () => {
		onSubmit(link)
	})
	const executeOnChange = useCallback((e) => {
		setCurrentPdp(e.target.value)
	})
	const submitFormWithEmpty = useCallback(() => {
		return
	})

	return (
		<Dialog open={isOpen} onBackdropClick={executeOnClose} maxWidth="md">
			<DialogContent>
				<Grid container justify="center" spacing={16}>
					<Grid item xs={10}>
						<Form onSubmit={submitFormWithEmpty} className={classes.form}>
							<InputSelectOnChange
								name="choosePdp"
								label={t('user:ChoosePdp')}
								value={currentPdp}
								onChange={executeOnChange}
							>
								{companyPdp.map((pdp) => {
									return (
										<SelectMenuItem key={pdp.id} value={pdp.id}>
											{pdp.nameInformation}
										</SelectMenuItem>
									)
								})}
							</InputSelectOnChange>
						</Form>
					</Grid>
					<Grid item>
						<Button
							key={uuid_v4()}
							onClick={executeOnSubmit(link.REPORTS_ACTIVITY_PHASES)}
							variant="contained"
							color="secondary"
							disabled={loading}
						>
							{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
							<GetApp /> {t('reports:ActivityPhase')}
						</Button>
					</Grid>
					<Grid item>
						<Button
							key={uuid_v4()}
							onClick={executeOnSubmit(link.REPORTS_SPECIFIC_ACTIVITY_PHASE)}
							variant="contained"
							color="secondary"
							disabled={loading}
						>
							{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
							<GetApp /> {t('reports:ParticularyActivityPhase')}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

export default ModalActivityPhase
