import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import {
	uploadOperationnalModes,
	upsertCompanyRecordOperationalMode,
} from '../../containers/DataContainer'
import { getFilesToByteArray } from '../../utils/File'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputMultipleFiles from '../Form/input/InputMultipleFiles'
import SlidingForm from '../Form/SlidingForm'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
	},
	rightIcon: {
		marginLeft: theme.spacing.unit,
		color: theme.palette.primary.main,
	},
	iconSmall: {
		fontSize: 20,
	},
	typographyRoot: {
		color: 'white',
		fontSize: 17,
		marginTop: 20,
		marginBottom: 15,
	},
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},
	charactersAllowed: {
		margin: '20px 0',
		color: '#fff',
	},
}))

function OperationnalModeForm({ open, close, data }) {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const [files, setFiles] = useState([])
	const [loader, setLoader] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()

	const onFormSubmit = () => {
		if (files) upload(files)
	}

	const upload = (listFiles) => {
		getFilesToByteArray(listFiles, function (response) {
			const formData = new FormData()
			if (response[0]) {
				setLoader(true)

				let newFilesCount = 0

				if (data.companyRecordId && data.listProcedure) {
					for (newFilesCount; newFilesCount < response.length; newFilesCount++) {
						const element = response[newFilesCount]
						formData.append(`files[${newFilesCount}].value`, element, element.name)
					}

					for (let index = 0; index < data.listProcedure.length; index++) {
						formData.append(
							`files[${index + newFilesCount}].dateAdded`,
							data.listProcedure[index].dateAdded,
						)
						formData.append(
							`files[${index + newFilesCount}].fileName`,
							data.listProcedure[index].fileName,
						)
						formData.append(`files[${index + newFilesCount}].path`, data.listProcedure[index].path)
						formData.append(
							`files[${index + newFilesCount}].username`,
							data.listProcedure[index].username,
						)
					}

					upsertCompanyRecordOperationalMode(
						cookies[PLATFORM_ID_COOKIE],
						data.companyRecordId,
						formData,
						function (res, error) {
							setLoader(false)
							if (res) {
								data.updateData()
								enqueueSnackbar(t('OperationnalModeAdded'), {
									variant: 'info',
								})
								close()
							}
							if (error) {
								enqueueSnackbar(t('AuthorizedFormat'), { variant: 'error' })
							}
						},
					)
				} else {
					formData.append(`value`, response[0], response[0].name)
					uploadOperationnalModes(data.companyID, formData, function (res, error) {
						setLoader(false)
						if (res) {
							enqueueSnackbar(t('YouHaveJustDownloadedThisFile'), { variant: 'success' })
							data.setRefresh(true)
							close()
						}
						if (error) {
							enqueueSnackbar(t('AuthorizedFormat'), { variant: 'error' })
						}
					})
				}
			} else {
				enqueueSnackbar(t('file_name_invalid'), { variant: 'error' })
			}
			setLoader(false)
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('OperationnalModes')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onFormSubmit}>
				<Typography
					component="p"
					classes={{
						root: classes.typographyRoot,
					}}
				>
					{t('user:joinFile')}
					<Typography component="span" className={classes.charactersAllowed}>
						{t('file_characters_allowed')}
					</Typography>
				</Typography>
				<InputMultipleFiles label={t('user:upload')} name="upload" setFiles={setFiles} />
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>
								{t('user:UploadInProgress')}
							</Typography>
						</div>
					</>
				)}
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}
OperationnalModeForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default OperationnalModeForm
