import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { changeStateOpeningRequest } from '../../containers/DataContainer'
import { required } from '../../utils/VerifyFields'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputDate from '../Form/input/InputDate'
import SlidingForm from '../Form/SlidingForm'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	typographyRoot: {
		color: 'white',
		fontSize: 17,
	},
	typographyFirst: {
		color: 'white',
		fontSize: 17,
		paddingTop: 20,
	},
	inputSelectRoot: {
		marginTop: '25px',
	},
	textArea: {
		marginTop: '25px',
		minHeight: '95px',
	},
	labelTitle: {
		fontSize: 17,
		color: theme.palette.primary.main,
		marginBottom: 20,
		marginTop: 20,
	},
}))

const AcceptOpenningRequestForm = ({ open, close, data }) => {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()

	const onSubmit = (values) => {
		//Test if the date is before the today's date
		if (moment(values.date).diff(new Date(), 'day') < 0) {
			enqueueSnackbar(t('error') + ' : ' + t('date_anterior_error'), { variant: 'error' })
			return
		}
		let requestData = {
			platformId: cookies[PLATFORM_ID_COOKIE],
			companyId: data.companyId,
			companyStateId: 2,
			expiration: values.date,
		}
		changeStateOpeningRequest(requestData, function (res, error) {
			if (res) {
				enqueueSnackbar(t('TheRequestHasBeenAccepted'), { variant: 'info' })
				data.setUpdate(!data.update)
				close()
			}
			if (error) {
				if (Array.isArray(error.data)) {
					error.data.forEach((err) => {
						enqueueSnackbar(t('error') + ` ${error.status} : ${t(err.code)}`, {
							variant: 'error',
						})
					})
					return
				} else if (error.data) {
					enqueueSnackbar(t('error') + ` ${error.status} : ${t(error.data.code)}`, {
						variant: 'error',
					})
					return
				}
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('AcceptOpeningRequest')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{ date: new Date() }}>
				<Typography variant="h4" classes={{ root: classes.labelTitle }}>
					{t('EndDateOfApproval')}
				</Typography>
				<InputDate name="date" validate={required} delayOptions={{ disablePast: true }}></InputDate>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default AcceptOpenningRequestForm
