import React, { useState } from 'react'
import { Field } from 'react-final-form'
import 'moment/locale/fr'
import { InputAdornment, Icon } from '@material-ui/core'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

moment.locale('fr')

// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#ffffff',
		color: theme.palette.secondary.main,
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
	inputSize: {
		padding: '22px 12px 4px!important',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
	size: {
		display: 'block',
	},
}))

function InputDate({ name, label, readOnly, classname, disableLine, minDate, maxDate, ...props }) {
	// STYLES
	const classes = useStyles()
	const [selectedDate, handleDateChange] = useState(new Date())
	const { t } = useTranslation([ 'validateData' ])

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="fr" moment={moment}>
			<Field
				name={name}
				render={({ input, meta }) => (
					<InlineDatePicker
						label={label}
						className={classNames(classes.size, classname)}
						disabled={readOnly}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon>calendar_today</Icon>
								</InputAdornment>
							),
							...props,
							className: classes.inputRoot,
							inputProps: {
								className: classes.inputSize,
							},
							disableUnderline: disableLine,
						}}
						InputLabelProps={{
							classes: {
								root: classes.labelRoot,
								focused: classes.labelFocused,
							},
						}}
						value={selectedDate}
						error={false}
						helperText={null}
						minDate={minDate}
						minDateMessage={t('minDate')}
						maxDate={maxDate}
						maxDateMessage={t('maxDate')}
						onChange={handleDateChange}
						onInputChange={(e) => {
							handleDateChange(e.target.value)
						}}
						variant="filled"
						format="DD/MM/YYYY"
						mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
						{...input}
					/>
				)}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default InputDate
