/**
 * @name HandlingErrorMessages
 * @description Retrieves all error codes returned by the API and processes the returns
 * @param {Array | Object} errorRes
 * @param {Function} process
 */
export function HandlingErrorMessages(errorRes, process) {
	if (Array.isArray(errorRes)) {
		for (const { code } of errorRes) {
			process(code)
		}
	} else if (errorRes.errors) {
		const errors = errorRes.errors
		Object.keys(errors).forEach(code => {
			process(code)
		})
	}
}
