import { Button, FormControl, Grid, Paper, TextField, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router-dom'
import { FIRST_NAME_COOKIE, LAST_NAME_COOKIE, PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	getAccountByTypePlatform,
	getPdPInformations,
	getPdPNumberSuggestion,
	getRestrictedPdPDuration,
	setPdPInfosResttrictedPdP,
} from '../../../containers/DataContainer'
import { typePdP } from '../../../pages/PdP/PreventionPlan'
import { HandlingErrorMessages } from '../../../utils/Errors'
import { required } from '../../../utils/VerifyFields'
import Form from '../../Form/Form'
import InputDate from '../../Form/inputFullPage/InputDate'
import InputTextArea from '../../Form/inputFullPage/InputTextArea'
import InputYear from '../../Form/inputFullPage/InputYear'
import PropTypes from 'prop-types'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	body: {
		fontSize: '1em',
		padding: 15,
		color: theme.palette.secondary.main,
		paddingLeft: 0,
	},
	dateText: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'bottom',
		paddingTop: 0,
		paddingBottom: 0,
	},
	date: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'bottom',
		width: 155,
		marginRight: 5,
	},
	inputSelect: {
		width: '70%',
		display: CSS_VALUES.INLINE_BLOCK,
		margin: '1%',
		verticalAlign: 'middle',
	},
	bodyDE: {
		display: CSS_VALUES.INLINE_BLOCK,
		width: '30%',
		paddingLeft: 5,
	},
	flexBox: {
		display: 'flex',
	},
	textArea: {
		marginTop: 20,
		marginRight: 20,
		[theme.breakpoints.down('sm')]: {
			marginRight: 0,
		},
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	dataGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: CSS_VALUES.INLINE_BLOCK,
		borderRadius: 5,
		marginTop: 15,
		marginRight: 10,
		paddingBottom: 10,
	},
	categoryTitle: {
		transform: 'translateY(-18px)',
		position: 'absolute',
		backgroundColor: theme.palette.primary.main,
		padding: 8,
		paddingTop: 0,
		color: theme.palette.secondary.main,
	},
	inlineBlock: {
		display: CSS_VALUES.INLINE_BLOCK,
	},
	inspection: {
		marginTop: 10,
	},
	radio: {
		color: theme.palette.secondary.main,
	},
	pdpNumber: {
		paddingRight: 5,
	},
	inputYear: {
		transform: 'translateY(-2.5px)',
		width: 70,
	},
	numberPDP: {
		width: 45,
		transform: 'translateY(-5px)',
		marginLeft: 5,
	},
	numberInput: {
		color: theme.palette.secondary.main,
		paddingBottom: 4,
	},
	textType: {
		display: CSS_VALUES.INLINE_BLOCK,
		marginLeft: 'auto',
	},
	companyGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: 'block',
		borderRadius: 5,
		marginTop: 40,
		marginRight: 10,
		paddingBottom: 10,
		maxWidth: 700,
	},
	companyText: {
		display: CSS_VALUES.INLINE_BLOCK,
		verticalAlign: 'middle',
		paddingTop: 0,
		paddingBottom: 0,
		width: 120,
	},
	addContactIcon: {
		width: '1.8em',
		height: '1.8em',
		color: theme.palette.secondary.main,
		verticalAlign: 'middle',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	paper: {
		marginTop: theme.spacing.unit,
		padding: theme.spacing.unit,
	},
	groupCompany: {
		marginTop: 20,
	},
	name: {
		width: 'auto',
	},
}))

/**
 * Shows the form to create a restricted prevention plan
 * @returns {JSX.Element} React stack to render
 */
function GeneralInformationsRestrictedPdPForm({
	buttonsStyle,
	pdpId,
	isReadOnly,
	isNotCreate,
	revisionNumber,
	setPdpId,
	setRevisionNumber,
	setTypePdP,
	setCompanyId,
	setCompanyRecordId,
	companyId,
	companyRecordId,
	setPdPNumber,
	setPdpState,
	nextStep,
	currentPdPType,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'pdp'])
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const todayDate = moment()
	const [year, setYear] = useState(todayDate.year())
	const cancelDraft = false
	const [initialValues, setInitialValues] = useState({
		start: todayDate,
		end: todayDate,
		inspection: todayDate,
		numberPDP: '001',
		type: currentPdPType.value,
		year: todayDate,
	})

	const [redirect, setRedirect] = useState(false)
	const [isSubmit, setIsSubmit] = useState(false)
	const [principal, setPrincipal] = useState(undefined)
	const [dateErrMsg, setDateErrMsg] = useState('')

	const [restrictedPdPDuration, setRestrictedPdPDuration] = useState(400) // Default set to 400 in specifications

	useEffect(() => {
		if (currentPdPType.id !== 3) return

		getRestrictedPdPDuration(cookies[PLATFORM_ID_COOKIE], function (res, error) {
			if (error) {
				console.error(error)
			}
			if (res) {
				setRestrictedPdPDuration(res.data)
			}
		})
	}, [])

	useEffect(() => {
		if (pdpId) return
		getPdPNumberSuggestion(cookies[PLATFORM_ID_COOKIE], 3, year, function (res, error) {
			if (error) {
				console.error(error)
			}
			if (res) {
				setInitialValues({
					...initialValues,
					numberPDP: res.data,
				})
			}
		})
	}, [year])

	const fetchAccountsByPlatform = (principalId) => {
		getAccountByTypePlatform(cookies[PLATFORM_ID_COOKIE], 3 /*DO ID*/, function (res, err) {
			if (err || !res) return /* ERROR */
			/* SUCCESS */
			let data = res.data
			let resultFiltered = data.filter((dt) => (dt.id === principalId ? dt : null))[0]
			setPrincipal(resultFiltered)
		})
	}

	useEffect(() => {
		if (!pdpId) return
		getPdPInformations(
			cookies[PLATFORM_ID_COOKIE],
			pdpId,
			revisionNumber,
			function (response, error) {
				if (error) {
					console.error(error)
				}
				if (response) {
					switch (response.data.pdPType) {
						case 1:
							setTypePdP(typePdP.PERMANENT)
							break
						case 2:
							setTypePdP(typePdP.SPECIFIC)
							break
						case 3:
							setTypePdP(typePdP.RESTRICTED)
							setCompanyId(response.data.companyId)
							setCompanyRecordId(response.data.companyRecordId)
							break
						default:
							break
					}

					let principalId = response.data.principalId
					let result = response.data

					setInitialValues({
						...initialValues,
						start: result.rpdDebut,
						end: result.rpdFin,
						operationTitle: result.rpdLibelle,
						operationAreasDescription: result.rpdDescriptionLieux,
						numberPDP: result.pdpNumero.substr(-3),
						reason: result.rdpMotif,
						pdpArret: false,
					})
					setPdPNumber(result.pdpNumero)
					setPdpState(result.pdPState)
					setYear(result.pdpNumero.substr(1, 4))
					fetchAccountsByPlatform(principalId)
				}
			},
		)
	}, [companyId, companyRecordId])

	const validate = (values) => {
		let errors = {}
		if (!values.year.year()) errors.year = t('pdp:year')

		if (values.start.isAfter) {
			if (values.start.isAfter(values.end, 'day')) {
				errors.date = 'incorrect'
				setDateErrMsg(t('pdp:incorrectDate'))
			} else setDateErrMsg('')
		}

		return errors
	}

	const onSubmit = (values) => {
		let formData = {
			number: values.numberPDP,
			operationTitle: values.operationTitle,
			pdPType: currentPdPType.id,
			operationAreasDescription: values.operationAreasDescription,
			validityStartDate: values.start,
			validityEndDate: values.end,
			preInspectionDate: values.inspection,
			revision: 0,
			year: values.year.year(),
			shouldValidate: !isNotCreate,
			reason: values.reason,
		}
		if (pdpId) {
			formData = {
				...formData,
				id: pdpId,
			}
		}

		setPdPInfosResttrictedPdP(cookies[PLATFORM_ID_COOKIE], formData, function (res, error) {
			if (error && error.data) {
				HandlingErrorMessages(error.data, (code) => {
					enqueueSnackbar(t('pdp:' + code), { variant: 'error' })
				})
			}
			if (res) {
				setPdpId(res.data.pdPId)
				setRevisionNumber(res.data.revisionNumber)
				setIsSubmit(true)
				enqueueSnackbar(t('pdPHasBeenCreated'), { variant: 'info' })
			}
		})
	}

	useEffect(() => {
		if (pdpId && isSubmit) {
			setRedirect(true)
			return
		}
		setRedirect(false)
	}, [pdpId, isSubmit])

	return (
		<>
			{redirect && <Redirect to={'/preventionPlan/' + pdpId + '/' + revisionNumber} />}
			{cancelDraft && <Redirect to={'/followPdp'} />}
			<Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
				{cancelDraft && <Redirect to={'/followPdp'} />}
				<Typography variant="h3" classes={{ root: classes.title }}>
					{t('GeneralInformations')}
				</Typography>
				<hr className={classes.hrRoot} />
				<Paper className={classes.paper}>
					<div>
						<div className={classes.flexBox}>
							<Typography variant="body1" className={classNames(classes.body, classes.pdpNumber)}>
								{t('preventionPlanNo')} {currentPdPType.value}
							</Typography>
							<InputYear
								name="year"
								classes={{ root: classes.inputYear }}
								disableLine={true}
								onChange={(e) => {
									setYear(e.year())
								}}
								readOnly={isReadOnly}
								placeholder={t('pdp:year')}
								defaultValue={year}
							/>
							<Typography variant="body1" className={classNames(classes.body, classes.inlineBlock)}>
								-
							</Typography>
							<Field
								name="numberPDP"
								validate={required}
								render={({ input }) => (
									<FormControl margin="normal" {...input} className={classes.numberPDP}>
										<TextField
											type="number"
											InputProps={{
												disableUnderline: true,
												inputProps: { min: 0, max: 999, className: classes.numberInput },
											}}
											{...input}
										/>
									</FormControl>
								)}
							/>
							{revisionNumber && revisionNumber > 0 && (
								<Typography variant="body1" classes={{ root: classes.body }}>
									-&nbsp;&nbsp;&nbsp;{revisionNumber || '00'}
								</Typography>
							)}
							<Typography variant="body1" className={classNames(classes.body, classes.textType)}>
								{t('type')} : {t('RestrictedPdP')}
							</Typography>
							<Typography variant="body1" className={classNames(classes.body, classes.textType)}>
								{t('DateCreation')} : {moment(initialValues.start).format('DD/MM/YYYY')}
							</Typography>
						</div>
					</div>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1" className={classes.body}>
						{t('restrictedPdpMessage', { duration: restrictedPdPDuration })}
					</Typography>
				</Paper>
				<Paper className={classes.paper}>
					<Typography variant="body1" className={classes.body}>
						{t('principal')} :{' '}
						{principal ? (
							<>
								{principal.firstName} {principal.lastName}
							</>
						) : (
							<>
								{cookies[FIRST_NAME_COOKIE]} {cookies[LAST_NAME_COOKIE]}
							</>
						)}
					</Typography>
					<div className={classes.dataGroup}>
						<Typography variant="body1" classes={{ root: classes.categoryTitle }}>
							{t('validity')}
						</Typography>
						<InputDate disableLine={true} label="Début" name="start" classname={classes.date} />
						<InputDate disableLine={true} label="Fin" name="end" classname={classes.date} />
					</div>
					<div className={classNames(classes.inlineBlock, classes.inspection)}>
						<Typography variant="body1" className={classNames(classes.body, classes.dateText)}>
							{t('preliminaryInspection')} :
						</Typography>
						<InputDate name="inspection" classname={classes.date} />
					</div>
					<Typography variant="caption" style={{ color: 'red' }}>
						{dateErrMsg}
					</Typography>
					<InputTextArea
						name="operationAreasDescription"
						label={t('operationAreasDescription')}
						classes={{ root: classes.textArea }}
						validate={required}
					/>

					<InputTextArea
						name="reason"
						label={t('reason')}
						classes={{ root: classes.textArea }}
						validate={required}
					/>
					<InputTextArea
						name="operationTitle"
						label={t('operationTitle')}
						classes={{ root: classes.textArea }}
						validate={required}
					/>
				</Paper>
				<div className={buttonsStyle.root}>
					{pdpId ? (
						<Grid container direction="row" justify="space-between" alignItems="center">
							{/* <Grid container item xs={4} justify="center">
                <Button className={buttonsStyle.previous} onClick={previousStep}>
                  {t("previous")}
                </Button>
              </Grid> */}
							<Grid container item xs={4} justify="center">
								<Button className={buttonsStyle.next} onClick={nextStep}>
									{t('next')}
								</Button>
							</Grid>
						</Grid>
					) : (
						<Grid container direction="row" justify="space-between" alignItems="center">
							<Grid container item xs={4} justify="center">
								<Button type="submit" className={buttonsStyle.next}>
									{t('completeStep')}
								</Button>
							</Grid>
						</Grid>
					)}
				</div>
			</Form>
		</>
	)
}
GeneralInformationsRestrictedPdPForm.propTypes = {
	buttonsStyle: PropTypes.object,
	pdpId: PropTypes.string,
	isReadOnly: PropTypes.bool,
	isNotCreate: PropTypes.bool,
	revisionNumber: PropTypes.string,
	setPdpId: PropTypes.func,
	setRevisionNumber: PropTypes.func,
	setTypePdP: PropTypes.func,
	setCompanyId: PropTypes.func,
	setCompanyRecordId: PropTypes.func,
	companyId: PropTypes.number,
	companyRecordId: PropTypes.number,
	setPdPNumber: PropTypes.func,
	setPdpState: PropTypes.func,
	nextStep: PropTypes.func,
	currentPdPType: PropTypes.object,
}

export default GeneralInformationsRestrictedPdPForm
