import {
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	MenuItem,
	OutlinedInput,
	Paper,
	Radio,
	RadioGroup,
	Select,
	Switch,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import Form from '../../components/Form/Form'
import { makeStyles } from '@material-ui/styles'
import { CHEMICAL_NOTIFICATION, DANGER_PHRASES, GETPLATFORMS } from '../../constants/url'
import api from '../../utils/Requester'
import i18next from 'i18next'
import { bindParams } from '../../utils/Link'

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: 18,
		display: 'inline-flex',
		fontWeight: 500,
	},
	formControl: {
		marginLeft: 16,
	},
	formControlSelect: {
		marginLeft: 16,
		minWidth: 200,
		maxWidth: 300,
	},
	marginAll: {
		marginTop: '15px',
		marginBottom: '15px',
		marginRight: '20px',
		marginLeft: '20px',
	},
}))

function HygienistNotification() {
	const classes = useStyles()
	const { t } = useTranslation()
	const [cookies] = useCookies()
	const { enqueueSnackbar } = useSnackbar()
	const [formField, setFormField] = useState({
		notifIsActive: false,
		activationCondition: '',
		notifOnlyCMR: false,
		notifDangerPhraseIds: [],
	})
	const [dangerPhrases, setDangerPhrases] = useState([])
	const [language] = useState(() => {
		switch (i18next.language) {
		case 'fr':
			return 1
		case 'en':
			return 2
		default:
			return 1
		}
	})

	const handleChange = (event) => {
		const target = event.target
		const value = target.type === 'checkbox' ? target.checked : target.value
		const name = target.name

		setFormField({
			...formField,
			[name]: value,
		})
	}

	const submitForm = () => {
		const params = {
			chemicalNotifIsActive: formField.notifIsActive,
			chemicalActivationCondition: formField.activationCondition,
			chemicalNotifOnlyCmr: formField.notifOnlyCMR,
			chemicalNotifDangerPhraseIds: formField.notifDangerPhraseIds,
		}
		api.post(bindParams(CHEMICAL_NOTIFICATION, cookies[PLATFORM_ID_COOKIE]), params).then((res) => {
			const { ok } = res
			if (!ok) return enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
			return enqueueSnackbar(t('user:hygienist_notification_form_success'), { variant: 'success' })
		})
	}

	useEffect(() => {
		api.get(GETPLATFORMS).then((res) => {
			const { data, ok } = res
			if (!ok) return enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })

			const plftm = data.find((p) => `${p.platformId}` === cookies[PLATFORM_ID_COOKIE])
			setFormField({
				notifIsActive: plftm.chemicalNotifIsActive,
				activationCondition: `${plftm.chemicalActivationCondition}`,
				notifOnlyCMR: plftm.chemicalNotifOnlyCmr,
				notifDangerPhraseIds: plftm.chemicalNotifDangerPhraseIds,
			})
		})
	}, [enqueueSnackbar, t])

	useEffect(() => {
		api.get(DANGER_PHRASES, { p_LangId: language }).then((res) => {
			const { data, ok } = res
			if (!ok) return enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
			setDangerPhrases(data)
		})
	}, [enqueueSnackbar, language, t])

	return (
		<>
			<HeadTitle title={t('user:hygienist_notification_title')} />
			<Paper>
				<Form onSubmit={submitForm}>
					<Grid container>
						<Grid item xs={12}>
							<FormControlLabel
								classes={{
									label: classes.label,
								}}
								control={
									<Switch
										size="normal"
										name="notifIsActive"
										onClick={handleChange}
										checked={formField.notifIsActive}
									/>
								}
								label={t('user:hygienist_notification_form_notifications_on_dangers_sentences')}
								labelPlacement="start"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl className={classes.formControl}>
								<FormLabel className={classes.label}>
									<span style={{ color: 'black' }}>
										{t('user:hygienist_notification_form_activation_to')}
									</span>
								</FormLabel>
								<RadioGroup
									name="activationCondition"
									value={formField.activationCondition}
									onChange={handleChange}
								>
									<FormControlLabel
										value={'false'}
										control={<Radio />}
										className={classes.formControl}
										label={t('user:hygienist_notification_form_activation_to_DE_submit')}
									/>
									<FormControlLabel
										value={'true'}
										control={<Radio />}
										className={classes.formControl}
										label={t('user:hygienist_notification_form_activation_to_DE_validation')}
									/>
								</RadioGroup>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControlLabel
								classes={{
									label: classes.label,
								}}
								control={
									<Switch
										size="normal"
										name="notifOnlyCMR"
										onClick={handleChange}
										checked={formField.notifOnlyCMR}
									/>
								}
								label={t('user:hygienist_notification_form_notifications_on_CMR')}
								labelPlacement="start"
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl>
								<FormControlLabel
									classes={{
										label: classes.label,
									}}
									control={
										<Select
											name="notifDangerPhraseIds"
											multiple
											value={formField.notifDangerPhraseIds}
											onChange={handleChange}
											input={<OutlinedInput labelWidth={0} />}
											className={`${classes.formControlSelect}`}
										>
											{dangerPhrases.map((dangerPhrase) => (
												<MenuItem key={dangerPhrase.phdId} value={dangerPhrase.phdId}>
													{`${dangerPhrase.lpdCodification} - ${dangerPhrase.lpdLibelle}`}
												</MenuItem>
											))}
										</Select>
									}
									label={t('user:hygienist_notification_form_danger_phrases')}
									labelPlacement="start"
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Grid container justify="center">
						<Grid item>
							<Button
								type="submit"
								className={classes.marginAll}
								variant="contained"
								color="secondary"
							>
								{t('user:hygienist_notification_form_save')}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Paper>
		</>
	)
}
export default HygienistNotification
