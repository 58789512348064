import React, { useEffect, useState } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../components/NewDataTable/NewDataTable'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { ACTIVITYPHASESMANAGER } from '../../constants/url'
import { useEditActivityPhases } from '../../containers/FormContainer'
import GetApp from '@material-ui/icons/GetApp'
import fileDownload from 'js-file-download'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import { IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { downloadFile } from '../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'
import { COLUMNS_ACTIVITY_PHASES_MANAGEMENT, getIndexOfColumn } from '../../utils/Datatable'

const useStyles = makeStyles((theme) => ({
	icon: {
		color: theme.palette.secondary.main + ' !important',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	addButton: {
		position: 'fixed',
		right: theme.spacing.unit,
		bottom: theme.spacing.unit,
	},
	yes: {
		color: theme.palette.yes.main,
	},
	no: {
		color: theme.palette.no.main,
	},
}))

function ActivityPhasesManagement() {
	const localLang = localStorage.getItem('i18nextLng')
	const [lang, setLang] = useState()
	const { t } = useTranslation(['user', 'activityPhaseManagement'])
	const [cookies] = useCookies()
	const classes = useStyles()
	const [datatable, setDatatable] = useState({ columns: [], data: [], options: {} })
	const [selectedRowData, setSelectedRowData] = useState()
	const { enqueueSnackbar } = useSnackbar()
	const [refresh, setRefresh] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	//#region USEEFFECT
	useEffect(() => {
		if (lang) getAllActivityPhases()
	}, [lang])
	useEffect(() => {
		setLang(localLang)
	}, [localLang])
	useEffect(() => {
		getAllActivityPhases()
	}, [refresh])
	//#endregion USEEFFECT

	//#region RECOVERY
	const recoveryAllActivityPhases = (res) => {
		const { data, ok } = res

		if (!ok) return enqueueSnackbar(t('view_render_failed'), { variant: 'error' })

		if (data) {
			setDatatable(data)
			setIsLoading(false)
		}
	}
	//#endregion RECOVERY

	//#region GETS
	const getAllActivityPhases = () => {
		api
			.post(bindParams(ACTIVITYPHASESMANAGER, cookies[PLATFORM_ID_COOKIE]), {
				nbItemsAsked: 50,
				pageNumber: 1,
			})
			.then(recoveryAllActivityPhases)
	}
	//#endregion GETS

	//#region ACTIONS
	const downloadDocument = (file) => {
		downloadFile(file, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName.replace(/"/gi, ''))
			}
		})
	}
	//#endregion ACTIONS

	//#region DATATABLES
	const actionsColumn = {
		name: t('activityPhaseManagement:actions'),
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}

	const customColumns = [
		{
			name: 'isActive',
			component: (value) => {
				if (value) return <DoneIcon classes={{ root: classes.yes }} />
				return <ClearIcon classes={{ root: classes.no }} />
			},
		},
		{
			name: 'pacEstInterditPdpPermanent',
			component: (value) => {
				if (value) return <DoneIcon classes={{ root: classes.yes }} />
				return <ClearIcon classes={{ root: classes.no }} />
			},
		},
		{
			name: 'pacEstInterditPdpRestreint',
			component: (value) => {
				if (value) return <DoneIcon classes={{ root: classes.yes }} />
				return <ClearIcon classes={{ root: classes.no }} />
			},
		},
		{
			name: 'isRequired',
			component: (value) => {
				if (value) return <DoneIcon classes={{ root: classes.yes }} />
				return <ClearIcon classes={{ root: classes.no }} />
			},
		},
		{
			name: 'pacDocumentCheminComplet',
			component: (value) => {
				return (
					<GetApp
						classes={{ root: classes.icon }}
						onClick={() => {
							downloadDocument(value)
						}}
					/>
				)
			},
		},
		{
			name: t('activityPhaseManagement:actions'),
			component: (_value, tableMeta) => {
				return (
					<>
						<IconButton
							onClick={() => {
								const dataObject = {
									phaseId:
										tableMeta.rowData[
											getIndexOfColumn(datatable.columns, COLUMNS_ACTIVITY_PHASES_MANAGEMENT.PACID)
										],
									order:
										tableMeta.rowData[
											getIndexOfColumn(datatable.columns, COLUMNS_ACTIVITY_PHASES_MANAGEMENT.ORDER)
										],
									phaseLabel:
										tableMeta.rowData[
											getIndexOfColumn(datatable.columns, COLUMNS_ACTIVITY_PHASES_MANAGEMENT.LPANOM)
										],
									isActive:
										tableMeta.rowData[
											getIndexOfColumn(
												datatable.columns,
												COLUMNS_ACTIVITY_PHASES_MANAGEMENT.ISACTIVE,
											)
										],
									isForbiddenPermanent:
										tableMeta.rowData[
											getIndexOfColumn(
												datatable.columns,
												COLUMNS_ACTIVITY_PHASES_MANAGEMENT.ISFORBIDDENPERMANENT,
											)
										],
									isForbiddenRestricted:
										tableMeta.rowData[
											getIndexOfColumn(
												datatable.columns,
												COLUMNS_ACTIVITY_PHASES_MANAGEMENT.ISFORBIDDENRESTRICTED,
											)
										],
									isRequired:
										tableMeta.rowData[
											getIndexOfColumn(
												datatable.columns,
												COLUMNS_ACTIVITY_PHASES_MANAGEMENT.ISREQUIRED,
											)
										],
								}
								setSelectedRowData(dataObject)
								open()
							}}
						>
							<EditIcon />
						</IconButton>
					</>
				)
			},
		},
	]
	//#endregion DATATABLES

	//#region MISCELLANEOUS FUNCTIONS
	const refreshAll = () => {
		setRefresh(!refresh)
	}
	const { formular, open } = useEditActivityPhases({ refresh: refreshAll, datas: selectedRowData })
	//#endregion MISCELLANEOUS FUNCTIONS

	return (
		<>
			{formular}
			<HeadTitle title={t('activityPhaseManagement:alternatesManagementTitle')} />
			<DataTable
				title={t('activityPhaseManagement:alternatesManagementTitle')}
				columns={[...datatable.columns, actionsColumn]}
				data={datatable.data}
				options={datatable.options}
				customColumns={customColumns}
				isLoading={isLoading}
				customLabels={[
					{
						name: 'order',
						label: t('activityPhaseManagement:order'),
					},
					{
						name: 'lpaNom',
						label: t('activityPhaseManagement:name'),
					},
					{
						name: 'isActive',
						label: t('activityPhaseManagement:isActive'),
					},
					{
						name: 'pacEstInterditPdpPermanent',
						label: t('activityPhaseManagement:isforbiddenpermanent'),
					},
					{
						name: 'pacEstInterditPdpRestreint',
						label: t('activityPhaseManagement:isforbiddenrestreint'),
					},
					{
						name: 'isRequired',
						label: t('activityPhaseManagement:isrequired'),
					},
					{
						name: 'pacDocumentCheminComplet',
						label: t('activityPhaseManagement:filepath'),
					},
				]}
			/>
		</>
	)
}
export default ActivityPhasesManagement
