/*
If you want to create a simple title, you need to set an object in titlebar with this model : {type: "titleBar", data: { icon: "person", title: "title" }}
If you want to add a subtitle, you just need to add a subtitle in the data object.
Warning : if you use the MainLayout and you doesn't want to set a title, you need to send a empty object to the context. 
*/
import React, { createContext, useContext, useState } from 'react'

const TotalTitleBarContext = createContext()

function TotalTitleBarContextProvider(props) {
	const [titleBar, setTitleBar] = useState({})
	let values = { titleBar, setTitleBar }
	return (
		<TotalTitleBarContext.Provider value={values}>{props.children}</TotalTitleBarContext.Provider>
	)
}

function useTotalTitleBarContext() {
	return useContext(TotalTitleBarContext)
}

let TotalTitleBarContextConsumer = TotalTitleBarContext.Consumer

export { useTotalTitleBarContext, TotalTitleBarContextProvider, TotalTitleBarContextConsumer }
