import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Fab,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Delete from '@material-ui/icons/Delete'
import GetApp from '@material-ui/icons/GetApp'
import { makeStyles } from '@material-ui/styles'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import HeadTitle from '../../components/HeadTitle'
import { COMPANY_ID_COOKIE } from '../../constants/cookies'
import { useMatch } from '../../containers/AuthContainer'
import {
	deleteEnvironmentalAspect,
	downloadFile,
	getEnvironmentalAspects,
} from '../../containers/DataContainer'
import { useEnvironmentalAspectForm } from '../../containers/FormContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../utils/File'
import PropTypes from 'prop-types'
import Access from '../../containers/RoleContainer'
import ROLES from '../../constants/roles'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
		zIndex: 1,
	},
	table: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		marginBottom: '20px',
		boxShadow: '2px 3px 11px 0px rgba(0,0,0,0.35)',
	},
	icon: {
		color: theme.palette.secondary.main + ' !important',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	circularProgress: {
		color: theme.palette.secondary.main + ' !important',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	messageEmpty: {
		textAlign: 'center',
	},
}))

function EnvironmentalAspect({ match }) {
	const classes = useStyles()
	// eslint-disable-next-line no-unused-vars
	const { t } = useTranslation('user')
	const { setTitleBar } = useTotalTitleBarContext()
	const [listFiles, setListFiles] = useState(null)
	const [cookies] = useCookies()
	const [refresh, setRefresh] = useState(true)
	const { enqueueSnackbar } = useSnackbar()
	const [confirmPopUp, setConfirmPopUp] = useState({ open: false, path: null })
	const [loader, setLoader] = useState({})
	const { companyId, formatUrl } = useMatch(match, '/op-infos')
	const targetCompanyID = companyId != null ? companyId : cookies[COMPANY_ID_COOKIE]
	const EnvironmentalAspectForm = useEnvironmentalAspectForm({
		setRefresh: setRefresh,
		companyID: targetCompanyID,
	})

	const closePopUp = () => {
		setConfirmPopUp({ open: false, path: null })
	}

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: { icon: 'person', title: () => t('OperationnalData') },
		})
	}, [])

	useEffect(() => {
		if (!refresh) return
		getEnvironmentalAspects(targetCompanyID, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListFiles(res.data)
				setRefresh(false)
			}
		})
	}, [refresh])

	const downloadDocument = (file, i) => {
		setLoader({ [`${i}`]: true })
		downloadFile(file.path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				setLoader({ [`${i}`]: false })
				enqueueSnackbar(t('error')` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				setLoader({ [`${i}`]: false })
				fileDownload(res.data, fileName)
			}
		})
	}

	const deleteDocument = (path) => {
		deleteEnvironmentalAspect(targetCompanyID, path, function (res, error) {
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('TheFileHasBeenDeleted'), { variant: 'info' })
				setRefresh(true)
				closePopUp()
			}
		})
	}

	const OpenConfirmPopUp = () => {
		return (
			<Dialog
				open={confirmPopUp.open}
				onClose={closePopUp}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t('warning')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('doYouWantToDeleteThisFile')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePopUp} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteDocument(confirmPopUp.path)
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	const AddFile = () => {
		const newFile = () => {
			EnvironmentalAspectForm.open()
		}

		return (
			<Fab className={classes.fab} color="secondary" onClick={newFile}>
				<AddIcon />
			</Fab>
		)
	}

	return (
		<>
			{confirmPopUp.open && <OpenConfirmPopUp />}
			{EnvironmentalAspectForm.formular}
			<HeadTitle title={t('EnvironmentalAspects')} link={formatUrl} />
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>{t('date_add_or_update')}</TableCell>
						<TableCell>{t('user')}</TableCell>
						<TableCell>{t('nameOfTheFile')}</TableCell>
						<TableCell align="center">{t('download')}</TableCell>
						<Access
							accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
						>
							<TableCell align="center">{t('delete')}</TableCell>
						</Access>
					</TableRow>
				</TableHead>
				<TableBody>
					{listFiles !== null && listFiles.length > 0 ? (
						listFiles.map((file, i) => (
							<TableRow key={file.fileName}>
								<TableCell component="th" scope="row">
									{file.dateAdded}
								</TableCell>
								<TableCell component="th" scope="row">
									{file.username}
								</TableCell>
								<TableCell component="th" scope="row">
									{file.fileName}
								</TableCell>
								<TableCell component="th" scope="row">
									{!loader[`${i}`] && (
										<GetApp
											classes={{ root: classes.icon }}
											onClick={() => {
												downloadDocument(file, i)
											}}
										/>
									)}
									{loader[`${i}`] && <CircularProgress className={classes.circularProgress} />}
								</TableCell>
								<Access
									accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}
								>
									<TableCell component="th" scope="row">
										<Delete
											classes={{ root: classes.icon }}
											onClick={() => {
												setConfirmPopUp({ open: true, path: file.path })
											}}
										/>
									</TableCell>
								</Access>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell />
							<TableCell>
								<p className={classes.messageEmpty}>{t('YouHaveNoFiles')}</p>
							</TableCell>
							<TableCell />
						</TableRow>
					)}
				</TableBody>
			</Table>
			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}>
				<AddFile />
			</Access>
		</>
	)
}
EnvironmentalAspect.propTypes = {
	match: PropTypes.object,
}
export default EnvironmentalAspect
