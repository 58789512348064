import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Link } from '@material-ui/core'

//STYLES
const useStyles = makeStyles((theme) => ({
	card: {
		backgroundColor: '#746F66',
		color: '#ffffff',
		width: '100%',
		position: 'relative',
	},
	toComplete: {
		backgroundColor: '#4B96D1',
	},
	incomplete: {
		backgroundColor: '#F5960A',
	},
	refused: {
		backgroundColor: '#9D2235',
	},
	validated: {
		backgroundColor: '#72B84D',
	},
	approved: {
		backgroundColor: '#006946',
	},
	cardHeader: {
		color: '#ffffff',
	},
	avatar: {
		margin: 10,
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		backgroundColor: 'transparent',
		borderRadius: 0,
		width: '60px',
		height: '60px',
	},
	secureShield: {
		backgroundImage: 'url("/icons/secure-shield.png")',
	},
	center: {
		textAlign: 'center',
	},
	attachedCompany: {
		paddingTop: 20,
		paddingBottom: 30,
	},
	expiring: {
		boxShadow: '#9D2235 0px 0px 6px 4px',
	},
}))

/**
 * Supervision : card for PDP
 *
 * @param {string} title Title block
 * @param {string} subTitle Subtitle block
 * @param {string} status
 * @param {int} size Block size
 * @param {int} count Principal number
 * @param {int} validCompanyRecordNumber
 * @param {int} toValidateCompanyRecordNumber
 * @param {int} unsubmittedCompanyRecordNumber
 * @param {string} linkRedirect Link to redirect
 * @param {string} keyCard Unique key for card
 * @param {string} pdpName Title of the PdP
 * @param {string} principalName Name of the DO
 * @param {int} dwpToSend Number of DWP to transmit
 */
const PDPSuperCard = ({
	title,
	subTitle,
	status = 'default',
	size = 4,
	count = 0,
	validCompanyRecordNumber,
	toValidateCompanyRecordNumber,
	unsubmittedCompanyRecordNumber,
	pdpName,
	principalName,
	dwpToSend,
	linkRedirect,
	keyCard = 'def',
	expiring,
}) => {
	// STYLES
	const classes = useStyles()
	const { t } = useTranslation('user')

	return (
		<>
			<Grid key={keyCard} style={{ display: 'flex' }} item sm={12} md={size}>
				<Card
					className={classNames(classes.card, classes[status], { [classes.expiring]: expiring })}
				>
					<CardHeader
						avatar={<Avatar className={classNames(classes.avatar, classes['secureShield'])} />}
						action={
							<Link href={linkRedirect}>
								<IconButton color="inherit">
									<MoreVertIcon />
								</IconButton>
							</Link>
						}
						title={title}
						titleTypographyProps={{ color: 'inherit', variant: 'h5' }}
						subheader={pdpName}
						subheaderTypographyProps={{ color: 'inherit', variant: 'body1' }}
						className={classes.cardHeader}
					/>
					<CardContent>
						<Grid container spacing={16}>
							<Grid item xs={9}>
								<Typography component="span" variant="h6" color="inherit">
									{subTitle}
								</Typography>
								<Typography component="span" variant="h6" color="inherit">
									{`${t('pdp_principal')} : ${principalName}`}
								</Typography>
							</Grid>
						</Grid>
						<Grid container spacing={16}>
							<Grid item xs={3} className={classes.center}>
								<Typography component="h2" variant="h3" color="inherit">
									<strong>{count}</strong>
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<Typography
									component="h2"
									variant="h6"
									color="inherit"
									className={classes.attachedCompany}
								>
									{t('attachedCompany')}
								</Typography>
							</Grid>
							<Grid container item xs={12} justify="space-between">
								<Grid item xs={3}>
									<Typography align="center" component="h2" variant="h3" color="inherit">
										<strong>{validCompanyRecordNumber}</strong>
									</Typography>
									<Typography align="center" variant="subtitle1" color="inherit">
										{t('companyFilesValidated')}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography align="center" component="h2" variant="h3" color="inherit">
										<strong>{toValidateCompanyRecordNumber}</strong>
									</Typography>
									<Typography align="center" variant="subtitle1" color="inherit">
										{t('companyFilesToValidate')}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography align="center" component="h2" variant="h3" color="inherit">
										<strong>{unsubmittedCompanyRecordNumber}</strong>
									</Typography>
									<Typography align="center" variant="subtitle1" color="inherit">
										{t('companyFilesNotSubmitted')}
									</Typography>
								</Grid>
								<Grid item xs={3}>
									<Typography align="center" component="h2" variant="h3" color="inherit">
										<strong>{dwpToSend}</strong>
									</Typography>
									<Typography align="center" variant="subtitle1" color="inherit">
										{t('dwp_to_send')}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</>
	)
}

export default PDPSuperCard
