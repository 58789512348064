import React, { useEffect, useState } from 'react'
import SlidingForm from '../Form/SlidingForm'
import InputText from '../Form/input/InputText'
import FormIcon from '../Form/FormIcon'
import FormButton from '../Form/FormButton'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import Form from '../Form/Form'
import InputMail from '../Form/input/InputMail'
import { composeValidators } from '../../utils/Form'
import {
	required,
	nameSize,
	emailFormat,
	emailSize,
	loginSize,
	noSpace,
	noSpecialChar,
} from '../../utils/VerifyFields'
import { useSession } from '../../containers/SessionContainer'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
	size1: {
		width: '45%',
		marginRight: '10%',
		marginTop: '15px',
		marginBottom: '15px',
		display: 'inline-block',
	},
	size2: {
		width: '45%',
		marginTop: '15px',
		marginBottom: '15px',
		display: 'inline-block',
	},
	inputID: {
		width: '100%',
		marginTop: '15px',
		marginBottom: '15px',
	},
	validateRoot: {
		display: 'block',
		margin: '0 auto',
	},
}))

function AccountInfoForm({ open, close }) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()
	const { session, setSession } = useSession('signup')
	const [initalValues, setInitialValues] = useState({})

	const onSubmit = (values) => {
		let initialVal = {}

		if (!session) Object.assign(initialVal, { accountInfo: values })

		let tmpSession = JSON.parse(session)

		if (tmpSession) Object.assign(initialVal, { ...tmpSession, accountInfo: values })

		setSession(JSON.stringify(initialVal))
		close()
	}

	const validate = (values) => {
		const errors = {}

		if (values.email !== values.confirmEmail) {
			errors.confirmEmail = 'emailNotTheSame'
		}
		return errors
	}

	useEffect(() => {
		if (!session) return
		let tmpSession = JSON.parse(session)
		if (tmpSession && tmpSession.accountInfo) setInitialValues(tmpSession.accountInfo)
	}, [open, session])

	return (
		<SlidingForm
			direction="horizontal"
			open={open}
			title={t('InformationAboutAccount')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			onClose={close}
			submitLabel="Valider"
		>
			<Form onSubmit={onSubmit} initialValues={initalValues} validate={validate}>
				<InputText
					classes={{ root: classes.inputID }}
					name="login"
					label={t('login')}
					validate={composeValidators(required, loginSize, noSpace, noSpecialChar)}
				/>
				<InputText
					classes={{ root: classes.size1 }}
					name="lastname"
					label={t('lastname')}
					validate={composeValidators(required, nameSize)}
				/>
				<InputText
					classes={{ root: classes.size2 }}
					name="firstname"
					label={t('firstname')}
					validate={composeValidators(required, nameSize)}
				/>
				<InputMail
					classes={{ root: classes.size1 }}
					name="email"
					label={t('email')}
					validate={composeValidators(required, emailSize, emailFormat)}
				/>
				<InputMail
					classes={{ root: classes.size2 }}
					name="confirmEmail"
					label={t('confirmEmail')}
					validate={composeValidators(required, emailSize)}
				/>

				<FormButton classes={{ root: classes.validateRoot }} label={t('validate')} type="submit" />
			</Form>
		</SlidingForm>
	)
}
AccountInfoForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
}
export default AccountInfoForm
