import React, { useState, useEffect } from 'react'
import { Typography, Link } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { bindParams } from '../../utils/Link'
import CGUDialog from '../CG/CGUDialog'
import { VERSION_NUMBER } from '../../constants/versionNumber'

const useStyles = makeStyles((theme) => ({
	footer: {
		position: 'fixed',
		bottom: 0,
		width: '100%',
		textAlign: 'center',
		justify: 'center',
		padding: theme.spacing.unit,
		backgroundColor: theme.palette.primary.main,
	},
	mention: {
		color: 'black',
	},
	pointer: {
		cursor: 'pointer',
	},
}))

function TotalFooter() {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'version'])
	const [today, setToday] = useState(new Date())
	const [openDialog, setOpenDialog] = useState(false)
	const [legalCheckCookies, setLegalCheckCookies] = useState(false)

	return (
		<div className={classes.footer}>
			<Typography className={classes.mention} variant="caption">
				{`© ${today.getFullYear()} - TotalEnergies | `}
				<Link
					className={`${classes.mention} ${classes.pointer}`}
					onClick={() => {
						setLegalCheckCookies(false)
						setOpenDialog(true)
					}}
				>
					{t('user:footerText')}
				</Link>{' '}
				|{' '}
				<Link
					className={`${classes.mention} ${classes.pointer}`}
					onClick={() => {
						setLegalCheckCookies(true)
						setOpenDialog(true)
					}}
				>
					{t('user:footerPersonalDatas')}
				</Link>{' '}
				| {VERSION_NUMBER}
				<CGUDialog
					openDialog={openDialog}
					legalCheckCookies={legalCheckCookies}
					setOpenDialog={setOpenDialog}
					setLegalCheckCookies={setLegalCheckCookies}
				/>
			</Typography>
		</div>
	)
}

export default TotalFooter
