import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/MailOutlined'
import EditIcon from '@material-ui/icons/EditOutlined'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core'
import ROLES from '../../constants/roles'
import Access from '../../containers/RoleContainer'

// STYLES
const useStyles = makeStyles(theme => ({
	card: {
		minHeight: 275,
		position: 'relative',
		margin: '10px',
	},
	cardIcon: {
		position: 'absolute',
		top: 10,
		right: 10,
		cursor: 'mouse',
	},
	addCardIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70%',
	},
	addIcon: {
		height: '100%',
		width: '100%',
	},
	title: {
		fontSize: 27,
		color: theme.palette.secondary.main,
	},
	subtitle: {
		color: theme.palette.secondary.main,
		fontSize: 17,
	},
	separator: {
		width: '90%',
		height: 1,
		border: 'none',
		backgroundColor: '#d6d6d6',
	},
	icons: {
		color: 'black',
	},
	link: {
		color: 'black !important',
		textDecoration: 'none',
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	cardHeader: {
		paddingBottom: 5,
	},
}))
/**
 * Shows a Contact card
 * @param {int} size : Size of the card
 * @param {any} data : Object with the data
 * @returns {JSX.Element} React stack to render
 */
const DWPContactCard = ({ size, data, sendData, clicked }) => {
	const classes = useStyles()

	const UpdateCard = () => {
		let cardData = {
			id: data.id,
			lastName: data.lastName,
			firstName: data.firstName,
			igg: data.igg,
			email: data.email,
			preparerPermit: data.isPermitPreparator,
			isCompanyAdmin: data.isCompanyAdmin,
			isPermitAcceptator: data.isPermitAcceptator,
		}
		sendData(cardData)
		clicked()
	}

	return (
		<Grid item sm={12} md={5} lg={size}>
			<Card className={classes.card}>
				<MuiCardHeader
					title={
						<Typography className={classes.title} variant="h6">
							{data.firstName} {data.lastName}
						</Typography>
					}
					subheader={
						<Typography className={classes.subtitle} variant="h6">
							{data.igg}
						</Typography>
					}
					titleTypographyProps={{ color: 'inherit' }}
					className={classes.cardHeader}
				/>
				<hr className={classes.separator} />
				<CardContent>
					<List>
						<ListItem>
							<ListItemIcon>
								<MailIcon className={classes.icons} />
							</ListItemIcon>
							<ListItemText>
								<a
									className={classes.link}
									href={`mailto:${data.email}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.email}
								</a>
							</ListItemText>
						</ListItem>
					</List>
					<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER, ROLES.EXTCOMPADMIN, ROLES.EXTCOMP]}>
						<IconButton className={classes.cardIcon} aria-label="Edit" onClick={UpdateCard}>
							<EditIcon className={classes.icons} />
						</IconButton>
					</Access>
				</CardContent>
			</Card>
		</Grid>
	)
}

export default DWPContactCard
