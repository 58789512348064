import React, { useEffect, useState } from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { makeStyles } from '@material-ui/styles'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import {
	POST_REF_DOC_PREVIEW,
	POST_REF_DOC_PREVIEWINFO,
	GET_REF_DOCS_SIGNETS,
	GET_REF_DOCS_INFOS,
	DOC_TYPE,
	POST_REF_DOC_PUBLISH,
} from '../../constants/url'
import fileDownload from 'js-file-download'
import { Grid, Typography, Link, Button, MenuItem, CircularProgress } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import Form from '../../components/Form/Form'
import InputFile from '../../components/Form/input/InputFile'
import { InputSelectOnChange } from '../../components/Form/inputFullPage/InputSelect'
import SearchIcon from '@material-ui/icons/Search'
import LaunchIcon from '@material-ui/icons/Launch'
import SignetsForm from '../../components/newForms/RefDocSignetsForm'
import classNames from 'classnames'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import LANG from '../../constants/lang'
import moment from 'moment'
import {
	getFileToByteArray,
	getFileNameToHeaders,
	managedFileNotFoundError,
	isValidFile,
} from '../../utils/File'
import humanFileSize from '../../constants/coreConstants'
import { downloadFile } from '../../containers/DataContainer'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	titlecentered: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
	},
	container: {
		padding: theme.spacing.unit,
		paddingBottom: theme.spacing.unit * 10,
	},
	link: {
		textDecoration: 'underline',
		color: '#4B96D1',
	},
	inputFileAlign: {
		marginLeft: '25px',
		marginTop: '10px',
	},
	docContainer: {
		backgroundColor: theme.palette.primary.main,
		border: 'solid 1px ' + theme.palette.secondary.main,
		borderRadius: '5px',
	},
	alignLastModif: {
		marginLeft: '25px',
	},
	buttonProgress: {
		color: 'black',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	hand: {
		cursor: 'pointer',
	},
	bold: {
		fontWeight: 'bold',
	},
}))

function EditReferenceDocument({ match }) {
	const localLang = localStorage.getItem('i18nextLng')
	const { t } = useTranslation(['user', 'referenceDocumentManagement'])
	const [cookies] = useCookies(['platformId'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()

	const [documents, setDocuments] = useState([])
	const [docInfos, setDocInfos] = useState(null)
	const [isPreview, setPreview] = useState(false)
	const [signets, setSignets] = useState(false)
	const [signetsData, setSignetsData] = useState({ section: '', list: [] })
	const [selectedPreviewData, setSelectedPreviewData] = useState(null)
	const [filePrincipal, setFilePrincipal] = useState(null)
	const [otherFiles, setOtherFiles] = useState([])
	const [principal, setPrincipal] = useState(false)
	const [current, setCurrent] = useState()
	const [bodyFormat, setBodyFormat] = useState({})
	const [names, setNames] = useState([])
	const [tokenPublish, setTokenPublish] = useState(null)
	const [loading, setLoading] = useState(false)
	const [docName, setDocName] = useState()

	const preview = () => setPreview(true)
	const openSignetsForm = (docType) => {
		getSignets(docType)
		setSignets(true)
	}
	const closeSignetsForm = () => setSignets(false)

	const getLangId = () => LANG[localLang].id
	const docType = match.params.idrefdoc

	const BODY_FORMAT = {
		MAIN: 'mainTemplate',
		TEMPLATEPART: 'templatePart',
		COMPANYRECORD: 'companyRecordId',
		PDP: 'pdpId',
		REVISION: 'revisionNumber',
	}

	const TYPE_PDP = ['1', '2', '6']

	//#region USEEFFECT
	useEffect(() => {
		preview()
		getDocInfos(docType)
		getDocuments()
	}, [])

	useEffect(() => {
		if (Object.keys(bodyFormat).length) {
			if (!bodyFormat) return
			if (!bodyFormat[BODY_FORMAT.MAIN]) return

			if (
				!bodyFormat[BODY_FORMAT.COMPANYRECORD] &&
				!bodyFormat[BODY_FORMAT.PDP] &&
				!bodyFormat[BODY_FORMAT.REVISION]
			)
				return

			if (otherFiles.length !== 0 && !bodyFormat[BODY_FORMAT.TEMPLATEPART]) return

			setLoading(true)
			api
				.post(
					bindParams(POST_REF_DOC_PREVIEW, cookies[PLATFORM_ID_COOKIE], docType),
					formatRequest(bodyFormat),
					{
						responseType: 'arraybuffer',
						'content-type': 'multipart/form-data;',
					},
				)
				.then(function (res) {
					const { ok, data } = res

					if (ok) {
						api
							.post(
								bindParams(POST_REF_DOC_PREVIEWINFO, cookies[PLATFORM_ID_COOKIE], docType),
								formatRequest(bodyFormat),
								{
									'content-type': 'multipart/form-data;',
								},
							)
							.then(function (res2) {
								const { ok } = res2
								const data2 = res2.data
								setLoading(false)
								if (!ok) return

								if (data && data2) {
									fileDownload(data, data2.fileName)
									setPreview(false)
									setTokenPublish(data2.guid)
								}
							})
							.catch(function (_err) {
								setLoading(false)
								setPreview(false)
							})
					}
				})
			setBodyFormat({})
		}
	}, [bodyFormat])
	//#endregion USEEFFECT

	const formatRequest = (jsonBody) => {
		const formData = new FormData()

		for (const [key, value] of Object.entries(jsonBody)) {
			if (key != BODY_FORMAT.TEMPLATEPART) formData.append(key, value)
			else {
				formData.append(`${BODY_FORMAT.TEMPLATEPART}.Value`, value[0].item2)
				formData.append(`${BODY_FORMAT.TEMPLATEPART}.ReferenceDocument`, value[0].item1)
			}
		}

		return formData
	}

	const getDocuments = () => {
		api.get(DOC_TYPE, { p_LangId: getLangId() }).then(function (res) {
			const { ok, data } = res

			if (!ok) return

			const find = data.find((d) => d.id.toString() === docType)
			if (find) setDocName(find.name)

			setDocuments(data)
		})
	}

	const getDocInfos = (docType) => {
		api
			.get(bindParams(GET_REF_DOCS_INFOS, cookies[PLATFORM_ID_COOKIE], docType))
			.then(function (res) {
				const { ok, data } = res

				if (!ok) return

				if (data.filesInfo.length) {
					//Sort filesInfo
					let _principal = data.filesInfo.find((fi) => fi.documentType.toString() === docType)
					let _annexe = data.filesInfo.find((fi) => fi.documentType.toString() !== docType)
					let _array = []
					_array.push(_principal)
					if (_annexe) _array.push(_annexe)
					data.filesInfo = _array

					let names = []
					data.filesInfo.map((fi) => {
						if (fi) {
							names.push({ documentType: fi.documentType, fileName: fi.fileName })
						}
					})
					setNames(names)
				}

				if (data.previewDatas.length) {
					if (data.previewDatas[0].subId !== null)
						setSelectedPreviewData(`${data.previewDatas[0].id}#${data.previewDatas[0].subId}`)
					else setSelectedPreviewData(data.previewDatas[0].id)
				}
				setDocInfos(data)
			})
	}

	const getDocByID = (docId) => {
		if (!documents.length) return { name: '...' }
		for (let doc of documents) {
			if (doc.id === docId) return doc
		}
	}

	const previewDoc = () => {
		if (!filePrincipal)
			return enqueueSnackbar(t('referenceDocumentManagement:error_noMainInBody'), {
				variant: 'error',
			})
		setLoading(true)
		let body = bodyFormat

		if (filePrincipal) upload(filePrincipal, BODY_FORMAT.MAIN)
		if (otherFiles)
			for (const of of otherFiles) uploadTuple(of.file, BODY_FORMAT.TEMPLATEPART, of.item)
		if (selectedPreviewData) {
			if (TYPE_PDP.find((type) => type === docType)) {
				const split = selectedPreviewData.toString().split('#')
				body[BODY_FORMAT.PDP] = split[0]
				body[BODY_FORMAT.REVISION] = split[1] ? split[1] : '0'
			} else {
				body[BODY_FORMAT.COMPANYRECORD] = selectedPreviewData
			}
			setBodyFormat({ ...body })
		}
	}

	/**
	 * Set one file (not principal)
	 * @param {Object} e
	 */
	const setOneFile = (e) => {
		preview()
		let files = otherFiles
		let fileExist = files.find((f) => f.item === current)
		if (!fileExist) files.push({ file: e, item: current })
		else fileExist.file = e

		let allnames = names
		const nameExist = allnames.find((n) => n.documentType === current)
		if (nameExist) {
			nameExist.fileName = e.name
			setNames(allnames)
		}
		setOtherFiles([...files])
	}

	/**
	 * Set the principal file
	 * @param {Object} e
	 */
	const setTheFilePrincipal = (e) => {
		const error = isValidFile(e)
		if (error.errorCode) {
			enqueueSnackbar(t('referenceDocumentManagement:filename_Error'), { variant: 'error' })
		} else {
			preview()
			setFilePrincipal(e)
			let allnames = names
			const nameExist = allnames.find((n) => n.documentType.toString() === docType)
			if (nameExist) {
				nameExist.fileName = e.name
				setNames(allnames)
			}
		}
	}

	/**
	 * Check, before the file popup pop, if the file is the principal or others
	 * @param {Object} d
	 */
	const check = (d) => {
		if (d.toString() === docType) setPrincipal(true)
		else {
			setCurrent(d)
			setPrincipal(false)
		}
	}

	/**
	 * Upload one simple file
	 * @param {Object} file
	 * @param {String} typeFile
	 */
	const upload = (file, typeFile) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				let body = bodyFormat
				body[typeFile] = res
				setBodyFormat({ ...body })
			} else {
				enqueueSnackbar(bindParams(t(cberror.errorCode), humanFileSize), { variant: 'error' })
			}
		})
	}

	/**
	 * Upload all otherFiles
	 * @param {Object} file
	 * @param {String} typeFile
	 * @param {*} value
	 */
	const uploadTuple = (file, typeFile, value) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				let fileData = {
					item1: value,
					item2: res,
				}

				let body = bodyFormat
				if (!body[typeFile]) body[typeFile] = []
				body[typeFile].push(fileData)
				setBodyFormat({ ...body })
			} else {
				enqueueSnackbar(bindParams(t(cberror.errorCode), humanFileSize), { variant: 'error' })
			}
		})
	}

	const publishDoc = () => {
		api
			.post(bindParams(POST_REF_DOC_PUBLISH, cookies[PLATFORM_ID_COOKIE], docType, tokenPublish))
			.then(function (res) {
				const { ok } = res
				if (!ok) return

				enqueueSnackbar(t('referenceDocumentManagement:success_Publish'), { variant: 'success' })

				Reset()
				preview()
			})
	}

	const getSignets = (docType) => {
		const handleResponse = (res) => {
			const { ok, data } = res

			if (!ok) return
			setSignetsData({ section: getDocByID(docType).name, list: data })
		}

		api
			.get(bindParams(GET_REF_DOCS_SIGNETS, cookies[PLATFORM_ID_COOKIE], docType))
			.then(handleResponse)
	}

	const Reset = () => {
		setFilePrincipal(null)
		setOtherFiles([])
		setPrincipal(false)
		setCurrent(null)
		setTokenPublish(null)
		getDocInfos(docType)
		getDocuments()
	}

	const downloadDocument = (path) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(
					`${t('error')} : ${t('referenceDocumentManagement:download_document_init_ex')} !`,
					{ variant: 'error' },
				)
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	return (
		<>
			<SignetsForm open={signets} onClose={closeSignetsForm} data={signetsData} />
			<HeadTitle title={docName} />
			<Grid container className={classes.container}>
				<Form onSubmit={() => console.log('submit')}>
					{docInfos && !docInfos.filesInfo.length && (
						<Typography className={classes.titlecentered} variant="h4">
							{t('referenceDocumentManagement:noDocuments')}
						</Typography>
					)}
					{docInfos &&
						docInfos.filesInfo.map((fileInfo, index) => {
							if (fileInfo) {
								return (
									<div key={index}>
										<Grid
											alignItems="center"
											container
											spacing={16}
											className={classNames(classes.inputFileAlign, classes.docContainer)}
										>
											<Grid item xs={12} sm={4}>
												<Link
													className={classes.hand}
													onClick={() => openSignetsForm(fileInfo.documentType)}
												>
													<Typography className={classes.link} variant="overline">
														{getDocByID(fileInfo.documentType).name}
													</Typography>
												</Link>
											</Grid>
											<Grid item xs={12} sm={4}>
												<InputFile
													label={t('upload')}
													name={`inputFile-${fileInfo.documentType}`}
													onClick={() => {
														if (!loading) check(fileInfo.documentType)
													}}
													setFile={principal ? setTheFilePrincipal : setOneFile}
													disabled={loading}
												/>
											</Grid>
											<Grid item xs={12} sm={4}>
												<Link
													className={classes.hand}
													onClick={() => downloadDocument(fileInfo.documentCheminComplet)}
												>
													<Typography className={classes.link} variant="overline">
														{names.find((n) => n.documentType === fileInfo.documentType).fileName}
													</Typography>
												</Link>
											</Grid>
										</Grid>
										<Grid alignItems="center" container className={classes.inputFileAlign}>
											<Grid item xs={12} className={classes.alignLastModif}>
												<Typography variant="overline">
													{moment(fileInfo.lastModified).format('DD/MM/YYYY')}
												</Typography>
											</Grid>
										</Grid>
									</div>
								)
							}
						})}
					<Grid container alignItems="center" spacing={16} className={classes.inputFileAlign}>
						<Grid item xs={3}>
							<InputSelectOnChange
								label={TYPE_PDP.find((type) => type === docType) ? 'PDP' : 'DE'}
								value={selectedPreviewData}
								onChange={(e) => {
									preview()
									setSelectedPreviewData(e.target.value)
								}}
								disabled={loading}
							>
								{docInfos &&
									docInfos.previewDatas.map((previewData) => (
										<MenuItem
											key={previewData.id}
											value={
												previewData.subId
													? `${previewData.id}#${previewData.subId}`
													: previewData.id
											}
										>
											{previewData.displayName}
										</MenuItem>
									))}
							</InputSelectOnChange>
						</Grid>
						<Grid item xs={12} sm={2}>
							<Button disabled={loading} onClick={() => previewDoc()}>
								{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								<SearchIcon />
								{t('referenceDocumentManagement:preview')}
							</Button>
						</Grid>
					</Grid>
					<Grid
						container
						alignItems="center"
						justify="center"
						spacing={16}
						className={classes.inputFileAlign}
					>
						<Grid item xs={3}>
							<Button
								variant="contained"
								color="secondary"
								disabled={isPreview}
								onClick={() => publishDoc()}
							>
								{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
								<LaunchIcon />
								{t('referenceDocumentManagement:publish')}
							</Button>
						</Grid>
					</Grid>
				</Form>
			</Grid>
		</>
	)
}

EditReferenceDocument.propTypes = {
	match: PropTypes.object,
}
export default EditReferenceDocument
