import React, { useCallback } from 'react'
import {
	Dialog,
	DialogContent,
	Typography,
    DialogTitle,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TableHead,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
}))

function ModalSubcontractors({ isOpen, onClose, subcontractors }) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])

	const executeOnClose = useCallback(() => {
        onClose(false)
	})

	return (
		<Dialog 
            open={isOpen} 
            onBackdropClick={executeOnClose}
            maxWidth="md">
            <DialogTitle>
                <Typography classes={{ root: classes.title }}>{t('subcontractorsList')}</Typography>
            </DialogTitle>
			<DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">{t('companies')}</TableCell>
                            <TableCell align="center">{t('Agency')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {subcontractors && subcontractors.map(subcontractor =>(
                            <TableRow key={subcontractor.companyId}>
                                <TableCell align="center">{subcontractor.socialReason}</TableCell>
                                <TableCell align="center">{subcontractor.agencyName}</TableCell>
                            </TableRow>                            
                        ))}
                    </TableBody>
                </Table>
			</DialogContent>
		</Dialog>
	)
}

export default ModalSubcontractors