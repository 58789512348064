import { Button, Modal, Typography } from '@material-ui/core'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	getPdPCompanyResponsable,
	updatePdPCompanyResponsable,
} from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import FormButtonIconAdd from '../../Form/FormButtonIconAdd'
import InputSelectField from '../../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import PropTypes from 'prop-types'
import { useStyles } from './PopInChoiceStyle'

const CSS_VALUES = {
	INLINE_BLOCK: 'inline-block',
}

function OperationManagerChoice({
	open,
	setmanagerChoiceForm,
	refreshDatatable,
	setRefreshDatatable,
	setCreateCompanyContactForm,
	managerChoiceData,
}) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listResponsable, setListResponsable] = useState([])
	const [initialValues, setInitialValues] = useState({})
	const { enqueueSnackbar } = useSnackbar()

	const close = () => {
		setmanagerChoiceForm(false)
	}

	const addContact = () => {
		setmanagerChoiceForm(false)
		setCreateCompanyContactForm(true)
	}

	useEffect(() => {
		if (!managerChoiceData) return
		getPdPCompanyResponsable(
			cookies[PLATFORM_ID_COOKIE],
			managerChoiceData.pdpId,
			managerChoiceData.companyId,
			function (res, error) {
				if (res) {
					setInitialValues({
						resp: res.data.selectedResponsablId,
					})
					setListResponsable(res.data.responsableList)
				}
				if (error) {
					enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				}
			},
		)
	}, [managerChoiceData])

	const onSubmit = (values) => {
		let formData = {
			companyManagerId: values.resp,
			companyId: managerChoiceData.companyId,
		}
		updatePdPCompanyResponsable(
			cookies[PLATFORM_ID_COOKIE],
			managerChoiceData.pdpId,
			formData,
			function (res, error) {
				if (res) {
					setRefreshDatatable(!refreshDatatable)
					close()
				}
				if (error) {
					console.log(error)
				}
			},
		)
	}

	return (
		<Modal open={open} onClose={close}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit} initialValues={initialValues}>
					<Typography variant="h6" classes={{ root: classes.mainTitle }}>
						{t('PersonChargeCompany')}
					</Typography>
					<Typography variant="h6" classes={{ root: classes.secondTitle }}>
						{managerChoiceData.companyName}
					</Typography>
					<div className={classes.OperationManagerGroup}>
						<Typography variant="subtitle1" classes={{ root: classes.label }}>
							{t('OperationManager')}
						</Typography>
						<InputSelectField
							name="resp"
							label="Responsable"
							classes={{ root: classes.inputSelect }}
						>
							{listResponsable &&
								listResponsable.map((elem) => {
									return (
										<SelectMenuItem key={elem.id} value={elem.id}>
											{elem.firstName} {elem.lastName}
										</SelectMenuItem>
									)
								})}
						</InputSelectField>
						<FormButtonIconAdd classes={{ root: classes.addButton }} onClick={addContact} />
					</div>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button className={classNames(classes.button, classes.validateButton)} type="submit">
							{t('submit')}
						</Button>
					</div>
					<div></div>
				</Form>
			</div>
		</Modal>
	)
}
OperationManagerChoice.propTypes = {
	open: PropTypes.bool,
	setmanagerChoiceForm: PropTypes.func,
	refreshDatatable: PropTypes.func,
	setRefreshDatatable: PropTypes.func,
	setCreateCompanyContactForm: PropTypes.func,
	managerChoiceData: PropTypes.object,
}

export default OperationManagerChoice
