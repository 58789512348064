import Axios from 'axios'

class AbstractRequest {
	constructor(type, method, url, data = undefined, options = undefined) {
		this.type = type
		this.url = url
		this.method = method
		this.data = data
		this.options = options
	}
}

class AxiosRequest extends AbstractRequest {
	constructor(method, url, data = undefined, options = undefined) {
		super('AxiosRequest', method, url, data, options)
	}

	get promise() {
		return Axios({
			method: this.method,
			url: this.url,
			data: this.data,
			...this.options,
		})
	}
}

export const RequestFactory = {
	createRequest: function (method, url, data = undefined, options = undefined) {
		return new Request(method, url, data, options)
	},

	createAxiosRequest: function (method, url, data = undefined, options = undefined) {
		return new AxiosRequest(method, url, data, options)
	},
}
