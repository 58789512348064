import { Button, Checkbox, Grid, Switch, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import LazyLoad from 'react-lazyload'
import Form from '../../../components/Form/Form'
import InputCheckboxGroup from '../../../components/Form/input/InputCheckboxGroup'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { AREAOPERATION, SECTORS } from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import PropTypes from 'prop-types'
import Access from '../../../containers/RoleContainer'
import ROLES from '../../../constants/roles'

const useStyles = makeStyles((theme) => ({
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	listCheckbox: {
		maxWidth: 450,
		margin: 'auto',
		height: '55vh',
	},
	switch: {
		maxWidth: 540,
		margin: 'auto',
	},
}))

function OperationAreas({
	buttonsStyle,
	pdpNumber,
	pdpId,
	isReadOnly,
	refreshChevrons,
	setRefreshChevrons,
	revisionNumber,
	setRevisionNumber,
	nextStep,
	previousStep,
	setRedirectRevision,
	setIsNotAreaSelected,
}) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listSectors, setListSectors] = useState([])
	const [valueCheckbox, setValueCheckbox] = useState([])
	const [switchCheck, setSwitchCheck] = useState(false)
	const { t } = useTranslation(['user', 'pdp'])
	const { enqueueSnackbar } = useSnackbar()

	const recoverySectors = (res) => {
		const { data } = res

		if (data) {
			setListSectors(data)
		}
	}

	const recoveryOperationAreas = (res) => {
		const { data } = res

		if (data) {
			let temp = []
			//Initialize the list of checkboxs
			data.forEach((id) => {
				temp = [...temp, { id: `${id}`, checked: true }]
			})
			setValueCheckbox(temp)
		}
	}

	useEffect(() => {
		api.get(SECTORS, { p_PlatformId: parseInt(cookies[PLATFORM_ID_COOKIE]) }).then(recoverySectors)
		api
			.get(bindParams(AREAOPERATION, parseInt(cookies[PLATFORM_ID_COOKIE]), pdpId, revisionNumber))
			.then(recoveryOperationAreas)
	}, [cookies, pdpId, revisionNumber])

	const sendingNewOperationAreas = (res) => {
		let { data, error } = res
		if (data) {
			enqueueSnackbar(t('pdp:updatedOperationsAreas'), {
				variant: 'info',
			})
			if (`${data.revisionNumber}` !== revisionNumber) {
				setRevisionNumber(data.revisionNumber)
				setRedirectRevision(true)
				setRefreshChevrons(!refreshChevrons)
				return
			}
			setRefreshChevrons(!refreshChevrons)
			nextStep()
		}
		if (error) {
			if (Array.isArray(error.response.data)) {
				enqueueSnackbar(t(`user:${error.response.data[0].code}`), { variant: 'error' })
				return
			}
			enqueueSnackbar(t('user:error') + ` : ${error.response.status} ! `, { variant: 'error' })
		}
	}

	const handleSwitchClick = () => {
		setSwitchCheck(!switchCheck)
	}

	useEffect(() => {
		if (switchCheck) {
			let units = []
			listSectors.forEach((sector) => {
				sector.units.forEach(
					(unit) => (units = [...units, { id: unit.id.toString(), checked: true }]),
				)
			})
			setValueCheckbox(units)
		} else {
			setValueCheckbox([])
		}
	}, [switchCheck])

	const onSubmit = () => {
		let listUnits = []
		for (const unit of valueCheckbox) {
			if (unit.checked) {
				listUnits = [...listUnits, unit.id]
			}
		}
		if (Array.isArray(listUnits) && listUnits.length === 0) {
			enqueueSnackbar(t('user:at_least_one_operation'), { variant: 'error' })
			return
		}
		let formData = {
			units: [...new Set(listUnits)],
		}

		api
			.post(
				bindParams(AREAOPERATION, parseInt(cookies[PLATFORM_ID_COOKIE]), pdpId, revisionNumber),
				formData,
			)
			.then(sendingNewOperationAreas)
		setIsNotAreaSelected(true)
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('user:operationsAreas')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('user:preventionPlanNo')} {pdpNumber}
			</Typography>
			<hr className={classes.hrRoot} />
			<Access accept={[ROLES.ADMIN, ROLES.PLATFORMMANAGER]}>
				<div className={classes.switch}>
					<Switch
						checked={switchCheck}
						size="normal"
						name="notifIsActive"
						onClick={handleSwitchClick}
					/>
					{t('user:preventPlanSelectAll')}
				</div>
			</Access>
			<Form onSubmit={onSubmit} initialValues={{}}>
				<div className={classes.listCheckbox}>
					<Scrollbars className={classes.scroll}>
						{listSectors.map((sector) => {
							return (
								<LazyLoad key={sector.id} overflow offset={100} placeholder={<h1>loading...</h1>}>
									<InputCheckboxGroup
										name={`sector-${sector.id}`}
										key={sector.id}
										label={sector.name}
										setValueCheckbox={setValueCheckbox}
										valueCheckbox={valueCheckbox}
										fullPage={true}
										readOnly={isReadOnly}
									>
										{sector?.units.map((unit) => {
											return (
												<Checkbox
													fullPage={true}
													name={`unit-${unit.id}`}
													label={unit.name}
													key={unit.id}
													readOnly={isReadOnly}
												/>
											)
										})}
									</InputCheckboxGroup>
								</LazyLoad>
							)
						})}
					</Scrollbars>
				</div>
				<div className={buttonsStyle.root}>
					<Grid container direction="row" justify="space-between" alignItems="center">
						<Grid container item xs={4} justify="center">
							<Button className={buttonsStyle.previous} onClick={previousStep}>
								{t('user:previous')}
							</Button>
						</Grid>
						{isReadOnly && (
							<Grid container item xs={4} justify="center">
								<Button className={buttonsStyle.next} onClick={nextStep}>
									{t('user:next')}
								</Button>
							</Grid>
						)}

						{!isReadOnly && (
							<Grid container item xs={4} justify="center">
								<Button type="submit" className={buttonsStyle.completed}>
									{t('user:completeStep')}
								</Button>
							</Grid>
						)}
					</Grid>
				</div>
			</Form>
		</>
	)
}
OperationAreas.propTypes = {
	buttonsStyle: PropTypes.object,
	pdpNumber: PropTypes.string,
	pdpId: PropTypes.string,
	isReadOnly: PropTypes.bool,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	revisionNumber: PropTypes.string,
	setRevisionNumber: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	setRedirectRevision: PropTypes.func,
	setIsNotAreaSelected: PropTypes.func,
}
export default OperationAreas
