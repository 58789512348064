import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
	CircularProgress,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import Delete from '@material-ui/icons/Delete'
import GetApp from '@material-ui/icons/GetApp'
import { downloadFile, deleteOtherDocument } from '../../../../../containers/DataContainer'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import { getFileNameToHeaders, managedFileNotFoundError } from '../../../../../utils/File'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	fab: {
		position: 'absolute',
		bottom: theme.spacing.unit * 2,
		right: theme.spacing.unit * 2,
	},
	table: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		marginBottom: '20px',
		boxShadow: '2px 3px 11px 0px rgba(0,0,0,0.35)',
	},
	icon: {
		color: theme.palette.secondary.main + ' !important',
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
		'&:hover': {
			cursor: 'pointer',
		},
	},
	circularProgress: {
		color: theme.palette.secondary.main + ' !important',
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
	},
	messageEmpty: {
		textAlign: 'center',
	},
	buttonAddCertification: {
		position: 'absolute',
		right: '-33px',
		bottom: '-75px',
		zIndex: 99,
	},
	filesList: {
		position: 'relative',
	},
	button: {
		margin: 10,
	},
}))

function StepFour({
	openAddCompanyFilesDocuments,
	isReadOnly,
	documentsList,
	setDocumentsList,
	companyId,
}) {
	const classes = useStyles()
	const [loader] = useState([])
	const { t } = useTranslation('user')
	const [confirmPopUp, setConfirmPopUp] = useState({ open: false, path: null })
	const { enqueueSnackbar } = useSnackbar()

	const closePopUp = () => {
		setConfirmPopUp({ open: false, path: null })
	}

	const downloadDocument = (path) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				console.log(error)
				return
			}
			if (res) {
				let fileName = getFileNameToHeaders(res.headers)
				fileDownload(res.data, fileName)
			}
		})
	}

	const deleteDocument = (file) => {
		deleteOtherDocument(companyId, file.docName, function (res, error) {
			if (error) {
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				enqueueSnackbar(t('TheFileHasBeenDeleted'), { variant: 'info' })
				let newListFiles = documentsList.filter((obj) => {
					return obj.docName !== file.docName
				})
				setDocumentsList(newListFiles)
				closePopUp()
			}
		})
	}

	const OpenConfirmPopUp = () => {
		return (
			<Dialog
				open={confirmPopUp.open}
				onClose={closePopUp}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{t('warning')}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('doYouWantToDeleteThisFile')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closePopUp} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteDocument(confirmPopUp.path)
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<>
			<div>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openAddCompanyFilesDocuments}
					>
						{t('addFromLibrary')}
					</Button>
				)}
			</div>
			{confirmPopUp.open && <OpenConfirmPopUp />}
			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>{t('nameOfTheFile')}</TableCell>
						<TableCell align="center">{t('download')}</TableCell>
						<TableCell align="center">{t('delete')}</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{documentsList.length ? (
						documentsList.map((file, i) => (
							<TableRow key={file.fileName || file.docName}>
								<TableCell component="th" scope="row">
									{file.fileName || file.docName}
								</TableCell>
								<TableCell component="th" scope="row">
									{!loader[i] && (
										<GetApp
											classes={{ root: classes.icon }}
											onClick={() => {
												// Direct Download
												if (typeof file.path !== 'undefined') downloadDocument(file.path)
												// Old file
												else downloadDocument(file.docDocumentCheminComplet)
											}}
										/>
									)}
									{loader[i] && <CircularProgress className={classes.circularProgress} />}
								</TableCell>
								<TableCell component="th" scope="row">
									{!isReadOnly && (
										<Delete
											classes={{ root: classes.icon }}
											onClick={() => {
												setConfirmPopUp({ open: true, path: file })
											}}
										/>
									)}
								</TableCell>
							</TableRow>
						))
					) : (
						<TableRow>
							<TableCell />
							<TableCell>
								<p className={classes.messageEmpty}>{t('YouHaveNoFiles')}</p>
							</TableCell>
							<TableCell />
						</TableRow>
					)}
				</TableBody>
			</Table>
		</>
	)
}
StepFour.propTypes = {
	openAddCompanyFilesDocuments: PropTypes.func,
	isReadOnly: PropTypes.bool,
	documentsList: PropTypes.object,
	setDocumentsList: PropTypes.func,
	companyId: PropTypes.string,
}
export default StepFour
