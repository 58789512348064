import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { toggleAccountState, toggleDO } from '../../containers/DataContainer'
import Form from '../Form/Form'
import FormButtonIcon from '../Form/FormButtonIcon'
import FormIcon from '../Form/FormIcon'
import InputSelect from '../Form/input/InputSelect'
import SelectMenuItem from '../Form/input/SelectMenuItem'
import SlidingForm from '../Form/SlidingForm'

const useStyles = makeStyles(theme => ({
	text: {
		marginTop: 15,
	},
	inputSelect: {
		marginTop: 20,
	},
}))

/**
 * Shows a form to add a subcontracting company to the company file
 * @returns {JSX.Element} React stack to render
 */
function ToggleDOForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const { enqueueSnackbar } = useSnackbar()
	const [cookies] = useCookies()

	const onSubmit = values => {
		// In order to disable a user, we need at first to switch it with another.
		toggleDO(
			{ newId: values.doID, oldId: data.accountId, platformId: cookies[PLATFORM_ID_COOKIE] },
			(res, error) => {
				if (error) {
					if (error.data) {
						enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
						return
					}
					enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
					return
				}
				if (res) {
					//Then, we can disable the user.
					toggleAccountState(
						{
							platformId: cookies[PLATFORM_ID_COOKIE],
							accountId: data.accountId,
						},
						(res, error) => {
							if (error) {
								if (error.data) {
									enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
									return
								}
								enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
								return
							}
							if (res) {
								enqueueSnackbar(t('AccountStatusHasBeenChanged'), { variant: 'info' })
							}
						},
					)
				}
			},
		)
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('warning')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit}>
				<Typography className={classes.text} variant="body1" color="primary">
					{t('toggleWarningMessage')}
				</Typography>
				<InputSelect
					name="doID"
					label="Coordinateur/DO"
					classes={{
						root: classes.inputSelect,
					}}
				>
					{data.listDO &&
            Object.keys(data.listDO).map(idDO => {
            	return (
            		<SelectMenuItem key={idDO} value={idDO}>
            			{data.listDO[idDO]}
            		</SelectMenuItem>
            	)
            })}
				</InputSelect>
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default ToggleDOForm
