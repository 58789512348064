import React from 'react'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Description from '@material-ui/icons/Description'
import Forum from '@material-ui/icons/Forum'
import InsertDriveFile from '@material-ui/icons/InsertDriveFile'
import Note from '@material-ui/icons/Note'
import DeleteForever from '@material-ui/icons/DeleteForever'
import {
	Typography,
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { downloadFile } from '../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import fileDownload from 'js-file-download'
import api from '../../utils/Requester'
import { bindParams } from '../../utils/Link'
import { DOCUMENT_DELETE_FILE } from '../../constants/url'
import PropTypes from 'prop-types'
import { managedFileNotFoundError } from '../../utils/File'

// STYLES
const useStyles = makeStyles((theme) => ({
	card: {
		minHeight: 170,
		position: 'relative',
		'&:hover': {
			color: theme.palette.secondary.main + ' !important',
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
			cursor: 'pointer',
		},
	},
	text: {
		color: theme.palette.secondary.main,
	},
	cardHeader: {
		paddingBottom: 5,
	},
	deleteIcon: {
		width: '1.3em',
		height: '1.3em',
		position: 'relative',
		bottom: 10,
		right: 10,
		color: theme.palette.secondary.main,
		'&:hover': {
			cursor: 'pointer',
		},
	},
	absolute: {
		position: 'absolute',
	},
	paper: {
		margin: 'auto',
		marginTop: '20%',
		width: 400,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	cardIcon: {
		width: '1.5em',
		height: '1.5em',
		verticalAlign: 'middle',
		color: theme.palette.secondary.main,
	},
	cardTitle: {
		display: 'inline-block',
		textAlign: 'center',
		fontWeight: 500,
		fontSize: '1.1em',
		margin: 'auto',
		color: theme.palette.secondary.main,
	},
	content: {
		display: 'flex',
	},
	fileName: {
		margin: '0 20px 20px 20px',
		fontSize: 13,
		textAlign: 'center',
	},
	footerCard: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0px 18px',
		paddingTop: 20,
	},
	footerText: {
		margin: 0,
		fontSize: 12,
	},
}))

/**
 * Shows a Contact card
 * @param {int} size : Size of the card
 * @param {any} data : Object with the data
 * @returns {JSX.Element} React stack to render
 */

const DocumentCard = ({
	size,
	refresh,
	setRefresh,
	file,
	refreshChevrons,
	setRefreshChevrons,
	isReadOnly,
	pdpId,
}) => {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const [open, setOpen] = React.useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const download = (path) => {
		downloadFile(path, function (res, error) {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res) {
				fileDownload(res.data, file.fileName)
			}
		})
	}

	const deleteDocument = (path) => {
		api
			.delete(bindParams(DOCUMENT_DELETE_FILE, cookies[PLATFORM_ID_COOKIE], pdpId, path))
			.then((res) => {
				const { ok } = res

				if (!ok) {
					enqueueSnackbar(t('errorDeleteDocumentFile'), { variant: 'error' })
					return
				}

				enqueueSnackbar(t('TheFileHasBeenDeleted'), { variant: 'info' })
				setRefresh(!refresh)
				setRefreshChevrons(!refreshChevrons)
				handleClose()
			})
	}

	const MessageDeleteContact = () => {
		return (
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title"> {t('warning')} !</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{t('doYouWantToDeleteThisFile')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="secondary">
						{t('no')}
					</Button>
					<Button
						onClick={() => {
							deleteDocument(file.path)
						}}
						color="secondary"
						autoFocus
					>
						{t('yes')}
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	return (
		<Grid item xs={12} sm={6} md={4} lg={size}>
			<MessageDeleteContact />
			<Card className={classes.card}>
				<CardContent className={classes.content}>
					{file.pdPFileType === 0 /* Chapeau */ && (
						<>
							<Description classes={{ root: classes.cardIcon }} />
							<Typography classes={{ root: classes.cardTitle }}>{t('HeadDocument')}</Typography>
						</>
					)}
					{file.pdPFileType === 1 /* Convication */ && (
						<>
							<Forum classes={{ root: classes.cardIcon }} />
							<Typography classes={{ root: classes.cardTitle }}>
								{t('InvitationToAttend')}
							</Typography>
						</>
					)}
					{file.pdPFileType === 2 /* Annexe */ && (
						<>
							<InsertDriveFile classes={{ root: classes.cardIcon }} />
							<Typography classes={{ root: classes.cardTitle }}> {t('Annex')} </Typography>
						</>
					)}
					{file.pdPFileType === 3 /* Avenant */ && (
						<>
							<Note classes={{ root: classes.cardIcon }} />
							<Typography classes={{ root: classes.cardTitle }}> {t('Endorsement')} </Typography>
						</>
					)}
				</CardContent>
				<Typography
					className={classes.fileName}
					onClick={() => {
						download(file.path)
					}}
				>
					{file && file.fileName}
				</Typography>
				{file && file.dateAdded ? (
					<div className={classes.footerCard}>
						<Typography className={classes.footerText}>{file.dateAdded}</Typography>
						{file.pdPFileType === 2 /* Annexe */ && !isReadOnly && (
							<DeleteForever
								classes={{ root: classes.deleteIcon }}
								onClick={handleOpen}
							></DeleteForever>
						)}
					</div>
				) : (
					<DeleteForever
						className={`${classes.deleteIcon} ${classes.absolute}`}
						onClick={handleOpen}
					></DeleteForever>
				)}
			</Card>
		</Grid>
	)
}
DocumentCard.propTypes = {
	size: PropTypes.number,
	data: PropTypes.object,
	openCreatePdPContactWithData: PropTypes.func,
	refresh: PropTypes.bool,
	setRefresh: PropTypes.func,
	file: PropTypes.object,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	isReadOnly: PropTypes.bool,
	pdpId: PropTypes.string,
}

export default DocumentCard
