import React, { useEffect, useState } from 'react'
import AddIcon from '@material-ui/icons/Add'
import {
	Paper,
	Button,
	Fab,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Tooltip,
} from '@material-ui/core'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import Dialog from '@material-ui/core/Dialog'
import ROLES, { findRoleById } from '../../constants/roles'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import Access from '../../containers/RoleContainer'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'inline-block',
		padding: theme.spacing.unit,
		minWidth: '100%',
		border: 'solid 1px ' + theme.palette.secondary.light,
	},
	modalTitle: {
		color: theme.palette.secondary.main,
	},
	button: {
		padding: 0,
		border: 'solid 1px black',
	},
	roleIcon: {
		padding: theme.spacing.unit,
		cursor: 'pointer',
	},
	noSelect: {
		margin: theme.spacing.unit,
		opacity: 0.5,
	},
	selectedRoles: {
		verticalAlign: 'middle',
		margin: theme.spacing.unit,
	},
	frame: {
		display: 'inline-block',
		margin: theme.spacing.unit,
		borderRadius: 10,
	},
	border: {
		backgroundColor: 'rgba(0,0,0,0.1)',
	},
	hide: { display: 'none' },
}))

const TotalAccountSelector = ({ name, form, initialValue = null, disableFabButton = false }) => {
	const classes = useStyles()
	const [selectedRoles, setSelectedRoles] = useState([])
	const [open, setOpen] = useState(false)
	const openModal = () => setOpen(true)
	const closeModal = () => setOpen(false)
	const { t } = useTranslation(['user'])
	const test = () => {
		setOpen(false)
		let rls = [...document.getElementsByClassName('roles')]
		let value = rls
			.map((rl) => {
				if (rl.checked) {
					return { id: parseInt(rl.name, 10) }
				}
			})
			.filter((rl) => {
				if (rl) return rl
			})
		form.change(name, JSON.stringify(value))
		setSelectedRoles(value)
	}

	useEffect(() => {
		if (!initialValue || initialValue.length <= 0) return
		form.change(name, JSON.stringify(initialValue))
		setSelectedRoles(initialValue)
	}, [initialValue])

	const RoleFab = ({ id, name, icon }) => {
		const [checked, setChecked] = useState(false)
		const toggle = () => {
			setChecked(!checked)
		}

		return (
			<React.Fragment>
				<div className={classNames({ [classes.border]: checked }, classes.frame)} onClick={toggle}>
					<Tooltip title={t(name)}>
						<img className={classes.roleIcon} src={icon} alt="basic alt" />
					</Tooltip>
				</div>
				<input
					checked={checked}
					onChange={(e) => setChecked(e.target.value)}
					name={id}
					className={classNames(['roles', classes.hide])}
					type="checkbox"
				/>
			</React.Fragment>
		)
	}

	return (
		<Paper className={classes.container}>
			<Dialog open={open} onClose={closeModal}>
				<DialogTitle className={classes.modalTitle}>{t('AccountType')}</DialogTitle>
				<DialogContent>
					<DialogContentText>{t('SelectDesiredRoles')} :</DialogContentText>
					{Object.values(ROLES).map((role, i) => {
						if (role.id === 1)
							return (
								<Access accept={[ROLES.ADMIN]}>
									<RoleFab key={i} id={role.id} name={role.name} icon={role.icon} />
								</Access>
							)
						if (role.id !== ROLES.EXTCOMP.id && role.id !== ROLES.EXTCOMPADMIN.id)
							return <RoleFab key={i} id={role.id} name={role.name} icon={role.icon} />
					})}
				</DialogContent>
				<DialogActions>
					<Button onClick={test} color="secondary">
						{t('confirm')}
					</Button>
					<Button onClick={closeModal} color="secondary">
						{t('cancel')}
					</Button>
				</DialogActions>
			</Dialog>
			<Field name={name} component="input" className={classes.hide} />
			<Fab onClick={openModal} color="secondary" disabled={disableFabButton}>
				<AddIcon />
			</Fab>
			{selectedRoles.length > 0 ? (
				selectedRoles.map((role, i) => (
					<React.Fragment key={i}>
						<Tooltip title={t(findRoleById(role.id)[0].name)}>
							<img
								className={classes.selectedRoles}
								src={findRoleById(role.id)[0].icon}
								alt="basic alt"
							/>
						</Tooltip>
					</React.Fragment>
				))
			) : (
				<span className={classes.noSelect}>{t('NoRoleSelected')}</span>
			)}
		</Paper>
	)
}

export default TotalAccountSelector
