import React from 'react'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import ArrowBackLink from './ArrowBack'
import PropTypes from 'prop-types'

const useStyles = makeStyles(theme => ({
	hrRoot: {
		margin: 0,
		marginTop: 10,
		marginRight: 'auto',
		maxWidth: 70,
		border: '1px solid' + theme.palette.secondary.main,
	},
	titleRoot: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		paddingLeft: '20px',
		fontWeight: '500',
		fontSize: '2.2em',
		verticalAlign: 'middle',
	},
	arrowRoot: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		verticalAlign: 'middle',
	},
	root: {
		margin: '20px',
	},
	subtitleText: {
		color: theme.palette.secondary.main,
		marginTop: '15px',
		fontSize: '1.3em',
	},
}))

function HeadTitle({ title, subtitle = null}) {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<ArrowBackLink></ArrowBackLink>
			<Typography
				variant="h5"
				classes={{
					root: classes.titleRoot,
				}}
			>
				{title}
				<hr className={classes.hrRoot} />
			</Typography>
			{subtitle && (
				<Typography className={classes.subtitleText}>
					<small>{subtitle}</small>
				</Typography>
			)}
		</div>
	)
}
HeadTitle.propTypes = {
	title : PropTypes.string,
	subtitle : PropTypes.string
}

export default HeadTitle
