import PlatformConfigurationForm from '../../components/forms/PlatformConfigurationForm'
import api from '../../utils/Requester'
import { PLATFORM_ID_COOKIE, ACCOUNT_TYPES_COOKIE } from '../../constants/cookies'
import { useCookies } from 'react-cookie'
import React, { useState, useEffect } from 'react'
import { useSnackbar } from 'notistack'
import ROLES from '../../constants/roles'
import { bindParams } from '../../utils/Link'
import { PLATFORM_CONFIGURATION } from '../../constants/url'
import { useTranslation } from 'react-i18next'

function PlatformConfiguration() {
	const [cookies] = useCookies()
	const [isPlatformManager, setIsPlatformManager] = useState(false)

	const [platformConfigurations, setPlatformConfigurations] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const { t } = useTranslation('platformConfiguration')

	useEffect(() => {
		getPlatformConfiguration()
		setIsPlatformManager(
			cookies[ACCOUNT_TYPES_COOKIE].some((ur) => ur.code === ROLES.PLATFORMMANAGER.code),
		)
	}, [])

	const recoverPlatformConfiguration = (res) => {
		const { data, problem } = res
		if (problem) {
			enqueueSnackbar(t('user:view_render_failed'), { variant: 'error' })
		} else {
			setPlatformConfigurations(data)
		}
	}

	const getPlatformConfiguration = () => {
		api
			.get(bindParams(PLATFORM_CONFIGURATION, cookies[PLATFORM_ID_COOKIE]))
			.then(recoverPlatformConfiguration)
	}
	return (
		<>
			{platformConfigurations.length > 0 && (
				<PlatformConfigurationForm
					configurations={platformConfigurations}
					isPlatformManager={isPlatformManager}
					platformId={cookies[PLATFORM_ID_COOKIE]}
				/>
			)}
		</>
	)
}

export default PlatformConfiguration
