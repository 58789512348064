import React from 'react'
import {
	Grid,
	Card,
	CardContent,
	Typography,
	CardHeader,
	LinearProgress,
	Icon,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
	Card: {
		backgroundColor: '#FFF',
		position: 'relative',
		width: '100%',
		height: 200,
		animation: 'test 2500ms ease-out infinite',
		'&:hover': {
			color: theme.palette.secondary.main + ' !important',
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
			cursor: 'pointer',
		},
	},
	title: {
		maxWidth: 250,
		maxHeight: 35,
		color: theme.palette.secondary.main,
	},
	percent: {
		textAlign: 'center',
		marginBottom: theme.spacing.unit * 0.5,
	},
	editIcon: {
		position: 'absolute',
		right: 15,
		bottom: 15,
		overflow: 'visible',
	},
	'@keyframes test': {
		'0%': {
			width: '90%',
		},
		'100%': {
			width: '100%',
		},
	},
}))

function BasicCard({ title, size, percent, edit }) {
	const classes = useStyles()

	return (
		<Grid item md={size} xs={12}>
			<Card className={classes.Card} onClick={edit}>
				<CardHeader
					avatar={
						<Typography className={classes.title} variant="h6">
							{title}
						</Typography>
					}
				/>
				<CardContent>
					<Typography className={classes.percent}>{percent} %</Typography>
					<LinearProgress variant="determinate" value={percent} color="secondary" />
					<Icon className={classes.editIcon}>
						<img height="25px" src="/icons/toComplete_b.png" alt="Footer Icon" />
					</Icon>
				</CardContent>
			</Card>
		</Grid>
	)
}
export default BasicCard
