import React, { useState, useEffect } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import {
	Typography,
	Button,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Dialog,
} from '@material-ui/core'
import Form from '../../Form/Form'
import classNames from 'classnames'
import InputCheckbox from '../../Form/inputFullPage/InputCheckbox'
import { Scrollbars } from 'react-custom-scrollbars'
import InputSearch from '../../Form/input/InputSearch'
import FilterResults from 'react-filter-search'
import { getEligibleCompanies, addMultiplesCompaniesToPdP } from '../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import LazyLoad from 'react-lazyload'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		maxWidth: '60%',
		minWidth: 700,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	mainTitle: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		width: '60%',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	inputSearch: {
		width: '80%',
		margin: 20,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	dialog: {
		minWidth: 700,
	},
}))

function AddMajorCompanies({
	open,
	pdpId,
	setAddMajorCompagnies,
	revisionNumber,
	refreshChevrons,
	setRefreshChevrons,
	refreshDatatable,
	setRefreshDatatable,
}) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [value, setValue] = useState('')
	const [cookies] = useCookies()
	const [listCompanies, setListCompanies] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const [refreshTab, setRefreshTab] = useState(false)

	const close = () => {
		setValue('')
		setAddMajorCompagnies(false)
	}

	const search = (event) => {
		setValue(event.target.value)
	}

	useEffect(() => {
		getEligibleCompanies(cookies[PLATFORM_ID_COOKIE], pdpId, function (res, error) {
			if (res) {
				setListCompanies(res.data)
			}
			if (error) {
				console.error(error)
			}
		})
	}, [refreshTab])

	const onSubmit = (values) => {
		const companies = Object.keys(values).reduce((filtredValues, key) => {
			if (values[key]) filtredValues.push(key.replace(/[^0-9]+/, ''))

			return filtredValues
		}, [])
		if (companies.length) {
			let formData = {
				companyIds: companies,
				isRestricted: false,
			}
			addMultiplesCompaniesToPdP(
				formData,
				cookies[PLATFORM_ID_COOKIE],
				pdpId,
				revisionNumber,
				function (res, error) {
					if (res) {
						setRefreshChevrons(!refreshChevrons)
						enqueueSnackbar(t('CompaniesHaveBeenAddedPdP'), {
							variant: 'info',
						})
						setRefreshTab(!refreshTab)
						setRefreshDatatable(!refreshDatatable)
					}
					if (error) {
						if (error.data) {
							enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
							return
						}
						enqueueSnackbar(t('error') + ` : ${error.status} ! `, { variant: 'error' })
					}
				},
			)
			close()
		} else return enqueueSnackbar(t('needSelectedCompanies'), { variant: 'info' })
	}

	return (
		<Dialog open={open} onClose={close} classes={{ paper: classes.dialog }}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit}>
					<Typography variant="h6" classes={{ root: classes.mainTitle }}>
						{t('AddingMainCompanies')}
					</Typography>
					<InputSearch
						name="search"
						label={t('SearchCompany')}
						onChange={search}
						classes={{ root: classes.inputSearch }}
						variant="FullPage"
					/>
					<Scrollbars autoHeight={true} autoHeightMin={'40vh'}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>{t('companyName')}</TableCell>
									<TableCell>{t('Agency')}</TableCell>
									<TableCell>{t('socialReason')}</TableCell>
									<TableCell>{t('siretNumber')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<FilterResults
									value={value}
									data={listCompanies}
									renderResults={(companies) => (
										<>
											{companies.map((el, i) => (
												<LazyLoad key={el.companyId} overflow offset={100}>
													<TableRow key={i}>
														<TableCell>
															<InputCheckbox
																name={`company-${el.companyId}`}
																values={el.companyId}
															></InputCheckbox>
														</TableCell>
														<TableCell component="th" scope="row">
															{el.companyName}
														</TableCell>
														<TableCell>{el.agencyName}</TableCell>
														<TableCell>{el.socialReason}</TableCell>
														<TableCell>{el.siretNumber}</TableCell>
													</TableRow>
												</LazyLoad>
											))}
										</>
									)}
								/>
							</TableBody>
						</Table>
					</Scrollbars>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button className={classNames(classes.button, classes.validateButton)} type="submit">
							{t('submit')}
						</Button>
					</div>
					<div></div>
				</Form>
			</div>
		</Dialog>
	)
}
AddMajorCompanies.propTypes = {
	open: PropTypes.bool,
	pdpId: PropTypes.string,
	setAddMajorCompagnies: PropTypes.func,
	revisionNumber: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	refreshDatatable: PropTypes.bool,
	setRefreshDatatable: PropTypes.func,
}
export default AddMajorCompanies
