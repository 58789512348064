export const SUPERVISION_CODE = {
	OPENINGREQUEST: 'OpeningRequest',
	OPENEDPREVENTIONPLAN: 'OpenedPreventionPlan',
	COMPANYRECORDVALIDATION: 'CompanyRecordValidation',
	PERFORMANCE_TRACK: 'PerformanceTrack',
	EXPIRING_PDP: 'ExpiringPdp',
}

export const ITEMPERCOL = 3

export const SUPERVISION_PERF_SCORE = {
	LOW: { code: 1, min: 0, max: 28.8 },
	MEDIUM: { code: 2, min: 28.8, max: 71.1 },
	FAST: { code: 3, min: 71.1, max: 100 },
}
