import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { TextField, Typography, MenuItem, Paper } from '@material-ui/core'
import { Translation } from 'react-i18next'
import Select from 'react-select'

// STYLES
const useStyles = makeStyles(theme => ({
	menuItem: {
		zIndex: 1000,
		color: '#3268A7',
		backgroundColor: theme.palette.primary.main,
		'&:hover': {
			color: theme.palette.primary.main,
			backgroundColor: '#3268A7 !important',
		},
	},
	paper: {
		position: 'absolute',
		zIndex: 1000,
		left: 0,
		right: 0,
	},
	label: {
		fontSize: '1em',
	},
}))

const customStyles = {
	option: (provided, state) => ({
		...provided,
		color: state.isSelected ? 'red' : 'blue',
		backgroundColor: state.isSelected ? 'red' : 'blue',
		padding: 20,
	}),
	indicatorContainer: (provided, state) => ({
		...provided,
		color: '#134391',
		backgroundColor: '#134391',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		backgroundColor: '#134391',
	}),
	control: (provided, state) => ({
		// none of react-select's styles are passed to <Control />
		...provided,
		borderColor: '#134391',
		padding: '8px 12px 5px',
		'&:hover': {
			borderColor: '#134391',
			cursor: 'pointer',
		},
	}),
}

function InputSelectSearch({ name, label, validate, data, onChange, defaultValue, ...props }) {
	const classes = useStyles()
	const [value, setValue] = useState()

	useEffect(() => {
		if(defaultValue) {
			setValue(defaultValue)
		}
	}, [defaultValue])

	const myOnChange = event => {
		setValue(event)
	}

	function SingleValue(props) {
		return (
			<Typography color="secondary" className={classes.label} {...props.innerProps}>
				{props.children}
			</Typography>
		)
	}

	function Placeholder(props) {
		const { selectProps, innerProps = {}, children } = props
		return (
			<Typography color="secondary" className={classes.label} {...innerProps}>
				{children}
			</Typography>
		)
	}

	function Option(props) {
		return (
			<MenuItem
				selected={props.isFocused}
				classes={{ root: classes.menuItem, selected: classes.selected }}
				{...props.innerProps}
			>
				{props.children}
			</MenuItem>
		)
	}

	function Menu(props) {
		return (
			<Paper square className={classes.paper} {...props.innerProps}>
				{props.children}
			</Paper>
		)
	}

	const components = {
		Placeholder,
		SingleValue,
		Menu,
		Option,
	}

	return (
		<Field
			name={name}
			validate={validate}
			InputLabelProps={{
				shrink: true,
			}}
			render={({ input, meta }) => {
				const mergedOnChange = e => {
					myOnChange(e)
					onChange(e)
				}
				const newInput = { ...input, onChange: mergedOnChange }
				return (
					<Select
						TextFieldProps={{
							variant: 'filled',
							InputLabelProps: {
								htmlFor: 'react-select-single',
								shrink: true,
								variant: 'filled',
							},
						}}
						styles={customStyles}
						options={data}
						components={components}
						placeholder={label}
						{...props}
						{...newInput}
						value={value}
					/>
				)
			}}
		/>
	)
}

export default InputSelectSearch