import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ROLES from '../../../../../constants/roles'
import { getAccountByTypePlatform } from '../../../../../containers/DataContainer'
import { required } from '../../../../../utils/VerifyFields'
import InputCheckbox from '../../../../Form/inputFullPage/InputCheckbox'
import { InputSelectOnChange } from '../../../../Form/inputFullPage/InputSelect'
import InputTextArea from '../../../../Form/inputFullPage/InputTextArea'
import SelectMenuItem from '../../../../Form/inputFullPage/SelectMenuItem'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	radioInput: {
		display: 'block',
	},
	radioLabel: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	radio: {
		margin: 5,
		marginLeft: 20,
	},
	space: {
		marginBottom: 15,
		color: theme.palette.secondary.main,
	},
	type: {
		color: theme.palette.secondary.main,
	},
}))

StepOne.propTypes = {
	platformId: PropTypes.number,
	operationInfos: PropTypes.object,
	principal: PropTypes.number,
	setPrincipal: PropTypes.func,
	isReadOnly: PropTypes.bool,
	restrictedPdPContext: PropTypes.bool,
	freezePrincipal: PropTypes.bool,
	companyRecordState: PropTypes.number,
	isAdminOrManager: PropTypes.bool,
}
function StepOne({
	platformId,
	operationInfos,
	principal,
	setPrincipal,
	isReadOnly,
	restrictedPdPContext = false,
	freezePrincipal,
	companyRecordState,
	isAdminOrManager,
}) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [listDO, setListDO] = useState([])
	const [principalFreezed, setPrincipalFreezed] = useState(true)

	useEffect(() => {
		getAccountByTypePlatform(platformId, ROLES.PRINCIPAL.id, function (res) {
			if (res) {
				setListDO(res.data)
			}
		})
	}, [])

	useEffect(() => {
		const allowedToChangeCoordinator = () => {
			return (
				restrictedPdPContext ||
				isAdminOrManager ||
				(!isReadOnly && !isAdminOrManager && !freezePrincipal)
			)
		}
		setPrincipalFreezed(!allowedToChangeCoordinator())
	}, [isReadOnly, companyRecordState, restrictedPdPContext, freezePrincipal, isAdminOrManager])

	return (
		<>
			<Typography variant="body1" classes={{ root: classes.space }}>
				{t('numberPDP')} : {operationInfos.pdPNumber}
			</Typography>
			<InputSelectOnChange
				name="principal"
				label={t('principal')}
				classes={{ root: classes.space }}
				readOnly={principalFreezed}
				value={principal}
				onChange={(e) => {
					setPrincipal(e.target.value)
				}}
			>
				{listDO.map((contact) => (
					<SelectMenuItem value={contact.id} key={`${contact.id}`}>
						{contact.firstName} {contact.lastName}
					</SelectMenuItem>
				))}
			</InputSelectOnChange>
			<Typography variant="body1" classes={{ root: classes.type }}>
				{t('CompanyType')}
			</Typography>
			<div>
				<InputCheckbox name="principale" label={t('mainCompany')} readOnly />
				<InputCheckbox name="sousTraitante" label={t('SubContractingCompany')} readOnly />
			</div>
			<InputTextArea
				name="intervention"
				label={t('intervention')}
				classes={{ root: classes.space }}
				validate={required}
				readOnly={isReadOnly}
			/>
		</>
	)
}

export default StepOne
