import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { useTranslation } from 'react-i18next'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
import CertificationCard from '../../Cards/CertificationCard'
import ErrorOutline from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles(theme => ({
	certification: {
		position: 'relative',
		minHeight: 250,
	},
	buttonAddCertification: {
		position: 'absolute',
		right: '-33px',
		bottom: '-33px',
		zIndex: 99,
	},
	errorOutline: {
		verticalAlign: 'middle',
		width: '2em',
		height: '2em',
		color: theme.palette.secondary.main,
	},
	errorMessage: {
		display: 'inline-block',
		fontSize: '1.1em',
		marginLeft: 10,
		fontWeight: 500,
		color: theme.palette.secondary.main,
	},
	messageBlock: {
		textAlign: 'center',
	},
}))

/**
 * Third step of the operational informations form
 */
function ThirdStep({ certifications, setCertifications, updateCertificate, newCertificate }) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()

	/*Add button openning certificate form*/
	const AddCertificate = () => {
		const classes = useStyles()
		return (
			<Fab className={classes.buttonAddCertification} color="secondary" onClick={newCertificate}>
				<AddIcon />
			</Fab>
		)
	}

	return (
		<>
			<div className={classes.certification}>
				<AddCertificate />
				{certifications && certifications.length > 0 ? (
					certifications.map((certification, i) => {
						if (!certification.cftDateObtention) return
						return (
							<CertificationCard
								key={i}
								title={certification.cftIntitule}
								subtitle={certification.cftReference}
								date={moment(certification.cftDateObtention.toString()).format('DD/MM/YYYY')}
								duration={certification.cftDureeValidite}
								location={certification.cftLieu}
								edit={() => {
									updateCertificate(certification)
								}}
								path={certification.cftDocumentCheminComplet}
								deleteFc={() => {
									setCertifications(
										certifications.filter(cft => cft.cftId !== certification.cftId),
									)
								}}
							/>
						)
					})
				) : (
					<div className={classes.messageBlock}>
						<ErrorOutline className={classes.errorOutline} />
						<p className={classes.errorMessage}>{t('YouHaveNoCertification')}</p>
					</div>
				)}
			</div>
		</>
	)
}
export default ThirdStep
