import React, { useState, useEffect } from 'react'
import FormIcon from '../../../Form/FormIcon'
import InputCheckbox from '../../../Form/input/InputCheckbox'
import SlidingForm from '../../../Form/SlidingForm'
import { makeStyles } from '@material-ui/styles'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import Form from '../../../Form/Form'
import FilterResults from 'react-filter-search'
import { Scrollbars } from 'react-custom-scrollbars'
import { getCompanyCertifications } from '../../../../containers/DataContainer'
import { useCookies } from 'react-cookie'
import { Button } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
	},
	icon: {
		width: '2em',
		height: '2em',
	},
	certification: {
		color: theme.palette.primary.main,
		margin: '0 auto',
		display: 'block',
		border: 0,
		marginTop: 20,
	},
	validateGroup: {
		display: 'flex',
	},
	scrollbars: {
		height: '63vh',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
}))

/**
 * Shows a form to add a contact to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddCompanyFilesCertification({ open, close, data }) {
	const classes = useStyles()
	const [value, setValue] = useState('')
	const [cookies] = useCookies()
	const { t } = useTranslation('user')
	const [listCertifications, setListCertifications] = useState([])

	const getCertifications = () => {
		if (!open) return
		getCompanyCertifications(cookies[PLATFORM_ID_COOKIE], data.companyId, (res, err) => {
			if (err) return console.error('Error to get certifications !')
			setListCertifications(res.data)
		})
	}

	useEffect(getCertifications, [open])

	const onSubmit = (values) => {
		let certifications = listCertifications.map((certification) => {
			if (values[`certification-${certification.cftId}`]) return certification
		})
		data.setCertificationList([...data.certificationList, ...certifications.filter((n) => n)])
		setValue('')
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addFromLibrary')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							setValue('')
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				setValue('')
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{}}>
				<div className={classes.scrollbars}>
					<Scrollbars>
						<FilterResults
							value={value}
							data={listCertifications}
							renderResults={(results) => (
								<div>
									{data.certificationList &&
										results.map((el) => {
											if (data.certificationList.map((cert) => cert.cftId).includes(el.cftId))
												return
											return (
												<InputCheckbox
													name={'certification-' + el.cftId}
													label={el.cftIntitule + ' ' + el.cftReference}
													value={el.cftId}
													key={el.cftId}
												/>
											)
										})}
								</div>
							)}
						/>
					</Scrollbars>
				</div>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.buttonValidate }} type="submit" />
					<Button
						className={classes.certification}
						variant="outlined"
						onClick={() => {
							data.openCertificationDEForm()
							close()
						}}
					>
						<AddCircle className={classes.icon}></AddCircle>
					</Button>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddCompanyFilesCertification
