import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import { Form } from 'react-final-form'
import { Button, Stepper, Typography, StepButton, StepLabel, Step } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import Access from '../../containers/RoleContainer'
import ROLES from '../../constants/roles'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
	},
	hidden: {
		display: 'none',
	},
	content: {
		display: 'block',
		width: '100%',
	},
	stepIcon: {
		color: theme.palette.secondary.main + '!important',
	},
	stepIconCompleted: {
		color: '#72B84D!important',
	},
	stepText: {
		fill: theme.palette.primary.main,
	},
	step: {},
	completedIcon: { color: theme.palette.primary.main },
	BasicIcon: {},
	show: {
		display: 'initial',
	},
	stepper: {
		backgroundColor: 'rgba(0,0,0,0)',
		paddingBottom: 0,
	},
	connector: {
		borderColor: 'rgba(255,255,255,0.3)',
	},
	StepCompleted: {
		marginTop: 50,
		color: '#72B84D',
		paddingLeft: 15,
		paddingRight: 15,
		fontWeight: 'bold',
	},
	nextPrevBlock: {
		display: 'flex',
	},
}))

function HorizontalStepper({
	contentStyle,
	buttonsStyle,
	result,
	initialValues,
	children,
	access = null,
}) {
	const classes = useStyles()
	const [step, setStep] = useState(0)
	const [completedSteps, setCompletedSteps] = useState([])
	const count = children.length
	const formRef = useRef()
	const [data, setData] = useState({})
	const { t } = useTranslation(['user', 'operationalInfos'])
	const { enqueueSnackbar } = useSnackbar()

	const isCompleted = (step1) => {
		let found = completedSteps.find((element) => element === step1)
		return found !== undefined
	}

	useEffect(() => {
		if (result && completedSteps.length >= count) {
			result(data)
			uncompleteAllSteps()
		}
	}, [data, completedSteps.length, count, result])

	const isLast = () => step + 1 >= count

	const isFirst = () => step - 1 < 0

	const complete = () => {
		if (isLast() && completedSteps.length < count - 1) {
			enqueueSnackbar(t('operationalInfos:steps_must_be_completed'), { variant: 'warning' })
			return
		}
		if (!isCompleted(step)) setCompletedSteps([...completedSteps, step])
	}

	const uncompleteByStep = (completedStep) => {
		setCompletedSteps(completedSteps.filter((stp) => stp !== completedStep))
	}

	const uncomplete = () => {
		setCompletedSteps(completedSteps.filter((stp) => stp !== step))
	}

	const uncompleteAllSteps = () => {
		setCompletedSteps([])
	}

	const nextStep = () => {
		if (isLast()) return
		setStep(step + 1)
	}

	const previousStep = () => {
		if (isFirst()) return
		setStep(step - 1)
	}

	const handleStep = (pStep) => () => {
		setStep(pStep)
	}

	const changeEvent = () => {
		uncomplete()
	}

	const saveData = (dt) => {
		setData({ ...data, ...dt })
	}

	const submit = (values) => {
		if (!children[step].props.validate || children[step].props.validate(values)) {
			complete()
			saveData(values)
			nextStep()
		}
	}

	return (
		<div className={classes.root}>
			<div className={classNames([classes.content, contentStyle])}>
				<Form
					onSubmit={submit}
					initialValues={initialValues}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit} onChange={changeEvent}>
							{children[step].props.children}
							<input ref={formRef} type="submit" className={classes.hidden} />
						</form>
					)}
				/>
			</div>

			<HStepper activeStep={step}>
				{children.map((child, i) => {
					const Props = child.props
					return (
						<Step key={i} {...Props} completed={isCompleted(i)}>
							<StepButton
								onClick={handleStep(i)}
								classes={{ root: classes.stepButton, horizontal: classes.stepButton }}
							>
								<StepLabel
									StepIconProps={{
										classes: {
											root: isCompleted(i) ? classes.stepIconCompleted : classes.stepIcon,
											text: classes.stepText,
											active: classes.stepIcon,
										},
									}}
								>
									{Props.label}
								</StepLabel>
							</StepButton>
						</Step>
					)
				})}
			</HStepper>

			<div className={buttonsStyle.root}>
				<div className={classes.nextPrevBlock}>
					{!isFirst() && (
						<Button
							className={buttonsStyle.previous}
							onClick={() => {
								uncompleteByStep(step)
								if (!isFirst()) previousStep()
							}}
						>
							{t('user:previous')}
						</Button>
					)}
					{!isLast() && (
						<Button
							className={buttonsStyle.previous}
							onClick={() => {
								nextStep()
							}}
						>
							{t('user:next')}
						</Button>
					)}
				</div>
				<Access accept={access ?? Object.keys(ROLES).map((x) => ROLES[x])}>
					{!isLast() ? (
						isCompleted(step) ? (
							<Typography className={classes.StepCompleted}>
								{t('user:AlreadyCompleteStep')}
							</Typography>
						) : (
							<Button
								className={buttonsStyle.previous}
								disabled={isCompleted(step)}
								onClick={() => formRef.current.click()}
							>
								{t('user:completeStep')}
							</Button>
						)
					) : (
						<Button
							className={buttonsStyle.finished}
							disabled={isCompleted(step)}
							onClick={() => formRef.current.click()}
						>
							{t('user:finish')}
						</Button>
					)}
				</Access>
			</div>
		</div>
	)
}
HorizontalStepper.propTypes = {
	companyID: PropTypes.number,
	contentStyle: PropTypes.string,
	buttonsStyle: PropTypes.object,
	result: PropTypes.func,
	initialValues: PropTypes.object,
	children: PropTypes.array,
}

function HStepper({ children, ...props }) {
	const classes = useStyles()

	return (
		<Stepper
			nonLinear
			alternativeLabel
			classes={{
				root: classes.stepper,
			}}
			{...props}
		>
			{children}
		</Stepper>
	)
}
HStepper.propTypes = {
	children: PropTypes.array,
}
export default HorizontalStepper
