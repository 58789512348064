const STATUS_DE = {
	VALIDE: { id: 4, code: 'valide' },
	SOUMIS: { id: 3, code: 'soumis' },
	INCOMPLET: { id: 2, code: 'incomplet' },
	BROUILLON: { id: 1, code: 'brouillon' },
}

export const findStatusById = id =>
	Object.values(STATUS_DE).filter(status => {
		return status.id === id
	})[0]

export const findStatusByCode = code =>
	Object.values(STATUS_DE).filter(status => {
		return status.code === code.toLowerCase()
	})[0]

export default STATUS_DE
