import { CircularProgress } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { setDEEnvironmentalAspect } from '../../../containers/DataContainer'
import { getFileToByteArray } from '../../../utils/File'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import FormIcon from '../../Form/FormIcon'
import InputFile from '../../Form/input/InputFile'
import InputRadio, { TotalRadio } from '../../Form/input/InputRadio'
import SlidingForm from '../../Form/SlidingForm'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},

	charactersAllowed: {
		margin: '20px 0',
		color: '#fff',
	},
}))

function EnvironmentalAspectNewFileForm({ open, close, data }) {
	const { t } = useTranslation(['user'])
	const classes = useStyles()
	const { enqueueSnackbar } = useSnackbar()
	const [file, setFile] = useState(null)
	const [loader, setLoader] = useState(false)

	const onSubmit = (values) => {
		getFileToByteArray(file, function (res, cberror = null) {
			if (!cberror) {
				const formData = new FormData()
				formData.append('files[0].value', res, res.name)
				formData.append('files[0].environmentalAspectCategory', values.type)

				for (let index = 0; index < data.listAspects.length; index++) {
					formData.append(`files[${index + 1}].dateAdded`, data.listAspects[index].dateAdded)
					formData.append(`files[${index + 1}].fileName`, data.listAspects[index].fileName)
					formData.append(`files[${index + 1}].path`, data.listAspects[index].path)
					formData.append(`files[${index + 1}].username`, data.listAspects[index].username)
				}

				setDEEnvironmentalAspect(data.platformId, data.companyRecordId, formData, (res, err) => {
					if (res) {
						if (data.setRefreshChevrons) data.setRefreshChevrons(!data.refreshChevrons)
						if (res.data && res.data.companyRecordId)
							data.setRevisionCompanyRecordId(res.data.companyRecordId)
						data.setUpdate(!data.update)
						close()
					}

					if (err) {
						enqueueSnackbar(t('AuthorizedFormat'), { variant: 'error' })
						setLoader(false)
					}
				})
			} else {
				enqueueSnackbar(t(cberror.errorCode), {
					variant: 'error',
				})
				close()
			}
		})
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('AddFile')}
			iconAttachement={
				<>
					<FormIcon icon="clear" onClick={close} />
				</>
			}
			submitLabel="Valider"
			onClose={close}
		>
			<Form onSubmit={onSubmit} initialValues={{ type: 1 }}>
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('joinFile')}

					<Typography component="span" className={classes.charactersAllowed}>
						{t('file_characters_allowed')}
					</Typography>
				</Typography>
				<InputFile label={t('upload')} name="upload" setFile={setFile} />
				<Typography variant="subtitle1" color="primary" className={classes.text}>
					{t('fileIsAbout')}
				</Typography>
				<InputRadio name="type">
					<TotalRadio className={classes.radioInput} label="Analyse environnementale" value="1" />
					<TotalRadio className={classes.radioInput} label="Déchets générés" value="2" />
				</InputRadio>
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>{t('UploadInProgress')}</Typography>
						</div>
					</>
				)}
				<FormButtonIcon type="submit" />
			</Form>
		</SlidingForm>
	)
}

export default EnvironmentalAspectNewFileForm
