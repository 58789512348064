export const convertArrayToDatatable = (data, hideColumns = null, options = null) => {
	if (!options)
		options = {
			selectableRows: 'none',
			filter: true,
			filterType: 'textField',
			responsive: 'scrollMaxHeight',
		}
	let columnNames = Object.keys(data[0])
	let columns = columnNames.map((name) => ({ name: name, options: { filter: true, sort: false } }))

	if (hideColumns && Array.isArray(hideColumns)) {
		let columnsWithHidden = columns.map((column) => {
			if (hideColumns.some((hideColumn) => column.name === hideColumn))
				return { ...column, options: { ...column.options, display: 'excluded' } }
			return column
		})
		return { columns: columnsWithHidden, data, options }
	}

	return { columns, data, options }
}

export const getIndexOfColumn = (columns, columnName) => {
	return columns.findIndex((column) => column.name === columnName)
}

export const COLUMNS_PDP = {
	COMPANYCONTRACTTYPE: 'companycontracttype',
	COMPANYRECORDID: 'companyrecordid',
	COMPANYFULLNAME: 'companyfullname',
	CRREVISIONNUMBER: 'cr-revisionnumber',
	SUBCONTRACTORSCOUNT: 'subcontractorscount',
	REVISIONNUMBER: 'revisionnumber',
	COMPANYRECORDSTATE: 'companyrecordstate',
	TYPE: 'type',
	PRELIMINARYVISIT: 'preliminaryvisit',
	COORDINATIONMEETING: 'coordinationmeeting',
	DWPSENDINGMODECODE: 'dwpsendingmodecode',
	DWPSTATUSCODE: 'dwpstatuscode',
	ISPRINCIPAL: 'isprincipal',
	ISSUBCONTRACTOR: 'issubcontractor',
	OPERATIONRESPONSABLEFULLN: 'operationresponsablefullname',
	COMPANYID: 'companyid',
	CRPRINCIPAL: 'crprincipal',
	CREATORFULLNAME: 'creatorfullname',
	DWPSENDINGMODELABEL: 'dwpsendingmodelabel',
	DWPSTATUSLABEL: 'dwpstatuslabel',
	DWPERRORCODE: 'dwperrorcode',
	PRINCIPALNAME: 'principalcoordinator',
}

export const COLUMNS_USERS = {
	ID: 'id',
	OWNER: 'owner',
	PHONENUMBER: 'phone-number',
	LOGINTECH: 'logintech',
	LOGIN: 'login',
	EMAIL: 'email',
	FUNCTION: 'function',
	STATE: 'state',
	INFO: 'info',
	PLATFORMS: 'platforms',
	ESTEDITABLE: 'estEditable',
	SIRET: 'siret',
	SOCIALREASON: 'socialReason',
	ISACTIVE: 'isActive',
}

export const COLUMNS_FOLLOW_PDP = {
	NUMERO_PDP: 'numero_pdp',
	STATUS: 'status',
	NUMERO_FORMAT: 'numero_format',
	LABEL: 'label',
	WORKS_LOCATIONS: 'works_locations',
	DWPSTATUSCODE: 'dwpStatusCode',
	DWPSTATUSLABEL: 'dwpStatusLabel',
	DATE_DEBUT: 'date_debut',
	DATE_FIN: 'date_fin',
	DATE_DIFFUSION: 'date_diffusion',
	ID: 'id',
}

export const COLUMNS_OPENING_REQUESTS = {
	OPENINGREQUESTDATE: 'openingrequestdate',
	COMPANYNAME: 'companyname',
	COMPANYAGENCYNAME: 'companyagencyname',
	COMPANYSIRET: 'companysiret',
	COMPANYPHONE: 'companyphone',
	COMPANYEMAIL: 'companyemail',
	COMPANYSTATEID: 'companystateid',
	COMPANYSTATE: 'companystate',
	COMPANYID: 'companyid',
	COMPANYJUSTIFICATION: 'companyjustification',
}

export const COLUMNS_MAILS_COMMUNICATION = {
	ID: 'id',
	RECIPIENTS: 'recipients',
	SUBJECT: 'subject',
	DATE: 'date',
	PLATFORMID: 'platformId',
	PLATFORMNAME: 'platformName',
	USERID: 'userId',
	USERFULLNAME: 'userFullName',
	ATTACHMENT: 'attachment',
	MESSAGE: 'message',
}

export const COLUMNS_SUBCONTRACTORS_COMPANIES = {
	COMPANYID: 'companyId',
	COMPANYNAME: 'companyName',
	RESPONSIBLEFULLNAME: 'responsibleFullName',
	WORKDESCRIPTION: 'workDescription',
	PRINCIPALCOMPANYRECORDID: 'principalCompanyRecordId',
	ISNEWFROMREVISION: 'isNewFromRevision',
	AGREEMENTEXPIRED: 'agreementExpired',
}

export const COLUMNS_ACTIVITY_PHASES_MANAGEMENT = {
	PACID: 'pacId',
	ORDER: 'order',
	LPANOM: 'lpaNom',
	ISACTIVE: 'isActive',
	PACESTINTERDITPDPPERMANENT: 'pacEstInterditPdpPermanent',
	PACESTINTERDITPDPRESTREINT: 'pacEstInterditPdpRestreint',
	ISREQUIRED: 'isRequired',
	PACDOCUMENTCHEMINCOMPLET: 'pacDocumentCheminComplet',
	ISNEWFROMREVISION: 'isNewFromRevision',
}

export const COLUMNS_FOLLOW_CR = {
	PRINCIPALID: 'principalId',
	ENTREPRISESOUSTRAITANTE: 'entrepriseSousTraitante',
	ENTREPRISEPRINCIPAL: 'entreprisePrincipal',
	STATUTDE: 'statutDE',
	ENTID: 'entId',
	DOCUMENTCHEMINCOMPLET: 'DocumentCheminComplet',
	PDPID: 'pdpId',
	PDPREV: 'pdpRev',
	DEPID: 'depId',
	NUMEROPDP: 'numeroPdp',
	LIBELLEPDP: 'libellePdP',
	DESCRIPTIONLIEUXPDP: 'descriptionLieuxPdP',
	PRINCIPALNAME: 'principalName',
	NATUREINTERVENTION: 'natureIntervention',
	DWPSTATUSCODE: 'dwpStatusCode',
	NOMENTREPRISE: 'nomEntreprise',
	TYPE: 'type',
	REVISIONDOSSIER: 'revisionDossier',
	LOCALSTATUS: 'localStatut',
	VISITEPREALABLE: 'visitePrealable',
	DERNIEREMODIFICATION: 'derniereModification',
	COMMENT: 'comment',
	CREATEUR: 'createur',
	DWPSTATUSLABEL: 'dwpStatusLabel',
	ISLASTREVISION: 'isLastRevision',
}

export const COLUMNS_APPROVAL_CR = {
	DEPID: 'depId',
	NUMEROPDP: 'numeroPdp',
	NATUREINTERVENTION: 'natureIntervention',
	NOMENTREPRISE: 'nomEntreprise',
	REVISIONDOSSIER: 'revisionDossier',
	STATUTAPPROBATION: 'statutApprobation',
	COMMENT: 'comment',
}

export const COLUMNS_CHEMICAL_PRODUCTS = {
	ID: 'id',
	NAME: 'name',
	DANGERPHRASE: 'dangerphrase',
	SUPPLIERNAME: 'suppliername',
	FDSVALIDITYDURATION: 'fdsvalidityduration',
	FDSVALIDITYENDDATE: 'fdsvalidityenddate',
	FDSPATH: 'fdspath',
	FDSNAME: 'fdsname',
}

export const COLMUNS_ENVIRONMENTAL_ASPECTS = {
	UPDATEDAT: 'updatedAt',
	OWNER: 'owner',
	FILENAME: 'fileName',
	FILE: 'file',
	ENVIRONMENTALASPECTCATEGORY: 'environmentalAspectCategory',
}

export const COLUMNS_HYGYENE_HEALTH = {
	ID: 'id',
	COMPANYRECORDID: 'companyRecordId',
	STARTDATE: 'startDate',
	ENDDATE: 'endDate',
	PERIODICMEDICALEXAMINATION: 'periodicMedicalExamination',
	HARMFULPRODUCT: 'harmfulProduct',
	WORKSTATION: 'workstation',
	WORKER: 'worker',
}

export const COLUMNS_OPERATIONAL_PROCEDURES = {
	UPDATEDAT: 'updatedAt',
	OWNER: 'owner',
	FILENAME: 'fileName',
	ISNEWFROMREVISION: 'isNewFromRevision',
	PATH: 'path',
	CONTENT: 'content',
	DATEADDED: 'dateAdded',
	USERNAME: 'username',
}
