import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { Redirect, Route } from 'react-router-dom'
import TotalLoader from '../components/TotalLoader/TotalLoader'
import { ACCOUNT_TYPES_COOKIE, USER_OBJECTID } from '../constants/cookies'
import * as link from '../constants/url'
import api, { HttpCode } from '../utils/Requester'
import { SearchQueryStrings } from '../utils/Url'
import {
	REQUIRED_COOKIES,
	requiredCookiesIsSaved,
	resetAllRequiredCookies,
} from './CookieContainer'
import ROLES from '../constants/roles.js'
import PropTypes from 'prop-types'
import {
	FetchToken,
	loginRequest,
	setActiveAccount,
	msalInstance,
	fetchActiveAccount,
	setAuthority,
} from '../authConfig'
import { useSelector } from 'react-redux'
import { store } from '../store/store'
import { removeToken, setToken } from '../store/token/tokenSlice'
import { bindParams } from '../utils/Link'
import { axiosHeaders } from './DataContainer'

export function LogoutRoute({ layout, page, ...rest }) {
	const [cookies] = useCookies()
	const [check, setCheck] = useState(false)
	const [isLoading, setLoading] = useState(true)
	const [message, setMessage] = useState()
	const [linkRedirect, setLinkRedirect] = useState()
	const { t } = useTranslation('common')
	const token = useSelector((state) => state.token.token)

	useEffect(() => {
		if (token) {
			setLoading(false)
			;(async () => {
				if (!cookies[ACCOUNT_TYPES_COOKIE]) {
					await disconnect()
				}
			})()

			const DASHBOARD_REDIRECT = [
				ROLES.ADMIN,
				ROLES.PLATFORMMANAGER,
				ROLES.PRINCIPAL,
				ROLES.DOCUMENTATION,
			]
			const FOLLOWPDP_REDIRECT = [ROLES.EXTERNALOBS]

			let redirectToDashboard = false
			let redirectToFollowPdp = false

			cookies[ACCOUNT_TYPES_COOKIE].map((c) => {
				if (DASHBOARD_REDIRECT.find((d) => d.code === c.code)) redirectToDashboard = true
				if (FOLLOWPDP_REDIRECT.find((d) => d.code === c.code)) redirectToFollowPdp = true
			})

			if (redirectToDashboard) setLinkRedirect('/dashboard')
			else if (redirectToFollowPdp) setLinkRedirect('/followPdp')
			else {
				// Default case
				setLinkRedirect('/followCR')
			}

			setCheck(true)
			return
		}
		api.get(link.GETPLATFORMS).then((res) => {
			if (res.ok) return setLoading(false)
			setMessage(t('unavailable_server'))
		})
	}, [])

	if (isLoading) return <TotalLoader label={message} />

	return (
		<>
			{check && <Redirect to={linkRedirect} />}
			{!check && <TotalRoute layout={layout} page={page} {...rest} />}
		</>
	)
}
LogoutRoute.propTypes = {
	layout: PropTypes.func,
	page: PropTypes.func,
}

export function LoginRoute({ layout, page, accept = null, ...rest }) {
	const [answered, confirmAnswer] = useState(false)
	const [path, setPath] = useState()
	const isAuthenticated = fetchActiveAccount() !== null

	/**
	 * Check if date now is between token expire date and token expire date - 10 minutes
	 * if so  : refresh it
	 * if less than expire date : nothing
	 * if greater than expire date : disconnect user
	 */
	useEffect(() => {
		if (!isAuthenticated || !requiredCookiesIsSaved(REQUIRED_COOKIES)) {
			setPath(disconnect())
			confirmAnswer(true)
			return
		}

		confirmAnswer(true)
	}, [page])

	return (
		<>
			{!answered && <TotalLoader />}
			{answered && isAuthenticated && <TotalRoute layout={layout} page={page} {...rest} />}
			{answered && !isAuthenticated && !path && <Redirect to="/" />}
			{answered && !isAuthenticated && path && <Redirect to={`/?targetUrl=${path}`} />}
		</>
	)
}
LoginRoute.propTypes = {
	layout: PropTypes.func,
	page: PropTypes.func,
	accept: PropTypes.array,
}

export const dispatchToken = (token) => {
	store.dispatch(setToken(token))
}

/**
 * Disconnect from RaPSo-D
 * @param {bool} redirect
 * @returns
 */
export const disconnect = async (redirect = true) => {
	const location = window.location
	const queries = SearchQueryStrings(location.href)

	const wantToDisconnect = queries.map((query) => query.key).includes('disconnect')

	try {
		resetAllRequiredCookies(REQUIRED_COOKIES)
		store.dispatch(removeToken())
		Cookies.remove(USER_OBJECTID)

		if (redirect) msalInstance().logoutRedirect('/')
		else await msalInstance().logoutPopup('/')
	} catch (e) {
		console.error(e)
	}
	if (!wantToDisconnect) return location.pathname
	return null
}

const handleUnauthorizedResponse = () => {
	axios.interceptors.response.use(
		function (response) {
			return response
		},
		async function (error) {
			if (HttpCode.Unauthorized === error.response.status) {
				await disconnect()
			} else {
				return Promise.reject(error)
			}
		},
	)
}
const handleRefreshToken = () => {
	axios.interceptors.request.use(
		async function (config) {
			if (store.getState().token.token != '') {
				const token = await FetchToken()
				const tmpHeaders = config.headers
				config.headers = {
					tmpHeaders,
					Authorization: `Bearer ${token}`,
				}
				dispatchToken(token)
			}

			return config
		},
		function (error) {
			return Promise.reject(error)
		},
	)
}

export function TotalRoute({ layout: Layout, page: Page, ...rest }) {
	return (
		<Route
			{...rest}
			render={(props) => (
				<Layout>
					<Page {...props} />
				</Layout>
			)}
		/>
	)
}
TotalRoute.propTypes = {
	layout: PropTypes.func,
	page: PropTypes.func,
}

export function sendAuth(isInternal, callback) {
	setAuthority(isInternal)
	msalInstance()
		.loginPopup(loginRequest)
		.then(async (res) => {
			setActiveAccount()
			const token = await FetchToken()
			axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
			dispatchToken(token)
			handleUnauthorizedResponse()
			handleRefreshToken()

			return callback(res, null)
		})
		.catch((err) => {
			if (err.errorCode) {
				return callback(null, err.errorCode)
			} else {
				return callback(null, 'substitue-sql-error') // Generic error
			}
		})
}

export function internalUserAuthentication(callback) {
	axios({
		method: 'POST',
		url: link.LOGIN,
		responseType: 'json',
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}

export function verifyToken(token, platformId, callback) {
	axios({
		method: 'GET',
		url: link.TOKEN + '?p_PlatformId=' + platformId,
		headers: {
			Authorization: `Bearer ${token}`,
		},
		responseType: 'json',
	})
		.then((res) => callback(res, null))
		.catch((err) => callback(null, err.response))
}

export function useMatch(match, url) {
	let backUrl = url
	let companyId =
		match && match.params && match.params.companyId ? match.params.companyId : undefined
	let formatUrl = companyId ? backUrl + '/' + companyId : backUrl
	return { companyId, formatUrl }
}

export function matchUrl(match, url) {
	let companyId =
		match && match.params && match.params.companyId ? match.params.companyId : undefined
	return companyId ? url + '/' + companyId : url
}

export function DocumentationUserAuthentication(platformId, callback) {
	axios({
		method: 'POST',
		url: bindParams(link.DOCUMENTATION_AUTHENTICATION, platformId),
		responseType: 'json',
		headers: {
			...axiosHeaders('application/json'),
		},
	})
		.then((res) => {
			return callback(res, null)
		})
		.catch((err) => {
			return callback(null, err.response)
		})
}
