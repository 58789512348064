import React from 'react'

function CRStepInitializer({
	currStep,
	content: Content,
	forms,
	stepperBtns,
	queries,
	isReadOnly,
	setRevisionCompanyRecordId,
	setCompanyRecordId,
	restrictedPdPContext,
	...props
}) {
	return (
		<>
			{forms && forms.map((form, i) => <React.Fragment key={i}>{form.formular}</React.Fragment>)}
			<Content
				{...props}
				currStep={currStep}
				forms={forms}
				btnsStyle={stepperBtns}
				queries={queries}
				isReadOnly={isReadOnly}
				setRevisionCompanyRecordId={setRevisionCompanyRecordId}
				setCompanyRecordId={setCompanyRecordId}
				restrictedPdPContext={restrictedPdPContext}
			/>
		</>
	)
}

export default CRStepInitializer
