import { Button, CircularProgress, Typography } from '@material-ui/core'
import AddCircle from '@material-ui/icons/AddCircle'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { OTHER_DOCUMENT_DESCRIPTION } from '../../../../constants/url'
import { getotherDocuments } from '../../../../containers/DataContainer'
import { useOtherFilesInformationForm } from '../../../../containers/FormContainer'
import { bindParams } from '../../../../utils/Link'
import api from '../../../../utils/Requester'
import Form from '../../../Form/Form'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import FormIcon from '../../../Form/FormIcon'
import InputCheckbox from '../../../Form/input/InputCheckbox'
import SlidingForm from '../../../Form/SlidingForm'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
		marginTop: '20px',
	},
	addIcon: {
		width: '2em',
		height: '2em',
	},
	validateGroup: {
		display: 'flex',
	},
	scrollbars: {
		height: '63vh',
	},
	inputSearch: {
		margin: 10,
		width: '90%',
	},
	progress: {
		display: 'inline-block',
		verticalAlign: 'middle',
	},
	progressLabel: {
		color: theme.palette.primary.main,
		display: 'inline-block',
		marginLeft: 10,
	},
	progressBlock: {
		margin: 10,
	},
}))

/**
 * Shows a form to add a contact to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddCompanyFilesDocuments({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [listFiles, setListFiles] = useState([])
	const [loader, setLoader] = useState(false)
	const { enqueueSnackbar } = useSnackbar()

	const OtherFilesInformationsForm = useOtherFilesInformationForm((dt) => {
		setLoader(true)
		const formData = new FormData()
		formData.append('p_OtherDoc.value', dt, dt.name)
		api
			.post(bindParams(OTHER_DOCUMENT_DESCRIPTION, data.platformId, data.companyId), formData)
			.then((res) => {
				const { ok } = res
				if (ok && res.data) {
					const newDoc = res.data.filter((doc) => doc.fileName === dt.name)
					data.setDocumentsList([...data.documentsList, ...newDoc])
					enqueueSnackbar(t('YourDataHasBeenEdited'), { variant: 'success' })
				} else {
					enqueueSnackbar(t('FailToSubmit'), { variant: 'error' })
				}
			})
			.catch((err) => {
				console.error('Error to upload documents !', err)
				enqueueSnackbar(t('FailToSubmit'), { variant: 'error' })
			})
			.finally(() => {
				setLoader(false)
				close()
			})
	})

	const getDocuments = () => {
		if (!open) return
		getotherDocuments(data.companyId, (res, err) => {
			if (err) return console.error('Error to get documents !')
			setListFiles(res.data)
		})
	}

	useEffect(getDocuments, [open])

	const onSubmit = (values) => {
		data.setDocumentsList([
			...data.documentsList,
			...listFiles.filter((file) => values[`file-${file.docId}`] !== undefined),
		])
		close()
	}

	return (
		<>
			{OtherFilesInformationsForm.formular}
			<SlidingForm
				direction="vertical"
				open={open}
				title={t('addFromLibrary')}
				iconAttachement={
					<>
						<FormIcon
							icon="clear"
							onClick={() => {
								close()
							}}
						/>
					</>
				}
				submitLabel="Valider"
				onClose={() => {
					close()
				}}
			>
				{loader && (
					<>
						<div className={classes.progressBlock}>
							<CircularProgress className={classes.progress} color="primary" />
							<Typography className={classes.progressLabel}>{t('UploadInProgress')}</Typography>
						</div>
					</>
				)}
				{!loader && (
					<Form onSubmit={onSubmit} initialValues={{}}>
						<div className={classes.scrollbars}>
							<Scrollbars>
								{listFiles &&
									listFiles.map((el, i) => (
										<InputCheckbox
											name={'file-' + el.docId}
											label={el.fileName}
											value={el.path}
											key={i}
										/>
									))}
							</Scrollbars>
						</div>
						<div className={classes.validateGroup}>
							<FormButtonIcon classes={{ root: classes.buttonValidate }} type="submit" />
							<Button
								className={classes.buttonValidate}
								onClick={() => OtherFilesInformationsForm.open()}
							>
								<AddCircle className={classes.addIcon} />
							</Button>
						</div>
					</Form>
				)}
			</SlidingForm>
		</>
	)
}

export default AddCompanyFilesDocuments
