import React, { Fragment, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { Typography, Slide, Drawer, Dialog, AppBar, Toolbar } from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'

// STYLES
const useStyles = makeStyles((theme) => ({
	horizontalContainer: {
		zIndex: theme.zIndex.drawer + 2,
		width: '100%',
		backgroundColor: '#134391',
		minHeight: 100,
		maxHeight: '100vh',
		bottom: '0px',
		padding: '40px 60px',
		left: 0,
	},
	verticalContainer: {
		zIndex: theme.zIndex.drawer + 2,
		height: '100vh',
		width: '500px',
		backgroundColor: '#134391',
		padding: '40px 60px',
		display: 'inline-flex',
		flexDirection: 'column',
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
		boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
	},
	fullScreenContainer: {
		zIndex: theme.zIndex.drawer + 2,
		width: '100%',
		height: 'calc(100% - 64px)',
		backgroundColor: '#134391',
		bottom: '0px',
		position: 'fixed',
		padding: '40px 60px',
		overflowY: 'auto',
		left: 0,
	},
	title: {
		fontWeight: 'bold',
		color: 'white',
		letterSpacing: 1,
		maxHeight: '32px',
		marginBottom: '50px',
		fontFamily: 'Roboto',
	},
	titleVertical: {
		fontWeight: 'bold',
		color: 'white',
		letterSpacing: 1,
		maxWidth: 300,
		fontFamily: 'Roboto',
	},
	iconButtons: {
		position: 'absolute',
		right: '75px',
		top: '25px',
	},
	buttons: {
		marginTop: theme.spacing.unit * 2,
	},

	content: {
		paddingBottom: '35px',
		minHeight: '80%',
	},
	scroll: {
		top: '-1px',
	},
	hrRoot: {
		margin: 0,
		marginTop: 8,
		marginRight: 'auto',
		maxWidth: 100,
		border: '1px solid' + theme.palette.primary.main,
	},
	appBar: {
		position: 'relative',
	},
	totalLogo: {
		display: 'block',
		marginLeft: 'auto',
		marginRight: 'auto',
	},
}))

function DialogTransition(props) {
	return <Slide direction="up" {...props} />
}

export default function SlidingForm({
	direction,
	open,
	title,
	iconAttachement,
	onClose,
	children,
}) {
	// STYLES
	const classes = useStyles()
	const [isOpen, setIsOpen] = useState(open)

	const close = () => {
		setIsOpen(false)
		onClose()
	}

	useEffect(() => {
		setIsOpen(open)
	}, [open])

	return (
		<Slider direction={direction} open={isOpen} close={close}>
			<div
				className={classNames({
					[classes.verticalContainer]: direction === 'vertical',
					[classes.horizontalContainer]: direction === 'horizontal' || direction == null,
					[classes.fullScreenContainer]: direction === 'fullscreen',
				})}
			>
				<Typography
					className={classNames({
						[classes.titleVertical]: direction === 'vertical',
						[classes.title]:
							direction === 'fullscreen' || direction === 'horizontal' || direction == null,
					})}
					variant="h5"
				>
					{title}
					<hr className={classes.hrRoot} />
				</Typography>

				<ScrollBar direction={direction}>
					<div className={classes.content}>{children}</div>
				</ScrollBar>
				<div className={classes.iconButtons}>{iconAttachement}</div>
			</div>
		</Slider>
	)
}

function ScrollBar({ direction, children }) {
	const classes = useStyles()

	return (
		<>
			{direction === 'vertical' && <Scrollbars className={classes.scroll}>{children}</Scrollbars>}
			{direction !== 'vertical' && <Fragment>{children}</Fragment>}
		</>
	)
}

function Slider({ direction, open, close, children }) {
	const classes = useStyles()
	const { t } = useTranslation('user')

	switch (direction) {
		case 'fullscreen':
			return (
				<Dialog fullScreen open={open} onClose={close} TransitionComponent={DialogTransition}>
					<AppBar className={classes.appBar}>
						<Toolbar>
							<a href="/" className={classes.totalLogo}>
								<img src="/images/total.png" alt={t('brand')} height="26" />
							</a>
						</Toolbar>
					</AppBar>
					{children}
				</Dialog>
			)
		case 'vertical':
			return (
				<Drawer anchor="right" open={open} onClose={close}>
					{children}
				</Drawer>
			)
		default:
			return (
				<Drawer anchor="bottom" open={open} onClose={close}>
					{children}
				</Drawer>
			)
	}
}
