import React, { useState, useEffect } from 'react'
import SlidingForm from '../Form/SlidingForm'
import ClearIcon from '@material-ui/icons/Clear'
import { IconButton, Typography } from '@material-ui/core'

function RefDocSignetsForm({ open, onClose, data }) {
	const props = {
		direction: 'vertical',
		title: 'Signets pour ' + data.section,
		iconAttachement: (
			<IconButton onClick={() => onClose()}>
				<ClearIcon color="primary" />
			</IconButton>
		),
	}

	const [signets, setSignets] = useState([])
	const [keys, setKeys] = useState([])

	useEffect(() => {
		if (!data) return

		setKeys(Object.keys(data.list))
		setSignets(Object.values(data.list))
	}, [data])

	return (
		<SlidingForm {...props} open={open} onClose={onClose}>
			<br />
			{keys.map((value, key) => (
				<Typography variant="subtitle1" color="primary">
					{value} : {signets[key]}
				</Typography>
			))}
		</SlidingForm>
	)
}

export default RefDocSignetsForm
