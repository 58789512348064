import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import {
	Typography,
	Button,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Dialog,
} from '@material-ui/core'
import Form from '../../../components/Form/Form'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'
import InputSearch from '../../../components/Form/input/InputSearch'
import FilterResults from 'react-filter-search'
import LazyLoad from 'react-lazyload'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import CheckIcon from '@material-ui/icons/Check'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		maxWidth: '60%',
		minWidth: 900,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	mainTitle: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		width: '60%',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	inputSearch: {
		width: '80%',
		margin: 20,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	dialog: {
		minWidth: 900,
	},
}))

function SearchExternalCompanies({ open, companyList, setClose, setSelectedValue }) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [value, setValue] = useState('')
	const [selected, setSelected] = useState()
	const { enqueueSnackbar } = useSnackbar()

	const close = () => {
		setValue('')
		setClose(false)
	}

	const search = (event) => {
		setValue(event.target.value)
	}

	const onSubmit = () => {
		if (selected) {
			setSelectedValue(selected)
			close()
		}
	}

	const handleRowClick = (el) => setSelected(el)

	return (
		<Dialog open={open} onClose={close} classes={{ paper: classes.dialog }}>
			<div className={classes.paper}>
				<Form onSubmit={onSubmit}>
					<Typography variant="h6" classes={{ root: classes.mainTitle }}>
						{t('AddingMainCompanies')}
					</Typography>
					<InputSearch
						name="search"
						label={t('SearchCompany')}
						onChange={search}
						classes={{ root: classes.inputSearch }}
						variant="FullPage"
					/>
					<Scrollbars autoHeight={true} autoHeightMin={'40vh'}>
						<Table className={classes.table}>
							<TableHead>
								<TableRow>
									<TableCell></TableCell>
									<TableCell>{t('companyName')}</TableCell>
									<TableCell>{t('Agency')}</TableCell>
									<TableCell>{t('socialReason')}</TableCell>
									<TableCell>{t('siretNumber')}</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<FilterResults
									value={value}
									data={companyList}
									renderResults={(companies) => (
										<>
											{companies.map((el, i) => (
												<LazyLoad key={el.entId} overflow offset={100}>
													<TableRow key={i} onClick={() => handleRowClick(el)}>
														<TableCell>{selected === el && <CheckIcon size="small" />}</TableCell>
														<TableCell component="th" scope="row">
															{el.entName}
														</TableCell>
														<TableCell>{el.entAgency}</TableCell>
														<TableCell>{el.entSocialReason}</TableCell>
														<TableCell>{el.entSiret}</TableCell>
													</TableRow>
												</LazyLoad>
											))}
										</>
									)}
								/>
							</TableBody>
						</Table>
					</Scrollbars>
					<div className={classes.buttonGroup}>
						<Button classes={{ root: classes.button }} onClick={close}>
							{t('cancel')}
						</Button>
						<Button
							className={classNames(classes.button, classes.validateButton)}
							type="submit"
							disabled={!selected}
						>
							{t('submit')}
						</Button>
					</div>
				</Form>
			</div>
		</Dialog>
	)
}
SearchExternalCompanies.propTypes = {
	open: PropTypes.bool,
	pdpId: PropTypes.string,
	setAddMajorCompagnies: PropTypes.func,
	revisionNumber: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	refreshDatatable: PropTypes.bool,
	setRefreshDatatable: PropTypes.func,
}
export default SearchExternalCompanies
