/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import { Button, Stepper, StepConnector } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'block',
	},
	hidden: {
		display: 'none',
	},
	content: {
		display: 'block',
		width: '100%',
	},
	step: {},
	completedIcon: { color: theme.palette.primary.main },
	BasicIcon: {},
	show: {
		display: 'initial',
	},
	stepper: {
		backgroundColor: 'rgba(0,0,0,0)',
		paddingBottom: 0,
	},
	connector: {
		borderColor: 'rgba(255,255,255,0.3)',
	},
}))

function VerticalStepper({
	contentStyle,
	buttonsStyle,
	initialValues = {},
	result,
	form = false,
	children,
}) {
	const classes = useStyles()
	const [step, setStep] = useState(0)
	const [completedSteps, setCompletedSteps] = useState([])
	const count = children.length
	const formRef = useRef()
	const [data, setData] = useState({})
	const { t } = useTranslation('user')

	const isCompleted = (step1) => {
		let found = completedSteps.find((element) => element === step1)
		return found !== undefined
	}

	useEffect(() => {
		if (result && completedSteps.length >= count) result(data)
	}, [data])

	const complete = () => {
		if (!isCompleted(step)) {
			setCompletedSteps([...completedSteps, step])
			nextStep()
		}
	}

	const uncomplete = () => {
		setCompletedSteps(completedSteps.filter((stp) => stp !== step))
	}

	const nextStep = () => {
		if (step + 1 >= count) setStep(0)
		else setStep(step + 1)
	}

	const previousStep = () => {
		if (step - 1 < 0) setStep(count - 1)
		else setStep(step - 1)
	}

	const changeEvent = (e) => {
		uncomplete()
	}

	const saveData = (dt) => {
		setData({ ...data, ...dt })
	}

	const isLastForm = () => {
		return completedSteps.length >= count - 1
	}

	const submit = (values) => {
		if (children[step].props.validate) {
			if (children[step].props.validate(values)) {
				complete()
				saveData(values)
			}
		} else {
			complete()
			saveData(values)
		}
	}

	const buttons = (
		<div className={buttonsStyle.root} key={step}>
			<Button className={buttonsStyle.previous} onClick={previousStep}>
				{t('previous')}
			</Button>

			<Button className={buttonsStyle.next} onClick={nextStep}>
				{t('next')}
			</Button>

			<Button
				className={classNames({ [classes.hidden]: isLastForm() }, buttonsStyle.completed)}
				disabled={isCompleted(step)}
				onClick={() => formRef.current.click()}
			>
				{t('completeStep')}
			</Button>

			<Button
				className={classNames({ [classes.hidden]: !isLastForm() }, buttonsStyle.finished)}
				disabled={isCompleted(step)}
				onClick={() => formRef.current.click()}
			>
				{t('finish')}
			</Button>
		</div>
	)

	const connector = (
		<StepConnector
			classes={{
				line: classes.connectorLine,
			}}
		/>
	)

	return (
		<div className={classes.root}>
			<Form onSubmit={submit} onChange={changeEvent} initialValues={initialValues}>
				<Stepper
					orientation="vertical"
					nonLinear
					connector={connector}
					classes={{
						root: classes.stepper,
					}}
					activeStep={step}
				>
					{children.map((child, i) => {
						const Step = child.type
						const props = child.props
						const chldr = [...props.children, buttons]
						return (
							<Step completed={isCompleted(i)} key={i} {...props}>
								{chldr}
							</Step>
						)
					})}
				</Stepper>
				<input ref={formRef} type="submit" className={classes.hidden} />
			</Form>
		</div>
	)
}
export default VerticalStepper
