import React from 'react'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import MassNotificationForm from '../../components/forms/MassNotificationForm'
import { useTranslation } from 'react-i18next'

/**
 * Shows the MyAccount page
 * @returns {JSX.Element} React stack to render
 */
// eslint-disable-next-line no-empty-pattern
function MassNotification({ match }) {
	const { t } = useTranslation('user')

	return (
		<>
			<HeadTitle title={t('MassNotifications')} link="/dashboard" />
			<MassNotificationForm match={match}/>
		</>
	)
}
export default MassNotification
