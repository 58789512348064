import React, { useState, useEffect } from 'react'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import FormIcon from '../../Form/FormIcon'
import SlidingForm from '../../Form/SlidingForm'
import { makeStyles } from '@material-ui/styles'
import Form from '../../Form/Form'
import FormButtonIcon from '../../Form/FormButtonIcon'
import Button from '@material-ui/core/Button'
import InputSelectField from '../../Form/input/InputSelect'
import { IconButton, Icon } from '@material-ui/core'
import SelectMenuItem from '../../Form/input/SelectMenuItem'
import classNames from 'classnames'
import { useCookies } from 'react-cookie'
import { getPdPCompanyDetails, updateCompanyPdPNonDraft } from '../../../containers/DataContainer'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import InputCheckbox from '../../Form/input/InputCheckbox'
import InputText from '../../Form/inputFullPage/InputText'

const useStyles = makeStyles((theme) => ({
	container: {
		color: theme.palette.primary.main,
		fill: theme.palette.primary.main,
	},
	nextStepArrow: {
		marginLeft: 'auto',
		marginRight: 'auto',
		display: 'block',
		fontSize: 75,
	},
	btn: {
		color: theme.palette.primary.main,
		borderColor: theme.palette.primary.main,
		display: 'block',
		width: '75%',
		marginLeft: 'auto',
		marginRight: 'auto',
		marginTop: theme.spacing.unit,
	},
	btnIcon: {
		verticalAlign: 'middle',
	},
	selectedRemoveBtn: {
		color: '#d92518',
	},
	selectedCheckBtn: {
		color: '#4CAF50',
	},
	active: {
		backgroundColor: '#4CAF50',
		borderColor: '#4CAF50',
		'&:hover': {
			backgroundColor: '#4CAF50',
			borderColor: theme.palette.primary.main,
			cursor: 'initial',
		},
	},
	dwpDisabled: {
		backgroundColor: '#e41749',
		borderColor: '#e41749',
		'&:hover': {
			backgroundColor: '#e41749',
			borderColor: theme.palette.primary.main,
			cursor: 'initial',
		},
	},
	checkbox: {
		marginLeft: '0',
	},
	comment: {
		backgroundColor: '#fff',
		'&:hover': {
			background: '#fff',
		},
		maxWidth: 250,
		paddingRight: 10,
		paddingLeft: 10,
		borderRadius: 5,
	},
}))

/**
 * Shows a form to edit a company to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function SocialReasonEditorForm({ open, close, data }) {
	const DWP_CODES = {
		AUTO: { code: 'Automatic', id: 2 },
		DEROG: { code: 'Derog', id: 1 },
		BANNED: { code: 'Forbidden', id: 0 },
	}

	const findDwpModeByCode = (code) =>
		Object.values(DWP_CODES).filter((s) => {
			if (s.code === code) return s
		})[0]

	const classes = useStyles()
	const [radio1, setRadio1] = useState(true)
	const [radio2, setRadio2] = useState(true)
	const [cookies] = useCookies()
	const [listResponsable, setListResponsable] = useState([])
	const [listPrincipal, setListPrincipal] = useState(null)
	const [dwpMode, setDwpMode] = useState(DWP_CODES.AUTO.code)
	const [isDWP, setIsDWP] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const [initialValues, setInitialValues] = useState({})
	const { t } = useTranslation(['user', 'pdpParticipatingCompanies'])

	const onSubmit = (values) => {
		let dwpAuthorization = findDwpModeByCode(dwpMode).id

		if (!values.responsable) {
			enqueueSnackbar(t('user:OperationnalResponsableRequired'), { variant: 'error' })
			return
		}
		if (listPrincipal && !values.principal) {
			enqueueSnackbar(t('user:PrincipalRequired'), { variant: 'error' })
			return
		}
		if (dwpMode === DWP_CODES.DEROG.code && !values.derogationComment) {
			enqueueSnackbar(t('pdpParticipatingCompanies:error_comment_dwp'), { variant: 'error' })
			return
		}

		let formData = {
			preliminaryVisit: radio1,
			coordinationMeeting: radio2,
			dwpAuthorization: dwpAuthorization,
			companyManagerId: values.responsable,
			companyId: data.companyId,
			isPrincipal: values.mainCompany,
			isSubcontractor: values.subContractingCompany,
			derogationComment: values.derogationComment,
			crPrincipalId: values.principal,
		}

		updateCompanyPdPNonDraft(
			cookies[PLATFORM_ID_COOKIE],
			data.pdpID,
			formData,
			function (res, error) {
				if (error) {
					enqueueSnackbar(t(error.data[0].code), { variant: 'error' })
					return
				}
				if (res) {
					enqueueSnackbar(t('TheCompanyHasBeenUpdated'), { variant: 'info' })
					close()
				}
				data.refresh()
				if (res.data.hasBeenRevised) {
					data.setRevisionNumber(res.data.revisionNumber)
					data.setRedirectRevision(true)
					return
				}
			},
		)
	}

	useEffect(() => {
		if (!data) return
		getPdPCompanyDetails(
			cookies[PLATFORM_ID_COOKIE],
			data.pdpID,
			data.companyId,
			function (res, err) {
				if (res) {
					setRadio1(res.data.preliminaryVisit)
					setRadio2(res.data.coordinationMeeting)
					setListResponsable(res.data.responsableList)
					setListPrincipal(res.data.crPrincipalList)
					setIsDWP(res.data.dwpStatus)
					setInitialValues({
						responsable: res.data.selectedResponsablId,
						mainCompany: res.data.isPrincipal,
						subContractingCompany: res.data.isSubcontractor,
						derogationComment: res.data.derogationComment,
						principal: res.data.crPrincipalId,
					})
					selectDwpMode(res.data.dwpStatus)
				}
				if (err) {
					enqueueSnackbar(t(err.code))
				}
			},
		)
	}, [data])

	const Radios = ({ label, state, setState }) => {
		return (
			<>
				<IconButton onClick={(e) => setState(true)}>
					<Icon
						color="primary"
						className={classNames(classes.btnIcon, { [classes.selectedCheckBtn]: state === true })}
					>
						check_circle
					</Icon>
				</IconButton>
				<IconButton onClick={(e) => setState(false)}>
					<Icon
						color="primary"
						className={classNames(classes.btnIcon, {
							[classes.selectedRemoveBtn]: state === false,
						})}
					>
						remove_circle
					</Icon>
				</IconButton>
				{label}
			</>
		)
	}

	const selectDwpMode = (code) => {
		setDwpMode(code)
	}

	const DWPModePicker = () => (
		<>
			<Button
				variant="outlined"
				onClick={() => {
					selectDwpMode(DWP_CODES.AUTO.code)
				}}
				className={classNames(classes.btn, {
					[classes.active]: dwpMode === DWP_CODES.AUTO.code,
				})}
			>
				<Icon className={classes.btnIcon}>autorenew</Icon>
				{t('Automatic')}
			</Button>
			<Button
				variant="outlined"
				onClick={() => {
					selectDwpMode(DWP_CODES.DEROG.code)
				}}
				className={classNames(classes.btn, {
					[classes.active]: dwpMode === DWP_CODES.DEROG.code,
				})}
			>
				<Icon className={classes.btnIcon}>reply</Icon>
				{t('Derogation')}
			</Button>
			<Button
				variant="outlined"
				onClick={() => {
					selectDwpMode(DWP_CODES.BANNED.code)
				}}
				className={classNames(classes.btn, {
					[classes.active]: dwpMode === DWP_CODES.BANNED.code,
				})}
			>
				<Icon className={classes.btnIcon}>remove_circle</Icon>
				{t('Prohibited')}
			</Button>
			<Icon color="primary" className={classes.nextStepArrow}>
				expand_more
			</Icon>
			<Button
				variant="outlined"
				disableRipple
				disableFocusRipple
				className={classNames(classes.btn, {
					[classes.dwpDisabled]: dwpMode === DWP_CODES.BANNED.code,
					[classes.active]: dwpMode === DWP_CODES.AUTO.code || dwpMode === DWP_CODES.DEROG.code,
				})}
			>
				<Icon className={classes.btnIcon}>call_split</Icon>DWP
			</Button>
		</>
	)

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('EditCompany')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={initialValues}>
				<br />
				<div className={classes.container}>
					<InputSelectField name="responsable" label={t('OperationManager')}>
						{listResponsable &&
							listResponsable.map((item) => (
								<SelectMenuItem value={item.id} key={item.id}>
									{item.firstName} {item.lastName}
								</SelectMenuItem>
							))}
					</InputSelectField>
					{listPrincipal && (
						<InputSelectField name="principal" label={t('principal')}>
							{listPrincipal.map((item) => (
								<SelectMenuItem value={item.id} key={item.id}>
									{item.firstName} {item.lastName}
								</SelectMenuItem>
							))}
						</InputSelectField>
					)}
					<Radios label={t('PreliminaryVisit')} state={radio1} setState={(b) => setRadio1(b)} />
					<br />
					<Radios label={t('CoordinationMeeting')} state={radio2} setState={(b) => setRadio2(b)} />
					<br />
					<InputCheckbox
						name="mainCompany"
						label={t('mainCompany')}
						helperStyle={classes.helpertext}
						labelClass={classes.checkbox}
					/>
					<InputCheckbox
						name="subContractingCompany"
						label={t('SubContractingCompany')}
						helperStyle={classes.helpertext}
						labelClass={classes.checkbox}
						disabled="true"
					/>
					<Icon color="primary" className={classes.nextStepArrow}>
						expand_more
					</Icon>
					<DWPModePicker />
					{dwpMode === DWP_CODES.DEROG.code && (
						<InputText
							name={'derogationComment'}
							label={t('comment')}
							classes={{ root: classes.btn, focused: classes.fcs }}
							inputProps={{
								className: classes.comment,
								maxLength: 255,
							}}
						/>
					)}
				</div>
				<FormButtonIcon
					type="submit"
					disable={initialValues.mainCompany || initialValues.subContractingCompany}
				/>
			</Form>
		</SlidingForm>
	)
}

export default SocialReasonEditorForm
