import React, { useState } from 'react'
import FormIcon from '../../Form/FormIcon'
import SlidingForm from '../../Form/SlidingForm'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

/**
 * write a description
 * @returns {JSX.Element} React stack to render
 */
function ValidationDEForm({ open, close, data }) {
	const { t } = useTranslation('user')

	const [societyName] = useState('Society Name')
	const [pdpNumber] = useState('.....-.')
	const [typeOf] = useState('...')

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('validateDE') + ` ${societyName}`}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Typography>{t('preventionPlanNumber') + `: ${pdpNumber}`}</Typography>
			<Typography>{t('natureintervention') + `: ${typeOf}`}</Typography>
		</SlidingForm>
	)
}

export default ValidationDEForm
