import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { NATIONALITIES } from '../../../constants/url'
import { composeValidators } from '../../../utils/Form'
import api from '../../../utils/Requester'
import { required, siretForeignerSize, siretSize, siretFormat } from '../../../utils/VerifyFields'
import InputNumber from '../../Form/inputFullPage/InputNumber'
import { InputSelectOnChange } from '../../Form/inputFullPage/InputSelect'
import InputText from '../../Form/inputFullPage/InputText'
import SelectMenuItem from '../../Form/inputFullPage/SelectMenuItem'
import { ACCOUNT_TYPES_COOKIE } from '../../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	rootText: {
		color: theme.palette.secondary.main,
		fontFamily: 'Helvetica',
		fontSize: '1.2em',
		fontWeight: 500,
	},
	contentStyle: {
		maxWidth: '95%',
		marginLeft: '2.5%',
		padding: '3%',
		borderRadius: '10px',
		backgroundColor: theme.palette.primary.main,
	},
	rootInput: {
		width: '100%',
		marginTop: '25px',
	},
	rootInputLeft: {
		marginTop: '25px',
		width: '47.5%',
		display: 'inline-block',
		marginRight: '5%',
	},
}))

/**
 * First step of the operational informations form
 */
function FirstStep({ initialValues, nationality, setNationality }) {
	const { t } = useTranslation(['user', 'validateData'])
	const classes = useStyles()
	const [nationalities, setNationalities] = useState([{}])
	const [cookies] = useCookies()
	const canEditCriticalInfo = cookies[ACCOUNT_TYPES_COOKIE].some(
		(acctype) => acctype.id === 1 || acctype.id === 2,
	)
	const NationalityRequestHandle = ({ ok, data }) => {
		if (ok && data) setNationalities(data)
	}
	useEffect(() => {
		api.get(NATIONALITIES).then(NationalityRequestHandle)
	}, [])

	const SiretPerNation = {
		fr: 285,
	}

	return (
		<>
			{/* STEP 1 */}
			<Typography classes={{ root: classes.rootText }}>
				<InputText
					name="socialReason"
					label={t('socialReason')}
					classes={{ root: classes.rootInput }}
					validate={composeValidators(required)}
					readOnly={!canEditCriticalInfo}
				/>
			</Typography>
			<Typography classes={{ root: classes.rootText }}>
				<InputText
					name="name"
					label={t('companyName')}
					classes={{ root: classes.rootInput }}
					validate={composeValidators(required)}
					readOnly={!canEditCriticalInfo}
				/>
			</Typography>
			<Typography classes={{ root: classes.rootText }}>
				<InputText
					name="nameAgency"
					label={t('agencyName')}
					classes={{ root: classes.rootInput }}
					validate={composeValidators(required)}
					readOnly={!canEditCriticalInfo}
				/>
			</Typography>
			<Grid container spacing={16} alignContent="center">
				<Grid item md={6} xs={12}>
					{nationality && nationality === SiretPerNation.fr ? (
						<InputNumber
							name="siret"
							label={t('siretNumber')}
							classes={{ root: classes.rootInput }}
							validate={composeValidators(required, siretSize, siretFormat)}
							readOnly={!canEditCriticalInfo}
						/>
					) : (
						<InputText
							name="siret"
							label={t('siretNumber')}
							classes={{ root: classes.rootInput }}
							validate={composeValidators(required, siretForeignerSize, siretFormat)}
						/>
					)}
				</Grid>
				<Grid item md={6} sm={12}>
					<InputSelectOnChange
						name="companyNationality"
						label={t('companyNationality')}
						classes={{ root: classes.rootInput }}
						value={nationality}
						onChange={(e) => {
							setNationality(e.target.value)
						}}
					>
						{nationalities.map((nationality, i) => {
							return (
								<SelectMenuItem key={i} value={nationality.id}>
									{nationality.name}
								</SelectMenuItem>
							)
						})}
					</InputSelectOnChange>
				</Grid>
			</Grid>
		</>
	)
}
export default FirstStep
