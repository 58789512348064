import React, { useState, useEffect, useCallback } from 'react'
import { makeStyles } from '@material-ui/styles'
import Form from '../../Form/Form'
import FormIcon from '../../Form/FormIcon'
import SlidingForm from '../../Form/SlidingForm'
import FormButton from '../../Form/FormButton'
import { useTranslation } from 'react-i18next'
import SelectMenuItem from '../../Form/input/SelectMenuItem'
import { required } from '../../../utils/VerifyFields'
import InputSelectField from '../../Form/input/InputSelect'
import api from '../../../utils/Requester'
import { ACCOUNTBYTYPEPLATFORM, ALTERNATES_ADD, ALTERNATES_ELIGIBLE } from '../../../constants/url'
import { useCookies } from 'react-cookie'
import { useSnackbar } from 'notistack'
import InputCheckboxGroup from '../../Form/input/InputCheckboxGroup'
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'
import { bindParams } from '../../../utils/Link'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'

const useStyles = makeStyles((theme) => ({
	profile: {
		textAlign: 'left',
	},
	submit: {
		display: 'block',
		marginTop: theme.spacing.unit * 5,
		margin: 'auto',
	},
	listCheckbox: {
		maxWidth: 450,
		margin: 'auto',
		height: '55vh',
		overflowY: 'auto',
	},
	topMargin: {
		marginTop: '20px',
	},
	viceVersa: {
		color: 'white',
	},
}))

function AlternatesForm({ open, close, data: pData }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'accountManagment', 'alternatesManagement'])
	const [cookies] = useCookies()
	const [listDO, setListDO] = useState([])
	const [listSectors, setListSectors] = useState([])
	const { enqueueSnackbar } = useSnackbar()
	const [valueCheckbox, setValueCheckbox] = useState([])
	const [viceVersaSwitch, setViceVersa] = useState(false)

	const recoveryAllDO = (response) => {
		const { data, ok } = response

		if (!ok) {
			enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
			return
		}

		if (data) {
			setListDO(data)
		}
	}

	const recoveryAlternatesEligible = (reponse) => {
		const { data, ok } = reponse

		if (!ok) {
			enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
			return
		}

		if (data) {
			setListSectors(data)
		}
	}

	const getAllDO = () => {
		api
			.get(ACCOUNTBYTYPEPLATFORM, { p_PlatformId: cookies[PLATFORM_ID_COOKIE], p_AccountType: 3 })
			.then(recoveryAllDO)
	}

	const isAllFalse = (array) => {
		let flag = true
		array.map((x) => {
			if (x.checked === true) flag = false
			return x
		})
		return flag
	}

	const emptyAndCloseForm = () => {
		setListDO([])
		setListSectors([])
		close()
	}

	const onSubmit = (value) => {
		//Check principalDO is not empty
		if (!value.principalDO) return
		//Check valueCheckbox is different of 0
		if (valueCheckbox.length !== 0) {
			if (isAllFalse(valueCheckbox)) {
				enqueueSnackbar(t('alternatesManagement:emptyAlternates'), { variant: 'info' })
				return
			} else {
				//Selected alternates
				let selectedAlternates = []
				for (const checkbox of valueCheckbox) {
					if (checkbox.checked)
						selectedAlternates = [...selectedAlternates, parseInt(checkbox.id, 10)]
				}
				addAlternate(selectedAlternates, value.principalDO, viceVersaSwitch)
			}
		} else {
			enqueueSnackbar(t('alternatesManagement:emptyAlternates'), { variant: 'info' })
		}
	}

	const addAlternate = (selectedAlternates, userId, viceVersa) => {
		api
			.post(bindParams(ALTERNATES_ADD, cookies[PLATFORM_ID_COOKIE]), {
				substitudeIds: selectedAlternates,
				userId: userId,
				viceVersa: viceVersa,
			})
			.then((res) => {
				const { ok } = res

				if (!ok) {
					enqueueSnackbar(t('view_render_failed'), { variant: 'error' })
					return
				}

				if (pData.refreshAll) pData.refreshAll()

				emptyAndCloseForm()
			})
	}

	const getAlternatesEligible = (value) => {
		api
			.get(bindParams(ALTERNATES_ELIGIBLE, cookies[PLATFORM_ID_COOKIE], value))
			.then(recoveryAlternatesEligible)
	}

	const changeViceVersa = useCallback((e) => {
		setViceVersa(e.target.checked)
	})

	useEffect(() => {
		if (open) getAllDO()
	}, [open])

	return (
		<>
			<SlidingForm
				direction="vertical"
				open={open}
				title={t('alternatesManagement:alternatesForm')}
				onClose={() => {
					emptyAndCloseForm()
				}}
				iconAttachement={
					<>
						<FormIcon icon="clear" onClick={close} />
					</>
				}
			>
				<Form onSubmit={onSubmit} className={classes.topMargin}>
					<InputSelectField
						name="principalDO"
						validate={required}
						label={t('accountManagment:principal')}
						onChange={(e) => {
							getAlternatesEligible(e.target.value)
							setValueCheckbox([])
						}}
					>
						{listDO &&
							listDO.map((oneDO) => {
								return (
									<SelectMenuItem key={oneDO.id} value={oneDO.id}>
										{oneDO.firstName} {oneDO.lastName}
									</SelectMenuItem>
								)
							})}
					</InputSelectField>
					<div className={classes.listCheckbox}>
						{listSectors.length !== 0 && (
							<InputCheckboxGroup
								name={'sectorAlternates'}
								label={t('alternatesManagement:Alternates')}
								setValueCheckbox={setValueCheckbox}
								valueCheckbox={valueCheckbox}
							>
								{listSectors.map((sector) => {
									return (
										<Checkbox
											name={`Id_${sector.id}`}
											label={`${sector.firstName} ${sector.lastName}`}
											key={sector.id}
										/>
									)
								})}
							</InputCheckboxGroup>
						)}
					</div>
					<FormControlLabel
						classes={{
							label: classes.viceVersa,
						}}
						control={
							<Switch
								size="normal"
								name="vice-versa"
								onClick={changeViceVersa}
								checked={viceVersaSwitch}
								color="primary"
							/>
						}
						label={t('alternatesManagement:viceVersa')}
						labelPlacement="start"
					/>
					<FormButton
						classes={{
							root: classes.submit,
						}}
						type="submit"
						label={t('submit')}
					/>
				</Form>
			</SlidingForm>
		</>
	)
}

export default AlternatesForm
