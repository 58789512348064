import React, { useState, useEffect } from 'react'
import { Typography, Button, IconButton, Icon, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { useTranslation } from 'react-i18next'
import { downloadFile } from '../../../containers/DataContainer'
import {
	COLUMNS_CHEMICAL_PRODUCTS,
	convertArrayToDatatable,
	getIndexOfColumn,
} from '../../../utils/Datatable'
import api from '../../../utils/Requester'
import { CHEMICALPRODUCTS } from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import fileDownload from 'js-file-download'
import { useSnackbar } from 'notistack'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { managedFileNotFoundError } from '../../../utils/File'

const useStyles = makeStyles((theme) => ({
	paper: {
		padding: 20,
	},
	title: {
		fontSize: '2em',
		color: theme.palette.secondary.main,
		fontWeight: 500,
	},
	categoryTitle: {
		color: theme.palette.secondary.main,
	},
	dataTableContainer: {
		position: 'relative',
	},
	button: {
		position: 'absolute',
		top: 14,
		right: 300,
	},
	fdsValidity: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	icon: {
		marginRight: 30,
	},
	green: {
		color: 'limegreen',
	},
}))

ChemicalsProducts.propTypes = {
	forms: PropTypes.array,
	btnsStyle: PropTypes.object,
	queries: PropTypes.object,
	isReadOnly: PropTypes.bool,
	isReviewMode: PropTypes.bool,
	setRevisionCompanyRecordId: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
}
function ChemicalsProducts({
	forms,
	btnsStyle,
	queries,
	isReadOnly,
	isReviewMode = false,
	setRevisionCompanyRecordId,
	nextStep,
	previousStep,
	refreshChevrons,
	setRefreshChevrons,
}) {
	const classes = useStyles()
	const [update, setUpdate] = useState(false)
	const [indexValidityEndDate, setIndexValidityEndDate] = useState()
	const [CPs, setCPs] = useState({ columns: [], data: [], options: {} })

	const [AddChemicalProduct, ChemicalProductsForm, ChemicalProductsStorage] = forms
	const { companyRecordId, companyId, platformId } = queries
	const [isLoading, setIsLoading] = useState(true)
	const { enqueueSnackbar } = useSnackbar()

	const { t } = useTranslation(['user', 'chemical', 'companyRecord'])

	const customLabels = [
		{
			name: 'name',
			label: t('chemical:nameFDS'),
		},
		{
			name: 'dangerphrase',
			label: t('danger_phrase'),
		},
		{
			name: 'suppliername',
			label: t('chemical:providerName'),
		},
		{
			name: 'fdspath',
			label: t('chemical:fdsfile'),
		},
		{
			name: 'fdsname',
			label: t('chemical:fdsname'),
		},
		{
			name: 'actions',
			label: t('actions'),
		},
		{
			name: 'fdsvalidityduration',
			label: t('chemical:fdsvalidityduration'),
		},
	]

	//#region TEMPORARY

	const actionsColumn = {
		name: 'actions',
		options: {
			filter: false,
			filterType: 'textField',
			sort: false,
		},
	}
	//#endregion TEMPORARY

	//#region actions
	const openChemicalProductsStorageForm = (value, productId, creationFunc = null) => {
		ChemicalProductsStorage.addData({
			chemicalName: value,
			productId: productId,
			companyRecordId: companyRecordId,
			companyId: companyId,
			platformId: platformId,
			refresh: () => {
				setUpdate(!update)
			},
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			isReviewMode,
			creationFunc,
		})
		ChemicalProductsStorage.open()
	}

	const openChemicalProductsForm = (productId, data = {}) => {
		ChemicalProductsForm.addData({
			productId: productId,
			companyRecordId: companyRecordId,
			companyId: companyId,
			platformId: platformId,
			openChemicalProductsStorageForm: openChemicalProductsStorageForm,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			refresh: () => {
				setUpdate(!update)
			},
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
			...data,
		})
		ChemicalProductsForm.open()
	}

	const openExistCPForm = () => {
		AddChemicalProduct.addData({
			companyRecordId,
			companyId,
			openChemicalProductsForm: openChemicalProductsForm,
			setRevisionCompanyRecordId: setRevisionCompanyRecordId,
			openChemicalProductsStorageForm: openChemicalProductsStorageForm,
			refresh: () => {
				setUpdate(!update)
			},
			refreshChevrons: refreshChevrons,
			setRefreshChevrons: setRefreshChevrons,
			setUpdate: setUpdate,
		})
		AddChemicalProduct.open()
	}

	const deletionChemicalProducts = (res) => {
		const { data, problem } = res
		if (problem) {
			if (!data || !Array.isArray(data) || !data.length) return
			data.forEach((error) => {
				if (error.code) console.error(new Error(error.code))
			})
			return
		}
		if (!data) return console.info('Response is empty !')
		setRefreshChevrons(!refreshChevrons)
		setUpdate(!update)
	}

	const removeCP = (productId) => {
		api
			.delete(bindParams(CHEMICALPRODUCTS, platformId, companyRecordId) + '/' + productId)
			.then(deletionChemicalProducts)
	}
	//#endregion actions

	//#region Datatable

	const recoveryChemicalProductsTab = (res) => {
		const { data, problem } = res
		setIsLoading(false)
		if (problem) return console.error('Error to subcontractor companies datatable !')

		if (!data) return
		if (!data.length) return setCPs({ columns: [], data: [], options: {} })
		setIndexValidityEndDate(Object.keys(data[0]).indexOf('fdsvalidityenddate'))
		return setCPs(convertArrayToDatatable(data, ['id', 'fdsvalidityenddate']))
	}

	const recoverDatatable = () => {
		setIsLoading(true)

		api
			.get(bindParams(CHEMICALPRODUCTS, platformId, companyRecordId))
			.then(recoveryChemicalProductsTab)
	}

	/**
	 * @name downloadFdsFile
	 * @description Convert string path to ArrayBuffer and download file
	 * @param {String} path
	 * @param {String} fileName
	 */
	const downloadFdsFile = (path, fileName) => {
		downloadFile(path, (res, error) => {
			if (error) {
				managedFileNotFoundError(error, t, enqueueSnackbar)
				enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				return
			}
			if (res.data) fileDownload(res.data, fileName)
		})
	}

	const customColumns = [
		{
			name: 'dangerphrase',
			component: function componentDangerPhrase(value) {
				if (!value || !value.length) return 'Pas de phrases'
				return value.join(', ')
			},
		},
		{
			name: 'fdsvalidityduration',
			component: function componentValidityDuration(value, tableMeta) {
				const endDate = new Date(tableMeta.rowData[indexValidityEndDate])
				return (
					<>
						<div className={classes.fdsValidity}>
							<span>
								{value} {value > 1 ? 'ans' : 'an'}
							</span>
							<Tooltip placement="top" title={endDate.toLocaleString().split(' ')[0]}>
								{new Date() > endDate ? (
									<Icon className={classes.icon} color="error">
										cancel
									</Icon>
								) : (
									<Icon className={classNames(classes.icon, classes.green)}>check_circle</Icon>
								)}
							</Tooltip>
						</div>
					</>
				)
			},
		},
		{
			name: 'fdspath',
			component: function componentFdsPath(_value, tableMeta) {
				const path =
						tableMeta.rowData[getIndexOfColumn(CPs.columns, COLUMNS_CHEMICAL_PRODUCTS.FDSPATH)],
					fileName =
						tableMeta.rowData[getIndexOfColumn(CPs.columns, COLUMNS_CHEMICAL_PRODUCTS.FDSNAME)]
				return (
					<IconButton
						onClick={() => {
							downloadFdsFile(path, fileName)
						}}
					>
						<Icon>get_app</Icon>
					</IconButton>
				)
			},
		},
		{
			name: 'actions',
			component: function componentActions(_value, tableMeta) {
				if (!tableMeta || !tableMeta.rowData) return
				let chemicalProductId =
					tableMeta.rowData[getIndexOfColumn(CPs.columns, COLUMNS_CHEMICAL_PRODUCTS.ID)]
				return (
					<>
						<IconButton
							disabled={Boolean(isReadOnly && !isReviewMode)}
							onClick={() =>
								openChemicalProductsStorageForm(tableMeta.rowData[2], {
									chemicalProductId: chemicalProductId,
									create: false,
								})
							}
						>
							<Icon>edit</Icon>
						</IconButton>
						<IconButton disabled={isReadOnly} onClick={() => removeCP(chemicalProductId)}>
							<Icon>delete</Icon>
						</IconButton>
					</>
				)
			},
		},
	]

	useEffect(recoverDatatable, [update])

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('ChemicalProducts')}
			</Typography>
			<br />
			<div className={classes.dataTableContainer}>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={() => openExistCPForm()}
					>
						{t('add')}
					</Button>
				)}
				<DataTable
					title={t('ChemicalProducts')}
					columns={[...CPs.columns, actionsColumn]}
					data={CPs.data}
					isLoading={isLoading}
					options={{ ...CPs.options, textLabels: { body: { noMatch: t('chemical:noProduct') } } }}
					customColumns={customColumns}
					customLabels={customLabels}
					noExportedColumns={[t('chemical:fdsfile'), 'fdsvalidityenddate']}
				/>
			</div>
			{!isReviewMode && (
				<div className={btnsStyle.root}>
					<Button className={btnsStyle.previous} onClick={previousStep}>
						{t('previous')}
					</Button>
					<Button className={btnsStyle.nextWithoutComplete} onClick={nextStep}>
						{t('next')}
					</Button>
				</div>
			)}
		</>
	)
}

export default ChemicalsProducts
