import React, { useState } from 'react'
import { Field } from 'react-final-form'
import 'moment/locale/fr'
import { InputAdornment, Icon } from '@material-ui/core'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, InlineDatePicker, DatePicker } from 'material-ui-pickers'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

moment.locale('fr')

// STYLES
const useStyles = makeStyles(theme => ({
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#ffffff',
		color: theme.palette.secondary.main,
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
	inputSize: {
		padding: '22px 12px 4px!important',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.secondary.main,
		},
	},
	size: {
		display: 'block',
	},
}))

function InputDate({
	name,
	value,
	defaultValue,
	onChange,
	readOnly,
	classname,
	disableLine,
	...props
}) {
	// STYLES
	const classes = useStyles()
	const [value2, setValue2] = useState(defaultValue)

	const myOnChange = event => {
		setValue2(event.year())
	}

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="fr" moment={moment}>
			<Field
				name={name}
				render={({ input, meta }) => {
					const mergedOnChange = e => {
						myOnChange(e)
						if (onChange) onChange(e)
					}
					const newInput = { ...input, onChange: mergedOnChange }
					return (
						<DatePicker
							className={classNames(classes.size, classname)}
							views={['year']}
							disabled={readOnly}
							InputProps={{
								...props,
								value: value || value2,
								className: classes.inputRoot,
								inputProps: {
									className: classes.inputSize,
								},
								disableUnderline: disableLine,
							}}
							InputLabelProps={{
								classes: {
									root: classes.labelRoot,
									focused: classes.labelFocused,
								},
							}}
							DialogProps={{
								okLabel: 'Valider',
								cancelLabel: 'Annuler',
							}}
							{...newInput}
							{...props}
						/>
					)
				}}
			/>
		</MuiPickersUtilsProvider>
	)
}

export default InputDate
