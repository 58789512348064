import {
	Button,
	Card,
	CardActions,
	CardContent,
	CircularProgress,
	Grid,
	Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import DataTable from '../../../components/NewDataTable/NewDataTable'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import {
	DIFFUSION_HISTORY,
	PDP_DIFFUSION,
	PDP_DIFFUSION_STATS,
	PDP_NOTIFIABLE,
} from '../../../constants/url'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		marginTop: theme.spacing.unit * 2,
		paddingBottom: theme.spacing.unit,
	},
	inline: {
		display: 'inline',
	},
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	loader: {
		position: 'absolute',
		right: theme.spacing.unit * 2,
	},
}))

function Diffusion({
	buttonsStyle,
	revisionNumber,
	pdpId,
	refreshChevrons,
	setRefreshChevrons,
	pdpNumber,
	previousStep,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['user'])
	const [cookies] = useCookies()
	const [isNotifiable, setIsNotifiable] = useState(false)
	const [stats, setStats] = useState({})
	const [result, setResult] = useState({})
	const { enqueueSnackbar } = useSnackbar()
	const [showLoader, setShowLoader] = useState(false)
	const [isLoading, setIsLoading] = useState(true)

	useEffect(() => {
		api
			.get(bindParams(PDP_NOTIFIABLE, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(recoveryPdpNotifiable)

		api
			.get(bindParams(PDP_DIFFUSION_STATS, cookies[PLATFORM_ID_COOKIE], pdpId))
			.then(recoveryPdpDiffusionStats)

		setIsLoading(true)
		api
			.post(bindParams(DIFFUSION_HISTORY, cookies[PLATFORM_ID_COOKIE], pdpId), {
				nbItemsAsked: 10,
				pageNumber: 1,
			})
			.then(recoveryDiffusionHistory)
	}, [refreshChevrons, cookies, pdpId, revisionNumber])

	const recoveryPdpNotifiable = (res) => {
		const { data, problem } = res

		if (problem) return

		if (data) {
			setIsNotifiable(data)
		} else {
			setIsNotifiable(false)
		}
	}

	const recoveryPdpDiffusionStats = (res) => {
		const { data, problem } = res

		if (problem) return

		if (data) {
			setStats(data)
		}
	}

	const recoveryDiffusionHistory = (res) => {
		const { data, problem } = res
		setIsLoading(false)
		if (problem) return

		if (data) {
			setResult(data)
		}
	}

	const recoveryPdpDiffusion = (res) => {
		setShowLoader(false)
		const { ok } = res

		if (!ok) return

		if (ok) {
			enqueueSnackbar('La diffusion a bien été envoyée', {
				variant: 'info',
			})
			setRefreshChevrons(!refreshChevrons)
		}
	}

	const sendPdPDiffusion = () => {
		setShowLoader(true)
		api
			.post(bindParams(PDP_DIFFUSION, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(recoveryPdpDiffusion)
	}

	return (
		<>
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('Diffusion')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('preventionPlanNo')} {pdpNumber}
			</Typography>
			<hr className={classes.hrRoot} />
			<br />
			<Grid container spacing={16}>
				<Grid item xs={12} md={4}>
					<Card className={classes.card}>
						<CardContent>
							{showLoader && <CircularProgress color="secondary" className={classes.loader} />}
							<Typography variant="h6" color="secondary" style={{ textAlign: 'left' }}>
								{t('Diffusion')}
							</Typography>
							<br />
							<Typography color="secondary" variant="h4" className={classes.inline}>
								{stats.nbCompanyHaveReceived}
							</Typography>
							&nbsp;
							<Typography color="secondary" variant="subtitle1" className={classes.inline}>
								{t('CompaniesWillReceive')}
							</Typography>
							<br />
							<Typography variant="h4" color="secondary" className={classes.inline}>
								{stats.nbCompanyWillReceive}
							</Typography>
							&nbsp;
							<Typography color="secondary" variant="subtitle1" className={classes.inline}>
								{t('CompaniesFutureAmendment')}
							</Typography>
							<br />
						</CardContent>
						<CardActions>
							<Button
								fullWidth
								variant="contained"
								onClick={sendPdPDiffusion}
								color="secondary"
								disabled={Boolean(!isNotifiable || showLoader)}
							>
								{t('send')}
							</Button>
						</CardActions>
					</Card>
				</Grid>
				<Grid item xs={12} md={8}>
					<DataTable
						title="Diffusion"
						data={result.data}
						columns={result.columns}
						isLoading={isLoading}
						options={result.options}
						customColumns={[
							{
								name: 'date_diffusion',
								component: (value) => {
									return moment(value.toString()).format('DD/MM/YYYY')
								},
							},
						]}
						customLabels={[
							{
								name: 'date_diffusion',
								label: t('date_diffusion'),
							},
							{
								name: 'pdp_version',
								label: t('numero_format'),
							},
							{
								name: 'qui',
								label: t('qui'),
							},
							{
								name: 'nb_entreprises',
								label: t('nb_entreprises'),
							},
						]}
					/>
				</Grid>
			</Grid>
			<div className={buttonsStyle.root}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.previous} onClick={previousStep}>
							{t('previous')}
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	)
}
Diffusion.propTypes = {
	buttonsStyle: PropTypes.object,
	setStepStatus: PropTypes.func,
	revisionNumber: PropTypes.string,
	pdpId: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	pdpNumber: PropTypes.string,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
}
export default Diffusion
