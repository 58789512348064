import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import MuiCardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MailIcon from '@material-ui/icons/MailOutlined'
import PhoneIcon from '@material-ui/icons/PhoneOutlined'
import PhonelinkEraseIcon from '@material-ui/icons/PhonelinkEraseOutlined'
import Clear from '@material-ui/icons/Clear'
import IconButton from '@material-ui/core/IconButton'
import { Typography } from '@material-ui/core'
import { useAddContactForm } from '../../containers/FormContainer'
import { useTranslation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	card: {
		minHeight: 230,
		position: 'relative',
		margin: '10px',
	},
	cardIcon: {
		position: 'absolute',
		bottom: 10,
		right: 10,
		cursor: 'mouse',
	},
	addCardIcon: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '70%',
	},
	addIcon: {
		height: '100%',
		width: '100%',
	},
	title: {
		fontSize: 27,
		color: theme.palette.secondary.main,
	},
	subtitle: {
		color: theme.palette.secondary.main,
		fontSize: 17,
	},
	separator: {
		width: '90%',
		height: 1,
		border: 'none',
		backgroundColor: '#d6d6d6',
	},
	icons: {
		color: 'black',
	},
	link: {
		color: 'black !important',
		textDecoration: 'none',
		display: 'block',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	cardHeader: {
		paddingBottom: 5,
	},
	iconList: {
		display: 'flex',
		flexDirection: 'row-reverse',
	},
	icon: {
		marginLeft: theme.spacing.unit,
	},
	roleIcon: {
		margin: 5,
	},
}))
/**
 * Shows a Contact card
 * @returns {JSX.Element} React stack to render
 */
const ContactCompanyFilesCard = ({ size, data, typeIcons, deleteAction, isReadOnly }) => {
	const classes = useStyles()
	const { t } = useTranslation('user')

	return (
		<Grid item xs={12} sm={6} md={size}>
			<Card className={classes.card}>
				<MuiCardHeader
					title={
						<Typography className={classes.title} variant="h6">
							{data.firstName} {data.name} {data.lastName}
						</Typography>
					}
					titleTypographyProps={{ color: 'inherit' }}
					className={classes.cardHeader}
				/>
				<hr className={classes.separator} />
				<CardContent>
					<List>
						<ListItem>
							<ListItemIcon>
								<MailIcon className={classes.icons} />
							</ListItemIcon>
							<ListItemText>
								<a
									className={classes.link}
									href={`mailto:${data.email}`}
									target="_blank"
									rel="noopener noreferrer"
								>
									{data.email}
								</a>
							</ListItemText>
						</ListItem>
						<ListItem>
							<ListItemIcon>
								{data.phone ? (
									<PhoneIcon className={classes.icons} />
								) : (
									<PhonelinkEraseIcon className={classes.icons} />
								)}
							</ListItemIcon>
							<ListItemText>
								{data.phone ? (
									<a
										className={classes.link}
										href={`tel:${data.phone}`}
										target="_blank"
										rel="noopener noreferrer"
									>
										{data.phone}
									</a>
								) : (
									t('NoPhoneNumber')
								)}
							</ListItemText>
						</ListItem>
					</List>
					{!isReadOnly && (
						<IconButton className={classes.cardIcon} aria-label="Edit" onClick={deleteAction}>
							<Clear className={classes.icons} />
						</IconButton>
					)}
					<div className={classes.iconList}>
						{typeIcons &&
              typeIcons.map((icon, i) => (
              	<img key={i} src={icon} alt="icon" className={classes.icon} />
              ))}
					</div>
				</CardContent>
			</Card>
		</Grid>
	)
}

export default ContactCompanyFilesCard
