import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { TextField, InputAdornment, Icon } from '@material-ui/core'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	labelRoot: {
		color: theme.palette.primary.main,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		width: '100%',
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
	},
}))

function InputMail({ name, validate, label, ...props }) {
	const classes = useStyles()

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => (
				<>
					<TextField
						type="email"
						label={label}
						variant="filled"
						className={classes.block}
						FormHelperTextProps={{
							classes: {
								root: classes.helperText,
							},
						}}
						helperText={
							meta && meta.touched && meta.error ? (
								<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
							) : (
								undefined
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon>alternate_email</Icon>
								</InputAdornment>
							),
							classes: {
								root: classes.inputRoot,
								focused: classes.inputFocused,
							},
						}}
						InputLabelProps={{
							classes: {
								root: classes.labelRoot,
								focused: classes.labelFocused,
							},
						}}
						// eslint-disable-next-line react/jsx-no-duplicate-props
						inputProps={{
							className: classes.inputSize,
						}}
						{...props}
						{...input}
					/>
				</>
			)}
		/>
	)
}

export default InputMail
