import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import TotalAccountSelector from '../TotalAccountSelector/TotalAccountSelector'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
	title: {
		padding: theme.spacing.unit,
	},
	box: {
		margin: theme.spacing.unit,
		borderRadius: 5,
		border: 'solid 1px ' + theme.palette.secondary.light,
		padding: theme.spacing.unit,
	},
}))
export default function PlatformPicker({ platform, form, initialValue = null }) {
	const classes = useStyles()
	return (
		<Paper className={classes.box}>
			<div className={classes.title}>
				<Typography variant="subtitle1" color="secondary">
					{platform.name}
				</Typography>
			</div>
			<TotalAccountSelector
				name={`roles-${platform.platformId}`}
				form={form}
				initialValue={initialValue}
				disableFabButton={platform.disableFabButton}
			/>
		</Paper>
	)
}

PlatformPicker.propTypes = {
	platform: PropTypes.object,
	form: PropTypes.object,
	initialValue: PropTypes.array,
}
