import { Button, Grid, Modal, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import i18next from 'i18next'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Scrollbars } from 'react-custom-scrollbars'
import { useTranslation } from 'react-i18next'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { addCompanyManager, getContactTypeList } from '../../../containers/DataContainer'
import Form from '../../Form/Form'
import InputCheckbox, { InputSwitch } from '../../Form/inputFullPage/InputCheckbox'
import InputMail from '../../Form/inputFullPage/InputMail'
import InputPhone from '../../Form/inputFullPage/InputPhone'
import InputText from '../../Form/inputFullPage/InputText'

const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '10%',
		maxWidth: '60%',
		minWidth: 650,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	mainTitle: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		width: '60%',
		marginBottom: 20,
	},
	secondTitle: {
		display: 'inline-block',
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	inputs: {
		margin: 10,
		width: '90%',
	},
	groupCheckbox: {
		marginLeft: 20,
	},
	scroll: {
		height: '75vh',
	},
}))

function OperationManagerChoice({
	open,
	managerChoiceData,
	refreshDatatable,
	setRefreshDatatable,
	setCreateCompanyContactForm,
}) {
	const { t } = useTranslation(['user', 'contacts', 'validateData'])
	const classes = useStyles()
	const [cookies] = useCookies()
	const [listType, setListType] = useState([])
	const [language, setLanguage] = useState(1)
	const { enqueueSnackbar } = useSnackbar()

	const close = () => {
		setCreateCompanyContactForm(false)
	}

	const onSubmit = (values) => {
		let typeId = [9]
		for (let i = 1; i < listType.length + 1; i++) {
			if (values['type-' + i]) {
				typeId = [...typeId, i]
			}
		}
		let formData = {
			contactName: values.name,
			contactFirstName: values.firstname,
			contactEmail: values.mail,
			contactPhone: values.phone,
			roles: typeId,
			isContactNotifiable: values.notifie,
			createApplicationAccess: false,
		}
		addCompanyManager(
			cookies[PLATFORM_ID_COOKIE],
			managerChoiceData.pdpId,
			managerChoiceData.companyId,
			formData,
			function (res, error) {
				if (res) {
					enqueueSnackbar(t('contactAddedToPdP'), { variant: 'info' })
					setRefreshDatatable(!refreshDatatable)
					close()
				}
				if (error) {
					enqueueSnackbar(t('error') + ` : ${error.status} !`, { variant: 'error' })
				}
			},
		)
	}

	useEffect(() => {
		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}
	})

	useEffect(() => {
		getContactTypeList(language, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListType(res.data)
			}
		})
	}, [open])

	return (
		<Modal open={open} onClose={close}>
			<div className={classNames(classes.paper, classes.scroll)}>
				<Scrollbars>
					<Form onSubmit={onSubmit}>
						<Typography variant="h6" classes={{ root: classes.mainTitle }}>
							{t('AddingContact')}
						</Typography>
						<Typography variant="h6" classes={{ root: classes.secondTitle }}>
							{managerChoiceData.companyName}
						</Typography>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<InputText
									name="firstname"
									label={t('firstname')}
									classes={{ root: classes.inputs }}
								/>
								<InputText name="name" label={t('lastname')} classes={{ root: classes.inputs }} />
								<InputMail name="mail" label={t('email')} classes={{ root: classes.inputs }} />
								<InputPhone name="phone" label={t('phone')} classes={{ root: classes.inputs }} />
							</Grid>
							<Grid item xs={12} sm={6}>
								<InputSwitch name="notifie" label={t('notifie')} />
								<Typography variant="h6">{t('function')} :</Typography>
								<div className={classes.groupCheckbox}>
									{listType &&
										listType.map((type) => {
											return (
												<InputCheckbox
													name={`type-${type.id}`}
													label={type.name}
													value={type.id}
													key={type.id}
													checked={type.id === 9 ? true : undefined}
												/>
											)
										})}
								</div>
							</Grid>
						</Grid>
						<div className={classes.buttonGroup}>
							<Button classes={{ root: classes.button }} onClick={close}>
								{t('cancel')}
							</Button>
							<Button className={classNames(classes.button, classes.validateButton)} type="submit">
								{t('submit')}
							</Button>
						</div>
						<div />
					</Form>
				</Scrollbars>
			</div>
		</Modal>
	)
}

export default OperationManagerChoice
