import { makeStyles } from '@material-ui/styles'
export const useStyles = makeStyles((theme) => ({
	paper: {
		margin: 'auto',
		marginTop: '20%',
		maxWidth: '60%',
		minWidth: 550,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		outline: 'none',
		padding: 25,
	},
	mainTitle: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		width: '60%',
	},
	secondTitle: {
		display: 'inline-block',
	},
	inputSelect: {
		width: '50%',
		display: 'inline-block',
	},
	label: {
		display: 'inline-block',
		margin: 'auto',
	},
	OperationManagerGroup: {
		marginTop: 50,
		display: 'flex',
	},
	addButton: {
		color: theme.palette.secondary.main,
		margin: 0,
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
		border: 'solid 2px',
		marginTop: 30,
		padding: 10,
		paddingLeft: 25,
		paddingRight: 25,
		marginBottom: 10,
	},
	validateButton: {
		marginLeft: 'auto',
	},
	buttonGroup: {
		display: 'flex',
	},
	PrincipalGroup: {
		marginTop: 50,
		display: 'flex',
	},
}))
