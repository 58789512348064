import React, { useState, useEffect } from 'react'
import { Field } from 'react-final-form'
import 'moment/locale/fr'
import { InputAdornment, Icon } from '@material-ui/core'
import moment from 'moment'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, InlineDatePicker } from 'material-ui-pickers'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'

moment.locale('fr')

// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: theme.palette.primary.main,
		marginTop: 5,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
		width: '100%',
	},
	inputSize: {
		padding: '22px 12px 10px!important',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	size: {
		display: 'block',
	},
}))

function InputDate({ name, label, inputStyle, delayOptions, ...props }) {
	// STYLES
	const classes = useStyles()
	const [selectedDate, handleDateChange] = useState(new Date())

	useEffect(() => {
		let currentDate = new Date()
		currentDate.setDate(currentDate.getDate() + 10)
		handleDateChange(currentDate)
	}, [])

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale="fr" moment={moment}>
			<Field
				name={name}
				render={({ input }) => (
					<InlineDatePicker
						label={label}
						className={inputStyle}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Icon>calendar_today</Icon>
								</InputAdornment>
							),
							...props,
							className: classes.inputRoot,
						}}
						InputLabelProps={{
							classes: {
								root: classes.labelRoot,
								focused: classes.labelFocused,
							},
						}}
						value={selectedDate}
						onChange={handleDateChange}
						variant="filled"
						format="DD/MM/YYYY"
						mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
						{...delayOptions}
						{...input}
					/>
				)}
			/>
		</MuiPickersUtilsProvider>
	)
}
InputDate.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	inputStyle: PropTypes.string,
	delayOptions: PropTypes.object,
}
export default InputDate
