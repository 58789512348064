import React, { useState } from 'react'
import Fab from '@material-ui/core/Fab'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Radio from '@material-ui/core/Radio'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MUILink from '@material-ui/core/Link'
import { switchLanguage } from '../../utils/Lang'

const useStyles = makeStyles((theme) => ({
	main: {
		width: 'auto',
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		textAlign: 'center',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100vh',
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
	leftPanel: {
		backgroundImage: 'url(/images/leftLoginBg.png)',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
	buttons: {
		width: '228px',
	},
	formControl: {
		marginTop: '40px',
		marginBottom: '40px',
	},
	flag: {
		boxShadow:
			'0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
	},
	link: {
		display: 'contents',
		textDecoration: 'none',
		'&:visited': {
			color: 'black',
		},
	},
	langage: {
		display: 'block',
	},
	logo: {
		width: '100%',
		height: 'auto',
		maxWidth: 400,
		padding: '0 50px',
	},
}))

function SignUp() {
	const classes = useStyles()
	const { t, i18n } = useTranslation(['translation', 'user'])
	const [selectedLang, setSelectedLang] = useState(i18n.language || 'fr') // current language or default french

	const handleChange = (event) => {
		setSelectedLang(event.target.value)
		i18n.changeLanguage(event.target.value)
	}

	return (
		<Grid container spacing={0}>
			<Grid item md={6} className={classes.leftPanel} />
			<Grid item md={6} sm={12}>
				<div className={classes.main}>
					<CssBaseline />
					<div className={classes.paper}>
						<img src="/images/total.png" alt={t('brand')} className={classes.logo} />
						<br />
						<br />
						<Typography component="h1" variant="h4" color="secondary">
							{t('user:signup')}
						</Typography>
						<br />
						<Divider variant="middle" />
						<FormControl component="fieldset" className={classes.formControl}>
							<Grid container spacing={40}>
								<Grid item>
									<FormControlLabel
										value="fr"
										className={classes.langage}
										control={
											<>
												<img
													src="/images/fr.png"
													alt={t('fr')}
													className={selectedLang === 'fr' ? classes.flag : ''}
												/>
												<br />
												<Radio
													checked={selectedLang === 'fr'}
													onChange={handleChange}
													value="fr"
													name="language"
												/>
											</>
										}
									/>
								</Grid>
								<Grid item>
									<FormControlLabel
										value="en"
										className={classes.langage}
										control={
											<>
												<img
													src="/images/en.png"
													alt={t('en')}
													className={selectedLang === 'en' ? classes.flag : ''}
												/>
												<br />
												<Radio
													checked={selectedLang === 'en'}
													onChange={handleChange}
													value="en"
													name="language"
												/>
											</>
										}
									/>
								</Grid>
							</Grid>
						</FormControl>

						<br />
						<Link className={classes.link} to="/register">
							<Fab
								variant="extended"
								color="secondary"
								className={classes.buttons}
								onClick={switchLanguage(selectedLang)}
							>
								{t('user:select_btn')}
							</Fab>
						</Link>
						<br />
						<br />
						<Link to="/">
							<MUILink color="secondary">{t('user:back')}</MUILink>
						</Link>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}

export default SignUp
