import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BasicCard from '../../components/Cards/BasicCard'
import HeadTitle from '../../components/HeadTitle/HeadTitle'
import api from '../../utils/Requester'
import { DOC_TYPE } from '../../constants/url'
import LANG from '../../constants/lang'

const useStyles = makeStyles(theme => ({
	titlecentered: {
		textAlign: 'center',
		color: theme.palette.secondary.main,
	},
	container: {
		padding: theme.spacing.unit,
		paddingBottom: theme.spacing.unit * 10,
	},
}))

function ReferenceDocumentManagement() {
	const localLang = localStorage.getItem('i18nextLng')
	const { t } = useTranslation(['user', 'referenceDocumentManagement'])
	const classes = useStyles()

	const ALLOW_DOC_CODES = ['PdP', 'Avenant', 'CompanyRecord', 'Convocation']

	const [documents, setDocuments] = useState([])

	const getLangId = () => LANG[localLang].id

	const getDocuments = () => {
		api.get(DOC_TYPE, { p_LangId: getLangId() }).then(function(res) {
			const { ok, data } = res

			if (!ok) return

			setDocuments(data)
		})
	}

	useEffect(() => {
		getDocuments()
	}, [])

	return (
		<>
			<HeadTitle title={t('referenceDocumentManagement:referenceDocumentManagementTitle')} />
			<Grid container spacing={16} direction="row" className={classes.container}>
				{documents
					.filter(doc => ALLOW_DOC_CODES.find(code => code === doc.code))
					.map((doc, i) => {
						return (
							<BasicCard
								key={i}
								size={4}
								onClick={() => {
									window.location.href = `/reference-documents-management/edit/${doc.id}`
								}}
								disableAction={true}
								icon={'/icons/toComplete_b.png'}
							>
								<Typography className={classes.titlecentered} variant="h6">
									{t(doc.name)}
								</Typography>
							</BasicCard>
						)
					})}
			</Grid>
		</>
	)
}
export default ReferenceDocumentManagement
