/*
This context will keep the data for all register forms. 
*/
import React, { createContext, useContext, useState } from 'react'

const SignUpContext = createContext()

function SignUpContextProvider(props) {
	const [data, setData] = useState([])
	let values = { data, setData }
	return <SignUpContext.Provider value={values}>{props.children}</SignUpContext.Provider>
}

function useSignUpContext() {
	return useContext(SignUpContext)
}

let SignUpContextConsumer = SignUpContext.Consumer

export { useSignUpContext, SignUpContextProvider, SignUpContextConsumer }
