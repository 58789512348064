import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme => ({
	button: {
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.primary.main,
		color: theme.palette.primary.main,
		borderRadius: 30
	}
}))

function FormButton({ size, label, type, onClick, ...props }) {
	const classes = useStyles()

	return (
		<Button
			className={classes.button}
			type={type}
			variant="outlined"
			onClick={onClick}
			{...props}
		>
			{label}
		</Button>
	)
}

export default FormButton
