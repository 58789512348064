export const MAX_FILE_SIZE = 10485760

function humanFileSize() {
	if (MAX_FILE_SIZE === 0) return '0 Bytes'
	var k = 1024,
		dm = 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(MAX_FILE_SIZE) / Math.log(k))
	return parseFloat((MAX_FILE_SIZE / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export default humanFileSize
