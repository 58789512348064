import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import TextField from '@material-ui/core/TextField'
import { Translation } from 'react-i18next'
import PropTypes from 'prop-types'
// STYLES
const useStyles = makeStyles((theme) => ({
	labelRoot: {
		color: theme.palette.primary.main,
	},
	labelFocused: {
		color: theme.palette.primary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		width: '100%',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
		minHeight: 0,
	},
}))

function InputTextArea({ name, validate, label, value = null, ...props }) {
	const classes = useStyles()

	const onChangeHandle = React.useCallback((input, e) => {
		input.onChange(e)
		value = e.target.value
	})

	return (
		<Field
			name={name}
			validate={validate}
			render={({ input, meta }) => (
				<TextField
					type="text"
					label={label}
					variant="filled"
					multiline={true}
					FormHelperTextProps={{
						classes: {
							root: classes.helperText,
						},
					}}
					helperText={
						meta && meta.touched && meta.error ? (
							<Translation ns="validateData">{(t) => <>{t(meta.error)}</>}</Translation>
						) : undefined
					}
					className={classes.block}
					InputProps={{
						classes: {
							root: classes.inputRoot,
							focused: classes.inputFocused,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.labelRoot,
							focused: classes.labelFocused,
						},
					}}
					{...props}
					{...input}
					value={value ?? input.value}
					onChange={(e) => onChangeHandle(input, e)}
				/>
			)}
		/>
	)
}
InputTextArea.propTypes = {
	name: PropTypes.string,
	validate: PropTypes.func,
	label: PropTypes.string,
}

export default InputTextArea
