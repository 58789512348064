import { ENV_NAME } from './environnements'

const formatCookieName = (cookieName) => `${ENV_NAME}_${cookieName}`

export const ACCOUNT_ID_COOKIE = formatCookieName('accountId')
export const ACCOUNT_TYPES_COOKIE = formatCookieName('accountTypes')
export const COMPANY_ID_COOKIE = formatCookieName('companyID')
export const FIRST_NAME_COOKIE = formatCookieName('firstName')
export const LAST_NAME_COOKIE = formatCookieName('lastName')
export const PLATFORM_ID_COOKIE = formatCookieName('platformId')
export const SHAREPOINT_URL_LIVE_COOKIE = formatCookieName('liveSharepointURL')
export const SHAREPOINT_URL_SANDBOX_COOKIE = formatCookieName('sandboxSharepointURL')
export const USER_OBJECTID = formatCookieName('RaPSoDUserObjectId')
export const FIRST_CONNECTION = formatCookieName('firstConnection')

// Set secure attribute for cookies (Requires HTTPS) only on env where domain is set (likely test / prod only)
export const DEFAULT_COOKIE_OPTIONS = process.env.REACT_APP_COOKIE_DOMAIN
	? {
			domain: process.env.REACT_APP_COOKIE_DOMAIN || window.location.hostname,
			path: '/',
			secure: true,
	  }
	: {
			domain: process.env.REACT_APP_COOKIE_DOMAIN || window.location.hostname,
			path: '/',
	  }
