import React from 'react'
import { Field } from 'react-final-form'
import { makeStyles } from '@material-ui/styles'
import { TextField } from '@material-ui/core'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	labelRoot: {
		color: theme.palette.secondary.main,
	},
	labelFocused: {
		color: theme.palette.secondary.main + ' !important',
	},
	inputRoot: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.secondary.main,
		width: '100%',
		border: 'solid 0.5px',
		borderColor: theme.palette.secondary.main,
		borderRadius: '5px',
		'&:before': {
			display: 'none',
		},
		'&:after': {
			display: 'none',
		},
	},
	inputSize: {
		padding: '22px 12px 10px',
	},
	inputFocused: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	block: {
		display: 'block',
	},
	helperText: {
		color: '#E10032',
	},
}))

function InputText({ name,validate,  ...props }) {
	const classes = useStyles()

	return (
		<Field
			name={name}
			validate={validate}
			InputLabelProps={{
				shrink: true,
			}}
			render={({ input, meta }) => (
				<TextField
					type="number"
					variant="filled"
					FormHelperTextProps={{
						classes: {
							root: classes.helperText,
						},
					}}
					helperText={
						meta && meta.touched && meta.error? (
							<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
						) : (
							undefined
						)
					}
					className={classes.block}
					InputProps={{
						classes: {
							root: classes.inputRoot,
							focused: classes.inputFocused,
						},
					}}
					InputLabelProps={{
						classes: {
							root: classes.labelRoot,
							focused: classes.labelFocused,
						},
					}}
					// eslint-disable-next-line react/jsx-no-duplicate-props
					inputProps={{
						className: classes.inputSize,
					}}
					{...props}
					{...input}
				/>
			)}
		/>
	)
}

export default InputText
