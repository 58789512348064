import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Field } from 'react-final-form'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import Typography from '@material-ui/core/Typography'
import { FormHelperText } from '@material-ui/core'
import { Translation } from 'react-i18next'

// STYLES
const useStyles = makeStyles(theme => ({
	root: {
		marginLeft: '0px',
		marginRight: '0px',
		width: '100%',
		display: 'block',
	},
	inputSize: {
		width: '100%',
	},
	inputRoot: {
		backgroundColor: '#3268A7',
		color: theme.palette.primary.main,
		'&:hover': {
			backgroundColor: '#113A7E',
		},
	},
	inputUnderline: {
		'&:after': {
			borderColor: theme.palette.primary.main,
		},
	},
	labelRoot: {
		color: theme.palette.primary.main,
	},
	block: {
		display: 'block',
	},
	helperText: {
		minHeight: '0px',
		color: '#E10032',
		margin: '8px 12px 0',
	},
}))

function InputPhone({ name, validate, label, ...props }) {
	const classes = useStyles()
	return (
		<FormControlLabel
			className={classes.root}
			{...props}
			control={
				<Field
					validate={validate}
					name={name}
					disabled={true}
					render={({ input, meta }) => (
						<>
							<MaterialUiPhoneNumber
								defaultCountry="fr"
								{...input}
								label={
									<Typography
										classes={{
											root: classes.labelRoot,
										}}
									>
										{label}
									</Typography>
								}
								inputClass={classes.inputSize}
								variant="filled"
								disableAreaCodes={true}
								inputProps={{
									classes: {
										root: classes.inputRoot,
										underline: classes.inputUnderline,
									},
								}}
							/>
							<FormHelperText classes={{ root: classes.helperText }}>
								{meta && meta.touched && meta.error ? (
									<Translation ns="validateData">{t => <>{t(meta.error)}</>}</Translation>
								) : (
									undefined
								)}
							</FormHelperText>
						</>
					)}
				/>
			}
		/>
	)
}

export default InputPhone
