import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CONTACTS from '../../../../constants/contacts'
import { getContactsByAccountId } from '../../../../containers/DataContainer'
import Form from '../../../Form/Form'
import FormButtonIcon from '../../../Form/FormButtonIcon'
import FormButtonIconAdd from '../../../Form/FormButtonIconAdd'
import FormIcon from '../../../Form/FormIcon'
import InputSelectField from '../../../Form/input/InputSelect'
import SelectMenuItem from '../../../Form/input/SelectMenuItem'
import SlidingForm from '../../../Form/SlidingForm'

const useStyles = makeStyles((theme) => ({
	size1: {
		marginTop: '15px',
		marginBottom: '15px',
	},
	buttonValidate: {
		color: theme.palette.primary.main,
		display: 'block',
		border: 0,
		margin: '0 auto',
	},
	validateButton: {
		marginLeft: 0,
		marginRight: 0,
	},
	deleteButton: {
		marginLeft: 0,
	},
	validateGroup: {
		display: 'flex',
	},
	select: {
		marginTop: 20,
	},
}))

/**
 * Shows a form to add a contact to the prevention plan
 * @returns {JSX.Element} React stack to render
 */
function AddExecutionManagerForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')
	const [listContacts, setListContacts] = useState([])

	const getContacts = () => {
		if (!open) return
		getContactsByAccountId(data.companyId, (res, err) => {
			if (err) return console.error('Error to get contacts !')
			setListContacts(res.data)
		})
	}

	useEffect(getContacts, [open])

	const onSubmit = (values) => {
		let executionManager = listContacts.map((contact) => {
			return contact.id === values.contact ? contact : undefined
		})
		data.setOperationManager(...executionManager.filter((n) => n))
		close()
	}

	return (
		<SlidingForm
			direction="vertical"
			open={open}
			title={t('addContact')}
			iconAttachement={
				<>
					<FormIcon
						icon="clear"
						onClick={() => {
							close()
						}}
					/>
				</>
			}
			submitLabel="Valider"
			onClose={() => {
				close()
			}}
		>
			<Form onSubmit={onSubmit} initialValues={{}}>
				<InputSelectField
					name="contact"
					label={t('choiceOfContact')}
					classes={{
						root: classes.select,
					}}
				>
					{listContacts &&
						listContacts.map((contact) => {
							return (
								<SelectMenuItem key={contact.id} value={contact.id}>
									{contact.firstName + ' ' + contact.name}
								</SelectMenuItem>
							)
						})}
				</InputSelectField>
				<div className={classes.validateGroup}>
					<FormButtonIcon classes={{ root: classes.deleteButton }} type="submit" />
					<FormButtonIconAdd
						classes={{ root: classes.validateButton }}
						onClick={() => {
							data.openCreateContactForm(listContacts, [CONTACTS.OPERATIONMANAGER.id])
							close()
							if (data.setTypeCreated) {
								data.setTypeCreated('resp')
							}
						}}
					/>
				</div>
			</Form>
		</SlidingForm>
	)
}

export default AddExecutionManagerForm
