import {
	CircularProgress,
	FormControl,
	IconButton,
	Paper,
	Switch,
	TextField,
	Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddCircleOutline from '@material-ui/icons/AddCircleOutline'
import Delete from '@material-ui/icons/Delete'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState, useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { Field } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { MASSNOTIFICATION } from '../../constants/url'
import {
	deleteNotif,
	getCompanyByPdp,
	getCompanyByPtf,
	getEntities,
	getJobList,
	getListMassNotifications,
} from '../../containers/DataContainer'
import { getFilesToByteArray } from '../../utils/File'
import { bindParams } from '../../utils/Link'
import api from '../../utils/Requester'
import Form from '../Form/Form'
import InputFile from '../Form/inputFullPage/InputFile'
import InputSelectField from '../Form/inputFullPage/InputMultipleSelect'
import { InputSelectOnChange } from '../Form/inputFullPage/InputSelect'
import SelectMenuItem from '../Form/inputFullPage/SelectMenuItem'
import { PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { EditorState, convertToRaw } from 'draft-js'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import RichText from '../../components/RichText/RichText'
import debounce from 'lodash.debounce'

const useStyles = makeStyles((theme) => ({
	validateRoot: {
		display: 'flex',
		marginTop: theme.spacing.unit * 2,
		marginLeft: 'auto',
		marginRight: 'auto',
	},
	flex: {
		display: 'flex',
	},
	fullWidth: {
		marginLeft: '2.5%',
	},
	inlineBlock: {
		display: 'inline-block',
	},
	title: {
		fontSize: 30,
		color: theme.palette.secondary.main,
	},
	switch: {
		verticalAlign: 'initial',
		marginTop: 0,
	},
	titleContainer: {
		verticalAlign: 'bottom',
	},
	create: {
		margin: 10,
	},
	selects: {
		maxWidth: 300,
		margin: 5,
		marginLeft: 0,
	},
	firstSelect: {
		verticalAlign: 'middle',
		marginLeft: 10,
	},
	addIcon: {
		fontSize: 45,
		color: theme.palette.secondary.main,
		verticalAlign: 'middle',
	},
	paper: {
		padding: 20,
		marginBottom: 10,
	},
	message: {
		margin: 10,
		marginLeft: 0,
	},
	fileNames: {
		marginTop: 10,
	},
	button: {
		backgroundColor: theme.palette.primary.main,
		borderColor: theme.palette.secondary.main,
		color: theme.palette.secondary.main,
		borderRadius: 30,
	},
	buttonProgress: {
		color: theme.palette.secondary.main,
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))

/**
 * Shows the MassNotification form
 * @returns {JSX.Element} React stack to render
 */

/* TODO : FONCTION INCORRECT A RENOMMER -> MassNotificationForm */
function MyAccountForm({ match }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t, i18n } = useTranslation(['user', 'massNotification'])
	const { enqueueSnackbar } = useSnackbar()
	const langId = i18n.language === 'fr' ? 1 : 2
	const [companyPdp, setCompanyPdp] = useState([])
	const [companyJob, setCompanyJob] = useState([])
	const [companyPhase, setCompanyPhase] = useState([])
	const [companyParticular, setCompanyParticular] = useState([])
	const [allResults, setAllResults] = useState({
		companyPdp: [],
		companyJob: [],
		companyPhase: [],
		companyParticular: [],
	})
	const [fileList, setFile] = useState([])
	const [nameFile, setNameFile] = useState('')
	const [listDiffusion, setListDiffusion] = useState([])
	const [initialValues, setInitialValues] = useState({ title: 'Ma Nouvelle Liste' })
	const [update, setUpdate] = useState(false)
	const [shouldSave, setShouldSave] = useState(false)
	const [loading, setLoading] = useState(false)
	const [isReadOnly, setIsReadOnly] = useState(false)
	const [editorState, setEditorState] = useState(EditorState.createEmpty())
	const debouncedChangeEditorState = useMemo(() => debounce(setEditorState), [editorState])

	const addDocument = (file) => {
		setFile([...fileList, file])
	}

	const addDocumentName = (name) => {
		setNameFile(name)
	}

	const onSubmit = (values) => {
		const rawContentState = convertToRaw(editorState.getCurrentContent())
		if (
			(!allResults.companyParticular.length &&
				!allResults.companyPhase.length &&
				!allResults.companyJob.length &&
				!allResults.companyPdp.length &&
				!values.companiesActifPdP &&
				!values.companiesActifAccount) ||
			(rawContentState.blocks.length === 1 && rawContentState.blocks[0].text.trim() === '')
		)
			return enqueueSnackbar(t('needNotificationCriteria'), { variant: 'error' })

		setLoading(true)
		setIsReadOnly(true)
		getFilesToByteArray(fileList, function (res) {
			const formData = new FormData()
			formData.append('companiesOnActivePdp', values.companiesActifPdP ? true : false)
			formData.append('companiesWithActiveAccount', values.companiesActifAccount ? true : false)
			if (allResults.companyParticular.length)
				for (const company of allResults.companyParticular) formData.append('companyIds[]', company)

			if (allResults.companyPhase.length)
				for (const company of allResults.companyPhase) formData.append('phaseIds[]', company)
			if (allResults.companyJob.length)
				for (const company of allResults.companyJob) formData.append('jobIds[]', company)
			if (allResults.companyPdp.length)
				for (const company of allResults.companyPdp) formData.append('pdpIds[]', company)

			formData.append('notificationMessage', draftToHtml(rawContentState))
			formData.append('shouldSaveList', shouldSave)
			formData.append('listName', values.title)

			for (const file of res) formData.append(`attachments`, file, file.name)

			if (initialValues.list) formData.append('massNotificationDiffusionListId', initialValues.list)

			api
				.post(bindParams(MASSNOTIFICATION, cookies[PLATFORM_ID_COOKIE]), formData, {
					headers: {
						'content-type': 'multipart/form-data;',
					},
				})
				.then((resultat) => {
					setLoading(false)
					setIsReadOnly(false)

					const { problem, status } = resultat
					if (problem)
						return enqueueSnackbar(`${t('error')} ${status} : ${t('view_render_failed')}`, {
							variant: 'error',
						})

					setUpdate(!update)
					enqueueSnackbar(t('massNotification:successful_notification'), { variant: 'success' })
				})
		})
	}

	const updateResults = (type, value) => {
		setAllResults({ ...allResults, [type]: value })
	}

	useEffect(() => {
		getCompanyByPdp(cookies[PLATFORM_ID_COOKIE], function (res, error) {
			if (res) {
				setCompanyPdp(res.data)
			}
		})
		getJobList(langId, function (res, error) {
			if (res) {
				setCompanyJob(res.data)
			}
		})
		getEntities(cookies[PLATFORM_ID_COOKIE], langId, function (res, error) {
			if (res) {
				setCompanyPhase(res.data)
			}
		})
		getCompanyByPtf(cookies[PLATFORM_ID_COOKIE], function (res, error) {
			if (res) {
				setCompanyParticular(res.data)
			}
		})
	}, [])

	useEffect(() => {
		getListMassNotifications(cookies[PLATFORM_ID_COOKIE], function (res, error) {
			if (res) {
				setListDiffusion(res.data)
			}
		})
	}, [update])

	const selectMassNotification = (id) => {
		let list = listDiffusion.find((list) => {
			return list.idList === id
		})
		setInitialValues({
			title: list.nameList,
			companiesActifAccount: list.companyActifAccount,
			companiesActifPdP: list.companyActifPdP,
			list: list.idList,
		})
		setAllResults({
			companyPdp: list.idPdP,
			companyJob: list.idMetier,
			companyPhase: list.idPhase,
			companyParticular: list.idCompany,
		})
	}

	const deleteNotification = () => {
		if (!initialValues.list) return
		deleteNotif(cookies[PLATFORM_ID_COOKIE], initialValues.list, function (res, error) {
			if (res) {
				setUpdate(!update)
				enqueueSnackbar('La liste a bien été supprimé', { variant: 'info' })
				setInitialValues({
					title: '',
					companiesActifAccount: false,
					companiesActifPdP: false,
					list: null,
				})
			}
		})
	}

	const flushForm = () => {
		setInitialValues({ list: null })
		setAllResults({
			companyPdp: [],
			companyJob: [],
			companyPhase: [],
			companyParticular: [],
		})
	}

	return (
		<Form onSubmit={onSubmit} className={classes.fullWidth} initialValues={initialValues}>
			<Paper className={classes.paper}>
				<div>
					<Typography className={classes.inlineBlock} color="secondary" variant="body1">
						Liste de diffusion
					</Typography>
					<InputSelectOnChange
						readOnly={isReadOnly}
						name="list"
						label="Sélectionner un élément"
						classes={{
							root: classNames(classes.inlineBlock, classes.selects, classes.firstSelect),
						}}
						onChange={(event) => {
							selectMassNotification(event.target.value)
						}}
					>
						{listDiffusion.map((list) => {
							return (
								<SelectMenuItem key={list.idList} value={list.idList}>
									{list.nameList}
								</SelectMenuItem>
							)
						})}
					</InputSelectOnChange>
					<IconButton disabled={isReadOnly} onClick={flushForm}>
						<AddCircleOutline
							className={classNames(classes.inlineBlock, classes.addIcon)}
						></AddCircleOutline>
					</IconButton>
				</div>
				<Field
					name="title"
					render={({ input, meta }) => (
						<FormControl
							margin="normal"
							{...input}
							className={classNames(classes.inlineBlock, classes.titleContainer)}
						>
							<TextField
								disabled={isReadOnly}
								type="text"
								InputProps={{
									classes: {
										root: classes.title,
									},
								}}
								{...input}
							/>
						</FormControl>
					)}
				/>
				<IconButton disabled={isReadOnly} onClick={deleteNotification}>
					<Delete
						className={classNames(classes.inlineBlock, classes.create)}
						color="secondary"
					></Delete>
				</IconButton>
				<div>
					<Field
						name="companiesActifPdP"
						type="checkbox"
						render={({ input, meta }) => (
							<FormControl
								disabled={isReadOnly}
								margin="normal"
								{...input}
								className={classNames(classes.inlineBlock, classes.switch)}
							>
								<Switch color="secondary" {...input}></Switch>
							</FormControl>
						)}
					/>
					<Typography className={classes.inlineBlock} color="secondary" variant="body1">
						Entreprises sur PdP Actifs
					</Typography>
				</div>
				<div>
					<Field
						name="companiesActifAccount"
						type="checkbox"
						render={({ input, meta }) => (
							<FormControl
								disabled={isReadOnly}
								margin="normal"
								{...input}
								className={classNames(classes.inlineBlock, classes.switch)}
							>
								<Switch color="secondary" {...input}></Switch>
							</FormControl>
						)}
					/>
					<Typography className={classes.inlineBlock} color="secondary" variant="body1">
						Entreprises ayant un compte actif
					</Typography>
				</div>

				<InputSelectField
					disabled={isReadOnly}
					name="companyByPdP"
					label="Entreprise par PdP"
					classes={{ root: classes.selects }}
					initialSelected={allResults.companyPdp}
					onChange={(event) => updateResults('companyPdp', event.target.value)}
				>
					{companyPdp.map((pdp) => {
						return (
							<SelectMenuItem key={pdp.id} value={pdp.id}>
								{pdp.nameInformation}
							</SelectMenuItem>
						)
					})}
				</InputSelectField>
				<InputSelectField
					disabled={isReadOnly}
					name="companyByJobs"
					label="Entreprise par métier"
					classes={{ root: classes.selects }}
					initialSelected={allResults.companyJob}
					onChange={(event) => updateResults('companyJob', event.target.value)}
				>
					{companyJob.map((job) => {
						return (
							<SelectMenuItem key={job.metId} value={job.metId}>
								{job.lmtNom}
							</SelectMenuItem>
						)
					})}
				</InputSelectField>
				<InputSelectField
					disabled={isReadOnly}
					name="companyByPhase"
					label="Entreprise par phase"
					classes={{ root: classes.selects }}
					initialSelected={allResults.companyPhase}
					onChange={(event) => updateResults('companyPhase', event.target.value)}
				>
					{companyPhase.map((phase) => {
						return (
							<SelectMenuItem key={phase.pacId} value={phase.pacId}>
								{phase.displayName}
							</SelectMenuItem>
						)
					})}
				</InputSelectField>
				<InputSelectField
					disabled={isReadOnly}
					name="companyParticular"
					label="Entreprise particulière"
					classes={{ root: classes.selects }}
					initialSelected={allResults.companyParticular}
					onChange={(event) => updateResults('companyParticular', event.target.value)}
				>
					{companyParticular.map((pdp) => {
						return (
							<SelectMenuItem key={pdp.id} value={pdp.id}>
								{pdp.nameInformation}
							</SelectMenuItem>
						)
					})}
				</InputSelectField>

				<RichText editorState={editorState} onChangeEditorState={debouncedChangeEditorState} />

				<div style={{ display: 'flex' }}>
					<InputFile
						disabled={isReadOnly}
						name="ccFile"
						label={t('SelectFile')}
						setFile={addDocument}
						nameFileUpdated={addDocumentName}
						hideName
					/>
				</div>

				<Typography variant="body2" color="secondary" className={classes.fileNames}>
					{nameFile}
				</Typography>
				<div className={classNames(classes.flex, classes.fullWidth)}>
					<Button
						disabled={isReadOnly}
						className={`${classes.button}`}
						type={'submit'}
						variant="outlined"
						classes={{ root: classes.validateRoot }}
					>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('send')}
					</Button>
					<Button
						className={`${classes.button}`}
						type={'submit'}
						disabled={isReadOnly}
						variant="outlined"
						onClick={() => setShouldSave(true)}
						classes={{ root: classes.validateRoot }}
					>
						{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
						{t('sendAndSaveList')}
					</Button>
				</div>
			</Paper>
		</Form>
	)
}
export default MyAccountForm
