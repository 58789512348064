import { Grid } from '@material-ui/core'
import ContactPhoneOutlinedIcon from '@material-ui/icons/ContactPhoneOutlined'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import LinkIcon from '@material-ui/icons/Link'
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined'
import PermContactCalendarOutlinedIcon from '@material-ui/icons/PermContactCalendarOutlined'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined'
import BuildOutlinedIcon from '@material-ui/icons/BuildOutlined'
import { makeStyles } from '@material-ui/styles'
import React, { useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { COMPANY_ID_COOKIE, PLATFORM_ID_COOKIE } from '../../constants/cookies'
import { DOCUMENTATION_HELP } from '../../constants/url'
import { matchUrl } from '../../containers/AuthContainer'
import {
	getContactsByAccountId,
	getContactTypeList,
	getOperationalInformationsByAccountId,
	getOperationnalModes,
} from '../../containers/DataContainer'
import { useTotalTitleBarContext } from '../../contexts/TotalTitleBarContext'
import PropTypes from 'prop-types'
import OICard from '../../components/Cards/OICard'
import i18next from 'i18next'

const useStyles = makeStyles((theme) => ({
	page: {
		paddingTop: theme.spacing.unit * 2,
		paddingLeft: theme.spacing.unit * 10,
		paddingRight: theme.spacing.unit * 10,
		paddingBottom: theme.spacing.unit * 4,
	},
	card: {
		position: 'relative',
		height: 175,
		border: 'none',
		transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		color: 'black !important',
		'&:hover': {
			color: theme.palette.secondary.main + ' !important',
			border: 'solid 1px rgba(102, 153, 255,0.4)',
			boxShadow: '0px 10px 24px 0px rgba(0,0,0,0.2)',
			transition: 'color 0.3s, border 0.3s, box-shadow 0.3s',
		},
		'&:hover $title': {
			color: theme.palette.secondary.main + ' !important',
			transition: 'color 0.3s',
		},
	},
	title: {
		margin: theme.spacing.unit,
		fontSize: '1.2em',
		[theme.breakpoints.down('md')]: {
			margin: 5,
		},
	},
	icon: {
		position: 'absolute',
		display: 'block',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: 60,
	},
	link: {
		display: 'contents',
		textDecoration: 'none',
		'&:visited': {
			color: 'black',
		},
	},
}))

function OperationalInfos({ match }) {
	const classes = useStyles()
	const [cookies] = useCookies()
	const { t } = useTranslation(['operationalInfos', 'user'])
	const [companyName, setCompanyName] = useState('')
	const [infos, setInfos] = useState()
	const [operationModes, setOperationModes] = useState([])
	const [contacts, setContacts] = useState([])
	const [listType, setListType] = useState([])
	const [language, setLanguage] = useState(1)
	const { setTitleBar } = useTotalTitleBarContext()
	const targetCompanyId = match.params.companyId || cookies[COMPANY_ID_COOKIE]
	let docLink = DOCUMENTATION_HELP.replace(/[{@}]{3}/g, cookies[PLATFORM_ID_COOKIE])
	useEffect(() => {
		let filters = 'Documents&Certificats'

		switch (i18next.language) {
			case 'fr':
				setLanguage(1)
				break
			case 'en':
				setLanguage(2)
				break
			default:
				setLanguage(1)
				break
		}

		getOperationalInformationsByAccountId(filters, targetCompanyId, (res, err) => {
			if (err || !res) return console.error(err)
			setInfos(res.data)
			return setCompanyName(res.data.entNom)
		})
		getOperationnalModes(targetCompanyId, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setOperationModes(res.data)
			}
		})
		getContactsByAccountId(targetCompanyId, function (res, error) {
			if (error) return
			if (res) {
				setContacts(res.data)
			}
		})
		getContactTypeList(language, function (res, error) {
			if (error) {
				return
			}
			if (res) {
				setListType(res.data)
			}
		})
	}, [])

	useEffect(() => {
		setTitleBar({
			type: 'titleBar',
			data: {
				icon: 'person',
				title: () => t('user:OperationnalDataOf') + companyName,
			},
		})
	}, [companyName, setTitleBar, t])

	const checkInfosFields = () =>
		infos?.entNom &&
		infos?.entRaisonSociale &&
		infos?.entNomAgence &&
		infos?.entNumeroSiret &&
		infos?.lngId &&
		infos?.entAdresse &&
		infos?.entCodePostal &&
		infos?.entVille &&
		infos?.entTelAgence &&
		infos?.entActivite

	const checkContactsFields = () => {
		const directorIDS = [1, 2] // ids of (D) and (RA)
		const personalIDS = [5, 6] // ids of (RP) and (HSE)
		const responsableIDS = [9, 10] // ids of (RT) and (RO)

		const contactRoles = [...new Set(contacts.map((x) => x.typesIds).flat(1))]
		const typesIdsRequired = listType.filter((x) => x.requiredField).map((x) => x.id)
		if (
			directorIDS.some((x) => typesIdsRequired.includes(x) && contactRoles.includes(x)) &&
			personalIDS.some((x) => typesIdsRequired.includes(x) && contactRoles.includes(x)) &&
			responsableIDS.some((x) => typesIdsRequired.includes(x) && contactRoles.includes(x))
		) {
			return typesIdsRequired
				.filter((x) => ![...directorIDS, ...personalIDS, ...responsableIDS].includes(x))
				.every((id) => contactRoles.includes(id))
		}

		return false
	}

	return (
		<Grid container className={classes.page} spacing={16}>
			<OICard
				title={t('user:info')}
				icon={InfoOutlinedIcon}
				link={matchUrl(match, '/OperationnalInformations')}
				error={!checkInfosFields()}
			/>
			<OICard title={t('user:Attachment')} icon={LinkIcon} link={matchUrl(match, '/attachment')} />
			<OICard
				title={t('DWDCorrespondents')}
				icon={ContactPhoneOutlinedIcon}
				link={matchUrl(match, '/DWPCorrespondent')}
			/>
			<OICard
				title={t('user:contacts')}
				icon={PermContactCalendarOutlinedIcon}
				link={matchUrl(match, '/contacts')}
				error={!checkContactsFields()}
			/>
			<OICard
				title={t('chemicalProduct')}
				icon={ReportProblemOutlinedIcon}
				link={matchUrl(match, '/chemical')}
			/>
			<OICard
				title={t('environmentalAspect')}
				icon={OpacityOutlinedIcon}
				link={matchUrl(match, '/environmentalAspects')}
			/>
			<OICard
				title={t('operationalModes')}
				icon={SettingsApplicationsOutlinedIcon}
				link={matchUrl(match, '/operationnalModes')}
				error={!operationModes.length}
			/>
			<OICard
				title={t('help')}
				icon={BuildOutlinedIcon}
				link={{ pathname: docLink }}
				external={true}
			/>
		</Grid>
	)
}
OperationalInfos.propTypes = {
	match: PropTypes.object,
}

export default OperationalInfos
