import React, { useCallback } from 'react'
import { Dialog, DialogContent, Button, CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/styles'
import GetApp from '@material-ui/icons/GetApp'
import { v4 as uuid_v4 } from 'uuid'
import * as link from '../../../constants/url'

const useStyles = makeStyles(() => ({
	mR: {
		marginRigth: '10px',
	},
	mL: {
		marginLeft: '10px',
	},
	center: {
		textAlign: 'center',
	},
	buttonProgress: {
		color: 'black',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
}))
function ModalChemicalProducts({ isOpen, onClose, onSubmit, loading }) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'chemical'])

	const executeOnClose = useCallback(() => {
		onClose(false)
	})
	const executeOnSubmit = useCallback((link) => () => {
		onSubmit(link)
	})

	return (
		<Dialog open={isOpen} onBackdropClick={executeOnClose} maxWidth="md">
			<DialogContent>
				<Button
					key={uuid_v4()}
					onClick={executeOnSubmit(link.REPORTS_CHEMICALS)}
					className={`${classes.mR} ${classes.mL}`}
					variant="contained"
					color="secondary"
					disabled={loading}
				>
					{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
					<GetApp /> {t('user:ChemicalProducts')}
				</Button>
				<Button
					key={uuid_v4()}
					onClick={executeOnSubmit(link.REPORTS_CHEMICALS_FDS)}
					className={`${classes.mR} ${classes.mL}`}
					variant="contained"
					color="secondary"
					disabled={loading}
				>
					{loading && <CircularProgress size={24} className={classes.buttonProgress} />}
					<GetApp /> {t('chemical:FDSChemicalProducts')}
				</Button>
			</DialogContent>
		</Dialog>
	)
}

export default ModalChemicalProducts
