const ID = () => {
	// Using window.crypto to generate random values
	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
	// https://sonarcloud.io/organizations/cpn-microsoft/rules?open=javascript%3AS2245&rule_key=javascript%3AS2245

	const crypto = window.crypto || window.msCrypto
	let array = new Uint32Array(15)
	let randomValues = crypto.getRandomValues(array)

	return '_' + randomValues.toString(36).substr(2, 9)
}

export default ID
