import React from 'react'
import './TotalLoader.css'
import { makeStyles } from '@material-ui/styles'
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
	fixedLoader: {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%) scale(2)',
	},
	customizableLoader: {
		position: 'relative',
	},
	label: {
		position: 'absolute',
		left: '50%',
		top: 'calc(50% + 33px)',
		transform: 'translate(-50%, 0%)',
	},
}))

function TotalLoader({ label, fixed = true }) {
	const classes = useStyles()

	return (
		<>
			<div
				className={classNames({
					[classes.fixedLoader]: fixed,
					[classes.customizableLoader]: !fixed,
					'lds-roller': true,
				})}
			>
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="dot" />
				<div className="logo">
					<img src="/images/totalLogo.png" width="32" height="32" alt="Total Loader" />
				</div>
			</div>
			{label && <p className={classes.label}>{label}</p>}
		</>
	)
}
export default TotalLoader
