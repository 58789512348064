import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Typography, Grid, Button } from '@material-ui/core'
import ContactCompanyFilesCard from '../../../../Cards/ContactCompanyFilesCard'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(theme => ({
	contactsGroup: {
		border: '1px solid ' + theme.palette.secondary.main,
		padding: 5,
		display: 'block',
		borderRadius: 5,
		marginTop: 40,
		paddingBottom: 10,
		minHeight: 130,
		position: 'relative',
	},
	categoryTitle: {
		transform: 'translateY(-50%)',
		position: 'absolute',
		backgroundColor: theme.palette.primary.main,
		padding: 8,
		paddingTop: 0,
		color: theme.palette.secondary.main,
	},
	button: {
		marginLeft: theme.spacing.unit,
		marginTop: theme.spacing.unit * 3,
	},
	buttonAddCertification: {
		right: 10,
		position: 'absolute',
	},
}))

function StepTwo({
	openAddCompanyFilesContact,
	openExecutionManagerForm,
	operationManager,
	setOperationManager,
	technicalsManagerList,
	setTechnicalsManagerList,
	isReadOnly,
}) {
	const classes = useStyles()
	const { t } = useTranslation('user')

	const deleteExecutiveManager = () => {
		setOperationManager(null)
	}

	const deleteTechnicalManager = id => {
		setTechnicalsManagerList(technicalsManagerList.filter(contact => contact.id !== id && contact))
	}

	return (
		<>
			<div className={classes.contactsGroup}>
				<Typography variant="h5" classes={{ root: classes.categoryTitle }}>
					{t('responsableExecution')}
				</Typography>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={openExecutionManagerForm}
					>
						{t('addFromLibrary')}
					</Button>
				)}
				<Grid container spacing={16} direction="row" className={classes.container}>
					{operationManager && (
						<ContactCompanyFilesCard
							size={4}
							data={{ ...operationManager }}
							onClick={e => openExecutionManagerForm()}
							deleteAction={deleteExecutiveManager}
							isReadOnly={isReadOnly}
						>
							{operationManager.name}
						</ContactCompanyFilesCard>
					)}
				</Grid>
			</div>
			<div className={classes.contactsGroup}>
				<Typography variant="h5" classes={{ root: classes.categoryTitle }}>
					{t('technicalResponsable')}
				</Typography>
				{!isReadOnly && (
					<Button
						variant="contained"
						color="primary"
						className={classes.button}
						onClick={e => openAddCompanyFilesContact()}
					>
						{t('addFromLibrary')}
					</Button>
				)}
				<Grid container spacing={16} direction="row" className={classes.container}>
					{technicalsManagerList &&
            technicalsManagerList.map((contact, i) => (
            	<ContactCompanyFilesCard
            		key={i}
            		size={4}
            		data={{ ...contact }}
            		deleteAction={() => {
            			deleteTechnicalManager(contact.id)
            		}}
            		isReadOnly={isReadOnly}
            	>
            		{`${contact.firstName} ${contact.lastName}`}
            	</ContactCompanyFilesCard>
            ))}
				</Grid>
			</div>
		</>
	)
}

export default StepTwo
