import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Form from '../Form/Form'
import FormIcon from '../Form/FormIcon'
import SlidingForm from '../Form/SlidingForm'
import InputRadio, { TotalRadio } from '../Form/input/InputRadio'
import FormButton from '../Form/FormButton'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
	form: {
		textAlign: 'center',
	},
	profile: {
		textAlign: 'left',
	},
	img: {
		verticalAlign: 'middle',
	},
	radio: {
		marginLeft: theme.spacing.unit * 2,
	},
	submit: {
		display: 'block',

		marginTop: theme.spacing.unit * 5,
		margin: 'auto',
	},
}))

function UserProfileForm({ open, close, data }) {
	const classes = useStyles()
	const { t } = useTranslation('user')

	const onSubmit = (value) => {
		switch (value.accountType) {
			case 'EE':
				data.OpenInfoForm()
				close()
				break
			case 'other':
				data.OpenOtherUserForm()
				close()
				break
			default:
				break
		}
	}

	return (
		<>
			<SlidingForm
				direction="horizontal"
				open={open}
				title={t('ChoiceOfTheAccountType')}
				onClose={close}
				iconAttachement={
					<>
						<FormIcon icon="clear" onClick={close} />
					</>
				}
			>
				<Form onSubmit={onSubmit} initialValues={{ accountType: 'EE' }} className={classes.form}>
					<InputRadio name="accountType">
						<TotalRadio
							label={t('externCompany')}
							value="EE"
							labelPlacement="end"
							image="/icons/owner_white.svg"
							className={classes.radio}
						/>
						<TotalRadio
							label={t('otherUser')}
							value="other"
							labelPlacement="end"
							image="/icons/extern_white.svg"
							className={classes.radio}
						/>
					</InputRadio>
					<FormButton
						classes={{
							root: classes.submit,
						}}
						type="submit"
						label={t('submit')}
					/>
				</Form>
			</SlidingForm>
		</>
	)
}
UserProfileForm.propTypes = {
	open: PropTypes.bool,
	close: PropTypes.func,
	data: PropTypes.object,
}
export default UserProfileForm
