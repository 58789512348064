const onlySpaces = (str) => {
	return typeof str === 'string' && str.trim().length === 0
}

export const required = (value) => (value && !onlySpaces(value) ? undefined : 'required')

export const nameSize = (value) => (value.length > 250 ? 'nameTooLong' : undefined)

export const loginSize = (value) => {
	if (value) {
		if (value.length > 250) return 'loginTooLong'
		else if (value.length < 6) return 'loginTooShort'
	}
	return undefined
}

export const noSpace = (value) => (value.match(' ') ? 'noSpace' : undefined)

export const passwordSize = (value) => {
	if (value) {
		if (value.length > 20) return 'passwordTooLong'
		else if (value.length < 8) return 'passwordTooShort'
	}
	return undefined
}

export const emailSize = (value) => {
	if (value.length > 60) return 'emailTooLong'
	else if (value.length < 6) return 'emailTooShort'
	else return undefined
}

export const emailFormat = (value) =>
	!/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i.test(
		value,
	)
		? 'emailFormat'
		: undefined

export const phoneFormat = (value) =>
	!/^\+((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\W*\d){0,13}\d$/.test(
		value,
	)
		? 'InvalidPhoneFormat'
		: undefined

export const maxLong = (value) => (value.length > 254 ? 'fieldTooLong' : undefined)

export const sectorUnitMinLength = (value) => (value.length < 3 ? 'fieldTooShort' : undefined)

export const siretSize = (value) => (value.length !== 14 ? 'wrongSIRETNumber' : undefined)

export const siretFormat = (value) => {
	return /^[a-zA-Z0-9]*$/.test(value) ? undefined : 'wrongSIRETNumber'
}

export const siretForeignerSize = (value) =>
	value.length < 4 || value.length > 20 ? 'wrongSIRETNumber' : undefined

export const oneSpecialCharRequired = (value) =>
	value && !/[^a-zA-Z0-9]+/.test(value) ? 'oneSpecialCharRequired' : undefined

export const oneMajCharRequired = (value) =>
	value && !/[A-Z]/.test(value) ? 'oneMajCharRequired' : undefined

export const noSpecialChar = (value) =>
	value && !/^[a-zA-Z0-9-_]*$/.test(value) ? 'noSpecialChar' : undefined

export const noSpecialCharURLFriendly = (value) =>
	value && !/^[^!"`'#%&,:°;<>=@{}~$*+/\\?[\]^|]+$/.test(value)

export const chemicalProductRetention = (value) =>
	value && !/^(H4(00|10|11|12|13)|H2(24|25|26))$/.test(value)

export const CASNumber = (value) => {
	if (!/^[0-9-]*$/.test(value)) return false
	value = value.replace(/-/g, '')
	if (value.length > 10) return false
	const keyNumber = value[value.length - 1]
	let cpt = 0
	for (let i = 2; i <= value.length; i++) {
		cpt += value[value.length - i] * (i - 1)
	}
	cpt = cpt % 10
	if (`${cpt}` !== keyNumber) return false
	return true
}

export const CENumber = (value) => {
	if (!/^[0-9-]*$/.test(value)) return false
	value = value.replace(/-/g, '')
	const keyNumber = value[value.length - 1]
	let cpt = 0
	for (let i = 1; i < value.length; i++) {
		cpt += value[i - 1] * i
	}
	cpt = cpt % 11
	if (`${cpt}` !== keyNumber) return false
	return true
}

export const CASorCENumber = (value) => {
	const numbers = value.split('/')
	for (const number of numbers) {
		if (CENumber(number) !== true && CASNumber(number) !== true) return 'InvalidCASorCE'
		if (CASNumber(number) !== true && CENumber(number) !== true) return 'InvalidCASorCE'
	}
}
