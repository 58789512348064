import { Button, Fab, Grid, Typography, Checkbox, Icon } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import DocumentCard from '../../../components/Cards/DocumentCard'
import { PLATFORM_ID_COOKIE } from '../../../constants/cookies'
import { PDP_DOCUMENTS, PDP_DOCUMENTS_DOWNLOAD } from '../../../constants/url'
import { usePDPDocumentFileForm } from '../../../containers/FormContainer'
import { bindParams } from '../../../utils/Link'
import api from '../../../utils/Requester'
import PropTypes from 'prop-types'
import ModalExtraction from './Modals/ModalExtraction'
import fileDownload from 'js-file-download'
import { getFileNameToHeaders } from '../../../utils/File'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme) => ({
	fab: {
		display: 'block',
		marginLeft: 'auto',
		marginTop: 20,
	},
	addIcon: {
		display: 'flex',
	},
	title: {
		display: 'inline-block',
		fontSize: '2em',
		padding: 10,
		color: theme.palette.secondary.main,
		fontWeight: 500,
		marginRight: 20,
	},
	preventionNumber: {
		display: 'inline-block',
		color: theme.palette.secondary.main,
		fontWeight: 400,
		fontSize: '1.2em',
	},
	hrRoot: {
		margin: 0,
		marginBottom: 12,
		marginRight: 'auto',
		marginLeft: 11,
		maxWidth: 100,
		border: '1px solid ' + theme.palette.secondary.main,
	},
	filters: {
		color: theme.palette.secondary.main,
		display: 'inline-block',
		margin: 15,
	},
}))

Documents.propTypes = {
	match: PropTypes.object,
	openCreatePdPContactWithData: PropTypes.func,
	isReadOnly: PropTypes.bool,
	pdpId: PropTypes.string,
	buttonsStyle: PropTypes.object,
	revisionNumber: PropTypes.string,
	pdpNumber: PropTypes.string,
	refreshChevrons: PropTypes.bool,
	setRefreshChevrons: PropTypes.func,
	nextStep: PropTypes.func,
	previousStep: PropTypes.func,
	pdpState: PropTypes.number,
}
function Documents({
	isReadOnly,
	pdpId,
	buttonsStyle,
	revisionNumber,
	pdpNumber,
	refreshChevrons,
	setRefreshChevrons,
	nextStep,
	previousStep,
	pdpState,
}) {
	const classes = useStyles()
	const { t } = useTranslation(['user', 'reports'])
	const [cookies] = useCookies()
	const [listDocuments, setListDocuments] = useState([])
	const [listDocumentsFiltered, setListDocumentsFiltered] = useState([])
	const [filterDocuments, setFilterDocuments] = useState([])
	const [refresh, setRefresh] = useState(false)
	const [isOpenExtraction, setIsOpenExtraction] = useState(false)
	const { enqueueSnackbar } = useSnackbar()
	const PDPDocumentFileForm = usePDPDocumentFileForm({
		setRefreshChevrons: setRefreshChevrons,
		refreshChevrons: refreshChevrons,
		refresh: refresh,
		setRefresh: setRefresh,
		pdpId: pdpId,
		revisionNumber: revisionNumber,
	})

	const openPDPDocumentFileForm = () => {
		PDPDocumentFileForm.open()
	}

	const recoveryPdpDocuments = (res) => {
		const { data, problem } = res
		if (problem) return
		if (data) {
			setListDocuments(data)
			setListDocumentsFiltered(data)
		}
	}

	useEffect(() => {
		api
			.get(bindParams(PDP_DOCUMENTS, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber))
			.then(recoveryPdpDocuments)
	}, [refresh])

	useEffect(() => {
		updateFilterDocuments(InitializeFilters())
	}, [listDocuments])

	useEffect(() => {
		let result = listDocuments.filter((doc) => {
			let pdpfileTypeKey = doc.pdPFileType
			return filterDocuments.some((filter) => filter.id === pdpfileTypeKey && filter.show)
		})
		setListDocumentsFiltered(result)
	}, [filterDocuments])

	const AddDocument = () => {
		return (
			<>
				<Fab
					classes={{ root: classes.fab, label: classes.addIcon }}
					color="secondary"
					onClick={openPDPDocumentFileForm}
				>
					<AddIcon className={classes.addIcon} />
				</Fab>
			</>
		)
	}

	const toggleCheckbox = (event) => {
		let v_filterDocuments = [...filterDocuments]
		if (event.target.checked) {
			v_filterDocuments.find((filter) => filter.id == event.target.dataset.value).show = true
		} else {
			v_filterDocuments.find((filter) => filter.id == event.target.dataset.value).show = false
		}
		updateFilterDocuments(v_filterDocuments)
	}

	const InitializeFilters = () => {
		let documentsFilters = []
		let pdpFileType = null
		for (const document of listDocuments) {
			pdpFileType = document.pdPFileType

			if (!documentsFilters.some((filter) => filter.id === pdpFileType)) {
				documentsFilters.push({
					id: pdpFileType,
					show: true,
				})
			}
		}
		return documentsFilters
	}

	const DisplayFilters = () => {
		let keyTraduction = {
			0: t('HeadDocument'),
			1: t('InvitationToAttend'),
			2: t('Annex'),
			3: t('Endorsement'),
		}
		return filterDocuments.map((filter) => (
			<div className={classes.filters} key={filter.id}>
				<span>{keyTraduction[filter.id]}</span>
				<Checkbox
					name={`name-checkbox-${filter.id}`}
					className={classes.checkboxChecked}
					onChange={toggleCheckbox}
					checked={filter.show}
					inputProps={{
						'data-value': filter.id.toString(),
					}}
				/>
			</div>
		))
	}

	const updateFilterDocuments = (docFilters) => {
		setFilterDocuments(docFilters)
	}

	const recoveryLink = (res) => {
		const { data, problem, headers, status } = res
		if (problem)
			return enqueueSnackbar(
				status === 404 ? `${t('reports:noFilePresent')} !` : `${t('reports:somethingWrong')}`,
				{ variant: 'error' },
			)

		if (data) {
			let blob = data
			const _filename = getFileNameToHeaders(headers)
			if (!_filename.includes('.zip'))
				blob = new Blob(['\ufeff', data], {
					type: headers['content-type'],
				})
			fileDownload(blob, _filename)
		}
	}

	const onExtractionSubmit = useCallback(async (values) => {
		// MUST BE LOWERCASE
		const pdpFileTypes = {
			headdocument: 0,
			convocation: 1,
			invitationtoattend: 1,
			annex: 2,
			endorsement: 3,
		}
		const paraName = 'p_FileTypes'
		const params = new URLSearchParams()

		Object.keys(values).forEach((key) => params.append(paraName, pdpFileTypes[key.toLowerCase()]))

		await api
			.get(
				bindParams(PDP_DOCUMENTS_DOWNLOAD, cookies[PLATFORM_ID_COOKIE], pdpId, revisionNumber),
				params,
				{
					headers: {
						Pragma: 'no-cache',
					},
					responseType: 'arraybuffer',
				},
			)
			.then((res) => recoveryLink(res))
			.catch((error) => console.log(error))

		setIsOpenExtraction(false)
	})

	return (
		<>
			<ModalExtraction
				isOpen={isOpenExtraction}
				onClose={setIsOpenExtraction}
				onSubmit={onExtractionSubmit}
				fileTypes={filterDocuments.map((filter) => filter.id)}
			/>
			{PDPDocumentFileForm.formular}
			<Typography variant="h3" classes={{ root: classes.title }}>
				{t('Documents')}
			</Typography>
			<Typography classes={{ root: classes.preventionNumber }}>
				{t('preventionPlanNo')} {pdpNumber}
			</Typography>
			<hr className={classes.hrRoot} />
			<DisplayFilters />
			<Button onClick={() => setIsOpenExtraction(true)} color="secondary" variant="contained">
				<Icon>download</Icon>
				{t('extractDocuments')}
			</Button>
			<Grid container spacing={32} direction="row">
				{listDocumentsFiltered.map((document, key) => (
					<DocumentCard
						size={3}
						key={key}
						file={document}
						setRefresh={setRefresh}
						refresh={refresh}
						refreshChevrons={refreshChevrons}
						setRefreshChevrons={setRefreshChevrons}
						isReadOnly={isReadOnly}
						pdpId={pdpId}
					/>
				))}
			</Grid>
			{!isReadOnly && <AddDocument />}
			<div className={buttonsStyle.root}>
				<Grid container direction="row" justify="space-between" alignItems="center">
					<Grid container item xs={4} justify="center">
						<Button className={buttonsStyle.previous} onClick={previousStep}>
							{t('previous')}
						</Button>
					</Grid>
					{pdpState > 1 && (
						<Grid container item xs={4} justify="center">
							<Button className={buttonsStyle.next} onClick={nextStep}>
								{t('next')}
							</Button>
						</Grid>
					)}
				</Grid>
			</div>
		</>
	)
}

export default Documents
